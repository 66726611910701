import { Col, Divider, Radio, Row, Space, Tag } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash, { includes } from "lodash";
import React, { useEffect, useState } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import { Modal, QAlert, QButton, QInput } from "quantum_components";
import { renderRoundedNumber } from "src/helpers/currency";
import { CURRENCY } from "src/constants/currencies";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrentUser } from "src/modules/core/core.selectors";
import DialogModal from "src/components/DialogModal/DialogModal";
import { calculateBudgetFeedback } from "../briefv3.helper";

const V3CartComponent = ({
  selectedServicesState,
  setSelectedServicesState,
  cartState,
  setCartState,
  briefResponse,
  parentRefresh,
  canEdit,
}) => {
  const [serviceRemoveConfirm, setServiceRemoveConfirm] = useState(false);
  const [serviceForRemoval, setServiceForRemoval] = useState<any>({});

  const { partnerData } = useSelector(getCurrentUser);
  const { t } = useTranslation(["translationV2", "translation"]);
  const showViewQuotation =
    lodash.get(briefResponse, "rawResponse.status") === "APPROVED" &&
    lodash.get(briefResponse, "rawResponse.payment.quotation");

  const onlinePaidStatus = lodash.get(
    briefResponse,
    "rawResponse.payment.onlinePayment.status"
  );

  const recalculateCart = () => {
    let c = { ...cartState };

    const servicesTotal = selectedServicesState.reduce(
      (acc, cur: any) => (acc += cur.total),
      0
    );

    const subTotal = servicesTotal + c.managementFee - c.discount;
    let totalTax = 0;

    if (
      partnerData?.taxTreatment === "VAT Registered" ||
      onlinePaidStatus === "PAID"
    ) {
      totalTax = subTotal * 1.15 - subTotal;
    }
    const cartTotal = totalTax + subTotal;

    c.servicesTotal = servicesTotal;
    c.subTotal = subTotal;
    c.totalTax = totalTax;
    c.cartTotal = cartTotal;
    // c.subTotal = servicesTotal + c.managementFee - c.discount;
    // c.totalTax = servicesTotal * 1.15 - servicesTotal;
    // c.cartTotal = servicesTotal * 1.15;

    setCartState(c);
  };

  useEffect(() => {
    recalculateCart();
  }, [selectedServicesState, partnerData, onlinePaidStatus]);

  const shouldDisable = (service: any) => {
    if (service.status === "REJECTED") return false;
    if (briefResponse?.status === "DRAFT") return false;
    return true;
  };

  const handleQuantityChange = (serviceId, quantity) => {
    if (!quantity || quantity < 0) quantity = 0;
    let updatedServices = selectedServicesState.map((ss) => {
      if (ss.id === serviceId) {
        ss.quantity = Number(quantity);
        ss.total = Number(ss.basePrice) * Number(quantity);
      }
      return ss;
    });
    setSelectedServicesState(updatedServices);
    recalculateCart();
  };

  const handleRemoveService = (serviceId: any) => {
    let updatedServices = selectedServicesState.filter(
      (s: any) => s.id !== serviceId
    );
    setSelectedServicesState(updatedServices);
  };

  const handleServiceRemove = (serviceId: any) => {
    setSelectedServicesState(
      selectedServicesState.filter((service: any) => service.id !== serviceId)
    );
    recalculateCart();
  };

  const getQuotationURL = () => {
    const taxedBrief = Number(cartState?.totalTax) > 0;

    const normalQuotation = lodash.get(
      briefResponse,
      "rawResponse.payment.quotation"
    );
    const taxedQuotation = lodash.get(
      briefResponse,
      "rawResponse.payment.quotationWithTax"
    );

    if (taxedBrief === true) {
      if (taxedQuotation) {
        return taxedQuotation;
      }
    }
    return normalQuotation;
  };

  const handleServiceMetricDataChange = (serviceId: any, control:string,value:string) => {
    if(!value) return;

    let updatedServices = selectedServicesState.map((ss: any) => {
      if (ss.id === serviceId) {
        ss["metricData"][control] = value;
        if(control === "campaignBudget"){
          ss.quantity = Number(1);
          ss.total = Number(value);
        }

        const {
          totalQuantity,
          estimatedDaysForTotalQuantity,
          dailyQuantity,
          estimatedDaysForDailyQuantity,
          dailyDistribution
        }:any = calculateBudgetFeedback(ss);
  
        ss["metricData"]["totalQuantity"] = totalQuantity;
        ss["metricData"]["estimatedDaysForTotalQuantity"] = estimatedDaysForTotalQuantity;
        ss["metricData"]["dailyQuantity"] = dailyQuantity;
        ss["metricData"]["estimatedDaysForDailyQuantity"] = estimatedDaysForDailyQuantity;
        ss["metricData"]["dailyDistribution"] = dailyDistribution;
      }
      return ss;
    });

    setSelectedServicesState(updatedServices);
    recalculateCart();
  };

  const getBudgetFeedbackLabel = (service:any) => {
    const {
      campaignBudget,
      simplifiedMetric,
      totalQuantity,
      estimatedDaysForTotalQuantity,
      dailyBudget,
      dailyQuantity,
      estimatedDaysForDailyQuantity,
      impressionsPerDay,
      clicksPerDay,
      selectedMetric
    }:any = calculateBudgetFeedback(service);
    if(!campaignBudget || !totalQuantity || !simplifiedMetric || !estimatedDaysForTotalQuantity) return;

    let label = "";
    if(dailyBudget > campaignBudget) return "";
    if(dailyBudget > 0){
      label = t("daily_budget_helper",{selectedMetric, impressionsPerDay:Number(selectedMetric === "CPM"?impressionsPerDay:clicksPerDay).toLocaleString(), dailyBudget:Number(dailyBudget).toLocaleString(),dailyQuantity:Number(dailyQuantity).toLocaleString(),simplifiedMetric:t(simplifiedMetric),estimatedDaysForDailyQuantity,totalQuantity:Number(totalQuantity).toLocaleString()})
    }else{
      label = t("total_budget_helper",{selectedMetric, impressionsPerDay:Number(selectedMetric === "CPM"?impressionsPerDay:clicksPerDay).toLocaleString(),campaignBudget:Number(campaignBudget).toLocaleString(),dailyQuantity:Number(dailyQuantity).toLocaleString(),simplifiedMetric:t(simplifiedMetric),estimatedDaysForDailyQuantity,totalQuantity:Number(totalQuantity).toLocaleString(),estimatedDaysForTotalQuantity})
    }

    return label;
    // return t("budget_helper", {
    //   budget,
    //   amount: quantity,
    //   simplifiedMetric,
    //   timeline,
    // }); 
  } 

  return (
    <div>
      {lodash.isEmpty(selectedServicesState) && (
        <div className="text-center">{t("No Data")}</div>
      )}
      {!lodash.isEmpty(selectedServicesState) && <h6>{t("Services")}</h6>}
      {selectedServicesState.map((ss: any, index: number) => {
        return (
          <Row align="top" className="mb-15" key={index}>
            <Col md={ss.durationType === "Per Metric" ? 24 : 12} xs={24}>
              <div>
                <label>
                  <b>
                    <label className="required-label">
                      {briefResponse?.status === "DRAFT" && (
                        <DeleteOutlined
                          title={t("Remove")}
                          onClick={() => {
                            setServiceForRemoval(ss);
                            setServiceRemoveConfirm(true);
                          }}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      )}{" "}
                      {ss.name}
                    </label>
                  </b>
                </label>
              </div>
              <div>
                {ss.durationType !== "Per Metric" && (
                  <div>
                    <label className="text-size-14">
                      {Number(ss.basePrice).toLocaleString() + " SAR"} [
                      {ss.durationType}]{" "}
                    </label>
                  </div>
                )}
                {ss.durationType === "Per Metric" && (
                  <div>
                    <Radio.Group
                      disabled={shouldDisable(ss)}
                      className="qu-radio-group mt-5"
                      value={ss?.metricData?.metricType}
                      onChange={(e:any)=>{
                        handleServiceMetricDataChange(ss.id,"metricType",e.target.value)
                      }}
                    >
                      <Space direction="vertical">
                      <Radio value="CPM">
                          <label className="text-size-14">
                            {ss.pricePerMetric?.cpm} SAR
                            {" "}
                            [{t("CPM")}]
                          </label>
                        </Radio>
                        <Radio value="CPC">
                          <label className="text-size-14">
                            {ss.pricePerMetric?.cpc} SAR
                            {" "}
                            [{t("CPC")}]
                          </label>
                        </Radio>
                      </Space>
                    </Radio.Group>

                    <Row align={"middle"} justify={"center"} gutter={24}>
                      <Col md={24} className={"mt-10"}>
                        <div className="text-bold required-label">{t("Campaign Budget (SAR)")}</div>
                        <div>
                          <QInput
                          id={`${ss.id}_campaignBudget`}
                          onFocus={(e:any)=>{
                            // @ts-ignore
                            document.getElementById(`${ss.id}_campaignBudget`)?.select();
                          }}
                            disabled={shouldDisable(ss)}
                            placeholder={t("Type here")}
                            value={ss?.metricData?.campaignBudget ?? 0}
                            size="small"
                            type="number"
                            onChange={(e:any)=>{
                              handleServiceMetricDataChange(ss.id,"campaignBudget",e.target.value)
                            }}
                          />
                          <small>{t("impressions_clicks_helper",{impressions:Number(ss.impressionsPerDay).toLocaleString(),clicks:Number(Math.ceil(ss.impressionsPerDay*0.01)).toLocaleString()})}</small>
                        </div>
                      </Col>
                      <Col md={24} className={"mt-10"}>
                        <div className="text-bold">{t("Daily Budget (SAR)")}</div>
                        <div>
                          <QInput
                          id={`${ss.id}_dailyBudget`}
                          onFocus={(e:any)=>{
                            // @ts-ignore
                            document.getElementById(`${ss.id}_dailyBudget`)?.select();
                          }}
                          disabled={shouldDisable(ss)}
                          placeholder={t("Type here")}
                            value={ss?.metricData?.dailyBudget ?? 0}
                            size="small"
                            type="number"
                            onChange={(e:any)=>{
                              handleServiceMetricDataChange(ss.id,"dailyBudget",e.target.value)
                            }}
                          />
                          {Number(ss?.metricData?.dailyBudget) > Number(ss?.metricData?.campaignBudget) && <div className="text-red text-sm">{t("Daily budget can't exceed campaign's budget")}</div>} 
                           <small>{t("Having a daily budget will divide your campaign into a number of days according to average impressions and clicks for this ad-space")}</small>
                        </div>

                      </Col>
                      {/* <Col md={24} className={"mt-10"}>
                        <div className="text-bold">{t("Min Duration (Days)")}</div>
                        <div>
                          <QInput
                          disabled={shouldDisable(ss)}
                          placeholder={t("Type here")}
                            value={ss?.metricData?.minDuration ?? 0}
                            size="small"
                            type="number"
                            onChange={(e:any)=>{
                              handleServiceMetricDataChange(ss.id,"minDuration",e.target.value)
                            }}
                          />
                        </div>
                      </Col> */}
                      <Col md={24} className={"mt-10"}>
                        <div className="text-italic">
                          {getBudgetFeedbackLabel(ss)}
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
              {/* {ss.status === "REJECTED" && ss.rejectionReason && (
                <>
                  <div className="mt-5">
                    <QAlert
                      style={{
                        padding: "5px",
                        margin: "0px",
                        fontSize: "14px",
                      }}
                      message={ss.rejectionReason}
                      type="error"
                      className="centered"
                    />
                  </div>
                </>
              )} */}
            </Col>
            {ss.durationType !== "Per Metric" && <Col md={12} xs={24} className="text-right">
              <QInput
                value={ss.quantity ?? 0}
                onChange={(e) => handleQuantityChange(ss.id, e.target.value)}
                type="number"
                size="small"
                placeholder={t("QTY")}
                style={{ width: "70px", textAlign: "center" }}
                disabled={shouldDisable(ss)}
                // disabled={["ACCEPTED", "PENDING"].includes(ss.status)}
              />
              <div>
                <label className="text-size-14">
                  <b>{renderRoundedNumber(ss.total, CURRENCY.SAR)}</b>
                </label>
              </div>
            </Col>} 
            {index < selectedServicesState.length - 1 && <Divider></Divider>}
          </Row>
        );
      })}
      {!lodash.isEmpty(selectedServicesState) && (
        <>
          <Divider></Divider>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Services Total")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>
                  {renderRoundedNumber(cartState.servicesTotal, CURRENCY.SAR)}
                </b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Management Fee")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>
                  {renderRoundedNumber(cartState.managementFee, CURRENCY.SAR)}
                </b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Discount")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>{renderRoundedNumber(cartState.discount, CURRENCY.SAR)}</b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Subtotal")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>{renderRoundedNumber(cartState.subTotal, CURRENCY.SAR)}</b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Taxes (15%)")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>{renderRoundedNumber(cartState.totalTax, CURRENCY.SAR)}</b>
              </label>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={15} xs={24}>
              <label>{t("Total")}</label>
            </Col>
            <Col md={9} xs={24} className="text-right">
              <label className="text-size-14">
                <b>{renderRoundedNumber(cartState.cartTotal, CURRENCY.SAR)}</b>
              </label>
            </Col>
          </Row>
        </>
      )}

      {showViewQuotation && (
        <a
          href={`${getQuotationURL()}`}
          target="_blank"
          className="ant-btn qu-button full-width justify-center qu-button-soft mt-20 ant-btn-primary"
        >
          {t("View Quotation")}
        </a>
      )}

      {/* confirmation of removal of service */}
      <DialogModal
        modalOpen={serviceRemoveConfirm}
        question={t(`Are you sure?`)}
        description={t(`This service will be removed from the cart`)}
        submitButtonText={t(`cancel`)}
        cancelButtonText={t(`confirm`)}
        onClose={() => {
          setServiceForRemoval({});
          setServiceRemoveConfirm(false);
        }}
        onProceed={() => {
          handleServiceRemove(serviceForRemoval.id);
          setServiceRemoveConfirm(false);
        }}
        revert
      />
    </div>
  );
};

export default React.memo(V3CartComponent);
