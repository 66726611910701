import React, { Fragment, ReactElement, useState } from 'react'
import TestahelBriefCartSummary from '../../modules/client/testahelBox/containers/components/TestahelBrief.CartSummary'
import { IBriefResponse, ICartList } from '../../modules/client/testahelBox/testahelBox.types'
import { Col, Row } from 'quantum_components'
import TestahelBriefViewSummary from '../../modules/client/testahelBox/containers/components/TestahelBrief.View.Summary'
import { isMobile } from 'react-device-detect'
import TestahelBriefSwitch from '../../modules/client/testahelBox/containers/components/TestahelBrief.Switch'
import TestahelServiceCard from '../../modules/client/testahelBox/containers/components/TestahelBrief.Service'
import {
  BRIEF_PRINT_ID,
  BRIEF_STEPS,
  BRIEF_TYPES,
  DISTRIBUTION_TYPE,
  SERVICE_NAMES_FOR_TABLE,
} from '../../constants/testahel_brief'
import TestahelBriefProduct from '../../modules/client/testahelBox/containers/components/TestahelBrief.Product'
import TestahelBriefMedia from '../../modules/client/testahelBox/containers/components/TestahelBrief.Media'
import TestahelBriefTargeting from '../../modules/client/testahelBox/containers/components/TestahelBrief.Targeting'
import TestahelBriefAdditional from '../../modules/client/testahelBox/containers/components/TestahelBrief.Additional'
import { canEditBrief, canPartiallyEditBrief } from '../../helpers/rules'

interface IProps {
  briefView: IBriefResponse
  cart: ICartList
  setCurrentStep: (step: BRIEF_STEPS) => void
  openPartner: (id: number) => void
  rejection?: boolean
}

export default function TestahelBriefEditSummaryComponent({
  briefView,
  cart,
  setCurrentStep,
  openPartner,
  rejection,
}: IProps): ReactElement {
  const [showPartner, setShowPartner] = useState(false)

  const changeSwitch = (value: any) => setShowPartner(value.target.value)
  const isProductSample = briefView.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isCustomizeBox = briefView.briefTypes?.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
  const isMediaBuying = briefView.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
  const isConsumerResearch = briefView.briefTypes?.includes(BRIEF_TYPES.CONSUMER_RESEARCH)

  const distributionItem =
    briefView.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
      ? SERVICE_NAMES_FOR_TABLE.INSERT
      : SERVICE_NAMES_FOR_TABLE.PRODUCT

  const hideBriefEdit = !canEditBrief(briefView.status)
  const hideCartEdit = !rejection && !canPartiallyEditBrief(briefView.status)

  return isMobile ? (
    <div id={BRIEF_PRINT_ID}>
      <div data-html2canvas-ignore>
        <TestahelBriefSwitch onChange={changeSwitch} value={showPartner} />
      </div>
      <div className={`${showPartner ? 'hidden-block' : 'visible-block'}`} id="ServiceCard">
        <Fragment>
          <TestahelServiceCard
            isProductSample={isProductSample}
            isCustomizeBox={isCustomizeBox}
            isMediaBuying={isMediaBuying}
            isConsumerResearch={isConsumerResearch}
            distributionItem={distributionItem}
            onEdit={() => setCurrentStep(BRIEF_STEPS.TYPE)}
            viewMode={hideBriefEdit}
          />
          <TestahelBriefProduct
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.PRODUCT)}
            viewMode={hideBriefEdit}
          />
          <TestahelBriefMedia
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.MEDIA_BUY)}
            viewMode={hideBriefEdit}
          />
          <TestahelBriefTargeting
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.TARGETING)}
            viewMode={hideBriefEdit}
          />
          <TestahelBriefAdditional
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.SERVICES)}
            viewMode={hideBriefEdit}
          />
        </Fragment>
      </div>
      <div className={`${showPartner ? 'visible-block' : 'hidden-block'} `}>
        <TestahelBriefCartSummary
          setCurrentStep={setCurrentStep}
          onEdit={openPartner}
          cart={cart}
          viewMode={hideCartEdit}
        />
      </div>
    </div>
  ) : (
    <Row gutter={20} className="pt-20 mb-30" id={BRIEF_PRINT_ID}>
      <Col span={16}>
        <TestahelBriefViewSummary briefView={briefView} setCurrentStep={setCurrentStep} viewMode={hideBriefEdit} />
      </Col>
      <Col span={8}>
        <TestahelBriefCartSummary
          briefView={briefView}
          setCurrentStep={setCurrentStep}
          onEdit={openPartner}
          cart={cart}
          viewMode={hideCartEdit}
        />
      </Col>
    </Row>
  )
}
