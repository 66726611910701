import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { debounce, pick } from 'lodash'
import { Space, Select } from 'antd'
import {
  Col,
  Form,
  InfoCard,
  PublicProfileField,
  QCheckbox,
  QInput,
  QRadio,
  QSelect,
  QSelectItem,
  Radio,
  Row,
} from 'quantum_components'
import MediaTypesSwitcher from '../../components/MediaTypesSwitcher'

import { ReactComponent as CloseIcon } from 'src/assets/icons/close-icon.svg'
import { actions } from '../../../partnerProfile.module'
import { useReduxDispatch } from 'src/helpers'
import { getForm, getPartnerProfileSettings } from '../../../profile.selectors'

import '../../../styles.scss'

import { ReactComponent as HomeBanner } from 'src/assets/icons/media-types/home-page-banners2.svg'
import { ReactComponent as BrandStore } from 'src/assets/icons/media-types/brand-store2.svg'
import { ReactComponent as FreeDelivery } from 'src/assets/icons/media-types/free-delivery2.svg'
import { ReactComponent as OnlineSurvey } from 'src/assets/icons/media-types/online-survey2.svg'
import { ReactComponent as OohMediaPartnership } from 'src/assets/icons/media-types/ooh-media-partnership2.svg'
import { ReactComponent as PopupWindow } from 'src/assets/icons/media-types/popup-window2.svg'
import { ReactComponent as PriceOffPromo } from 'src/assets/icons/media-types/price-off-promo2.svg'
import { ReactComponent as PushNotifications } from 'src/assets/icons/media-types/push-notifications2.svg'
import { ReactComponent as Feed } from 'src/assets/icons/media-types/feed2.svg'
import { ReactComponent as SkuUplifting } from 'src/assets/icons/media-types/sku-uplifting2.svg'
import { ReactComponent as CashbackOffers } from 'src/assets/icons/media-types/cashback-offers2.svg'
import { ReactComponent as HomeBannerGray } from 'src/assets/icons/media-types/home-page-banners.svg'
import { ReactComponent as BrandStoreGray } from 'src/assets/icons/media-types/brand-store.svg'
import { ReactComponent as FreeDeliveryGray } from 'src/assets/icons/media-types/free-delivery.svg'
import { ReactComponent as OnlineSurveyGray } from 'src/assets/icons/media-types/online-survey.svg'
import { ReactComponent as OohMediaPartnershipGray } from 'src/assets/icons/media-types/ooh-media-partnership.svg'
import { ReactComponent as PopupWindowGray } from 'src/assets/icons/media-types/popup-window.svg'
import { ReactComponent as PriceOffPromoGray } from 'src/assets/icons/media-types/price-off-promo.svg'
import { ReactComponent as PushNotificationsGray } from 'src/assets/icons/media-types/push-notifications.svg'
import { ReactComponent as SkuUpliftingGray } from 'src/assets/icons/media-types/sku-uplifting.svg'
import { ReactComponent as CashbackOffersGray } from 'src/assets/icons/media-types/cashback-offers.svg'
import { DECIMAL_DIGIT, MEDIA_TYPES } from 'src/constants/brief'
import {
  AVAILABLE_SERVICES_VALUES,
  PARTNER_STEPS,
  AVAILABLE_SERVICES,
  ADVERTISEMENT_LANGUAGES,
  PRICING_MODEL,
  DynamicPricingTypes,
  DynamicPricesMainTypes,
  DynamicPricingTypeLabels,
} from 'src/constants/profile'
import { REGEXES } from 'src/constants'
import LocationSearchInput from 'src/modules/client/clientProfile/containers/components/LocationSearchInput'
import { IOptionForSelect } from 'src/modules/brief/brief.types'

interface IProps {
  setError: (stepNmae: string, value: boolean) => void
  isViewOnly?: boolean
}

export const StepFourContainer: FC<IProps> = ({ setError, isViewOnly }) => {
  const [formRef] = Form.useForm()
  const dispatch = useReduxDispatch()
  const { Option, OptGroup } = Select
  const settings = useSelector(getPartnerProfileSettings)
  const partnerForm = useSelector(getForm)
  const [modalState, setModalState] = useState({ visible: false, name: '', isChecked: false })
  const [dynamicSamplePriceTypesOptions, setDynamicSamplePriceTypesOptions] = useState<IOptionForSelect[]>([])
  const stepFields = pick(partnerForm, Object.values(AVAILABLE_SERVICES_VALUES))
  const initialValues = {
    ...stepFields,
    pricePerSample: partnerForm.pricePerSample ? (+partnerForm.pricePerSample).toFixed(DECIMAL_DIGIT) : 0,
    pricePerInsert: partnerForm.pricePerInsert ? (+partnerForm.pricePerInsert).toFixed(DECIMAL_DIGIT) : 0,
    warehouseAddresses: partnerForm.warehouseAddresses?.length ? partnerForm.warehouseAddresses : [''],
    mediaBuyTypes: partnerForm.mediaBuyTypes ? partnerForm.mediaBuyTypes : [],
  }

  const MEDIA_TYPES_IMAGE: { [key: string]: ReactElement } = {
    [MEDIA_TYPES.HOME_BANNERS]: <HomeBanner />,
    [MEDIA_TYPES.BRAND_STORE]: <BrandStore />,
    [MEDIA_TYPES.FREE_DELIVERY]: <FreeDelivery />,
    [MEDIA_TYPES.SURVEY]: <OnlineSurvey />,
    [MEDIA_TYPES.SOCIAL_MEDIA]: <OohMediaPartnership />,
    [MEDIA_TYPES.POPUP_WINDOW]: <PopupWindow />,
    [MEDIA_TYPES.PRICE_OFF_PROMO]: <PriceOffPromo />,
    [MEDIA_TYPES.PUSH_NOTIFICATIONS]: <PushNotifications />,
    [MEDIA_TYPES.FEED]: <Feed />,
    [MEDIA_TYPES.SKU]: <SkuUplifting />,
    [MEDIA_TYPES.CASHBACK]: <CashbackOffers />,
  }

  const MEDIA_TYPES_IMAGE_GRAY: { [key: string]: ReactElement } = {
    [MEDIA_TYPES.HOME_BANNERS]: <HomeBannerGray />,
    [MEDIA_TYPES.BRAND_STORE]: <BrandStoreGray />,
    [MEDIA_TYPES.FREE_DELIVERY]: <FreeDeliveryGray />,
    [MEDIA_TYPES.SURVEY]: <OnlineSurveyGray />,
    [MEDIA_TYPES.SOCIAL_MEDIA]: <OohMediaPartnershipGray />,
    [MEDIA_TYPES.POPUP_WINDOW]: <PopupWindowGray />,
    [MEDIA_TYPES.PRICE_OFF_PROMO]: <PriceOffPromoGray />,
    [MEDIA_TYPES.PUSH_NOTIFICATIONS]: <PushNotificationsGray />,
    [MEDIA_TYPES.FEED]: <PushNotificationsGray />,
    [MEDIA_TYPES.SKU]: <SkuUpliftingGray />,
    [MEDIA_TYPES.CASHBACK]: <CashbackOffersGray />,
  }

  const handleSelectItem = (name: string) => {
    let isChecked = !!initialValues.mediaBuyTypes.find(abc => abc.name === name)
    setModalState({ visible: true, name, isChecked })
  }

  const handleOnFinishModal = (values: any, shouldDelete?: boolean) => {
    values.name = modalState.name
    let newMediaTypes: any[] = []

    if (shouldDelete) {
      newMediaTypes = initialValues.mediaBuyTypes.filter(f => f.name !== values.name)
    } else {
      newMediaTypes = [...partnerForm.mediaBuyTypes]
      if (!Array.isArray(values)) {
        newMediaTypes = newMediaTypes.find(e => e.name === values.name)
          ? newMediaTypes.map((element: any) =>
              element.name === values.name
                ? {
                    ...values,
                    previewImage: values.previewImage ? values.previewImage : undefined,
                    // previewImage: element.previewImage
                  }
                : element
            )
          : [...newMediaTypes, values]
      } else {
        values.forEach((t: any) => {
          const idx = newMediaTypes.findIndex(({ subName }) => subName === t.subName)
          if (idx !== -1) {
            newMediaTypes[idx] = {
              ...newMediaTypes[idx],
              ...t,
              previewImage: t.previewImage ? t.previewImage : undefined,
              // previewImage: newMediaTypes[idx].previewImage,
            }
          } else {
            newMediaTypes.push(t)
          }
        })

        if (values.length <= 1) {
          const val = values[0] || { name: modalState.name }
          newMediaTypes = newMediaTypes.filter(
            ty => (ty.name === val.name && ty.subName === val.subName) || ty.name !== val.name
          )
        } else {
          newMediaTypes = newMediaTypes.filter(
            ty =>
              (values.map(s => s.subName).includes(ty.subName) && ty.name === values[0].name) ||
              ty.name !== values[0].name
          )
        }
      }
    }

    setModalState({ visible: false, name: '', isChecked: false })
    formRef.setFieldsValue({ ...formRef.getFieldsValue(), mediaBuyTypes: newMediaTypes })
    // @ts-ignore
    onValuesChange({ mediaBuyTypes: newMediaTypes }, { ...formRef.getFieldsValue() })
  }

  const renderMediaTypes = () => {
    return settings.mediaTypes.map((elem: string, index: number) => {
      let isChecked = !!initialValues.mediaBuyTypes.find(abc => abc.name === elem)
      return (
        <Col xs={8} key={`col${index}`}>
          <QSelectItem
            key={`select${index}`}
            className={elem.length > 16 ? 'mb-16 fs-12 height146' : 'mb-16 height146'}
            label={elem}
            checked={isChecked}
            cardImage={isChecked ? MEDIA_TYPES_IMAGE[elem] : MEDIA_TYPES_IMAGE_GRAY[elem]}
            onChange={() => handleSelectItem(elem)}
          />
        </Col>
      )
    })
  }

  const onValuesChange = (changedValue: typeof initialValues, allValues: typeof initialValues) => {
    const [key, value] = Object.entries(changedValue)[0]
    if (key === 'isMediaBuy' && !value) {
      formRef.setFieldsValue({
        mediaBuyTypes: [],
        impressionRate: null,
        conversionRate: null,
        reachRate: null,
        advertisementLanguage: [],
        mbPricingModel: PRICING_MODEL.DURATION,
      })
    } else {
      if (formRef.getFieldValue('impressionRate') === '') formRef.setFieldsValue({ impressionRate: null })
      if (formRef.getFieldValue('conversionRate') === '') formRef.setFieldsValue({ conversionRate: null })
      if (formRef.getFieldValue('reachRate') === '') formRef.setFieldsValue({ reachRate: null })
    }

    if (key === 'isSampleDistribution' && !value) {
      formRef.setFieldsValue({ pricePerSample: null })
    }

    if (key === 'isInsertDistribution' && !value) {
      formRef.setFieldsValue({ pricePerInsert: null })
    }

    dispatch(actions.setForm({ ...formRef.getFieldsValue() }))
  }

  const delayedChange = useCallback(
    //@ts-ignore
    debounce((c, a) => onValuesChange(c, a), 500),
    []
  )

  const onSelectLocation = (field: string, fieldKey: number) => (value: string) => {
    const newWarehouse = formRef.getFieldValue(field)
    newWarehouse[fieldKey] = value
    formRef.setFieldsValue({ ...formRef.getFieldsValue(), warehouseAddresses: newWarehouse })
    // @ts-ignore
    onValuesChange({ warehouseAddresses: newWarehouse }, { ...formRef.getFieldsValue() })
  }

  const validateFields = async () => {
    try {
      await formRef.validateFields()
      setError(PARTNER_STEPS.AVAILABLE, false)
      await checkValidationsForDynamicPricing()
    } catch (error: any) {
      setError(PARTNER_STEPS.AVAILABLE, true)
    }
  }

  const checkValidationsForDynamicPricing = async () => {
    if (
      partnerForm.isSampleDistribution &&
      partnerForm.hasDynamicSamplePricing &&
      formRef.getFieldValue('dynamicSamplePriceTypes') &&
      formRef.getFieldValue('dynamicSamplePriceTypes').length
    ) {
      for (let i = 0; i < formRef.getFieldValue('dynamicSamplePriceTypes').length; i++) {
        if (!formRef.getFieldValue('dynamicSamplePrices')[formRef.getFieldValue('dynamicSamplePriceTypes')[i]]) {
          setError(PARTNER_STEPS.AVAILABLE, true)
          break
        } else {
          setError(PARTNER_STEPS.AVAILABLE, false)
        }
      }
    } else if (
      partnerForm.isSampleDistribution &&
      partnerForm.hasDynamicSamplePricing &&
      (!formRef.getFieldValue('dynamicSamplePriceTypes') || !formRef.getFieldValue('dynamicSamplePriceTypes').length)
    ) {
      setError(PARTNER_STEPS.AVAILABLE, true)
    }
  }

  useEffect(() => {
    validateFields()

    if (partnerForm.hasDynamicSamplePricing && partnerForm.isSampleDistribution) {
      const pricingOptions: IOptionForSelect[] = []
      if (partnerForm.hasDistributionTypePricing) {
        Object.entries(DynamicPricingTypes.Distribution).forEach(([key, value]) => {
          pricingOptions.push({
            label: value,
            value: key,
          })
        })
      } else {
        Object.entries(DynamicPricingTypes.SampleType).forEach(([key, value]) => {
          pricingOptions.push({
            label: value,
            value: key,
          })
        })
      }
      setDynamicSamplePriceTypesOptions(pricingOptions)
      if (!partnerForm.dynamicSamplePriceTypes) {
        formRef.setFieldsValue({ dynamicSamplePriceTypes: [] })
      }
    }
  }, [partnerForm])

  const handleChangeAdsLanguage = (adLanguage: string) => {
    const newForm = formRef.getFieldValue('advertisementLanguage') || []

    newForm.includes(adLanguage) ? newForm.splice(newForm.indexOf(adLanguage), 1) : newForm.push(adLanguage)

    formRef.setFieldsValue({ ...formRef.getFieldsValue(), advertisementLanguage: newForm })
    // @ts-ignore
    onValuesChange({ advertisementLanguage: newForm }, { ...formRef.getFieldsValue() })
  }

  return (
    <div className="fourth_content">
      <InfoCard headerLeft={<h3>Available Services</h3>}>
        <Form form={formRef} onValuesChange={delayedChange} initialValues={initialValues}>
          <PublicProfileField
            description={{
              title: 'Do you offer Sample Distribution?',
            }}
            input={
              <Form.Item name="isSampleDistribution">
                <Radio.Group disabled={isViewOnly}>
                  <QRadio value={true}>Yes</QRadio>
                  <QRadio value={false}>No</QRadio>
                </Radio.Group>
              </Form.Item>
            }
          />
          {partnerForm.hasDynamicSamplePricing && partnerForm.isSampleDistribution ? (
            <>
              <h3 className="dynamic-sample-pricing-label">Dynamic Sample Pricing</h3>
              <PublicProfileField
                description={{
                  title: '* Select Price Type',
                }}
                input={
                  <Form.Item
                    name="dynamicSamplePriceTypes"
                    rules={[
                      {
                        required: true,
                        message: 'Dynamic Sample Pricing is required',
                      },
                    ]}
                    validateTrigger={['onBlur', 'onChange']}
                  >
                    <QSelect
                      style={{ width: '100%' }}
                      mode="multiple"
                      size="large"
                      placeholder="Select Price Type"
                      disabled={isViewOnly}
                      showArrow
                    >
                      {
                        <OptGroup
                          key={
                            partnerForm.hasDistributionTypePricing
                              ? DynamicPricesMainTypes.Distribution
                              : DynamicPricesMainTypes.SampleType
                          }
                          label={
                            partnerForm.hasDistributionTypePricing
                              ? DynamicPricesMainTypes.Distribution
                              : DynamicPricesMainTypes.SampleType
                          }
                        >
                          {dynamicSamplePriceTypesOptions.map((ele, index) => {
                            return (
                              <Option key={ele.value} value={ele.value}>
                                {ele.label}
                              </Option>
                            )
                          })}
                        </OptGroup>
                      }
                    </QSelect>
                  </Form.Item>
                }
              />
              {formRef.getFieldValue('dynamicSamplePriceTypes') &&
                formRef.getFieldValue('dynamicSamplePriceTypes').map((ele: any) => (
                  <PublicProfileField
                    key={ele}
                    description={{
                      title: (
                        <>
                          * Price Per Sample{' '}
                          <span className="dynamic-sample-price-input-label">({DynamicPricingTypeLabels[ele]})</span>
                        </>
                      ),
                    }}
                    input={
                      <Form.Item
                        name={['dynamicSamplePrices', ele]}
                        rules={[
                          {
                            pattern: REGEXES.AMOUNT_REGEX,
                            message: 'Invalid amount!',
                          },
                        ]}
                      >
                        <QInput
                          className="pricePer"
                          placeholder="Price per sample"
                          size="large"
                          type="number"
                          disabled={isViewOnly}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    }
                  />
                ))}
            </>
          ) : (
            initialValues.isSampleDistribution && (
              <PublicProfileField
                description={{
                  title: 'Price per Sample',
                }}
                input={
                  <Form.Item
                    name="pricePerSample"
                    rules={[
                      {
                        message: 'Invalid amount!',
                        pattern: REGEXES.AMOUNT_REGEX,
                      },
                    ]}
                  >
                    <QInput
                      className="pricePer"
                      placeholder="Price per sample"
                      size="large"
                      type="number"
                      disabled={isViewOnly}
                    />
                  </Form.Item>
                }
              />
            )
          )}

          <PublicProfileField
            description={{
              title: 'Do you offer Insert Distribution?',
            }}
            input={
              <Form.Item name="isInsertDistribution">
                <Radio.Group disabled={isViewOnly}>
                  <QRadio value={true}>Yes</QRadio>
                  <QRadio value={false}>No</QRadio>
                </Radio.Group>
              </Form.Item>
            }
          />

          {initialValues.isInsertDistribution && (
            <PublicProfileField
              description={{
                title: 'Price per Insert',
              }}
              input={
                <Form.Item
                  name="pricePerInsert"
                  rules={[
                    {
                      message: 'Invalid amount!',
                      pattern: REGEXES.AMOUNT_REGEX,
                    },
                  ]}
                >
                  <QInput
                    className="pricePer"
                    placeholder="Price per insert"
                    size="large"
                    type="number"
                    disabled={isViewOnly}
                  />
                </Form.Item>
              }
            />
          )}

          <Form.List name="warehouseAddresses">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <PublicProfileField
                      key={field.key}
                      description={{
                        title: `Warehouse Address ${index + 1}`,
                      }}
                      input={
                        <div className="PPField__inputList">
                          <div className="input">
                            <Form.Item {...field}>
                              <LocationSearchInput
                                onSelectHandler={onSelectLocation(
                                  AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.WAREHOUSE_ADDRESS],
                                  field.name
                                )}
                                isViewOnly={isViewOnly}
                              />
                            </Form.Item>
                            {(fields.length > 1 || !isViewOnly) && (
                              <CloseIcon className="closeIcon" onClick={() => remove(field.name)} />
                            )}
                          </div>
                        </div>
                      }
                    />
                  ))}
                  {!isViewOnly && (
                    <PublicProfileField
                      description={{}}
                      input={
                        <div className="PPField__inputAdd" onClick={() => add()}>
                          Add Another Warehouse
                        </div>
                      }
                    />
                  )}
                </>
              )
            }}
          </Form.List>
          {initialValues.isMediaBuy && <hr className="form__hr" />}
          <PublicProfileField
            description={{
              title: 'Do you offer Media Buying?',
            }}
            input={
              <Form.Item name="isMediaBuy">
                <Radio.Group disabled={isViewOnly}>
                  <QRadio value={true}>Yes</QRadio>
                  <QRadio value={false}>No</QRadio>
                </Radio.Group>
              </Form.Item>
            }
          />

          {initialValues.isMediaBuy && (
            <>
              <PublicProfileField
                description={{
                  title: 'Which price model do you follow?',
                }}
                input={
                  <Form.Item name="mbPricingModel">
                    {/* <Radio.Group disabled={isViewOnly}> */}
                    <Space direction="vertical">
                      <QRadio
                        value="duration"
                        checked={formRef.getFieldValue('mbPricingModel') === PRICING_MODEL.DURATION}
                        disabled={isViewOnly}
                        onChange={() => {
                          formRef.setFieldsValue({
                            ...formRef,
                            impressionRate: null,
                            conversionRate: null,
                            reachRate: null,
                          })
                        }}
                      >
                        Duration/activity model
                      </QRadio>
                      <span className="ml-30">(cost per day/week/month or cost per activity)</span>
                      <QRadio
                        value="metrics"
                        checked={formRef.getFieldValue('mbPricingModel') === PRICING_MODEL.METRICS}
                        disabled={isViewOnly}
                        isNewBadge={true}
                      >
                        Metrics model
                      </QRadio>
                      <span className="ml-30">(cost per impression/click/reach)</span>
                    </Space>
                    {/* </Radio.Group> */}
                  </Form.Item>
                }
              />
              {formRef.getFieldValue('mbPricingModel') === PRICING_MODEL.METRICS && (
                <>
                  <PublicProfileField
                    description={{
                      title: 'Impression rate (%)',
                      subTitle: 'Ratio of total ad views against total online traffic.',
                    }}
                    input={
                      <Form.Item
                        name="impressionRate"
                        rules={[
                          {
                            message: 'Invalid percentage!',
                            pattern: REGEXES.PERCENTAGE_NUMBER,
                          },
                        ]}
                      >
                        <QInput
                          className="pricePer"
                          placeholder="Impression rate (%)"
                          size="large"
                          type="number"
                          disabled={isViewOnly}
                        />
                      </Form.Item>
                    }
                  />
                  <PublicProfileField
                    description={{
                      title: 'Conversion rate (%)',
                      subTitle: 'Ratio of total clicks against total online traffic.',
                    }}
                    input={
                      <Form.Item
                        name="conversionRate"
                        rules={[
                          {
                            message: 'Invalid percentage!',
                            pattern: REGEXES.PERCENTAGE_NUMBER,
                          },
                        ]}
                      >
                        <QInput
                          className="pricePer"
                          placeholder="Conversion rate (%)"
                          size="large"
                          type="number"
                          disabled={isViewOnly}
                        />
                      </Form.Item>
                    }
                  />
                  <PublicProfileField
                    description={{
                      title: 'Reach rate (%)',
                      subTitle: 'Ratio of total unique impressions against total online traffic.',
                    }}
                    input={
                      <Form.Item
                        name="reachRate"
                        rules={[
                          {
                            message: 'Invalid percentage!',
                            pattern: REGEXES.PERCENTAGE_NUMBER,
                          },
                        ]}
                      >
                        <QInput
                          className="pricePer"
                          placeholder="Reach rate (%)"
                          size="large"
                          type="number"
                          disabled={isViewOnly}
                        />
                      </Form.Item>
                    }
                  />
                </>
              )}
              <PublicProfileField
                description={{
                  title: 'Which languages are your advertisements in?',
                }}
                input={
                  <Row gutter={8} justify="start" align="top">
                    <Col>
                      <Form.Item className="mb-0" name="advertisementLanguage" key={ADVERTISEMENT_LANGUAGES.ARABIC}>
                        <QCheckbox
                          checked={initialValues.advertisementLanguage?.includes(ADVERTISEMENT_LANGUAGES.ARABIC)}
                          className="test-class"
                          onChange={() => handleChangeAdsLanguage(ADVERTISEMENT_LANGUAGES.ARABIC)}
                          disabled={isViewOnly}
                        >
                          Arabic
                        </QCheckbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item className="mb-0" name="advertisementLanguage" key={ADVERTISEMENT_LANGUAGES.ENGLISH}>
                        <QCheckbox
                          checked={initialValues.advertisementLanguage?.includes(ADVERTISEMENT_LANGUAGES.ENGLISH)}
                          className="test-class"
                          onChange={() => handleChangeAdsLanguage(ADVERTISEMENT_LANGUAGES.ENGLISH)}
                          disabled={isViewOnly}
                        >
                          English
                        </QCheckbox>
                      </Form.Item>
                    </Col>
                  </Row>
                }
              />
              <PublicProfileField
                description={{
                  title: 'What media types do you offer?',
                  subTitle:
                    'Clients can discover you based on the media types you have available. ' +
                    'The more media types made available, the more likelihood of clients finding you you and purchasing services.',
                }}
                input={
                  <Row gutter={8} justify="center" align="middle">
                    {renderMediaTypes()}
                  </Row>
                }
              />
            </>
          )}

          {!isViewOnly && (
            <Form.Item noStyle name="mediaBuyTypes">
              <MediaTypesSwitcher
                onFinish={handleOnFinishModal}
                isChecked={modalState.isChecked}
                nameMediaType={modalState.name}
                visible={modalState.visible}
                onCancel={() => setModalState({ visible: false, name: '', isChecked: false })}
              />
            </Form.Item>
          )}
        </Form>
      </InfoCard>
    </div>
  )
}
