import get from 'lodash/get'

import { IState } from './resetPassword.types'
import * as CONSTANTS from './resetPassword.constants'
import IAction from 'src/interfaces/IAction'

const initialState: IState = {
  email: '',
  isLoading: false,
  resetPasswordError: '',
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordError: initialState.resetPasswordError,
        isLoading: true,
      }
    case CONSTANTS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        email: get(action, 'payload.email', ''),
        isLoading: false,
      }
    case CONSTANTS.RESET_PASSWORD_FAILURE:
      return { ...state, resetPasswordError: action.error, isLoading: false }
    default:
      return state
  }
}
