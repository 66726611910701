import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BRIEF_STEPS,
  BRIEF_TYPES,
  DISTRIBUTION_TYPE,
  MEDIA_GROUP_TYPE,
  SERVICE_NAMES_FOR_TABLE,
  PROMOTION_TYPE,
} from "../../../../constants/brief";
import { Descriptions } from "antd";
import moment from "moment";
import { FORMATS, LOCALIZATION_LANGUAGES } from "../../../../constants";
import { BriefSummary, IconsCommon } from "quantum_components";
import { IBriefResponse, IUploadPartner } from "../../brief.types";
import { MediaTypeIcon } from "./Brief.MediaTypeCard";
import { COLORS } from "../../../../constants/colors";
import { getFileNameFromUrl } from "../../../../utils";
import { getBase64 } from "../../../../utils/getBase64";
import { getLocaleCost } from "src/helpers/currency";
import { BRIEF_ADVERTISEMENT_LANGUAGES } from "src/constants/brief";
import { useSelector } from "react-redux";
import { getBriefSettings } from "../../brief.selectors";
import { filter } from "lodash";
import lodash from "lodash";

const checkIcon = <IconsCommon.IconCheck color={COLORS.LIGHT_GREEN} />;

const uncheckIcon = <IconsCommon.IconStepIncomplete />;

interface IProps {
  briefView: IBriefResponse;
  setCurrentStep: (step: BRIEF_STEPS) => void;
  hideTitle?: boolean;
  title?: string;
  subtitle?: string;
  viewMode?: boolean;
  partnerQuantity?: number;
  requestType?: MEDIA_GROUP_TYPE;
  partnerIds?: number[];
}

export default function BriefViewSummary({
  briefView,
  setCurrentStep,
  hideTitle,
  title,
  subtitle,
  viewMode,
  partnerQuantity,
  requestType,
  partnerIds,
}: IProps): ReactElement {
  const isProductSample = briefView.briefTypes?.includes(
    BRIEF_TYPES.PRODUCT_SAMPLE
  );
  const isMediaBuying = briefView.briefTypes?.includes(
    BRIEF_TYPES.MEDIA_BUYING
  );
  const briefSettings = useSelector(getBriefSettings);

  const [productImage, setProductImage] = useState<string>("");
  const [serviceImage, setServiceImage] = useState<string>("");
  const [uploadMaterial, setUploadMaterial] = useState<IUploadPartner[]>([]);

  const { i18n } = useTranslation();
  const { t } = useTranslation(["translation", "translationCommon"]);

  const getCities = (cities: string[]) => {
    let settings = briefSettings?.countries[0].city;

    let cities_ = cities.map((city) => {
      let obj = lodash.first(lodash.filter(settings, (s) => s.name === city));
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC
        ? lodash.get(obj, "name_ar")
        : city;
    });

    return cities_.join(", ");
  };

  const getCountries = (countries: string[]) => {
    let settings = briefSettings?.countries;

    let countries_ = countries.map((country) => {
      let obj = lodash.first(
        lodash.filter(settings, (s) => s.name === country)
      );
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC
        ? lodash.get(obj, "name_ar")
        : country;
    });

    return countries_.join(", ");
  };

  const getAgeGroupNames = (ageGroups: string[]) => {
    let settings = briefSettings?.ageGroups;

    let ageGroups_ = ageGroups.map((ageGroup) => {
      let obj = lodash.first(
        lodash.filter(
          settings,
          (s: any) =>
            s.from + (s.to ? "-" + s.to + " " + s.name : "+ " + s.name) ===
            ageGroup
        )
      );
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC
        ? obj.from +
            (obj.to ? "-" + obj.to + " " + obj.name_ar : "+ " + obj.name_ar)
        : ageGroup;
    });

    return ageGroups_.join(
      i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? " ," : ", "
    );
  };

  const getProductImage = async () => {
    const productImageUrl = briefView.productImages
      ? briefView.productImages[0]
      : "";

    if (productImageUrl) {
      const image = await getBase64(productImageUrl);
      setProductImage(image);
    }

    const serviceImageUrl = briefView.serviceImages
      ? briefView.serviceImages[0]
      : "";

    if (serviceImageUrl) {
      const image = await getBase64(serviceImageUrl);
      setServiceImage(image);
    }
  };

  const getUploadsBase64 = async (upload: IUploadPartner) => {
    return Promise.resolve(getBase64(upload.imageTargetUrl));
  };

  const getUploadMaterial = async () => {
    const uploads = partnerIds
      ? briefView.uploadMaterial.filter((file: IUploadPartner) =>
          partnerIds.includes(file.partnerId)
        )
      : briefView.uploadMaterial;
    if (!uploads) return;

    const list = uploads.map((material: IUploadPartner) => {
      // getUploadsBase64(material).then((data: string) => {
      //   material.imageTargetUrl = data
      // })
      return material;
    });

    setUploadMaterial(list);
  };

  useEffect(() => {
    async function fetchImages() {
      await getProductImage();
      await getUploadMaterial();
    }
    fetchImages();
  }, [briefView]);

  const isRequestMediaBuy =
    requestType && requestType === MEDIA_GROUP_TYPE.MEDIA_BUY;
  const isRequestDistribution =
    requestType &&
    (requestType === MEDIA_GROUP_TYPE.PRINTED_INSERT ||
      requestType === MEDIA_GROUP_TYPE.PRODUCT_SAMPLE);

  const getTitle = () => {
    if (hideTitle) return "";
    if (title) return title;

    return t("brief.summary.title");
  };
  const distributionItem =
    briefView.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
      ? SERVICE_NAMES_FOR_TABLE.INSERT
      : SERVICE_NAMES_FOR_TABLE.PRODUCT;

  const serviceBlock = requestType ? (
    <ul className="BSUnit__list">
      <li className={"BSUnit__list-item"}>
        <i className="i">{checkIcon}</i>
        {requestType}
      </li>
    </ul>
  ) : (
    <ul className="BSUnit__list">
      <li className={`BSUnit__list-item ${!isProductSample && "uncheck"}`}>
        <i className="i">{isProductSample ? checkIcon : uncheckIcon}</i>
        {distributionItem}
      </li>
      <li className={`BSUnit__list-item ${!isMediaBuying && "uncheck"}`}>
        <i className="i">{isMediaBuying ? checkIcon : uncheckIcon}</i>
        Media Buying
      </li>
    </ul>
  );
  return (
    <BriefSummary title={getTitle()} subtitle={subtitle ? subtitle : ""}>
      <div className="BSUnit">
        <div className="BSUnit__heading">
          <h4 className="BSUnit__title">{t("brief.summary.Campaign_brief")}</h4>
          {productImage && (
            <div className="BSUnit__thumb">
              <img src={productImage} alt={briefView.name} />
            </div>
          )}
        </div>
        <div className="BSUnit__content">
          {!viewMode && (
            <span
              onClick={() => setCurrentStep(BRIEF_STEPS.PRODUCT)}
              className="BSUnit__edit"
              data-html2canvas-ignore
            >
              {t("common.edit", { ns: "translationCommon" })}
            </span>
          )}
          <div className="BSUnit__content-header">
            <h5 className="BSUnit__content-title">{briefView.campaignName}</h5>
            <h6 className="BSUnit__content-subTitle">{`${t(
              `brief.edit.step2.${String(
                briefView.campaignPromotionType
              ).toLowerCase()}`
            )}`}</h6>
          </div>
          {(!requestType || isRequestDistribution) && (
            <div className="BSUnit__descriptions">
              <Descriptions column={1}>
                {briefView.campaignPromotionType == PROMOTION_TYPE.BRAND && (
                  <Descriptions.Item label={t("brief.edit.step2.product_name")}>
                    {briefView.name ||
                      t("common.none", { ns: "translationCommon" })}
                  </Descriptions.Item>
                )}
                {briefView.campaignPromotionType == PROMOTION_TYPE.BRAND && (
                  <Descriptions.Item
                    label={t("brief.edit.step2.product_description")}
                  >
                    {briefView.productDescription ||
                      t("common.none", { ns: "translationCommon" })}
                  </Descriptions.Item>
                )}
                <Descriptions.Item
                  label={t("brief.summary.Desired_Start_Date")}
                >
                  {briefView.mediaStartDate
                    ? moment(briefView.mediaStartDate).format(
                        FORMATS.DATE_LL_FORMAT
                      )
                    : t("common.none", { ns: "translationCommon" })}
                </Descriptions.Item>
                <Descriptions.Item label={t("brief.summary.Instructions")}>
                  {briefView.instructions ||
                    t("common.none", { ns: "translationCommon" })}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
      </div>
      <Fragment>
        {!lodash.isEmpty(
          filter(
            briefSettings?.targetingToggles,
            (t) => t.control_toggle == true
          )
        ) && (
          <div className="BSUnit">
            <div className="BSUnit__heading">
              <h4 className="BSUnit__title">{t("brief.summary.Targeting")}</h4>
            </div>
            <div className="BSUnit__content">
              {!viewMode && (
                <span
                  onClick={() => setCurrentStep(BRIEF_STEPS.TARGETING)}
                  className="BSUnit__edit"
                  data-html2canvas-ignore
                >
                  {t("common.edit", { ns: "translationCommon" })}
                </span>
              )}
              <div className="BSUnit__descriptions">
                <Descriptions column={1}>
                  {briefView?.customTargets &&
                    Object.keys(briefView?.customTargets).map((key: any) => {
                      return (
                        <Descriptions.Item label={key}>
                          {/* @ts-ignore */}
                          {briefView?.customTargets[key]
                            ? briefView?.customTargets[key]?.join(", ")
                            : t("common.none", { ns: "translationCommon" })}
                        </Descriptions.Item>
                      );
                    })}

                  {/* {!lodash.isEmpty(
                    filter(
                      briefSettings?.targetingToggles,
                      (t) =>
                        t.control_name == "targeting_age_group" &&
                        t.control_toggle
                    )
                  ) && (
                    <Descriptions.Item label={t("brief.summary.Ages")}>
                      {briefView.targetAgeGroups
                        ? getAgeGroupNames(briefView?.targetAgeGroups)
                        : t("common.none", { ns: "translationCommon" })}
                    </Descriptions.Item>
                  )} */}

                  {/* {!lodash.isEmpty(
                    filter(
                      briefSettings?.targetingToggles,
                      (t) =>
                        t.control_name == "targeting_gender" && t.control_toggle
                    )
                  ) && (
                    <Descriptions.Item label={t("brief.summary.Gender")}>
                      {briefView.targetFocus?.length
                        ? t(
                            "brief.targeting.gender." +
                              lodash.snakeCase(
                                briefView.targetFocus?.join(", ")
                              ),
                            {
                              ns: "translationCommon",
                            }
                          )
                        : t("common.none", { ns: "translationCommon" })}
                    </Descriptions.Item>
                  )} */}
                  {/* {!lodash.isEmpty(
                    filter(
                      briefSettings?.targetingToggles,
                      (t) =>
                        t.control_name == "targeting_location" &&
                        t.control_toggle
                    )
                  ) && (
                    <>
                      <Descriptions.Item label={t("brief.summary.Countries")}>
                        {briefView.targetCountries
                          ? getCountries(briefView?.targetCountries)
                          : t("common.none", { ns: "translationCommon" })}
                      </Descriptions.Item>
                      <Descriptions.Item label={t("brief.summary.Cities")}>
                        {briefView?.targetCities
                          ? getCities(briefView?.targetCities)
                          : t("common.none", { ns: "translationCommon" })}
                      </Descriptions.Item>
                    </>
                  )} */}
                </Descriptions>
              </div>
            </div>
          </div>
        )}
        {((!requestType && isProductSample) || isRequestDistribution) && (
          <div className="BSUnit">
            <div className="BSUnit__heading">
              <h4 className="BSUnit__title">
                {t("brief.summary.Additional_Info")}
              </h4>
              <div className="BSUnit__thumb">
                {serviceImage && <img src={serviceImage} alt="" />}
              </div>
            </div>
            <div className="BSUnit__content">
              {!viewMode && (
                <span
                  onClick={() => setCurrentStep(BRIEF_STEPS.SERVICES)}
                  className="BSUnit__edit"
                  data-html2canvas-ignore
                >
                  {t("common.edit", { ns: "translationCommon" })}
                </span>
              )}
              <Descriptions column={2}>
                <Descriptions.Item label="Packaging Type">
                  {briefView.servicePackageType ||
                    t("common.none", { ns: "translationCommon" })}
                </Descriptions.Item>
                <Descriptions.Item label="Temperature Condition">
                  {briefView.serviceTemperature ||
                    t("common.none", { ns: "translationCommon" })}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Samples per Bundle"
                  style={{ verticalAlign: "baseline" }}
                >
                  {briefView.servicePackagingQuantity ||
                    t("common.none", { ns: "translationCommon" })}
                </Descriptions.Item>
                <Descriptions.Item label="Co-packaging needs">
                  {briefSettings?.copackingPrices?.filter(
                    (ele) => ele.id == briefView.copackingPriceId
                  ).length > 0
                    ? briefSettings?.copackingPrices?.filter(
                        (ele) => ele.id == briefView.copackingPriceId
                      )[0].name
                    : t("common.none", { ns: "translationCommon" })}
                </Descriptions.Item>
                <Descriptions.Item label="Printing needs">
                  {briefSettings?.printingPrices?.filter(
                    (ele) => ele.id == briefView.printingPriceId
                  ).length > 0
                    ? briefSettings?.printingPrices?.filter(
                        (ele) => ele.id == briefView.printingPriceId
                      )[0].name
                    : t("common.none", { ns: "translationCommon" })}
                </Descriptions.Item>
              </Descriptions>
              <ul className="BSUnit__list">
                <li
                  className={`BSUnit__list-item ${
                    !briefView.serviceIsLiquid && "uncheck"
                  }`}
                >
                  <i className="i">
                    {briefView.serviceIsLiquid ? checkIcon : uncheckIcon}
                  </i>
                  Sample is Liquid
                </li>
                <li
                  className={`BSUnit__list-item ${
                    !briefView.serviceIncludingCoupon && "uncheck"
                  }`}
                >
                  <i className="i">
                    {briefView.serviceIncludingCoupon ? checkIcon : uncheckIcon}
                  </i>
                  Include Coupon / Marketing Material
                </li>
                <li
                  className={`BSUnit__list-item ${
                    !briefView.serviceDesign && "uncheck"
                  }`}
                >
                  <i className="i">
                    {briefView.serviceDesign ? checkIcon : uncheckIcon}
                  </i>
                  Require Insert Design
                </li>
                <li
                  className={`BSUnit__list-item ${
                    !briefView.servicePackaging && "uncheck"
                  }`}
                >
                  <i className="i">
                    {briefView.servicePackaging ? checkIcon : uncheckIcon}
                  </i>
                  Require Co-Packing Services
                </li>
                <li
                  className={`BSUnit__list-item ${
                    !briefView.servicePrinting && "uncheck"
                  }`}
                >
                  <i className="i">
                    {briefView.servicePrinting ? checkIcon : uncheckIcon}
                  </i>
                  Require Printing
                </li>
                <li
                  className={`BSUnit__list-item ${
                    !briefView.hasFreeSample && "uncheck"
                  }`}
                >
                  <i className="i">
                    {briefView.hasFreeSample ? checkIcon : uncheckIcon}
                  </i>
                  Free Sample
                </li>
              </ul>
            </div>
          </div>
        )}
        {((!requestType && isMediaBuying) || isRequestMediaBuy) &&
          !lodash.isEmpty(uploadMaterial) && (
            <div className="BSUnit">
              <div className="BSUnit__heading">
                <h4 className="BSUnit__title">
                  {t("brief.summary.Media_Buying")}
                </h4>
              </div>
              <div className="BSUnit__content">
                {!viewMode && (
                  <span
                    onClick={() => setCurrentStep(BRIEF_STEPS.UPLOAD)}
                    className="BSUnit__edit"
                    data-html2canvas-ignore
                  >
                    {t("common.edit", { ns: "translationCommon" })}
                  </span>
                )}

                <div className="BSUnit__media">
                  {uploadMaterial.map((upload: any, i: number) => {
                    const name = upload.mediaSubType
                      ? upload.mediaSubType
                      : upload.mediaType;
                    return (
                      <div className="media-item" key={i}>
                        {i > 0 && <hr className="form__hr" />}
                        <div className="media-item-label">
                          {t(
                            "services.media-buy.types." +
                              lodash.snakeCase(name),
                            name
                          )}
                        </div>
                        <br />
                        <div className="BSUnit__descriptions">
                          {
                            <Descriptions column={1}>
                              {upload.link && (
                                <Descriptions.Item
                                  label={t("brief.summary.target_url")}
                                >
                                  <a
                                    href={upload.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {upload.link}
                                  </a>
                                </Descriptions.Item>
                              )}
                              {Object.entries(
                                BRIEF_ADVERTISEMENT_LANGUAGES
                              ).map(([key, value]) => (
                                <Fragment key={key}>
                                  {upload.adTitle[key] && (
                                    <Descriptions.Item
                                      label={t(
                                        "brief.summary.title_" +
                                          lodash.snakeCase(value)
                                      )}
                                    >
                                      {upload.adTitle[key]}
                                    </Descriptions.Item>
                                  )}
                                  {upload.adDescription[key] && (
                                    <Descriptions.Item
                                      label={t(
                                        "brief.summary.description_" +
                                          lodash.snakeCase(value)
                                      )}
                                    >
                                      {upload.adDescription[key]}
                                    </Descriptions.Item>
                                  )}
                                  {upload.imageTargetUrl[key] && (
                                    <Fragment>
                                      <div className="media-item-image">
                                        {upload.imageTargetUrl[key].includes(
                                          ".webm"
                                        ) ||
                                        upload.imageTargetUrl[key].includes(
                                          ".mp4"
                                        ) ||
                                        upload.imageTargetUrl[key].includes(
                                          ".ogg"
                                        ) ? (
                                          <video
                                            width="178"
                                            height="100"
                                            controls
                                          >
                                            <source
                                              src={upload.imageTargetUrl[key]}
                                              type={`video/${
                                                upload.imageTargetUrl[
                                                  key
                                                ].split(".")[
                                                  upload.imageTargetUrl[
                                                    key
                                                  ].split(".").length - 1
                                                ]
                                              }`}
                                            />
                                            Your browser does not support HTML
                                            video.
                                          </video>
                                        ) : (
                                          <img
                                            src={upload.imageTargetUrl[key]}
                                            alt={name}
                                          />
                                        )}
                                      </div>
                                      <div className="media-item-hint">
                                        {getFileNameFromUrl(
                                          upload.imageTargetUrl[key]
                                        )}
                                        <span>
                                          {upload.width}
                                          {upload.height &&
                                            `x ${upload.height}`}
                                        </span>
                                      </div>
                                    </Fragment>
                                  )}
                                </Fragment>
                              ))}
                            </Descriptions>
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
      </Fragment>
    </BriefSummary>
  );
}
