import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useReduxDispatch } from "src/helpers";
import * as actions from "../campaign.actions";
import * as briefActions from "../../brief/brief.actions";
import {
  QBackLink,
  Radio,
  Tabs,
  Row,
  Col,
  IconsCommon,
  QButton,
  Empty,
} from "quantum_components";
import { useSelector } from "react-redux";
import {
  getCampaignAnalyticsInfo,
  getActiveTab,
  getClientActiveTab,
  getCampaignDetails,
  getCampaignDetailsLoading,
} from "../campaign.selectors";
import SpinnerCenter from "../../../components/Spinner/spinner";
import { daysPastFromToday } from "src/helpers/datesHelper";
import moment from "moment";
import { FORMATS, PATHS } from "../../../constants";
import RequestTableComponent from "src/components/requestsTable/RequestTable.component";
import { requestsToTable } from "src/helpers/request";
import BriefViewMobileComponent from "../../../components/BriefView/BriefViewMobile.component";
import { TABS_NAMES } from "../../../constants/tabs";
import BriefViewDesktopComponent from "../../../components/BriefView/BriefViewDesktop.component";
import { getCurrency, getCurrentUserRole } from "../../core/core.selectors";
import { isMobile } from "react-device-detect";
import ROLES from "../../../constants/roles";
import { getForm } from "../../partner/partnerProfile/profile.selectors";
import { IRequest } from "../../partner/requests/request.types";
import {
  CAMPAIGN_PRINT_ANALYTICS_ID,
  campaignStatusesLabel,
} from "../../../constants/campaign";
import CampaignAnalytics from "../components/CampaignAnalytics";
import ClientSurveys from "../components/ClientSurveys";
import ClientGallery from "../components/ClientGallery";
import CampaignAnalyticsDistCharts from "../components/CampaignAnalyticsDistCharts";
import CampaignAnalyticsMediaBuy from "../components/CampaignAnalyticsMediaBuy";
import { CampaignAnalyticsTimeline } from "../components/CampaignAnalyticsTimeline";
import {
  getScreenPagePdf,
  saveScreenPageImage,
} from "../../../utils/getScreenPage";
import { calcDistributionQuantity } from "../../../helpers/cart";
import { COLORS } from "src/constants/colors";
import { ReactComponent as OpenIcon } from "src/assets/icons/open-icon.svg";

const checkIcon = (
  <IconsCommon.IconCheck
    color={COLORS.LIGHT_GREEN}
    className="check_report_icon"
  />
);
const downloadIcon = (
  <IconsCommon.IconDownload className="download_report_icon" />
);

export default function CampaignDetailsContainer(): ReactElement {
  const { i18n } = useTranslation();
  const { t } = useTranslation(["translationCampaign", "translationCommon"]);
  const history = useHistory();
  const dispatch = useReduxDispatch();
  const analyticsInfo = useSelector(getCampaignAnalyticsInfo);
  const campaignDetails = useSelector(getCampaignDetails);
  const campaignDetailsLoading = useSelector(getCampaignDetailsLoading);
  const role = useSelector(getCurrentUserRole);
  const partnerProfile = useSelector(getForm);
  const currency = useSelector(getCurrency);
  const activeTab = useSelector(getActiveTab);
  const clientActiveTab = useSelector(getClientActiveTab);

  const { id } = useParams<{ id: any }>();

  useEffect(() => {
    if (role === ROLES.UNKNOWN) return;

    dispatch(actions.fetchCampaignDetails(role, id));
  }, [role]);

  useEffect(() => {
    dispatch(actions.setActiveTab(TABS_NAMES.ANALYTICS));
    dispatch(actions.setClientActiveTab(TABS_NAMES.MEDIA));
    dispatch(briefActions.fetchBriefSettings());

    return () => {
      dispatch(actions.resetCampaignState());
    };
  }, []);

  const [generatingPdf, setGeneratingPdf] = useState<boolean>(false);

  const handleDownload = async () => {
    setGeneratingPdf(true);
    const markup = document.getElementById(CAMPAIGN_PRINT_ANALYTICS_ID);

    if (markup) {
      const pdf = await getScreenPagePdf(markup);
      pdf.save("Campaign Report.pdf");
    }

    setGeneratingPdf(false);
  };

  if (!campaignDetails || campaignDetailsLoading) {
    return <SpinnerCenter />;
  }

  const partnerRequests = campaignDetails.requests.filter(
    (request: IRequest) => request.partnerId === partnerProfile.id
  );
  const requestList = requestsToTable(partnerRequests, currency);
  const isPartner = role === ROLES.PARTNER;

  const brief = campaignDetails.brief;
  const cart = campaignDetails.cart;

  const partnerQuantity = calcDistributionQuantity(cart.additional.items);

  const summaryTitle = `Received from ${
    campaignDetails.briefOwner?.companyName || ""
  }.`;
  const campaignTitle = `${campaignDetails.brief.campaignName} from ${campaignDetails.briefOwner.companyName}.`;

  const partnerIds = isPartner
    ? [partnerProfile.id!]
    : campaignDetails.requests.map((request: IRequest) => request.partnerId);

  const summarySubtitle = () => {
    const dateMoment = moment(campaignDetails.startDate);
    const approvedDate = moment(campaignDetails.startDate).format(
      FORMATS.DATE_LL_FORMAT
    );
    const days = daysPastFromToday(dateMoment);
    const text = days < 2 ? `${days} Day ago` : `${days} Days ago`;
    return approvedDate + ". " + text;
  };

  const handleBack = () => {
    const backPath = isPartner ? PATHS.PARTNER_CAMPAIGNS : PATHS.CAMPAIGNS;
    history.push(backPath);
  };

  const handleTabsRadio = (e: any) => {
    const { value } = e.target;
    dispatch(actions.setActiveTab(value));
  };

  const handleClientTabsRadio = (e: any) => {
    const { value } = e.target;
    dispatch(actions.setClientActiveTab(value));
  };

  const handleTabs = (tab: any) => {
    dispatch(actions.setActiveTab(tab));
  };

  const partnerContent = (
    <div className="partner-brief-view">
      <QBackLink
        title={
          t("common.Back_to", { ns: "translationCommon" }) +
          " " +
          t("panel-header.nav.campaigns", { ns: "translationCommon" })
        }
        onClick={handleBack}
      />
      <div className="status-title">
        <h1>{campaignTitle}</h1>
        <div
          className={`status-label ${
            campaignStatusesLabel[campaignDetails.status].classStyle
          }`}
        >
          {campaignDetails.status}
        </div>
      </div>
      <div className="dashboard-tabs only-desktop">
        <Tabs onChange={handleTabs} activeKey={activeTab}>
          <Tabs.TabPane tab="Analytics" key={TABS_NAMES.ANALYTICS}>
            <div className="qu-brief-analytics">
              <div className="mb-30" id={CAMPAIGN_PRINT_ANALYTICS_ID}>
                <CampaignAnalytics />
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Campaign" key={TABS_NAMES.CAMPAIGNS}>
            <div className="dashboard-table-wrapper partner-table-wrapper">
              <BriefViewDesktopComponent
                brief={brief}
                cart={cart}
                summaryTitle={summaryTitle}
                summarySubtitle={summarySubtitle()}
                partnerIds={partnerIds}
                partnerQuantity={partnerQuantity}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            className="partner-requests-tab"
            tab="Requests"
            key={TABS_NAMES.REQUESTS}
          >
            <RequestTableComponent
              requestList={requestList}
              loading={false}
              hideSearch
              hidePagination
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className="only-mobile">
        <Radio.Group
          value={activeTab}
          onChange={handleTabsRadio}
          className="qu-radio-group full-width"
          buttonStyle="solid"
        >
          <Radio.Button
            className="qu-radio-button qu-radio-white mb-10"
            value={TABS_NAMES.ANALYTICS}
          >
            Analytics
          </Radio.Button>
          <Radio.Button
            className="qu-radio-button qu-radio-white mb-10"
            value={TABS_NAMES.CAMPAIGN}
          >
            Campaign
          </Radio.Button>
          <Radio.Button
            className="qu-radio-button qu-radio-white"
            value={TABS_NAMES.REQUESTS}
          >
            Requests
          </Radio.Button>
        </Radio.Group>

        {activeTab === TABS_NAMES.ANALYTICS && <CampaignAnalytics />}
        {activeTab === TABS_NAMES.CAMPAIGN && (
          <BriefViewMobileComponent
            brief={brief}
            cart={cart}
            partnerIds={partnerIds}
            partnerQuantity={partnerQuantity}
          />
        )}
        {activeTab === TABS_NAMES.REQUESTS && (
          <RequestTableComponent
            requestList={requestList}
            loading={false}
            hideSearch
            hidePagination
          />
        )}

        {/*<QButton className="qu-button-green partner-button full-width mv-30">*/}
        {/*  <span className="mr-5">*/}
        {/*    <UserIcon />*/}
        {/*  </span>*/}
        {/*  Refer a Partner*/}
        {/*</QButton>*/}
      </div>
    </div>
  );

  // console.log(clientActiveTab);

  const clientContent = isMobile ? (
    <div className="mv-30 mobile">
      <div className="ml-10">
        <QBackLink
          title={
            t("common.Back_to", { ns: "translationCommon" }) +
            " " +
            t("panel-header.nav.campaigns", { ns: "translationCommon" })
          }
          onClick={handleBack}
        />
      </div>
      <Tabs
        className="qu-brief-tabs"
        activeKey={activeTab}
        onChange={handleTabs}
      >
        <Tabs.TabPane
          tab={t("campaign.details.campaign_analytics")}
          key={TABS_NAMES.ANALYTICS}
        >
          <div className="qu-brief-analytics">
            <div className="header-badged">
              <h1>{campaignTitle}</h1>
              <div
                className={`status-label ${
                  campaignStatusesLabel[campaignDetails.status].classStyle
                }`}
              >
                {t(campaignStatusesLabel[campaignDetails.status].label)}
              </div>
            </div>

            {/* <Radio.Group
              value={clientActiveTab}
              onChange={handleClientTabsRadio}
              className="qu-radio-group full-width"
              buttonStyle="solid"
            >
              <Radio.Button className="qu-radio-button qu-radio-white mb-10" value={TABS_NAMES.DISTRIBUTION}>
                Product Distribution
              </Radio.Button>
              <Radio.Button
                className="qu-radio-button qu-radio-white mb-10"
                value={TABS_NAMES.MEDIA}
                disabled={!analyticsInfo || !analyticsInfo.availableCampaignMediaBuyData.length ? true : false}
              >
                Media Buy
              </Radio.Button>
            </Radio.Group> */}

            {clientActiveTab === TABS_NAMES.DISTRIBUTION && (
              <CampaignAnalyticsDistCharts />
            )}
            {clientActiveTab === TABS_NAMES.MEDIA && (
              <CampaignAnalyticsMediaBuy />
            )}
            {/* <CampaignAnalyticsTimeline /> */}
          </div>
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Surveys" key={TABS_NAMES.SURVEYS}>
          <ClientSurveys />
        </Tabs.TabPane> */}
        {!isPartner && (
          <Tabs.TabPane
            tab={t("campaign.details.gallery")}
            key={TABS_NAMES.GALLERY}
          >
            <ClientGallery />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={t("campaign.details.original_request")}
          key={TABS_NAMES.REQUESTS}
        >
          <BriefViewMobileComponent
            brief={brief}
            cart={cart}
            partnerIds={partnerIds}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  ) : (
    <div className="client-brief-view">
      <QBackLink
        title={
          t("common.Back_to", { ns: "translationCommon" }) +
          " " +
          t("panel-header.nav.campaigns", { ns: "translationCommon" })
        }
        onClick={handleBack}
      />
      <Tabs className="qu-brief-tabs" defaultActiveKey={activeTab}>
        <Tabs.TabPane
          tab={t("campaign.details.campaign_analytics")}
          key={TABS_NAMES.ANALYTICS}
        >
          <div className="qu-brief-analytics">
            <div className="header-badged">
              <h1>{campaignTitle}</h1>
              <div
                className={`status-label ${
                  campaignStatusesLabel[campaignDetails.status].classStyle
                }`}
              >
                {t(campaignStatusesLabel[campaignDetails.status].label)}
              </div>
            </div>

            <Row gutter={20} id={CAMPAIGN_PRINT_ANALYTICS_ID}>
              <Col xs={24} sm={16}>
                {/* <div data-html2canvas-ignore>
                  <Radio.Group
                    value={clientActiveTab}
                    onChange={handleClientTabsRadio}
                    className="qu-radio-group full-width"
                    buttonStyle="solid"
                  >
                    <Radio.Button className="qu-radio-button qu-radio-white mb-10" value={TABS_NAMES.DISTRIBUTION}>
                      Product Distribution
                    </Radio.Button>
                    <Radio.Button
                      className="qu-radio-button qu-radio-white mb-10"
                      value={TABS_NAMES.MEDIA}
                    >
                      Media Buy
                    </Radio.Button>
                  </Radio.Group>
                </div> */}
                {clientActiveTab === TABS_NAMES.DISTRIBUTION && (
                  <CampaignAnalyticsDistCharts />
                )}
                {clientActiveTab === TABS_NAMES.MEDIA && (
                  <CampaignAnalyticsMediaBuy />
                )}
              </Col>

              <Col xs={24} sm={8}>
                <div className="timeline mb-20">
                  <div className="timeline__head">
                    <h5 className="timeline__title">
                      {t("campaign.details.reports")}
                    </h5>
                  </div>

                  {campaignDetails &&
                  campaignDetails?.brief?.campaignReportfilesUrls &&
                  campaignDetails?.brief?.campaignReportfilesUrls.length > 0 ? (
                    campaignDetails?.brief?.campaignReportfilesUrls.map(
                      (ele, index) => (
                        <div
                          className="reports_listing"
                          key={ele.id}
                          onClick={() =>
                            window.open(
                              ele.briefs_campaign_report_files,
                              "_blank"
                            )
                          }
                        >
                          {checkIcon}
                          <span>
                            {t("campaign.details.report_label", {
                              reportCount: index + 1,
                            })}
                          </span>
                          <div>
                            {/*<a>
                                                      <OpenIcon className="open_report_icon" />
                                                    </a>*/}
                            <a
                              href={ele.briefs_campaign_report_files}
                              target="_blank"
                              download
                            >
                              {downloadIcon}
                            </a>
                          </div>
                          <br />
                          <span className="date_span">
                            {t("campaign.details.uploaded_on_only")}{" "}
                            {moment(ele.createdAt).format(
                              FORMATS.DATE_LL_FORMAT
                            )}
                          </span>
                        </div>
                      )
                    )
                  ) : (
                    <div style={{ marginBottom: "20px" }}>
                      <Empty
                        description={
                          <span>
                            {t("common.No_Data", { ns: "translationCommon" })}
                          </span>
                        }
                      />
                    </div>
                  )}
                </div>
                {/*<CampaignAnalyticsTimeline />*/}

                {/* {!isMobile && (
                  <QButton
                    loading={generatingPdf}
                    disabled={generatingPdf}
                    onClick={handleDownload}
                    block
                    className="qu-button-soft mt-20 justify-between"
                    data-html2canvas-ignore
                  >
                    Download Report <IconsCommon.IconDownload />
                  </QButton>
                )} */}
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Surveys" key={TABS_NAMES.SURVEYS}>
          <ClientSurveys />
        </Tabs.TabPane> */}
        {!isPartner && (
          <Tabs.TabPane
            tab={t("campaign.details.gallery")}
            key={TABS_NAMES.GALLERY}
          >
            <ClientGallery />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={t("campaign.details.original_request")}
          key={TABS_NAMES.REQUESTS}
        >
          <BriefViewDesktopComponent
            brief={brief}
            cart={cart}
            summaryTitle={summaryTitle}
            summarySubtitle={summarySubtitle()}
            partnerIds={partnerIds}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );

  return (
    <div className={`qu-brief-view ${isPartner ? "dashboard-layout" : ""}`}>
      {isPartner ? partnerContent : clientContent}
    </div>
  );
}
