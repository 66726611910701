// @ts-nocheck
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconsCommon, Popover, Badge } from 'quantum_components'
import { InfoOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import SpinnerComponent from '../Spinner/SpinnerComponent'
import {
  LOCALIZATION_LANGUAGES,
} from "src/constants"

const ICONS = {
  COMPLETED: <IconsCommon.IconCheckMark />,
  REJECTED: <IconsCommon.IconWarning />,
  APPROVED: <IconsCommon.InfoOutlined />,
  ERROR: <IconsCommon.IconClose />,
}

export interface IProps {
  data: any
  onClick: (e: any, item: any) => void
  fetchData: () => void
  hasMore: boolean
}

const NotificationsList = ({ data = [], onClick, hasMore, fetchData }: IProps) => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCommon'])
  return (
    <div className="notifications-menu-list" id="scrollableDiv">
      {!data.length && <div className="notifications-empty">{t('common.No_new_notifications')}</div>}
      {data.length > 0 && (
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={data.length}
          next={fetchData}
          hasMore={hasMore}
          loader={
            <div style={{ textAlign: 'center' }}>
              <SpinnerComponent />
            </div>
          }
        >
          {data.map(item => (
            <a
              className="notifications-menu-item notifications-menu-approved"
              key={item.key}
              onClick={(e: any) => onClick(e, item)}
            >
              <span className={`notifications-menu-image ${item.status ? item.status.toLowerCase() : ''}`}>
                {ICONS[item.status] || <InfoOutlined />}
              </span>
              <span className="notifications-menu-info">
                <div className="notifications-text">
                  <div className="mr-5 font-weight-800">{item.titleAr && i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? item.titleAr : item.title}</div>
                  {item.descriptionAr && i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? item.descriptionAr : item.description}
                </div>
                <span className="notifications-menu-date">{item.date}</span>
              </span>
            </a>
          ))}
        </InfiniteScroll>
      )}
    </div>
  )
}

export interface Props {
  fetchData: () => void
  hasMore: boolean
  className?: string
  notificationsData?: any
  badgeCount?: number
  onOpen?: (e: any, name?: string) => void
  onClick?: (e: any, item: any) => void
}

const NotificationMenu = ({
  className = '',
  notificationsData = [],
  badgeCount = 0,
  onOpen,
  onClick,
  hasMore,
  fetchData,
}: Props) => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCommon'])
  return (
    <Popover
      className={`notifications-menu-popover ${className}`}
      overlayClassName="notifications-menu-wrapper"
      content={<NotificationsList data={notificationsData} onClick={onClick} fetchData={fetchData} hasMore={hasMore} />}
      title={t('common.Notifications')}
      trigger="click"
      placement="bottomRight"
      getPopupContainer={(node: any) => node.parentNode}
    >
      <div className="notifications-menu" onClick={onOpen}>
        <Badge count={badgeCount}>
          <span className="notifications-menu-icon">
            <IconsCommon.IconBell />
          </span>
        </Badge>
      </div>
    </Popover>
  )
}
export default NotificationMenu
