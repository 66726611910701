import { createSelector } from 'reselect'
import { IState, IPublicProfileResponse } from './publicProfile.types'
import { MODULE_NAME } from './publicProfile.constants'
import { IBriefPartnerDetails } from '../../brief/brief.types'
import { pick } from 'lodash'
import { NAMES_PUBLIC_PROFILE_FIELDS } from 'src/constants/profile'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getPublicProfile = createSelector(
  selectState,
  (state: IState): IPublicProfileResponse => state.publicProfile
)
export const getPublicProfileLoading = createSelector(
  selectState,
  (state: IState): boolean => state.publicProfileLoading
)
export const getPartnerPublicPercent = createSelector(selectState, (state: IState): number => {
  const values = Object.values(pick(state.publicProfile, Object.values(NAMES_PUBLIC_PROFILE_FIELDS)))
  const filled = values.filter((e: any) => !!e?.length)
  // @ts-ignore
  return Math.ceil((filled.length / values.length) * 100)
})

export const getPartnerDetails = createSelector(
  selectState,
  (state: IState): IBriefPartnerDetails | null => state.partnerDetails
)

export const getPartnerDetailsLoading = createSelector(
  selectState,
  (state: IState): boolean => state.fetchPartnerDetailsLoading
)
