import IAction from '../../../interfaces/IAction'
import * as CONSTANTS from './profile.constants'
import { CLIENT_STEPS } from '../../../constants/clientProfile'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'
import { IUploadResponse, ICurrentUser } from '../../core/core.types'
import { BASIC_INFO, BASIC_INFO_VALUES as VALUES } from 'src/constants/clientProfile'
import omit from 'lodash/omit'
import { fetchCurrencySuccess } from '../../core/core.actions'

export interface Step {
  name: string
  status: string
}

export const setCurrentStep = (step: CLIENT_STEPS): IAction => ({
  type: CONSTANTS.SET_CURRENT_STEP,
  payload: { step },
})

export const setLastFilled = (step: CLIENT_STEPS): IAction => ({
  type: CONSTANTS.SET_LAST_FINISHED,
  payload: { step },
})

export const setFilledList = (step: string): IAction => ({
  type: CONSTANTS.SET_FILLED,
  payload: { step },
})

export const setStepData = (stepData: any[]): IAction => ({
  type: CONSTANTS.SET_STEP_DATA,
  payload: { stepData },
})

export const createClientRequest = (): IAction => ({
  type: CONSTANTS.CREATE_CLIENT_REQUEST,
})

export const createClientSuccess = (): IAction => ({
  type: CONSTANTS.CREATE_CLIENT_SUCCESS,
})

export const createClientFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_CLIENT_FAILURE,
  error,
})

export const resetClientState = (): IAction => ({
  type: CONSTANTS.RESET_CLIENT_STATE,
})

export const fetchClientSettingsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_SETTINGS_REQUEST,
})

export const fetchClientSettingsSuccess = (settings: any): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_SETTINGS_SUCCESS,
  payload: { settings },
})

export const fetchClientSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_SETTINGS_FAILURE,
  error,
})

export const fetchClientSettings =
  (): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fetchClientSettingsRequest())
      const response = await requestHttp.get(urls.getClientSettingsUrl())

      if (response.status === 200) {
        const { content } = response.data.data

        dispatch(fetchClientSettingsSuccess(content))
      }
    } catch (error: any) {
      dispatch(fetchClientSettingsFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchClientInfoRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_INFO_REQUEST,
})

export const fetchClientInfoSuccess = (clientInfo: any): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_INFO_SUCCESS,
  payload: { clientInfo },
})

export const fetchClientInfoFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_INFO_FAILURE,
  error,
})

export const fetchClientInfo =
  (user: ICurrentUser): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fetchClientInfoRequest())
      const response = await requestHttp.get(urls.getClientProfileUrl())

      if (response.status === 200) {
        const { content } = response.data.data

        dispatch(fetchClientInfoSuccess({ ...user, ...content }))
      }
    } catch (error: any) {
      dispatch(fetchClientInfoFailure(getResponseErrorMessage(error)))
    }
  }

export const updateClient =
  (stepsData: any): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(createClientRequest())
      stepsData = omit(stepsData, 'email')
      const body = {
        ...stepsData,
        vat: stepsData.vat ? stepsData.vat : null,
        [VALUES[BASIC_INFO.LOCATION]]: stepsData[VALUES[BASIC_INFO.LOCATION]].trim()
          ? stepsData[VALUES[BASIC_INFO.LOCATION]]
          : null,
      }
      const response = await requestHttp.patch(urls.getEditClientUrl(), body)
      const { content } = response.data.data
      dispatch(createClientSuccess())
      dispatch(fetchCurrencySuccess(content.currency))
    } catch (error: any) {
      dispatch(createClientFailure(getResponseErrorMessage(error)))
    }
  }

export const imageUploadRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_REQUEST,
})

export const imageUploadSuccess = (res: any): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_SUCCESS,
  payload: { res },
})

export const imageUploadFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_FAILURE,
  error,
})

export const imageRemoveRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_REQUEST,
})

export const imageRemoveSuccess = (res: any): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_SUCCESS,
  payload: { res },
})

export const imageRemoveFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_FAILURE,
  error,
})

export const uploadProductImage =
  (files: File[]): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(imageUploadRequest())

      const bodyFormData = new FormData()

      files.forEach((file: File) => {
        bodyFormData.append('files', file)
      })

      const config = {
        multipartFormData: true,
        skipDownloadProgress: true,
      }

      const response = await requestHttp.post<IUploadResponse>(urls.getClientUploadUrl(), bodyFormData, config)

      if (response.status === 201) {
        const { content } = response.data.data
        dispatch(imageUploadSuccess(content))
      }
    } catch (error: any) {
      dispatch(imageUploadFailure(error))
    }
  }

export const setPercent = (percent: number) => ({
  type: CONSTANTS.SET_PERCENT,
  payload: { percent },
})
