import React, { useCallback, useEffect, useState } from 'react'
import { QSelect, Form, QButton, Row, Col, Modal, IconsCommon } from 'quantum_components'
import { IOptionForSelect, IPartnerFilter } from '../../testahelBox.types'
import { useReduxDispatch } from '../../../../../helpers'
import { actions } from '../../testahelBox.module'
import { useSelector } from 'react-redux'
import { getPartnerFilter, getPartnerFilterSettings } from '../../testahelBox.selectors'

const filterIcon = <IconsCommon.FilterIcon />
const closeIcon = <IconsCommon.IconClose />

const TestahelBriefPartnerFilterMobile = () => {
  const dispatch = useReduxDispatch()
  const filter = useSelector(getPartnerFilter)
  const filterSettings = useSelector(getPartnerFilterSettings)
  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const toggleModal = useCallback(
    (value?: boolean): void => {
      setVisible(value === undefined ? !visible : value)
    },
    [setVisible, visible]
  )
  const onToggleModal = useCallback((): void => {
    toggleModal()
  }, [toggleModal])

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(filter)
  }, [filter])

  const handleSave = () => {
    form.submit()
  }

  const onFinish = (values: any) => {
    setFilterState(values)
    onToggleModal()
  }

  const setFilterState = (filter: IPartnerFilter) => {
    dispatch(actions.setPartnerFilterState(filter))
  }

  const clearFilter = () => {
    dispatch(actions.resetPartnerFilterState())
    form.setFieldsValue(filter)
  }

  const categoriesOptions: IOptionForSelect[] = filterSettings.categories.map((name: string) => {
    return { label: name, value: name }
  })

  const pricesOptions: IOptionForSelect[] = filterSettings.pricePerUnit.map((name: string) => {
    return { label: name, value: name }
  })

  const matchesOptions: IOptionForSelect[] = filterSettings.matches.map((name: string) => {
    return { label: name, value: name }
  })

  const typesOptions: IOptionForSelect[] = filterSettings.types.map((name: string) => {
    return { label: name, value: name }
  })

  const getTagsCount = () => {
    delete filter.sortBy
    return Object.values(filter).flat().length
  }

  return (
    <div className={`qu-brief-filter-modal`}>
      <QButton className="qu-button-outline qu-brief-filter-button" onClick={onToggleModal}>
        Filter Results
        <span className="h-24 ml-10">{filterIcon}</span>
        <span className="button__counter button__counter-gary">{getTagsCount()}</span>
      </QButton>
      <Modal
        wrapClassName="qu-modal"
        closeIcon={closeIcon}
        visible={visible}
        onCancel={onToggleModal}
        title={<h3 className="text-center">Filter Results</h3>}
        footer={
          <Row align="middle" justify="space-between">
            <Col>
              <QButton className="qu-button-outline" size="small" onClick={clearFilter}>
                Clear Filters
              </QButton>
            </Col>
            <Col>
              <QButton className="w-100" type="primary" size="small" onClick={handleSave}>
                Save
              </QButton>
            </Col>
          </Row>
        }
      >
        <Form form={form} onFinish={onFinish} layout="vertical" initialValues={filter}>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item label="Categories" name="categories">
                <QSelect
                  showArrow
                  maxTagCount={2}
                  maxTagTextLength={8}
                  mode="multiple"
                  placeholder="Categories"
                  options={categoriesOptions}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Price Per Unit" name="pricePerUnit">
                <QSelect
                  showArrow
                  maxTagCount={2}
                  maxTagTextLength={8}
                  mode="multiple"
                  placeholder="Price Per Unit"
                  options={pricesOptions}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Matches" name="matches">
                <QSelect
                  showArrow
                  maxTagCount={2}
                  maxTagTextLength={8}
                  mode="multiple"
                  placeholder="Match %"
                  options={matchesOptions}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Types" name="types">
                <QSelect
                  showArrow
                  maxTagCount={2}
                  maxTagTextLength={8}
                  mode="multiple"
                  placeholder="Type"
                  options={typesOptions}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
export default TestahelBriefPartnerFilterMobile
