import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import BriefFooter from './components/Brief.footer'
import {
  BRIEF_FORM_HEADER_TITLE,
  BRIEF_STATUS,
  BRIEF_STEP_STATUS,
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  MAIN_PRINT_ID,
  MEDIA_TYPES,
  REJECTED_BRIEF_STEPS,
  BRIEF_UPLOAD_TYPES
} from '../../../constants/brief'
import BriefHeader from './components/Brief.header'
import { actions } from '../brief.module'
import { useSelector } from 'react-redux'
import {
  getBrief,
  getCart,
  getCurrentStep,
  getIsSaving,
  getRecommendedPartnersLoading,
  getUpdatingCart,
} from '../brief.selectors'
import { QBriefStep } from 'quantum_components'
import { ICartItem, ICartServiceItem, IUploadPartner } from '../brief.types'
import { LOCAL_STORAGE_KEYS, PATHS } from '../../../constants'
import BriefPartnerForm from './briefForms/BriefPartner.form'
import UploadForm from './briefForms/Upload.form'
import FinalSummary from './briefForms/FinalSummary'
import SpinnerCenter from 'src/components/Spinner/spinner'
import {
  getBriefQuotationPdf,
} from "../../../utils/getScreenPage"

export default function BriefRejectedContainer(): ReactElement {
  const { t } = useTranslation([
    "translation",
    "translationCommon",
    "translationInviteClient",
  ]);
  const dispatch = useReduxDispatch()
  const history = useHistory()
  const location = useLocation()
  const brief = useSelector(getBrief)
  const currentStep = useSelector(getCurrentStep)
  const cart = useSelector(getCart)
  const isSaving = useSelector(getIsSaving)
  const isUpdatingCart = useSelector(getUpdatingCart)
  const isRecommendedPartnersLoading = useSelector(getRecommendedPartnersLoading)

  const [skipUploadStep, setSkipUploadStep] = useState<boolean>(false)

  const uploadFormRef = useRef()

  const { id } = useParams<{id: any}>()

  const fetchSettings = async () => {
    dispatch(actions.fetchBriefSettings())
  }

  const fetchBrief = async () => {
    await dispatch(actions.fetchEditBrief(id))
  }

  const fetchFilterSettings = () => {
    dispatch(actions.fetchPartnerFilterSettings())
  }

  const setRejection = () => {
    if (location.pathname.includes('rejected')) {
      dispatch(actions.setRejectionFlow(true))
    }
  }

  useEffect(() => {
    setRejection()
    fetchSettings()
    fetchFilterSettings()
    fetchBrief()

    dispatch(actions.setCurrentStep(BRIEF_STEPS.PARTNER))

    return () => {
      dispatch(actions.resetBriefState())
    }
  }, [])

  useEffect(() => {
    if (cart.additional.items.length > 0) {
      checkMediaTypes()
    }
  }, [cart.additional.items])

  const checkMediaTypes = () => {
    const services = cart.additional.items.map((item: ICartItem) => item.services).flat()
    const hasMediaBuy = services.some((services: ICartServiceItem) => services.type !== MEDIA_TYPES.DISTRIBUTION)
    setSkipUploadStep(!hasMediaBuy)
  }

  const handleFinishLater = async () => {
    await saveBrief()
    history.push(PATHS.BRIEF)
  }

  const handleSubmitBrief = async () => {

    const markup = document.getElementById("brief_quotation_print_area");
    if (!markup) return;

    const pdf = await getBriefQuotationPdf(markup, "en");
    // pdf.save('Brief Quotation.pdf')

    let blob = pdf.output("blob");
    await dispatch(
      actions.uploadGeneratedQuotation(
        [blob],
        BRIEF_UPLOAD_TYPES.BRIEFS_QUOTATION_FILES,
        id //briefId
      )
    )

    history.push(PATHS.BRIEF)
  }

  const isPartnerStep = currentStep === BRIEF_STEPS.PARTNER
  const isUploadStep = currentStep === BRIEF_STEPS.UPLOAD
  const isFinalSummary = currentStep === BRIEF_STEPS.SUMMARY

  const showFooter = !isPartnerStep

  const finalSummaryPreviousStep = skipUploadStep ? BRIEF_STEPS.PARTNER : BRIEF_STEPS.UPLOAD
  const isPendingApproval = brief.status === BRIEF_STATUS.PENDING_APPROVAL

  const submitButtonText = () => {
    if (!isFinalSummary) {
      return t("common.Next", { ns: "translationCommon" })
    }

    return t("common.submit", { ns: "translationCommon" })
  }

  const handleNext = async () => {
    const index = REJECTED_BRIEF_STEPS.indexOf(currentStep)
    const lastIndex = index === REJECTED_BRIEF_STEPS.length - 1

    if (lastIndex) {
      // @ts-ignore
      const hasErrors = uploadFormRef.current.validateFields()

      if (hasErrors) {
        return
      }

      await saveBrief()
      return
    }

    await saveBrief()
    if (!lastIndex) {
      setCurrentStep(REJECTED_BRIEF_STEPS[index + 1])
    }
  }

  const handleBack = () => {
    const index = REJECTED_BRIEF_STEPS.indexOf(currentStep)

    if (isFinalSummary) {
      setCurrentStep(finalSummaryPreviousStep)
      return
    }

    if (index === 0) {
      return
    }

    setCurrentStep(REJECTED_BRIEF_STEPS[index - 1])
  }

  const setCurrentStep = (step: BRIEF_STEPS) => {
    dispatch(actions.setCurrentStep(step))
  }

  const saveBrief = async () => {
    await dispatch(actions.updateBrief(currentStep, brief))
  }

  const checkStatus = (step: BRIEF_STEPS) => {
    const currentStepIndex = REJECTED_BRIEF_STEPS.indexOf(currentStep)
    const stepIndex = REJECTED_BRIEF_STEPS.indexOf(step)

    if (currentStep === step) {
      return BRIEF_STEP_STATUS.CURRENT
    }

    if (currentStepIndex >= stepIndex) {
      return BRIEF_STEP_STATUS.PASSED
    }

    return BRIEF_STEP_STATUS.DEFAULT
  }

  const handleStepperClick = (step: BRIEF_STEPS) => {
    setCurrentStep(step)
  }

  const steps = (
    <div className="qu-stepper">
      <div className="steps">
        {REJECTED_BRIEF_STEPS.map((step: BRIEF_STEPS, index: number) => (
          <QBriefStep
            key={step}
            title={`${index + 1}. ${t(BRIEF_STEPS_NAMES[step])}`}
            onClickPath={() => handleStepperClick(step)}
            status={checkStatus(step)}
          />
        ))}
      </div>
    </div>
  )

  const handleUploadStep = (fields: IUploadPartner[]) => {
    brief[BRIEF_STEPS.UPLOAD].uploadMaterial = fields
  }

  const handleEdit = (step: BRIEF_STEPS) => {
    dispatch(actions.setCurrentStep(step))
  }

  return (
    <div className="qu-brief-layout">
      <BriefHeader
        title={t(BRIEF_FORM_HEADER_TITLE.EDIT, { ns: "translationCommon" })}
        stepper={steps}
        showPartnerFilter={isPartnerStep}
        onClickCancel={() => {
          if (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN))
            history.push(`/p/${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`)
          else history.push(PATHS.BRIEF)
        }}
      />
      <div className={`qu-brief-main ${isPartnerStep ? 'qu-brief-partner' : ''}`} id={MAIN_PRINT_ID}>
        {((isSaving && !isRecommendedPartnersLoading) || isUpdatingCart) && <SpinnerCenter />}

        {isFinalSummary && (
          <FinalSummary onFinishLater={handleFinishLater} briefId={id} setCurrentStep={handleEdit} rejection />
        )}
        {isPartnerStep && <BriefPartnerForm brief={brief} />}
        {isUploadStep && (
          <UploadForm
            ref={uploadFormRef}
            brief={brief}
            onChange={handleUploadStep}
            onFinishLater={handleFinishLater}
            disabledFinishLater={false}
            skipUploadStep={skipUploadStep}
            showFinalSummary={() => setCurrentStep(BRIEF_STEPS.SUMMARY)}
          />
        )}
      </div>
      {showFooter && (
        <BriefFooter
          className=''
          disableNext={false}
          disableBack={false}
          isZeroStep={false}
          onBack={handleBack}
          onNext={isFinalSummary ? handleSubmitBrief : handleNext}
          hideArrow={isFinalSummary}
          onFinishLater={handleFinishLater}
          nextButtonText={submitButtonText()}
        />
      )}
    </div>
  )
}
