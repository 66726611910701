import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { requestHttp, urls } from '../../api'
import { getResponseErrorMessage } from '../../helpers'
import IAction from '../../interfaces/IAction'
import * as CONSTANTS from './campaign.constants'
import {
  ICampaignAnalyticsData,
  ICampaignAnalyticsInfo,
  ICampaignAnalyticsMediaBuyData,
  ICampaignDetails,
  ICampaignDetailsResponse,
  ICampaignDistAnalyticsFilter,
  ICampaignList,
  ICampaignListResponse,
  ICampaignMediaBuyFilter,
  ICampaignTableParams,
  ICampaignTimeline,
  IClientGalleryParams,
  IClientSurveysList,
  IClientSurveysParams,
} from './campaign.types'
import {
  getClientCampaignAnalyticsDataUrl,
  getClientCampaignAnalyticsInfoUrl,
  getClientCampaignAnalyticsMediaBuyDataUrl,
  getClientCampaignTimelineUrl,
  getPartnerCampaignAnalyticsDataUrl,
  getPartnerCampaignAnalyticsInfoUrl,
  getPartnerCampaignAnalyticsMediaBuyDataUrl,
  getPartnerCampaignTimelineUrl,
} from '../../api/urls'
import { CAMPAIGN_TIMELINES_LIMIT } from '../../constants/campaign'
import getUserRole from '../../helpers/getUserRole'
import ROLES from '../../constants/roles'
import downloadZipFile from '../../helpers/downloadZipFile'
import { TABS_NAMES } from '../../constants/tabs'

export const resetCampaignState = (): IAction => ({
  type: CONSTANTS.RESET_CAMPAIGN_STATE,
})

export const setActiveTab = (tab: TABS_NAMES): IAction => ({
  type: CONSTANTS.SET_ACTIVE_TAB,
  payload: { tab },
})

export const setClientActiveTab = (tab: TABS_NAMES): IAction => ({
  type: CONSTANTS.SET_CLIENT_ACTIVE_TAB,
  payload: { tab },
})

export const campaignListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGNS_REQUEST,
})

export const campaignListSuccess = (campaignList: ICampaignList): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGNS_SUCCESS,
  payload: { campaignList },
})

export const campaignListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGNS_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const setCampaignListParams = (params: ICampaignTableParams): IAction => ({
  type: CONSTANTS.SET_CAMPAIGN_LIST_PARAMS,
  payload: { params },
})

export const setParamsAndFetch = (
  role: ROLES,
  params: ICampaignTableParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setCampaignListParams(params))
  await dispatch(fetchCampaigns(role, params))
}

export const fetchCampaigns = (
  role: ROLES,
  params: ICampaignTableParams
): ThunkAction<Promise<AxiosResponse<ICampaignListResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ICampaignListResponse>> => {
  try {
    const url = role === ROLES.CLIENT ? urls.getClientCampaignsUrl() : urls.getPartnerCampaignsUrl()

    dispatch(campaignListRequest())
    const response = await requestHttp.get<ICampaignListResponse>(url, { params })
    const { content } = response.data.data

    const result = {
      results: content.campaigns.results,
      total: content.campaigns.total,
      stats: content.stats,
    } as ICampaignList

    dispatch(campaignListSuccess(result))
    return response
  } catch (error: any) {
    dispatch(campaignListFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchDetailsCampaign = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_DETAILS_REQUEST,
})

export const campaignDetailsSuccess = (details: ICampaignDetails): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_DETAILS_SUCCESS,
  payload: { details },
})

export const campaignDetailsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_DETAILS_FAILURE,
  error,
})

export const fetchCampaignDetails = (
  role: ROLES,
  id: number
): ThunkAction<Promise<AxiosResponse<ICampaignDetailsResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ICampaignDetailsResponse>> => {
  try {
    dispatch(fetchDetailsCampaign())
    const url = role === ROLES.CLIENT ? urls.getClientCampaignDetailsUrl(id) : urls.getPartnerCampaignDetailsUrl(id)

    const response = await requestHttp.get<ICampaignDetailsResponse>(url)
    const { content } = response.data.data

    dispatch(campaignDetailsSuccess(content))
    return response
  } catch (error: any) {
    dispatch(campaignDetailsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const campaignAnalyticsInfoRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_REQUEST,
})

export const campaignAnalyticsInfoSuccess = (info: ICampaignAnalyticsInfo): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_SUCCESS,
  payload: { info },
})

export const campaignAnalyticsInfoFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_FAILURE,
  error,
})

export const fetchCampaignAnalyticsInfo = (id: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignAnalyticsInfoRequest())
    const role = await getUserRole()

    console.log("inside")
    const url = role === ROLES.PARTNER ? getPartnerCampaignAnalyticsInfoUrl(id) : getClientCampaignAnalyticsInfoUrl(id)

    const response = await requestHttp.get(url)
    console.log(response)
    const { content } = response.data.data

    dispatch(campaignAnalyticsInfoSuccess(content))
  } catch (error: any) {
    dispatch(campaignAnalyticsInfoFailure(getResponseErrorMessage(error)))
  }
}

export const campaignAnalyticsDataRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_REQUEST,
})

export const campaignAnalyticsDataSuccess = (data: ICampaignAnalyticsData): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_SUCCESS,
  payload: { data },
})

export const campaignAnalyticsDataFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_FAILURE,
  error,
})

export const fetchCampaignAnalyticsData = (
  id: number,
  params: ICampaignDistAnalyticsFilter
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignAnalyticsDataRequest())
    const role = await getUserRole()

    const url = role === ROLES.PARTNER ? getPartnerCampaignAnalyticsDataUrl(id) : getClientCampaignAnalyticsDataUrl(id)

    const response = await requestHttp.get(url, { params })
    const { content } = response.data.data

    dispatch(campaignAnalyticsDataSuccess(content))
  } catch (error: any) {
    dispatch(campaignAnalyticsDataFailure(getResponseErrorMessage(error)))
  }
}

export const campaignTimelineRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_TIMELINE_REQUEST,
})

export const campaignTimelineSuccess = (data: ICampaignTimeline[]): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_TIMELINE_SUCCESS,
  payload: { data },
})

export const campaignTimelineFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_TIMELINE_FAILURE,
  error,
})

export const fetchCampaignTimeline = (id: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignTimelineRequest())
    const params = { limit: CAMPAIGN_TIMELINES_LIMIT }
    const role = await getUserRole()
    const url = role === ROLES.PARTNER ? getPartnerCampaignTimelineUrl(id) : getClientCampaignTimelineUrl(id)

    const response = await requestHttp.get(url, { params })
    const { content } = response.data.data

    dispatch(campaignTimelineSuccess(content.results))
  } catch (error: any) {
    dispatch(campaignTimelineFailure(getResponseErrorMessage(error)))
  }
}

export const setDistAnalyticsFilter = (filter: ICampaignDistAnalyticsFilter): IAction => ({
  type: CONSTANTS.SET_DIST_ANALYTICS_FILTER,
  payload: { filter },
})

export const setMediaBuyAnalyticsFilter = (filter: ICampaignMediaBuyFilter): IAction => ({
  type: CONSTANTS.SET_MEDIA_BUY_ANALYTICS_FILTER,
  payload: { filter },
})

export const campaignAnalyticsMediaBuyRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_REQUEST,
})

export const campaignAnalyticsMediaBuySuccess = (data: ICampaignAnalyticsMediaBuyData | null): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_SUCCESS,
  payload: { data },
})

export const campaignAnalyticsMediaBuyFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_FAILURE,
  error,
})

export const fetchCampaignAnalyticsMediaBuy = (
  id: number,
  params: ICampaignMediaBuyFilter
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(campaignAnalyticsMediaBuyRequest())
    const role = await getUserRole()
    const url =
      role === ROLES.PARTNER
        ? getPartnerCampaignAnalyticsMediaBuyDataUrl(id, params.mediaType || '')
        : getClientCampaignAnalyticsMediaBuyDataUrl(id)

    const response = await requestHttp.get(url, { params })
    const { content } = response.data.data
    dispatch(campaignAnalyticsMediaBuySuccess(content))
  } catch (error: any) {
    dispatch(campaignAnalyticsMediaBuyFailure(getResponseErrorMessage(error)))
  }
}

export const setClientSurveysCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CLIENT_SURVEYS_CURRENT_PAGE,
  payload: { page },
})

export const setClientSurveysParams = (params: IClientSurveysParams): IAction => ({
  type: CONSTANTS.SET_CLIENT_SURVEYS_PARAMS,
  payload: { params },
})

export const setParamsAndFetchSurveys = (
  campaignId: number,
  params: IClientSurveysParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setClientSurveysParams(params))
  await dispatch(fetchClientSurveys(campaignId, params))
}

export const clientSurveysRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_SURVEYS_REQUEST,
})

export const clientSurveysSuccess = (surveys: IClientSurveysList): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_SURVEYS_SUCCESS,
  payload: { surveys },
})

export const clientSurveysFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_SURVEYS_FAILURE,
  error,
})

export const fetchClientSurveys = (
  campaignId: number,
  params: IClientSurveysParams
): ThunkAction<Promise<AxiosResponse<ICampaignListResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ICampaignListResponse>> => {
  try {
    dispatch(clientSurveysRequest())
    const response = await requestHttp.get(urls.getClientSurveysUrl(campaignId), { params })
    const { content } = response.data.data

    dispatch(clientSurveysSuccess(content))
    return response
  } catch (error: any) {
    dispatch(clientSurveysFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const setClientGalleryCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CLIENT_GALLERY_CURRENT_PAGE,
  payload: { page },
})

export const setClientGalleryParams = (params: IClientGalleryParams): IAction => ({
  type: CONSTANTS.SET_CLIENT_GALLERY_PARAMS,
  payload: { params },
})

export const setParamsAndFetchGallery = (
  campaignId: number,
  params: IClientGalleryParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setClientGalleryParams(params))
  await dispatch(fetchClientGallery(campaignId, params))
}

export const clientGalleryRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_GALLERY_REQUEST,
})

export const clientGallerySuccess = (gallery: IClientSurveysList): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_GALLERY_SUCCESS,
  payload: { gallery },
})

export const clientGalleryFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CLIENT_GALLERY_FAILURE,
  error,
})

export const fetchClientGallery = (
  campaignId: number,
  params: IClientGalleryParams
): ThunkAction<void, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  try {
    dispatch(clientGalleryRequest())
    const response = await requestHttp.get(urls.getClientGalleryUrl(campaignId), { params })
    const { content } = response.data.data
    dispatch(clientGallerySuccess(content))
  } catch (error: any) {
    dispatch(clientGalleryFailure(getResponseErrorMessage(error)))
  }
}

export const fetchDownloadMediaRequest = (): IAction => ({
  type: CONSTANTS.FETCH_DOWNLOAD_MEDIA_REQUEST,
})

export const fetchDownloadMediaSuccess = (): IAction => ({
  type: CONSTANTS.FETCH_DOWNLOAD_MEDIA_SUCCESS,
})

export const fetchDownloadMediaFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_DOWNLOAD_MEDIA_FAILURE,
  error,
})

export const downloadClientGallery = (imageUrls: string[]): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fetchDownloadMediaRequest())
    const data = { urls: imageUrls }
    const response = await requestHttp.post(urls.getClientGalleryDownloadUrl(), data, {
      skipDownloadProgress: false,
      responseType: 'blob',
    })

    downloadZipFile(response)
    dispatch(fetchDownloadMediaSuccess())
  } catch (error: any) {
    dispatch(fetchDownloadMediaFailure(getResponseErrorMessage(error)))
  }
}
