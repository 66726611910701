import React, { useEffect } from 'react'
import { Col, Empty, QBarChartSimple, QPieChart, QSelect, Row } from 'quantum_components'
import { useSelector } from 'react-redux'
import {
  getCampaignAnalyticsDataAgeGroups,
  getCampaignAnalyticsDataCities,
  getCampaignAnalyticsDataGender,
  getCampaignAnalyticsDistFilter,
  getCampaignAnalyticsInfo,
} from '../campaign.selectors'
import { getLocaleCost } from '../../../helpers/currency'
import { ICity, IOptionForSelect } from '../../brief/brief.types'
import { ICampaignPartner } from '../campaign.types'
import { useReduxDispatch } from '../../../helpers'
import { actions } from '../campaign.module'
import { useParams } from 'react-router-dom'
import { FORMATS } from '../../../constants'
import moment from 'moment'
import { hasChartData } from '../../../helpers/campaign'
import { getCurrentUserRole } from '../../core/core.selectors'
import ROLES from '../../../constants/roles'

const CampaignAnalyticsDistCharts = () => {
  const dispatch = useReduxDispatch()
  const citiesData = useSelector(getCampaignAnalyticsDataCities)
  const gendersData = useSelector(getCampaignAnalyticsDataGender)
  const ageGroupsData = useSelector(getCampaignAnalyticsDataAgeGroups)
  const analyticsInfo = useSelector(getCampaignAnalyticsInfo)
  const distFilter = useSelector(getCampaignAnalyticsDistFilter)
  const { id } = useParams<{id: any}>()
  const role = useSelector(getCurrentUserRole)
  const isPartner = role === ROLES.PARTNER

  const fetchAnalytics = () => {
    dispatch(actions.fetchCampaignAnalyticsData(id, distFilter))
    dispatch(actions.fetchCampaignAnalyticsInfo(id))
  }

  useEffect(() => {
    if (role === ROLES.UNKNOWN) return
    fetchAnalytics()
  }, [])

  const handleFilter = async (values: IOptionForSelect[], name: string) => {
    const filter = { ...distFilter }
    //@ts-ignore
    filter[name] = values

    await dispatch(actions.setDistAnalyticsFilter(filter))
    await dispatch(actions.fetchCampaignAnalyticsData(id, filter))
  }

  const ageGroups: IOptionForSelect[] = analyticsInfo
    ? analyticsInfo.ageGroups.map((group: string) => ({ label: group, value: group }))
    : []

  const partnerOptions: IOptionForSelect[] = analyticsInfo
    ? analyticsInfo.partners.map((partner: ICampaignPartner) => ({ label: partner.companyName, value: partner.id }))
    : []

  const cityOptions: IOptionForSelect[] = analyticsInfo
    ? analyticsInfo.cities.map((city: ICity) => ({ label: city.name, value: city.id }))
    : []

  const hasCitiesData = hasChartData(citiesData)
  const hasGendersData = hasChartData(gendersData)
  const hasAgeGroupsData = hasChartData(ageGroupsData)
  return (
    <div className="qu-chart-card mb-20">
      <div className="card-header">
        <h3 className="font-weight-600">Product Distribution</h3>
        {analyticsInfo && (
          <div className="card-header-right">
            <div className="card-header-info">
              <div>
                Started{' '}
                {analyticsInfo.startedDate ? moment(analyticsInfo.startedDate).format(FORMATS.DATE_LL_FORMAT) : '-'}
              </div>
              <div>Duration {analyticsInfo.duration ? analyticsInfo.duration : '-'}</div>
            </div>
            <div className="card-header-info">
              <b>
                {getLocaleCost(analyticsInfo.quantity)} of {getLocaleCost(analyticsInfo.assigned)}
              </b>
              <div>items distributed</div>
            </div>
          </div>
        )}
      </div>
      <div className="card-body">
        <Row gutter={16} data-html2canvas-ignore>
          <Col span={24}>
            <p className="font-weight-600 mb-8">Filter By:</p>
          </Col>
          <Col xs={24} sm={8}>
            <QSelect
              className="full-width mb-16"
              showArrow
              allowClear
              maxTagCount={1}
              maxTagTextLength={6}
              mode="multiple"
              placeholder="All Cities"
              options={cityOptions}
              onChange={(options: IOptionForSelect[]) => handleFilter(options, 'cityIds')}
              size="large"
            />
          </Col>
          <Col xs={24} sm={8}>
            <QSelect
              className="full-width mb-16"
              showArrow
              allowClear
              maxTagCount={1}
              maxTagTextLength={6}
              mode="multiple"
              placeholder="All Age Groups"
              options={ageGroups}
              onChange={(options: IOptionForSelect[]) => handleFilter(options, 'ageGroups')}
              size="large"
            />
          </Col>
          {!isPartner && (
            <Col xs={24} sm={8}>
              <QSelect
                className="full-width mb-16"
                showArrow
                allowClear
                maxTagCount={1}
                maxTagTextLength={6}
                mode="multiple"
                placeholder="All Partners"
                options={partnerOptions}
                onChange={(options: IOptionForSelect[]) => handleFilter(options, 'partnerIds')}
                size="large"
              />
            </Col>
          )}
        </Row>

        <h3 className="qu-chart-label mb-10">Reach & Volume</h3>
        <div className="grid-row grid-row--jcc">
          <div className="grid-col">
            {hasCitiesData ? (
              <QBarChartSimple className="h-300" data={citiesData} />
            ) : (
              <div className="qu-chart-empty h-300">
                <Empty />
              </div>
            )}
          </div>
        </div>

        <h3 className="qu-chart-label">Demographics</h3>
        <Row className="negative-card-body" gutter={0}>
          <Col xs={24} sm={12}>
            {hasGendersData ? (
              <QPieChart data={gendersData} />
            ) : (
              <div className="qu-chart-empty h-300">
                <Empty />
              </div>
            )}
          </Col>
          <Col xs={24} sm={12}>
            {hasAgeGroupsData ? (
              <QPieChart data={ageGroupsData} />
            ) : (
              <div className="qu-chart-empty h-300">
                <Empty />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CampaignAnalyticsDistCharts
