import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'
import { QButton, QInput, QSelect, Form, IconsCommon, Row, Col } from 'quantum_components'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../modules/client/referral'
import { getCoupons } from '../../modules/client/referral/referral.selectors'
import { ICartList, IOptionForSelect } from '../../modules/brief/brief.types'
import { ICoupon } from '../../modules/client/referral/referral.types'
import { getBriefSettings, getCart } from '../../modules/brief/brief.selectors'
import * as cartActions from '../../modules/brief/brief.actions'
import { useParams } from 'react-router-dom'
import { COLORS } from '../../constants/colors'
import { cloneDeep, snakeCase } from 'lodash'
import { calcManagementFee, calcTaxes } from 'src/helpers/cart'
import DialogModal from '../DialogModal/DialogModal'

const checkIcon = <IconsCommon.IconCheck color={COLORS.LIGHT_GREEN} />

const CouponForm = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translation', 'translationCommon'])
  const dispatch = useDispatch()
  const coupons = useSelector(getCoupons)
  const cart = useSelector(getCart)
  const { managementFee, partnerVat } = useSelector(getBriefSettings)
  const { id } : any = useParams()
  const briefId = id
  const [code, setCode] = useState<string>('')
  const [deleteCouponCodeHandle, setDeleteCouponCodeHandle] = useState<boolean>(false)

  useEffect(() => {
    dispatch(actions.fetchCoupons())
  }, [])

  const handleCouponInput = (e: any) => {
    setCode(e.target.value)
  }

  const onAddCoupon = async () => {
    ReactGA.event({
      category: `Create/Edit Brief - Coupon Code`,
      action: code,
      label: code,
      value: 1,
    })
    const cartData = {
      briefId,
      additional: cart.additional,
      couponCode: code,
    } as ICartList

    const newCartData = cloneDeep(cartData)
    newCartData.additional.servicesTotal =
      newCartData.additional.servicesTotal -
      (newCartData.additional.copackingTotal + newCartData.additional.printingTotal)
    newCartData.additional.managementFee = calcManagementFee(newCartData.additional.servicesTotal, managementFee)
    newCartData.additional.subTotal = newCartData.additional.servicesTotal + newCartData.additional.managementFee
    newCartData.additional.taxes = calcTaxes(newCartData.additional.subTotal, partnerVat)
    newCartData.additional.cartTotal = newCartData.additional.subTotal + newCartData.additional.taxes

    await dispatch(cartActions.addToCart(newCartData))
    await dispatch(cartActions.fetchCart(briefId))
  }

  const handleSelect = (option: string) => {
    setCode(option)
  }

  const translateDiscountText = (discountText: any) => {
    let buffer = new Array()
    buffer = discountText.split("%");

    if (buffer[0] == 0) return t("brief.cart." + snakeCase(buffer[1]));

    return buffer[0] + "% " + t("brief.cart." + snakeCase(buffer[1]));
  }

  const couponOptions: IOptionForSelect[] = coupons.map((c: ICoupon) => ({ label: c.couponCode, value: c.couponCode }))
  const showSelect = !cart.couponCode && couponOptions.length > 0

  if (!!cart.couponCode) {
    return (
      <>
        <Row align="middle" gutter={8}>
          <Col span={20}>
            <div className="qu-discount-badge mb-15">
              {checkIcon}
              {translateDiscountText(cart?.additional?.discountText)}
            </div>
          </Col>
          <Col span={4} className="selection-summary__description mb-16">
            <span className="qu-button-link" onClick={() => setDeleteCouponCodeHandle(true)}>
              {t('common.Remove', { ns: 'translationCommon' })}
            </span>
          </Col>
        </Row>

        <DialogModal
          modalOpen={deleteCouponCodeHandle}
          question={'Remove discount code'}
          description={'Your cart will be recalculated. Are you sure you want to remove this promo code?'}
          submitButtonText={'Cancel'}
          cancelButtonText={'Confirm'}
          onClose={() => setDeleteCouponCodeHandle(false)}
          onProceed={() => {
            setCode('')
            onAddCoupon()
            setDeleteCouponCodeHandle(false)
          }}
          revert
        />
      </>
    )
  }

  return (
    <div className="coupon-container">
      {showSelect && (
        <QSelect
          value={null}
          onChange={handleSelect}
          className="full-width mb-15"
          options={couponOptions}
          size="large"
          placeholder="Select Coupon Code"
          showArrow
        />
      )}
      <div className="services-discount">
        <Form.Item label={t('brief.cart.Coupon_code')}>
          <QInput
            value={cart.couponCode ? cart.couponCode : code}
            disabled={!!cart.couponCode}
            onChange={handleCouponInput}
            placeholder={t('brief.cart.Enter_code')}
            size="large"
          />
        </Form.Item>
        {!cart.couponCode && (
          <QButton disabled={!code} onClick={onAddCoupon} className="qu-button-soft">
            {t('brief.cart.Add')}
          </QButton>
        )}
      </div>
    </div>
  )
}

export default CouponForm
