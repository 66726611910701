import React, { Fragment, useEffect, useState } from 'react'
import { IBriefResponse, IUploadPartner } from '../../brief.types'
import { useTranslation } from 'react-i18next'
import lodash from 'lodash'
import { Col, Descriptions } from 'antd'
import { BRIEF_ADVERTISEMENT_LANGUAGES } from 'src/constants/brief'
import { getFileNameFromUrl } from 'src/utils'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
  partnerIds?: number[]
}

const BriefUpload = ({ briefView, onEdit, viewMode, partnerIds }: Props) => {
  const { t } = useTranslation(['translation'])

  const [uploadMaterial, setUploadMaterial] = useState<IUploadPartner[]>([])

  useEffect(() => {
    getUploadMaterial()
  }, [briefView])

  const getUploadMaterial = async () => {
    const uploads = partnerIds
      ? briefView.uploadMaterial.filter((file: IUploadPartner) => partnerIds.includes(file.partnerId))
      : briefView.uploadMaterial
    if (!uploads) return

    const list = uploads.map((material: IUploadPartner) => {
      return material
    })

    setUploadMaterial(list)
  }

  return (
    !lodash.isEmpty(uploadMaterial) && <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>{t('brief.summary.Media_Buying')}</h4>
        {!viewMode && <span onClick={onEdit}>{t('brief.summary.Edit')}</span>}
      </div>
      <div className="product-brief">
        <div className="BSUnit__media">
          {uploadMaterial.map((upload: any, i: number) => {
            const name = upload.mediaSubType ? upload.mediaSubType : upload.mediaType
            return (
              <div className="media-item" key={i}>
                {i > 0 && <hr className="form__hr" />}
                <div className="media-item-label">{t('services.media-buy.types.' + lodash.snakeCase(name), name)}</div>
                <br />
                <div className="BSUnit__descriptions">
                  {upload.link && <Col span={24}>
                    <div className="data-view mb-15">
                      <div className="data-view-label">{t('brief.summary.target_url')}</div>
                      <div className="data-view-value">
                        <a href={upload.link} target="_blank" rel="noopener noreferrer">
                          {upload.link}
                        </a>
                      </div>
                    </div>
                  </Col>}

                  {Object.entries(BRIEF_ADVERTISEMENT_LANGUAGES).map(([key, value]) => (
                    <Fragment key={key}>
                      {upload.adTitle[key] && (
                        <Col span={24}>
                          <div className="data-view mb-15">
                            <div className="data-view-label">{t('brief.summary.title_' + lodash.snakeCase(value))}</div>
                            <div className="data-view-value">
                              {upload.adTitle[key]}
                            </div>
                          </div>
                        </Col>
                      )}
                      {upload.adDescription[key] && (
                        <Col span={24}>
                          <div className="data-view mb-15">
                            <div className="data-view-label">{t('brief.summary.description_' + lodash.snakeCase(value))}</div>
                            <div className="data-view-value">
                              {upload.adDescription[key]}
                            </div>
                          </div>
                        </Col>
                      )}
                      <Descriptions column={1}>
                        {upload.imageTargetUrl[key] && (
                          <Fragment>
                            <div className="media-item-image">
                              {upload.imageTargetUrl[key].includes('.webm') ||
                                upload.imageTargetUrl[key].includes('.mp4') ||
                                upload.imageTargetUrl[key].includes('.ogg') ? (
                                <video width="178" height="100" controls>
                                  <source
                                    src={upload.imageTargetUrl[key]}
                                    type={`video/${upload.imageTargetUrl[key].split('.')[
                                      upload.imageTargetUrl[key].split('.').length - 1
                                    ]
                                      }`}
                                  />
                                  Your browser does not support HTML video.
                                </video>
                              ) : (
                                <img src={upload.imageTargetUrl[key]} alt={name} />
                              )}
                            </div>
                            <div className="media-item-hint">
                              {getFileNameFromUrl(upload.imageTargetUrl[key])}
                              <span>
                                {upload.height ? upload.width + `x${upload.height}` : upload.width}
                              </span>
                            </div>
                          </Fragment>
                        )}
                      </Descriptions>
                    </Fragment>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default BriefUpload
