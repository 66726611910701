export enum TABS_NAMES {
  CAMPAIGN = 'Campaign',
  CAMPAIGNS = 'Campaigns',
  REQUESTS = 'Requests',
  BRIEF = 'Brief',
  SERVICES = 'Services',
  ANALYTICS = 'Analytics',
  SURVEYS = 'Surveys',
  GALLERY = 'Gallery',
  DISTRIBUTION = 'Distribution',
  MEDIA = 'Media',
}
