export const MODULE_NAME = 'core'

/* actions */
export const SET_PROGRESS = `${MODULE_NAME}/SET_PROGRESS`
export const RESET_PROGRESS = `${MODULE_NAME}/RESET_PROGRESS`

export const LOGOUT_REQUEST = `${MODULE_NAME}/LOGOUT_REQUEST`
export const LOGOUT_SUCCESS = `${MODULE_NAME}/LOGOUT_SUCCESS`
export const LOGOUT_FAILURE = `${MODULE_NAME}/LOGOUT_FAILURE`

export const SET_SERVER_ERROR = `${MODULE_NAME}/SET_SERVER_ERROR`
export const RESET_SERVER_ERROR = `${MODULE_NAME}/RESET_SERVER_ERROR`

export const UPLOAD_REQUEST = `${MODULE_NAME}/UPLOAD_REQUEST`
export const UPLOAD_SUCCESS = `${MODULE_NAME}/UPLOAD_SUCCESS`
export const UPLOAD_FAILURE = `${MODULE_NAME}/UPLOAD_FAILURE`

export const DOWNLOAD_REQUEST = `${MODULE_NAME}/DOWNLOAD_REQUEST`
export const DOWNLOAD_SUCCESS = `${MODULE_NAME}/DOWNLOAD_SUCCESS`
export const DOWNLOAD_FAILURE = `${MODULE_NAME}/DOWNLOAD_FAILURE`

export const SAAS_REQUEST = `${MODULE_NAME}/SAAS_REQUEST`
export const SAAS_SUCCESS = `${MODULE_NAME}/SAAS_SUCCESS`
export const SAAS_FAILURE = `${MODULE_NAME}/SAAS_FAILURE`

export const FETCH_CURRENCIES_SUCCESS = `${MODULE_NAME}/FETCH_CURRENCIES_SUCCESS`
export const FETCH_CURRENCIES_FAILURE = `${MODULE_NAME}/FETCH_CURRENCIES_FAILURE`

export const GET_CURRENT_USER_REQUEST = `${MODULE_NAME}/GET_CURRENT_USER_REQUEST`
export const GET_CURRENT_USER_SUCCESS = `${MODULE_NAME}/GET_CURRENT_USER_SUCCESS`
export const GET_CURRENT_USER_FAILURE = `${MODULE_NAME}/GET_CURRENT_USER_FAILURE`

export const FETCH_SETTINGS_REQUEST = `${MODULE_NAME}/FETCH_SETTINGS_REQUEST`
export const FETCH_SETTINGS_SUCCESS = `${MODULE_NAME}/FETCH_SETTINGS_SUCCESS`
export const FETCH_SETTINGS_FAILURE = `${MODULE_NAME}/FETCH_SETTINGS_FAILURE`

export const FETCH_CONSTANTS_REQUEST = `${MODULE_NAME}/FETCH_CONSTANTS_REQUEST`
export const FETCH_CONSTANTS_SUCCESS = `${MODULE_NAME}/FETCH_CONSTANTS_SUCCESS`
export const FETCH_CONSTANTS_FAILURE = `${MODULE_NAME}/FETCH_CONSTANTS_FAILURE`
