import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { requestHttp, urls } from "../../api";
import { getResponseErrorMessage } from "../../helpers";
import IAction from "../../interfaces/IAction";
import * as CONSTANTS from "./campaignV3.constants";
import {
  ICampaignDistribution,
  ICampaignDetails,
  ICampaignDetailsResponse,
  ICampaignList,
  ICampaignListResponse,
  ICampaignProps,
  ICampaignQuantityInfo,
  ICampaignSettings,
  ICampaignSurvey,
  ICampaignTableParams,
  ICampaignDistributionEdit,
  ICampaignDistAnalyticsFilter,
  ICampaignAnalyticsDistData,
  ICampaignAnalyticsInfo,
  ICampaignMediaBuyData,
  ICampaignMediaBuyFilter,
  ICampaignAnalyticsMediaBuyData,
  ICampaignTimeline,
  ICampaignTimelineList,
  IClientSurveysParams,
  IClientSurveysList,
  ICampaignGallery,
  ICampaignGalleryList,
  ICampaignGalleryParams,
} from "./campaignV3.types";
import history from "../../utils/history";
import { PATHS, STATUS_CODES } from "../../constants";
import {
  // getCampaignCompleteUrl,
  // getClientCampaignAnalyticsDistDataUrl,
  getClientCampaignAnalyticsInfoUrl,
  getClientCampaignAnalyticsMediaBuyDataUrl,
} from "../../api/urls";
import { cloneDeep } from "lodash";
import { ISurveyInitState } from "./campaignV3.reducer";
import { upload } from "../core/core.actions";
// import { CAMPAIGN_UPLOAD_TYPES } from "src/constants/campaign";
import { UPLOAD_ENTITY_KEYS } from "src/constants/upload";

// fetchCampaignAnalyticsData

export const fetchCampaignDetails = async (id: number) => {
  try {
    const response = await requestHttp.get(
      urls.getClientCampaignDetailsUrl(id) + "?v=2"
    );
    const { content } = response.data.data;
    return content;
  } catch (error) {
    return error;
  }
};

export const fetchCampaignAnalyticsDetails = async (id: number) => {
  try {
    const response = await requestHttp.get(
      getClientCampaignAnalyticsInfoUrl(id) + "?v=2"
    );
    const { content } = response.data.data;
    return content;
  } catch (error) {
    return error;
  }
};

export const fetchCampaignChartsDetails = async (campaignId: number, partnerId: number, mediaType: string, beforeDate: string, afterDate: String) => {
  try {
    const queryString = `?partnerId=${partnerId}&mediaType=${encodeURIComponent(mediaType)}&beforeDate=${beforeDate}&afterDate=${afterDate}&v=2`;
    const response = await requestHttp.get(
      getClientCampaignAnalyticsMediaBuyDataUrl(campaignId) + queryString
    );
    const { content } = response.data.data;
    return content;
  } catch (error) {
    return error;
  }
};

// export const completeCampaign = async (campaignId: number) => {
//   try {
//     const response = await requestHttp.get(
//       getCampaignCompleteUrl(campaignId) + "?v=2"
//     );
//     const { content } = response.data.data;
//     return content;
//   } catch (error) {
//     return error;
//   }
// };

export const fetchCampaignReportDetails = async (campaignId: number, startDate: string, endDate: string) => {
  try {
    const response = await requestHttp.get(urls.getCampaignReportDetailsUrl(campaignId, startDate, endDate));
    const { content } = response.data.data;
    return content;
  } catch (error) {
    return error;
  }
};
