import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { QButton } from 'quantum_components'

import { PATHS } from 'src/constants'

import { ReactComponent as ImageCheck } from '../../../../assets/icons/check-icon.svg'
import ClientLayout from '../../../../components/Layouts/ClientLayout'
import * as selectors from '../../../core/core.selectors'

interface IProps {
  approvedUser: boolean
}

export default function UpdatedSuccessfully() {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translation', 'translationCommon'])
  const history = useHistory()
  const currentUser = useSelector(selectors.getCurrentUser)

  const handleFinish = () => {
    history.push(PATHS.BRIEF)
  }

  return (
    <ClientLayout user={currentUser}>
      <div className="brief-step-success">
        <ImageCheck />
        <h1>{t("brief.edit.common.Campaign Updated Successfully!")}</h1>
        <QButton className="w-180" type="primary" onClick={handleFinish}>
          {t("brief.edit.common.Okay!")}
        </QButton>
      </div>
    </ClientLayout>
  )
}
