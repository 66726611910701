import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, QInput, QButton } from 'quantum_components'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '../../core/core.selectors'
import { validateMessages } from '../../../helpers/validationMessage'
import { actions } from '../updateEmail.module'
import { getIsUpdating } from '../updateEmail.selectors'
import { PATHS, STATUS_CODES } from '../../../constants'
import { useHistory } from 'react-router-dom'

export default function UpdateEmailContainer(): ReactElement {
  const { t } = useTranslation(['translationUser', 'translationCommon'])
  const currentUser: any = useSelector(getCurrentUser)
  const dispatch = useDispatch()
  const history = useHistory()
  const updating = useSelector(getIsUpdating)

  useEffect(() => {
    if (currentUser.id != null && currentUser.partnerDepartment && currentUser.partnerDepartment.isOwner == false) {
      history.push(PATHS.PARTNER_DASHBOARD)
    }
  }, [currentUser])

  const onFinish = async (values: any) => {
    const response = await dispatch(actions.updateEmail({ email: values.email, password: values.password }))
    //@ts-ignore
    if (response.data && response.data.status === STATUS_CODES.SUCCESS_201) history.push(PATHS.ROOT)
  }

  return (
    currentUser.id && (
      <div className="container">
        <div className="auth-form">
          <div className="pv-40 text-center">
            <h2>{t('user.change-email.change_your_email')}</h2>
          </div>
          <div className="mb-30">
            <div className="font-weight-500">{t('user.change-email.current_email')}:</div>
            <div className="text-primary">{currentUser.email}</div>
          </div>
          <Form className="mb-30" layout="vertical" validateMessages={validateMessages} onFinish={onFinish}>
            <div className="q-form-item-wrapper">
              <Form.Item
                label={t('user.change-email.new_email')}
                name="email"
                rules={[{ required: true, max: 255, type: 'email', whitespace: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput size="large" placeholder={t('user.change-email.enter_email')} autoComplete="off" />
              </Form.Item>
            </div>
            <div className="q-form-item-wrapper">
              <Form.Item
                label={t('user.change-email.enter_password_for_confirmation')}
                name="password"
                rules={[{ required: true, whitespace: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput
                  size="large"
                  placeholder={t('user.change-email.enter_password')}
                  type="password"
                  autoComplete="off"
                />
              </Form.Item>
            </div>
            <QButton disabled={updating} className="mt-16" type="primary" block htmlType="submit">
              {t('user.change-email.change_email')}
            </QButton>
          </Form>
        </div>
      </div>
    )
  )
}
