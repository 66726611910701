import React, { ReactElement } from 'react'
import { Col, QSelectItem } from 'quantum_components'
import { ReactComponent as Box } from '../../../../../assets/icons/package-type/box.svg'
import { ReactComponent as BoxActive } from '../../../../../assets/icons/package-type/box2.svg'
import { ReactComponent as Can } from '../../../../../assets/icons/package-type/can.svg'
import { ReactComponent as CanActive } from '../../../../../assets/icons/package-type/can2.svg'
import { ReactComponent as FlatSachet } from '../../../../../assets/icons/package-type/flat-sachet.svg'
import { ReactComponent as FlatSachetActive } from '../../../../../assets/icons/package-type/flat-sachet2.svg'
import { ReactComponent as GlassBottle } from '../../../../../assets/icons/package-type/glass-bottle.svg'
import { ReactComponent as GlassBottleActive } from '../../../../../assets/icons/package-type/glass-bottle2.svg'
import { ReactComponent as GlassVial } from '../../../../../assets/icons/package-type/glass-vial.svg'
import { ReactComponent as GlassVialActive } from '../../../../../assets/icons/package-type/glass-vial2.svg'
import { ReactComponent as PlasticBottle } from '../../../../../assets/icons/package-type/plastic-bottle.svg'
import { ReactComponent as PlasticBottleActive } from '../../../../../assets/icons/package-type/plastic-bottle2.svg'
import { ReactComponent as PlasticVial } from '../../../../../assets/icons/package-type/plastic-vial.svg'
import { ReactComponent as PlasticVialActive } from '../../../../../assets/icons/package-type/plastic-vial2.svg'
import { ReactComponent as PolyBag } from '../../../../../assets/icons/package-type/polybag.svg'
import { ReactComponent as PolyBagActive } from '../../../../../assets/icons/package-type/polybag2.svg'
import { ReactComponent as Other } from '../../../../../assets/icons/package-type/other.svg'
import { ReactComponent as OtherActive } from '../../../../../assets/icons/package-type/other2.svg'

const PACKAGE_TYPES_IMAGE: { [key: string]: ReactElement } = {
  Box: <Box />,
  Box_active: <BoxActive />,
  Can: <Can />,
  Can_active: <CanActive />,
  'Flat Sachet': <FlatSachet />,
  'Flat Sachet_active': <FlatSachetActive />,
  'Glass Bottle': <GlassBottle />,
  'Glass Bottle_active': <GlassBottleActive />,
  'Glass Vial': <GlassVial />,
  'Glass Vial_active': <GlassVialActive />,
  'Plastic Bottle': <PlasticBottle />,
  'Plastic Bottle_active': <PlasticBottleActive />,
  'Plastic Vial': <PlasticVial />,
  'Plastic Vial_active': <PlasticVialActive />,
  Polybag: <PolyBag />,
  Polybag_active: <PolyBagActive />,
  Other: <Other />,
  Other_active: <OtherActive />,
}

interface IProps {
  checked: boolean
  name: string
  onChange: (e: MouseEvent, name: string) => void
}
export default function TestahelPackageTypeCard({ checked, name, onChange }: IProps): ReactElement {
  const imageName = checked ? name + '_active' : name
  return (
    <Col xs={8} key={name}>
      <QSelectItem
        className={'package-card mb-16'}
        label={name}
        checked={checked}
        cardImage={PACKAGE_TYPES_IMAGE[imageName]}
        onChange={(e: MouseEvent) => onChange(e, name)}
      />
    </Col>
  )
}
