import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { IUpdateEmailResponse } from './updateEmail.types'
import * as CONSTANTS from './updateEmail.constants'
import { getResponseErrorMessage } from 'src/helpers'
import { getCurrentUserRole } from '../core/core.selectors'
import { ROLES } from 'src/constants'
import { getUserSuccess } from '../core/core.actions'

export const updateEmailRequest = (): IAction => ({
  type: CONSTANTS.UPDATE_EMAIL_REQUEST,
})

export const updateEmailSuccess = (): IAction => ({
  type: CONSTANTS.UPDATE_EMAIL_SUCCESS,
})

export const updateEmailFailure = (error: string): IAction => ({
  type: CONSTANTS.UPDATE_EMAIL_FAILURE,
  error,
})

export const updateEmail = (data: {
  email: string
  password: string
}): ThunkAction<Promise<AxiosResponse<IUpdateEmailResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: any
): Promise<AxiosResponse<IUpdateEmailResponse>> => {
  try {
    const role = getCurrentUserRole(getState())
    const url = role === ROLES.CLIENT ? urls.getClientUpdateEmailUrl() : urls.getPartnerUpdateEmailUrl()
    dispatch(updateEmailRequest())
    const response = await requestHttp.post<IUpdateEmailResponse>(url, data)

    const { content } = response.data.data
    dispatch(updateEmailSuccess())
    dispatch(getUserSuccess(content))

    return response
  } catch (error: any) {
    dispatch(updateEmailFailure(getResponseErrorMessage(error)))

    return error
  }
}
