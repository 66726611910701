export const MODULE_NAME = 'notifications'

/* actions */

export const NOTIFICATIONS_RESET = `${MODULE_NAME}/NOTIFICATIONS_RESET`

export const FETCH_NOTIFICATIONS_REQUEST = `${MODULE_NAME}/FETCH_NOTIFICATIONS_REQUEST`
export const FETCH_NOTIFICATIONS_SUCCESS = `${MODULE_NAME}/FETCH_NOTIFICATIONS_SUCCESS`
export const FETCH_NOTIFICATIONS_FAILURE = `${MODULE_NAME}/FETCH_NOTIFICATIONS_FAILURE`

export const UPDATE_NOTIFICATIONS_REQUEST = `${MODULE_NAME}/UPDATE_NOTIFICATIONS_REQUEST`
export const UPDATE_NOTIFICATIONS_SUCCESS = `${MODULE_NAME}/UPDATE_NOTIFICATIONS_SUCCESS`
export const UPDATE_NOTIFICATIONS_FAILURE = `${MODULE_NAME}/UPDATE_NOTIFICATIONS_FAILURE`
