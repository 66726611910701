import { createSelector } from 'reselect'
import { MODULE_NAME } from './profile.constants'
import { IClient } from './profile.reducer'

const selectState = (state: { [MODULE_NAME]: IClient }): IClient => state[MODULE_NAME]
export const getCurrentStep = createSelector(selectState, state => state.currentStep)
export const getFilledSteps = createSelector(selectState, (state): object => state.filledSteps)
export const getSettings = createSelector(selectState, (state): object => state.settings)
export const getIsImagesUploading = createSelector(selectState, (state): boolean => state.imagesUploading)
export const getClientInfo = createSelector(selectState, (state): any => state.clientInfo)
export const getFilledData = createSelector(selectState, (state): object => state.filledData)
export const getIsLoading = createSelector(
  selectState,
  (state): boolean => state.clientInfoLoading || state.settingsLoading
)
export const getIsUpdating = createSelector(selectState, (state): boolean => state.profileUpdating)
export const getClientProfilePercent = createSelector(selectState, state => state.percent)
