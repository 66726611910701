import React, { useEffect, useMemo } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import getRoutes from './routes'
import NotFound from '../modules/core/containers/NotFound'
import requireLogin from './guards/requireLogin'
import requireRole from './guards/requireRole'
import requireHome from './guards/requireHome'
import history from '../utils/history'
import ReactGA from 'react-ga'
import { LOCAL_STORAGE_KEYS } from 'src/constants'

const GLOBAL_GUARDS = [requireLogin, requireRole, requireHome]

interface Props {
  children: any
}

if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLE) === 'CLIENT') {
  ReactGA.initialize(process.env.REACT_APP_CLIENT_GA_TRACKER_ID || '')
} else {
  ReactGA.initialize(process.env.REACT_APP_PARTNER_GA_TRACKER_ID || '')
}

const exactPages = [
  '/briefs',
  '/campaigns',
  '/marketplace',
  '/briefs/create',
  '/partner/dashboard',
  '/requests',
  '/partner/campaigns',
  '/partner/partner-profile',
  '/partner/public-profile',
  '/partner/team-members',
  '/partner/invite-client',
]

// Initialize google analytics page view tracking
history.listen((location: any) => {
  if (
    exactPages.indexOf(location.pathname) > -1 ||
    location.pathname.includes('/briefs/view') ||
    location.pathname.includes('/briefs/edit')
  ) {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }
})

const AppRouter = ({ children }: Props) => {
  useEffect(() => {
    if (
      exactPages.indexOf(window.location.pathname) > -1 ||
      window.location.pathname.includes('/briefs/view') ||
      window.location.pathname.includes('/briefs/edit')
    ) {
      ReactGA.set({ page: window.location.pathname })
      ReactGA.pageview(window.location.pathname)
    }
  }, [])

  const routes = useMemo(() => getRoutes(), [])
  return (
    <Router history={history}>
      <GuardProvider guards={GLOBAL_GUARDS} loading="Loading..." error={NotFound}>
        <Route
          render={routeProps =>
            children(
              <Switch>
                {routes.map(({ component, error, exact, ignoreGlobal, loading, meta, path }, i) => (
                  <GuardedRoute
                    key={i}
                    component={component}
                    exact={exact}
                    error={error}
                    ignoreGlobal={ignoreGlobal}
                    loading={loading}
                    meta={meta}
                    path={path}
                  />
                ))}
              </Switch>,
              routeProps
            )
          }
        />
      </GuardProvider>
    </Router>
  )
}

export default AppRouter
