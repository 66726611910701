import React, { ReactElement } from 'react'
import { ICampaignReportData } from '../../campaignV3.types'
import Blue_Strip_Image from 'src/assets/ReportImages/blue-strip.png'
import Logo_Image from 'src/assets/ReportImages/logo.png'
import { Col, QPieChart, Row } from 'quantum_components'
import { abbreviateNumberWithoutDecimal } from 'src/helpers/abbreviateNumber'
import './styles.scss'
import moment from 'moment'
import { FORMATS } from 'src/constants'

interface IProps {
  campaignReportDetails: ICampaignReportData
}

export default function ReportPage2({ campaignReportDetails }: IProps): ReactElement {
  return (
    <div className="report-page-container flex-column report-page2">
      <img className="second-page-green-strip-image" alt="green-strip-image" src={Blue_Strip_Image} />
      <h3 className="second-page-heading">Campaign Brief</h3>
      <img className="second-page-logo-image" alt="logo-image" src={Logo_Image} />

      <Row className="second-page-brief-details" justify="start" align="middle" gutter={[64, 64]}>
        <Col span={12}>
          <Row align="middle" justify={'center'}>
            <Col span={24}>
              <h3>Total Impressions</h3>
            </Col>
            <Col span={24}>
              <h2>{campaignReportDetails?.impressions ? abbreviateNumberWithoutDecimal(campaignReportDetails?.impressions) : '-'}</h2>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row align="middle" justify={'center'}>
            <Col span={24}>
              <h3>Total Clicks</h3>
            </Col>
            <Col span={24}>
              <h2>{campaignReportDetails?.clicks ? abbreviateNumberWithoutDecimal(campaignReportDetails?.clicks) : '-'}</h2>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row align="middle" justify={'center'}>
            <Col span={24}>
              <h3>Campaign Duration</h3>
            </Col>
            <Col span={24}>
              <h2>
                {moment(campaignReportDetails?.startDate).format(FORMATS.DATE_MM_FORMAT)}
                {' - '}
                {moment(campaignReportDetails?.endDate).format(FORMATS.DATE_MM_FORMAT)}
              </h2>
            </Col>
          </Row>
        </Col>
        {
          campaignReportDetails?.targeting &&
          Object.keys(campaignReportDetails?.targeting).slice(0, 3).map((key: any) => {
            return <Col span={12} key={key}>
              <Row align="middle" justify={'center'}>
                <Col span={24}>
                  <h3>Targeted {key}</h3>
                </Col>
                <Col span={24}>
                  <h2>{campaignReportDetails?.targeting[key] ? campaignReportDetails?.targeting[key]?.join(', ') : '-'}</h2>
                </Col>
              </Row>
            </Col>
          })
        }

        {
          (campaignReportDetails?.impressions || campaignReportDetails?.clicks) && (Number(campaignReportDetails?.impressions) > 0 || Number(campaignReportDetails?.clicks) > 0) &&
          <Col span={24}>
            <div className='pie-chart'>
              <QPieChart
                data={[{ name: 'Impressions', value: campaignReportDetails?.impressions }, { name: 'Clicks', value: campaignReportDetails?.clicks }]}
                // @ts-ignore
                height={650}
                minWidth={600}
                innerRadius={140}
                outerRadius={240}
                chartArrow={false}
                textColor={'#173994'}
                textFontSize={'32'}
                weeklyReport={true}
                colors={["#00eb7d", "#eb4b2d"]}
              />
            </div>
          </Col>
        }
      </Row>
    </div>
  )
}
