/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { QInput, QSelect, Row, Col, Form, Radio, QRadio } from 'quantum_components'
import { useSelector } from 'react-redux'

import { useReduxDispatch } from '../../../../../helpers'
import { actions } from '../../clientProfile.module'

import {
  BASIC_INFO,
  BASIC_INFO_LABELS,
  BASIC_INFO_NAMES,
  BASIC_INFO_VALUES as VALUES,
  BASIC_INFO_INITIAL_VALUES,
  TAX_TREATMENT,
} from 'src/constants/clientProfile'
import UploadComponent from '../../../../../components/UploadComponent'
import IUploadFile from '../../../../../interfaces/IUploadFile'
import { getIsImagesUploading } from '../../profile.selectors'
import { getFilledData } from '../../profile.selectors'
import { createObj } from '../../../../../helpers/createObjectWithOldFields'
import LocationSearchInput from '../components/LocationSearchInput'
import { REGEXES } from 'src/constants'
import { currencyOptions } from '../../../../../helpers/currency'

export function ClientsProfileBody() {
  const { t } = useTranslation(['translationUser', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const [form] = Form.useForm()
  const filledData = useSelector(getFilledData)

  const stepData = createObj(BASIC_INFO_INITIAL_VALUES, filledData)

  const [files, setFiles] = useState<IUploadFile[]>([])
  const [errors, setErrors] = useState(BASIC_INFO_INITIAL_VALUES)
  const [errorText, setErrorText] = useState(BASIC_INFO_INITIAL_VALUES)

  const isImagesUploading = useSelector(getIsImagesUploading)

  const changeStepValue = (field, value) =>
    dispatch(
      actions.setStepData({
        ...stepData,
        [field]: value,
      })
    )

  const onChangeSelectHandler = field => value => changeStepValue(field, value)

  const onChangeInputHandler = field => event => changeStepValue(field, event.currentTarget.value)

  const filesMapper = () => {
    const value = stepData[VALUES[BASIC_INFO.PROFILE_PICTURE]]
    if (value.length === 0) return
    setFiles([
      {
        uid: value + '',
        name: value?.split('/').slice(-1)[0],
        status: 'done',
        url: value,
      } as IUploadFile,
    ])
  }

  useEffect(() => {
    filesMapper()
  }, [stepData[VALUES[BASIC_INFO.PROFILE_PICTURE]].length])

  const handleUpload = (files: File[]) => {
    dispatch(actions.uploadProductImage(files))
  }

  const handleRemove = (file: IUploadFile) => {
    // dispatch(actions.removeImage(file, isUpdateUserData))
    setFiles([])
    dispatch(
      actions.setStepData({
        ...stepData,
        [VALUES[BASIC_INFO.PROFILE_PICTURE]]: '',
      })
    )
  }

  const onChangeInputWithValidation =
    (field: string, validator: RegExp, errorTextHelp: string, required: boolean) => e => {
      const value = e.currentTarget.value.trim()
      changeStepValue(field, value)
      if (required ? RegExp(validator).test(value) : value.length === 0 || RegExp(validator).test(value)) {
        setErrors({ ...errors, [field]: '' })
        setErrorText({ ...errorText, [field]: '' })
      } else {
        setErrors({ ...errors, [field]: 'error' })
        setErrorText({ ...errorText, [field]: errorTextHelp })
      }
    }

  return (
    <Form layout="vertical" form={form} initialValues={stepData}>
      <div className={`form form-brief form--horizontal`}>
        <div className="form__head">
          <h3>{t('client.profile.view.basic_information')}</h3>
        </div>
        <div className="form__cont">
          <div className="mb-20">
            <Row gutter={16} align="middle">
              <Col xs={24} sm={24} md={10}>
                <label htmlFor="productName" className="form-label">
                  {t(BASIC_INFO_LABELS.PROFILE_PICTURE)}
                </label>
              </Col>
              <Col xs={24} sm={24} md={14}>
                <UploadComponent
                  files={files}
                  onChange={handleUpload}
                  onRemove={handleRemove}
                  isUploading={isImagesUploading}
                />
              </Col>
            </Row>
          </div>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="firstName" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.FIRST_NAME)} *
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item validateStatus={errors[VALUES[BASIC_INFO.FIRST_NAME]]} name="firstName">
                <QInput
                  placeholder={t('client.profile.edit.enter_first_name')}
                  value={stepData[VALUES[BASIC_INFO.FIRST_NAME]]}
                  onChange={onChangeInputWithValidation(VALUES[BASIC_INFO.FIRST_NAME], REGEXES.EMPTY_FIELD, '', true)}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="lastName" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.LAST_NAME)} *
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item validateStatus={errors[VALUES[BASIC_INFO.LAST_NAME]]} name="lastName" className="mb-0">
                <QInput
                  placeholder={t('client.profile.edit.enter_last_name')}
                  value={stepData[VALUES[BASIC_INFO.LAST_NAME]]}
                  onChange={onChangeInputWithValidation(VALUES[BASIC_INFO.LAST_NAME], REGEXES.EMPTY_FIELD, '', true)}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="companyName" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.COMPANY_NAME)}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item name="companyName">
                <QInput
                  placeholder={t('client.profile.edit.enter_company_name')}
                  value={stepData[VALUES[BASIC_INFO.COMPANY_NAME]]}
                  onChange={onChangeInputHandler(VALUES[BASIC_INFO.COMPANY_NAME])}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="companyWebsite" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.COMPANY_WEBSITE)}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                validateStatus={errors[VALUES[BASIC_INFO.COMPANY_WEBSITE]]}
                help={errorText[VALUES[BASIC_INFO.COMPANY_WEBSITE]]}
                name="companyWebsite"
                className="mb-0"
              >
                <QInput
                  placeholder={t('client.profile.edit.enter_website_url')}
                  value={stepData[VALUES[BASIC_INFO.COMPANY_WEBSITE]]}
                  onChange={onChangeInputWithValidation(
                    VALUES[BASIC_INFO.COMPANY_WEBSITE],
                    REGEXES.URL,
                    'Please enter correct website URL including https://'
                  )}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="email" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.OFFICIAL_EMAIL)}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item name="email">
                <div disabled className="qu-input ant-input-lg disabledField">
                  {stepData[VALUES[BASIC_INFO.OFFICIAL_EMAIL]]}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="phone" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.PHONE_NUMBER)}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                name="phone"
                validateStatus={errors[VALUES[BASIC_INFO.PHONE_NUMBER]]}
                help={errorText[VALUES[BASIC_INFO.PHONE_NUMBER]]}
                required
              >
                <QInput
                  placeholder={t('client.profile.edit.your_phone_number')}
                  value={stepData[VALUES[BASIC_INFO.PHONE_NUMBER]]}
                  onChange={onChangeInputWithValidation(
                    VALUES[BASIC_INFO.PHONE_NUMBER],
                    REGEXES.PHONE_BODY_REGEX,
                    'Please enter correct phone number'
                  )}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} md={10}>
              <label htmlFor="location" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.LOCATION)}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item name="location">
                <LocationSearchInput
                  value={stepData[VALUES[BASIC_INFO.LOCATION]]}
                  onSelectHandler={onChangeSelectHandler(VALUES[BASIC_INFO.LOCATION])}
                  onChange={onChangeSelectHandler(VALUES[BASIC_INFO.LOCATION])}
                  placeholder={t('client.profile.edit.your_location')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} md={10}>
              <label htmlFor="currency" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.CURRENCY)}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item name="currency">
                <QSelect
                  value={stepData[VALUES[BASIC_INFO.CURRENCY]]}
                  onChange={onChangeSelectHandler(VALUES[BASIC_INFO.CURRENCY])}
                  options={currencyOptions}
                  placeholder={t('client.profile.edit.select_currency')}
                  size="large"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="taxTreatment" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.TAX_TREATMENT)}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className='tax-treatment'
                name="taxTreatment"
                validateStatus={errors[VALUES[BASIC_INFO.TAX_TREATMENT]]}
                required
              >
                <Radio.Group
                  onChange={(e) => {
                    if (e.target.value === TAX_TREATMENT.NON_VAT_REGISTERED) {
                      dispatch(
                        actions.setStepData({
                          ...stepData,
                          [VALUES[BASIC_INFO.TAX_TREATMENT]]: e.target.value,
                          [VALUES[BASIC_INFO.VAT]]: "",
                        })
                      )
                      form.setFieldsValue({ vat: '' })
                      setErrors({ ...errors, vat: '' })
                      setErrorText({ ...errorText, vat: '' })
                    } else {
                      dispatch(
                        actions.setStepData({
                          ...stepData,
                          [VALUES[BASIC_INFO.TAX_TREATMENT]]: e.target.value,
                        })
                      )
                    }
                  }}
                >
                  <QRadio key={TAX_TREATMENT.VAT_REGISTERED} value={TAX_TREATMENT.VAT_REGISTERED}>
                    {t('client.profile.edit.VAT Registered')}
                  </QRadio>
                  <QRadio key={TAX_TREATMENT.NON_VAT_REGISTERED} value={TAX_TREATMENT.NON_VAT_REGISTERED}>
                    {t('client.profile.edit.Non-VAT Registered')}
                  </QRadio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="vat" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.VAT)}{form.getFieldValue('taxTreatment') === TAX_TREATMENT.VAT_REGISTERED ? ' *' : ''}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                name="vat"
                validateStatus={errors[VALUES[BASIC_INFO.VAT]]}
                help={errorText[VALUES[BASIC_INFO.VAT]]}
                required
              >
                <QInput
                  placeholder={t('client.profile.edit.enter_vat')}
                  value={stepData[VALUES[BASIC_INFO.VAT]]}
                  onChange={onChangeInputWithValidation(
                    VALUES[BASIC_INFO.VAT],
                    REGEXES.FIFTEEN_DIGIT,
                    t('client.profile.edit.vat_validation'),
                    form.getFieldValue('taxTreatment') === TAX_TREATMENT.VAT_REGISTERED ? true : false
                  )}
                  disabled={form.getFieldValue('taxTreatment') === TAX_TREATMENT.NON_VAT_REGISTERED ? true : false}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="cr" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.CR)} *
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item validateStatus={errors[VALUES[BASIC_INFO.CR]]} help={errorText[VALUES[BASIC_INFO.CR]]} name="cr">
                <QInput
                  placeholder={t('client.profile.edit.enter_cr')}
                  value={stepData[VALUES[BASIC_INFO.CR]]}
                  onChange={onChangeInputWithValidation(VALUES[BASIC_INFO.CR], REGEXES.TEN_DIGIT, t('client.profile.edit.cr_validation'), true)}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="nationalAddress" className="form-label mt-10">
                {t(BASIC_INFO_LABELS.NATIONAL_ADDRESS)} *
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item validateStatus={errors[VALUES[BASIC_INFO.NATIONAL_ADDRESS]]} help={errorText[VALUES[BASIC_INFO.NATIONAL_ADDRESS]]} name="nationalAddress">
                <QInput
                  placeholder={t('client.profile.edit.enter_national_address')}
                  value={stepData[VALUES[BASIC_INFO.NATIONAL_ADDRESS]]}
                  onChange={onChangeInputWithValidation(VALUES[BASIC_INFO.NATIONAL_ADDRESS], REGEXES.EMPTY_FIELD, t('client.profile.edit.national_address_validation'), true)}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  )
}
