import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import first from 'lodash/first'
import cloneDeep from 'lodash/cloneDeep'

import { requestHttp, urls } from 'src/api'
import { FORMATS } from 'src/constants'
import { UPLOAD_ENTITY_KEYS } from 'src/constants/upload'
import { BRIEF_UPLOAD_TYPES } from 'src/constants/brief'
import { getFileNameFromUrl } from 'src/utils'
import { getResponseErrorMessage } from 'src/helpers'
import { upload } from 'src/modules/core/core.actions'
import IAction from 'src/interfaces/IAction'
import IUploadFile from 'src/interfaces/IUploadFile'

import { IBillListElement, IBillListResponse, IBillPaymentUploadTypes, IBillTableParams } from './bills.types'
import * as CONSTANTS from './bills.constants'

export const billListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_BILLS_REQUEST,
})

export const billListSuccess = (billList: { results: IBillListElement[]; total: number }): IAction => ({
  type: CONSTANTS.FETCH_BILLS_SUCCESS,
  payload: { billList },
})

export const billListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_BILLS_FAILURE,
  error,
})

export const setBillListParams = (params: IBillTableParams): IAction => ({
  type: CONSTANTS.SET_BILL_LIST_PARAMS,
  payload: { params },
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const fetchBillList =
  (params: IBillTableParams): ThunkAction<Promise<AxiosResponse<IBillListResponse>>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IBillListResponse>> => {
    try {
      dispatch(billListRequest())
      const response = await requestHttp.get<IBillListResponse>(urls.getBillListUrl(), {
        params,
      })
      const {
        content: { bills, total },
      } = response.data.data

      const billList = bills.results.map<IBillListElement>(bill => ({
        key: bill.id,
        brief: { campaignName: bill.campaignName, name: bill.name, image: first(bill.productImages) ?? null },
        payment: {
          ...bill.payment,
          po: {
            isUploading: false,
            uploadingError: '',
            isRemoving: false,
            removingError: '',
            files: bill.payment.po?.map(url => ({ name: getFileNameFromUrl(url), status: 'done', url })) ?? [],
          },
          bankTransfer: {
            isUploading: false,
            uploadingError: '',
            isRemoving: false,
            removingError: '',
            files:
              bill.payment.bankTransfer?.map(url => ({ name: getFileNameFromUrl(url), status: 'done', url })) ?? [],
          },
          createdAt: moment(bill.payment.createdAt).format(FORMATS.DATE_LL_FORMAT),
          updatedAt: moment(bill.payment.createdAt).format(FORMATS.DATE_LL_FORMAT),
        },
        version:bill.version
      }))

      dispatch(billListSuccess({ results: billList, total: total }))
      return response
    } catch (error: any) {
      dispatch(billListFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const setParamsAndFetch =
  (params: IBillTableParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setBillListParams(params))
    dispatch(fetchBillList(params))
  }

export const uploadPaymentFile =
  ({
    bill,
    files,
    filesType,
  }: {
    bill: IBillListElement
    files: File[]
    filesType: IBillPaymentUploadTypes
  }): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fileUploadRequest({ bill, filesType }))

      const briefId = bill.payment.briefId
      const response = await upload(
        urls.getBriefUploadUrl(),
        files,
        briefId,
        BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
        UPLOAD_ENTITY_KEYS.BRIEF_ID
      )

      if (response.status === 201) {
        const { content: remoteServerUrls }: { content: string[] } = response.data.data
        const newBill = cloneDeep(bill)
        newBill.payment[filesType].files = [
          ...newBill.payment[filesType].files,
          ...remoteServerUrls.map<IUploadFile>((url: string) => ({
            name: getFileNameFromUrl(url),
            status: 'done',
            url,
          })),
        ]

        await requestHttp.put(urls.getUpdateBillFilesLinksUrl(), {
          id: newBill.payment.id,
          po: newBill.payment.po.files.map(({ url }) => url),
          bankTransfer: newBill.payment.bankTransfer.files.map(({ url }) => url),
        })

        dispatch(fileUploadSuccess({ bill: newBill, filesType }))
      }
    } catch (error: any) {
      dispatch(fileUploadFailure({ bill, filesType, error }))
    }
  }

export const fileUploadRequest = ({
  bill,
  filesType,
}: {
  bill: IBillListElement
  filesType: IBillPaymentUploadTypes
}): IAction => ({
  type: CONSTANTS.FILE_UPLOAD_REQUEST,
  payload: { bill, filesType },
})

export const fileUploadSuccess = ({
  bill,
  filesType,
}: {
  bill: IBillListElement
  filesType: IBillPaymentUploadTypes
}): IAction => ({
  type: CONSTANTS.FILE_UPLOAD_SUCCESS,
  payload: { bill, filesType },
})

export const fileUploadFailure = ({
  bill,
  filesType,
  error,
}: {
  bill: IBillListElement
  filesType: IBillPaymentUploadTypes
  error: string
}): IAction => ({
  type: CONSTANTS.FILE_UPLOAD_FAILURE,
  payload: { bill, filesType, error },
})

export const removePaymentFile =
  ({
    bill,
    filesType,
  }: {
    bill: IBillListElement
    filesType: IBillPaymentUploadTypes
  }): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fileRemoveRequest({ bill, filesType }))

      const data = {
        briefId: String(bill.payment.briefId),
        entity: BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
        urls: bill.payment[filesType].files.map(({ url }) => url),
      }
      const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), { data })

      if (response.status === 201) {
        const newBill = cloneDeep(bill)
        newBill.payment[filesType].files = []

        await requestHttp.put(urls.getUpdateBillFilesLinksUrl(), {
          id: newBill.payment.id,
          po: newBill.payment.po.files.map(({ url }) => url),
          bankTransfer: newBill.payment.bankTransfer.files.map(({ url }) => url),
        })

        dispatch(fileRemoveSuccess({ bill: newBill, filesType }))
      }
    } catch (error: any) {
      dispatch(fileRemoveFailure({ bill, filesType, error }))
    }
  }

export const fileRemoveRequest = ({
  bill,
  filesType,
}: {
  bill: IBillListElement
  filesType: IBillPaymentUploadTypes
}): IAction => ({
  type: CONSTANTS.FILE_REMOVE_REQUEST,
  payload: { bill, filesType },
})

export const fileRemoveSuccess = ({
  bill,
  filesType,
}: {
  bill: IBillListElement
  filesType: IBillPaymentUploadTypes
}): IAction => ({
  type: CONSTANTS.FILE_REMOVE_SUCCESS,
  payload: { bill, filesType },
})

export const fileRemoveFailure = ({
  bill,
  filesType,
  error,
}: {
  bill: IBillListElement
  filesType: IBillPaymentUploadTypes
  error: string
}): IAction => ({
  type: CONSTANTS.FILE_REMOVE_FAILURE,
  payload: { bill, filesType, error },
})
