import React from 'react'
import { MEDIA_GROUP_TYPE, SERVICE_NAMES_FOR_TABLE } from '../../../../../constants/testahel_brief'

interface Props {
  isProductSample: boolean
  isCustomizeBox: boolean
  isMediaBuying: boolean
  isConsumerResearch: boolean
  distributionItem: SERVICE_NAMES_FOR_TABLE
  onEdit: () => void
  viewMode?: boolean
  requestType?: MEDIA_GROUP_TYPE
}
const TestahelServiceCard = ({
  isProductSample,
  isCustomizeBox,
  isMediaBuying,
  isConsumerResearch,
  distributionItem,
  onEdit,
  viewMode,
  requestType,
}: Props) => {
  return (
    <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>Services</h4>
        {!viewMode && <span onClick={onEdit}>Edit</span>}
      </div>
      <div className="services mb-15">
        {requestType ? (
          <ul className="p p--sm cart-summary__ulist mb-0">
            <li className={'BSUnit__list-item'}>{requestType}</li>
          </ul>
        ) : (
          <ul className="p p--sm cart-summary__ulist mb-0">
            <li className={`BSUnit__list-item ${!isProductSample && 'disabled'}`}>{distributionItem}</li>
            <li className={`BSUnit__list-item ${!isCustomizeBox && 'disabled'}`}>Customize Box</li>
            <li className={`BSUnit__list-item ${!isMediaBuying && 'disabled'}`}>Media Buying</li>
            <li className={`BSUnit__list-item ${!isConsumerResearch && 'disabled'}`}>Consumer Research</li>
          </ul>
        )}
      </div>
    </div>
  )
}

export default TestahelServiceCard
