export const MODULE_NAME = 'clientProfile'

export const SET_CURRENT_STEP = `${MODULE_NAME}/SET_CURRENT_STEP`
export const SET_LAST_FINISHED = `${MODULE_NAME}/SET_LAST_FINISHED`
export const SET_FILLED = `${MODULE_NAME}/SET_FILLED`
export const SET_STEP_DATA = `${MODULE_NAME}/SET_STEP_DATA`
export const RESET_CLIENT_STATE = `${MODULE_NAME}/RESET_CLIENT_STATE`

export const CREATE_CLIENT_REQUEST = `${MODULE_NAME}/CREATE_CLIENT_REQUEST`
export const CREATE_CLIENT_SUCCESS = `${MODULE_NAME}/CLIENT_SUCCESS`
export const CREATE_CLIENT_FAILURE = `${MODULE_NAME}/CREATE_CLIENT_FAILURE`

export const FETCH_CLIENT_SETTINGS_REQUEST = `${MODULE_NAME}/FETCH_CLIENT_SETTINGS_REQUEST`
export const FETCH_CLIENT_SETTINGS_SUCCESS = `${MODULE_NAME}/FETCH_CLIENT_SETTINGS_SUCCESS`
export const FETCH_CLIENT_SETTINGS_FAILURE = `${MODULE_NAME}/FETCH_CLIENT_SETTINGS_FAILURE`

export const IMAGE_UPLOAD_REQUEST = `${MODULE_NAME}/IMAGE_UPLOAD_REQUEST`
export const IMAGE_UPLOAD_SUCCESS = `${MODULE_NAME}/IMAGE_UPLOAD_SUCCESS`
export const IMAGE_UPLOAD_FAILURE = `${MODULE_NAME}/IMAGE_UPLOAD_FAILURE`

export const IMAGE_REMOVE_REQUEST = `${MODULE_NAME}/IMAGE_REMOVE_REQUEST`
export const IMAGE_REMOVE_SUCCESS = `${MODULE_NAME}/IMAGE_REMOVE_SUCCESS`
export const IMAGE_REMOVE_FAILURE = `${MODULE_NAME}/IMAGE_REMOVE_FAILURE`

export const FETCH_CLIENT_INFO_REQUEST = `${MODULE_NAME}/FETCH_CLIENT_INFO_REQUEST`
export const FETCH_CLIENT_INFO_SUCCESS = `${MODULE_NAME}/FETCH_CLIENT_INFO_SUCCESS`
export const FETCH_CLIENT_INFO_FAILURE = `${MODULE_NAME}/FETCH_CLIENT_INFO_FAILURE`

export const SET_PERCENT = `${MODULE_NAME}/SET_PERCENT`
