import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import * as actions from '../../../modules/core/core.actions'
import * as notificationsSelectors from '../../../modules/notifications/notifications.selectors'
import * as notificationsActions from '../../../modules/notifications/notifications.actions'
import { useReduxDispatch } from '../../../helpers'
import { QPartnerHeader, QSidenav, QUserMenu, Menu } from 'quantum_components'
import { ReactComponent as KeyIcon } from '../../../assets/icons/key-icon.svg'
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout-icon.svg'
import SidebarMenu from '../../Layouts/PartnerLayout/SidebarMenu'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { PATHS } from '../../../constants'
import { ICurrentUser } from '../../../modules/core/core.types'
import { INotificationList } from 'src/modules/notifications/notifications.types'
import { getInitials } from 'src/utils/getInitialsFromName'
import { MailOutlined } from '@ant-design/icons'
import CurrencyDropdown from '../../CurrencyDropdown/CurrencyDropdown'
import * as selectors from 'src/modules/core/core.selectors'
import NotificationMenu from '../../NotificationMenu/NotificationMenu'

const LIMIT = 10

interface IProps {
  user: ICurrentUser
}

const PartnerHeader = ({ user }: IProps) => {
  const dispatch = useReduxDispatch()
  const history = useHistory()
  const location = useLocation()

  const currentUser: any = useSelector(selectors.getCurrentUser)
  const notificationsCount = useSelector(selectors.getNotificationCount)
  const notificationsList = useSelector(notificationsSelectors.getNotificationsList)
  const total = useSelector(notificationsSelectors.getNotificationsTotal)

  const [offset, setOffset] = useState(0)
  const hasMore = notificationsList.length < total

  const onOpenNotificationsHandler = async () => {
    await dispatch(notificationsActions.resetState())
    await dispatch(notificationsActions.fetchNotificationsList({ offset: 0, limit: LIMIT }))
  }

  const fetchNotifications = () => {
    let newOffset = offset + LIMIT
    if (hasMore) {
      setOffset(newOffset)
      dispatch(notificationsActions.fetchNotificationsList({ offset: newOffset, limit: LIMIT }))
    }
  }

  const handleLogout = async () => {
    await dispatch(actions.logout())
    history.push(PATHS.SIGN_IN)
  }

  const getLocationName = () => {
    switch (location.pathname) {
      case PATHS.PUBLIC_PROFILE:
        return 'Profile'
      case PATHS.PARTNER_PROFILE:
        return 'Profile'
      case PATHS.PARTNER_DASHBOARD:
        return 'Dashboard'
      case PATHS.PARTNER_CAMPAIGNS:
        return 'Campaigns'
      case PATHS.REQUESTS:
        return 'Requests'
      case PATHS.TEAM_MEMBERS:
        return 'Team'
      case PATHS.BILLINGS:
        return 'Billings'
    }
  }

  const handleNotificationClick = (e: any, notification: INotificationList) => {
    if (!notification.redirectPath) {
      return
    }
    history.push(notification.redirectPath)
  }

  const menu = (
    <Menu className="user-menu-list">
      <Menu.Item key="101">
        <Link to={PATHS.UPDATE_PASSWORD}>
          <span className="user-menu-icon">
            <KeyIcon />
          </span>
          Update Password
        </Link>
      </Menu.Item>
      {currentUser && currentUser.partnerDepartment && currentUser.partnerDepartment.isOwner == true && (
        <Menu.Item key="102">
          <Link to={PATHS.UPDATE_EMAIL}>
            <span className="user-menu-icon">
              <MailOutlined />
            </span>
            Update Email
          </Link>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="103">
        <div onClick={handleLogout}>
          <span className="user-menu-icon">
            <LogoutIcon />
          </span>
          Log Out
        </div>
      </Menu.Item>
    </Menu>
  )

  const hideCurrencyDialog = () => {
    const exactRoutes = [PATHS.PARTNER_DASHBOARD, PATHS.PARTNER_CAMPAIGNS]
    const detailsRoutes = [PATHS.REQUESTS, PATHS.CAMPAIGNS]

    return (
      exactRoutes.includes(location.pathname) || detailsRoutes.some((path: string) => location.pathname.includes(path))
    )
  }

  return (
    <QPartnerHeader
      childrenSidenav={<QSidenav onLogoLink={() => history.push(PATHS.ROOT)} childrenMenu={<SidebarMenu />} />}
      childrenTitle={<h1>{getLocationName()}</h1>}
      childrenMenus={
        <>
          {/*<QButton className="qu-button-green partner-button mr-15">*/}
          {/*  <span className="mr-5">*/}
          {/*    <UserIcon />*/}
          {/*  </span>*/}
          {/*  Refer a Partner*/}
          {/*</QButton>*/}
          <div className="currency_dropdown">
            <CurrencyDropdown hideDialog={hideCurrencyDialog()} />
          </div>
          <NotificationMenu
            fetchData={fetchNotifications}
            hasMore={hasMore}
            className="partner"
            notificationsData={notificationsList}
            badgeCount={notificationsCount}
            onOpen={onOpenNotificationsHandler}
            onClick={handleNotificationClick}
          />
          <QUserMenu
            menu={menu}
            profileName={`${user.firstName} ${user.lastName}`}
            profileInitials={getInitials(`${user.firstName} ${user.lastName}`)}
          />
        </>
      }
    />
  )
}

export default PartnerHeader
