import React, { ReactElement, useEffect, useState } from 'react'
import { Col, Modal, ProfileCard, Row, QInput, QAlert, QPagination, IconsCommon } from 'quantum_components'
import { useSelector } from 'react-redux'
import * as actions from '../marketplace.actions'
import * as briefActions from '../../../brief/brief.actions'
import { SearchOutlined } from '@ant-design/icons'
import { useReduxDispatch } from '../../../../helpers'
import { getCurrency } from '../../../core/core.selectors'
import {
  calculateLowestValue,
  getDistributionPrices,
  getDynamicDistributionPrices,
  getMediaBuyPrice,
  getMediaBuyPriceForMetrics,
  getMediaBuyPrices,
  getMediaBuyPricesForMetrics,
  renderRoundedNumber,
} from '../../../../helpers/currency'
import SpinnerComponent from '../../../../components/Spinner/SpinnerComponent'
import useDebounce from '../../../../hooks/useDebounce'
import {
  getMarketplaceParams,
  getMarketplacePartners,
  getMarketplacePartnersLoading,
  getActiveSinglePartnerId,
  getPagesForMarketplace,
  getTotalPartners,
  getPartnerLocked,
} from '../marketplace.selectors'
import { IMarketplacePartner } from '../marketplace.types'
import { IPartnerCard } from 'src/modules/brief/brief.types'
import { ALERTS_MSG } from 'src/constants/alerts'
import PartnerDetailsModal from '../../../../components/PartnerDetailsComponents/PartnerDetailsModal'
import { getCurrentUser } from '../../../core/core.selectors'
import { getPartnerFilterSettings } from '../../../brief/brief.selectors'
import { getPartnerDetails, getPartnerDetailsLoading } from '../marketplace.selectors'
import { getSocial } from '../../../../helpers/social'
import { getMediaTypes } from '../../../../helpers/mediaTypes'
import CurrencyDropdown from '../../../../components/CurrencyDropdown/CurrencyDropdown'

const closeIcon = <IconsCommon.IconClose />
const arrowIcon = <IconsCommon.IconArrowHalf />

const DESC = 'Below is a sample of our partner network to use as channels for your goals and target markets.'

export default function Marketplace(): ReactElement {
  const dispatch = useReduxDispatch()
  const currentUser = useSelector(getCurrentUser)
  const params = useSelector(getMarketplaceParams)
  const partners = useSelector(getMarketplacePartners)
  const isLoading = useSelector(getMarketplacePartnersLoading)
  const partnerId = useSelector(getActiveSinglePartnerId)
  const pages = useSelector(getPagesForMarketplace)
  const totalPartners = useSelector(getTotalPartners)
  const partnerLocked = useSelector(getPartnerLocked)
  const filterSettings = useSelector(getPartnerFilterSettings)
  const partnerDetails = useSelector(getPartnerDetails)
  const partnerDetailsLoading = useSelector(getPartnerDetailsLoading)
  const currency = useSelector(getCurrency)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const fetchFilterSettings = () => {
    dispatch(briefActions.fetchPartnerFilterSettings())
  }

  useEffect(() => {
    fetchFilterSettings()
  }, [])

  useEffect(() => {
    dispatch(actions.fetchMarketplacePartners({ ...params, search: debouncedSearch, offset: 0, limit: 12 }))
  }, [debouncedSearch])

  const getCardsPartners = () => {
    const cards = partners.map((partner: IMarketplacePartner) => {
      let card = {} as IPartnerCard

      const distributionPrices = partner.hasDynamicSamplePricing
        ? getDynamicDistributionPrices(partner)
        : getDistributionPrices(partner)
      const servicePrices =
        partner.mbPricingModel === 'metrics'
          ? getMediaBuyPricesForMetrics(partner.availableServices)
          : getMediaBuyPrices(partner.availableServices)
      const distributionLowestPrice = calculateLowestValue(distributionPrices)
      const mediaBuyLowestPrice = calculateLowestValue(servicePrices)

      card.id = partner.userId
      card.headerImg = partner.mainImageUrl
      card.logo = partner.logoUrl
      card.name = partner.name
      card.url = partner.companyWebsite
      card.description = partner.companyDescription
      card.serviceLabel = partner.companyLabel

      card.servicesPackagePrice = renderRoundedNumber(distributionLowestPrice, currency)
      card.servicesDevicePrice = renderRoundedNumber(mediaBuyLowestPrice, currency)

      card.social = getSocial(partner.social)
      if (Object.keys(partner.monthlyOrderRange).length > 0)
        card.social.push({
          name: 'Monthly Order Volume',
          counter: partner.monthlyOrderRange.text ? partner.monthlyOrderRange.text : '',
        })

      partner.mbPricingModel === 'metrics'
        ? partner.availableServices.sort((a, b) => getMediaBuyPriceForMetrics(a) - getMediaBuyPriceForMetrics(b))
        : partner.availableServices.sort((a, b) => getMediaBuyPrice(a) - getMediaBuyPrice(b))
      card.popupServices = getMediaTypes(partner, filterSettings.mediaSubtypes, currency)

      return card
    })

    return cards
  }
  const partnerCards = getCardsPartners()

  const setActivePartner = async (id: number | null) => {
    await dispatch(actions.setActiveSinglePartner(id))
    if (id) await dispatch(actions.fetchPartnerDetails(id))
  }
  const onOpenModal = async (e: MouseEvent, partnerId: string) => {
    await setActivePartner(+partnerId)
  }
  const closeModal = async () => {
    await setActivePartner(null)
    dispatch(actions.resetPartnerDetails())
  }

  const handleLeftArrow = () => {
    if (!partnerId) {
      return
    }

    const activeCard = partnerCards.find((card: IPartnerCard) => card.id === partnerId)
    if (!activeCard) {
      return
    }
    const activeCardIndex = partnerCards.indexOf(activeCard)

    if (activeCardIndex > 0) {
      setActivePartner(partnerCards[activeCardIndex - 1].id)
    }
  }

  const handleRightArrow = () => {
    if (!partnerId) {
      return
    }

    const activeCard = partnerCards.find((card: IPartnerCard) => card.id === partnerId)
    if (!activeCard) {
      return
    }
    const activeCardIndex = partnerCards.indexOf(activeCard)

    if (activeCardIndex < partnerCards.length - 1) {
      setActivePartner(partnerCards[activeCardIndex + 1].id)
    }
  }

  const handleChangePage = (page: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(page))
    dispatch(
      actions.setParamsAndFetchMarketplacePartners({ ...params, offset: (page - 1) * pageSize, limit: pageSize })
    )
  }

  return (
    <div className="qu-partners-content">
      {!currentUser.isApproved && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <QAlert message={ALERTS_MSG.ALERT_BRIEF_LIST_EMPTY} type="warning" className="centered" />
          </Col>
        </Row>
      )}
      <div className="qu-partners-header">
        <Row gutter={[16, 32]} align="middle" justify="space-between">
          {!partnerLocked ? (
            <Col xs={24} sm={16}>
              <h1>
                <span>Quantum Marketplace</span>
              </h1>
              <p>{DESC}</p>
            </Col>
          ) : (
            <Col xs={24} sm={16}>
              <h1>
                <span>Work with {partnerCards[0].name}</span>
              </h1>
              <p>
                Collaborate with {partnerCards[0].name} and effectively meet your marketing goals and target audience.
              </p>
            </Col>
          )}
          {!partnerLocked && (
            <Col xs={24} sm={6}>
              <div className="qu-partners-search">
                <QInput
                  value={search}
                  onChange={(e: any) => setSearch(e.currentTarget.value)}
                  placeholder="Search"
                  size="large"
                  prefix={<SearchOutlined />}
                />
              </div>
            </Col>
          )}
          <Col xs={24} sm={2}>
            <div className={'mt-10'}>
              <CurrencyDropdown size={'large'} hideDialog />
            </div>
          </Col>
        </Row>
      </div>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        partnerCards.length > 0 && (
          <div className="qu-partners-grid">
            <Row gutter={[16, 24]}>
              {partnerCards.map((partner: IPartnerCard) => (
                <Col key={partner.id} xs={24} sm={12} md={6}>
                  <ProfileCard
                    onClick={(e: MouseEvent) => onOpenModal(e, `${partner.id}`)}
                    {...partner}
                    priceBlur={partnerLocked && !currentUser.isApproved ? 'cell-pricing-blur' : ''}
                  />
                </Col>
              ))}
            </Row>
            {!partnerLocked && (
              <Row justify="center">
                <QPagination
                  size="default"
                  defaultCurrent={1}
                  current={pages.currentPage}
                  total={totalPartners}
                  defaultPageSize={12}
                  onChange={handleChangePage}
                />
              </Row>
            )}
          </div>
        )
      )}
      {!isLoading && !partnerCards.length && <Row justify="center">No partners...</Row>}

      <Modal
        wrapClassName="qu-modal qu-modal-details"
        closeIcon={closeIcon}
        visible={!!partnerId}
        onCancel={closeModal}
        footer={
          <div className="footer-arrows">
            <div onClick={handleLeftArrow} className="footer-arrows-left">
              {arrowIcon}
            </div>
            <div onClick={handleRightArrow} className="footer-arrows-right">
              {arrowIcon}
            </div>
          </div>
        }
      >
        {partnerId && <PartnerDetailsModal details={partnerDetails} isLoading={partnerDetailsLoading} />}
      </Modal>
    </div>
  )
}
