import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as notificationsSelectors from "../../../modules/notifications/notifications.selectors";
import * as notificationsActions from "../../../modules/notifications/notifications.actions";
import {
  PATHS,
  COMMON,
  STATUS_CODES,
  LOCAL_STORAGE_KEYS,
  LOCALIZATION_LANGUAGES,
} from "../../../constants";
import {
  QHeader,
  QButton,
  QUserMenu,
  Menu,
  IconsCommon,
  Modal,
} from "quantum_components";
import {
  MailOutlined,
  SwitcherOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as actions from "../../../modules/core/core.actions";
import { useReduxDispatch } from "../../../helpers";
import { ICurrentUser } from "../../../modules/core/core.types";
import { INotificationList } from "src/modules/notifications/notifications.types";
import { getInitials } from "src/utils/getInitialsFromName";
import DiscountForm from "../../Discount/DiscountForm";
import { IReferral } from "../../../modules/client/referral/referral.types";
import * as clientActions from "src/modules/client/referral/referral.actions";
import DiscountThanks from "../../Discount/DiscountThanks";
import { isMobile } from "react-device-detect";
import * as selectors from "../../../modules/core/core.selectors";
import NotificationMenu from "../../NotificationMenu/NotificationMenu";
import LanguageLoader from "src/components/LanguageLoader/LanguageLoader";

const userIcon = <IconsCommon.IconUser />;
const keyIcon = <IconsCommon.IconKey />;
const logoutIcon = <IconsCommon.IconLogout />;
const closeIcon = <IconsCommon.IconClose />;
const billIcon = <IconsCommon.IconBill />;

const LIMIT = 10;

interface IProps {
  user: ICurrentUser;
}

const ClientHeader = ({ user }: IProps) => {
  const currentUser = useSelector(selectors.getCurrentUser);

  const isPaymentStepEnabled = currentUser?.paymentEnabled;

  const { i18n } = useTranslation();
  const { t } = useTranslation(["translation", "translationCommon"]);
  const dispatch = useReduxDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showLangLoader, setShowLangLoader] = useState(false)

  const notificationsCount = useSelector(selectors.getNotificationCount);
  const notificationsList = useSelector(
    notificationsSelectors.getNotificationsList
  );
  const total = useSelector(notificationsSelectors.getNotificationsTotal);

  const [referralCongrats, setReferralCongrats] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);
  const [langUpdate, setlangUpdate] = useState(0);
  const sysLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION) || "en";
  const hasMore = notificationsList.length < total;

  const [hideCredentials, setHideCredentials] = useState(false)

  useEffect(() => {
    i18n.changeLanguage(
      sysLang === LOCALIZATION_LANGUAGES.ARABIC
        ? LOCALIZATION_LANGUAGES.ARABIC
        : LOCALIZATION_LANGUAGES.ENGLISH
    );
  }, []);

  const redirect = (to: string) => {
    history.push(to);
  };

  const onOpenNotificationsHandler = async () => {
    await dispatch(notificationsActions.resetState());
    await dispatch(
      notificationsActions.fetchNotificationsList({ offset: 0, limit: LIMIT })
    );
  };

  const fetchNotifications = () => {
    let newOffset = offset + LIMIT;
    if (hasMore) {
      setOffset(newOffset);
      dispatch(
        notificationsActions.fetchNotificationsList({
          offset: newOffset,
          limit: LIMIT,
        })
      );
    }
  };

  const handleLogout = async () => {
    await dispatch(actions.logout());
    if (
      // !window.location.host.includes('localhost') &&
      !window.location.host.includes('saas-dev') &&
      !window.location.host.includes('live') &&
      // !window.location.host.includes('prod') &&
      !window.location.host.includes('srmg')
    ) {
      history.push(PATHS.SIGN_IN)
    } else {
      history.push(PATHS.HOME_PAGE);
    }
  };

  const changeLanguage = () => {
    setShowLangLoader(true)
    if (
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION) &&
      sysLang === LOCALIZATION_LANGUAGES.ENGLISH
    ) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ARABIC
      );
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ARABIC);
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ENGLISH
      );
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ENGLISH);
    }
    setlangUpdate(langUpdate + 1);
    // hideLanguageLoader()
    window.location.reload()
  };

  const hideLanguageLoader = async () => {
    await new Promise(resolve => setTimeout(resolve, 2000))
    setShowLangLoader(false)
  }

  const getLocalizationLanguage = () => {
    return sysLang === LOCALIZATION_LANGUAGES.ENGLISH ? "العربية" : "English";
  };

  const menuItems = [
    {
      key: "briefs",
      to: PATHS.BRIEF,
      label: t("panel-header.nav.campaigns", { ns: "translationCommon" }),
      active: location.pathname.includes(PATHS.BRIEF),
    },
    {
      key: "campaigns",
      to: PATHS.CAMPAIGNS,
      label: t("panel-header.nav.active_campaigns", {
        ns: "translationCommon",
      }),
      active: location.pathname.includes(PATHS.CAMPAIGNS),
    },
    // {
    //   key: 'marketplace',
    //   to: PATHS.MARKETPLACE,
    //   label: 'Marketplace',
    //   active: location.pathname.includes(PATHS.MARKETPLACE),
    // },
    // {
    //   key: "accnouncements",
    //   to: PATHS.ANNOUNCEMENTS,
    //   label: t("panel-header.nav.promotions", { ns: "translationCommon" }),
    //   active: location.pathname.includes(PATHS.ANNOUNCEMENTS),
    // },
  ];

  const showReferralButton = user.isReferralEmailSent;

  useEffect(() => {
    if (!currentUser.id) return;
    if (currentUser.socialLoginType)
      setHideCredentials(true)
  }, [currentUser])


  const menu = (
    <Menu className="user-menu-list">
      <Menu.Item key="0">
        <Link to={PATHS.CLIENT_PROFILE_PREVIEW}>
          <span className="user-menu-icon">{userIcon}</span>{" "}
          {t("panel-header.options.profile", { ns: "translationCommon" })}
        </Link>
      </Menu.Item>
      {isPaymentStepEnabled && (
        <Menu.Item key="1">
          <Link to={PATHS.BILLS}>
            <span className="user-menu-icon">{billIcon}</span>{" "}
            {t("panel-header.options.bills", { ns: "translationCommon" })}
          </Link>
        </Menu.Item>
      )}
      {!hideCredentials && <Menu.Item key="2">
        <Link to={PATHS.UPDATE_PASSWORD}>
          <span className="user-menu-icon">{keyIcon}</span>
          {t("panel-header.options.update_password", {
            ns: "translationCommon",
          })}
        </Link>
      </Menu.Item>}
      {
        !currentUser.username && !hideCredentials && <Menu.Item key="3">
          <Link to={PATHS.UPDATE_EMAIL}>
            <span className="user-menu-icon">
              <MailOutlined />
            </span>
            {t("panel-header.options.update_email", { ns: "translationCommon" })}
          </Link>
        </Menu.Item>
      }
      {localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION) && (
        <Menu.Item key="5">
          <a onClick={() => changeLanguage()}>
            <span className="user-menu-icon">
              <GlobalOutlined />
            </span>
            {getLocalizationLanguage()}
          </a>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="4">
        <div onClick={handleLogout}>
          <span className="user-menu-icon">{logoutIcon}</span>
          {t("panel-header.options.logout", { ns: "translationCommon" })}
        </div>
      </Menu.Item>
    </Menu>
  );

  const handleNotificationClick = (e: any, notification: INotificationList) => {
    if (!notification.redirectPath) return;
    history.push(notification.redirectPath);
  };

  const showReferModal = (show: boolean) => {
    show
      ? history.push(location.pathname + COMMON.REFERRAL_HASH)
      : history.push(location.pathname);
  };

  const handleSubmit = async (data: IReferral) => {
    const res = await dispatch(clientActions.createReferral(data));
    if (res.status === STATUS_CODES.BASE_SUCCESS) {
      setReferralCongrats(true);
    }
  };

  const handleClose = () => {
    showReferModal(false);
    setReferralCongrats(false);
  };

  const isShowReferModal = location.hash === COMMON.REFERRAL_HASH;

  return (
    <Fragment>
      <LanguageLoader visible={showLangLoader} />
      <div className="new-header-logo-container">
        <img onClick={() => history.push(PATHS.ROOT)} src={currentUser.partnerData.logo ?? 'https://i.ibb.co/ww6nQJw/home-1.png'} alt="Logo" />
      </div>
      <QHeader
        className="client-header"
        logoLink={PATHS.ROOT}
        childrenButtons={
          <>
            {menuItems.map((item) => (
              <div className="full-width" key={item.key}>
                <QButton
                  onClick={() => redirect(item.to)}
                  className={`qu-button-blue text-capitalize ml-15 ${item.active && "active"
                    }`}
                  size="small"
                >
                  {item.label}
                </QButton>
              </div>
            ))}
          </>
        }
        childrenMenus={
          <>
            {/* {showReferralButton && (
              <div className="only-desktop refer">
                <QButton
                  onClick={() => showReferModal(true)}
                  className="qu-button-outline qu-button-white refer-button"
                  size="small"
                >
                  <IconsCommon.IconGiftInfo className={'h-20'} />
                  Refer & Earn
                </QButton>
              </div>
            )} */}
            <NotificationMenu
              fetchData={fetchNotifications}
              hasMore={hasMore}
              className="client"
              notificationsData={notificationsList}
              badgeCount={notificationsCount}
              onOpen={onOpenNotificationsHandler}
              onClick={handleNotificationClick}
            />
            <QUserMenu
              menu={menu}
              profileAvatar={user.profilePicture}
              profileName={`${user.firstName} ${user.lastName ? user.lastName : ''}`}
              profileInitials={getInitials(
                `${user.firstName} ${user.lastName}`
              )}
              clientMobile={isMobile ? true : false}
              welcomeBackText={t("panel-header.nav.welcome_back", { ns: "translationCommon" })}
            />
          </>
        }
      />
      {isShowReferModal && (
        <Modal
          wrapClassName="qu-modal"
          closeIcon={closeIcon}
          visible={showReferralButton && isShowReferModal}
          onCancel={handleClose}
          width={678}
          title={null}
          footer={null}
        >
          {referralCongrats ? (
            <DiscountThanks onOkay={handleClose} />
          ) : (
            <DiscountForm onSubmit={handleSubmit} onClose={handleClose} />
          )}
        </Modal>
      )}
    </Fragment>
  );
};

export default ClientHeader;
