import React, { ReactElement, useState } from 'react'
import DetailsAbout from './Details.About'
import SpinnerCenter from '../Spinner/spinner'
import { Radio } from 'quantum_components'
import { isMobile } from 'react-device-detect'
import defaultImage from 'src/assets/profile-default.svg'

import ServicesMobileForm from './Details.ServicesMobileForm'
import { IBriefPartnerDetails } from '../../modules/brief/brief.types'
import DetailsCompanyInfo from './Details.CompanyInfo'
import DetailsServicesTable from './Details.ServicesTable'

interface Props {
  details: IBriefPartnerDetails | null
  isLoading: boolean
}
export default function PartnerDetailsModal({ details, isLoading }: Props): ReactElement {
  const [isServices, setIsServices] = useState<boolean>(true)

  const handleChangeRadio = (e: any) => {
    const { value } = e.target
    setIsServices(value)
  }

  if (!details || isLoading) {
    return (
      <div className="qu-details-content">
        <div className="qu-details-main">
          <div style={{ height: 600 }}>
            <SpinnerCenter />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="qu-details-content">
      <div className="qu-details-main">
        <div className="qu-details-thumbnail">
          <img src={details.companyBackgroundImageUrl || defaultImage} alt={details.companyName} />
        </div>
        <div className="qu-details-body">
          <DetailsCompanyInfo details={details} />

          <h2 className="section-title">Available Services</h2>
          {!isMobile && (
            <DetailsServicesTable
              details={details}
              selectedServices={[]}
              onChangeServices={() => null}
              editMode={false}
            />
          )}

          {isMobile && (
            <div className="only-mobile">
              <Radio.Group
                className="qu-radio-group full-width mb-15"
                buttonStyle="solid"
                value={isServices}
                onChange={handleChangeRadio}
              >
                <Radio.Button className="qu-radio-button qu-radio-white" value={true}>
                  Services
                </Radio.Button>
                <Radio.Button className="qu-radio-button qu-radio-white" value={false}>
                  About
                </Radio.Button>
              </Radio.Group>
              {isServices && <ServicesMobileForm viewMode details={details} briefId={0} />}
              {!isServices && (
                <React.Fragment>
                  <h2 className="section-title">About {details.companyName}</h2>
                  <DetailsAbout details={details} />
                </React.Fragment>
              )}
            </div>
          )}
          {!isMobile && (
            <React.Fragment>
              <h2 className="section-title">About {details.companyName}</h2>
              <DetailsAbout details={details} />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}
