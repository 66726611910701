import React from 'react'
import { Col, Row } from 'quantum_components'
import { IBriefResponse } from '../../brief.types'
import { useTranslation } from 'react-i18next'
import lodash, { filter } from 'lodash'
import { useSelector } from 'react-redux'
import { getBriefSettings } from '../../brief.selectors'
import i18n from 'src/i18n'
import { LOCALIZATION_LANGUAGES } from 'src/constants'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
}

const BriefTargeting = ({ briefView, onEdit, viewMode }: Props) => {
  const { t } = useTranslation(['translation','translationCommon'])
  const briefSettings = useSelector(getBriefSettings)

  const getCities = (cities: string[]) => {
    let settings = briefSettings?.countries.length && briefSettings?.countries[0].city;

    let cities_ = cities.map(city => {
      let obj = lodash.first(lodash.filter(settings, (s) => s.name === city))
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? lodash.get(obj, 'name_ar') : city
    })

    return cities_.join(', ')
  }

  const getCountries = (countries: string[]) => {

    let settings = briefSettings?.countries;

    let countries_ = countries.map(country => {
      let obj = lodash.first(lodash.filter(settings, (s) => s.name === country))
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? lodash.get(obj, 'name_ar') : country
    })

    return countries_.join(', ')
  }

  const getAgeGroupNames = (ageGroups: string[]) => {

    let settings = briefSettings?.ageGroups;

    let ageGroups_ = ageGroups.map(ageGroup => {
      let obj = lodash.first(lodash.filter(settings, (s: any) => (s.from + (s.to ? "-" + s.to + " " + s.name : "+ " + s.name)) === ageGroup))
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? obj && (obj.from + (obj.to ? "-" + obj.to + " " + obj.name_ar : "+ " + obj.name_ar)) : ageGroup
    })

    return ageGroups_.join(i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? ' ,' : ', ')
  }

  return (
    <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>{t('brief.summary.Targeting')}</h4>
        {!viewMode && <span onClick={onEdit}>{t('brief.summary.Edit')}</span>}
      </div>
      <div className="product-brief">
        <Row gutter={16}>

        {briefView?.customTargets &&
                    Object.keys(briefView?.customTargets).map((key: any) => {
                      return (

                        <Col xs={24} sm={12}>
                          <div className="data-view mb-15">
                            <div className="data-view-label">{key}</div>
                            <div className="data-view-value">
                            {briefView?.customTargets[key]
                            ? briefView?.customTargets[key]?.join(", ")
                            : t("common.none", { ns: "translationCommon" })}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
          


          {/* <Col xs={24} sm={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('brief.summary.Ages')}</div>
              <div className="data-view-value">
                {briefView.targetAgeGroups ? getAgeGroupNames(briefView?.targetAgeGroups) : t('common.none', { ns: 'translationCommon' })}
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('brief.summary.Gender')}</div>
              <div className="data-view-value">
                {briefView.targetFocus?.length ? t('brief.targeting.gender.' + lodash.snakeCase(briefView.targetFocus?.join(', ')), { ns: 'translationCommon', }) : t('common.none', { ns: 'translationCommon' })}
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('brief.summary.Countries')}</div>
              <div className="data-view-value">
                {briefView.targetCountries?.length ? getCountries(briefView?.targetCountries) : t('common.none', { ns: 'translationCommon' })}
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <div className="data-view mb-15">
              <div className="data-view-label">{t('brief.summary.Cities')}</div>
              <div className="data-view-value">
                {briefView.targetCities?.length ? getCities(briefView?.targetCities) : t('common.none', { ns: 'translationCommon' })}
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    </div>
  )
}
export default BriefTargeting
