import React, { FC, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { InfoCard, PublicProfileField, QInput, QSelect, Form } from 'quantum_components'

import '../../../styles.scss'

import { getForm, getPartnerProfileSettings } from '../../../profile.selectors'
import { actions } from '../../../partnerProfile.module'
import { useReduxDispatch } from 'src/helpers'
import { REGEXES } from 'src/constants'
import { PARTNER_STEPS, SALES_INFORMATION, SALES_INFORMATION_VALUES } from 'src/constants/profile'
import { debounce, pick } from 'lodash'

interface IProps {
  setError: (stepName: string, value: boolean) => void
  isViewOnly?: boolean
}

export const StepTwoContainer: FC<IProps> = ({ setError, isViewOnly }) => {
  const [form] = Form.useForm()
  const dispatch = useReduxDispatch()
  const settings = useSelector(getPartnerProfileSettings)
  const partnerForm = useSelector(getForm)

  const stepFields = pick(partnerForm, Object.values(SALES_INFORMATION_VALUES))
  const initialValues = {
    ...stepFields,
    productCategories: stepFields.productCategories ? stepFields.productCategories : [],
  }

  const selectProductCategories = () => {
    if (settings.productCategories) {
      return settings.productCategories.map((elem: string) => ({
        label: elem,
        value: elem,
      }))
    } else {
      return []
    }
  }

  const selectCurrencies = () => {
    if (settings.currencies) {
      return settings.currencies.map((elem: string) => ({
        label: elem,
        value: elem,
      }))
    } else {
      return []
    }
  }

  const onValuesChange = (changedValue: any, allValues: typeof initialValues) => {
    const formValues = {
      ...allValues,
      amountOfOrders: allValues.amountOfOrders ? Number(allValues.amountOfOrders) : null,
      costOfOrder: allValues.costOfOrder ? Number(allValues.costOfOrder) : null,
    }
    dispatch(actions.setForm(formValues))
  }

  const delayedChange = useCallback(
    //@ts-ignore
    debounce((c, a) => onValuesChange(c, a), 500),
    []
  )

  const validation = async (rule: any, v: string | number | null) => {
    const value = v === null ? '' : `${v}`

    if (Number(value) > LIMIT_FOR_NUMBERS.MAX) {
      return await Promise.reject('The highest value this field can take is 1000000000')
    }
    if (value.length && !RegExp(REGEXES.AMOUNT_REGEX).test(value)) {
      return await Promise.reject('Invalid amount!')
    }
    return await Promise.resolve()
  }
  const LIMIT_FOR_NUMBERS = {
    MIN: 0,
    MAX: 1000000000,
  }

  const validateFields = async () => {
    try {
      await form.validateFields()
      setError(PARTNER_STEPS.SALES, false)
    } catch (error: any) {
      setError(PARTNER_STEPS.SALES, true)
    }
  }

  useEffect(() => {
    validateFields()
  }, [partnerForm])

  return (
    <div className="second_content">
      <InfoCard headerLeft={<h3>Sales Information</h3>}>
        <Form form={form} onValuesChange={delayedChange} initialValues={initialValues}>
          <PublicProfileField
            description={{
              title: <span>What product categories do you sell?</span>,
            }}
            input={
              <Form.Item name={SALES_INFORMATION_VALUES[SALES_INFORMATION.PRODUCT_CATEGORIES]}>
                <QSelect
                  style={{ width: '100%' }}
                  mode="multiple"
                  size="large"
                  placeholder="Select Product Categories"
                  options={selectProductCategories()}
                  disabled={isViewOnly}
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: <span>Average number of monthly online traffic</span>,
            }}
            input={
              <Form.Item
                name={SALES_INFORMATION_VALUES[SALES_INFORMATION.MONTHLY_VISITORS]}
                rules={[
                  {
                    message: 'Invalid amount!',
                    pattern: REGEXES.AMOUNT_REGEX,
                  },
                ]}
              >
                <QInput placeholder="Enter Monthly Visitors" size="large" type="number" disabled={isViewOnly} />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: <span>Average number of monthly customers</span>,
            }}
            input={
              <>
                <Form.Item
                  name={SALES_INFORMATION_VALUES[SALES_INFORMATION.MONTHLY_CUSTOMERS]}
                  rules={[
                    {
                      message: 'Invalid amount!',
                      pattern: REGEXES.AMOUNT_REGEX,
                    },
                  ]}
                >
                  <QInput placeholder="Enter Monthly Customers" size="large" type="number" disabled={isViewOnly} />
                </Form.Item>
                <p>
                  The above fields are visible to clients. They help clients discover your company using our search
                  tools and filters.
                </p>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: 'Roughly how many orders do you fulfill each month?',
            }}
            input={
              <Form.Item
                name="amountOfOrders"
                rules={[
                  {
                    validator: async (rule, value: string) => await validation(rule, value),
                  },
                ]}
              >
                <QInput placeholder="Enter Quantity" type="number" size="large" disabled={isViewOnly} />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: 'What is the average cost of an order?',
            }}
            input={
              <>
                <Form.Item
                  name="costOfOrder"
                  rules={[
                    {
                      validator: async (rule, value: string) => await validation(rule, value),
                    },
                  ]}
                >
                  <QInput placeholder="Enter Amount" type="number" size="large" disabled={isViewOnly} />
                </Form.Item>
                <p>
                  The above fields are not visible to clients and kept private. This information will only be used by
                  Quantum staff to make partner recommendations to clients.
                </p>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: 'Primary Store Currency',
            }}
            input={
              <Form.Item name={SALES_INFORMATION_VALUES[SALES_INFORMATION.PRIMARY_STORE_CURRENCY]}>
                <QSelect
                  placeholder="Select a Currency"
                  options={selectCurrencies()}
                  size="large"
                  style={{ width: '100%' }}
                  disabled={isViewOnly}
                />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: 'VAT Registration No.',
            }}
            input={
              <Form.Item name="vat">
                <QInput placeholder="Enter VAT registration number" type="number" size="large" disabled={isViewOnly} />
              </Form.Item>
            }
          />
        </Form>
      </InfoCard>
    </div>
  )
}
