import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BRIEF_STEPS } from '../../../../constants/brief'
import { useSelector } from 'react-redux'
import { getBriefView, getCart } from '../../brief.selectors'
import * as actions from '../../brief.actions'
import { actions as coreActions } from '../../../core/core.module'
import { useReduxDispatch } from '../../../../helpers'
import BriefEditSummaryComponent from '../../../../components/BriefView/BriefEditSummary.component'

interface IProps {
  briefId: number
  setCurrentStep: (step: BRIEF_STEPS) => void
  onFinishLater: () => void
  rejection?: boolean
}

export default function FinalSummary({ briefId, setCurrentStep, onFinishLater, rejection }: IProps): ReactElement {
  const { t } = useTranslation(['translation', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const briefView = useSelector(getBriefView)
  const cart = useSelector(getCart)

  useEffect(() => {
    dispatch(actions.fetchViewBriefDetails(briefId))
    dispatch(actions.fetchCart(briefId))
    dispatch(coreActions.getUser())
  }, [briefId])

  const openPartner = async (id: number) => {
    setCurrentStep(BRIEF_STEPS.PARTNER)
    await dispatch(actions.fetchPartnerDetails(briefId, id))
    await dispatch(actions.setActiveSinglePartner(id))
  }

  return (
    <div className="qu-brief-content brief-step">
      <div className="brief-step-header">
        <h1>{t('brief.edit.final-summary.review_submit_your_brief')}</h1>
        <p>
          {t('brief.edit.final-summary.description1')}
          <a onClick={onFinishLater}> {t('brief.edit.final-summary.description2')}</a>
        </p>
      </div>
      <BriefEditSummaryComponent
        briefView={briefView}
        cart={cart}
        hidePaymentSection={true}
        setCurrentStep={setCurrentStep}
        openPartner={openPartner}
        rejection={rejection}
      />
    </div>
  )
}
