import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

import { requestHttp, urls } from 'src/api'
import { FORMATS } from 'src/constants'
import { UPLOAD_ENTITY_KEYS } from 'src/constants/upload'
import { BRIEF_UPLOAD_TYPES } from 'src/constants/brief'
import { getFileNameFromUrl } from 'src/utils'
import { getResponseErrorMessage } from 'src/helpers'
import { upload } from 'src/modules/core/core.actions'
import IAction from 'src/interfaces/IAction'
import IUploadFile from 'src/interfaces/IUploadFile'

import { IBillingsListElement, IBillingPaymentUploadTypes, IBillingTableParams } from './billings.types'
import * as CONSTANTS from './billings.constants'
import { IRequestListResponse } from '../partner/requests/request.types'
import { REQUEST_STATUS } from '../../constants/request'

export const billListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_BILLS_REQUEST,
})

export const billListSuccess = (billList: { results: IBillingsListElement[]; total: number }): IAction => ({
  type: CONSTANTS.FETCH_BILLS_SUCCESS,
  payload: { billList },
})

export const billListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_BILLS_FAILURE,
  error,
})

export const setBillListParams = (params: IBillingTableParams): IAction => ({
  type: CONSTANTS.SET_BILL_LIST_PARAMS,
  payload: { params },
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const fetchBillingsList =
  (params: IBillingTableParams): ThunkAction<Promise<AxiosResponse<IRequestListResponse>>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IRequestListResponse>> => {
    try {
      dispatch(billListRequest())
      const response = await requestHttp.get<IRequestListResponse>(urls.getPartnerRequestsListUrl(), {
        params: { ...params, status: [REQUEST_STATUS.ACCEPTED] },
      })
      const {
        content: { results, total },
      } = response.data.data

      const billingsList = results.map<IBillingsListElement>(request => ({
        ...request,
        key: request.id,
        lastApprovedDate: moment(request.lastApprovedDate).format(FORMATS.DATE_LL_FORMAT),
        payment: request.payment
          ? {
              ...request.payment,
              invoice: {
                isUploading: false,
                uploadingError: '',
                isRemoving: false,
                removingError: '',
                files: request.payment?.invoice?.length
                  ? [
                      {
                        name: getFileNameFromUrl(request.payment.invoice[0]),
                        status: 'done',
                        url: request.payment.invoice[0],
                      },
                    ]
                  : [],
              },
            }
          : null,
      }))

      dispatch(billListSuccess({ results: billingsList, total: total }))
      return response
    } catch (error: any) {
      dispatch(billListFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const setParamsAndFetch =
  (params: IBillingTableParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setBillListParams(params))
    dispatch(fetchBillingsList(params))
  }

export const uploadPaymentFile =
  ({
    bill,
    files,
    filesType,
  }: {
    bill: IBillingsListElement
    files: File[]
    filesType: IBillingPaymentUploadTypes
  }): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fileUploadRequest({ bill, filesType }))

      const briefId = bill.briefId
      const isPublicFile = false
      const response = await upload(
        urls.getPartnerBriefUploadUrl(),
        files,
        briefId,
        BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
        UPLOAD_ENTITY_KEYS.BRIEF_ID,
        isPublicFile
      )

      if (response.status === 201) {
        const { content: remoteServerUrls }: { content: string[] } = response.data.data
        const newBill = cloneDeep(bill)
        newBill.payment![filesType].files = [
          ...newBill.payment![filesType].files,
          ...remoteServerUrls.map<IUploadFile>((url: string) => ({
            name: getFileNameFromUrl(url),
            status: 'done',
            url,
          })),
        ]

        await requestHttp.put(urls.getUpdatePartnerBillFilesLinksUrl(), {
          id: newBill.payment?.id,
          invoice: newBill.payment?.invoice.files.map(({ url }) => url),
        })
        dispatch(fileUploadSuccess({ bill: newBill, filesType }))
      }
    } catch (error: any) {
      dispatch(fileUploadFailure({ bill, filesType, error }))
    }
  }

export const fileUploadRequest = ({
  bill,
  filesType,
}: {
  bill: IBillingsListElement
  filesType: IBillingPaymentUploadTypes
}): IAction => ({
  type: CONSTANTS.FILE_UPLOAD_REQUEST,
  payload: { bill, filesType },
})

export const fileUploadSuccess = ({
  bill,
  filesType,
}: {
  bill: IBillingsListElement
  filesType: IBillingPaymentUploadTypes
}): IAction => ({
  type: CONSTANTS.FILE_UPLOAD_SUCCESS,
  payload: { bill, filesType },
})

export const fileUploadFailure = ({
  bill,
  filesType,
  error,
}: {
  bill: IBillingsListElement
  filesType: IBillingPaymentUploadTypes
  error: string
}): IAction => ({
  type: CONSTANTS.FILE_UPLOAD_FAILURE,
  payload: { bill, filesType, error },
})

export const removePaymentFile =
  ({
    bill,
    file,
    filesType,
  }: {
    bill: IBillingsListElement
    file: IUploadFile
    filesType: IBillingPaymentUploadTypes
  }): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fileRemoveRequest({ bill, filesType }))

      const data = {
        briefId: String(bill.briefId),
        entity: BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
        urls: bill.payment![filesType].files.map(({ url }) => url),
      }
      const response = await requestHttp.delete(urls.getPartnerBriefRemoveMediaUrl(), { data })

      if (response.status === 201) {
        const newBill = cloneDeep(bill)
        newBill.payment![filesType].files = newBill.payment![filesType].files.filter(
          oldFile => oldFile.url !== file.url
        )

        await requestHttp.put(urls.getUpdatePartnerBillFilesLinksUrl(), {
          id: newBill.payment!.id,
          invoice: newBill.payment!.invoice.files.map(({ url }) => url),
        })

        dispatch(fileRemoveSuccess({ bill: newBill, filesType }))
      }
    } catch (error: any) {
      dispatch(fileRemoveFailure({ bill, filesType, error }))
    }
  }

export const fileRemoveRequest = ({
  bill,
  filesType,
}: {
  bill: IBillingsListElement
  filesType: IBillingPaymentUploadTypes
}): IAction => ({
  type: CONSTANTS.FILE_REMOVE_REQUEST,
  payload: { bill, filesType },
})

export const fileRemoveSuccess = ({
  bill,
  filesType,
}: {
  bill: IBillingsListElement
  filesType: IBillingPaymentUploadTypes
}): IAction => ({
  type: CONSTANTS.FILE_REMOVE_SUCCESS,
  payload: { bill, filesType },
})

export const fileRemoveFailure = ({
  bill,
  filesType,
  error,
}: {
  bill: IBillingsListElement
  filesType: IBillingPaymentUploadTypes
  error: string
}): IAction => ({
  type: CONSTANTS.FILE_REMOVE_FAILURE,
  payload: { bill, filesType, error },
})
