import { Col, QButton, Row } from 'quantum_components'
import { ReactElement } from 'react'

export default function BriefV2Footer(): ReactElement {
  return (
    <footer className="qu-footer">
      <Row align='middle' gutter={8} justify='center' className="footer-buttons">
        <Col>
          <QButton
            className="qu-button-soft full-width"
            size="small"
          >
            Do This Later
          </QButton>
        </Col>

        <Col>
          <QButton
            className="test-class full-width"
            type="primary"
            size="small"
          >
            Continue
          </QButton>
        </Col>
      </Row>
    </footer>
  )
}
