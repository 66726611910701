import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SpinnerCenter from 'src/components/Spinner/spinner';
import { actions } from '../deploymentDetails.module';
// import { actions as briefActions } from '../../brief/brief.module';
import { getActiveSaasPartner, getIsLoading } from '../deploymentDetails.selectors';
import defaultImage from 'src/assets/profile-default.svg'
import DetailsCompanyInfo from 'src/components/PartnerDetailsComponents/Details.CompanyInfo';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import DetailsServicesTable from 'src/components/PartnerDetailsComponents/Details.ServicesTable';
import DetailsAbout from 'src/components/PartnerDetailsComponents/Details.About';
import { Radio } from 'quantum_components'
import ServicesMobileForm from 'src/components/PartnerDetailsComponents/Details.ServicesMobileForm';
import { LOCALIZATION_LANGUAGES, LOCAL_STORAGE_KEYS } from 'src/constants';
import { GlobalOutlined } from '@ant-design/icons';
import i18n from 'src/i18n';

export default function DeploymentDetails(): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation(['translationUser', 'translationCommon'])

  const details = useSelector(getActiveSaasPartner);
  const isLoading = useSelector(getIsLoading);

  const [isServices, setIsServices] = useState<boolean>(true)

  const sysLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION) || "ar";

  useEffect(() => {
    // dispatch(briefActions.fetchPartnerFilterSettings());
    dispatch(actions.fetchSaasPartnerDetails());
    if (!localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION)) {
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ARABIC)
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ARABIC
      );
    }
  }, []);

  const handleChangeRadio = (e: any) => {
    const { value } = e.target
    setIsServices(value)
  }

  const getLocalizationLanguage = () => {
    return sysLang === LOCALIZATION_LANGUAGES.ENGLISH ? "العربية" : "English";
  };

  const changeLanguage = async () => {
    if (sysLang && sysLang === LOCALIZATION_LANGUAGES.ENGLISH) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ARABIC
      );
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ENGLISH
      );
    }
    window.location.reload()
  };

  return (
    <div className={`qu-details-content deployment-details ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && 'deployment-details-ar'}`}>
      {isLoading && <SpinnerCenter />}

      {details.companyName &&
        <div className="qu-details-main">
          <div className="qu-details-thumbnail">
            <img src={details.companyBackgroundImageUrl || defaultImage} alt={details.companyName} />
          </div>
          <div className="qu-details-body">
            <DetailsCompanyInfo details={details} />

            <h2 className="section-title mt-30">{t("public.available_services")}</h2>

            {!isMobile && (
              <DetailsServicesTable
                details={details}
                selectedServices={[]}
                onChangeServices={() => null}
                editMode={false}
              />
            )}

            {isMobile && (
              <div className="only-mobile">
                <Radio.Group
                  className="qu-radio-group full-width mb-15"
                  buttonStyle="solid"
                  value={isServices}
                  onChange={handleChangeRadio}
                >
                  <Radio.Button className="qu-radio-button qu-radio-white" value={true}>
                    {t(`brief.table-columns.Services`, { ns: 'translationCommon' })}
                  </Radio.Button>
                  <Radio.Button className="qu-radio-button qu-radio-white" value={false}>
                    {t(`public.about`)}
                  </Radio.Button>
                </Radio.Group>

                {isServices && <ServicesMobileForm viewMode details={details} briefId={0} />}

                {!isServices && (
                  <React.Fragment>
                    <h2 className="section-title">{t(`public.about`)} {details.companyName}</h2>
                    <DetailsAbout details={details} />
                  </React.Fragment>
                )}
              </div>
            )}

            {!isMobile && (
              <React.Fragment>
                <h2 className="section-title">{t(`public.about`)} {details.companyName}</h2>
                <DetailsAbout details={details} />
              </React.Fragment>
            )}
          </div>
          {sysLang && <div className="auth-section-footer">
            <p>
              <a onClick={() => changeLanguage()}>
                <span className="user-menu-icon mr-5 ml-5">
                  <GlobalOutlined />
                </span>
                {getLocalizationLanguage()}
              </a>
            </p>
          </div>}
        </div>}
    </div>
  )
}
