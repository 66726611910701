import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import TestahelBriefTypeForm from './testahelBriefForms/TestahelBriefType.form'
import TestahelBriefFooter from './components/TestahelBrief.footer'
import {
  ADDITIONAL_STEPS,
  BRIEF_FORM_HEADER_TITLE,
  BRIEF_STATUS,
  BRIEF_STEP_STATUS,
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  BRIEF_TYPES,
  CREATE_BRIEF_STEPS,
  DISTRIBUTION_TYPE,
  MAIN_PRINT_ID,
  MEDIA_TYPES,
  COUNTRY_NAME,
} from '../../../../constants/testahel_brief'
import TestahelBriefHeader from './components/TestahelBrief.header'
import TestahelProductBriefForm from './testahelBriefForms/TestahelProductBrief.form'
import TestahelCustomizedBoxForm from './testahelBriefForms/TestahelCustomizedBox.form'
import { actions } from '../testahelBox.module'
import { actions as SpecialClientsActions } from '../../../specialClients/specialClients.module'
import { useSelector } from 'react-redux'
import { getBrief, getBriefSettings, getCart, getCurrentStep, getNeedCheckLastStep } from '../testahelBox.selectors'
import TestahelTargetingForm from './testahelBriefForms/TestahelTargeting.form'
import TestahelServicesForm from './testahelBriefForms/TestahelServices.form'
import { Col, QBriefStep, Row, IconsCommon, Modal, QButton } from 'quantum_components'
import {
  ICartItem,
  ICartServiceItem,
  ICustomizedInitState,
  ICustomizedStep,
  IConsumerResearchInitState,
  IConsumerResearchStep,
  IMediaBuyInitState,
  IMediaBuyStep,
  IProductInitState,
  IProductStep,
  IServiceInitState,
  IServicesStep,
  ITargetingInitState,
  ITargetingStep,
  IUploadPartner,
} from '../testahelBox.types'
import { LOCAL_STORAGE_KEYS, PATHS } from '../../../../constants'
import TestahelBriefSummary from './components/TestahelBrief.summary'
import cloneDeep from 'lodash/cloneDeep'
import TestahelMediaBuyForm from './testahelBriefForms/TestahelMediaBuy.form'
import BriefDialog from 'src/components/BriefDialog/Brief.Dialog'
import * as selectors from '../../../core/core.selectors'
import TestahelSummaryForm from './testahelBriefForms/TestahelSummary.form'
import TestahelBriefPartnerForm from './testahelBriefForms/TestahelBriefPartner.form'
import TestahelUploadForm from './testahelBriefForms/TestahelUpload.form'
import TestahelFinalSummary from './testahelBriefForms/TestahelFinalSummary'
import TestahelSubmittedSuccessfully from './testahelBriefForms/TestahelSubmittedSuccessfully'
import { canEditBrief } from '../../../../helpers/rules'
import {
  validateCustomizedStep,
  validateConsumerResearchStep,
  validateMediaBuyStep,
  validateTestahelProductStep,
  validateTestahelTargetingStep,
  validateTestahelServicesStep,
} from '../../../../helpers/validation'
import { isInsert, isSample } from '../../../../helpers/testahelBrief'
import { getIsoDate } from '../../../../helpers/datesHelper'
import SpinnerCenter from '../../../../components/Spinner/spinner'
import { ReactComponent as CircleCheckIcon } from 'src/assets/icons/circle-check-icon.svg'
import { calculation } from 'src/helpers/cart'
import TestahelConsumerResearchForm from './testahelBriefForms/TestahelConsumerResearch.form'

const closeIcon = <IconsCommon.IconClose />

export default function TestahelBriefEditContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const history = useHistory()

  const brief = useSelector(getBrief)
  const currentStep = useSelector(getCurrentStep)
  const needCheckLastStep = useSelector(getNeedCheckLastStep)
  const currentUser = useSelector(selectors.getCurrentUser)
  const settings = useSelector(getBriefSettings)

  const customizedInitState = cloneDeep(ICustomizedInitState)
  const productInitState = cloneDeep(IProductInitState)
  const targetInitState = cloneDeep(ITargetingInitState)
  const servicesInitState = cloneDeep(IServiceInitState)
  const mediaBuyInitState = cloneDeep(IMediaBuyInitState)
  const consumerResearchInitState = cloneDeep(IConsumerResearchInitState)

  const cart = useSelector(getCart)

  const [briefTypes, setBriefTypes] = useState<BRIEF_TYPES[]>([])
  const [customizedStepFields, setCustomizedStepFields] = useState<ICustomizedStep>(customizedInitState)
  const [productStepFields, setProductStepFields] = useState<IProductStep>(productInitState)
  const [targetingStepFields, setTargetingStepFields] = useState<ITargetingStep>(targetInitState)
  const [servicesStepFields, setServicesStepFields] = useState<IServicesStep>(servicesInitState)
  const [mediaBuyStepFields, setMediaBuyStepFields] = useState<IMediaBuyStep>(mediaBuyInitState)
  const [consumerResearchStepFields, setConsumerResearchStepFields] =
    useState<IConsumerResearchStep>(consumerResearchInitState)
  const [addMediaBuyStep, setAddMediaBuy] = useState<boolean>(false)
  const [addProductSample, setProductSample] = useState<boolean>(false)
  const [additionalStep, setAdditionalStep] = useState<ADDITIONAL_STEPS | null>(null)
  const [skipUploadStep, setSkipUploadStep] = useState<boolean>(false)
  const [formSteps, setFormSteps] = useState<BRIEF_STEPS[]>(CREATE_BRIEF_STEPS)
  const [modalVisible, setModalVisible] = useState<any>(false)
  const [partnerData, setPartnerData] = useState<any>(false)
  const [partnerLogo, setPartnerLogo] = useState<any>('')

  const [discountCodeErrorModal, setDiscountCodeErrorModal] = useState({
    title: '',
    visible: false,
    description: '',
  })

  const uploadFormRef = useRef()

  const { id } = useParams<any>()

  useEffect(() => {
    if (!mediaBuyStepFields.budgetType) {
      const fields = { ...mediaBuyStepFields }
      // @ts-ignore
      fields['budgetType'] = 'Lifetime'
      setMediaBuyStepFields(fields)
    }
  }, [mediaBuyStepFields])

  const fetchSettings = async () => {
    dispatch(actions.fetchBriefSettings())
    if (
      localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN)
    ) {
      let inviterID: any = localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
      let data = await dispatch(SpecialClientsActions.getCoBrandingData(inviterID))
      setPartnerData(data)
    }
  }

  const handleContinue = async () => {
    setModalVisible(false)
    history.push(`/login`)
  }

  const handleDiscard = () => {
    setModalVisible(false)
    window.location.href = `https://www.quantums.com.sa/register-client?inviteToken=${localStorage.getItem(
      LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
    )}&partnerData=${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`
  }

  const fetchBrief = async () => {
    await dispatch(actions.fetchEditBrief(id))
  }

  const fetchFilterSettings = () => {
    dispatch(actions.fetchPartnerFilterSettings())
  }

  const fetchCart = () => {
    dispatch(actions.fetchCart(id))
  }

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO)) {
      setPartnerLogo(localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO))
    }
    fetchSettings()
    fetchBrief()
    fetchCart()
    setFormSteps([...CREATE_BRIEF_STEPS])

    return () => {
      dispatch(actions.resetBriefState())
    }
  }, [])

  useEffect(() => {
    const containers = document.getElementsByClassName('qu-brief-main')
    if (containers) {
      containers[0].scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [currentStep])

  useEffect(() => {
    if (currentUser.isApproved) {
      fetchFilterSettings()
    }
  }, [currentUser])

  useEffect(() => {
    const fields = { ...productStepFields }
    fields.productImages = [...brief[BRIEF_STEPS.PRODUCT].productImages]
    setProductStepFields(fields)
  }, [brief[BRIEF_STEPS.PRODUCT].productImages.length])

  useEffect(() => {
    const fields = { ...productStepFields }
    fields.quantity = brief[BRIEF_STEPS.PRODUCT].quantity
    setProductStepFields(fields)
  }, [brief[BRIEF_STEPS.PRODUCT].quantity])

  useEffect(() => {
    const fields = { ...customizedStepFields }
    fields.customizeBoxProductImages = [...brief[BRIEF_STEPS.CUSTOMIZED].customizeBoxProductImages]
    setCustomizedStepFields(fields)
  }, [brief[BRIEF_STEPS.CUSTOMIZED].customizeBoxProductImages.length])

  useEffect(() => {
    const fields = { ...customizedStepFields }
    fields.customizeBoxDistributionCount = brief[BRIEF_STEPS.CUSTOMIZED].customizeBoxDistributionCount
    setCustomizedStepFields(fields)
  }, [brief[BRIEF_STEPS.CUSTOMIZED].customizeBoxDistributionCount])

  useEffect(() => {
    const fields = { ...servicesStepFields }
    fields.serviceImages = [...brief[BRIEF_STEPS.SERVICES].serviceImages]
    setServicesStepFields(fields)
  }, [brief[BRIEF_STEPS.SERVICES].serviceImages.length])

  // useEffect(() => {
  //   const fields = { ...servicesStepFields }
  //   fields.servicePackagingQuantity = brief[BRIEF_STEPS.SERVICES].servicePackagingQuantity
  //   setServicesStepFields(fields)
  // }, [brief[BRIEF_STEPS.SERVICES].servicePackagingQuantity])

  useEffect(() => {
    setBriefTypes(brief[BRIEF_STEPS.TYPE].briefTypes)
  }, [brief[BRIEF_STEPS.TYPE]])

  useEffect(() => {
    // if (cart.additional.items.length > 0) {
    const skipUpload = checkMediaTypes()
    if (skipUpload && currentStep === BRIEF_STEPS.UPLOAD) {
      if (
        brief.BRIEF_TYPE.briefTypes.length === 1 &&
        (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX) ||
          brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE)) &&
        currentStep === BRIEF_STEPS.UPLOAD
      ) {
        showFinalSummary()
      }
    }
    // }
  }, [cart.additional.items])

  useEffect(() => {
    setBriefTypes(brief[BRIEF_STEPS.TYPE].briefTypes)
    if (brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX))
      setCurrentStep(BRIEF_STEPS.CUSTOMIZED)
    else setCurrentStep(BRIEF_STEPS.PRODUCT)

    if (brief[BRIEF_STEPS.CUSTOMIZED]) {
      setCustomizedStepFields(cloneDeep(brief[BRIEF_STEPS.CUSTOMIZED]))
    }

    if (brief[BRIEF_STEPS.PRODUCT]) {
      setProductStepFields(cloneDeep(brief[BRIEF_STEPS.PRODUCT]))
    }

    if (brief[BRIEF_STEPS.TARGETING]) {
      setTargetingStepFields(cloneDeep(brief[BRIEF_STEPS.TARGETING]))
    }

    if (brief[BRIEF_STEPS.SERVICES]) {
      setServicesStepFields(cloneDeep(brief[BRIEF_STEPS.SERVICES]))
    }

    if (brief[BRIEF_STEPS.MEDIA_BUY]) {
      setMediaBuyStepFields(cloneDeep(brief[BRIEF_STEPS.MEDIA_BUY]))
    }

    if (brief[BRIEF_STEPS.CONSUMER_RESEARCH]) {
      setConsumerResearchStepFields(cloneDeep(brief[BRIEF_STEPS.CONSUMER_RESEARCH]))
    }

    if (brief.lastFilledStep && brief.lastFilledStep !== BRIEF_STEPS.TYPE && needCheckLastStep) {
      setCurrentStep(brief.lastFilledStep)
    }

    if (forceShowFinalStep) {
      showFinalSummary()
    }
  }, [brief.id])

  useEffect(() => {
    updateStepper()
  }, [briefTypes, briefTypes.length])

  const updateStepper = () => {
    const steps = [...formSteps]
    // if (hasMediaBuyStep && hasSampleStep && hasConsumerResearchStep) {
    //   setFormSteps([...CREATE_BRIEF_STEPS])
    //   return
    // }

    if (hasCustomizedStep) {
      if (!steps.includes(BRIEF_STEPS.CUSTOMIZED)) {
        steps.splice(1, 0, BRIEF_STEPS.CUSTOMIZED)
      }
      if (!hasMediaBuyStep && steps.includes(BRIEF_STEPS.PARTNER)) {
        steps.splice(steps.indexOf(BRIEF_STEPS.PARTNER), 1)
      }
    } else {
      const customizedIndex = steps.indexOf(BRIEF_STEPS.CUSTOMIZED)
      if (customizedIndex > 0) {
        steps.splice(customizedIndex, 1)
      }
    }

    if (hasSampleStep) {
      if (!steps.includes(BRIEF_STEPS.PRODUCT)) {
        steps.splice(1, 0, BRIEF_STEPS.PRODUCT)
      }
      if (!steps.includes(BRIEF_STEPS.SERVICES)) {
        const targetingIndex = steps.indexOf(BRIEF_STEPS.TARGETING)
        steps.splice(targetingIndex + 1, 0, BRIEF_STEPS.SERVICES)
      }
    } else {
      const productIndex = steps.indexOf(BRIEF_STEPS.PRODUCT)
      if (productIndex > 0) {
        steps.splice(productIndex, 1)
      }
      const servicesIndex = steps.indexOf(BRIEF_STEPS.SERVICES)
      if (servicesIndex > 0) {
        steps.splice(servicesIndex, 1)
      }
    }

    if (hasMediaBuyStep) {
      if (!steps.includes(BRIEF_STEPS.MEDIA_BUY)) {
        const partnerIndex = steps.indexOf(BRIEF_STEPS.PARTNER)
        steps.splice(partnerIndex, 0, BRIEF_STEPS.MEDIA_BUY)
      }
      if (!hasCustomizedStep && !steps.includes(BRIEF_STEPS.PRODUCT)) {
        steps.splice(1, 0, BRIEF_STEPS.PRODUCT)
      }
      if (!steps.includes(BRIEF_STEPS.PARTNER)) {
        const mediaBuyIndex = steps.indexOf(BRIEF_STEPS.MEDIA_BUY)
        steps.splice(mediaBuyIndex + 1, 0, BRIEF_STEPS.PARTNER)
      }
    } else {
      const mediaBuyIndex = steps.indexOf(BRIEF_STEPS.MEDIA_BUY)
      if (mediaBuyIndex > 0) {
        steps.splice(mediaBuyIndex, 1)
      }
    }

    if (hasConsumerResearchStep) {
      if (!steps.includes(BRIEF_STEPS.CONSUMER_RESEARCH)) {
        const partnerIndex = steps.indexOf(BRIEF_STEPS.PARTNER)
        steps.splice(partnerIndex, 0, BRIEF_STEPS.CONSUMER_RESEARCH)
      }
      if (hasConsumerResearchStep && brief.BRIEF_TYPE.briefTypes.length === 1) {
        if (!steps.includes(BRIEF_STEPS.PRODUCT)) {
          steps.splice(1, 0, BRIEF_STEPS.PRODUCT)
        }
      } else if (hasConsumerResearchStep && !steps.includes(BRIEF_STEPS.PARTNER)) {
        const consumerResearchIndex = steps.indexOf(BRIEF_STEPS.CONSUMER_RESEARCH)
        steps.splice(consumerResearchIndex + 1, 0, BRIEF_STEPS.PARTNER)
      }
    } else {
      const consumerResearchIndex = steps.indexOf(BRIEF_STEPS.CONSUMER_RESEARCH)
      if (consumerResearchIndex > 0) {
        steps.splice(consumerResearchIndex, 1)
      }
    }

    setFormSteps(steps)
  }

  const checkMediaTypes = () => {
    const services = cart.additional.items.map((item: ICartItem) => item.services).flat()
    const hasMediaBuy = services.some((services: ICartServiceItem) => services.type !== MEDIA_TYPES.DISTRIBUTION)
    if (brief.BRIEF_TYPE.briefTypes.length > 0 && !brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH))
      setSkipUploadStep(!hasMediaBuy)
    return !hasMediaBuy
  }

  const handleFinishLater = async () => {
    await saveBrief()
    if (
      localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN)
    ) {
      setModalVisible(true)
    } else {
      history.push(PATHS.BRIEF)
    }
  }
  const isZeroStep = currentStep === BRIEF_STEPS.TYPE
  const isCustomizedStep = currentStep === BRIEF_STEPS.CUSTOMIZED
  const isProductStep = currentStep === BRIEF_STEPS.PRODUCT
  const isServiceStep = currentStep === BRIEF_STEPS.SERVICES
  const isTargetingStep = currentStep === BRIEF_STEPS.TARGETING
  const isMediaBuyStep = currentStep === BRIEF_STEPS.MEDIA_BUY
  const isConsumerResearchStep = currentStep === BRIEF_STEPS.CONSUMER_RESEARCH
  const isPartnerStep = currentStep === BRIEF_STEPS.PARTNER
  const isUploadStep = currentStep === BRIEF_STEPS.UPLOAD
  const isFinalSummary = additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY
  const showFooter = !isPartnerStep || isFinalSummary
  const forceShowFinalStep = !canEditBrief(brief.status) && brief.lastFilledStep !== BRIEF_STEPS.UPLOAD

  const hasSampleStep = briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const hasCustomizedStep = briefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
  const hasConsumerResearchStep = briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)
  const hasMediaBuyStep = briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)

  const needShowDialogToAddMediaBuy = isServiceStep && !hasMediaBuyStep
  const needShowDialogToAddProductSample = isMediaBuyStep && !hasSampleStep

  const summaryPreviousStep = hasMediaBuyStep ? BRIEF_STEPS.MEDIA_BUY : BRIEF_STEPS.SERVICES
  const finalSummaryPreviousStep = skipUploadStep ? BRIEF_STEPS.PARTNER : BRIEF_STEPS.UPLOAD
  const needShowSummary = !currentUser.isApproved && currentStep === summaryPreviousStep
  const disableEdit = !canEditBrief(brief.status)
  const isDraft = brief.status === BRIEF_STATUS.DRAFT
  const isTestahelBrief = brief.isTestahel
  const isSelectPartners = brief.status === BRIEF_STATUS.SELECT_PARTNERS
  const blockEditBrief = (disableEdit && !isFinalSummary) || (disableEdit && skipUploadStep && isFinalSummary)

  const onlyInsert = isInsert(productStepFields.distributionItem)
  const onlySample = isSample(productStepFields.distributionItem)

  const lastFilledStepIndex = brief.lastFilledStep ? formSteps.indexOf(brief.lastFilledStep) : 0

  const handleSubmitBrief = async () => {
    if (isDraft || isSelectPartners) {
      if (
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN)
      ) {
        setModalVisible(true)
        return
      } else {
        let response: any = await dispatch(actions.approveBrief(brief.id!))
        if (response?.data?.status === 409 && response?.data?.error?.payload?.invalid) {
          await dispatch(actions.createBriefFailure(response.data.error.message))
          setDiscountCodeErrorModal({
            title: `${cart.couponCode}: Discount code invalid`,
            visible: true,
            description: response.data.error.message,
          })
        } else {
          setAdditionalStep(ADDITIONAL_STEPS.CONGRATS)
        }
        return
      }
    }

    history.push(PATHS.BRIEF)
  }

  const onProceedDiscountCodeErrorModal = async () => {
    const cartData = { ...cart }
    const cartItems = cartData.additional.items

    const result = calculation(cartItems, settings.managementFee, settings.partnerVat)

    cartData.additional.managementFee = result.managementFee
    cartData.additional.taxes = result.taxes
    cartData.additional.cartTotal = result.total
    cartData.couponCode = ''

    delete cartData.sampleAllocated

    await dispatch(actions.addToCart(cartData))
    await dispatch(actions.fetchCart(id))

    setDiscountCodeErrorModal({
      title: '',
      visible: false,
      description: '',
    })
  }

  const submitButtonText = () => {
    if (!isFinalSummary) {
      return 'Next'
    }

    return isDraft ? 'Submit & View Quotation' : 'Save Brief'
  }

  const handleMediaBuyDialog = (e: MouseEvent, value: boolean) => {
    setAddMediaBuy(value)
  }

  const handleProductSampleDialog = (e: MouseEvent, value: boolean) => {
    setProductSample(value)
  }

  const showFinalSummary = () => {
    setAdditionalStep(ADDITIONAL_STEPS.FINAL_SUMMARY)
  }

  const saveMediaBuy = async () => {
    setAdditionalStep(null)
    setAddMediaBuy(false)
    briefTypes.push(BRIEF_TYPES.MEDIA_BUYING)
    await updateBriefTypes(BRIEF_TYPES.MEDIA_BUYING)
  }

  const saveProductSample = async () => {
    setAdditionalStep(null)
    setProductSample(false)
    briefTypes.push(BRIEF_TYPES.PRODUCT_SAMPLE)
    await updateBriefTypes(BRIEF_TYPES.PRODUCT_SAMPLE)
  }

  const handleNext = async () => {
    if (currentStep === 'BRIEF_TYPE' || currentStep === 'BRIEF_CUSTOMIZED_BOX' || currentStep === 'BRIEF_PRODUCT') {
      if (!targetingStepFields.targetCountries.includes(COUNTRY_NAME.SAUDI_ARABIA)) {
        targetingStepFields.targetCountries.push(COUNTRY_NAME.SAUDI_ARABIA)
        handleTargetingStep(targetingStepFields)
      }
    }

    const index = formSteps.indexOf(currentStep)
    const lastIndex = index === formSteps.length - 1

    // show dialog Media Buy
    if (!additionalStep && needShowDialogToAddMediaBuy && !addMediaBuyStep) {
      setAdditionalStep(ADDITIONAL_STEPS.MEDIA_BUY_DIALOG)
      await saveBrief()

      return
    }

    // show dialog Product
    if (!additionalStep && needShowDialogToAddProductSample && !addProductSample && !hasCustomizedStep) {
      setAdditionalStep(ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG)
      await saveBrief()

      return
    }

    // set&show media buy step
    if (additionalStep === ADDITIONAL_STEPS.MEDIA_BUY_DIALOG && addMediaBuyStep) {
      await saveMediaBuy()
      setCurrentStep(BRIEF_STEPS.MEDIA_BUY)

      return
    }

    // set Product Sample
    if (additionalStep === ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG && addProductSample) {
      await saveProductSample()
      setCurrentStep(BRIEF_STEPS.PRODUCT)

      return
    }

    // show summary
    if (additionalStep !== ADDITIONAL_STEPS.SUMMARY && !addMediaBuyStep && needShowSummary) {
      await saveBrief()
      setAdditionalStep(ADDITIONAL_STEPS.SUMMARY)

      return
    }

    if (!currentUser.isApproved && additionalStep === ADDITIONAL_STEPS.SUMMARY) {
      await handleSubmitBrief()
      return
    }

    // show final summary
    if (lastIndex && !needShowDialogToAddMediaBuy) {
      // @ts-ignore
      const hasErrors = uploadFormRef.current.validateFields()

      if (hasErrors) {
        return
      }

      await saveBrief()
      showFinalSummary()

      return
    }

    await saveBrief()
    setAdditionalStep(null)
    if (!lastIndex) {
      setCurrentStep(formSteps[index + 1])
    }
  }

  const handleBack = () => {
    if (currentStep === 'BRIEF_SERVICES') {
      if (!targetingStepFields.targetCountries.includes(COUNTRY_NAME.SAUDI_ARABIA)) {
        targetingStepFields.targetCountries.push(COUNTRY_NAME.SAUDI_ARABIA)
        handleTargetingStep(targetingStepFields)
      }
    }

    const index = formSteps.indexOf(currentStep)

    if (additionalStep === ADDITIONAL_STEPS.MEDIA_BUY_DIALOG) {
      setAdditionalStep(null)
      setCurrentStep(BRIEF_STEPS.SERVICES)
      return
    }

    if (additionalStep === ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG) {
      setAdditionalStep(null)
      setCurrentStep(BRIEF_STEPS.MEDIA_BUY)
      return
    }

    if (additionalStep === ADDITIONAL_STEPS.SUMMARY) {
      setAdditionalStep(null)
      setCurrentStep(summaryPreviousStep)
      return
    }

    if (additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY) {
      setAdditionalStep(null)
      if (
        brief.BRIEF_TYPE.briefTypes.length === 1 &&
        brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
      )
        setCurrentStep(formSteps[index - 1])
      else setCurrentStep(finalSummaryPreviousStep)
      return
    }

    if (index === 0) {
      return
    }

    setCurrentStep(formSteps[index - 1])
  }

  const setCurrentStep = (step: BRIEF_STEPS) => {
    setAdditionalStep(null)
    dispatch(actions.setCurrentStep(step))
  }

  const forceUpdateCustomizeBoxStep = async () => {
    const updatedBrief = { ...brief }
    const customizeBoxFields = { ...customizedStepFields }

    customizeBoxFields.customizeBoxBriefName = ''
    customizeBoxFields.customizeBoxDistributionCount = null
    customizeBoxFields.customizeBoxProductCount = null
    customizeBoxFields.customizeBoxProductType = []
    customizeBoxFields.customizeBoxStartDate = null
    customizeBoxFields.customizeBoxProductImages = []
    customizeBoxFields.customizeBoxAdditionalComment = null

    setCustomizedStepFields(customizeBoxFields)
    updatedBrief[BRIEF_STEPS.CUSTOMIZED] = customizeBoxFields

    await dispatch(actions.updateBrief(BRIEF_STEPS.CUSTOMIZED, updatedBrief))
  }

  const forceUpdateProductStep = async () => {
    const updatedBrief = { ...brief }
    const productFields = { ...productStepFields }

    productFields.briefStartDate = null
    productFields.distributionItem = null
    productFields.quantity = null
    productFields.duration = null

    setProductStepFields(productFields)
    updatedBrief[BRIEF_STEPS.PRODUCT] = productFields

    await dispatch(actions.updateBrief(BRIEF_STEPS.PRODUCT, updatedBrief))
  }

  const forceUpdateServicesStep = async () => {
    if (!brief.id) {
      return
    }

    const updatedBrief = { ...brief }

    if (onlyInsert) {
      servicesInitState.servicePrinting = servicesStepFields.servicePrinting
      servicesInitState.serviceIncludingCoupon = servicesStepFields.serviceIncludingCoupon
      servicesInitState.serviceDesign = servicesStepFields.serviceDesign
      servicesInitState.printingPriceId = servicesStepFields.printingPriceId
      servicesInitState.serviceImages = servicesStepFields.serviceImages
    }

    if (onlySample) {
      servicesInitState.servicePackageType = servicesStepFields.servicePackageType
      // servicesInitState.servicePackaging = servicesStepFields.servicePackaging
      // servicesInitState.servicePackagingNeeds = servicesStepFields.servicePackagingNeeds
      // servicesInitState.servicePackagingQuantity = servicesStepFields.servicePackagingQuantity
      servicesInitState.printingPriceId = servicesStepFields.printingPriceId
      servicesInitState.serviceIsLiquid = servicesStepFields.serviceIsLiquid
      // servicesInitState.hasFreeSample = servicesStepFields.hasFreeSample
      servicesInitState.serviceImages = servicesStepFields.serviceImages
    }

    setServicesStepFields(servicesInitState)
    updatedBrief[BRIEF_STEPS.SERVICES] = servicesInitState

    await dispatch(actions.updateBrief(BRIEF_STEPS.SERVICES, updatedBrief))
  }

  const forceUpdateMediaBuyStep = () => {
    if (!brief.id) {
      return
    }

    const updatedBrief = { ...brief }
    setMediaBuyStepFields(mediaBuyInitState)
    updatedBrief[BRIEF_STEPS.MEDIA_BUY] = mediaBuyInitState

    dispatch(actions.updateBrief(BRIEF_STEPS.MEDIA_BUY, updatedBrief))
  }

  const forceUpdateConsumerResearchStep = () => {
    if (!brief.id) {
      return
    }

    const updatedBrief = { ...brief }
    setConsumerResearchStepFields(consumerResearchInitState)
    updatedBrief[BRIEF_STEPS.CONSUMER_RESEARCH] = consumerResearchInitState

    dispatch(actions.updateBrief(BRIEF_STEPS.MEDIA_BUY, updatedBrief))
  }

  const updateBriefBeforeSave = () => {
    const updatedBrief = { ...brief }
    const currentStepIndex = formSteps.indexOf(currentStep)

    if (currentStepIndex > lastFilledStepIndex) {
      updatedBrief.lastFilledStep = currentStep
    }

    if (isZeroStep) {
      updatedBrief[BRIEF_STEPS.TYPE].briefTypes = briefTypes
      if (!hasSampleStep && lastFilledStepIndex > 0) {
        forceUpdateProductStep()
        forceUpdateServicesStep()
      }

      if (!hasMediaBuyStep && lastFilledStepIndex > 0) {
        forceUpdateMediaBuyStep()
      }

      if (!hasConsumerResearchStep && lastFilledStepIndex > 0) {
        forceUpdateConsumerResearchStep()
      }
    }

    if (isCustomizedStep) {
      const customizedFields = { ...customizedStepFields }
      customizedFields.customizeBoxStartDate = customizedFields.customizeBoxStartDate
        ? getIsoDate(customizedFields.customizeBoxStartDate)
        : null
      customizedFields.customizeBoxAdditionalComment =
        customizedStepFields.customizeBoxAdditionalComment === ''
          ? null
          : customizedStepFields.customizeBoxAdditionalComment
      updatedBrief[BRIEF_STEPS.CUSTOMIZED] = customizedFields
    }

    if (isProductStep) {
      const productFields = { ...productStepFields }
      productFields.briefStartDate = productFields.briefStartDate ? getIsoDate(productFields.briefStartDate) : null
      productFields.instructions = productStepFields.instructions === '' ? null : productStepFields.instructions
      updatedBrief[BRIEF_STEPS.PRODUCT] = productFields

      if ((onlyInsert || onlySample) && lastFilledStepIndex > 1) {
        forceUpdateServicesStep()
      }
    }

    if (isTargetingStep) {
      updatedBrief[BRIEF_STEPS.TARGETING] = targetingStepFields
    }

    if (isServiceStep) {
      updatedBrief[BRIEF_STEPS.SERVICES] = servicesStepFields
    }

    if (isMediaBuyStep) {
      const mediaBuyFields = { ...mediaBuyStepFields }
      mediaBuyFields.mediaStartDate = mediaBuyFields.mediaStartDate ? getIsoDate(mediaBuyFields.mediaStartDate) : null
      updatedBrief[BRIEF_STEPS.MEDIA_BUY] = mediaBuyFields
    }

    if (isConsumerResearchStep) {
      const consumerResearchFields = { ...consumerResearchStepFields }
      consumerResearchFields.consumerResearchStartDate = consumerResearchFields.consumerResearchStartDate
        ? getIsoDate(consumerResearchFields.consumerResearchStartDate)
        : null
      updatedBrief[BRIEF_STEPS.CONSUMER_RESEARCH] = consumerResearchFields
    }

    return updatedBrief
  }

  const updateBriefTypes = async (type: BRIEF_TYPES) => {
    const updatedBrief = { ...brief }
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = briefTypes

    if (type === BRIEF_TYPES.PRODUCT_SAMPLE) {
      updatedBrief.lastFilledStep = BRIEF_STEPS.PRODUCT
    }
    if (type === BRIEF_TYPES.MEDIA_BUYING) {
      updatedBrief.lastFilledStep = BRIEF_STEPS.MEDIA_BUY
    }

    await dispatch(actions.updateBrief(BRIEF_STEPS.TYPE, updatedBrief))
  }

  const saveBrief = async () => {
    const briefForSave = updateBriefBeforeSave()
    await dispatch(actions.updateBrief(currentStep, briefForSave))
  }

  const checkStatus = (step: BRIEF_STEPS) => {
    const stepIndex = formSteps.indexOf(step)
    const lockedSteps = [BRIEF_STEPS.PARTNER, BRIEF_STEPS.UPLOAD]

    if (disableEdit && step !== BRIEF_STEPS.UPLOAD) {
      return BRIEF_STEP_STATUS.DISABLED
    }

    if (!currentUser.isApproved && lockedSteps.includes(step)) {
      return BRIEF_STEP_STATUS.DISABLED
    }

    if (currentStep === step) {
      return BRIEF_STEP_STATUS.CURRENT
    }

    if (lastFilledStepIndex >= stepIndex) {
      return BRIEF_STEP_STATUS.PASSED
    }

    return BRIEF_STEP_STATUS.DEFAULT
  }

  const handleStepperClick = (step: BRIEF_STEPS) => {
    if (currentStep === 'BRIEF_TARGETING') {
      if (!targetingStepFields.targetCountries.includes(COUNTRY_NAME.SAUDI_ARABIA)) {
        targetingStepFields.targetCountries.push(COUNTRY_NAME.SAUDI_ARABIA)
        handleTargetingStep(targetingStepFields)
      }
    }

    if (!canProceed()) return
    setCurrentStep(step)
  }

  const steps = !isZeroStep && (
    <div className="qu-stepper">
      <div className="steps">
        {formSteps.map(
          (step: BRIEF_STEPS, index: number) =>
            step !== BRIEF_STEPS.TYPE && (
              <QBriefStep
                key={step}
                title={`${index}. ${BRIEF_STEPS_NAMES[step]}`}
                onClickPath={() => handleStepperClick(step)}
                status={checkStatus(step)}
              />
            )
        )}
      </div>
    </div>
  )

  const handleBriefTypeStep = (briefTypes: BRIEF_TYPES[]) => {
    setBriefTypes(briefTypes)
  }

  const handleCustomizedStep = (fields: ICustomizedStep) => {
    if (lastFilledStepIndex > 1) {
      const newBrief = { ...brief }
      newBrief.lastFilledStep = BRIEF_STEPS.TARGETING
      dispatch(actions.createBriefSuccess(newBrief))
    }
    setCustomizedStepFields(fields)
  }

  const handleProductStep = (fields: IProductStep) => {
    if (fields.distributionItem === DISTRIBUTION_TYPE.PRODUCT_SAMPLE && lastFilledStepIndex > 1) {
      const newBrief = { ...brief }
      newBrief.lastFilledStep = BRIEF_STEPS.TARGETING
      dispatch(actions.createBriefSuccess(newBrief))
    }

    setProductStepFields(fields)
  }

  const handleTargetingStep = (fields: ITargetingStep) => {
    setTargetingStepFields(fields)
  }

  const handleServicesStep = (fields: IServicesStep) => {
    setServicesStepFields(fields)
  }

  const handleMediaBuyStep = (fields: IMediaBuyStep) => {
    setMediaBuyStepFields(fields)
  }

  const handleConsumerResearchStep = (fields: IConsumerResearchStep) => {
    setConsumerResearchStepFields(fields)
  }

  const handleUploadStep = (fields: IUploadPartner[]) => {
    brief[BRIEF_STEPS.UPLOAD].uploadMaterial = fields
  }

  const canProceed = () => {
    if (isZeroStep && briefTypes.length === 0) return false
    if (isCustomizedStep) return validateCustomizedStep(customizedStepFields)
    if (isProductStep) return validateTestahelProductStep(productStepFields, hasSampleStep)
    if (isTargetingStep) return validateTestahelTargetingStep(targetingStepFields, settings.countries)
    if (isServiceStep) return validateTestahelServicesStep(servicesStepFields, onlyInsert)
    if (isMediaBuyStep) return validateMediaBuyStep(mediaBuyStepFields)
    if (isConsumerResearchStep) return validateConsumerResearchStep(consumerResearchStepFields)

    return true
  }

  const renderCurrentStepForm = () => {
    if (isProductStep) {
      return (
        <TestahelProductBriefForm
          productFields={productStepFields}
          onChange={handleProductStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    if (isCustomizedStep) {
      return (
        <TestahelCustomizedBoxForm
          customizedFields={customizedStepFields}
          onChange={handleCustomizedStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    if (isTargetingStep) {
      return (
        <TestahelTargetingForm
          brief={brief}
          targetingFields={targetingStepFields}
          onChange={handleTargetingStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    if (isServiceStep) {
      return (
        <TestahelServicesForm
          brief={brief}
          servicesFields={servicesStepFields}
          onChange={handleServicesStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    if (isMediaBuyStep) {
      return (
        <TestahelMediaBuyForm
          brief={brief}
          mediaBuyFields={mediaBuyStepFields}
          onChange={handleMediaBuyStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    if (isConsumerResearchStep) {
      return (
        <TestahelConsumerResearchForm
          brief={brief}
          mediaBuyFields={consumerResearchStepFields}
          onChange={handleConsumerResearchStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      )
    }

    return null
  }

  if (additionalStep === ADDITIONAL_STEPS.CONGRATS) {
    return <TestahelSubmittedSuccessfully approvedUser={currentUser.isApproved} />
  }

  if (!brief.lastFilledStep) {
    return (
      <div className="qu-brief-layout">
        <TestahelBriefHeader
          title={BRIEF_FORM_HEADER_TITLE.EDIT}
          stepper={null}
          onClickCancel={() => {
            if (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN))
              history.push(`/p/${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`)
            else history.push(PATHS.BRIEF)
          }}
        />
        <div className="qu-brief-main">
          <SpinnerCenter />
        </div>
      </div>
    )
  }
  return (
    <div className="qu-brief-layout">
      {(localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO) && (
          <header className="qu-header partner-logo-header">
            <img src={partnerLogo} className="partner-logo-brief" />
          </header>
        )}
      <TestahelBriefHeader
        title={BRIEF_FORM_HEADER_TITLE.EDIT}
        stepper={steps}
        showPartnerFilter={isPartnerStep && !additionalStep}
        onClickCancel={() => {
          if (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN))
            history.push(`/p/${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`)
          else history.push(PATHS.BRIEF)
        }}
      />
      <div
        className={`qu-brief-main ${isPartnerStep ? 'qu-brief-partner' : ''} ${
          partnerLogo ? 'partner-logo-qu-brief-main' : ''
        }`}
        id={MAIN_PRINT_ID}
      >
        {additionalStep === ADDITIONAL_STEPS.SUMMARY && (
          <TestahelSummaryForm briefId={brief.id!} setCurrentStep={setCurrentStep} />
        )}

        {additionalStep === ADDITIONAL_STEPS.MEDIA_BUY_DIALOG && (
          <BriefDialog checked={addMediaBuyStep} onChange={handleMediaBuyDialog} title={'Media Buying'} />
        )}

        {additionalStep === ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG && (
          <BriefDialog
            checked={addProductSample}
            onChange={handleProductSampleDialog}
            title={'Product Sample or Printed Insert'}
          />
        )}
        {isFinalSummary && (
          <TestahelFinalSummary onFinishLater={handleFinishLater} briefId={brief.id!} setCurrentStep={setCurrentStep} />
        )}

        {isZeroStep && !additionalStep && (
          <TestahelBriefTypeForm briefTypes={briefTypes} onChange={handleBriefTypeStep} />
        )}
        {isPartnerStep && !additionalStep && <TestahelBriefPartnerForm brief={brief} saveMediaBuy={saveMediaBuy} />}
        {isUploadStep && !additionalStep && (
          <TestahelUploadForm
            ref={uploadFormRef}
            brief={brief}
            onChange={handleUploadStep}
            onFinishLater={handleFinishLater}
            disabledFinishLater={!canProceed()}
            skipUploadStep={skipUploadStep}
            showFinalSummary={showFinalSummary}
          />
        )}
        {!isZeroStep && !additionalStep && !isPartnerStep && !isUploadStep && (
          <div className="qu-brief-content brief-step">
            <Row gutter={16}>
              <Col sm={24} md={17}>
                {renderCurrentStepForm()}
              </Col>
              <Col sm={24} md={7}>
                <div className="qu-summary-wrapper">
                  <TestahelBriefSummary brief={brief} />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
      {showFooter && (
        <TestahelBriefFooter
          disableNext={!canProceed()}
          disableBack={blockEditBrief}
          isZeroStep={isZeroStep}
          onBack={handleBack}
          onNext={isFinalSummary ? handleSubmitBrief : handleNext}
          onFinishLater={handleFinishLater}
          nextButtonText={submitButtonText()}
        />
      )}
      <Modal
        wrapClassName="qu-modal"
        title={<h3>{'Join Quantums Platform!'}</h3>}
        closeIcon={closeIcon}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={false}
      >
        <div>
          <p className="mb-25">You're just a few steps away from working with {partnerData?.companyName}</p>
          <div className="align-vertical text-center">
            <QButton onClick={handleDiscard} className="w-280 mb-20" type="primary">
              <span className="mr-5">Sign up now</span>
              <CircleCheckIcon />
            </QButton>
            <button onClick={handleContinue} type="button" className="button button--link mb-10">
              I already have an account
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{discountCodeErrorModal.title}</h3>}
        width={400}
        closeIcon={<IconsCommon.IconClose />}
        visible={discountCodeErrorModal.visible}
        onCancel={() =>
          setDiscountCodeErrorModal({
            title: '',
            visible: false,
            description: '',
          })
        }
        footer={false}
      >
        <div>
          <p className="mb-25">{discountCodeErrorModal.description}</p>
          <div className="align-vertical text-center">
            <QButton onClick={onProceedDiscountCodeErrorModal} className="w-280 mb-20" type="primary">
              <span className="mr-5">Remove Code & Review Brief</span>
            </QButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}
