import React, { ReactElement, useEffect, useState } from 'react'
import { Col, Form, QButton, QInput, QRadio, QSelect, Radio, Row } from 'quantum_components'
import { IBrief, ICOPACKINGPRINTINGPRICES, IOptionForSelect, IServicesStep } from '../../testahelBox.types'
import { useSelector } from 'react-redux'
import { getBriefSettings, getIsImagesUploading } from '../../testahelBox.selectors'
import TestahelPackageTypeCard from '../components/TestahelBrief.PackageTypeCard'
import { REGEXES } from '../../../../../constants'
import { isMobile } from 'react-device-detect'
import { ReactComponent as LogOut } from '../../../../../assets/icons/logout-icon-alt.svg'
import { BRIEF_STEPS, BRIEF_STEPS_NAMES, BRIEF_UPLOAD_TYPES } from '../../../../../constants/testahel_brief'
import TestahelPopoverSummary from '../components/TestahelBrief.PopoverSummary'
import UploadComponent from '../../../../../components/UploadComponent'
import { actions } from '../../testahelBox.module'
import { useReduxDispatch } from '../../../../../helpers'
import IUploadFile from '../../../../../interfaces/IUploadFile'
import { getFileNameFromUrl } from '../../../../../utils'
import { isInsert, isSample } from '../../../../../helpers/testahelBrief'

interface IProps {
  brief: IBrief
  servicesFields: IServicesStep
  onChange: (serviceFields: IServicesStep) => void
  onFinishLater: () => void
  disabledFinishLater: boolean
}

export default function TestahelServicesForm({
  brief,
  servicesFields,
  onChange,
  onFinishLater,
  disabledFinishLater,
}: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const settings = useSelector(getBriefSettings)
  const isImagesUploading = useSelector(getIsImagesUploading)
  const [errorPackaging, setPackagingErrors] = useState<string>('')
  const [files, setFiles] = useState<IUploadFile[]>([])
  const [form] = Form.useForm()

  const filesMapper = () => {
    const list = servicesFields.serviceImages
      ? servicesFields.serviceImages.map((url: string, index: number) => {
          return {
            uid: index + '',
            name: getFileNameFromUrl(url),
            status: 'done',
            url: url,
          } as IUploadFile
        })
      : []
    setFiles(list)
  }

  useEffect(() => {
    form.setFieldsValue(servicesFields)
  })

  useEffect(() => {
    filesMapper()
  }, [servicesFields.serviceImages.length])

  // useEffect(() => {
  //   form.validateFields(['servicePackagingNeeds', 'servicePackagingQuantity'])
  // }, [servicesFields.servicePackaging])

  useEffect(() => {
    form.validateFields(['printingPriceId'])
  }, [servicesFields.servicePrinting])

  const briefQuantity = brief[BRIEF_STEPS.PRODUCT].quantity

  const validatePackagingType = (items: string[]) => {
    const msg = items.length > 0 ? '' : 'Should be at least one packaging type.'
    setPackagingErrors(msg)
  }

  const handlePackagingType = (e: MouseEvent, name: string) => {
    const fields = { ...servicesFields }
    fields.servicePackageType[0] = name
    validatePackagingType(fields.servicePackageType)

    onChange(fields)
  }

  // const resetPackagingFields = (fields: IServicesStep) => {
  //   form.setFieldsValue({
  //     // servicePackagingNeeds: null,
  //     servicePackagingQuantity: null,
  //   })
  //   // fields.servicePackagingNeeds = null
  //   fields.servicePackagingQuantity = null
  // }

  const resetPrintingFields = (fields: IServicesStep) => {
    form.setFieldsValue({
      printingPriceId: null,
    })
    fields.printingPriceId = null
  }

  const resetDesignField = (fields: IServicesStep) => {
    form.setFieldsValue({
      serviceDesign: false,
    })
    fields.serviceDesign = false
  }

  const handleChangeRadio = (e: any) => {
    const fields = { ...servicesFields }
    const { name, value } = e.target

    if (name === 'serviceIncludingCoupon' && !value) {
      resetDesignField(fields)
    }

    // if (name === 'servicePackaging' && !value) {
    //   resetPackagingFields(fields)
    // }

    if (name === 'servicePrinting' && !value) {
      resetPrintingFields(fields)
    }

    // @ts-ignore
    fields[name] = value
    onChange(fields)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fields = { ...servicesFields }
    const { id, value, type } = e.target

    if (value) {
      let num = +value
      if (briefQuantity && num > briefQuantity) {
        num = briefQuantity
      }

      // @ts-ignore
      fields[id] = type === 'number' ? num : value
    } else {
      // @ts-ignore
      fields[id] = null
    }
    onChange(fields)
  }

  const handleTemperatureChange = (value: string) => {
    const fields = { ...servicesFields }
    fields.serviceTemperature = value

    onChange(fields)
  }

  const handlePrintingChange = (value: number) => {
    const fields = { ...servicesFields }
    fields.printingPriceId = value

    onChange(fields)
  }

  const handleUpload = async (uploads: File[]) => {
    if (files.length > 0) {
      await dispatch(actions.removeImage(brief, files[0], BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES))
    }

    dispatch(actions.uploadProductImage(brief, uploads, BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES))
  }

  const handleRemove = async () => {
    await dispatch(actions.removeImage(brief, files[0], BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES))
  }

  const onlyInsert = isInsert(brief[BRIEF_STEPS.PRODUCT].distributionItem)
  const onlySample = isSample(brief[BRIEF_STEPS.PRODUCT].distributionItem)

  const serviceTempOptions: IOptionForSelect[] = settings.temperature.map((option: string) => {
    return { label: option, value: option }
  })

  const printingPrices: IOptionForSelect[] = settings.printingPrices.map((option: ICOPACKINGPRINTINGPRICES) => {
    return {
      label: option.name + (option.minQuantity > 0 ? ` (min. qty.: ${option.minQuantity})` : ''),
      value: option.id,
    }
  })

  return (
    <Form form={form} layout="vertical" hideRequiredMark initialValues={servicesFields}>
      <div className={`form form-brief form--horizontal`}>
        <div className="form__head">
          <div className="form__number">
            {isMobile ? <span>{`3. ${BRIEF_STEPS_NAMES[BRIEF_STEPS.SERVICES]}`}</span> : '3'}
          </div>
          <h3>Additional services and info</h3>
        </div>
        <TestahelPopoverSummary brief={brief} />
        <div className="form__cont">
          {onlySample && (
            <React.Fragment>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="targetFocus" className="form-label">
                    * Packaging Type
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Row gutter={16} align="middle">
                    {settings.packagingTypes.map((name: string) => {
                      const isChecked = servicesFields.servicePackageType.includes(name)
                      return (
                        <TestahelPackageTypeCard
                          key={name}
                          checked={isChecked}
                          name={name}
                          onChange={handlePackagingType}
                        />
                      )
                    })}
                  </Row>
                  {errorPackaging && <div className="text-red font-size-12 mt-5 mb-10">{errorPackaging}</div>}
                </Col>
              </Row>
              <hr className="form__hr" />
            </React.Fragment>
          )}
          {onlySample && (
            <React.Fragment>
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="serviceIsLiquid" className="form-label">
                    Sample is liquid
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Row gutter={16} align="middle">
                    <Form.Item className="mb-0 full-width" name="serviceIsLiquid">
                      <Radio.Group
                        className="qu-radio-group"
                        name="serviceIsLiquid"
                        value={servicesFields.serviceIsLiquid}
                        onChange={handleChangeRadio}
                      >
                        <QRadio value={true}>Yes</QRadio>
                        <QRadio value={false}>No</QRadio>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
              <hr className="form__hr" />

              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="serviceTemperature" className="form-label">
                    What's the temperature conditions needed to store your product?
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Form.Item className="mb-0" name="serviceTemperature">
                    <QSelect
                      value={servicesFields.serviceTemperature}
                      onChange={handleTemperatureChange}
                      showSearch
                      size="large"
                      placeholder="Select service temperature."
                      options={serviceTempOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <hr className="form__hr" />
            </React.Fragment>
          )}

          {onlyInsert && (
            <React.Fragment>
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="serviceIncludingCoupon" className="form-label">
                    Include Coupon / Marketing Material
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Row gutter={16} align="middle">
                    <Form.Item className="mb-0 full-width" name="serviceIncludingCoupon">
                      <Radio.Group
                        className="qu-radio-group"
                        name="serviceIncludingCoupon"
                        value={servicesFields.serviceIncludingCoupon}
                        onChange={handleChangeRadio}
                      >
                        <QRadio value={true}>Yes</QRadio>
                        <QRadio value={false}>No</QRadio>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
              {servicesFields.serviceIncludingCoupon && (
                <Row className="mt-20" gutter={16} align="middle">
                  <Col xs={24} sm={24} md={10}>
                    <label htmlFor="serviceDesign" className="form-label">
                      Do you require insert design?
                    </label>
                  </Col>
                  <Col xs={24} sm={24} md={14}>
                    <Row gutter={16} align="middle">
                      <Form.Item className="mb-0 full-width" name="serviceDesign">
                        <Radio.Group
                          className="qu-radio-group"
                          name="serviceDesign"
                          value={servicesFields.serviceDesign}
                          onChange={handleChangeRadio}
                        >
                          <QRadio value={true}>Yes</QRadio>
                          <QRadio value={false}>No</QRadio>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              )}
              <hr className="form__hr" />
              {/* <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="servicePrinting" className="form-label">
                    Do you require printing?
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Row gutter={16} align="middle">
                    <Form.Item className="mb-0 full-width" name="servicePrinting">
                      <Radio.Group
                        className="qu-radio-group"
                        name="servicePrinting"
                        value={servicesFields.servicePrinting}
                        onChange={handleChangeRadio}
                      >
                        <QRadio value={true}>Yes</QRadio>
                        <QRadio value={false}>No</QRadio>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
              <hr className="form__hr" /> */}
            </React.Fragment>
          )}
          {onlySample && (
            <React.Fragment>
              {/* <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="servicePackaging" className="form-label">
                    Do you require co-packing services?
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Row gutter={16} align="middle">
                    <Form.Item className="mb-0 full-width" name="servicePackaging">
                      <Radio.Group
                        className="qu-radio-group"
                        name="servicePackaging"
                        value={servicesFields.servicePackaging}
                        onChange={handleChangeRadio}
                      >
                        <QRadio value={true}>Yes</QRadio>
                        <QRadio value={false}>No</QRadio>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                </Col>
              </Row> */}
              {/* {servicesFields.servicePackaging && (
                <>
                  <Row className="mv-20" gutter={16} align="middle">
                    <Col xs={24} sm={24} md={10}>
                      <label htmlFor="servicePackagingNeeds" className="form-label mb-25">
                        * Describe your co-packaging needs
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={14}>
                      <Form.Item
                        className="mb-0"
                        name="servicePackagingNeeds"
                        rules={[
                          {
                            required: servicesFields.servicePackaging,
                            min: 0,
                            max: 255,
                            whitespace: true,
                            message: 'Description is required and should be less then 255 characters.',
                          },
                        ]}
                        validateTrigger={['onBlur']}
                      >
                        <QInput
                          value={servicesFields.servicePackagingNeeds}
                          onChange={handleInputChange}
                          placeholder="Enter description"
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16} align="middle">
                    <Col xs={24} sm={24} md={10}>
                      <label htmlFor="servicePackagingQuantity" className="form-label">
                        * Sample(s) per bundle
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={7}>
                      <Form.Item
                        className="mb-0"
                        name="servicePackagingQuantity"
                        rules={[
                          {
                            required: servicesFields.servicePackaging,
                            whitespace: true,
                            pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                            message: `Should be more than 0 and less than ${briefQuantity}.`,
                          },
                        ]}
                        validateTrigger={['onBlur']}
                      >
                        <QInput
                          value={servicesFields.servicePackagingQuantity}
                          onChange={handleInputChange}
                          min={0}
                          type="number"
                          placeholder="Enter quantity"
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )} */}
              {/* <hr className="form__hr" />
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="servicePrinting" className="form-label">
                    Does the product has free sample written on it?
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Row gutter={16} align="middle">
                    <Form.Item className="mb-0 full-width" name="hasFreeSample">
                      <Radio.Group
                        className="qu-radio-group"
                        name="hasFreeSample"
                        value={servicesFields.hasFreeSample}
                        onChange={handleChangeRadio}
                      >
                        <QRadio value={true}>Yes</QRadio>
                        <QRadio value={false}>No</QRadio>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
              <hr className="form__hr" /> */}
            </React.Fragment>
          )}

          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="servicePrinting" className="form-label">
                Do you require printing?
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Row gutter={16} align="middle">
                <Form.Item className="mb-0 full-width" name="servicePrinting">
                  <Radio.Group
                    className="qu-radio-group"
                    name="servicePrinting"
                    value={servicesFields.servicePrinting}
                    onChange={handleChangeRadio}
                  >
                    <QRadio value={true}>Yes</QRadio>
                    <QRadio value={false}>No</QRadio>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>
          </Row>
          {servicesFields.servicePrinting && (
            <Row className="mv-20" gutter={16} align="middle">
              <Col xs={24} sm={24} md={10}>
                <label htmlFor="printingPriceId" className="form-label mb-25">
                  * Select your printing needs
                </label>
              </Col>
              <Col xs={24} sm={24} md={14}>
                <Form.Item
                  className="mb-0"
                  name="printingPriceId"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose printing.',
                    },
                  ]}
                  validateTrigger={['onBlur', 'onChange']}
                >
                  <QSelect
                    value={servicesFields.printingPriceId}
                    onChange={handlePrintingChange}
                    size="large"
                    options={printingPrices}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <hr className="form__hr" />

          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="productImage" className="form-label mt-35">
                Upload your flyer or brochure
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item className="mb-0">
                <UploadComponent
                  files={files}
                  onChange={handleUpload}
                  onRemove={handleRemove}
                  isUploading={isImagesUploading}
                />
              </Form.Item>
            </Col>
          </Row>
          {isMobile && (
            <div className="form-brief-footer">
              <QButton
                disabled={disabledFinishLater}
                onClick={onFinishLater}
                className="qu-button-outline mt-30 full-width"
              >
                Save & Finish Later <LogOut className="ml-10" />
              </QButton>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}
