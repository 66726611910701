import React, { ReactElement, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { ICartItem, ICartList, ICartServiceItem, IProposal, IBriefResponse } from '../../testahelBox.types'
import { IconsCommon, Modal, QBriefCartItem, QBriefCartProduct, QButton, ServicesSummary } from 'quantum_components'
import { Descriptions } from 'antd'
import {
  BRIEF_PRINT_ID,
  BRIEF_STEPS,
  BRIEF_TYPES,
  CONSUMER_RESEARCH_MEDIA_TYPES,
  DISTRIBUTION_TYPE,
  MEDIA_TYPES,
  MIN_LOGISTIC_PRICE_TEXT,
} from '../../../../../constants/testahel_brief'
import { renderRoundedNumber } from '../../../../../helpers/currency'
import { isMobile } from 'react-device-detect'
import { REQUEST_STATUS } from '../../../../../constants/request'
import { useHistory, useParams } from 'react-router-dom'
import { COLORS } from '../../../../../constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBrief,
  getBriefSettings,
  getBriefView,
  getPartnerFilter,
  getPrintingPrice,
  getProposalLoading,
  getRecommendedPartners,
} from '../../testahelBox.selectors'
import { getBriefProposalPdf, togglePrintClass } from '../../../../../utils/getScreenPage'
import TestahelEmailProposalForm from '../../../../../components/TestahelBriefView/TestahelEmailProposalForm.component'
import { actions } from '../../testahelBox.module'
import { getCurrency, getCurrentUser, getCurrentUserRole } from '../../../../core/core.selectors'
import ROLES from '../../../../../constants/roles'
import { partnerCalculation } from '../../../../../helpers/cart'
import { getBase64 } from '../../../../../utils/getBase64'
import { canEditBrief } from '../../../../../helpers/rules'
import { PRICING_MODEL } from 'src/constants/profile'

const checkIcon = <IconsCommon.IconCheck color={COLORS.LIGHT_GREEN} />
const uncheckIcon = <IconsCommon.IconStepIncomplete className="i" />
const arrowIcon = <IconsCommon.IconArrowRight />
const closeIcon = <IconsCommon.IconClose />

interface Props {
  cart: ICartList
  setCurrentStep: (step: BRIEF_STEPS) => void
  onEdit: (id: number) => void
  viewMode?: boolean
  hideProposalButtons?: boolean
  briefView?: IBriefResponse
}

export default function TestahelBriefCartSummary({
  cart,
  setCurrentStep,
  onEdit,
  viewMode,
  hideProposalButtons,
  briefView,
}: Props): ReactElement {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams<{id: any}>()
  const briefId = id
  const currentUser: any = useSelector(getCurrentUser)
  const settings = useSelector(getBriefSettings)
  const emailProposalLoading = useSelector(getProposalLoading)
  const MINIMUM_DISTRIBUTION_LOGISTIC_COUNT = settings.MINIMUM_DISTRIBUTION_LOGISTIC_COUNT
  const [openProposalModal, setOpenProposalModal] = useState<boolean>(false)
  const role = useSelector(getCurrentUserRole)
  const isPartner = role === ROLES.PARTNER
  const currency = useSelector(getCurrency)
  const brief = useSelector(getBrief)
  const viewBrief = useSelector(getBriefView)
  const printingPrice = useSelector(getPrintingPrice)
  const filter = useSelector(getPartnerFilter)
  const partners = useSelector(getRecommendedPartners)

  const briefStatus = briefView ? briefView.status : brief.status
  const disableEdit = !canEditBrief(briefStatus)

  const [generatingPdf, setGeneratingPdf] = useState<boolean>(false)
  const [cartItems, setCartItems] = useState<ICartItem[]>([])
  const [sampleAllocated, setSampleAllocated] = useState(0)

  const disabledButton = emailProposalLoading

  useEffect(() => {
    getLogos()
    if (cart.sampleAllocated) setSampleAllocated(cart.sampleAllocated)
    else setSampleAllocated(0)
  }, [cart])

  useEffect(() => {
    if (briefView?.briefTypes?.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)) {
      dispatch(actions.fetchRecommendedPartners(briefId, filter))
    }
  }, [briefView])

  const getUploadsBase64 = async (item: ICartItem) => {
    if (!item.companyLogoUrl) return ''
    return Promise.resolve(getBase64(item.companyLogoUrl))
  }

  const getLogos = async () => {
    const items = cart.additional.items
    if (!items) return

    const list = items.map((item: ICartItem) => {
      getUploadsBase64(item).then((data: string) => {
        item.companyLogoUrl = data
      })
      return item
    })

    setCartItems(list)
  }

  const handleEdit = (partnerId: number) => {
    onEdit(partnerId)
  }

  const togglePrint = (value: boolean) => {
    const el = document.getElementById('ServiceCard')
    return value ? el?.classList.add('visible-block') : el?.classList.remove('visible-block')
  }

  const renderServiceItem = (service: ICartServiceItem, priceModel: string) => {
    const label = (
      <span className="label-column">
        <span>{service.name}</span>
        {service.type === MEDIA_TYPES.DISTRIBUTION ? (
          <small>{`${renderRoundedNumber(service.amount, currency)} x ${service.quantity}`}</small>
        ) : window.location.pathname.includes('view') ? (
          (!viewBrief.marketingObjective || priceModel === PRICING_MODEL.DURATION) && (
            <small>{`${renderRoundedNumber(service.amount, currency)} x ${service.quantity}`}</small>
          )
        ) : (
          (!brief.BRIEF_MEDIA_BUY.marketingObjective || priceModel === PRICING_MODEL.DURATION) && (
            <small>{`${renderRoundedNumber(service.amount, currency)} x ${service.quantity}`}</small>
          )
        )}
      </span>
    )

    return (
      <Descriptions.Item key={service.name} label={label}>
        {renderRoundedNumber(service.total, currency)}
      </Descriptions.Item>
    )
  }

  const renderLogisticPriceLabel = (service: ICartServiceItem, item: ICartItem) => {
    const isSample = service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE
    const price = isSample ? item.logisticSamplePrice : item.logisticInsertPrice
    const priceText = renderRoundedNumber(price, currency)

    const rate = isSample
      ? renderRoundedNumber(item.logisticSampleRate, currency)
      : renderRoundedNumber(item.logisticInsertRate, currency)

    const quantity = service.quantity ? service.quantity : 0
    const description =
      quantity < MINIMUM_DISTRIBUTION_LOGISTIC_COUNT ? MIN_LOGISTIC_PRICE_TEXT : `${rate} x ${quantity}`

    const label = (
      <span className="label-column">
        <span>Logistic Price</span>
        <small>{description}</small>
      </span>
    )

    return (
      <Descriptions.Item key="Logistic Price" label={label}>
        {priceText}
      </Descriptions.Item>
    )
  }

  const renderStickerCostItem = (service: ICartServiceItem, item: ICartItem) => {
    const label = (
      <span className="label-column">
        <span>Sticker Cost</span>
        <small>{`${renderRoundedNumber(item.stickerCost, currency)} x ${service.quantity}`}</small>
      </span>
    )

    return (
      <Descriptions.Item key="Sticker Cost" label={label}>
        {renderRoundedNumber(item.stickerPrice, currency)}
      </Descriptions.Item>
    )
  }

  const renderAdditional = (amount: number, name: string, taxes?: boolean) => {
    if (!amount) {
      return
    }

    const price = renderRoundedNumber(amount, currency)

    return (
      <div className="SSUnit__descriptions">
        <Descriptions column={1}>
          <Descriptions.Item label={name}>
            <span className="label-column text-right">
              {name === 'Discount' ? <span className="text-red">- {price}</span> : <span>{price}</span>}
              {taxes && <small>plus taxes</small>}
            </span>
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const handleFindPartner = () => {
    history.push(`/testahel-brief/rejected/${briefId}`)
  }

  const handleSendProposal = async (data: IProposal) => {
    togglePrintClass(true)
    togglePrint(true)
    const markup = document.getElementById(BRIEF_PRINT_ID)
    if (!markup) return

    setOpenProposalModal(false)
    const pdf = await getBriefProposalPdf(markup)
    dispatch(actions.emailProposal(id, data, pdf))
    togglePrintClass(false)
    togglePrint(false)
  }

  const handleDownloadProposal = async () => {
    ReactGA.event({
      category: `Create/Edit Brief - ${brief?.BRIEF_PRODUCT?.name} - ${
        currentUser?.firstName + ' ' + currentUser?.lastName
      }`,
      action: 'Download Proposal',
      label: 'Download Proposal',
      value: 1,
    })
    setGeneratingPdf(true)
    togglePrintClass(true)
    togglePrint(true)

    const markup = document.getElementById(BRIEF_PRINT_ID)
    if (!markup) return

    const pdf = await getBriefProposalPdf(markup)
    pdf.save('Brief Proposal.pdf')

    setGeneratingPdf(false)
    togglePrintClass(false)
    togglePrint(false)
  }

  const handleEmailModalOpen = () => {
    ReactGA.event({
      category: `Create/Edit Brief - ${brief?.BRIEF_PRODUCT?.name} - ${
        currentUser?.firstName + ' ' + currentUser?.lastName
      }`,
      action: 'Email Proposal',
      label: 'Email Proposal',
      value: 1,
    })
    setOpenProposalModal(true)
  }

  const handleEmailModalClose = () => {
    setOpenProposalModal(false)
  }

  const renderCartAdditionalChargesItemCoPacking = (
    item: any,
    name: string,
    totalPrice: number,
    minQty: number,
    servicePackagingQuantity: any
  ) => {
    if (servicePackagingQuantity) {
      const key = item.name
      const total = renderRoundedNumber(
        item.rate *
          (minQty > sampleAllocated
            ? Math.floor(minQty / servicePackagingQuantity)
            : Math.floor(sampleAllocated / servicePackagingQuantity)),
        item.currency
      )
      const description = `${item.rate} ${item.currency} x ${
        minQty > sampleAllocated
          ? Math.floor(minQty / servicePackagingQuantity) + ' (Minimum bundles)'
          : Math.floor(sampleAllocated / servicePackagingQuantity)
      }`
      const bundles = `${
        minQty > sampleAllocated
          ? Math.floor(minQty / servicePackagingQuantity)
          : Math.floor(sampleAllocated / servicePackagingQuantity)
      } bundles (${servicePackagingQuantity} units/bundle)`

      return (
        <QBriefCartItem
          key={key}
          summaryPrice={total}
          summaryName={name}
          summaryDescription={description}
          hideRemove={true}
          summarySubName={item.name}
          bundlesDescription={bundles}
        />
      )
    }
  }

  const renderCartAdditionalChargesItem = (item: any, name: string, totalPrice: number, minQty: number) => {
    const key = item.name
    const total = renderRoundedNumber(totalPrice, item.currency)
    const description = `${item.rate} ${item.currency} x ${
      minQty > sampleAllocated ? minQty + ' (Minimum qty.)' : sampleAllocated
    }`

    return (
      <QBriefCartItem
        key={key}
        summaryPrice={total}
        summaryName={name}
        summaryDescription={description}
        hideRemove={true}
        summarySubName={item.name}
      />
    )
  }

  const partnerCalc = partnerCalculation(cart.additional.items, settings.partnerVat)
  const taxes = isPartner ? partnerCalc.taxes : cart.additional.taxes
  const cartTotal = isPartner ? partnerCalc.total : cart.additional.cartTotal

  const emailModalHeader = <h3>Email this proposal</h3>

  const proposalActions = (
    <div className="proposal-actions">
      <QButton
        loading={disabledButton}
        disabled={disabledButton}
        onClick={handleEmailModalOpen}
        block
        className="qu-button-soft justify-between"
        data-html2canvas-ignore
      >
        Email Proposal <IconsCommon.IconSend />
      </QButton>
      <QButton
        loading={generatingPdf}
        disabled={generatingPdf}
        onClick={handleDownloadProposal}
        block
        className="qu-button-soft justify-between mt-20"
        data-html2canvas-ignore
      >
        Download Proposal <IconsCommon.IconDownload />
      </QButton>

      <Modal
        wrapClassName="qu-modal"
        title={emailModalHeader}
        width={400}
        closeIcon={closeIcon}
        visible={openProposalModal}
        onCancel={handleEmailModalClose}
        footer={false}
      >
        <TestahelEmailProposalForm onSend={handleSendProposal} />
      </Modal>
    </div>
  )

  const content = (
    <div className="SS__partners">
      <div className="SS__category">
        <h4 className="SS__category-title">E-COMMERCE</h4>
        {partners.length > 0 && briefView?.briefTypes?.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX) && (
          <div key={partners[0].id} className="SSUnit">
            <div className="SSUnit__heading" style={{ paddingRight: 'unset' }}>
              <div className="SSUnit__title-frame">
                {partners[0].logoUrl && (
                  <div className="SSUnit__thumb">
                    <img src={partners[0].logoUrl} alt={partners[0].name} />
                  </div>
                )}
                <h4 className="SSUnit__title">{partners[0].name}</h4>
              </div>
              <div>
                <p className="customized-box-cart-summary-title">Customized Box</p>
                <p className="customized-box-cart-summary-text">
                  Please allow 2 business days for our team to get in touch with you for Customized Box pricing.
                </p>
              </div>
            </div>
          </div>
        )}
        {briefView?.briefTypes &&
        briefView?.briefTypes.length === 1 &&
        briefView?.briefTypes?.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
          ? ''
          : cartItems.map((item: ICartItem) => {
              const distributions = item.services.filter((s: ICartServiceItem) => s.type === MEDIA_TYPES.DISTRIBUTION)
              const media = item.services.filter(
                (s: ICartServiceItem) =>
                  s.type !== MEDIA_TYPES.DISTRIBUTION &&
                  s.name !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
                  s.name !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
              )
              const CRMedia = item.services.filter(
                (s: ICartServiceItem) =>
                  s.type !== MEDIA_TYPES.DISTRIBUTION &&
                  (s.name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ||
                    s.name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE)
              )
              const isDistributionAccepted = distributions.some(
                (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.ACCEPTED
              )
              const isDistributionRejected = distributions.some(
                (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.REJECTED
              )
              const isMediaBuyAccepted = media.some(
                (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.ACCEPTED
              )
              const isMediaBuyRejected = media.some(
                (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.REJECTED
              )

              return (
                <div key={item.partnerId} className="SSUnit">
                  <div className="SSUnit__heading">
                    <div className="SSUnit__title-frame">
                      {item.companyLogoUrl && (
                        <div className="SSUnit__thumb">
                          <img src={item.companyLogoUrl} alt={item.companyName} />
                        </div>
                      )}
                      <h4 className="SSUnit__title">{item.companyName}</h4>
                    </div>
                    {disableEdit || viewMode || (currentUser && currentUser.partnerPermissionLevel == 'view') ? null : (
                      <span onClick={() => handleEdit(item.partnerId)} className="SSUnit__edit" data-html2canvas-ignore>
                        Edit
                      </span>
                    )}
                  </div>
                  <div className="SSUnit__content">
                    {distributions.length > 0 && (
                      <div className={`SSUnit__descriptions ${isDistributionRejected && 'rejected'}`}>
                        <Descriptions column={1} title="Distribution">
                          {distributions.map((service: ICartServiceItem) => (
                            <React.Fragment key={item.partnerId + service.name}>
                              {renderServiceItem(service, item.priceModel)}
                              {!isPartner && renderLogisticPriceLabel(service, item)}
                              {!isPartner &&
                                !!item.stickerPrice &&
                                service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
                                renderStickerCostItem(service, item)}
                            </React.Fragment>
                          ))}
                        </Descriptions>
                        {isDistributionAccepted && (
                          <div className="SSUnit__sLabel accepted">
                            <IconsCommon.IconCheck className="i" color={COLORS.LIGHT_GREEN} />
                            Accepted
                          </div>
                        )}
                        {isDistributionRejected && (
                          <React.Fragment>
                            <div className="SSUnit__sLabel rejected">
                              {uncheckIcon}
                              Rejected
                            </div>
                            <QButton
                              disabled={currentUser && currentUser.partnerPermissionLevel == 'view'}
                              onClick={handleFindPartner}
                              className="SSUnit__btn"
                              type="primary"
                              block
                              data-html2canvas-ignore
                            >
                              Find a partner{arrowIcon}
                            </QButton>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                    {CRMedia.length > 0 && (
                      <div className={`SSUnit__descriptions`}>
                        <Descriptions column={1} title="Consumer Research">
                          {CRMedia.map((service: ICartServiceItem) => renderServiceItem(service, item.priceModel))}
                        </Descriptions>
                      </div>
                    )}
                    {media.length > 0 && (
                      <div className={`SSUnit__descriptions ${isMediaBuyRejected && 'rejected'}`}>
                        <Descriptions column={1} title="Media Buy">
                          {media.map((service: ICartServiceItem) => renderServiceItem(service, item.priceModel))}
                        </Descriptions>
                        {isMediaBuyAccepted && (
                          <div className="SSUnit__sLabel accepted">
                            <IconsCommon.IconCheck className="i" color={COLORS.LIGHT_GREEN} />
                            Accepted
                          </div>
                        )}
                        {isMediaBuyRejected && (
                          <React.Fragment>
                            <div className="SSUnit__sLabel rejected">
                              {uncheckIcon}
                              Rejected
                            </div>
                            <QButton
                              disabled={currentUser && currentUser.partnerPermissionLevel == 'view'}
                              onClick={handleFindPartner}
                              className="SSUnit__btn"
                              type="primary"
                              block
                              data-html2canvas-ignore
                            >
                              Find a partner{arrowIcon}
                            </QButton>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
      </div>
      {briefView?.briefTypes &&
      briefView?.briefTypes.length === 1 &&
      briefView?.briefTypes?.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
        ? ''
        : (window.location.pathname.includes('view') ? briefView?.printingPrice : printingPrice) &&
          sampleAllocated > 0 && (
            <div className="selection-summary__cont additional_charges">
              <QBriefCartProduct
                productName={'Additional Charges'}
                onEdit={() => setCurrentStep(BRIEF_STEPS.SERVICES)}
                isAdditional={true}
              />
              {window.location.pathname.includes('view')
                ? briefView?.printingPrice &&
                  renderCartAdditionalChargesItem(
                    briefView?.printingPrice,
                    'Printing Price',
                    cart.additional.printingTotal,
                    cart.additional.printingMinQuantity
                  )
                : printingPrice &&
                  renderCartAdditionalChargesItem(
                    printingPrice,
                    'Printing Price',
                    cart.additional.printingTotal,
                    cart.additional.printingMinQuantity
                  )}
            </div>
          )}
      {briefView?.briefTypes &&
      briefView?.briefTypes.length === 1 &&
      briefView?.briefTypes?.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX) ? (
        ''
      ) : (
        <div className="SS__total">
          {!isPartner && !!cart.couponCode && (
            <div className="qu-discount-badge mb-15">
              {checkIcon}
              {cart?.additional?.discountText}
            </div>
          )}
          {!isPartner && renderAdditional(cart.additional.servicesTotal, 'Services Total')}
          {!isPartner && !!cart.couponCode && renderAdditional(cart.additional.couponFee, 'Discount')}
          {!isPartner && renderAdditional(cart.additional.managementFee, `Management Fee (${settings.managementFee}%)`)}
          {!isPartner && renderAdditional(cart.additional.subTotal, 'Subtotal')}
          {renderAdditional(taxes, 'Taxes (15%)')}
          {renderAdditional(cartTotal, 'Total', true)}
        </div>
      )}
    </div>
  )

  return isMobile ? (
    <>
      <div className="qu-view-card m-10">{content}</div>
      {!(hideProposalButtons || isPartner) && proposalActions}
    </>
  ) : (
    <React.Fragment>
      <ServicesSummary title="Services Summary">{content}</ServicesSummary>
      {!(hideProposalButtons || isPartner) && proposalActions}
    </React.Fragment>
  )
}
