import React, { ReactElement, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Col, Form, QButton, QDatePicker, QInput, QSelect, QTextArea, Row } from 'quantum_components'
import { IBrief, IConsumerResearchStep, IOptionForSelect } from '../../testahelBox.types'
import { useSelector } from 'react-redux'
import { getBriefSettings } from '../../testahelBox.selectors'
import { FORMATS, LOCAL_STORAGE_KEYS } from '../../../../../constants'
import { isMobile } from 'react-device-detect'
import { ReactComponent as LogOut } from '../../../../../assets/icons/logout-icon-alt.svg'
import { BRIEF_STEPS, BRIEF_STEPS_NAMES } from '../../../../../constants/testahel_brief'
import TestahelPopoverSummary from '../components/TestahelBrief.PopoverSummary'
import moment from 'moment'
import { afterDays, disabledDatesForFourteenDays } from '../../../../../helpers/datesHelper'
import VALIDATE_STATUSES from 'src/constants/validateStatuses'
import { TestahelAgeGroupCard } from '../components/TestahelBrief.AgeGroupCard'
import imageSurvey from '../../../../../assets/survey.jpg'
import imageChallenge from '../../../../../assets/challenge.jpg'
import { VALIDATION_MESSAGE } from 'src/constants/validationMessages'

interface IProps {
  brief: IBrief
  mediaBuyFields: IConsumerResearchStep
  onChange: (mediaBuyFields: IConsumerResearchStep) => void
  onFinishLater: () => void
  disabledFinishLater: boolean
}

export default function TestahelConsumerResearchForm({
  brief,
  mediaBuyFields,
  onChange,
  onFinishLater,
  disabledFinishLater,
}: IProps): ReactElement {
  // const settings = useSelector(getBriefSettings)
  const [errorMedia, setMediaErrors] = useState<string>('')
  const [mediaStartDateIsDifferent, setMediaStartDateIsDifferent] = useState<boolean>(false)
  // const [selectAllTypes, setSelectAllTypes] = useState<boolean>(false)
  const [form] = Form.useForm()

  const [dateError, setDateError] = useState<string>('')
  // const consumerResearchMediaType = []

  useEffect(() => {
    form.setFieldsValue(mediaBuyFields)
  })

  const briefStartDate = afterDays(30)

  const setMediaStartDate = () => {
    const fields = { ...mediaBuyFields }
    fields.consumerResearchStartDate = moment(briefStartDate)
    onChange(fields)
  }

  useEffect(() => {
    if (!mediaBuyFields.consumerResearchStartDate) {
      setMediaStartDate()
      return
    }

    const isBriefStartDate =
      moment(mediaBuyFields.consumerResearchStartDate, FORMATS.DATE_FORMAT).format(FORMATS.DATE_FORMAT) ===
      moment(briefStartDate, FORMATS.DATE_FORMAT).format(FORMATS.DATE_FORMAT)

    setMediaStartDateIsDifferent(!isBriefStartDate)
  }, [])

  useEffect(() => {
    form.validateFields(['consumerResearchStartDate'])
  }, [mediaStartDateIsDifferent])

  // useEffect(() => {
  //     if (settings.consumerResearchMediaType.length === 0) {
  //         return
  //     }

  //     const isSelectedAll = mediaBuyFields.consumerResearchMediaType.length === settings.consumerResearchMediaType.length
  //     setSelectAllTypes(isSelectedAll)
  // }, [mediaBuyFields.consumerResearchMediaType.length])

  const validateMediaType = (items: string[]) => {
    const msg = items.length > 0 ? '' : 'Should be at least one media type.'
    setMediaErrors(msg)
  }

  // const handleMediaType = (e: MouseEvent, name: string) => {
  //   const fields = { ...mediaBuyFields }
  //   const items = fields.consumerResearchMediaType
  //   !items.includes(name) &&
  //     ReactGA.event({
  //       category: `Create/Edit Brief ${
  //         localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
  //         sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
  //           ? '- Invited Client'
  //           : ''
  //       } - Media/Ad Type(s)`,
  //       action: name,
  //       label: name,
  //       value: 1,
  //     })
  //   items.includes(name) ? items.splice(items.indexOf(name), 1) : items.push(name)
  //   validateMediaType(items)

  //   onChange(fields)
  // }

  // const resetMediaDate = (isDifferent: boolean) => {
  //   const fields = { ...mediaBuyFields }
  //   if (isDifferent) {
  //     form.setFieldsValue({ consumerResearchStartDate: null })
  //     fields.consumerResearchStartDate = null
  //   } else {
  //     fields.consumerResearchStartDate = moment(briefStartDate)
  //   }

  //   onChange(fields)
  // }

  // const handleChangeRadio = (e: any) => {
  //   const value = e.target.value
  //   setMediaStartDateIsDifferent(value)
  //   // resetMediaDate(value)
  // }

  // const handleSelectChange = (value: string, option: IOptionForSelect) => {
  //   if (!option) {
  //     return
  //   }

  //   const fields = { ...mediaBuyFields }
  //   // @ts-ignore
  //   fields[option.id] = value
  //   ReactGA.event({
  //     category: `Create/Edit Brief ${
  //       localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
  //       sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
  //         ? '- Invited Client'
  //         : ''
  //     } - How long should the media run?`,
  //     action: value,
  //     label: value,
  //     value: 1,
  //   })
  //   onChange(fields)
  // }

  // const handleSelectAll = (e: any) => {
  //     const fields = { ...mediaBuyFields }
  //     const isChecked = e.target.checked
  //     setSelectAllTypes(isChecked)
  //     fields.consumerResearchMediaType = isChecked ? [...settings.consumerResearchMediaType] : []

  //     fields.consumerResearchMediaType.map(ele => {
  //         ReactGA.event({
  //             category: `Create/Edit Brief ${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
  //                 sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
  //                 ? '- Invited Client'
  //                 : ''
  //                 } - Media/Ad Type(s)`,
  //             action: ele,
  //             label: ele,
  //             value: 1,
  //         })
  //     })

  //     onChange(fields)
  // }

  // const consumerResearchDuration: IOptionForSelect[] = settings.mediaDurations.map((type: string) => {
  //   return { id: 'consumerResearchDuration', label: type, value: type }
  // })

  // const renderMediaStartDate = moment(briefStartDate, FORMATS.DATE_LL_FORMAT).format(FORMATS.DATE_LL_FORMAT)

  const handleDatePicker = (date: moment.Moment) => {
    const fields = { ...mediaBuyFields }
    fields.consumerResearchStartDate = date
    validateDate(date)
    onChange(fields)
  }

  const validateDate = (value: string | moment.Moment | null) => {
    if (!value) {
      setDateError('Please choose date.')
      return
    }
    setDateError('')
  }

  const handleAgeGroup = (name: string) => {
    const fields = { ...mediaBuyFields }
    const items = fields.consumerResearchMediaType
    if (items.includes(name)) {
      items.splice(items.indexOf(name), 1)
    } else {
      items.push(name)
    }
    validateMediaType(items)
    onChange(fields)
  }

  const onChangeConsumerResearchObjective = (name: string) => {
    const fields = { ...mediaBuyFields }
    fields.consumerResearchObjective = name ? name : null
    onChange(fields)
  }

  const onChangeConsumerResearchNote = (name: string) => {
    const fields = { ...mediaBuyFields }
    fields.consumerResearchNotes = name
    onChange(fields)
  }

  return (
    <Form form={form} layout="vertical" hideRequiredMark initialValues={mediaBuyFields}>
      <div className={`form form-brief form--horizontal`}>
        <div className="form__head">
          <div className="form__number">
            {isMobile ? <span>{`4. ${BRIEF_STEPS_NAMES[BRIEF_STEPS.MEDIA_BUY]}`}</span> : '4'}
          </div>
          <h3>Tell us about the consumer research</h3>
        </div>
        <TestahelPopoverSummary brief={brief} />
        <div className="form__cont">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="mediaStartDateIsDifferent" className="form-label mt-10">
                * What is your desired start date?
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="consumerResearchStartDate"
                validateStatus={dateError ? VALIDATE_STATUSES.ERROR : VALIDATE_STATUSES.SUCCESS}
                help={dateError ? dateError : 'This is the date which you would like to start this campaign'}
              >
                <QDatePicker
                  value={mediaBuyFields.consumerResearchStartDate}
                  format={FORMATS.DATE_FORMAT}
                  onChange={handleDatePicker}
                  disabledDate={disabledDatesForFourteenDays}
                  onBlur={() => validateDate(mediaBuyFields.consumerResearchStartDate)}
                  className="full-width"
                  size="large"
                  placeholder="Select Date"
                />
              </Form.Item>
            </Col>
          </Row>

          <hr className="form__hr" />

          {/* <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="consumerResearchDuration" className="form-label">
                * How long?
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="consumerResearchDuration"
                rules={[
                  {
                    required: true,
                    message: 'Please choose consumer research duration.',
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <QSelect
                  value={mediaBuyFields.consumerResearchDuration}
                  onChange={handleSelectChange}
                  showSearch
                  size="large"
                  placeholder="Select Duration"
                  options={consumerResearchDuration}
                />
              </Form.Item>
            </Col>
          </Row>

          <hr className="form__hr" /> */}

          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="targetFocus" className="form-label">
                * Media Type
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Row gutter={16} align="middle">
                <TestahelAgeGroupCard
                  classname="consumer_research_survey"
                  key={1}
                  checked={mediaBuyFields.consumerResearchMediaType.includes('CR Survey')}
                  label={'Survey'}
                  sublabel={''}
                  img={imageSurvey}
                  onChange={(e: any) => handleAgeGroup('CR Survey')}
                />
                <TestahelAgeGroupCard
                  classname="consumer_research_challenge"
                  key={2}
                  checked={mediaBuyFields.consumerResearchMediaType.includes('CR Challenge')}
                  label={'Challenge'}
                  sublabel={''}
                  img={imageChallenge}
                  onChange={(e: any) => handleAgeGroup('CR Challenge')}
                />
                {errorMedia && <div className="text-red font-size-12">{errorMedia}</div>}
              </Row>
            </Col>
          </Row>

          <hr className="form__hr" />

          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="name" className="form-label mt-10">
                * Objective
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="consumerResearchObjective"
                rules={[
                  {
                    required: true,
                    message: 'Please type the Objective.',
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <QInput
                  onChange={(e: any) => onChangeConsumerResearchObjective(e.target.value)}
                  value={mediaBuyFields.consumerResearchObjective}
                  placeholder="Enter Objective"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>

          <hr className="form__hr" />

          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="consumerResearchNotes" className="form-label">
                Additional notes
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="consumerResearchNotes"
                rules={[
                  {
                    max: 500,
                    message: VALIDATION_MESSAGE.ADDITIONAL_INSTRUCTION,
                  },
                ]}
              >
                <QTextArea
                  onChange={(e: any) => onChangeConsumerResearchNote(e.target.value)}
                  value={mediaBuyFields.consumerResearchNotes}
                  rows={3}
                  cols={3}
                  placeholder="Let us know if you have any additional instructions for challenges or surveys."
                  className="form-textarea"
                />
              </Form.Item>
            </Col>
          </Row>

          {isMobile && (
            <div className="form-brief-footer">
              <QButton
                disabled={disabledFinishLater}
                onClick={onFinishLater}
                className="qu-button-outline mt-30 full-width"
              >
                Save & Finish Later <LogOut className="ml-10" />
              </QButton>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}
