import React, { ReactElement } from 'react'
import { QBriefHeader } from 'quantum_components'
import { PATHS } from '../../../../constants'
import { useHistory } from 'react-router-dom'
import BriefPartnerFilter from './Brief.PartnerFilter'
import { isMobile } from 'react-device-detect'
import { BRIEF_FORM_HEADER_TITLE } from '../../../../constants/brief'

export interface IProps {
  title: BRIEF_FORM_HEADER_TITLE
  stepper: React.ReactNode
  showPartnerFilter?: boolean
  isRejectionFlow?: boolean
  onClickCancel: any
}
export default function BriefHeader({ title, stepper, showPartnerFilter, onClickCancel }: IProps): ReactElement {
  const history = useHistory()

  return (
    <QBriefHeader
      onClickCancel={onClickCancel}
      title={title}
      children={<div className="header-stepper">{stepper}</div>}
      childrenFilter={null}
    />
  )
}
