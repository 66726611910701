import { Row, Col } from 'antd'
import { IBriefResponse } from '../../brief.types'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getFirstProductImage } from '../../brief.selectors'
import { first } from 'lodash'
import { MEDIA_GROUP_TYPE, PROMOTION_TYPE } from '../../../../constants/brief'
import { useTranslation } from 'react-i18next'
import lodash from 'lodash'
import { FORMATS, LOCALIZATION_LANGUAGES } from 'src/constants'
import i18n from 'src/i18n'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
  requestType?: MEDIA_GROUP_TYPE
  partnerQuantity?: number
}

const BriefProduct = ({ briefView, onEdit, viewMode, requestType, partnerQuantity }: Props) => {
  const { t } = useTranslation(['translation'])

  const firstImage = useSelector(getFirstProductImage)
  const productImage = briefView.productImages ? first(briefView.productImages) : firstImage
  const isRequestDistribution = requestType && (requestType === MEDIA_GROUP_TYPE.PRINTED_INSERT || requestType === MEDIA_GROUP_TYPE.PRODUCT_SAMPLE)

  return (
    <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>{t('brief.summary.Campaign_brief')}</h4>
        {!viewMode && <span onClick={onEdit}>{t('brief.summary.Edit')}</span>}
      </div>

      <div className="product-brief">
        <div className="qu-card-item-image mb-25">
          <Row>
            <div className="image">{productImage && <img src={productImage} alt="product" />}</div>
            <div className={`description ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && 'mr-10'}`}>
              <h5>
                {
                  briefView?.campaignName?.length > 20
                    ? (i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                      ? '...' + briefView.campaignName.substring(0, 20)
                      : briefView.campaignName.substring(0, 20) + '...')
                    : briefView.campaignName
                }
              </h5>
              <p>{`${t(`brief.edit.step2.${String(briefView.campaignPromotionType).toLowerCase()}`)}`}</p>
            </div>
          </Row>
        </div>

        {(!requestType || isRequestDistribution) && (
          <Row gutter={16}>
            {briefView.campaignPromotionType == PROMOTION_TYPE.BRAND && <Col xs={24} sm={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">{t('brief.edit.step2.product_name')}</div>
                <div className="data-view-value">{briefView.name || t('common.none', { ns: 'translationCommon' })}</div>
              </div>
            </Col>}

            {briefView.campaignPromotionType == PROMOTION_TYPE.BRAND && <Col xs={24} sm={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">{t('brief.edit.step2.product_description')}</div>
                <div className="data-view-value">{briefView.productDescription || t('common.none', { ns: 'translationCommon' })}</div>
              </div>
            </Col>}

            <Col xs={24} sm={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">{t('brief.summary.Marketing_Objective')}</div>
                <div className="data-view-value">{briefView.marketingObjective && t('brief.media-buy.marketing-objective.' + lodash.snakeCase(briefView.marketingObjective)) || t('common.none', { ns: 'translationCommon' })}</div>
              </div>
            </Col>

            <Col xs={24} sm={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">{t('brief.summary.Desired_Start_Date')}</div>
                <div className="data-view-value">{briefView.mediaStartDate ? moment(briefView.mediaStartDate).format(FORMATS.DATE_LL_FORMAT) : t('common.none', { ns: 'translationCommon' })}</div>
              </div>
            </Col>

            <Col span={24}>
              <div className="data-view mb-15">
                <div className="data-view-label">{t('brief.summary.Instructions')}</div>
                <div className="data-view-value">{briefView.instructions || t('common.none', { ns: 'translationCommon' })}</div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default BriefProduct
