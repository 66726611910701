import React, { ReactElement, useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { getLocaleCost, renderRoundedNumber } from 'src/helpers/currency'
import { isMobile } from 'react-device-detect'
import * as actions from '../campaign.actions'
import {
  CampaignsCardItem,
  Col,
  Dropdown,
  IconsCommon,
  Menu,
  QInput,
  QMenuItem,
  QPagination,
  QSelect,
  QTable,
  Row,
} from 'quantum_components'
import { useSelector } from 'react-redux'
import {
  getCampaignList,
  getCampaignListLoading,
  getCampaignParams,
  getCampaignStats,
  getPages,
  getTotalCampaignList,
} from '../campaign.selectors'
import useDebounce from 'src/hooks/useDebounce'
import { ICampaignTable, ICampaignTableProduct, ICampaignTableService } from '../campaign.types'
import { CAMPAIGN_STATUS, campaignStatusesLabel, statusFilterOptions } from '../../../constants/campaign'
import { DISTRIBUTION_TYPE } from '../../../constants/brief'
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg'
import { getCurrency, getCurrentUserRole } from '../../core/core.selectors'
import ROLES from '../../../constants/roles'

export default function PartnerCampaignsContainer(): ReactElement {
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const campaignList = useSelector(getCampaignList)
  const campaignListLoading = useSelector(getCampaignListLoading)
  const campaignStats = useSelector(getCampaignStats)
  const params = useSelector(getCampaignParams)
  const totalCampaignList = useSelector(getTotalCampaignList)
  const pages = useSelector(getPages)
  const role = useSelector(getCurrentUserRole)
  const currency = useSelector(getCurrency)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const tableLoading = {
    spinning: campaignListLoading,
    indicator: <div className="loader loader-small" />,
  }

  const colKey = {
    revenue: 'earnings',
    status: 'status',
  }

  const menu = (id: number) => (
    <Menu className="user-menu-list">
      <QMenuItem key={0} onClick={() => handleView(id)} name="View" icon={<EyeIcon />} />
    </Menu>
  )

  useEffect(() => {
    if (role === ROLES.UNKNOWN) {
      return
    }

    dispatch(actions.setParamsAndFetch(role, { ...params, search: debouncedSearch, offset: 0, limit: 10 }))
    dispatch(actions.setCurrentPage(1))
  }, [debouncedSearch, role])

  const handleChangeSearch = (e: any) => {
    setSearch(e.currentTarget.value)
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setCurrentPage(page))
    await dispatch(actions.setParamsAndFetch(role, { ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleChangeSelect = async (status: string) => {
    await dispatch(actions.setParamsAndFetch(role, { ...params, offset: 0, status: status }))
  }

  const handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      //@ts-ignore
      sort: colKey[sorter.columnKey],
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    await dispatch(actions.setParamsAndFetch(role, newParams))
  }

  const handleView = (campaignId: number) => {
    history.push(`/campaigns/${campaignId}`)
  }

  const isDistribution = (service: string) =>
    service === DISTRIBUTION_TYPE.PRODUCT_SAMPLE || service === DISTRIBUTION_TYPE.PRINTED_INSERT

  const isMediaBuy = (service: string) =>
    service !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE && service !== DISTRIBUTION_TYPE.PRINTED_INSERT

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      width: '30%',
      fixed: 'left',
      render: (product: ICampaignTableProduct) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.image && <img src={product.image} alt={product.title} />}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.title}</h3>
              <div className="q-table-product_created">Started {product.startDate}</div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Services',
      dataIndex: 'service',
      key: 'service',
      width: '20%',
      render: (service: ICampaignTableService) => {
        const distributions = service.types.filter(isDistribution)
        const mediaBuys = service.types.filter(isMediaBuy)

        return (
          <ul className="q-table-list">
            {distributions.length > 0 && (
              <li className="q-table-list_item font-weight-500">
                <span className="mr-10">
                  <IconsCommon.IconSample />
                </span>
                Distribution
              </li>
            )}
            {mediaBuys.length > 0 && (
              <li className="q-table-list_item font-weight-500">
                <span className="mr-10">
                  <IconsCommon.IconDevices />
                </span>
                Media Buy
              </li>
            )}
          </ul>
        )
      },
    },
    {
      title: 'Start & Duration',
      dataIndex: 'service',
      key: 'service',
      width: '20%',
      render: (service: ICampaignTableService) => {
        const distributions = service.types.filter(isDistribution)
        const mediaBuys = service.types.filter(isMediaBuy)

        return (
          <React.Fragment>
            {distributions.length > 0 && (
              <div>
                <div>{service.productStartDate}</div>
                <div>{service.productDuration}</div>
              </div>
            )}

            {mediaBuys.length > 0 && (
              <div>
                <div>{service.mediaStartDate}</div>
                <div>{service.mediaDuration}</div>
              </div>
            )}
          </React.Fragment>
        )
      },
    },
    {
      title: 'Earnings',
      dataIndex: 'service',
      key: 'revenue',
      width: '15%',
      sorter: true,
      render: (service: ICampaignTableService) => (
        <span className="font-size-14 font-weight-600">{service.revenue}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      sorter: true,
      render: (status: CAMPAIGN_STATUS, record: ICampaignTable) => (
        <div>
          <span className={`status-label ${campaignStatusesLabel[status] && campaignStatusesLabel[status].classStyle}`}>
            {campaignStatusesLabel[status] ? campaignStatusesLabel[status].label : ''}
          </span>
          {/* QUA-1449, QP-492 - prevent event bubbling to onRowClick */}
          <div onClick={e => e.stopPropagation()}>
            <Dropdown overlay={menu(record.key)} trigger={['click']} placement="bottomRight">
              <div className="q-table_menu">
                <IconsCommon.IconEllipsisV />
              </div>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ]

  const handleOnRowClick = (campaign: ICampaignTable) => ({
    onClick: (event: any) => history.push(`/campaigns/${campaign.key}`),
  })

  return (
    <div className="qt dashboard-layout">
      <div className="qu-statistic-wrapper">
        <h1>Campaigns</h1>
        <ul className="qu-statistic-list qu-statistic-dubble">
          <li className="qu-statistic-item">
            <span className="qu-statistic-value">{renderRoundedNumber(campaignStats.upcoming, currency)}</span>
            <span className="qu-statistic-name">Upcoming Earnings</span>
          </li>
          <li className="qu-statistic-item">
            <span className="qu-statistic-value">{renderRoundedNumber(campaignStats.lifetime, currency)}</span>
            <span className="qu-statistic-name">Lifetime Earnings</span>
          </li>
        </ul>
      </div>
      <Row className="qt-header qt-header-campaigns" justify="space-between">
        <Col className="qt-search">
          <QInput
            className={`${isMobile && 'full-width'}`}
            value={search}
            onChange={handleChangeSearch}
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col className="qt-filter">
          <QSelect
            onChange={handleChangeSelect}
            size="large"
            options={statusFilterOptions}
            placeholder="Filter by status"
          />
        </Col>
      </Row>
      {isMobile ? (
        <ul className="qu-request-list">
          {campaignList.map((item: ICampaignTable) => {
            return <CampaignsCardItem key={item.key} data={item} onClick={() => handleView(item.key)} />
          })}
        </ul>
      ) : (
        <div className="qt-body">
          <QTable
            //@ts-ignore
            onRow={handleOnRowClick}
            //@ts-ignore
            loading={tableLoading}
            columns={columns}
            dataSource={campaignList}
            rowClassName="q-table"
            className="cursorPointer"
            sortDirections={['ascend', 'descend', 'ascend']}
            onChange={handleTableChange}
          />
        </div>
      )}
      <div className="qt-footer">
        <QPagination
          size="small"
          defaultCurrent={1}
          current={pages.currentPage}
          total={totalCampaignList}
          onChange={handleChangePage}
        />
      </div>
    </div>
  )
}
