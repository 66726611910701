import { ICampaign, ICampaignIMediaBuyTypeShort, ICampaignTable, IChartData } from '../modules/campaign/campaign.types'
import moment from 'moment'
import { FORMATS } from '../constants'
import { IMediaBuyTypesElement } from '../modules/partner/partnerProfile/profile.types'
import { CURRENCY } from '../constants/currencies'
import { renderRoundedNumber } from './currency'

export const campaignsToTable = (campaigns: ICampaign[], currency: CURRENCY): ICampaignTable[] => {
  if (!campaigns) {
    return []
  }

  return campaigns.map((campaign: ICampaign) => {
    return {
      key: campaign.id,
      product: {
        title: campaign.productName,
        image: campaign.productImageUrls ? campaign.productImageUrls[0] : null,
        startDate: campaign.startedDate ? moment(campaign.startedDate).format(FORMATS.DATE_LL_FORMAT) : '-',
        isExistCouponCode: campaign.isExistCouponCode,
        discountText: campaign.discountText,
      },
      service: {
        types: campaign.types,
        productStartDate: campaign.productStartDate
          ? moment(campaign.productStartDate).format(FORMATS.DATE_LL_FORMAT)
          : '-',
        mediaStartDate: campaign.mediaStartDate ? moment(campaign.mediaStartDate).format(FORMATS.DATE_LL_FORMAT) : '-',
        productDuration: campaign.productDuration,
        mediaDuration: campaign.mediaDuration,
        quantity: campaign.quantity,
        revenue: renderRoundedNumber(campaign.revenue, currency),
      },
      status: campaign.status,
      version: campaign.version
    } as ICampaignTable
  })
}

export const hasChartData = (data: IChartData[]) => {
  return !data.every((item: IChartData) => !item.value)
}

//'Growth Rate: ((After ad Revenue -Before ad Revenue )/Before ad Revenue )*100',
//'CTR (Click Through Rate):# of clicks/ # of impressions',
//'CR (Conversion Rate): # of orders/# of clicks',
//'Engagement Rate: SM Total interactions/SM Followers',

export const calculateGrowthRate = (beforeRevenue: number, afterRevenue: number): number => {
  if (!beforeRevenue || !afterRevenue) return 0
  const value = Math.round(((afterRevenue - beforeRevenue) / beforeRevenue) * 100)
  return value < 0 ? 0 : value
}

export const calculateRate = (a: number, b: number) => {
  if (!a || !b) return 0
  const value = Math.round((a / b) * 100)
  return value < 0 ? 0 : value
}

export const calculateRateDateRange = (a: any, b: any) => {
  if (!a || !b) return 0
  if (typeof a === 'object' && typeof b === 'object') {
    const valueA = Object.values(a).reduce((accumulator: any, value: any) => {
      return accumulator + value;
    }, 0)

    const valueB = Object.values(b).reduce((accumulator: any, value: any) => {
      return accumulator + value;
    }, 0)

    // Math.round
    const value = +Number((Number(valueA) / Number(valueB)) * 100).toFixed(1)
    return value < 0 ? 0 : value
  } else {
    const value = +Number((a / b) * 100).toFixed(1)
    return value < 0 ? 0 : value
  }
}

export const subtypeNamesByPartner = (mediaSubTypes: ICampaignIMediaBuyTypeShort[]) =>
  mediaSubTypes.map((subtype: ICampaignIMediaBuyTypeShort) => (subtype.subName ? subtype.subName : subtype.name))
