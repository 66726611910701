import { createSelector } from 'reselect'
import { IState } from './announcement.types'
import { MODULE_NAME } from './announcement.constants'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getDataLoading = createSelector(selectState, (state: IState): boolean => state.loading)

export const getAllAnnouncements = createSelector(selectState, (state: IState): any => state.announcements)

export const getAllAnnouncementsTotal = createSelector(selectState, (state: IState): any => state.totalPromotions)

export const getAnnouncementLoading = createSelector(selectState, (state: IState): boolean => state.announcementLoading)

export const getAnnouncementDetails = createSelector(selectState, (state: IState): any => state.announcementDetails)

export const getPages = createSelector(selectState, (state: IState): {
    totalPages: number, currentPage: number
} => state.pages)