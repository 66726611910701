import { IState } from './deploymentDetails.types'
import * as CONSTANTS from './deploymentDetails.constants'
import IAction from 'src/interfaces/IAction'

const initialState: IState = {
    saasUser: {},
    serverError: null,
    isLoading: false,
}

export default (state: IState = initialState, action: IAction): IState => {
    switch (action.type) {
        case CONSTANTS.SAAS_REQUEST:
            return { ...state, isLoading: true, saasUser: {} }
        case CONSTANTS.SAAS_SUCCESS:
            return { ...state, isLoading: false, saasUser: action.payload }
        case CONSTANTS.SAAS_FAILURE:
            return { ...state, isLoading: false, serverError: action.error }
        default:
            return state
    }
}
