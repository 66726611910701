import IAction from 'src/interfaces/IAction'
import * as CONSTANTS from './partnerAPI.constants'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { requestHttp, urls } from 'src/api'
import { IPartnerAPI, IPartnerAPITableParams } from './partnerAPI.types'

export const getPartnerAPISuccess = (partnerAPIData: IPartnerAPI[], total: number, isValid: boolean): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_API_LIST_SUCCESS,
  payload: { partnerAPIData, total, isValid },
})

export const generatePartnerAPIToken = (): ThunkAction<void, {}, {}, AnyAction> => async (): Promise<number> => {
  try {
    const response = await requestHttp.post(urls.generatePartnerAPITokenUrl())
    return response.data.status
  } catch (error: any) {
    return error
  }
}

export const getPartnerAPI =
  (params: IPartnerAPITableParams): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IPartnerAPI[]> => {
    try {
      const response = await requestHttp.get(urls.partnerAPIListingUrl(), { params })
      dispatch(
        getPartnerAPISuccess(
          response.data.data.content.results,
          response.data.data.content.total,
          response.data.data.content.isValid
        )
      )
      return response.data.data.content
    } catch (error: any) {
      return error
    }
  }

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const setParamsAndFetch =
  (params: IPartnerAPITableParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(setRequestListParams(params))
    await dispatch(getPartnerAPI(params))
  }

export const setRequestListParams = (params: IPartnerAPITableParams): IAction => ({
  type: CONSTANTS.SET_REQUEST_LIST_PARAMS,
  payload: { params },
})
