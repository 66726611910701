import IStatusedMessage from 'src/interfaces/IStatusedMessage'
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_SORT_TYPE,
  BRIEF_STATUS,
  BRIEF_STEPS,
  BRIEF_TYPES,
  DIALOG_MODALS,
  DISTRIBUTION_TYPE,
  GENDER,
  MEDIA_TYPES,
  MEDIA_TYPES_DURATION,
  BRIEF_PAYMENT_SELECTED_TYPES,
} from '../../constants/brief'
import moment from 'moment'
import { PARTNER_LABEL, SOCIAL_NAMES, SOCIAL_TYPES } from '../../constants/partner'
import { CURRENCY } from '../../constants/currencies'
import { REQUEST_STATUS } from '../../constants/request'
import { IMediaBuyTypesElement } from '../partner/partnerProfile/profile.types'
import { IBillListElementPaymentDto, IBillPaymentUploadTypes } from '../client/bills/bills.types'

export interface IState {
  createBriefError: string
  isSaving: boolean
  brief: IBrief
  currentStep: BRIEF_STEPS
  copackingPrice: IPackagingPrintingPrice | null
  printingPrice: IPackagingPrintingPrice | null
  needCheckLastStep: boolean
  fetchingBriefListError: string
  briefListLoading: boolean
  briefList: IBriefList
  params: IBriefTableParams
  pages: {
    totalPages: number
    currentPage: number
  }
  fetchSettingsError: string
  settingsLoading: boolean
  settings: IBriefSettings
  viewBrief: IBriefResponse
  viewBriefPaymentFilesState: BriefPaymentFilesStateType
  imagesUploading: boolean
  imagesUploadingError: string
  imagesRemoving: boolean
  imagesRemovingError: string
  partnerFilterSettingsError: string
  partnerFilterSettingsLoading: boolean
  partnerFilterSettings: IPartnerFilterSettings
  partnerFilter: IPartnerFilter
  serviceImages: string
  productImages: string
  fetchRecommendedPartnersLoading: boolean
  recommendedPartners: IBriefRecommendedPartner[]
  fetchPartnerDetailsLoading: boolean
  partnerDetails: IBriefPartnerDetails | null
  fetchCartLoading: boolean
  updateCartLoading: boolean
  cart: ICartList
  activeSinglePartnerId: number | null
  totalQuantity: number
  uploadSettings: IUploadSettings[]
  uploadSettingsLoading: boolean
  uploadStepLoading: string[]
  rejectionFlow: boolean
  dialogModal: DIALOG_MODALS | null
  sendProposalLoading: boolean
  exclusivePartnerId: number | null
  potentialAudienceReach: IPotentialAudienceReach
  paymentSelectedType: BRIEF_PAYMENT_SELECTED_TYPES
}

export type BriefPaymentFilesStateType = {
  [key in IBillPaymentUploadTypes]: {
    isUploading: boolean
    uploadingError: string
    isRemoving: boolean
    removingError: string
  }
}

export interface ICreateBriefResponse extends IStatusedMessage {
  data: {
    content: {
      briefId: number
    }
  }
  message: string | null
}

export interface IUpdateBriefResponse extends IStatusedMessage {
  data: {
    content: IBriefResponse
  }
  message: string | null
}

export interface IBriefResponse {
  id: number
  promotionId: number
  lastFilledStep: BRIEF_STEPS
  status: BRIEF_STATUS
  isTestahel?: boolean
  //BRIEF TYPE
  briefTypes: BRIEF_TYPES[]
  //PRODUCT BRIEF
  campaignName: string
  campaignPromotionType: string
  productDescription: string
  name: string
  quantity: number
  distributionItem: DISTRIBUTION_TYPE
  productType: string
  briefStartDate: string
  duration: string
  instructions?: string
  productImages: string[]

  //TARGETING
  targetFocus: GENDER[]
  targetAgeGroups: string[]
  targetSegments: string[]
  targetCountries: string[]
  targetCities: string[]
  customTargets: {}

  //SERVICES
  servicePackageType?: string[]
  serviceIsLiquid?: boolean
  serviceIncludingCoupon?: boolean
  serviceDesign?: boolean
  servicePrinting?: boolean
  printingPriceId?: number
  servicePackaging?: boolean
  copackingPriceId?: number
  servicePackagingQuantity?: number
  serviceImages: string[]
  hasFreeSample: boolean
  serviceTemperature: string
  copackingPrice: IPackagingPrintingPrice
  printingPrice: IPackagingPrintingPrice

  //MEDIA
  mediaStartDate?: string
  mediaDuration?: string
  mediaTypes?: string[]
  marketingObjective?: string
  budgetType?: string
  budgetValue?: number | null
  budgetCurrency?: string

  uploadMaterial: IUploadPartner[]
  payment: IBillListElementPaymentDto
  owner: IOwner

  campaignReportfilesUrls?: any[]

  mediaBuyEstimates: IAdEstimation[]
}

export interface IBankDetails {
  account_currency: string
  account_number: string
  address: string
  bank_address: string
  bank_name: string
  iban_number: string
  swift_code: string
}

export interface IOwner {
  id: number
  firstName: string
  lastName: string
  email: string
  phone: string
  jobTitle: string | null
  role: string
  status: string
  isApproved: boolean
  isBanned: boolean
  createdAt: string
  updatedAt: string
  companyName: string
  companyWebsite: string
  currency: CURRENCY
  isReferralEmailSent: boolean
  parentPartnerId: number | null
  partnerDepartmentId: number | null
  partnerInviteUrl: string | null
  isTemporary: boolean
  poEnabled: boolean
  paymentEnabled: boolean
  zohoId: string
  bankTransfer: boolean
  purchaseOrder: boolean
  onlinePayment: boolean
  clientProfile?: {
    location: string
    vat: string
    nationalAddress: string
  }
}

export interface IProductStep {
  campaignName: string | null
  campaignPromotionType: string | null
  name: string | null
  productImages: string[]
  productDescription: string | null
  mediaStartDate: moment.Moment | string | null
  marketingObjective: string
  instructions: string | null

  quantity: number | null
  distributionItem: DISTRIBUTION_TYPE | null
  productType: string | null
  briefStartDate: moment.Moment | string | null
  duration: string | null
  otherProductType: string | null
}

export const IProductInitState: IProductStep = {
  campaignName: null,
  campaignPromotionType: null,
  name: null,
  productImages: [],
  productDescription: null,
  mediaStartDate: null,
  marketingObjective: '',
  instructions: null,
  quantity: null,
  distributionItem: null,
  productType: null,
  briefStartDate: null,
  duration: null,
  otherProductType: null,
}

export interface ITargetingStep {
  targetFocus: GENDER[]
  targetAgeGroups: string[]
  targetSegments: string[]
  targetCountries: string[]
  targetCities: string[]
  customTargets:{}
}

export const ITargetingInitState: ITargetingStep = {
  targetFocus: [],
  targetAgeGroups: [],
  targetSegments: [],
  targetCountries: [],
  targetCities: [],
  customTargets:{}
}

export interface IServicesStep {
  servicePackageType: string[]
  serviceIsLiquid: boolean
  serviceIncludingCoupon: boolean
  serviceDesign: boolean
  servicePrinting: boolean
  printingPriceId: number | null
  servicePackaging: boolean
  copackingPriceId: number | null
  servicePackagingQuantity: number | null
  serviceImages: string[]
  hasFreeSample: boolean
  serviceTemperature: string | null
}

export const IServiceInitState: IServicesStep = {
  servicePackageType: [],
  serviceIsLiquid: false,
  serviceIncludingCoupon: false,
  serviceDesign: false,
  servicePrinting: false,
  printingPriceId: null,
  servicePackaging: false,
  copackingPriceId: null,
  servicePackagingQuantity: null,
  serviceImages: [],
  hasFreeSample: false,
  serviceTemperature: null,
}

export interface IMediaBuyStep {
  mediaStartDate: moment.Moment | string | null
  mediaDuration: string | null
  mediaTypes: string[]
  marketingObjective: string
  budgetType: string
  budgetValue: number | null
  budgetCurrency: string
}

export interface IPartnerStep {
  quantity: number
  hasFreeSample: boolean
}

export const IMediaBuyInitState: IMediaBuyStep = {
  mediaStartDate: null,
  mediaDuration: null,
  mediaTypes: [],
  marketingObjective: '',
  budgetType: '',
  budgetValue: null,
  budgetCurrency: '',
}

export interface IUploadPartner {
  partnerId: number
  mediaType: string
  mediaSubType: string | null
  imageTargetUrl: {
    english: string | null
    arabic: string | null
  }
  link: string | null
  adTitle: {
    english: string | null
    arabic: string | null
  }
  adDescription: {
    english: string | null
    arabic: string | null
  }
  width: number
  height: number,
  platformTarget: string | null
}

export interface IUploadStep {
  uploadMaterial: IUploadPartner[]
}

export interface IBrief {
  id: number | null
  promotionId: number | null
  paymentEnabled: boolean | null
  isSku: boolean | null
  status: BRIEF_STATUS
  lastFilledStep: BRIEF_STEPS | null
  [BRIEF_STEPS.TYPE]: { briefTypes: BRIEF_TYPES[] }
  [BRIEF_STEPS.PRODUCT]: IProductStep
  // [BRIEF_STEPS.CAMPAIGN]: ICampaignStep
  [BRIEF_STEPS.TARGETING]: ITargetingStep
  [BRIEF_STEPS.SERVICES]: IServicesStep
  [BRIEF_STEPS.MEDIA_BUY]: IMediaBuyStep
  [BRIEF_STEPS.PARTNER]: IPartnerStep
  [BRIEF_STEPS.UPLOAD]: IUploadStep
  [BRIEF_STEPS.SUMMARY]: any
  [BRIEF_STEPS.PAYMENT]: any
}

export interface IBriefListResponse extends IStatusedMessage {
  data: {
    content: {
      briefs: IBriefListElementDto[]
      total: number
    }
  }
  message: string
}

export interface IBriefListElementDto {
  id: number
  clientId:number
  name: string
  campaignName: string
  customizeBoxBriefName: string
  productImages: string[]
  quantity: number
  createdAt?: string
  briefStartDate: string
  duration: string
  mediaStartDate: string
  mediaDuration: string
  status: BRIEF_STATUS
  briefTypes: BRIEF_TYPES[]
  isExistCouponCode: boolean
  isPromotional: boolean
  allImages?: IAllImagesElement[]
  distributionItem: string
  discountText: string | null
  isTestahel: boolean
  customizeBoxProductImages: string[]
  customizeBoxStartDate: string
  customizeBoxDistributionCount: number
  consumerResearchStartDate: string
  payment: { status: BRIEF_PAYMENT_STATUS } | null
  cart?: ICartList | null
}
export interface IAllImagesElement {
  briefId: number
  createdAt: string
  entity: string
  id: number
  src: string
  updatedAt: string
}

export interface IBriefStatusGroup {
  id: string
  label: string
  name: string
  statuses: IBriefStatus[]
}

export interface IBriefStatus {
  id: string
  name: string
  label: string
}

export interface IBriefList {
  results: IBriefListElement[]
  total: number
}
export interface IBriefTableParams {
  offset: number
  limit: number
  direction: 'asc' | 'desc'
  search: string
  status: string[]
  paymentStatus: string[]
  sortField: string
}

export interface IDeleteBriefResponse extends IStatusedMessage {
  message: string
}
export interface IBriefListElement {
  key: number
  thumb: string
  product: {
    title: string
    created: string
    isExistCouponCode: boolean
    isPromotional: boolean
    discountText: string | null
  }
  services: serviceElement[]
  cart?: ICartList | null
  status: { value: BRIEF_STATUS; id: number }
  isTestahel: boolean
  paymentStatus: { value: BRIEF_PAYMENT_STATUS } | null
}

export interface serviceElement {
  title: string
  productStartDate: string
  mediaStartDate: string
  productDuration: string
  mediaDuration: string
  quantity?: number
}

export interface ICity {
  id: number
  name: string
  name_ar: string
  country_id: number
}

export interface ICountry {
  id: number
  name: string
  name_ar: string
  city: ICity[]
}

export interface ITargettingToggle {
  control_name: string
  control_toggle: boolean
}

export interface ICOPACKINGPRINTINGPRICES {
  id: number
  name: string
  minQuantity: number
}

export interface IBriefSettings {
  ageGroups: string[]
  partnerProfileId: number
  targetingToggles: ITargettingToggle[]
  distributionItems: string[]
  productTypes: string[]
  briefDurations: string[]
  targetSegments: string[]
  targetCampaigns: string[]
  targetingKeys: any[]
  publishers: any[]
  availableServices: any[]
  nativeadTemplates: any[]
  packagingTypes: string[]
  mediaDurations: string[]
  mediaTypes: string[]
  countries: ICountry[]
  MINIMUM_DISTRIBUTION_LOGISTIC_COUNT: number
  temperature: string[]
  copackingPrices: ICOPACKINGPRINTINGPRICES[]
  printingPrices: ICOPACKINGPRINTINGPRICES[]
  managementFee: number
  partnerVat: any
}

export interface IBriefSettingsResponse {
  data: { content: IBriefSettings }
  message: string
}

export interface MediaSubtype {
  name: MEDIA_TYPES
  subtype: string
  availableFields: { lable: string; name: string }[]
  description: string
  subNames?: string[]
}

export interface IPartnerFilterSettings {
  categories: string[]
  pricePerUnit: string[]
  matches: string[]
  types: string[]
  sortBy: BRIEF_SORT_TYPE[]
  mediaSubtypes: MediaSubtype[]
}

export interface IPartnerFilterSettingsResponse {
  data: { content: IPartnerFilterSettings }
  message: string
}

export interface IOptionForSelect {
  id?: string
  label: string
  value: string | number
}
export interface IViewBriefDetailsResponse extends IStatusedMessage {
  data: {
    content: any
  }
  message: string
}

export interface IPartnerFilter {
  search: string
  categories: string[]
  pricePerUnit: string[]
  matches: string[]
  types: string[]
  sortBy?: string
}

export interface ISocial {
  name: string
  counter: string
  redirectURL?: string
}

export const IISocialInitState: ISocial[] = [
  {
    name: SOCIAL_NAMES[SOCIAL_TYPES.LEADS],
    counter: '0',
  },
  {
    name: SOCIAL_NAMES[SOCIAL_TYPES.LOOKS],
    counter: '0',
  },
  {
    name: SOCIAL_TYPES.FACEBOOK,
    counter: '0',
  },
  {
    name: SOCIAL_TYPES.INSTAGRAM,
    counter: '0',
  },
  {
    name: SOCIAL_TYPES.TWITTER,
    counter: '0',
  },
]

export interface IBriefRecommendedPartner {
  id: number
  userId: number
  mainImageUrl: string
  logoUrl: string
  name: string
  companyWebsite: string
  companyDescription: string
  companyLabel: PARTNER_LABEL
  match: number
  isSampleDistribution: boolean
  pricePerSample: number
  isInsertDistribution: boolean
  pricePerInsert: number
  currency: CURRENCY
  social: ISocial[]
  links: any
  availableServices: IPartnerDetailsService[]
  other: boolean
  monthlyOrderRange: IMonthlyOrdersRange
  hasDynamicSamplePricing: boolean
  dynamicSamplePriceTypes?: [] | null
  dynamicSamplePrices?: any
  mbPricingModel: string
}

export interface IMonthlyOrdersRange {
  text?: string
  min?: number
  max?: number
}

export interface IBriefRecommendedPartnerResponse extends IStatusedMessage {
  data: {
    content: {
      partners: IBriefRecommendedPartner[]
      total: number
    }
  }
  message: string
}

export interface IPartnerCardService {
  name: string
  price: string
  type: string
  subtype?: string
}

export interface IPackagingPrintingPrice {
  currency: CURRENCY
  name: string
  rate: string
  type: string
}

export interface IPartnerDetailsService {
  serviceName: MEDIA_TYPES
  servicePrice: number | null
  servicePricePerExecution: number | null
  serviceExecutionType: string
  serviceDuration: MEDIA_TYPES_DURATION
  serviceDescription: string
  serviceImpressions: number
  serviceImageUrl: string
  serviceClicks: number
  serviceSubName: string | null
  serviceMinOrderValue: string | null
  servicePriceForActivity: string | null
  servicePriceForDuration: string | null
  servicePriceForImpression: string | null
  servicePriceForPush: string | null
  serviceCostPerClick: string | null
  serviceCostPerImpression: string | null
  serviceCostPerReach: string | null
  serviceDailyClicks: string | null
  serviceDailyImpressions: string | null
  serviceDailyReach: string | null
}

export interface IPartnerDetailsCountry {
  name: string
  cities: string[]
}
export interface IPartnerProps {
  dynamicSamplePriceTypes?: [] | null
  dynamicSamplePrices?: {} | null
  hasDynamicSamplePricing?: boolean
  stickerCost?: number
}

export interface LogisticRate {
  rate: number
  min: number
  max?: number
}

export interface IBriefPartnerDetails {
  id: number
  userId: number
  companyName: string
  companyWebsite: string
  companyShortDescription: string
  companyFullDescription: string
  companyFullDescriptionAr?: string
  companyLogoUrl: string
  companyBackgroundImageUrl: string
  match: number
  currency: CURRENCY
  sampleAllocated: number
  social: ISocial[]
  monthlyOrderRange: IMonthlyOrdersRange

  mbPricingModel: string
  isSampleDistribution: boolean
  pricePerSample: number
  isInsertDistribution: boolean
  pricePerInsert: number
  availableServices: IPartnerDetailsService[]
  highlights: string[]
  metricsMarketing: string[]
  locations: IPartnerDetailsCountry[]

  logisticSampleRates: LogisticRate[]
  logisticInsertRates: LogisticRate[]

  partnerProperties: IPartnerProps
  metrics: {} //TODO
  matchDetails: string[]
  mediaSubtypes?: MediaSubtype[]
  countries?: ICountry[]
}

export interface IPartnerCard {
  id: number
  selected: boolean
  headerImg: string
  logo: string
  name: string
  url: string
  description: string
  serviceLabel: PARTNER_LABEL
  servicesMatch: number
  servicesPackagePrice: string
  servicesDevicePrice: string
  social: ISocial[]
  popupServices: IPartnerCardService[]
  other: boolean
  monthlyOrderRange: IMonthlyOrdersRange
}

export interface IServiceItemPricing {
  amount: number
  currency: CURRENCY
  quantity: number | null
  total: number
  duration: MEDIA_TYPES_DURATION | null
}

export interface IAvailableServiceItem {
  key: string
  product: string
  productSubType?: string
  type: string
  pricing: IServiceItemPricing
  details?: IPartnerDetailsService
  blocked?: boolean
  requestStatus?: REQUEST_STATUS
}

export interface IBriefPartnerDetailsResponse extends IStatusedMessage {
  data: {
    content: IBriefPartnerDetails
  }
  message: string
}

export interface ICartServiceItem {
  name: string
  type: string
  amount: number
  currency: CURRENCY
  quantity: number | null
  total: number
  duration: MEDIA_TYPES_DURATION
  requestStatus?: REQUEST_STATUS
  rejectionReason?: string
  imageUrl?: string
  productSubType?: string
  offerPrice?: number
}

export interface ICartItem {
  partnerId: number
  companyName: string
  companyBank?: IBankDetails
  companyLogoUrl: string
  companyAddress: string
  companyVat: number
  companyWebsite: string
  logisticSampleRate: number
  logisticInsertRate: number
  logisticSamplePrice: number
  logisticInsertPrice: number
  stickerCost: number
  stickerPrice: number
  subTotal: number
  services: ICartServiceItem[]
  isApprovedByUser: boolean
  advertisementLanguage: any
  priceModel: string
  promotionSubtotal?: number
}

export interface ICartList {
  briefId: number | null
  additional: {
    managementFee: number
    managementFeeRate: number
    couponFee: number
    promotionCouponFee: number
    taxes: number
    cartTotal: number
    items: ICartItem[]
    services: []
    discountText?: string
    copackingTotal: number
    printingTotal: number
    servicesTotal: number
    promotionCartTotal: number
    promotionTaxes: number
    subTotal: number
    copackingMinQuantity: number
    printingMinQuantity: number
  }
  sampleAllocated?: number
  couponCode?: string
  mediaBuyEstimates?: IAdEstimation[]
}

export interface ICartResponse extends IStatusedMessage {
  data: {
    content: ICartList
  }
  message: string
}

export interface IUploadSettings extends ICartItem {
  mediaTypes: IMediaBuyTypesElement[]
}

export interface ICalculation {
  subTotal: number
  managementFee: number
  taxes: number
  total: number
}

export interface IProposal {
  emails: string[]
  message: string
  cc: boolean
}

export interface IPotentialAudienceReach {
  totalSampleAudience: number
  totalMediaBuyAudience: number
  messageSampleDistribution: string
  messageMediaBuy: string
}

export interface IPotentialAudienceReachResponse extends IStatusedMessage {
  data: { content: IPotentialAudienceReach }
  message: string
}

export interface IAdEstimation {
  partnerId: number
  campaignDays?: string
  impressions?: string
  clicks?: string
  reach?: string
}
