import React, { useEffect, useState } from 'react'
import { LOCAL_STORAGE_KEYS, PATHS } from 'src/constants'
import { useReduxDispatch } from 'src/helpers'
import { useHistory } from 'react-router-dom'
import TestahelBriefTypeForm from './testahelBriefForms/TestahelBriefType.form'
import { BRIEF_FORM_HEADER_TITLE, BRIEF_STEPS, BRIEF_TYPES } from 'src/constants/testahel_brief'
import { actions as specialClientsActions } from '../../../specialClients/specialClients.module'
import TestahelBriefHeader from './components/TestahelBrief.header'
import TestahelBriefFooter from './components/TestahelBrief.footer'
import { Modal } from 'antd'
import { IconsCommon, QButton } from 'quantum_components'
import { ReactComponent as CircleCheckIcon } from 'src/assets/icons/circle-check-icon.svg'
import { useSelector } from 'react-redux'
import { getBrief } from '../testahelBox.selectors'
import { actions } from '../testahelBox.module'

const closeIcon = <IconsCommon.IconClose />

const TestahelBriefCreateContainer = () => {
  const dispatch = useReduxDispatch()
  const history = useHistory()

  const brief = useSelector(getBrief)

  const [partnerLogo, setPartnerLogo] = useState<any>('')
  const [briefData, setBriefData] = useState<any>()
  const [modalVisible, setModalVisible] = useState<any>(false)
  const [briefTypes, setBriefTypes] = useState<BRIEF_TYPES[]>([])

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO)) {
      setPartnerLogo(localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO))
    }
    getInviterBriefDetails()
    return () => {
      dispatch(actions.resetBriefState())
    }
  }, [])

  const handleDiscard = () => {
    setModalVisible(false)
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
    history.push(`/testahel-brief/edit/${briefData.exclusiveBrief.id}`)
  }

  const handleBack = () => {
    history.push(PATHS.CREATE_BRIEF)
  }

  const handleContinue = async () => {
    let deleteResponse: any = await dispatch(specialClientsActions.deleteBrief(briefData.exclusiveBrief.id))
    if (deleteResponse == 200) {
      setModalVisible(false)
      history.push(`/testahel-brief/create`)
    }
  }

  const getInviterBriefDetails = async () => {
    if (
      (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) ||
      (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN))
    ) {
      let briefData: any = await dispatch(
        specialClientsActions.getInviterBriefDetails({
          data: localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
            ? sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            : localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID),
        })
      )
      setBriefData(briefData)
      if (briefData.exclusiveBrief && briefData.exclusiveBrief != null) {
        setModalVisible(true)
      }
    }
  }

  const handleBriefTypeStep = (briefTypes: BRIEF_TYPES[]) => {
    setBriefTypes(briefTypes)
  }

  const canProceed = () => {
    return !!briefTypes.length
  }

  const handleNext = async () => {
    await saveBrief()
    if (sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) {
      sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
    }
  }

  const handleFinishLater = async () => {
    await saveBrief()
    history.push(PATHS.BRIEF)
  }

  const saveBrief = async () => {
    const briefForSave = updateBriefBeforeSave()
    await dispatch(actions.createBrief(briefForSave))
  }

  const updateBriefBeforeSave = () => {
    const updatedBrief = { ...brief }
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = briefTypes

    return updatedBrief
  }

  return (
    <div className="qu-brief-layout">
      {(localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO) && (
          <header className="qu-header partner-logo-header">
            <img src={partnerLogo} className="partner-logo-brief" />
          </header>
        )}
      <TestahelBriefHeader
        title={BRIEF_FORM_HEADER_TITLE.CREATE}
        stepper={null}
        onClickCancel={() => {
          if (
            localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
            sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ) {
            if (sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) {
              history.push(`/p/${sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`)
              sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            } else {
              history.push(`/p/${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`)
            }
          } else {
            history.push(PATHS.BRIEF)
          }
        }}
      />
      <div className={`qu-brief-main ${partnerLogo ? 'partner-logo-qu-brief-main' : ''}`}>
        <TestahelBriefTypeForm briefTypes={briefTypes} onChange={handleBriefTypeStep} />
      </div>
      <TestahelBriefFooter
        disableNext={!canProceed()}
        disableBack={false}
        isZeroStep={false}
        onBack={handleBack}
        onNext={handleNext}
        onFinishLater={handleFinishLater}
        nextButtonText={'Next'}
      />

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{'You have an existing Consumer Research brief!'}</h3>}
        closeIcon={closeIcon}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false)
          history.push(`/p/${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`)
        }}
        footer={false}
      >
        <div>
          <p className="mb-25">
            Would you like to continue your Consumer Research brief with{' '}
            {briefData?.exclusiveBrief?.exclusivePartner?.companyName}?
          </p>
          <div className="align-vertical text-center">
            <QButton onClick={handleDiscard} className="w-280 mb-20" type="primary">
              <span className="mr-5">Continue</span>
              <CircleCheckIcon />
            </QButton>
            <button onClick={handleContinue} type="button" className="button button--link mb-10">
              Discard and create new
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TestahelBriefCreateContainer
