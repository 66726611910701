import React, { ReactElement, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import * as actions from "../signIn.actions";
import { useReduxDispatch } from "src/helpers";
import { PATHS, ROLES } from "src/constants";
import {
  Col,
  Form,
  QButton,
  QInput,
  Row,
  QButtonEye,
} from "quantum_components";
import { validateMessages } from "../../../../helpers/validationMessage";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-icon.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow-left-icon.svg";
import AuthLayout from "../../../../components/Layouts/AuthLayout";
import { Store } from "antd/lib/form/interface";
import { useSelector } from "react-redux";
import { LOCAL_STORAGE_KEYS, LOCALIZATION_LANGUAGES } from "src/constants";
import { getActiveSaasPartner } from "src/modules/core/core.selectors";
import { ReactComponent as Qlogo } from "../../../../assets/quantum-logo.svg";
import { GlobalOutlined } from "@ant-design/icons";
import LanguageLoader from "src/components/LanguageLoader/LanguageLoader";
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script';
import { SOCIAL_LOGIN_TYPES } from "src/constants/common";
import { getIsAuthorizing } from "../signIn.selectors";
import SpinnerCenter from "src/components/Spinner/spinner";
import { SocialFormValues } from "../../signUp/containers/SignUp.container";

const clearInviterKeys = async () => {
  await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID);
  await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN);
  await sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID);
  await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO);
};

export default function SignInContainer({
  hidePartnerInfo,
  specialClientData,
}): ReactElement {
  const { t } = useTranslation(["translationUser", "translationCommon"]);
  const { i18n } = useTranslation();
  const dispatch = useReduxDispatch();
  const isAuthorizing = useSelector(getIsAuthorizing)

  const [showLangLoader, setShowLangLoader] = useState(false);
  const [hideGoogleLogin, setHideGoogleLogin] = useState(false);

  const [langUpdate, setlangUpdate] = useState(0);

  const sysLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION) || "en";

  const clientId = process.env.REACT_APP_GOOGLE_LOGIN_KEY

  useEffect(() => {
    gapi.load('client:auth2', initClient);

    i18n.changeLanguage(
      sysLang == LOCALIZATION_LANGUAGES.ARABIC
        ? LOCALIZATION_LANGUAGES.ARABIC
        : LOCALIZATION_LANGUAGES.ENGLISH
    );

    i18n.changeLanguage(
      sysLang === LOCALIZATION_LANGUAGES.ARABIC
        ? LOCALIZATION_LANGUAGES.ARABIC
        : LOCALIZATION_LANGUAGES.ENGLISH
    );

    if (hidePartnerInfo) return
    clearInviterKeys();
  }, []);

  function initClient() {
    gapi.client.init({
      clientId: clientId,
      scope: ''
    });
  };

  const [passwordIsVisible, setPasswordVisible] = useState<boolean>(false);

  const getPasswordInputType = (passwordIsVisible: boolean) => {
    return passwordIsVisible ? "text" : "password";
  };

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordIsVisible);
  };

  const submit = (values: Store) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = "";
    let specialId = "";
    let briefId = "";
    const resumeBriefId = urlParams.has("resumeBriefId")
      ? urlParams.get("resumeBriefId")
      : "";

    if (specialClientData && specialClientData.p) {
      // data coming from sign in modal in invite client
      token = specialClientData.t;
      specialId = specialClientData.p;
      briefId = specialClientData.b;
    } else {
      // data coming from query string
      token = urlParams.has("t") ? urlParams.get("t") : "";
      specialId = urlParams.has("p") ? urlParams.get("p") : "";
      briefId = urlParams.has("b") ? urlParams.get("b") : "";
    }

    dispatch(
      actions.signIn(
        values.email,
        values.password,
        token,
        specialId,
        briefId,
        resumeBriefId
      )
    );
  };

  const changeLanguage = () => {
    setShowLangLoader(true);
    if (sysLang && sysLang === LOCALIZATION_LANGUAGES.ENGLISH) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ARABIC
      );
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ARABIC);
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ENGLISH
      );
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ENGLISH);
    }
    setlangUpdate(langUpdate + 1);
    hideLanguageLoader();
  };

  const hideLanguageLoader = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setShowLangLoader(false);
  };

  const getLocalizationLanguage = () => {
    return sysLang === LOCALIZATION_LANGUAGES.ENGLISH ? "العربية" : "English";
  };

  const getSignupLink = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.has("t") ? urlParams.get("t") : "";
    const specialId = urlParams.has("p") ? urlParams.get("p") : "";

    const signupUrl =
      token && specialId
        ? `/signup/client?t=${token}&p=${specialId}`
        : "/signup/client";
    return signupUrl;
  };

  const handleLoginSuccess = async (data: any) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = "";
    let specialId = "";
    let briefId = "";
    const resumeBriefId = urlParams.has("resumeBriefId")
      ? urlParams.get("resumeBriefId")
      : "";

    if (specialClientData && specialClientData.p) {
      // data coming from sign in modal in invite client
      token = specialClientData.t;
      specialId = specialClientData.p;
      briefId = specialClientData.b;
    } else {
      // data coming from query string
      token = urlParams.has("t") ? urlParams.get("t") : "";
      specialId = urlParams.has("p") ? urlParams.get("p") : "";
      briefId = urlParams.has("b") ? urlParams.get("b") : null;
    }

    // dispatch(
    //   actions.socialSignIn(
    //     data?.tokenId,
    //     SOCIAL_LOGIN_TYPES.GOOGLE,
    //     token,
    //     specialId,
    //     briefId,
    //     resumeBriefId
    //   )
    // );

    const formValues = {
      email: data.profileObj.email,
      firstName: data.profileObj.givenName,
      lastName: data.profileObj.familyName,
      socialToken: data.tokenId,
      socialType: SOCIAL_LOGIN_TYPES.GOOGLE,
      role: ROLES.CLIENT
    } as SocialFormValues
    dispatch(actions.socialSignUp(formValues, token, specialId, briefId, resumeBriefId))
  }

  const handleLoginFailure = async (error: any) => {
    if (error.error === 'idpiframe_initialization_failed') setHideGoogleLogin(true)
    else setHideGoogleLogin(false)
    console.log('error', error)
  }

  return (
    <AuthLayout hidePartnerInfo={hidePartnerInfo} AuthPageLadyImageMaxWidth={"400px"}>
      {isAuthorizing && <SpinnerCenter />}
      <div className="auth-form">
        <h1>{t("user.login.sign_in_to_your_account")}</h1>
        <LanguageLoader visible={showLangLoader} />
        <Form
          layout="vertical"
          hideRequiredMark
          validateMessages={{
            ...validateMessages,
            required: t("required field"),
          }}
          onFinish={submit}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={t("user.login.email_address")}
                name="email"
                rules={[
                  { required: true, max: 255, min: 8, message: t("required field") },
                ]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput
                  placeholder={t(
                    "Type here"
                  )}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("user.login.password")}
                name="password"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <QInput
                  size="large"
                  placeholder={t("Type here")}
                  style={{ paddingRight: 50 }}
                  type={getPasswordInputType(passwordIsVisible)}
                />
              </Form.Item>
              <QButtonEye
                onClick={handlePasswordVisibility}
                isVisible={passwordIsVisible}
              />
            </Col>
          </Row>
          {!hidePartnerInfo && (
            <div className="remember">
              <Link to={PATHS.RESET_PASSWORD}>
                {t("user.login.forgot_password")}
              </Link>
            </div>
          )}
          <QButton
            className="text-capitalize"
            type="primary"
            block
            htmlType="submit"
          >
            {t("user.login.sign_in")}
            {i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? (
              <ArrowRight className="ml-5" />
            ) : (
              <ArrowLeft className="ml-5" />
            )}
          </QButton>

          {/* <h5 className='mt-10 full-width text-center'>{t('user.signup.or')}</h5>

          <GoogleLogin
            disabled={hideGoogleLogin}
            className='mt-10 full-width ant-btn qu-button text-capitalize ant-btn-primary ant-btn-block google-login-button'
            clientId={clientId}
            buttonText={t('user.signup.sign_in_with_google')}
            onSuccess={handleLoginSuccess}
            onFailure={handleLoginFailure}
            cookiePolicy={'single_host_origin'}
          />
          {hideGoogleLogin && <div className="google-signin-hint">
            {t('user.login.We do not offer Sign In With Google in Private Window')}
          </div>} */}
        </Form>
      </div>
      {!hidePartnerInfo && (
        <div className="auth-section-footer">
          <p>
            {t("user.login.dont_have_an_account")}{" "}
            <Link to={getSignupLink()}>{t("user.login.sign_up")}</Link>
          </p>

          {/* {sysLang && (
            <p>
              <a onClick={() => changeLanguage()}>
                <span className="user-menu-icon">
                  <GlobalOutlined />
                </span>
                {getLocalizationLanguage()}
              </a>
            </p>
          )} */}
        </div>
      )}
    </AuthLayout>
  );
}
