import React, { useEffect, useState } from 'react'
import { QButton, qnotification, QPagination, QTable } from 'quantum_components'
import { useReduxDispatch } from 'src/helpers'
import { CopyOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { actions } from '../partnerAPI.module'
import {
  getPartnerAPI,
  getPages,
  getRequestParams,
  getTotalRecordsCount,
  getPartnerAPIListLoading,
  getIsValid,
} from '../partnerAPI.selectors'
import moment from 'moment'
import { PARTNER_API_STATUS } from 'src/constants/partner'
import DialogModal from 'src/components/DialogModal/DialogModal'
import { IPartnerAPI } from '../partnerAPI.types'
import { NOTIFICATION_TYPES, PATHS } from 'src/constants'
import { getCurrentUser } from 'src/modules/core/core.selectors'
import history from 'src/utils/history'

const PartnerAPIContainer = () => {
  const dispatch = useReduxDispatch()

  const currentUser = useSelector(getCurrentUser)
  const pages = useSelector(getPages)
  const params = useSelector(getRequestParams)
  const partnerAPIData = useSelector(getPartnerAPI)
  const getTotalCount = useSelector(getTotalRecordsCount)
  const tableLoading = useSelector(getPartnerAPIListLoading)
  const isValid = useSelector(getIsValid)

  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    if (currentUser?.partnerApiAccess) {
      fetchPartnerAPIData()
    } else if (currentUser?.partnerApiAccess === false) {
      history.push(PATHS.ROOT)
    }
  }, [currentUser])

  const fetchPartnerAPIData = () => {
    dispatch(actions.setParamsAndFetch({ ...params, offset: 0 }))
    dispatch(actions.setCurrentPage(1))
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setCurrentPage(page))
    await dispatch(actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const onToggleModal = async () => {
    if (isValid) {
      setModalVisible(true)
    } else {
      await dispatch(actions.generatePartnerAPIToken())
      fetchPartnerAPIData()
    }
  }

  const onProceed = async () => {
    setModalVisible(false)
    await dispatch(actions.generatePartnerAPIToken())
    fetchPartnerAPIData()
  }

  const truncate = (str: string, max: number) => {
    max = max || 10
    var len = str.length
    if (len > max) {
      const sep = '......'
      var seplen = sep.length
      if (seplen > max) {
        return str.substr(len - max)
      }
      var n = -0.5 * (max - len - seplen)
      var center = len / 2
      var front = str.substr(0, center - n)
      var back = str.substr(len - center + n)
      return front + sep + back
    }
    return str
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    dispatch(actions.setParamsAndFetch(newParams))
  }

  const copyCodeToClipboard = (token: string) => {
    var input: any = document.body.appendChild(document.createElement('input'))
    input.value = token
    input.focus()
    input.select()
    document.execCommand('copy')
    input.parentNode.removeChild(input)
    qnotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Success',
      description: 'Token copied successfully!!',
    })
  }

  const colKey: any = {
    token: 'token',
    createdAt: 'createdAt',
  }

  const columns = [
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
      width: '50%',
      render: (token: any, record: IPartnerAPI) => (
        <span>
          {truncate(token, 16)}{' '}
          {record.status === PARTNER_API_STATUS.VALID && (
            <a onClick={() => copyCodeToClipboard(token)}>
              <CopyOutlined />
            </a>
          )}
        </span>
      ),
    },
    {
      title: 'Generated On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '30%',
      sorter: true,
      render: (createdAt: any) => createdAt && <span>{moment(createdAt).format('MMM Do YYYY, h:mm a')}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (status: string) => (
        <div>
          <span
            className={`status-label ${status == PARTNER_API_STATUS.EXPIRED && 'pending'} ${
              status == PARTNER_API_STATUS.VALID && 'accepted'
            }`}
          >
            {PARTNER_API_STATUS[status]}
          </span>
        </div>
      ),
    },
  ]

  return (
    <div className="dashboard-layout">
      {currentUser?.partnerApiAccess && (
        <>
          <div className="qt-header">
            <div className="qu-statistic-wrapper" style={{ marginBottom: 'unset' }}>
              <h1>Partner API</h1>
            </div>
            <QButton onClick={onToggleModal} className="qu-button-soft" style={{ float: 'right' }}>
              GENERATE API TOKEN
            </QButton>

            <DialogModal
              modalOpen={modalVisible}
              question={'You already have a valid token!'}
              description={
                'Generating a new token will expire your existing valid token. Are you sure you want to do this?'
              }
              submitButtonText={'Cancel'}
              cancelButtonText={'Confirm'}
              onClose={() => setModalVisible(false)}
              onProceed={() => onProceed()}
              revert
            />
          </div>

          <div className="qt-body">
            <QTable
              //@ts-ignore
              loading={tableLoading}
              //@ts-ignore
              onChange={handleTableChange}
              sortDirections={['ascend', 'descend', 'ascend']}
              columns={columns}
              dataSource={partnerAPIData}
              rowClassName="q-table"
              className="cursorPointer"
            />
          </div>
          <div className="qt-footer">
            <QPagination
              size="small"
              defaultCurrent={1}
              current={pages.currentPage}
              total={getTotalCount}
              onChange={handleChangePage}
              showSizeChanger
            />
          </div>
        </>
      )}
    </div>
  )
}

export default PartnerAPIContainer
