/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useReduxDispatch } from "../../../helpers";
import { isMobile } from "react-device-detect";
import * as actions from "../brief.actions";
import {
  Dropdown,
  IconsCommon,
  Menu,
  ProfileCard,
  QAlert,
  QButton,
  QCampaignsTableCardList,
  QInput,
  QMenuItem,
  QPagination,
  QPromotionsCard,
  QSelect,
  QTable,
  QTableCardList,
  Row,
} from "quantum_components";
import { PATHS, LOCALIZATION_LANGUAGES, FORMATS } from "src/constants";
import { useSelector } from "react-redux";
import {
  getBrief,
  getBriefList,
  getBriefListLoading,
  getBriefParams,
  getCardList,
  getPages,
  getTotalBriefList,
} from "../brief.selectors";
import {
  Col,
  Modal,
  Select,
  Carousel,
  Tag,
  Button,
  Popover,
  Divider,
} from "antd";
import { SearchOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import useDebounce from "../../../hooks/useDebounce";
import {
  BRIEF_FORM_HEADER_TITLE,
  BRIEF_STATUS_GROUP,
  BRIEF_STATUS,
  BRIEF_TYPES,
  BRIEF_STATUS_LIST,
  BRIEF_STEPS,
  BRIEF_STATUS_NAMES,
  briefPaymentStatuses,
  briefStatuses,
  BRIEF_PAYMENT_STATUS_LIST,
  BRIEF_PAYMENT_STATUS_NAMES,
  DEFAULT_BRIEF_TABLE_PARAMS,
  BRIEF_PAYMENT_STATUS,
} from "src/constants/brief";
import { getCurrentUser } from "../../core/core.selectors";
import { ALERTS_MSG } from "src/constants/alerts";
import { ReactComponent as EyeIcon } from "src/assets/icons/eye-icon.svg";
import { ReactComponent as EditIcon } from "src/assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "src/assets/icons/delete-icon.svg";
import { COLORS } from "../../../constants/colors";
import {
  IBriefListElement,
  IBriefStatusGroup,
  IBriefStatus,
} from "../brief.types";
import { getAllAnnouncements } from "src/modules/client/announcement/announcement.selectors";
import { fetchAllAnnouncementsList } from "src/modules/client/announcement/announcement.actions";
import moment from "moment";
import formats from "src/constants/formats";
import { snakeCase } from "lodash";
import { renderRoundedNumber } from "src/helpers/currency";
import { CURRENCY } from "src/constants/currencies";
import SpinnerCenter from "src/components/Spinner/spinner";
import { deleteBriefAsync } from "src/modules/briefV3/briefv3.actions";

const BriefList = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation([
    "translation",
    "translationCommon",
    "translationV2",
  ]);
  const { Option, OptGroup } = Select;

  const briefTranslatedStatuses = {
    [BRIEF_STATUS.PENDING]: {
      classStyle: "pending",
      label: t("brief.brief-statuses-array.PENDING"),
    },
    [BRIEF_STATUS.PENDING_ACCOUNT_APPROVAL]: {
      classStyle: "partially running account_approval",
      label: t("brief.brief-statuses-array.PENDING_ACCOUNT_APPROVAL"),
    },
    [BRIEF_STATUS.SELECT_PARTNERS]: {
      classStyle: "partially running",
      label: t("brief.brief-statuses-array.SELECT_PARTNERS"),
    },
    [BRIEF_STATUS.DRAFT]: {
      classStyle: "running",
      label: t("brief.brief-statuses-array.DRAFT"),
    },
    [BRIEF_STATUS.APPROVED]: {
      classStyle: "approved",
      label: t("brief.brief-statuses-array.APPROVED"),
    },
    [BRIEF_STATUS.PARTIALLY_APPROVED]: {
      classStyle: "partially approved",
      label: t("brief.brief-statuses-array.PARTIALLY_APPROVED"),
    },
    [BRIEF_STATUS.REJECTED]: {
      classStyle: "rejected",
      label: t("brief.brief-statuses-array.REJECTED"),
    },
    [BRIEF_STATUS.PENDING_APPROVAL]: {
      classStyle: "partially rejected",
      label: t("brief.brief-statuses-array.PENDING_APPROVAL"),
    },
    [BRIEF_STATUS.PARTIALLY_REJECTED]: {
      classStyle: "partially rejected",
      label: t("brief.brief-statuses-array.PARTIALLY_REJECTED"),
    },
    [BRIEF_STATUS.NO_RESPONSE]: {
      classStyle: "rejected",
      label: t("brief.brief-statuses-array.NO_RESPONSE"),
    },
    [BRIEF_STATUS.EXPIRED]: {
      classStyle: "completed",
      label: t("brief.brief-statuses-array.EXPIRED"),
    },
    [BRIEF_STATUS.PAID]: {
      classStyle: "completed",
      label: "paid",
    },
    [BRIEF_STATUS.PAYOUT_COMPLETE]: {
      classStyle: "completed",
      label: t("brief.brief-statuses-array.PAYOUT_COMPLETE"),
    },
    [BRIEF_STATUS.MISSING_INFO]: {
      classStyle: "missing info",
      label: t("brief.brief-statuses-array.MISSING_INFO"),
    },
  };

  const briefTranslatedPaymentStatuses = {
    [BRIEF_PAYMENT_STATUS.PENDING]: {
      classStyle: "pending",
      label: t("brief.payment-statuses-array.PENDING"),
    },
    [BRIEF_PAYMENT_STATUS.PROCESSING]: {
      classStyle: "pending",
      label: t("brief.payment-statuses-array.PROCESSING"),
    },
    [BRIEF_PAYMENT_STATUS.PARTIAL_REFUND]: {
      classStyle: "running",
      label: t("brief.payment-statuses-array.PARTIAL_REFUND"),
    },
    [BRIEF_PAYMENT_STATUS.REFUND]: {
      classStyle: "rejected",
      label: t("brief.payment-statuses-array.REFUND"),
    },
    [BRIEF_PAYMENT_STATUS.PAID]: {
      classStyle: "approved",
      label: t("brief.payment-statuses-array.PAID"),
    },
    [BRIEF_PAYMENT_STATUS.DECLINED]: {
      classStyle: "rejected",
      label: t("brief.payment-statuses-array.DECLINED"),
    },
    [BRIEF_PAYMENT_STATUS.REJECTED]: {
      classStyle: "rejected",
      label: t("brief.payment-statuses-array.REJECTED"),
    },
    [BRIEF_PAYMENT_STATUS.PO_ISSUED]: {
      classStyle: "approved",
      label: t("brief.payment-statuses-array.PO_ISSUED"),
    },
    [BRIEF_PAYMENT_STATUS.PAYOUT_COMPLETE]: {
      classStyle: "approved",
      label: t("brief.payment-statuses-array.PAYOUT_COMPLETE"),
    },
  };

  const history = useHistory();
  const dispatch = useReduxDispatch();
  const briefList = useSelector(getBriefList);
  const cardList = useSelector(getCardList);
  const brief = useSelector(getBrief);
  const currentUser = useSelector(getCurrentUser);
  const totalBriefList = useSelector(getTotalBriefList);
  const briefListLoading = useSelector(getBriefListLoading);
  const params = useSelector(getBriefParams);
  const pages = useSelector(getPages);
  // let promotionsList = useSelector(getAllAnnouncements);
  let promotionsList = [];


  const [modal, setModal] = useState({ visible: false, id: null, isV2: false });
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [tableKey, setTableKey] = useState(1);
  const [showAllPromotions, setShowAllPromotions] = useState(false);

  const [showPopover, setShowPopover] = useState(false);

  const tableLoading = {
    spinning: briefListLoading,
    indicator: <div className="loader loader-small" />,
  };

  const [timeDelayed, setTimeDelayed] = useState(false);

  useEffect(() => {
    if (!currentUser.id) return;
    if (currentUser.isApproved) {
      setTimeout(() => {
        setTimeDelayed(true);
      }, 1500);
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(
      fetchAllAnnouncementsList(
        "?offset=0&limit=4&direction=desc&sortField=createdAt&search="
      )
    );
  }, []);

  useEffect(() => {
    cardList.map((card) => {
      card.services.map((service) => {
        service.name = t(
          "services.media-buy.types." + snakeCase(service.name),
          service.name,
          { ns: "translation" }
        );
        service.durationText = getCartLabel(service.duration, service.quantity);
      });
    });
  }, [cardList]);

  useEffect(() => {
    dispatch(
      actions.setParamsAndFetch({
        ...params,
        search: debouncedSearch,
        offset: 0,
        limit: 10,
      })
    );
    dispatch(actions.setCurrentPage(1));

    return () =>
      dispatch(actions.setBriefListParams(DEFAULT_BRIEF_TABLE_PARAMS));
  }, [debouncedSearch]);

  const handleOk = async () => {
    setModal({ ...modal, visible: false });
    setTableKey(tableKey + 1);
    if (modal.isV2) {
      await deleteBriefAsync(modal.id);
      setModal({ isV2: false, visible: false, id: -1 });
      dispatch(actions.fetchBriefList(params));
    } else {
      dispatch(actions.deleteBrief(modal.id, params));
    }
  };
  const handleDeleteBrief = (id, isV2?) => {
    setModal({ visible: true, id, isV2 });
    setTableKey(tableKey + 1);
  };

  const colKey = {
    product: "name",
  };

  const translateDiscountText = (text, isPromotion?) => {
    let buffer = text.split("%");

    if (buffer[0] == 0) return t("brief.cart." + snakeCase(buffer[1]));

    return buffer[0] + "% " + t("brief.cart." + snakeCase(buffer[1]));
  };

  const columns = [
    {
      title: t("brief.table-columns.Campaigns", { ns: "translationCommon" }),
      dataIndex: "product",
      key: "product",
      width: "40%",
      fixed: "left",
      sorter: true,
      render: (product, list) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.productFirstImage && (
                <img
                  src={product.productFirstImage}
                  alt={product.campaignName}
                />
              )}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">
                {product.campaignName ||
                  t("common.unnamed_brief", { ns: "translationCommon" })}
              </h3>
              <div className="q-table-product_created">
                {t("common.created", { ns: "translationCommon" })}{" "}
                {product.created}
              </div>
              {product.discountText && (
                <div className="text-green line-height-10 font-size-12">
                  {product.isPromotional &&
                    "[" + t("brief.promotions.promotion") + "] "}
                  {translateDiscountText(
                    product.discountText,
                    product.isPromotional
                  )}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: t("brief.table-columns.Start", { ns: "translationCommon" }),
      dataIndex: "product",
      key: "product",
      width: "20%",
      render: (product, list) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_info">
              <div className="q-table-product_created">
                {product.mediaStartDate === "Invalid date"
                  ? ""
                  : product.mediaStartDate}
                {list.clientId && list.clientId > 0
                  ? moment(
                      list?.cart?.additional?.services[0]?.startDate
                    ).format(FORMATS.DATE_LL_FORMAT)
                  : ""}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: t("brief.table-columns.Status", { ns: "translationCommon" }),
      dataIndex: "status",
      key: "status",
      width: "19%",
      fixed: "right",
      render: (status) => {
        return (
          <div>
            <span
              className={`status-label ${
                briefStatuses[status.value] &&
                briefStatuses[status.value].classStyle
              }`}
            >
              {briefStatuses[status.value]
                ? t(briefStatuses[status.value].label)
                : t(briefStatuses.draft.label)}
            </span>
          </div>
        );
      },
    },
    {
      title: t("brief.table-columns.Payment_Status", {
        ns: "translationCommon",
      }),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: "19%",
      fixed: "right",
      render: (paymentStatus, { status, isTestahel, clientId }) => {
        const rejectionStatuses = [
          BRIEF_STATUS.REJECTED,
          BRIEF_STATUS.PARTIALLY_REJECTED,
        ];
        return (
          <div>
            {paymentStatus && status.value !== BRIEF_STATUS.REJECTED ? (
              <span
                className={`status-label ${
                  briefPaymentStatuses[paymentStatus.value] &&
                  briefPaymentStatuses[paymentStatus.value].classStyle
                }`}
              >
                {t(briefPaymentStatuses[paymentStatus.value].label)}
              </span>
            ) : (
              <span>-</span>
            )}

            {/*/!* QUA-1449, QP-492 - prevent event bubbling to onRowClick *!/*/}
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu(
                  status.id,
                  rejectionStatuses.includes(status.value),
                  isTestahel,
                  clientId > -1
                )}
                trigger={["click"]}
                placement="bottomRight"
              >
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        );
      },
    },
  ];

  const menu = (
    id: number,
    isRejected: boolean,
    isTestahel: boolean,
    isV2: boolean
  ) => (
    <Menu
      className={`user-menu-list ${
        i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? "html-rtl" : ""
      }`}
    >
      <QMenuItem
        key={0}
        onClick={() => handleView(id, isTestahel, isV2)}
        name={t("brief.table-menu.View", { ns: "translationCommon" })}
        icon={<EyeIcon />}
      />
      {!isRejected && (
        <QMenuItem
          key={1}
          onClick={() => handleEdit(id, isTestahel, isV2)}
          name={t("brief.table-menu.Edit", { ns: "translationCommon" })}
          icon={<EditIcon />}
        />
      )}

      {isRejected && (
        <QMenuItem
          key={5}
          onClick={() => handleRejection(id, isV2)}
          name={t(BRIEF_FORM_HEADER_TITLE.REJECTION, {
            ns: "translationCommon",
          })}
          icon={<EditIcon />}
        />
      )}
      <Menu.Divider />
      <QMenuItem
        key={2}
        onClick={() => handleDeleteBrief(id, isV2)}
        name={t("brief.table-menu.Delete", { ns: "translationCommon" })}
        icon={<DeleteIcon />}
      />
    </Menu>
  );

  const handleChangeSearch = (e) => {
    setSearch(e.currentTarget.value);
  };

  const handleChangeSelect = (activeStatuses: string[]) => {
    const statuses = activeStatuses.reduce(
      (acc, statusFullName) => {
        const statusGroupName = statusFullName.split("-")[0];
        const statusName = statusFullName.split("-")[1];

        const groupNames: { [key in BRIEF_STATUS_GROUP]: string } = {
          [BRIEF_STATUS_GROUP.BRIEF]: "status",
          [BRIEF_STATUS_GROUP.PAYMENT]: "paymentStatus",
        };
        const groupName = groupNames[statusGroupName];

        if (acc[groupName]) {
          acc[groupName].push(statusName);
        } else {
          acc[groupName] = [statusName];
        }
        return acc;
      },
      { status: [], paymentStatus: [] }
    );
    dispatch(actions.setParamsAndFetch({ ...params, ...statuses, offset: 0 }));
  };

  const handleChangePage = (page, pageSize) => {
    dispatch(actions.setCurrentPage(page));
    dispatch(
      actions.setParamsAndFetch({
        ...params,
        offset: (page - 1) * pageSize,
        limit: pageSize,
      })
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    };
    const newParams = {
      ...params,
      ...sortingParams,
    };
    dispatch(actions.setParamsAndFetch(newParams));
  };
  const handleView = (id, isTestahel, isV2) => {
    if (isTestahel) history.push(`/testahel-brief/view/${id}`);
    else if (isV2) history.push(`/briefs/v3/edit/${id}`);
    else history.push(`/briefs/view/${id}`);
  };

  const handleEdit = (id, isTestahel, isV2) => {
    dispatch(actions.setCurrentStep(brief.lastFilledStep));
    dispatch(actions.setNeedCheckLastStep(true));
    if (isTestahel) history.push(`/testahel-brief/edit/${id}`);
    else if (isV2) history.push(`/briefs/v3/edit/${id}`);
    else history.push(`/briefs/edit/${id}`);
  };

  const handleRejection = (id, isV2) => {
    if(isV2){
      history.push(`/briefs/v3/edit/${id}`);
    }else{
      history.push(`/briefs/rejected/${id}`);
    }
    
  };

  const isBriefListNotEmpty = !!(!currentUser.isApproved && briefList.length);
  const isBriefListEmpty = !currentUser.isApproved && !briefList.length;

  const checkIfUserIsApproved = () => {
    return (
      currentUser.isApproved &&
      !briefList.some(
        (brief: IBriefListElement) => brief.status !== BRIEF_STATUS.DRAFT
      )
    );
  };

  const showAlertApproved = checkIfUserIsApproved();

  const briefStatusGroup: IBriefStatusGroup = {
    id: BRIEF_STATUS_GROUP.BRIEF,
    name: BRIEF_STATUS_GROUP.BRIEF,
    label: t("brief.brief-statuses-label"),
    statuses: BRIEF_STATUS_LIST.map((status, index) => ({
      id: `${BRIEF_STATUS_GROUP.BRIEF}_${index}`,
      name: status,
      label: t(BRIEF_STATUS_NAMES[status]),
    })),
  };

  const handleOnRowClick = (brief: IBriefListElement, rowIndex: Number) => ({
    onClick: (event: any) => {
      document.getElementsByClassName("q-table_menu")[rowIndex].click();
      // brief.isTestahel
      // ? history.push(`/testahel-brief/view/${brief.key}`)
      // : history.push(`/briefs/view/${brief.key}`)
    },
  });

  const paymentStatusGroup: IBriefStatusGroup = {
    id: BRIEF_STATUS_GROUP.PAYMENT,
    name: BRIEF_STATUS_GROUP.PAYMENT,
    label: t("brief.payment-statuses-label"),
    statuses: BRIEF_PAYMENT_STATUS_LIST.map((status, index) => ({
      id: `${BRIEF_STATUS_GROUP.PAYMENT}_${index}`,
      name: status,
      label: t(BRIEF_PAYMENT_STATUS_NAMES[status]),
    })),
  };

  const statusGroups: IBriefStatusGroup[] = [
    briefStatusGroup,
    paymentStatusGroup,
  ];

  const handleCreateBriefClick = async (id?: number, version?: string) => {
    // history.push('/briefs/v3/edit/3293')
    // return
    let updatedBrief = { ...brief };
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = [BRIEF_TYPES.MEDIA_BUYING];
    if (id) updatedBrief["promotionId"] = id;

    if (!version || version === "v1") {
      await dispatch(actions.createBrief(updatedBrief));
    } else {
      await dispatch(actions.createBriefV3());
    }
    setShowPopover(false);
  };

  const calculateDuration = (duration: string, quantity: number) => {
    if (!duration) return "N/A";
    return `${quantity} ${
      quantity > 1
        ? t(`common.` + duration.split(" ")[1] + `s`, {
            ns: "translationCommon",
          })
        : t(`common.` + duration.split(" ")[1], { ns: "translationCommon" })
    }`;
  };

  const getCartLabel = (duration: string, quantity: number) => {
    if (!duration) return t("brief.cart.quantity");
    return `${
      quantity > 1
        ? t(`common.` + duration.split(" ")[1] + `s`, {
            ns: "translationCommon",
          })
        : t(`common.` + duration.split(" ")[1], { ns: "translationCommon" })
    }`;
  };

  const getFileDetailsFromURL = (url: string) => {
    const filename = url.substring(url.lastIndexOf("/") + 1, url.length);
    const ext = filename.substring(
      filename.lastIndexOf(".") + 1,
      filename.length
    );
    const truncatedFilename = "..." + filename.substring(filename.length - 10);
    return {
      truncatedFilename,
      filename,
      ext,
    };
  };

  const roundOffto2Decimals = (number: any) => {
    return +(Math.round(number + "e+2") + "e-2");
  };

  const calculatePromotionDiscount = (promotion: any) => {
    let services = promotion.service;
    let promotionDiscount = +promotion.discount;

    if (promotionDiscount != 0) {
      return `${i18n.dir() === "rtl" ? "%" : ""}${promotionDiscount}${
        i18n.dir() === "ltr" ? "%" : ""
      }`;
    }

    let total = services.reduce((acc, cur) => acc + Number(cur.total), 0);
    let offerPrice = services.reduce(
      (acc, cur) => acc + Number(cur.offerPrice ?? cur.total),
      0
    );
    let discount = total - offerPrice;

    return renderRoundedNumber(discount, CURRENCY.SAR);
  };

  // useEffect(() => {
  //   if (promotionsList.length < 1) return;
  //   if (promotionsList.length > 3) setShowAllPromotions(true);
  // }, [promotionsList]);

  const renderCreateCampaignV2 = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "200px",
          background: "white",
        }}
      >
        <div
          onClick={() => handleCreateBriefClick(null, "v1")}
          className="p-15"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor:"pointer"
          }}
        >
          <img
            style={{ height: "55px" }}
            src="https://saas-dev.quantums.me:8081/static/media/home-page-banners.57f479c0.svg"
          />
          <div className="mt-10" style={{ fontSize: "20px" }}>
          {t("Media Buying", { ns: "translationV2" })}
          </div>
        </div>
        <Divider className="m-0" />
        <div
          onClick={() => handleCreateBriefClick(null, "v2")}
          className="p-15"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            cursor:"pointer"
          }}
        >
          <img
            style={{ height: "55px" }}
            src="https://saas-dev.quantums.me:8081/static/media/sku-uplifting.ed2e0e20.svg"
            alt="Media Buying"
          />
          <div className="mt-10" style={{ fontSize: "20px" }}>
          {t("SKU Uplifting", { ns: "translationV2" })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="qu-brief-view" onClick={(e) => setShowPopover(false)}>
      {isMobile && briefListLoading && <SpinnerCenter />}

      <Row
        className={`${
          isBriefListNotEmpty ||
          showAlertApproved ||
          (isBriefListEmpty && "mb-20")
        }`}
      >
        <Col span={24}>
          {isBriefListNotEmpty && (
            <QAlert
              message={t(ALERTS_MSG.ALERT_BRIEF_LIST_NOT_EMPTY, {
                ns: "translationCommon",
              })}
              type="warning"
              className="centered pt-40"
            />
          )}
          {timeDelayed && showAlertApproved && (
            <QAlert
              message={t(ALERTS_MSG.ALERT_ACCOUNT_APPROVED, {
                ns: "translationCommon",
              })}
              type="info"
              className="centered pt-40"
            />
          )}
          {timeDelayed && isBriefListEmpty && (
            <QAlert
              message={t(ALERTS_MSG.ALERT_BRIEF_LIST_EMPTY, {
                ns: "translationCommon",
              })}
              type="warning"
              className="centered pt-40"
            />
          )}
        </Col>
      </Row>

      {promotionsList.length > 0 && (
        <div>
          {isMobile ? (
            <Row
              className={`${isMobile && "pb-15"} ${isMobile && "pt-10"}`}
              align="middle"
              justify="center"
            >
              <div style={{ width: "100%" }}>
                <Carousel adaptiveHeight={true} className="promotions-carousel">
                  {promotionsList.slice(0, 3).map((promotion: any) => (
                    <Col span={24} key={promotion.id}>
                      <QPromotionsCard
                        promotionThumbnail={
                          promotion.promotionImages &&
                          !!promotion.promotionImages.length
                            ? promotion.promotionImages.slice(0, 1)
                            : ["https://i.ibb.co/6tH20M9/placeholder-image.png"]
                        }
                        promotionName={promotion.promotionName}
                        promotionType={promotion.service.map((pr: any) => {
                          return (
                            <>
                              {t(
                                "services.media-buy.types." +
                                  snakeCase(pr.name),
                                pr.name,
                                { ns: "translation" }
                              )}{" "}
                              {t("brief.promotions.for")}{" "}
                              {calculateDuration(pr.duration, pr.quantity)}
                            </>
                          );
                        })}
                        promotionTypeHeading={t(
                          "brief.promotions.services_bundle"
                        )}
                        promotionPrice={
                          i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                            ? `@ ${t(
                                "brief.promotions.discount"
                              )} ${calculatePromotionDiscount(promotion)}`
                            : `@ ${calculatePromotionDiscount(promotion)} ${t(
                                "brief.promotions.discount"
                              )}`
                        }
                        promotionDate={
                          t("brief.promotions.starting") +
                          " " +
                          moment(promotion.desiredStartingDate).format(
                            formats.DATE_MMMM_FORMAT
                          )
                        }
                        promotionFiles={
                          promotion.promotionFiles &&
                          promotion.promotionFiles.length
                            ? promotion.promotionFiles
                            : []
                        }
                        promotionFilesText={t("brief.promotions.file")}
                        buttonText={
                          promotion.briefs.length > 0
                            ? t("brief.resume-brief-button")
                            : t("brief.add-brief-button") + " +"
                        }
                        onCreateCampaign={() =>
                          promotion.briefs.length > 0
                            ? history.push(
                                `/briefs/edit/${promotion.briefs[0].id}`
                              )
                            : handleCreateBriefClick(promotion.id)
                        }
                      />
                    </Col>
                  ))}
                  {showAllPromotions && (
                    <Col span={24} key={0}>
                      <QPromotionsCard
                        showAllPromotionsButton={true}
                        showAllPromotionsButtonText={t(
                          "brief.promotions.show_all_promotions"
                        )}
                        handleShowAllPromotion={() =>
                          history.push("/announcements")
                        }
                      />
                    </Col>
                  )}
                </Carousel>
              </div>
            </Row>
          ) : (
            <>
              <Row
                className={`${
                  isMobile ? "pl-15 pr-15" : "pt-30 mb-25"
                } brief-list-header`}
              >
                <Col>
                  <h3>{t("brief.promotions.promotions")}</h3>
                </Col>
              </Row>
              <Row className={`${isMobile && "pb-15"} ${isMobile && "pt-10"}`}>
                {promotionsList.slice(0, 3).map((promotion: any) => {
                  return (
                    <Col sm={6} md={6} key={promotion.id}>
                      <div className="promotion-container pb-10">
                        {promotion.promotionImages &&
                          promotion.promotionImages.length > 0 && (
                            <Carousel autoplay>
                              {promotion.promotionImages.map((image: any) => {
                                return (
                                  <img src={image} alt={"promotion image"} />
                                );
                              })}
                            </Carousel>
                          )}
                        {promotion.promotionImages &&
                          promotion.promotionImages.length < 1 && (
                            <img
                              src={
                                "https://i.ibb.co/6tH20M9/placeholder-image.png"
                              }
                              alt={"placeholder promotion image"}
                            />
                          )}
                        <div className="pl-10 pr-10">
                          <b>{promotion.promotionName}</b>
                        </div>
                        <div className="pl-10 pr-10 font-size-12 line-height-14 mt-10 dotted-border">
                          {promotion.service.map((pr: any) => {
                            return (
                              <>
                                <div>
                                  <b>
                                    {t(
                                      "services.media-buy.types." +
                                        snakeCase(pr.name),
                                      pr.name,
                                      { ns: "translation" }
                                    )}
                                  </b>
                                </div>
                                <div className="mb-10">
                                  <b>
                                    for{" "}
                                    {calculateDuration(
                                      pr.duration,
                                      pr.quantity
                                    )}
                                  </b>
                                </div>
                              </>
                            );
                          })}
                          <div className="text-green">
                            {i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                              ? `@ ${t(
                                  "brief.promotions.discount"
                                )} ${calculatePromotionDiscount(promotion)}`
                              : `@ ${calculatePromotionDiscount(promotion)} ${t(
                                  "brief.promotions.discount"
                                )}`}
                          </div>
                          <div>
                            {t("brief.promotions.starting")}{" "}
                            <b>
                              {moment(promotion.desiredStartingDate).format(
                                formats.DATE_LL_FORMAT
                              )}
                            </b>
                          </div>
                        </div>
                        <div className="pl-10 pr-10 mt-10">
                          {promotion.promotionFiles &&
                            promotion.promotionFiles.length > 0 &&
                            promotion.promotionFiles.map((file: any) => {
                              return (
                                <Tag
                                  onClick={() => window.open(file, "_blank")}
                                  color={"default"}
                                >
                                  {
                                    getFileDetailsFromURL(file)
                                      .truncatedFilename
                                  }
                                </Tag>
                              );
                            })}
                        </div>
                        <div className="pl-10 pr-10 mt-10">
                          <QButton
                            block
                            type="primary"
                            size="small"
                            className="qu-button-soft"
                            onClick={() =>
                              promotion.briefs.length > 0
                                ? history.push(
                                    `/briefs/edit/${promotion.briefs[0].id}`
                                  )
                                : handleCreateBriefClick(promotion.id)
                            }
                          >
                            {promotion.briefs.length > 0
                              ? t("brief.resume-brief-button")
                              : t("brief.add-brief-button") + " +"}
                          </QButton>
                        </div>
                      </div>
                    </Col>
                  );
                })}
                {showAllPromotions && (
                  <Col sm={6} md={6}>
                    <div className="promotion-container-inverse pb-10">
                      <div
                        className="show-all-container"
                        onClick={() => history.push("/announcements")}
                      >
                        <label>
                          {t("brief.promotions.show_all_promotions")}...
                        </label>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </>
          )}
        </div>
      )}

      <div className="qu-brief-list pb-20">
        {isMobile ? (
          <Row className="pb-20 pt-10 text-center">
            <Col span={24}>
              <Popover
                content={
                  <Row gutter={16}>
                    <Col md={12}>
                      <QButton
                        type="primary"
                        onClick={() => handleCreateBriefClick(null, "v1")}
                        className="qu-button-soft"
                        size="small"
                        htmlType="button"
                      >
                        {t("Media Buying", { ns: "translationV2" })}
                      </QButton>
                    </Col>
                    <Col md={12}>
                      <QButton
                        type="primary"
                        onClick={() => handleCreateBriefClick(null, "v2")}
                        className="qu-button-soft"
                        size="small"
                        htmlType="button"
                      >
                        {t("SKU Uplifting", { ns: "translationV2" })}
                      </QButton>
                    </Col>
                  </Row>
                }
                trigger="click"
                open={showPopover}
              >
                <QButton type="primary" onClick={() => setShowPopover(true)}>
                  {t("brief.start-your-ad-button")}
                </QButton>
              </Popover>
            </Col>
          </Row>
        ) : (
          <Row
            className="pt-30 pb-10 pl-15 brief-list-header"
            align="middle"
            justify="space-between"
          >
            <Col>
              <h3>{t("brief.title")}</h3>
            </Col>
            <Col>
              {/* <Popover
                content={
                  renderCreateCampaignV2()
                }
                 trigger="click"
                 open={showPopover}
               >
                 <QButton type="primary" onClick={() => setShowPopover(true)}>
                   {t("brief.add-brief-button")} +
                 </QButton>
               </Popover> */}
              <QButton type="primary" onClick={() => handleCreateBriefClick(null, "v2")}>
                  {t("brief.add-brief-button")} +
                </QButton>
            </Col>
          </Row>
        )}

        <Row
          className={`${isMobile && "pb-15 pl-5 pr-5"}`}
          justify={`${isMobile ? "center" : "space-between"}`}
        >
          <Col
            span={isMobile && 24}
            className={`${!isMobile && "qt-search"} ${isMobile && "pb-5"}`}
          >
            <QInput
              className={`${isMobile && "full-width"}`}
              value={search}
              onChange={handleChangeSearch}
              placeholder={t("brief.search-field")}
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col span={isMobile && 24} className={`${!isMobile && "qt-filter"}`}>
            <QSelect
              className={`${isMobile && "full-width"}`}
              showArrow
              maxTagTextLength={8}
              maxTagCount={2}
              onChange={handleChangeSelect}
              mode="multiple"
              size="large"
              placeholder={t("brief.filter-field")}
            >
              {statusGroups.map((group: IBriefStatusGroup) => (
                <OptGroup key={group.id} label={group.label}>
                  {group.statuses.map((status: IBriefStatus) => (
                    <Option
                      key={status.id}
                      value={`${group.name}-${status.name}`}
                    >
                      {status.label}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </QSelect>
          </Col>
        </Row>

        {isMobile ? (
          <div className="p-5 campaigns-table-card">
            <QCampaignsTableCardList
              //@ts-ignore
              cardList={cardList}
              onViewClick={(briefId) => handleView(briefId)}
              onEditClick={(briefId) => handleEdit(briefId)}
              onDeleteClick={(briefId) => handleDeleteBrief(briefId)}
              labelStatus={briefTranslatedStatuses}
              paymentStatus={briefTranslatedPaymentStatuses}
              dummyCampaignNameText={t("common.unnamed_brief", {
                ns: "translationCommon",
              })}
              dateStartedText={t("common.Started", { ns: "translationCommon" })}
              dateCreatedText={t("common.created", { ns: "translationCommon" })}
              startsText={t("common.starts", { ns: "translationCommon" })}
              promotionText={t("brief.promotions.promotion")}
              statusText={t("brief.table-columns.Status", {
                ns: "translationCommon",
              })}
              paymentStatusText={t("brief.table-columns.Payment_Status", {
                ns: "translationCommon",
              })}
              viewText={t("common.View", { ns: "translationCommon" })}
              editText={t("common.edit", { ns: "translationCommon" })}
              deleteText={t("brief.table-menu.Delete", {
                ns: "translationCommon",
              })}
            />
            <div className="mobile-pagination">
              <Button
                disabled={
                  i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                    ? pages.currentPage === pages.totalPages
                    : pages.currentPage === 1
                }
                onClick={() =>
                  i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                    ? handleChangePage(pages.currentPage + 1, 10)
                    : handleChangePage(pages.currentPage - 1, 10)
                }
                className="left-arrow"
                type="text"
                shape="circle"
                icon={<LeftOutlined />}
              />
              <span>
                {t("common.showing_page", { ns: "translationCommon" })}{" "}
                {pages.currentPage}{" "}
                {t("common.of", { ns: "translationCommon" })} {pages.totalPages}
              </span>
              <Button
                disabled={
                  i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                    ? pages.currentPage === 1
                    : pages.currentPage === pages.totalPages
                }
                onClick={() =>
                  i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                    ? handleChangePage(pages.currentPage - 1, 10)
                    : handleChangePage(pages.currentPage + 1, 10)
                }
                className="right-arrow"
                type="text"
                shape="circle"
                icon={<RightOutlined />}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="pt-15 pb-30">
              <QTable
                //@ts-ignore
                onRow={handleOnRowClick}
                onChange={handleTableChange}
                sortDirections={["ascend", "descend", "ascend"]}
                loading={tableLoading}
                columns={columns}
                dataSource={briefList}
                scroll={{ x: 990 }}
                rowClassName="q-table cursorPointer"
                locale={{ emptyText: t("my_campaigns_empty_str") }}
              />
            </div>
            <Row
              justify={`${isMobile ? "center" : "end"}`}
              className={`${isMobile && "pt-15 mb-30"}`}
            >
              <QPagination
                size="default"
                defaultCurrent={1}
                current={pages.currentPage}
                total={totalBriefList}
                onChange={handleChangePage}
              />
            </Row>
          </>
        )}

        <Modal
          key={tableKey}
          wrapClassName={`qu-modal ${
            i18n.language === LOCALIZATION_LANGUAGES.ARABIC
              ? "html-rtl delete-brief-modal"
              : ""
          }`}
          title={<h3>{t("brief.edit.common.are_you_sure")}</h3>}
          visible={modal.visible}
          onOk={handleOk}
          onCancel={() => {
            setModal({ visible: false });
            setTableKey(tableKey + 1);
          }}
          footer={false}
        >
          <p className="mb-25">
            {t("brief.edit.common.delete_brief_confirmation")}
          </p>
          <div className="grid-row grid-row--smg grid-row--jce">
            <div className="grid-col grid-col--auto">
              <QButton onClick={handleOk} type="primary">
                {t("common.Confirm", { ns: "translationCommon" })}
              </QButton>
            </div>
            <div className="grid-col grid-col--auto">
              <QButton
                className="qu-button-outline"
                onClick={() => {
                  setModal({ visible: false });
                  setTableKey(tableKey + 1);
                }}
              >
                {t("common.Cancel", { ns: "translationCommon" })}
              </QButton>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default BriefList;
