import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { actions } from '../clientProfile.module'
import { getCurrentUser } from '../../../core/core.selectors'
import { getClientInfo, getIsLoading } from '../profile.selectors'
import { useReduxDispatch } from 'src/helpers'

import '../styles.scss'
import { ClientProfilePreviewBody } from './Layout/profile-preview-body'
import { ClientProfilePreviewHeader } from './Layout/profile-preview-header'
import SpinnerCenter from 'src/components/Spinner/spinner'

const ProfileClientPreviewContainer = () => {
  const dispatch = useReduxDispatch()
  const user = useSelector(getCurrentUser)
  const clientInfo = useSelector(getClientInfo)
  // console.log(clientInfo)
  const isLoading = useSelector(getIsLoading)

  const fetchSettingsAndInfo = async () => {
    dispatch(actions.fetchClientInfo(user))
  }

  useEffect(() => {
    if (user && user.id) fetchSettingsAndInfo()
  }, [user])

  return (
    <div className="client-preview-container profile">
      {isLoading && <SpinnerCenter />}
      <ClientProfilePreviewHeader percentage={clientInfo.completion} />
      <ClientProfilePreviewBody profile={clientInfo} />
    </div>
  )
}

export default ProfileClientPreviewContainer
