import { createSelector } from 'reselect'

import { IBillingsListElement, IBillingTableParams, IState } from './billings.types'
import { MODULE_NAME } from './billings.constants'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getBillingsListLoading = createSelector(selectState, (state: IState): boolean => state.billListLoading)

export const getBillingsList = createSelector(
  selectState,
  (state: IState): IBillingsListElement[] => state.billList.results
)

export const getTotalBillingsList = createSelector(selectState, (state: IState): number => state.billList.total)

export const getBillingsParams = createSelector(selectState, (state: IState): IBillingTableParams => state.params)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
