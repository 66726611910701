import { LogisticRate } from '../modules/brief/brief.types'

export const getLogisticRate = (quantity: number, logisticRates: LogisticRate[]) => {
  const logisticRate = logisticRates.find((rate: LogisticRate) => {
    if (!rate.max) {
      return
    }

    if (quantity > rate.min && quantity <= rate.max) {
      return rate
    }
  })

  return logisticRate ? logisticRate.rate : 0
}
