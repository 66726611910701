import React, { ReactElement, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import {
  Col,
  QBarChart,
  QLifetimeEarnings,
  QSelect,
  QUnfinishedProfile,
  Row,
  Tabs,
  Radio,
  RequestCardItem,
  CampaignsCardItem,
  Badge,
} from 'quantum_components'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { PATHS } from 'src/constants'
import { TABS_NAMES } from 'src/constants/tabs'
import { briefPaymentStatuses } from 'src/constants/brief'
import { useSelector } from 'react-redux'
import {
  getChartData,
  getChartParams,
  getLiveCampaigns,
  getOpenRequests,
  getParsedChartData,
  getStatisticsCards,
} from '../dashboard.selectors'
import { useReduxDispatch } from 'src/helpers'
import { actions } from '../dashboard.module'
import { getLocaleCost, renderRoundedNumber } from 'src/helpers/currency'
import { getCurrency, getCurrentUser } from 'src/modules/core/core.selectors'
import { getPartnerPartnerPercent } from 'src/modules/partner/partnerProfile/profile.selectors'
import RequestsTable from './components/RequestsTable'
import CampaignsTable from './components/CampaignsTable'

export default function Dashboard(): ReactElement {
  const dispatch = useReduxDispatch()
  const history = useHistory()
  const { completion, id } = useSelector(getCurrentUser)
  const partnerPercent = useSelector(getPartnerPartnerPercent)
  const statsCards = useSelector(getStatisticsCards)
  const { statistics } = useSelector(getChartData)
  const chartData = useSelector(getParsedChartData)
  const chartParams = useSelector(getChartParams)
  const dataRequests = useSelector(getOpenRequests)
  const dataLiveCampaigns = useSelector(getLiveCampaigns)
  const currency = useSelector(getCurrency)
  const [tab, setTab] = useState(TABS_NAMES.REQUESTS)

  useEffect(() => {
    if (!id) return
    dispatch(actions.fetchStatisticsCards())
    dispatch(actions.fetchChartData(chartParams))
    dispatch(actions.fetchOpenRequests())
    dispatch(actions.fetchLiveCampaigns())
  }, [id])

  const dataEarnings = {
    requests: {
      value: renderRoundedNumber(statsCards.openRequests.amount, currency),
      quantity: statsCards?.openRequests.count,
    },
    campaigns: {
      value: renderRoundedNumber(statsCards?.liveCampaigns.amount, currency),
      quantity: statsCards?.liveCampaigns.count,
    },
    total: renderRoundedNumber(statsCards?.lifetimeEarnings.amount, currency),
  }

  const selectOptions = [
    {
      label: '3 months',
      value: 3,
    },
    {
      label: '6 months',
      value: 6,
    },
    {
      label: '1 year',
      value: 12,
    },
  ]

  const handleChangeSelect = (e: number) => {
    dispatch(actions.fetchChartData({ period: e }))
  }

  const percent = partnerPercent ? partnerPercent : completion.partner
  return (
    <div className="dashboard-layout">
      <div className="dashboard-content">
        <h1 className="only-desktop">Dashboard</h1>
        <Row gutter={32}>
          {isMobile && percent < 100 && (
            <div className="mh-16">
              <QUnfinishedProfile onClick={() => history.push(PATHS.PARTNER_PROFILE)} />
            </div>
          )}
          <Col xs={24} sm={24} md={16}>
            <div className="qu-card-chart">
              <div className="chart-header">
                <QSelect
                  value={chartParams.period}
                  onChange={handleChangeSelect}
                  options={selectOptions}
                  placeholder="Select period"
                  size="large"
                />
                <ul className="qu-statistic-list">
                  <li className="qu-statistic-item">
                    <span className="qu-statistic-value">{getLocaleCost(statistics.impressions)}</span>
                    <span className="qu-statistic-name">Impressions</span>
                  </li>
                  <li className="qu-statistic-item">
                    <span className="qu-statistic-value">{statistics.requests}</span>
                    <span className="qu-statistic-name">Requests</span>
                  </li>
                  <li className="qu-statistic-item">
                    <span className="qu-statistic-value">{renderRoundedNumber(statistics.earnings, currency)}</span>
                    <span className="qu-statistic-name">Earnings</span>
                  </li>
                </ul>
              </div>
              <div className="chart-body">
                <QBarChart data={chartData} />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className="dashboard-aside">
              <QLifetimeEarnings
                onClickRequests={() => history.push(PATHS.REQUESTS)}
                onClickCampaigns={() => history.push(PATHS.PARTNER_CAMPAIGNS)}
                data={dataEarnings}
              />
            </div>
            {!isMobile && percent < 100 && <QUnfinishedProfile onClick={() => history.push(PATHS.PARTNER_PROFILE)} />}
          </Col>
        </Row>
        <div className="dashboard-tabs only-desktop">
          <Tabs
            defaultActiveKey="1"
            onChange={e => {
              if (e === '2') {
                ReactGA.event({
                  category: 'Dashboard',
                  action: 'Live Campaigns',
                  label: 'Live Campaigns',
                  value: 1,
                })
              }
            }}
          >
            <Tabs.TabPane
              tab={
                <div className="dashboard-tabs-title">
                  <span>Open Requests</span>
                  <Badge className="dashboard-tabs-badge" count={dataRequests.length} />
                </div>
              }
              key="1"
            >
              <RequestsTable />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div className="dashboard-tabs-title">
                  <span>Live Campaigns</span>
                  <Badge className="dashboard-tabs-badge" count={dataLiveCampaigns.length} />
                </div>
              }
              key="2"
            >
              <div className="dashboard-table-wrapper">
                <CampaignsTable />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="only-mobile">
          <Radio.Group
            value={tab}
            onChange={e => {
              setTab(e.target.value)
              if (e.target.value === TABS_NAMES.CAMPAIGNS) {
                ReactGA.event({
                  category: 'Dashboard',
                  action: 'Live Campaigns',
                  label: 'Live Campaigns',
                  value: 1,
                })
              }
            }}
            className="qu-radio-group full-width"
            buttonStyle="solid"
          >
            <Radio.Button className="qu-radio-button qu-radio-white mb-10" value={TABS_NAMES.REQUESTS}>
              Open Requests
              <Badge className="tab-badge" count={dataRequests.length} />
            </Radio.Button>
            <Radio.Button className="qu-radio-button qu-radio-white" value={TABS_NAMES.CAMPAIGNS}>
              Live Campaigns
              <Badge className="tab-badge" count={dataLiveCampaigns.length} />
            </Radio.Button>
          </Radio.Group>
          <ul className="qu-request-list">
            {tab === TABS_NAMES.REQUESTS
              ? dataRequests.map(data => {
                  return (
                    <RequestCardItem
                      key={data.key}
                      onClick={() => history.push(`/requests/${data.key}`)}
                      data={data}
                      paymentStatus={briefPaymentStatuses}
                    />
                  )
                })
              : dataLiveCampaigns.map(data => {
                  return (
                    <CampaignsCardItem
                      key={data.key}
                      onClick={() => history.push(`/campaigns/${data.key}`)}
                      //@ts-ignore
                      data={data}
                    />
                  )
                })}
          </ul>
          {/* <QButton className="qu-button-green partner-button full-width mv-30">
            <span className="mr-5">
              <IconsCommon.IconUser />
            </span>
            Refer a Partner
          </QButton> */}
        </div>
      </div>
    </div>
  )
}
