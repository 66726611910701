import { Store, AnyAction } from 'redux'
import { AxiosResponse } from 'axios'
import get from 'lodash/get'

import * as coreActions from 'src/modules/core/core.actions'
import { LOCAL_STORAGE_KEYS, PATHS } from './../constants'
import history from '../utils/history'
import localStorage from 'redux-persist/es/storage'
import { qnotification } from 'quantum_components'

import NOTIFICATION_TYPES from '../constants/notificationTypes'
import { getUpdateBillFilesLinksUrl, getUpdatePartnerBillFilesLinksUrl } from './urls'
import i18n from 'src/i18n'

/* eslint-disable @typescript-eslint/no-explicit-any */
type Interceptor = (error: any) => any

const serverSuccessInterceptor =
  (): Interceptor =>
    async (response: AxiosResponse): Promise<AxiosResponse | void> => {
      if (window.location.pathname != '/signup/client' && window.location.pathname != '/signup/partner') {
        const updateBillUrls = [getUpdateBillFilesLinksUrl(), getUpdatePartnerBillFilesLinksUrl()]
        if (!updateBillUrls.includes(response.config.url ?? '') && response.data.data?.message) {
          qnotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: i18n.t("push-notifications." + response.data.data.message, response.data.data.message, { ns: 'translationCommon' }),
          })
        }
      }

      return Promise.resolve(response)
    }

const serverErrorInterceptor =
  (store: Store<any, AnyAction>): Interceptor =>
    async (error: AxiosResponse): Promise<AxiosResponse | void> => {
      const token = await localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
      // if (error.status === 440 && !token) {
      //   return Promise.reject(error)
      // }

      if (error.status >= 500) {
        qnotification({
          type: NOTIFICATION_TYPES.ERROR,
          message: error.status,
          description: i18n.t("push-notifications.Something went wrong", { ns: 'translationCommon' })
        })
        return Promise.reject(error)
      }

      if (
        error?.config?.url?.includes('brief/final-approve') &&
        error?.status === 409 &&
        error?.data?.error?.payload?.invalid
      ) {
      } else {
        qnotification({
          type: NOTIFICATION_TYPES.ERROR,
          message: error.data.error?.message
            ? i18n.t("push-notifications." + error.data.error?.message, error.data.error?.message, { ns: 'translationCommon' })
            : i18n.t("push-notifications.Something went wrong", { ns: 'translationCommon' })
        })
      }

      if (get(error, 'config.skipError', false)) return Promise.reject(error)

      const statusesNoErrors = get(error, 'config.statusesNoErrors', [])

      if (statusesNoErrors.includes(error.status)) return Promise.reject(error)

      if (error.status === 401 || error.status === 403 || error.status === 440) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
        store.dispatch(coreActions.logoutSuccess())
        history.push(PATHS.SIGN_IN)
        return Promise.reject(error)
      }

      const isServerError = error.status > 300

      if (!isServerError) return Promise.reject(error)

      const isHtmlResponseError = get(error, 'headers.content-type', '').includes('text/html')
      const errorType = isHtmlResponseError ? 'html' : 'text'

      store.dispatch(
        coreActions.setServerError(get(error, 'data.message') || JSON.stringify(error.data) || String(error), errorType)
      )

      return error
    }
/* eslint-enable @typescript-eslint/no-explicit-any */

export { serverSuccessInterceptor, serverErrorInterceptor }
