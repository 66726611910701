import { IServerError, IState } from './core.types'
import * as CONSTANTS from './core.constants'
import IAction from 'src/interfaces/IAction'
import get from 'lodash/get'
import ROLES from '../../constants/roles'
import { CURRENCY } from '../../constants/currencies'

export const initialState: IState = {
  saasUser:{},
  isAuthenticated: false,
  showProgress: false,
  progress: 0,
  isLoggingOut: false,
  isLoggingIn: false,
  isUploading: false,
  isDownloading: false,
  fileUrls: [],
  uploadError: '',
  downloadError: '',
  currency: CURRENCY.SAR,
  currencyError: '',
  serverError: null,
  settings: {
    currencies: [],
  },
  coreSettings: null,
  coreConstants: null,
  settingsLoading: false,
  constantsLoading: false,
  currentUser: {
    id: null,
    paymentEnabled:null,
    bankTransfer: null,
    purchaseOrder: null,
    onlinePayment: null,
    firstName: '',
    lastName: '',
    companyName: '',
    companyWebsite: '',
    partnerProfileId:null,
    partnerUserId:null,
    phone: '',
    email: '',
    username:'',
    role: ROLES.UNKNOWN,
    status: '',
    isBanned: '',
    profilePicture: '',
    isApproved: false,
    completion: { public: 0, partner: 0, client: 0 },
    openRequests: 0,
    isReferralEmailSent: false,
    unreadNotificationCount: 0,
    partnerApiAccess: null,
    socialLoginType:null,
    partnerData:{
      bankDetails:{},
      name:'',
      logoTransparent:'',
      vat:'',
      taxTreatment:'',
      logo:''
    }
  },
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_PROGRESS:
      return { ...state, showProgress: initialState.showProgress, progress: initialState.progress }
    case CONSTANTS.SET_PROGRESS:
      return { ...state, showProgress: true, progress: get(action, 'payload.progress') }
    case CONSTANTS.LOGOUT_REQUEST:
      return { ...state, isLoggingOut: true }
    case CONSTANTS.LOGOUT_SUCCESS:
      return initialState
    case CONSTANTS.LOGOUT_FAILURE:
      return { ...state, isLoggingOut: false }
    case CONSTANTS.SET_SERVER_ERROR:
      return { ...state, serverError: action.payload as IServerError }
    case CONSTANTS.RESET_SERVER_ERROR:
      return { ...state, serverError: initialState.serverError }
    case CONSTANTS.UPLOAD_REQUEST:
      return { ...state, isUploading: true }
    case CONSTANTS.UPLOAD_SUCCESS:
      // @ts-ignore
      return { ...state, isUploading: false, fileUrls: [...state.fileUrls, ...get(action, 'payload.fileUrls')] }
    case CONSTANTS.UPLOAD_FAILURE:
      return { ...state, isUploading: false, uploadError: action.error }
    case CONSTANTS.DOWNLOAD_REQUEST:
      return { ...state, isDownloading: true }
    case CONSTANTS.DOWNLOAD_SUCCESS:
      return { ...state, isDownloading: false }
    case CONSTANTS.DOWNLOAD_FAILURE:
      return { ...state, isDownloading: false, downloadError: action.error }
      case CONSTANTS.SAAS_REQUEST:
        return { ...state, saasUser:{} }
      case CONSTANTS.SAAS_SUCCESS:
        return { ...state, saasUser:action.payload }
      case CONSTANTS.SAAS_FAILURE:
        return { ...state, serverError: action.error }
    case CONSTANTS.FETCH_CURRENCIES_SUCCESS:
      return { ...state, currency: get(action, 'payload.currency') }
    case CONSTANTS.FETCH_CURRENCIES_FAILURE:
      return { ...state, currencyError: action.error }
    case CONSTANTS.GET_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: { ...state.currentUser, ...action.payload } }
    case CONSTANTS.GET_CURRENT_USER_FAILURE:
      return { ...state, currentUser: initialState.currentUser }
    case CONSTANTS.FETCH_SETTINGS_REQUEST:
      return { ...state, settingsLoading: true }
    case CONSTANTS.FETCH_SETTINGS_SUCCESS:
      return { ...state, settingsLoading: false, coreSettings: get(action, 'payload.settings') }
    case CONSTANTS.FETCH_SETTINGS_FAILURE:
      return { ...state, settingsLoading: false }
    case CONSTANTS.FETCH_CONSTANTS_REQUEST:
      return { ...state, constantsLoading: true }
    case CONSTANTS.FETCH_CONSTANTS_SUCCESS:
      return {
        ...state,
        coreConstants: get(action, 'payload.constants'),
        constantsLoading: false,
      }
    case CONSTANTS.FETCH_CONSTANTS_FAILURE:
      return { ...state, constantsLoading: false }

    default:
      return state
  }
}
