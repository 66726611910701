import React from 'react'
import { Row, Col, Radio } from 'quantum_components'

interface Props {
  onChange: (v: any) => void
  value: boolean
}
const TestahelBriefSwitch = ({ onChange, value }: Props) => {
  return (
    <Radio.Group
      className="qu-radio-panel qu-radio-group full-width mt-10 ph-10"
      defaultValue={value}
      onChange={onChange}
      buttonStyle="solid"
    >
      <Radio.Button className="qu-radio-button qu-radio-white" value={false}>
        <span className="fw-500">Brief</span>
      </Radio.Button>
      <Radio.Button className="qu-radio-button qu-radio-white" value={true}>
        <span className="fw-500">Partners</span>
      </Radio.Button>
    </Radio.Group>
  )
}

export default TestahelBriefSwitch
