import React, { ReactElement } from 'react'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'quantum_components'
import { IBriefPartnerDetails, IPartnerDetailsCountry } from '../../modules/brief/brief.types'
import { getBriefSettings } from 'src/modules/brief/brief.selectors'
import { useSelector } from 'react-redux'
import { LOCALIZATION_LANGUAGES } from 'src/constants'

interface Props {
  details: IBriefPartnerDetails
}
export default function DetailsAbout({ details }: Props): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationMarketplace', 'translationCommon'])

  const briefSettings = useSelector(getBriefSettings)

  const highlightsAndMetric = [...details.highlights, ...details.metricsMarketing]

  const getCities = (cities: string[]) => {
    let settings = details?.countries?.length ? details?.countries[0].city : briefSettings?.countries.length && briefSettings?.countries[0].city;

    let cities_ = cities.map(city => {
      let obj = lodash.first(lodash.filter(settings, (s) => s.name === city))
      return i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? lodash.get(obj, 'name_ar') : city
    })

    return cities_.join(', ')
  }

  return (
    <div className="qu-details-list mb-35">
      <div className="list-item">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6}>
            <div className="list-item-title">{t('marketplace.Locations')}</div>
          </Col>
          <Col xs={24} sm={24} md={18}>
            {details.locations?.map((location: IPartnerDetailsCountry) => (
              <div key={location.name} className="list-item-row">
                <div className="list-item-label">
                  {
                    briefSettings.countries.find(ele => ele.name === location.name)
                      ? briefSettings.countries.find(ele => ele.name === location.name).name_ar
                      : location.name
                  }
                </div>
                <div className="list-item-value">
                  {location.cities.length ? getCities(location.cities) : t('common.none', { ns: 'translationCommon' })}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </div>
      {details.highlights && (
        <div className="list-item">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6}>
              <div className="list-item-title">{t('marketplace.Highlights')}</div>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <div className="pills-container">
                {details.highlights.map((highlight: string, index) => {
                  return <p className="pill" key={index}>{highlight}</p>
                })}
              </div>
              {/* <ul className="list-item-column">
                {details.highlights?.map((highlight: string, i: number) => (
                  <li key={i}>
                    <span>{highlight}</span>
                  </li>
                ))}
              </ul> */}
            </Col>
          </Row>
        </div>
      )}
      {details.metricsMarketing && (
        <div className="list-item">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6}>
              <div className="list-item-title">{t('marketplace.Metrics')}</div>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <div className="pills-container">
                {details.metricsMarketing.map((metric: string, i: number) => {
                  return <p className="pill" key={i}>{metric}</p>
                })}
              </div>
              {/* <ul className="list-item-column">
                {details.highlights?.map((highlight: string, i: number) => (
                  <li key={i}>
                    <span>{highlight}</span>
                  </li>
                ))}
              </ul> */}
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}
