import React, { ReactElement } from 'react'
import { Col, QSelectItem, Row } from 'quantum_components'

interface IProps {
  checked: boolean
  onChange: (e: MouseEvent, yes: boolean) => void
  title: string
}

export default function BriefDialog({ checked, onChange, title }: IProps): ReactElement {
  const isMediaBuying = title === 'Media Buying'
  return (
    <div className="qu-brief-content brief-step brief-step-0">
      <div className="brief-step-header">
        <h1>{`Would you like to add ${title} service?`}</h1>
        {isMediaBuying && <p>Select if you would like to digitally advertise your product</p>}
      </div>
      <Row gutter={16} justify="center">
        <Col xs={12} md={4}>
          <QSelectItem
            checked={checked}
            onChange={(e: MouseEvent) => onChange(e, true)}
            className="text-card full-width"
            cardImage={<h2>Yes</h2>}
            label={`Yes, add ${title}`}
          />
        </Col>
        <Col xs={12} md={4}>
          <QSelectItem
            checked={!checked}
            onChange={(e: MouseEvent) => onChange(e, false)}
            className="text-card full-width"
            cardImage={<h2>No</h2>}
            label={`No, don’t add ${title}`}
          />
        </Col>
      </Row>
    </div>
  )
}
