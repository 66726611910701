import { REGEXES } from "src/constants"

export const hasErrorFields = (object: any, fieldsToSkip: string[], requiredFields: string[] = []) => {
  let hasError = false
  for (const [key, value] of Object.entries(object)) {
    if (fieldsToSkip.includes(key) && !requiredFields.includes(key)) {
      continue
    }

    // @ts-ignore
    if (key === 'companyWebsite' && value && !RegExp(REGEXES.URL).test(value)) {
      hasError = true;
      break;
    }

    if (Array.isArray(value) && (!value.length || (requiredFields.includes(key) && value.some(v => !v.length)))) {
      hasError = true
      break
    }

    //@ts-ignore
    if (Number.isInteger(value) && value < 0) {
      hasError = true
      break
    }
    //@ts-ignore
    if (value === null || value === undefined || (requiredFields.includes(key) && !value.length)) {
      hasError = true
      break
    }

    if (key === 'customizeBoxDistributionCount' && (!Number.isInteger(value) || value === 0)) {
      hasError = true
      break
    }

    if ((key === 'quantity' || key === 'servicePackagingQuantity') && (!Number.isInteger(value) || value === 0)) {
      hasError = true
      break
    }

    // @ts-ignore
    if (key === 'cr' && value && !RegExp(REGEXES.TEN_DIGIT).test(value)) {
      hasError = true;
      break;
    }

    // @ts-ignore
    if (key === 'vat' && value && !RegExp(REGEXES.FIFTEEN_DIGIT).test(value)) {
      hasError = true;
      break;
    }
  }

  return hasError
}
