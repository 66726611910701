import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { IResetPasswordResponse } from './resetPassword.types'
import * as CONSTANTS from './resetPassword.constants'
import { getResponseErrorMessage } from 'src/helpers'
import { PATHS } from '../../../constants'
import history from '../../../utils/history'

export const resetPasswordRequest = (): IAction => ({
  type: CONSTANTS.RESET_PASSWORD_REQUEST,
})

export const resetPasswordSuccess = (): IAction => ({
  type: CONSTANTS.RESET_PASSWORD_SUCCESS,
})

export const resetPasswordFailure = (error: string): IAction => ({
  type: CONSTANTS.RESET_PASSWORD_FAILURE,
  error,
})

const encodeStringToBase64 = (string) => Buffer.from(string).toString('base64');

export const resetPassword = (
  email: string
): ThunkAction<Promise<AxiosResponse<IResetPasswordResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IResetPasswordResponse>> => {
  try {
    dispatch(resetPasswordRequest())
    const response = await requestHttp.post<IResetPasswordResponse>(urls.getResetPasswordUrl(), { email })
    // dispatch(resetPasswordSuccess())
    history.push(`${PATHS.RESTORE_PASSWORD}?es=${encodeStringToBase64(email)}`)

    return response
  } catch (error: any) {
    dispatch(resetPasswordFailure(getResponseErrorMessage(error)))

    return error
  }
}

// export const confirmResetPassword = (
//   confirmationCode: string
// ): ThunkAction<Promise<AxiosResponse<IResetPasswordResponse>>, {}, {}, AnyAction> => async (
//   dispatch: ThunkDispatch<{}, {}, AnyAction>
// ): Promise<AxiosResponse<IResetPasswordResponse>> => {
//   try {
//     dispatch(resetPasswordRequest())
//     const response = await requestHttp.post<IResetPasswordResponse>(urls.getConfirmResetPasswordUrl(), { confirmationCode })
//     dispatch(resetPasswordSuccess())
//     history.push(PATHS.RESET_PASSWORD_CONFIRM)

//     return response
//   } catch (error: any) {
//     dispatch(resetPasswordFailure(getResponseErrorMessage(error)))

//     return error
//   }
// }
