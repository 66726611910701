import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux'
import { requestHttp, urls } from 'src/api'

export const getCoBrandingData = (hashId: string): ThunkAction<void, {}, {}, AnyAction> => async (): Promise<[]> => {
  try {
    const response = await requestHttp.get(urls.getCoBrandingDataUrl(hashId))
    return response?.data?.data?.content ? response.data.data.content : null
  } catch (error: any) {
    return error
  }
}

export const getInviterSpecialToken = (): ThunkAction<void, {}, {}, AnyAction> => async (): Promise<[]> => {
  try {
    const response = await requestHttp.get(urls.getInviterSpecialTokenUrl())
    return response.data.data.content
  } catch (error: any) {
    return error
  }
}

export const getInviterBriefDetails = (payload: any): ThunkAction<void, {}, {}, AnyAction> => async (): Promise<
  any
> => {
  try {
    const response = await requestHttp.post(urls.getInviterBriefDetailsUrl(), payload)
    return response.data.data.content
  } catch (error: any) {
    return error
  }
}

export const deleteBrief = (id: number): ThunkAction<void, {}, {}, AnyAction> => async (): Promise<any> => {
  try {
    const response = await requestHttp.delete(urls.getDeleteBriefUrl(id))
    return response.data.status
  } catch (error: any) {
    return error
  }
}

export const getVerifyInviteClient = (hashId: string): ThunkAction<void, {}, {}, AnyAction> => async (): Promise<[]> => {
  try {
    const response = await requestHttp.get(urls.getVerifyInviteClientUrl(hashId))
    return response?.data?.data?.content
  } catch (error: any) {
    return error
  }
}

