import { CAMPAIGN_NAME_MAX, CAMPAIGN_NAME_MIN, PRODUCT_DESCRIPTION_MAX, PRODUCT_DESCRIPTION_MIN, PRODUCT_NAME_MAX, PRODUCT_NAME_MIN } from './brief'

// export const VALIDATION_MESSAGE = {
//   DISTRIBUTION_TYPE: 'Should be at least one distribution item.',
//   PRODUCT_NAME: 'Please enter the name of your product.',
//   PRODUCT_DESCRIPTION: 'Please enter the description of your product.',
//   CAMPAIGN_NAME: 'Please enter the name of your campaign.',
//   PRODUCT_NAME_MIN_MAX: `Product name should have min ${PRODUCT_NAME_MIN}, max ${PRODUCT_NAME_MAX} characters.`,
//   PRODUCT_DESCRIPTION_MIN_MAX: `Product description should have min ${PRODUCT_DESCRIPTION_MIN}, max ${PRODUCT_DESCRIPTION_MAX} characters.`,
//   CAMPAIGN_NAME_MIN_MAX: `Product name should have min ${CAMPAIGN_NAME_MIN}, max ${CAMPAIGN_NAME_MAX} characters.`,
//   MIN_QUANTITY: 'Minimum quantity is 1 and should have no decimals.',
//   OTHER_PRODUCT_TYPE: 'Please enter other product type.',
//   DURATION: 'Please choose duration.',
//   ADDITIONAL_INSTRUCTION: 'Additional instruction should have max 500 characters.',
//   WEBSITE_URL: 'Please enter correct website URL',
//   EMAIL: 'Please enter correct email address.',
//   DUPLICATE_EMAIL: 'Email addresses should be unique.',
//   PASSWORD: 'Should contain at least 7 characters including a number, an uppercase letter and a lowercase letter',
//   CUSTOMIZE_BOX_NAME: 'eg. Ramadan Box, Back-to-school Box',
//   CUSTOMIZE_BOX_NAME_MIN_MAX: `Name should have min ${PRODUCT_NAME_MIN}, max ${PRODUCT_NAME_MAX} characters.`,
// }

export const VALIDATION_MESSAGE = {
  DISTRIBUTION_TYPE: 'brief.validation.should_be_at_least',
  PRODUCT_NAME: 'brief.validation.name_of_your_product',
  PRODUCT_DESCRIPTION: 'brief.validation.description_of_your_product',
  CAMPAIGN_NAME: 'brief.validation.name_of_your_campaign',
  CAMPAIGN_NAME_MIN_MAX: 'brief.validation.campaign_name_min_max',
  PRODUCT_NAME_MIN_MAX: 'brief.validation.product_name_min_max',
  PRODUCT_DESCRIPTION_MIN_MAX: 'brief.validation.product_description_min_max',
  MIN_QUANTITY: 'brief.validation.minimum_quantity_is_1',
  OTHER_PRODUCT_TYPE: 'brief.validation.other_product_type',
  DURATION: 'brief.validation.please_choose_duration',
  ADDITIONAL_INSTRUCTION: 'brief.validation.additional_instruction_max_500',
  WEBSITE_URL: 'brief.validation.website_url_including_https',
  EMAIL: 'brief.validation.correct_email_addres',
  DUPLICATE_EMAIL: 'brief.validation.email_addresses_unique',
  PASSWORD: 'brief.validation.password_validation',
  PHONE_NUMBER: 'brief.validation.phone_number',
  CUSTOMIZE_BOX_NAME: 'brief.validation.eg_box',
  CUSTOMIZE_BOX_NAME_MIN_MAX: 'brief.validation.customize_product_name_min_max',
}