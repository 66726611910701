import { MEDIA_TYPES } from '../../constants/brief'

const MODULE_NAME = 'campaigns'
const CLIENT = 'client'
const PARTNER = 'partner'

export const getClientCampaignsUrl = (): string => `${CLIENT}/${MODULE_NAME}`
export const getClientCampaignDetailsUrl = (campaignId: number): string => `${CLIENT}/${MODULE_NAME}/view/${campaignId}`
export const getPartnerCampaignsUrl = (): string => `${PARTNER}/${MODULE_NAME}`
export const getPartnerCampaignDetailsUrl = (campaignId: number): string =>
  `${PARTNER}/${MODULE_NAME}/view/${campaignId}`

export const getClientCampaignAnalyticsInfoUrl = (campaignId: number): string =>
  `${CLIENT}/edit-graph/${campaignId}/info`
export const getClientCampaignAnalyticsDataUrl = (campaignId: number): string =>
  `${CLIENT}/edit-graph/${campaignId}/filter`
export const getClientCampaignTimelineUrl = (campaignId: number): string =>
  `${CLIENT}/${MODULE_NAME}/${campaignId}/timeline/list`
export const getClientCampaignAnalyticsMediaBuyDataUrl = (campaignId: number): string =>
  `${CLIENT}/${MODULE_NAME}/${campaignId}/media-buy/graph`
export const getClientGalleryUrl = (campaignId: number): string => `${CLIENT}/gallery/${campaignId}/all`
export const getClientGalleryDownloadUrl = (): string => `${CLIENT}/gallery/download-page`

export const getClientSurveysUrl = (campaignId: number): string => `${CLIENT}/surveys/export/${campaignId}`

export const getPartnerCampaignAnalyticsInfoUrl = (campaignId: number): string =>
  `${PARTNER}/edit-graph/${campaignId}/info`
export const getPartnerCampaignAnalyticsDataUrl = (campaignId: number): string =>
  `${PARTNER}/edit-graph/${campaignId}/filter`
export const getPartnerCampaignTimelineUrl = (campaignId: number): string =>
  `${PARTNER}/${MODULE_NAME}/${campaignId}/timeline/list`
export const getPartnerCampaignAnalyticsMediaBuyDataUrl = (campaignId: number, mediaType: string): string =>
  `${PARTNER}/${MODULE_NAME}/${campaignId}/media-buy/${mediaType}/graph`

export const getCampaignReportDetailsUrl = (id: number, startDate: string, endDate: string): string => `${CLIENT}/report/${id}?startDate=${startDate}&endDate=${endDate}`
