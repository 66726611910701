import React, { ReactElement, useEffect, useState } from 'react'
import { IconsCommon, Popover, QTable } from 'quantum_components'
import {
  IAvailableServiceItem,
  IBriefPartnerDetails,
  IPartnerDetailsService,
  IServiceItemPricing,
  MediaSubtype,
} from '../../modules/client/testahelBox/testahelBox.types'
import {
  BRIEF_STEPS,
  DISTRIBUTION_TYPE,
  MEDIA_TYPES,
  MEDIA_TYPES_DURATION,
  BRIEF_TYPES,
  BANNERS_BY_MEDIA_TYPE,
  CONSUMER_RESEARCH_MEDIA_TYPES,
} from '../../constants/testahel_brief'
import { useSelector } from 'react-redux'
import { getCurrency, getCurrentUser } from '../../modules/core/core.selectors'
import { getLocaleCostWithoutCurrencyNDecimals, renderRoundedNumber } from '../../helpers/currency'
import { getBrief, getPartnerFilterSettings } from '../../modules/client/testahelBox/testahelBox.selectors'
import { REQUEST_STATUS } from '../../constants/request'
import { getPartnerProfileSettings } from 'src/modules/partner/partnerProfile/profile.selectors'
import { MEDIA_TYPES_NAMES_FOR_PRICE } from 'src/modules/partner/partnerProfile/profile.types'
import { getDuration } from '../../helpers/mediaTypes'
import { isInsert, isSample, onlyDistribution } from '../../helpers/testahelBrief'
import { getPartnerLocked } from 'src/modules/client/marketplace/marketplace.selectors'
import { PRICING_MODEL } from 'src/constants/profile'
import { cloneDeep } from 'lodash'

interface Props {
  details: IBriefPartnerDetails
  selectedServices: IAvailableServiceItem[]
  onChangeServices: (selected: IAvailableServiceItem[]) => void
  editMode: boolean
  showModal?: () => void
  isBrief?: boolean
}

export default function TestahelDetailsServicesTable({
  details,
  selectedServices,
  onChangeServices,
  editMode,
  showModal,
  isBrief,
}: Props): ReactElement {
  const filterSettings = useSelector(getPartnerFilterSettings)
  const partnerProfileSettings = useSelector(getPartnerProfileSettings)
  const brief = useSelector(getBrief)
  const mediaSubTypes = filterSettings.mediaSubtypes.length
    ? filterSettings.mediaSubtypes
    : partnerProfileSettings.mediaSubtypes
  const currency = useSelector(getCurrency)
  const partnerLocked = useSelector(getPartnerLocked)
  const currentUser = useSelector(getCurrentUser)

  const [imagesLoading, setImagesLoading] = useState<boolean>(false)
  const onlyInsert = isBrief ? isInsert(brief[BRIEF_STEPS.PRODUCT].distributionItem) : true
  const onlySample = isBrief ? isSample(brief[BRIEF_STEPS.PRODUCT].distributionItem) : true
  const isOnlyDistribution = onlyDistribution(brief[BRIEF_STEPS.TYPE].briefTypes)

  const [availableServices, setAvailableServices] = useState<IPartnerDetailsService[]>([])
  const [seeMore, setSeeMore] = useState<number>(0)
  const [columns, setColumns] = useState<any>([])

  useEffect(() => {
    async function calculateMyData() {
      if (isBrief) {
        if (
          brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING) ||
          brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)
        ) {
          let localAvailableServices: any = []

          if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)) {
            details.availableServices.filter(ele => {
              const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
              const targetService = selectedServices.find((s: IAvailableServiceItem) => s.key === name)
              targetService && localAvailableServices.push(ele)
            })

            details.availableServices.filter(ele => {
              if (brief.BRIEF_MEDIA_BUY.mediaTypes.includes(ele.serviceName) && !localAvailableServices.includes(ele))
                localAvailableServices.push(ele)
            })
          }

          if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)) {
            details.availableServices.filter(ele => {
              const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
              if (
                (name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY || name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE) &&
                !localAvailableServices.includes(ele)
              ) {
                localAvailableServices.push(ele)
              }
            })
          }
          setAvailableServices(localAvailableServices)

          if (details.availableServices.length > localAvailableServices.length) setSeeMore(1)
          else if (details.availableServices.length < localAvailableServices.length) setSeeMore(2)
          else setSeeMore(0)

          if (
            !brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE) &&
            !brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)
          ) {
            setSeeMore(0)
          }
        }
      } else {
        setAvailableServices(details.availableServices)
      }
    }

    calculateMyData()
  }, [])

  const seeMoreLess = async (isSeeMore: boolean) => {
    if (isSeeMore) {
      if (
        brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING) ||
        brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)
      ) {
        let localAvailableServices: any = []

        if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)) {
          details.availableServices.filter(ele => {
            const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
            const targetService = selectedServices.find((s: IAvailableServiceItem) => s.key === name)
            targetService && localAvailableServices.push(ele)
          })

          details.availableServices.filter(ele => {
            if (brief.BRIEF_MEDIA_BUY.mediaTypes.includes(ele.serviceName) && !localAvailableServices.includes(ele))
              localAvailableServices.push(ele)
          })

          details.availableServices.filter(ele => {
            if (!localAvailableServices.includes(ele)) localAvailableServices.push(ele)
          })
        }

        if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)) {
          details.availableServices.filter(ele => {
            const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
            if (
              (name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY || name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE) &&
              !localAvailableServices.includes(ele)
            ) {
              localAvailableServices.push(ele)
            }
          })
        }

        setAvailableServices(localAvailableServices)

        setSeeMore(2)
      } else {
        let localAvailableServices: any = cloneDeep(availableServices)

        details.availableServices.filter(ele => {
          const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
          if (
            (name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY || name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE) &&
            !localAvailableServices.includes(ele)
          ) {
            localAvailableServices.push(ele)
          }
        })

        setAvailableServices(localAvailableServices)
      }
    } else {
      if (
        brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING) ||
        brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)
      ) {
        let localAvailableServices: any = []

        if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)) {
          details.availableServices.filter(ele => {
            const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
            const targetService = selectedServices.find((s: IAvailableServiceItem) => s.key === name)
            targetService && localAvailableServices.push(ele)
          })

          details.availableServices.filter(ele => {
            if (brief.BRIEF_MEDIA_BUY.mediaTypes.includes(ele.serviceName) && !localAvailableServices.includes(ele))
              localAvailableServices.push(ele)
          })
        }

        if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)) {
          details.availableServices.filter(ele => {
            const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
            if (
              (name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY || name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE) &&
              !localAvailableServices.includes(ele)
            ) {
              localAvailableServices.push(ele)
            }
          })
        }

        setAvailableServices(localAvailableServices)

        setSeeMore(1)
      } else {
        let localAvailableServices: any = cloneDeep(availableServices)

        details.availableServices.filter(ele => {
          const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
          if (
            (name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY || name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE) &&
            !localAvailableServices.includes(ele)
          ) {
            localAvailableServices.push(ele)
          }
        })

        setAvailableServices(localAvailableServices)
      }
    }

    servicesData()
  }

  useEffect(() => {
    getImages(details.availableServices)
  }, [details])

  useEffect(() => {
    async function calculateColumns() {
      if (details.mbPricingModel === PRICING_MODEL.DURATION) {
        setColumns([
          {
            title: 'PRODUCT',
            dataIndex: 'product',
            render: (text: string, record: IAvailableServiceItem) => {
              const rejected = record.requestStatus === REQUEST_STATUS.REJECTED

              const handleClick = () => {
                if (!record.blocked || !showModal) return
                showModal()
              }

              return record.type === MEDIA_TYPES.DISTRIBUTION ? (
                <div className="cursorPointer" onClick={handleClick}>
                  <span className="cell-services">{text.replace(/CR /g, '')}</span>
                  {rejected && <span className="text-red font-size-12 ml-5">(rejected)</span>}
                </div>
              ) : (
                <Popover
                  overlayClassName="qu-banner-popover popover-padded"
                  content={contentMedia(record)}
                  placement="right"
                  getPopupContainer={(node: any) => node.parentNode}
                >
                  <div className="cursorPointer" onClick={handleClick}>
                    <span className="cell-services">{text.replace(/CR /g, '')}</span>
                    {rejected && <span className="text-red font-size-12 ml-5">(rejected)</span>}
                  </div>
                </Popover>
              )
            },
          },
          {
            title: 'Type',
            dataIndex: 'type',
            render: (type: MEDIA_TYPES) => (
              <div className="cell-type">
                {type === MEDIA_TYPES.DISTRIBUTION ? <IconsCommon.IconSample /> : <IconsCommon.IconDevices />}
                <span>{type}</span>
              </div>
            ),
          },
          {
            title: 'Pricing',
            dataIndex: 'pricing',
            render: (pricing: IServiceItemPricing, record: IAvailableServiceItem) =>
              record.type === MEDIA_TYPES.DISTRIBUTION ? (
                <div className={`cell-pricing ${partnerLocked && !currentUser.isApproved ? 'cell-pricing-blur' : ''}`}>
                  {renderRoundedNumber(pricing.amount, pricing.currency)}
                  <span>{pricing.duration}</span>
                </div>
              ) : (
                <Popover
                  overlayClassName="qu-banner-popover popover-padded"
                  content={contentMedia(record)}
                  placement="right"
                  getPopupContainer={(node: any) => node.parentNode}
                >
                  <div
                    className={`cell-pricing ${partnerLocked && !currentUser.isApproved ? 'cell-pricing-blur' : ''}`}
                  >
                    {renderRoundedNumber(pricing.amount, pricing.currency)}
                    <span>{pricing.duration}</span>
                  </div>
                </Popover>
              ),
          },
        ])
      } else if (details.mbPricingModel === PRICING_MODEL.METRICS) {
        setColumns([
          {
            title: 'PRODUCT',
            dataIndex: 'product',
            render: (text: string, record: IAvailableServiceItem) => {
              const rejected = record.requestStatus === REQUEST_STATUS.REJECTED

              const handleClick = () => {
                if (!record.blocked || !showModal) return
                showModal()
              }

              return record.type === MEDIA_TYPES.DISTRIBUTION ? (
                <div className="cursorPointer" onClick={handleClick}>
                  <span className="cell-services">{text.replace(/CR /g, '')}</span>
                  {rejected && <span className="text-red font-size-12 ml-5">(rejected)</span>}
                </div>
              ) : (
                <Popover
                  overlayClassName="qu-banner-popover popover-padded"
                  content={contentMedia(record)}
                  placement="right"
                  getPopupContainer={(node: any) => node.parentNode}
                >
                  <div className="cursorPointer" onClick={handleClick}>
                    <span className="cell-services">{text.replace(/CR /g, '')}</span>
                    {rejected && <span className="text-red font-size-12 ml-5">(rejected)</span>}
                  </div>
                </Popover>
              )
            },
          },
          {
            title: 'Type',
            dataIndex: 'type',
            render: (type: MEDIA_TYPES) => (
              <div className="cell-type">
                {type === MEDIA_TYPES.DISTRIBUTION ? <IconsCommon.IconSample /> : <IconsCommon.IconDevices />}
                <span>{type}</span>
              </div>
            ),
          },
          {
            title: 'Pricing',
            dataIndex: 'pricing',
            render: (pricing: IServiceItemPricing, record: IAvailableServiceItem) =>
              record.type === MEDIA_TYPES.DISTRIBUTION ? (
                <div className={`cell-pricing ${partnerLocked && !currentUser.isApproved ? 'cell-pricing-blur' : ''}`}>
                  {renderRoundedNumber(pricing.amount, pricing.currency)}
                  <span>{pricing.duration}</span>
                </div>
              ) : (
                <div>-</div>
              ),
          },
          {
            title: 'Daily Impression',
            dataIndex: 'serviceDailyImpressions',
            render: (serviceDailyImpressions: any, record: IAvailableServiceItem) =>
              record.type !== MEDIA_TYPES.DISTRIBUTION && (
                <div className={`cell-pricing ${partnerLocked && !currentUser.isApproved ? 'cell-pricing-blur' : ''}`}>
                  {serviceDailyImpressions ? getLocaleCostWithoutCurrencyNDecimals(serviceDailyImpressions) : '-'}
                  <br />
                  {record.details?.serviceCostPerImpression && (
                    <p>
                      CPM <span>{renderRoundedNumber(record.details?.serviceCostPerImpression, currency)}</span>
                    </p>
                  )}
                </div>
              ),
          },
          {
            title: 'Daily Click',
            dataIndex: 'serviceDailyClicks',
            render: (serviceDailyClicks: any, record: IAvailableServiceItem) =>
              record.type !== MEDIA_TYPES.DISTRIBUTION && (
                <div className={`cell-pricing ${partnerLocked && !currentUser.isApproved ? 'cell-pricing-blur' : ''}`}>
                  {serviceDailyClicks ? getLocaleCostWithoutCurrencyNDecimals(serviceDailyClicks) : '-'}
                  <br />
                  {record.details?.serviceCostPerClick && (
                    <p>
                      CPC <span>{renderRoundedNumber(record.details?.serviceCostPerClick, currency)}</span>
                    </p>
                  )}
                </div>
              ),
          },
          {
            title: 'Daily Reach',
            dataIndex: 'serviceDailyReach',
            render: (serviceDailyReach: any, record: IAvailableServiceItem) =>
              record.type !== MEDIA_TYPES.DISTRIBUTION && (
                <div className={`cell-pricing ${partnerLocked && !currentUser.isApproved ? 'cell-pricing-blur' : ''}`}>
                  {serviceDailyReach ? getLocaleCostWithoutCurrencyNDecimals(serviceDailyReach) : '-'}
                  <br />
                  {record.details?.serviceCostPerReach && (
                    <p>
                      CPR <span>{renderRoundedNumber(record.details?.serviceCostPerReach, currency)}</span>
                    </p>
                  )}
                </div>
              ),
          },
        ])
      }
    }

    calculateColumns()
  }, [details.mbPricingModel, brief])

  useEffect(() => {
    if (
      !brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE) &&
      brief.BRIEF_MEDIA_BUY.marketingObjective &&
      columns[2]?.title === 'Pricing' &&
      details.mbPricingModel === PRICING_MODEL.METRICS
    ) {
      const data = cloneDeep(columns)
      data.splice(2, 1)
      setColumns(data)
    }
  }, [columns])

  const newDistribution = (distributionType: DISTRIBUTION_TYPE) => {
    const isSample = distributionType === DISTRIBUTION_TYPE.PRODUCT_SAMPLE
    const amount = isSample ? details.pricePerSample : details.pricePerInsert
    const duration = isSample ? MEDIA_TYPES_DURATION.PER_SAMPLE : MEDIA_TYPES_DURATION.PER_INSERT

    return {
      key: distributionType,
      product: distributionType,
      type: MEDIA_TYPES.DISTRIBUTION,
      pricing: {
        amount: amount,
        currency: currency,
        quantity: null,
        total: 0,
        duration: duration,
      },
    }
  }

  const getImages = async (services: IPartnerDetailsService[]) => {
    setImagesLoading(true)
    const promises = await services.map((service: IPartnerDetailsService) => {
      return new Promise(function (resolve: any, reject: any) {
        const img = new Image()
        img.src = service.serviceImageUrl
        img.onload = resolve()
        img.onerror = reject()
      })
    })

    await Promise.all(promises)
    setImagesLoading(false)
  }

  const servicesData = () => {
    const services = [] as IAvailableServiceItem[]

    if (details.isSampleDistribution && onlySample) {
      const targetService = selectedServices.find(
        (s: IAvailableServiceItem) => s.key === DISTRIBUTION_TYPE.PRODUCT_SAMPLE
      )
      targetService ? services.push(targetService) : services.push(newDistribution(DISTRIBUTION_TYPE.PRODUCT_SAMPLE))
    }

    if (details.isInsertDistribution && onlyInsert) {
      const targetService = selectedServices.find(
        (s: IAvailableServiceItem) => s.key === DISTRIBUTION_TYPE.PRINTED_INSERT
      )
      targetService ? services.push(targetService) : services.push(newDistribution(DISTRIBUTION_TYPE.PRINTED_INSERT))
    }

    const mediaTypeServices = availableServices?.map((service: IPartnerDetailsService) => {
      const name = service.serviceSubName ? service.serviceSubName : service.serviceName
      const duration = getDuration(service)

      const targetService = selectedServices.find((s: IAvailableServiceItem) => s.key === name)
      if (targetService) {
        return {
          ...targetService,
          details: service,
          serviceCostPerClick: service.serviceCostPerClick,
          serviceCostPerImpression: service.serviceCostPerImpression,
          serviceCostPerReach: service.serviceCostPerReach,
          serviceDailyClicks: service.serviceDailyClicks,
          serviceDailyImpressions: service.serviceDailyImpressions,
          serviceDailyReach: service.serviceDailyReach,
        }
      } else {
        //@ts-ignore
        const priceField = MEDIA_TYPES_NAMES_FOR_PRICE[service.serviceName]
        //@ts-ignore
        const price = service[priceField]
        const subType = mediaSubTypes.find((type: MediaSubtype) => type.name === service.serviceName)

        return {
          key: name,
          product: name,
          type: subType ? subType.subtype : '',
          pricing: {
            amount: price,
            currency: currency,
            quantity: null,
            total: 0,
            duration: duration,
          },
          details: service,
          blocked: isOnlyDistribution,
          serviceCostPerClick: service.serviceCostPerClick,
          serviceCostPerImpression: service.serviceCostPerImpression,
          serviceCostPerReach: service.serviceCostPerReach,
          serviceDailyClicks: service.serviceDailyClicks,
          serviceDailyImpressions: service.serviceDailyImpressions,
          serviceDailyReach: service.serviceDailyReach,
        }
      }
    })

    const available_Services = []
    if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE)) available_Services.push(...services)
    if (
      brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING) ||
      brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)
    )
      available_Services.push(...mediaTypeServices)
    return isBrief ? available_Services : [...services, ...mediaTypeServices]
  }

  const contentMedia = (item: IAvailableServiceItem) => {
    const itemImg = (item.details?.serviceImageUrl || BANNERS_BY_MEDIA_TYPE[item.details!.serviceName]?.image) ?? null
    return (
      <div>
        <div className="p fw-500">{item.product}</div>
        {item.details && <div className="p p--sm tc--light">{item.details.serviceDescription}</div>}
        {item.details && item.details.serviceImpressions > 0 && (
          <div className="grid-row grid-row--aic grid-row--jcb mt--100">
            <div className="grid-col grid-col--auto p p--ms fw-700">Avg Impressions</div>
            <div className="grid-col grid-col--auto p p--sm">{item.details.serviceImpressions}</div>
          </div>
        )}
        {item.details && (
          <div className="mt--100 banner-popover__image">
            <img src={itemImg} width="292" height="170" alt="" />
          </div>
        )}
      </div>
    )
  }

  const data: IAvailableServiceItem[] = servicesData()
  const selectedRowKeys = selectedServices?.map((service: IAvailableServiceItem) => service.key)

  const handleChange = (selectedKeys: string[], selectedRows: IAvailableServiceItem[]) => {
    onChangeServices(selectedRows)
    // if (isBrief) {
    //   if (data.length === selectedRows.length) setSeeMore(0)
    //   else if (data.length > selectedRows.length) setSeeMore(2)
    //   else setSeeMore(1)

    //   if (!brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE) && !brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)) {
    //     setSeeMore(0)
    //   }
    // }
  }

  const rowSelection = {
    onChange: handleChange,
    getCheckboxProps: (record: IAvailableServiceItem) => {
      return {
        disabled:
          !editMode ||
          record.blocked ||
          record.product === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ||
          record.product === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE,
        name: record.key,
      }
    },
    selectedRowKeys: selectedRowKeys,
  }

  return (
    <div className="qu-details-table">
      <QTable
        loading={imagesLoading}
        className="qu-details-table"
        //@ts-ignore
        size="small"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      {seeMore === 1 && (
        <span className="cursorPointer" style={{ color: '#1379AC' }} onClick={() => seeMoreLess(true)}>
          See More
        </span>
      )}
      {seeMore === 2 && (
        <span className="cursorPointer" style={{ color: '#1379AC' }} onClick={() => seeMoreLess(false)}>
          See Less
        </span>
      )}
    </div>
  )
}
