import get from 'lodash/get'

import { IState } from './partnerAPI.types'
import * as CONSTANTS from './partnerAPI.constants'
import IAction from 'src/interfaces/IAction'

export const initialState: IState = {
  partnerAPIData: [
    {
      token: '',
      id: 0,
      partnerId: 0,
      status: '',
      createdAt: '',
    },
  ],
  isValid: false,
  partnerAPIListLoading: true,
  total: 0,
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  params: {
    offset: 0,
    limit: 10,
    direction: 'desc',
    sortField: 'createdAt',
  },
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.FETCH_PARTNER_API_REQUEST:
      return {
        ...state,
        partnerAPIListLoading: true,
      }
    case CONSTANTS.FETCH_PARTNER_API_LIST_SUCCESS:
      const partnerAPIData = get(action, 'payload.partnerAPIData')
      const total = get(action, 'payload.total')
      const isValid = get(action, 'payload.isValid')
      return {
        ...state,
        partnerAPIData,
        total,
        isValid,
        partnerAPIListLoading: false,
      }
    case CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, pages: { ...state.pages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_REQUEST_LIST_PARAMS:
      return { ...state, params: get(action, 'payload.params'), partnerAPIListLoading: true }
    default:
      return state
  }
}
