import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { requestHttp, urls } from '../../../api'
import { getResponseErrorMessage } from '../../../helpers'
import IAction from '../../../interfaces/IAction'
import * as CONSTANTS from './request.constants'
import {
  IRequestDetails,
  IRequestDetailsResponse,
  IRequestInfo,
  IRequestList,
  IRequestListResponse,
  IRequestTableParams,
} from './request.types'
import { REQUEST_ACTIONS } from '../../../constants/request'
import { getUser } from '../../core/core.actions'

export const requestListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_REQUEST,
})

export const requestListSuccess = (requestList: IRequestList): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_SUCCESS,
  payload: { requestList },
})

export const requestListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const setRequestListParams = (params: IRequestTableParams): IAction => ({
  type: CONSTANTS.SET_REQUEST_LIST_PARAMS,
  payload: { params },
})

export const setParamsAndFetch = (params: IRequestTableParams): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setRequestListParams(params))
  await dispatch(fetchRequestList(params))
}

export const fetchRequestList = (
  params: IRequestTableParams
): ThunkAction<Promise<AxiosResponse<IRequestListResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IRequestListResponse>> => {
  try {
    dispatch(requestListRequest())
    const response = await requestHttp.get<IRequestListResponse>(urls.getPartnerRequestsListUrl(), { params })
    const { content } = response.data.data

    dispatch(requestListSuccess(content))
    return response
  } catch (error: any) {
    dispatch(requestListFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const requestInfoSuccess = (info: IRequestInfo): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_INFO_SUCCESS,
  payload: { info },
})

export const requestInfoFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_REQUESTS_INFO_FAILURE,
  error,
})

export const fetchRequestInfo = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await requestHttp.get(urls.getPartnerRequestInfoUrl())
    const { content } = response.data.data

    dispatch(requestInfoSuccess(content))
  } catch (error: any) {
    dispatch(requestInfoFailure(getResponseErrorMessage(error)))
  }
}

export const fetchDetailsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_REQUEST_DETAILS_REQUEST,
})

export const requestDetailsSuccess = (details: IRequestDetails): IAction => ({
  type: CONSTANTS.FETCH_REQUEST_DETAILS_SUCCESS,
  payload: { details },
})

export const requestDetailsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_REQUEST_DETAILS_FAILURE,
  error,
})

export const fetchRequestDetails = (
  id: number
): ThunkAction<Promise<AxiosResponse<IRequestDetailsResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IRequestDetailsResponse>> => {
  try {
    dispatch(fetchDetailsRequest())
    const response = await requestHttp.get<IRequestDetailsResponse>(urls.getPartnerRequestDetailsUrl(id))
    const { content } = response.data.data

    dispatch(requestDetailsSuccess(content))
    return response
  } catch (error: any) {
    dispatch(requestDetailsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const updateRequestRequest = (): IAction => ({
  type: CONSTANTS.UPDATE_REQUEST_REQUEST,
})

export const updateRequestSuccess = (): IAction => ({
  type: CONSTANTS.UPDATE_REQUEST_SUCCESS,
})

export const updateRequestFailure = (error: string): IAction => ({
  type: CONSTANTS.UPDATE_REQUEST_FAILURE,
  error,
})

export const updateRequest = (
  requestId: number,
  action: REQUEST_ACTIONS,
  reason: string | null
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(updateRequestRequest())
    const data = { requestId, action, reason }
    await requestHttp.post(urls.getPartnerRequestActionUrl(), data)
    dispatch(updateRequestSuccess())
    dispatch(getUser())
  } catch (error: any) {
    dispatch(updateRequestFailure(error))
    return error
  }
}
