import React, { ReactElement } from 'react'
import { QButton, IconsCommon } from 'quantum_components'

export interface IProps {
  currentIndex: number
  onBack: () => void
  onNext: () => void
  onFinishLater: () => void
  disabled: boolean
}

const { IconArrowLeft, IconArrowRight } = IconsCommon

const LAST_STEP = 3

export default function PartnersProfileFooter({
  currentIndex,
  onBack,
  onNext,
  onFinishLater,
  disabled,
}: IProps): ReactElement {
  const isLastStep = currentIndex === LAST_STEP
  return (
    <div className="partner-container-footer" style={isLastStep ? { flexDirection: 'row-reverse' } : undefined}>
      {!isLastStep && (
        <QButton disabled={disabled} onClick={onFinishLater} className="qu-button-soft">
          SAVE UPDATES
        </QButton>
      )}
      <div className="right">
        {!!currentIndex && (
          <QButton className="qu-button-outline" onClick={onBack}>
            <IconArrowLeft className="mr-5" />
            Back
          </QButton>
        )}
        {!isLastStep ? (
          <QButton onClick={onNext} type="primary">
            Next
            <IconArrowRight className="ml-5" />
          </QButton>
        ) : (
          <QButton disabled={disabled} onClick={onFinishLater} className="qu-button-soft">
            SAVE UPDATES
          </QButton>
        )}
      </div>
    </div>
  )
}
