import React from 'react'
import { render } from 'react-dom'
import * as serviceWorker from './serviceWorker'
import './i18n'

import Root from './modules/core/App'

render(<Root />, document.getElementById('root'))

serviceWorker.unregister()
