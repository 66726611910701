import React, { ReactElement } from 'react'
import { Col, QSelectItem } from 'quantum_components'

interface ICardProps {
  checked: boolean
  label: string
  sublabel: string
  onChange: (e: MouseEvent, label: string) => void
  isViewOnly?: boolean
  img: string
}

export function AgeGroupCard({ checked, label, sublabel, onChange, isViewOnly, img }: ICardProps): ReactElement {
  return (
    <Col xs={8} key={label}>
      <QSelectItem
        className={'mb-16 age-card'}
        label={label}
        sublabel={sublabel}
        checked={checked}
        cardImage={<img src={img} alt={label} />}
        onChange={(e: MouseEvent) => (!isViewOnly ? onChange(e, label) : console.log(e))}
      />
    </Col>
  )
}
