export default {
  EMAIL_REGEX: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  EMAIL_SEPARATE_COMMA_REGEX:
    /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
  EMAIL_SIGNUP_REGEX: /^[a-zA-Z0-9._%+-]+@(?!gmail\.|yahoo\.|outlook\.|hotmail\.|msn\.|live\.|passport\.|aol\.|netscape\.|proton\.|zoho\.|gmx\.|icloud\.|me\.|mac\.|mail2world\.|tutanota\.|juno\.|mailinator\.|yopmail\.|sharklasers\.|tomizone\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
  PHONE_BODY_REGEX: /\d{9,13}$/i,
  PHONE_SIGNUP_REGEX: /\d{10}$/i,
  TEN_DIGIT: /^[0-9]{10}$/,
  FIFTEEN_DIGIT: /^[0-9]{15}$/,
  AMOUNT_REGEX: /^\d+(\.\d{0,4})?$/,
  URL: /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{7,255}$/,
  POSITIVE_NUMBERS_REGEX: /^\d*[1-9]\d*$/,
  EMPTY_FIELD: /([^\s])/,
  GREATER_THAN_2500: /([1-9][0-9]{2,}|[3-9][0-9]|2[5-9])[0-9]{2}/,
  AMOUNT_REGEX_WITH_TWO_DECIMAL: /^\d+(\.\d{0,2})?$/,
  PERCENTAGE_NUMBER: /^([0]?[1-9]?\d(\.\d\d?)?|100(\.00?)?)$/,
  ARABIC_CHARACTERS_NUMBERS_SPACES: /^[\u0621-\u064A\u0660-\u06690-9 ]+$/,
  POSITIVE_AMOUNT_REGEX_WITH_TWO_DECIMAL: /^(?=.*[1-9])[0-9]*[.,]?[0-9]{1,2}$/,
  POSITIVE_NUMBERS_GREATER_THAN_999_REGEX: /^(?!(?:\d{1,3}|999)$)[0-9]\d+$/,
}
