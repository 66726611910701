import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as actions from 'src/modules/registration/signUp/signUp.actions'
import { useReduxDispatch } from 'src/helpers'
import { REGEXES, ROLES, LOCALIZATION_LANGUAGES } from 'src/constants'
import { Col, Form, QButton, QButtonEye, QInput, Row } from 'quantum_components'
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-icon.svg'
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrow-left-icon.svg'
import { validateMessages } from '../../../../helpers/validationMessage'
import { Store } from 'antd/lib/form/interface'
import AuthLayout from '../../../../components/Layouts/AuthLayout'
import ReCAPTCHA from 'react-google-recaptcha'
import { VALIDATION_MESSAGE } from '../../../../constants/validationMessages'
import SpinnerCenter from 'src/components/Spinner/spinner'
import { useSelector } from 'react-redux'
import { getIsLoading } from '../../signUp/signUp.selectors'
import { getIsAuthorizing } from '../../signIn/signIn.selectors'

export interface SocialFormValues {
  email?: string
  companyName?: string
  firstName?: string
  lastName?: string
  phone?: string
  password?: string
  role?: ROLES
  socialToken: string
  socialType?: string
}

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''

export default function PendingSignUpContainer(): ReactElement {
  const { socialToken, briefId, socialType }: any = useParams()
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationUser', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const isLoading = useSelector(getIsLoading);
  const isAuthorizing = useSelector(getIsAuthorizing);
  const [reCaptcha, setRecaptcha] = useState<string>('')
  const [reCaptchaError, setRecaptchaError] = useState<boolean>(false)
  const [passwordIsVisible, setPasswordVisible] = useState<boolean>(false)

  const passwordRegexNumbers = "0123456789"
  const passwordRegexAlphaCap = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const passwordRegexAlphaSmall = "abcdefghijklmnopqrstuvwxyz"
  const passwordRegexSpecial = "!'^+%&/()=?_#$½§{[]}|;:>÷`<.*-@é"

  useEffect(() => {
    dispatch(actions.socialSignUp({ socialToken, socialType }, '', '', briefId))
  }, [socialToken, socialType])

  const createRandomPassword = async () => {
    let password = ''
    const passwordRegexNumbersLength = passwordRegexNumbers.length
    const passwordRegexAlphaCapLength = passwordRegexAlphaCap.length
    const passwordRegexAlphaSmallLength = passwordRegexAlphaSmall.length
    const passwordRegexSpecialLength = passwordRegexSpecial.length

    for (let i = 0; i < 5; i++) {
      let characterIndex = Math.round(Math.random() * passwordRegexNumbersLength)
      password = password + passwordRegexNumbers.charAt(characterIndex)
    }
    for (let i = 0; i < 3; i++) {
      let characterIndex = Math.round(Math.random() * passwordRegexAlphaCapLength)
      password = password + passwordRegexAlphaCap.charAt(characterIndex)
    }
    for (let i = 0; i < 2; i++) {
      let characterIndex = Math.round(Math.random() * passwordRegexAlphaSmallLength)
      password = password + passwordRegexAlphaSmall.charAt(characterIndex)
    }
    for (let i = 0; i < 5; i++) {
      let characterIndex = Math.round(Math.random() * passwordRegexSpecialLength)
      password = password + passwordRegexSpecial.charAt(characterIndex)
    }

    return password
  }

  const onFinish = async (values: Store) => {
    let token = '';
    let specialId = '';
    // let briefId = '';

    if (!reCaptcha) {
      setRecaptchaError(true)
      return
    }

    const password = await createRandomPassword()

    const formValues = {
      companyName: values.companyName,
      phone: values.phone,
      password: password,
      socialToken,
      socialType
    } as SocialFormValues
    dispatch(actions.socialSignUp(formValues, token, specialId, briefId))
  }

  const initialValues = {}

  const handleReCaptcha = (token: string | null) => {
    if (token) {
      setRecaptcha(token)
      setRecaptchaError(false)
    } else {
      setRecaptcha('')
      setRecaptchaError(true)
    }
  }

  const getPasswordInputType = (passwordIsVisible: boolean) => {
    return passwordIsVisible ? 'text' : 'password'
  }

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordIsVisible)
  }

  return (
    <AuthLayout AuthPageLadyImageMaxWidth={"400px"}>
      {(isLoading || isAuthorizing) && <SpinnerCenter />}
      <div className="auth-form">
        <h4 className='mb-20'>
          {t('user.signup.complete_your_account')}
        </h4>
        <Form
          layout="vertical"
          hideRequiredMark
          initialValues={initialValues}
          validateMessages={{ ...validateMessages, required: t('required field') }}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={t('user.signup.company_name')}
                name="companyName"
                rules={[{ required: true, max: 255, whitespace: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t('user.signup.your_company_name')} size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('user.signup.phone_number')}
                name="phone"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    pattern: REGEXES.PHONE_BODY_REGEX,
                  },
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t('user.signup.your_phone_number')} size="large" />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                label={t('user.signup.password')}
                name="password"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    pattern: REGEXES.PASSWORD,
                    message: t(VALIDATION_MESSAGE.PASSWORD, { ns: 'translation' }),
                  },
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput
                  size="large"
                  placeholder={t('user.signup.your_password')}
                  style={{ paddingRight: 50 }}
                  type={getPasswordInputType(passwordIsVisible)}
                />
              </Form.Item>
              <QButtonEye onClick={handlePasswordVisibility} isVisible={passwordIsVisible} />
            </Col> */}
          </Row>
          <div className="auth-captcha">
            <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleReCaptcha} />
            {reCaptchaError && <div className="text-red font-size-12 mt-5 mb-10">Please complete the CAPTCHA</div>}
          </div>
          <QButton
            onClick={() => handleReCaptcha(reCaptcha)}
            className="text-capitalize"
            type="primary"
            block
            htmlType="submit"
          >
            {t('user.signup.save_continue')}
            {i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? (
              <ArrowRight className="ml-5" />
            ) : (
              <ArrowLeft className="ml-5" />
            )}
          </QButton>
        </Form>
      </div>
    </AuthLayout>
  )
}
