import { Col, QButton, QInput, QTable, Row } from "quantum_components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SpinnerCenter from "src/components/Spinner/spinner";
import { getAllProductsList } from "../products.actions";
import lodash from "lodash";
import { Pagination } from "antd";
import i18n from "src/i18n";

const ProductsContainer = ({
  state,
  setState,
  arrayIndex,
  setShowModal
}: any) => {
  const { t } = useTranslation(["translationProduct"]);
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [search, setSearch] = useState("");
  const [debounce, setDebounce] = useState<any>(-1);

  const [pagination, setPagination] = useState<any>({
    offset: 0,
    limit: 10,
    direction: "desc",
    sortField: "id",
    search: "",
    total: 0,
  });

  const handleProductSelect = (product: any) => {
    let s = [...state];

    if (!product) {
      s[arrayIndex].activeProduct = {}
    } else {
      s[arrayIndex].activeProduct = { ...product }
    }

    // update active native template here as well 
    if (product) {
      // replace template variable values according to the selected product
      let values = {};
      s[arrayIndex]?.activeNativeTemplate?.variables?.forEach((variable: any, index: number) => {
        if (variable?.mappedProductField) {
          const value = lodash.get(product, variable?.mappedProductField);
          if (variable?.mappedProductField === "productLink") {
            s[arrayIndex].redirectUrl = lodash.isArray(value) ? [...value].pop() : value;
          }
          values = { ...values, [variable.placeholder]: lodash.isArray(value) ? [...value].pop() : value };
        } else {
          values = { ...values, [variable.placeholder]: "" };
        }
      });

      s[arrayIndex].activeNativeTemplate["values"] = values;
    }

    setState(s);

    if (product) {
      setShowModal(false);
    }
  }

  const columnsForPlacement = [
    {
      title: t("Product"),
      width: "60%",
      align: i18n.dir() === "ltr" ? "left" : "right",
      fixed: "left",
      render: (element: any, row: any) => {
        const { name, description, imageLink } = element;
        return (
          <Row gutter={24}>
            <Col md={4}>
              <img
                src={
                  imageLink
                    ? imageLink
                    : "https://placehold.co/250x200?text=No%20Image%20Yet"
                }
                className="h-50 full-width table-preview-img"
              />
            </Col>
            <Col md={20}>
              <div className="text-capitalize text-bold">{name}</div>
              <div className="text-capitalize">{description ? description.substring(0, 40) + "..." : "-"}</div>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t("Price (SAR)"),
      dataIndex: "price",
      key: "price",
      width: "20%",
      align: i18n.dir() === "ltr" ? "left" : "right",
    },
    {
      title: t("Action"),
      dataIndex: null,
      key: null,
      width: "20%",
      align: "middle",
      render: (element: any, row: any) => {
        const { id } = lodash.get(state[arrayIndex], 'activeProduct', {});
        return <QButton htmlType="button" type="primary" size="small" onClick={() => {
          element?.id === id ? handleProductSelect(null) : handleProductSelect(element)
        }}>{(element?.id === id ? t("Unselect") : t("Select"))}</QButton>
      }
    },
  ]

  const fetchProductList = async (pagination: any) => {
    setIsLoading(true);
    const data = await getAllProductsList(pagination);
    setProductList(lodash.get(data, 'results', []).map((product: any) => ({
      ...product,
      key: product.id,
    })))
    setPagination({ ...pagination, total: lodash.get(data, "total", 0) });
    setIsLoading(false);
  };

  const resetPagination = () => {
    const defaultPagination: any = {
      offset: 0,
      limit: 10,
      direction: "desc",
      sortField: "id",
      search: "",
      total: 0,
    };
    setPagination(defaultPagination);
    return defaultPagination;
  };

  const handleTableChange = (page: any, filters: any, sorter: any) => {
    let column = sorter?.column?.title ?? "id";
    let direction = sorter?.order ?? "desc";

    if (column === "Product") {
      column = "name";
    } else if (column === "SKU ID") {
      column = "sku";
    }

    if (direction === "descend") {
      direction = "desc";
    } else {
      direction = "asc";
    }

    let existingPagination = { ...pagination, direction, sortField: column };

    setPagination(existingPagination);
    fetchProductList(existingPagination);
  };

  const handleChangePage = async (page: number, pageSize: number) => {
    const dp = { ...pagination };
    dp.offset = (page - 1) * dp.limit;
    await fetchProductList(dp);
  };

  useEffect(() => {
    if (!search && debounce < 0) return;
    if (debounce) clearTimeout(debounce);

    setDebounce(
      setTimeout(() => {
        let dp = resetPagination();
        dp.search = search;
        setPagination(dp);
        if (!search.length) {
          resetProductList(dp)
        } else {
          fetchProductList(dp);
        }
      }, 500)
    );
  }, [search]);

  const resetProductList = async (pagination: any) => {
    setIsLoading(true);
    setProductList([]);
    setPagination({ ...pagination, total: 0 });
    setIsLoading(false);
  };

  return (
    <div className="services-v2">
      {isLoading && <SpinnerCenter />}

      <Row justify="space-between" className="mt-20 mb-20">
        <Col>
          <h2>{t("Browse Products")}</h2>
          <p className="m-0">{t("Search for a product to advertise")}</p>
        </Col>
      </Row>

      <Row justify="space-between" align={"middle"} className="pb-10">
        <Col md={24} className="flex-container-row-flex-start">
          <div className={"full-width"}>
            <QInput
              placeholder={t("E.g. Milk")}
              size="large"
              className={"full-width"}
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </div>
        </Col>
      </Row>

      {!!productList.length && <Row>
        <Col md={24}>
          <div className="pb-10">
            <QTable
              //@ts-ignore
              onChange={handleTableChange}
              columns={columnsForPlacement}
              dataSource={productList}
              loading={false}
              scroll={{ x: "auto" }}
            />
          </div>
          <div className="qt-footer">
            <Pagination
              showSizeChanger={false}
              size="small"
              defaultCurrent={1}
              current={
                pagination?.offset / pagination?.limit == 0
                  ? 1
                  : pagination?.offset / pagination?.limit + 1
              }
              total={pagination?.total}
              defaultPageSize={pagination?.limit}
              onChange={handleChangePage}
            />
          </div>
        </Col>
      </Row>}
    </div>
  );
};

export default ProductsContainer;