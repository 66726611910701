export enum ALERTS_MSG {
  ALERT_BRIEF_LIST_NOT_EMPTY = 'alert.ALERT_BRIEF_LIST_NOT_EMPTY',
  ALERT_ACCOUNT_APPROVED = 'alert.ALERT_ACCOUNT_APPROVED',
  ALERT_BRIEF_APPROVED = 'alert.ALERT_BRIEF_APPROVED',
  ALERT_BRIEF_LIST_EMPTY = 'alert.ALERT_BRIEF_LIST_EMPTY',
  ALERT_BRIEF_VIEW = 'alert.ALERT_BRIEF_VIEW',
  ALERT_BRIEF_UPLOAD_MAX_FIVE = 'alert.ALERT_BRIEF_UPLOAD_MAX_FIVE',
  ALERT_BRIEF_PAYMENT_REVIEW = 'alert.ALERT_BRIEF_PAYMENT_REVIEW',
  ALERT_BRIEF_UPLOAD_MAX_TEN = 'alert.ALERT_BRIEF_UPLOAD_MAX_TEN',
  ALERT_REQUEST_REVIEW_NOW = 'alert.ALERT_REQUEST_REVIEW_NOW',
  ALERT_REJECTION_FLOW_ADD = 'alert.ALERT_REJECTION_FLOW_ADD',
}

export const ALERT_BRIEF_UPLOAD_MAX = (count: number) => `You can upload maximum ${count} files`
