import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import * as CONSTANTS from './deploymentDetails.constants'
import { getResponseErrorMessage } from 'src/helpers'

export const saasRequest = (): IAction => ({
    type: CONSTANTS.SAAS_REQUEST
})

export const saasSuccess = (data: any): IAction => ({
    type: CONSTANTS.SAAS_SUCCESS,
    payload: data
})

export const saasFailure = (error: string): IAction => ({
    type: CONSTANTS.SAAS_FAILURE,
    error,
})

export const fetchSaasPartnerDetails = (): ThunkAction<void, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
        dispatch(saasRequest())
        const response = await requestHttp.get<any>(urls.getCurrentSaasPartner())
        const { content } = response.data.data
        const socials = []

        if (content?.partnerProfile?.metrics) {
            for (const [key, value] of Object.entries(content?.partnerProfile?.metrics)) {
                socials.push({
                    name: key,
                    counter: value
                })
            }
        }

        if (content?.partnerProfile?.socialMediaLinks) {
            for (const [key, value] of Object.entries(content?.partnerProfile?.socialMediaLinks)) {
                let index = socials.length ? socials.findIndex(ele1 => ele1.name.toLowerCase() == key.toLowerCase()) : -1
                if (index >= 0) socials[index].redirectURL = value
                else socials.push({ name: key, counter: '0', redirectURL: value })
            }
        }

        const data = {
            "id": content.partnerProfile.id,
            "userId": content.partnerProfile.userId,
            "companyName": content.companyName,
            "companyWebsite": content.companyWebsite,
            "currency": content.currency,
            "companyShortDescription": content.publicProfile.listingShortDescription,
            "companyFullDescription": content.partnerProfile.companyDescription,
            "companyFullDescriptionAr": content.partnerProfile.companyDescriptionAr,
            "companyLogoUrl": content.publicProfile.companyLogo,
            "companyBackgroundImageUrl": content.publicProfile.listingHeaderImage,
            "highlights": content.publicProfile.highlights,
            "isSampleDistribution": content.partnerProfile.isSampleDistribution,
            "pricePerSample": content.partnerProfile.pricePerSample,
            "isInsertDistribution": content.partnerProfile.isInsertDistribution,
            "pricePerInsert": content.partnerProfile.pricePerInsert,
            "partnerProperties": {},
            "metricsMarketing": content.publicProfile.metricsMarketing,
            "monthlyOrderRange": {},
            "mbPricingModel": content.partnerProfile.mbPricingModel,
            "social": socials,
            "locations": [{
                "name": content.partnerProfile.countries,
                "cities": content.partnerProfile.cities
            }],
            "availableServices": content.availableServices,
            "mediaSubtypes": content.mediaSubtypes,
            "countries": content.countries,
        }
        dispatch(saasSuccess(data))
    } catch (error) {
        dispatch(saasFailure(getResponseErrorMessage(error)))

    }
}
