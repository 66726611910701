import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getForm } from '../../../profile.selectors'
import { useReduxDispatch } from 'src/helpers'
import IUploadFile from 'src/interfaces/IUploadFile'
import { getFileNameFromUrl } from 'src/utils'
import UploadComponent from 'src/components/UploadComponent'
import { actions } from 'src/modules/partner/partnerProfile/partnerProfile.module'
import { PARTNER_UPLOAD_TYPES } from 'src/constants/partner'

interface IProps {
  value: string | undefined
  onChange: any
  caption?: string[]
  heading?: string
  accept?: string
}

const ImageUploadInput: FC<IProps> = ({ value = '', onChange, caption, heading, accept }) => {
  const dispatch = useReduxDispatch()

  const [src, setSrc] = useState(value)
  const [isImagesUploading, setIsImagesUploading] = useState(false)

  const { id } = useSelector(getForm)
  const [files, setFiles] = useState<IUploadFile[]>([])

  const filesMapper = () => {
    const url = src
    const list = url
      ? [
          {
            uid: 0 + '',
            name: getFileNameFromUrl(url),
            status: 'done',
            url: url,
          } as IUploadFile,
        ]
      : []

    setFiles(list)
  }

  const handleUpload = async (uploads: File[]) => {
    setIsImagesUploading(true)
    if (files.length > 0) {
      setSrc('')
    }
    const res =
      heading === 'Upload Video'
        ? await dispatch(actions.uploadProductImage(id!, uploads, PARTNER_UPLOAD_TYPES.PARTNER_PROFILE_VIDEOS))
        : await dispatch(actions.uploadProductImage(id!, uploads, PARTNER_UPLOAD_TYPES.PARTNER_PROFILE_IMAGES))
    setIsImagesUploading(false)
    setSrc(res)
    triggerChange(res)
  }

  const handleRemove = async () => {
    setSrc('')
    triggerChange('')
  }
  useEffect(() => {
    filesMapper()
  }, [src])

  const triggerChange = (changedValue: any) => {
    if (onChange) {
      onChange(changedValue)
    }
  }

  return caption && heading && accept ? (
    <UploadComponent
      caption={caption}
      heading={heading}
      accept={accept}
      files={files}
      onChange={handleUpload}
      onRemove={handleRemove}
      isUploading={isImagesUploading}
    />
  ) : (
    <UploadComponent files={files} onChange={handleUpload} onRemove={handleRemove} isUploading={isImagesUploading} />
  )
}

export default ImageUploadInput
