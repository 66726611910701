import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, QButton, QInput, Row } from "quantum_components";
import {
  IBrief,
  ICartServiceItem,
  IUploadPartner,
  IUploadSettings,
} from "../../brief.types";
import { useSelector } from "react-redux";
import { getUploadSettings, getUploadStepLoading } from "../../brief.selectors";
import { REGEXES } from "../../../../constants";
import { isMobile } from "react-device-detect";
import { ReactComponent as LogOut } from "../../../../assets/icons/logout-icon-alt.svg";
import videoThumbnail from "../../../../assets/video-thumbnail.jpg";
import {
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  MEDIA_TYPES,
} from "../../../../constants/brief";
import PopoverSummary from "../components/Brief.PopoverSummary";
import UploadComponent from "../../../../components/UploadComponent";
import { actions } from "../../brief.module";
import { useReduxDispatch } from "../../../../helpers";
import IUploadFile from "../../../../interfaces/IUploadFile";
import { getFileNameFromUrl } from "../../../../utils";
import { IMediaBuyTypesElement } from "../../../partner/partnerProfile/profile.types";
import { VALIDATION_MESSAGE } from "../../../../constants/validationMessages";
import { ADVERTISEMENT_LANGUAGES } from "src/constants/profile";
import { snakeCase } from "lodash";

interface IProps {
  brief: IBrief;
  onChange: (uploadFields: IUploadPartner[]) => void;
  onFinishLater: () => void;
  disabledFinishLater: boolean;
  skipUploadStep: boolean;
  showFinalSummary: () => void;
}

const UploadForm = forwardRef(
  (
    {
      brief,
      onChange,
      onFinishLater,
      disabledFinishLater,
      skipUploadStep,
      showFinalSummary,
    }: IProps,
    ref
  ): ReactElement => {
    const { i18n } = useTranslation();
    const { t } = useTranslation(["translation", "translationCommon"]);
    const dispatch = useReduxDispatch();
    const uploadSettings = useSelector(getUploadSettings);
    const imageLoadingList = useSelector(getUploadStepLoading);
    const [form] = Form.useForm();
    const [errors, setErrors] = useState({});

    const uploadFields = brief[BRIEF_STEPS.UPLOAD].uploadMaterial;

    useEffect(() => {
      if (skipUploadStep) {
        showFinalSummary();
      }
      uploadFields?.forEach((item) => validate(item));
    }, []);

    useImperativeHandle(ref, () => ({
      validateFields() {
        return Object.values(errors).some((error) => !!error);
      },
    }));

    const getObjectKey = (object: IUploadPartner) => {
      const name = object.mediaSubType ? object.mediaSubType : object.mediaType;
      return name + "_" + object.partnerId;
    };

    const validate = (object: IUploadPartner) => {
      const newErrors = { ...errors };
      const key = getObjectKey(object);

      if (!object.link) {
        //@ts-ignore
        newErrors[key] = "";
        setErrors(newErrors);

        return;
      }

      const isValid = object.link.match(REGEXES.URL);

      //@ts-ignore
      newErrors[key] = isValid ? "" : t(VALIDATION_MESSAGE.WEBSITE_URL);
      setErrors(newErrors);
    };

    const fetchUploadMedia = async () => {
      if (!brief.id) {
        return;
      }
      await dispatch(actions.fetchUploadMediaTypes(brief.id));
    };

    useEffect(() => {
      fetchUploadMedia();
    }, []);

    const getFileObject = (service: IUploadPartner, languageType: string) => {
      const targetMaterial: any = uploadFields?.find(
        (item: IUploadPartner) =>
          item.partnerId === service.partnerId &&
          item.mediaType === service.mediaType &&
          item.mediaSubType === service.mediaSubType
      );
      if (!targetMaterial || !targetMaterial.imageTargetUrl) {
        return null;
      }

      const name = service.mediaSubType
        ? service.mediaSubType
        : service.mediaType;

      return {
        uid: service.partnerId + name,
        name: getFileNameFromUrl(targetMaterial.imageTargetUrl[languageType]),
        status: "done",
        url: targetMaterial.imageTargetUrl[languageType],
      } as IUploadFile;
    };

    const formBuilder = () => {
      const data = [] as IUploadPartner[];
      uploadSettings.forEach((setting: IUploadSettings) => {
        const mediaTypes = setting.mediaTypes;

        setting.services.forEach((service: ICartServiceItem) => {
          if (!service.type || service.type === MEDIA_TYPES.DISTRIBUTION) {
            return;
          }

          const target = uploadFields?.find((item: IUploadPartner) => {
            const itemName = item.mediaSubType
              ? item.mediaSubType
              : item.mediaType;
            return (
              item.partnerId === setting.partnerId && itemName === service.name
            );
          });

          if (target) {
            data.push(target);
          } else {
            const mediaBuyType = mediaTypes.find(
              (media: IMediaBuyTypesElement) => {
                const name = media.subName ? media.subName : media.name;
                return name === service.name;
              }
            );
            data.push({
              partnerId: setting.partnerId,
              mediaType: mediaBuyType ? mediaBuyType.name : service.name,
              mediaSubType: mediaBuyType ? mediaBuyType.subName : service.name,
              imageTargetUrl: {
                arabic: null,
                english: null,
              },
              link: null,
              adTitle: {
                arabic: null,
                english: null,
              },
              adDescription: {
                arabic: null,
                english: null,
              },
              width: mediaBuyType?.width ? mediaBuyType?.width : 800,
              height: mediaBuyType?.height ? mediaBuyType?.height : 600,
            } as IUploadPartner);
          }
        });
      });

      return data;
    };

    const handleInputChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      service: IUploadPartner
    ) => {
      let fields = uploadFields ? [...uploadFields] : [];
      const { value } = e.target;

      const target = fields.find(
        (item: IUploadPartner) =>
          item.partnerId === service.partnerId &&
          item.mediaType === service.mediaType &&
          item.mediaSubType === service.mediaSubType
      );

      if (!target) {
        return;
      }

      target.link = value;

      validate(target);

      onChange(fields);
    };

    const handleInputFieldChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      service: IUploadPartner,
      objectname: string,
      fieldName: string
    ) => {
      let fields = uploadFields ? [...uploadFields] : [];
      const { value } = e.target;

      const target: any = fields.find(
        (item: IUploadPartner) =>
          item.partnerId === service.partnerId &&
          item.mediaType === service.mediaType &&
          item.mediaSubType === service.mediaSubType
      );

      if (!target) {
        return;
      }

      if (target[objectname]) {
        target[objectname][fieldName] = value;
      } else {
        target[objectname] = {
          english: null,
          arabic: null,
        };
        target[objectname][fieldName] = value;
      }

      onChange(fields);
    };

    const handleRemove = async (
      service: IUploadPartner,
      languageType: string
    ) => {
      await dispatch(
        actions.removeUploadStepImage(brief, service, languageType)
      );
    };

    const handleUpload = async (
      uploads: File[],
      params: any,
      languageType: string
    ) => {
      if (params.imageTargetUrl && params.imageTargetUrl[languageType]) {
        await handleRemove(params, languageType);
      }

      dispatch(
        actions.uploadStepSaveImage(brief, uploads, params, languageType)
      );
    };

    const data = formBuilder();

    return (
      <div className="qu-brief-content brief-step brief-upload-step">
        {!isMobile && (
          <div className="brief-step-header only-desktop">
            <h1>{t("brief.edit.step7.upload_your_media")}</h1>
            <p className="mb-0">
              {t("brief.edit.step7.upload_separate_media")}
            </p>
            <p>{t("brief.edit.step7.media_not_ready")}</p>
          </div>
        )}
        <Form form={form} layout="vertical" hideRequiredMark>
          <div className={`form form-brief form--horizontal`}>
            {isMobile && (
              <div className="form__head">
                <div className="form__number">
                  <span>{t(BRIEF_STEPS_NAMES[BRIEF_STEPS.UPLOAD])}</span>
                </div>
                <h3>{t("brief.edit.step7.upload_your_media")}</h3>
                <p>{t("brief.edit.step7.upload_separate_media")}</p>
                <p>{t("brief.edit.step7.media_not_ready")}</p>
              </div>
            )}
            <PopoverSummary brief={brief} />
            <div className="form__cont">
              {uploadSettings.map(
                (settings: IUploadSettings, index: number) => {
                  const isLast = uploadSettings.length - 1 === index;
                  const services = data.filter(
                    (item: IUploadPartner) =>
                      item.partnerId === settings.partnerId
                  );
                  if (!services.length) {
                    return null;
                  }
                  return (
                    <React.Fragment key={settings.partnerId}>
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={10}>
                          <div className="qu-brand-label">
                            <div className="qu-brand-logo">
                              {settings.companyLogoUrl && (
                                <img
                                  src={settings.companyLogoUrl}
                                  alt={settings.companyName}
                                />
                              )}
                            </div>
                            <div className="qu-brand-info">
                              <h4 className="qu-brand-name">
                                {settings.companyName}
                              </h4>
                              <p className="qu-brand-subtitle text-truncate">
                                {settings.companyWebsite}
                              </p>
                              <p className="qu-brand-subtitle">
                                {t("brief.edit.step7.ad_languages", {
                                  languages: settings.advertisementLanguage
                                    .length
                                    ? settings.advertisementLanguage.join(", ")
                                    : "english",
                                })}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={14}>
                          {services.map(
                            (service: IUploadPartner, index1: number) => {
                              const isLast = services.length - 1 === index1;
                              const key = getObjectKey(service);
                              //@ts-ignore
                              const error = errors[key];
                              const name = service.mediaSubType
                                ? service.mediaSubType
                                : service.mediaType;

                              const serviceValidations =
                                settings.mediaTypes.find(
                                  (ele) =>
                                    (ele.subName ? ele.subName : ele.name) ===
                                    name
                                );

                              const caption =
                                service.mediaType ===
                                  MEDIA_TYPES.HOME_BANNERS ||
                                service.mediaType === MEDIA_TYPES.SOCIAL_MEDIA
                                  ? [
                                      t(
                                        "brief.edit.step7.variable_image_size",
                                        {
                                          width: service.width,
                                          height: service.height,
                                        }
                                      ),
                                      t("brief.edit.step7.max_file_size"),
                                    ]
                                  : undefined;
                                  const captionMobile =
                                  service.mediaType ===
                                    MEDIA_TYPES.HOME_BANNERS ||
                                  service.mediaType === MEDIA_TYPES.SOCIAL_MEDIA
                                    ? [
                                        t(
                                          "brief.edit.step7.variable_image_size",
                                          {
                                            width: service.height,
                                            height: service.width,
                                          }
                                        ),
                                        t("brief.edit.step7.max_file_size"),
                                      ]
                                    : undefined;
                              return (
                                <React.Fragment key={name}>
                                  <p className="form-brief-subtitle mb-15">
                                    {t(
                                      "services.media-buy.types." +
                                        snakeCase(name),
                                      name
                                    )}
                                  </p>
                                  {settings.advertisementLanguage.length ===
                                    0 ||
                                    (settings.advertisementLanguage.includes(
                                      ADVERTISEMENT_LANGUAGES.ENGLISH
                                    ) &&
                                      (name === MEDIA_TYPES.FEED ? (
                                        <Form.Item>
                                          <UploadComponent
                                            files={
                                              service.imageTargetUrl.english
                                                ? [
                                                    getFileObject(
                                                      service,
                                                      "english"
                                                    ),
                                                  ]
                                                : []
                                            }
                                            onChange={(f: File[]) =>
                                              handleUpload(
                                                f,
                                                service,
                                                "english"
                                              )
                                            }
                                            onRemove={() =>
                                              handleRemove(service, "english")
                                            }
                                            disabled={
                                              imageLoadingList.length > 0
                                            }
                                            isUploading={imageLoadingList.includes(
                                              key + "_english"
                                            )}
                                            uploadStepHeading={t(
                                              "brief.edit.step7.upload_desktopv_label"
                                            )}
                                            caption={[
                                              t(
                                                "brief.edit.step7.accepted_video"
                                              ),
                                              t(
                                                "brief.edit.step7.accepted_video_size"
                                              ),
                                            ]}
                                            accept=".webm, .ogg, .mp4"
                                            isVideo={true}
                                          />
                                        </Form.Item>
                                      ) : (
                                        <Form.Item>
                                          <UploadComponent
                                            files={
                                              service.imageTargetUrl.english
                                                ? [
                                                    getFileObject(
                                                      service,
                                                      "english"
                                                    ),
                                                  ]
                                                : []
                                            }
                                            onChange={(f: File[]) =>
                                              handleUpload(
                                                f,
                                                service,
                                                "english"
                                              )
                                            }
                                            onRemove={() =>
                                              handleRemove(service, "english")
                                            }
                                            disabled={
                                              imageLoadingList.length > 0
                                            }
                                            isUploading={imageLoadingList.includes(
                                              key + "_english"
                                            )}
                                            caption={caption}
                                            uploadStepHeading={t(
                                              "brief.edit.step7.upload_desktop_label"
                                            )}
                                          />
                                        </Form.Item>
                                      )))}

                                  {
                                    (name === MEDIA_TYPES.FEED ? (
                                      <Form.Item>
                                        <UploadComponent
                                          files={
                                            service.imageTargetUrl.arabic
                                              ? [
                                                  getFileObject(
                                                    service,
                                                    "arabic"
                                                  ),
                                                ]
                                              : []
                                          }
                                          onChange={(f: File[]) =>
                                            handleUpload(f, service, "arabic")
                                          }
                                          onRemove={() =>
                                            handleRemove(service, "arabic")
                                          }
                                          disabled={imageLoadingList.length > 0}
                                          isUploading={imageLoadingList.includes(
                                            key + "_arabic"
                                          )}
                                          uploadStepHeading={t(
                                            "brief.edit.step7.upload_mobilev_label"
                                          )}
                                          caption={[
                                            t(
                                              "brief.edit.step7.accepted_video"
                                            ),
                                            t(
                                              "brief.edit.step7.accepted_video_size"
                                            ),
                                          ]}
                                          accept=".webm, .ogg, .mp4"
                                          isVideo={true}
                                        />
                                      </Form.Item>
                                    ) : (
                                      <Form.Item>
                                        <UploadComponent
                                          files={
                                            service.imageTargetUrl.arabic
                                              ? [
                                                  getFileObject(
                                                    service,
                                                    "arabic"
                                                  ),
                                                ]
                                              : []
                                          }
                                          onChange={(f: File[]) =>
                                            handleUpload(f, service, "arabic")
                                          }
                                          onRemove={() =>
                                            handleRemove(service, "arabic")
                                          }
                                          disabled={imageLoadingList.length > 0}
                                          isUploading={imageLoadingList.includes(
                                            key + "_arabic"
                                          )}
                                          caption={captionMobile}
                                          uploadStepHeading={t(
                                            "brief.edit.step7.upload_mobile_label"
                                          )}
                                        />
                                      </Form.Item>
                                    ))}

                                  <Form.Item className="mb-0">
                                    <QInput
                                      disabled={
                                        !service?.imageTargetUrl?.english &&
                                        !service?.imageTargetUrl?.arabic
                                      }
                                      value={service.link}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => handleInputChange(e, service)}
                                      placeholder={t(
                                        "brief.edit.step7.enter_target_url"
                                      )}
                                      size="large"
                                    />
                                    <small>{t("brief.edit.step7.enter_target_url_help")}</small>
                                  </Form.Item>
                                  {!!error && (
                                    <div className="text-red font-size-12 mt-5 mb-10">
                                      {error}
                                    </div>
                                  )}

                                  <Row gutter={32} className="mt-20">
                                    {(settings.advertisementLanguage.length ===
                                      0 ||
                                      settings.advertisementLanguage.includes(
                                        ADVERTISEMENT_LANGUAGES.ENGLISH
                                      )) && (
                                      <Col span={12}>
                                        <Form.Item
                                          className="mb-0"
                                          rules={
                                            serviceValidations?.adTitleCharacterLimit
                                              ? [
                                                  {
                                                    max: serviceValidations?.adTitleCharacterLimit,
                                                    whitespace: true,
                                                    message: t(
                                                      "brief.edit.step7.title_validation",
                                                      {
                                                        limit:
                                                          serviceValidations?.adTitleCharacterLimit,
                                                      }
                                                    ),
                                                  },
                                                ]
                                              : []
                                          }
                                          validateTrigger={[
                                            "onBlur",
                                            "onChange",
                                          ]}
                                          name={`titleEnglish${index}${index1}`}
                                        >
                                          <QInput
                                            disabled={
                                              !service.imageTargetUrl.english
                                            }
                                            defaultValue={
                                              service?.adTitle?.english
                                            }
                                            value={service?.adTitle?.english}
                                            onChange={(
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                              handleInputFieldChange(
                                                e,
                                                service,
                                                "adTitle",
                                                "english"
                                              )
                                            }
                                            placeholder={t(
                                              "brief.edit.step7.enter_ad_title_in_english"
                                            )}
                                            size="large"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {settings.advertisementLanguage.includes(
                                      ADVERTISEMENT_LANGUAGES.ARABIC
                                    ) && (
                                      <Col span={12}>
                                        <Form.Item
                                          className="mb-0"
                                          rules={
                                            serviceValidations?.adTitleCharacterLimit
                                              ? [
                                                  {
                                                    max: serviceValidations?.adTitleCharacterLimit,
                                                    whitespace: true,
                                                    message: t(
                                                      "brief.edit.step7.title_validation",
                                                      {
                                                        limit:
                                                          serviceValidations?.adTitleCharacterLimit,
                                                      }
                                                    ),
                                                  },
                                                  {
                                                    pattern:
                                                      REGEXES.ARABIC_CHARACTERS_NUMBERS_SPACES,
                                                    message: t(
                                                      "brief.edit.step7.only_arabic_title"
                                                    ),
                                                  },
                                                ]
                                              : [
                                                  {
                                                    pattern:
                                                      REGEXES.ARABIC_CHARACTERS_NUMBERS_SPACES,
                                                    message: t(
                                                      "brief.edit.step7.only_arabic_title"
                                                    ),
                                                  },
                                                ]
                                          }
                                          validateTrigger={[
                                            "onBlur",
                                            "onChange",
                                          ]}
                                          name={`titleArabic${index}${index1}`}
                                        >
                                          <QInput
                                            disabled={
                                              !service.imageTargetUrl.arabic
                                            }
                                            defaultValue={
                                              service?.adTitle?.arabic
                                            }
                                            value={service?.adTitle?.arabic}
                                            onChange={(
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                              handleInputFieldChange(
                                                e,
                                                service,
                                                "adTitle",
                                                "arabic"
                                              )
                                            }
                                            placeholder={t(
                                              "brief.edit.step7.enter_ad_title_in_arabic"
                                            )}
                                            size="large"
                                            className="arabicLanguage"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                  </Row>

                                  <Row gutter={32} className="mt-20 d-none">
                                    {(settings.advertisementLanguage.length ===
                                      0 ||
                                      settings.advertisementLanguage.includes(
                                        ADVERTISEMENT_LANGUAGES.ENGLISH
                                      )) && (
                                      <Col span={12}>
                                        <Form.Item
                                          className="mb-0"
                                          rules={
                                            serviceValidations?.adDescriptionCharacterLimit
                                              ? [
                                                  {
                                                    max: serviceValidations?.adDescriptionCharacterLimit,
                                                    whitespace: true,
                                                    message: t(
                                                      "brief.edit.step7.title_validation",
                                                      {
                                                        limit:
                                                          serviceValidations?.adDescriptionCharacterLimit,
                                                      }
                                                    ),
                                                  },
                                                ]
                                              : []
                                          }
                                          validateTrigger={[
                                            "onBlur",
                                            "onChange",
                                          ]}
                                          name={`descriptionEnglish${index}${index1}`}
                                        >
                                          <QInput
                                            disabled={
                                              !service.imageTargetUrl.english
                                            }
                                            defaultValue={
                                              service?.adDescription?.english
                                            }
                                            value={
                                              service?.adDescription?.english
                                            }
                                            onChange={(
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                              handleInputFieldChange(
                                                e,
                                                service,
                                                "adDescription",
                                                "english"
                                              )
                                            }
                                            placeholder={t(
                                              "brief.edit.step7.enter_ad_description_in_english"
                                            )}
                                            size="large"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {settings.advertisementLanguage.includes(
                                      ADVERTISEMENT_LANGUAGES.ARABIC
                                    ) && (
                                      <Col span={12}>
                                        <Form.Item
                                          className="mb-0"
                                          rules={
                                            serviceValidations?.adDescriptionCharacterLimit
                                              ? [
                                                  {
                                                    max: serviceValidations?.adDescriptionCharacterLimit,
                                                    whitespace: true,
                                                    message: t(
                                                      "brief.edit.step7.desc_validation",
                                                      {
                                                        limit:
                                                          serviceValidations?.adDescriptionCharacterLimit,
                                                      }
                                                    ),
                                                  },
                                                  {
                                                    pattern:
                                                      REGEXES.ARABIC_CHARACTERS_NUMBERS_SPACES,
                                                    message: t(
                                                      "brief.edit.step7.only_arabic_desc"
                                                    ),
                                                  },
                                                ]
                                              : [
                                                  {
                                                    pattern:
                                                      REGEXES.ARABIC_CHARACTERS_NUMBERS_SPACES,
                                                    message: t(
                                                      "brief.edit.step7.only_arabic_desc"
                                                    ),
                                                  },
                                                ]
                                          }
                                          validateTrigger={[
                                            "onBlur",
                                            "onChange",
                                          ]}
                                          name={`descriptionArabic${index}${index1}`}
                                        >
                                          <QInput
                                            disabled={
                                              !service.imageTargetUrl.arabic
                                            }
                                            defaultValue={
                                              service?.adDescription?.arabic
                                            }
                                            value={
                                              service?.adDescription?.arabic
                                            }
                                            onChange={(
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                              handleInputFieldChange(
                                                e,
                                                service,
                                                "adDescription",
                                                "arabic"
                                              )
                                            }
                                            placeholder={t(
                                              "brief.edit.step7.enter_ad_description_in_arabic"
                                            )}
                                            size="large"
                                            className="arabicLanguage"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                  </Row>

                                  {!isLast && <hr className="form__hr" />}
                                </React.Fragment>
                              );
                            }
                          )}
                        </Col>
                      </Row>
                      {!isLast && <hr className="form__hr" />}
                    </React.Fragment>
                  );
                }
              )}
              {isMobile && (
                <div className="form-brief-footer">
                  <QButton
                    disabled={disabledFinishLater}
                    onClick={onFinishLater}
                    className="qu-button-outline mt-30 full-width"
                  >
                    {t("brief.edit.common.save_and_finish_later")}{" "}
                    <LogOut className="ml-10" />
                  </QButton>
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
    );
  }
);

export default UploadForm;
