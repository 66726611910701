export enum PARTNER_LABEL {
  ECOMMERCE = 'ECOMMERCE',
  EVENT = 'EVENT',
  GROUP = 'GROUP',
}

export enum SOCIAL_TYPES {
  LEADS = 'Leads',
  LOOKS = 'Looks',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  TWITTER = 'Twitter',
  LINKEDIN = 'linkedin',
  YOUTUBE = 'youtube',
}

export const SOCIAL_NAMES: { [key: string]: string } = {
  [SOCIAL_TYPES.LEADS]: 'Customers',
  [SOCIAL_TYPES.LOOKS]: 'Impressions',
  [SOCIAL_TYPES.FACEBOOK]: 'Facebook',
  [SOCIAL_TYPES.INSTAGRAM]: 'Instagram',
  [SOCIAL_TYPES.TWITTER]: 'Twitter',
  [SOCIAL_TYPES.LINKEDIN]: 'LinkedIn',
  [SOCIAL_TYPES.YOUTUBE]: 'Youtube',
}

export enum PARTNER_UPLOAD_TYPES {
  PARTNER_PROFILE_IMAGES = 'partner_profile_images',
  PARTNER_PROFILE_VIDEOS = 'partner_profile_videos',
}

export const INVITED_CLIENTS_STATUS: any = {
  JOINED: 'JOINED',
  PENDING: 'PENDING',
  EXISTING_USER: 'EXISTING USER',
}

export const PARTNER_API_STATUS: any = {
  VALID: 'VALID',
  EXPIRED: 'EXPIRED',
}
