import React, { ReactElement } from 'react'
import { SocialList, QMatchPopover } from 'quantum_components'
import { IBriefPartnerDetails } from '../../modules/brief/brief.types'
import { getMatchDec } from 'src/helpers/matchNumber'
import { MATCH_DETAILS } from 'src/constants/match'
import { getSocial } from '../../helpers/social'
import { useSelector } from 'react-redux'
import { getBriefExclusivePartnerId } from 'src/modules/brief/brief.selectors'
import i18n from 'src/i18n'

interface Props {
  details: IBriefPartnerDetails
}
export default function DetailsCompanyInfo({ details }: Props): ReactElement {
  const briefExclusivePartnerId = useSelector(getBriefExclusivePartnerId)

  const getMatchDescriptions = () => {
    const match = getMatchDec(details.match)
    if (match >= 50) {
      return `Partner is a ${MATCH_DETAILS[match]} match to your target audience criteria`
    }

    return 'Partner does not match your target audience criteria'
  }


  const getSocialData = () => {
    let socialData = getSocial(details.social)
    if (Object.keys(details.monthlyOrderRange).length > 0) {
      socialData.push({
        name: 'Monthly Order Volume',
        counter: details.monthlyOrderRange.text ? details.monthlyOrderRange.text : '',
      })
    }
    return socialData
  }

  // console.log(getSocialData())

  return (
    <React.Fragment>
      <div className="qu-details-title">
        <div className="qu-details-logo">
          {details.companyLogoUrl && <img src={details.companyLogoUrl} alt="logo" />}
        </div>
        <div className="qu-details-block">
          <div className="qu-details-heading">
            <div className="qu-details-info">
              <h2 className="text-truncate">{details.companyName}</h2>
              <p className="text-truncate">{details.companyWebsite}</p>

            </div>

            {details.match && briefExclusivePartnerId == null && (
              <QMatchPopover
                match={details.match.toFixed()}
                description={getMatchDescriptions()}
                matchDetails={details.matchDetails}
              >
                <div className={`badge badge--lg badge--match badge--match-${getMatchDec(details.match)}`}>
                  {details.match.toFixed()}% Match
                </div>
              </QMatchPopover>
            )}
          </div>
          <div className="qu-details-tags">
            <span className="text-word-break">{details.companyShortDescription}</span>
          </div>
          <SocialList list={getSocialData()} />
        </div>
      </div>
      <div className="qu-details-description text-word-break">
        {
          i18n.language === "ar"
            ? details.companyFullDescriptionAr
            : details.companyFullDescription
        }
      </div>

    </React.Fragment>
  )
}
