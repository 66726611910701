import React from 'react'
import { QSidenav } from 'quantum_components'
import { useHistory } from 'react-router-dom'

import SidebarMenu from './SidebarMenu'
import { PATHS } from '../../../constants'
import PartnerHeader from '../../Headers/PartnerHeader'
import { ICurrentUser } from '../../../modules/core/core.types'

interface Props {
  children: React.ReactNode
  user: ICurrentUser
}

const PartnerLayout = ({ children, user }: Props) => {
  const history = useHistory()

  return (
    <div className="layout partner">
      <QSidenav onLogoLink={() => history.push(PATHS.ROOT)} childrenMenu={<SidebarMenu />} />
      <div className="partner-layout">
        <PartnerHeader user={user} />
        <div className="partner-main">
          <div className="partner-content">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default PartnerLayout
