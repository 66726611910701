import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Col,
  IconsCommon,
  Modal,
  QButton,
  QRadio,
  Radio,
  Row,
} from "quantum_components";

import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_PAYMENT_UPLOAD_TYPES,
  BRIEF_STATUS,
} from "src/constants/brief";
import { IBillPaymentUploadTypes } from "src/modules/client/bills/bills.types";
import IUploadFile from "src/interfaces/IUploadFile";
import ILocalFile from "src/interfaces/ILocalFile";
import { isUploadFile } from "src/type-guards";
import { getFileNameFromUrl } from "src/utils";
import * as coreActions from "src/modules/core/core.actions";

import { actions } from "../../brief.module";
import { IBriefResponse } from "../../brief.types";
import { getBriefViewPaymentFilesState } from "../../brief.selectors";

import UploadComponent from "src/components/UploadComponent";
import BriefPaymentModal from "./Brief.PaymentModal";
import { getCurrentUser } from "src/modules/core/core.selectors";
import { PATHS } from "src/constants";
import {
  TAX_TREATMENT
} from 'src/constants/clientProfile'
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";

interface Props {
  briefView: IBriefResponse;
  onFilesChange?: (
    files: { files: File[]; filesType: IBillPaymentUploadTypes }[]
  ) => void;
  onPaymentTypeChange?: (uploadType: BRIEF_PAYMENT_UPLOAD_TYPES) => void;
  hideSubmitButton?: boolean;
}

export default function BriefPayment({
  briefView,
  onFilesChange = () => { },
  onPaymentTypeChange = () => { },
  hideSubmitButton = false,
}: Props): ReactElement {
  const { i18n } = useTranslation();
  const history = useHistory();
  const { t } = useTranslation(["translation", "translationCommon"]);
  const dispatch = useDispatch();
  const { id: briefId } = useParams<{ id: any }>();

  const currentUser = useSelector(getCurrentUser);
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);

  const [showBankingDetails, setShowBankingDetails] = useState(false);
  const [allPaymentDetailsShown, setAllPaymentDetailsShown] =
    useState<boolean>(false);
  const [activeUploadType, setActiveUploadType] =
    useState<BRIEF_PAYMENT_UPLOAD_TYPES>(
      currentUser.onlinePayment ? BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE : BRIEF_PAYMENT_UPLOAD_TYPES.BANK_TRANSFER
    );
  const [clientRequiredFieldsOpen, setClientRequiredFieldsOpen] = useState<boolean>(false);

  const [localBankTransferFiles, setLocalBankTransferFiles] = useState<
    ILocalFile[]
  >([]);
  const [localPurchaseOrderFiles, setLocalPurchaseOrderFiles] = useState<
    ILocalFile[]
  >([]);

  const canUploadPurchaseOrder = Boolean(briefView.owner?.poEnabled);

  const hasUploadedPurchaseOrderFiles = Boolean(briefView.payment?.po?.length);
  const hasLocalPurchaseOrderFiles = Boolean(localPurchaseOrderFiles.length);

  const hasUploadedBankTransferFiles = Boolean(
    briefView.payment?.bankTransfer?.length
  );
  const hasLocalBankTransferFiles = Boolean(localBankTransferFiles.length);

  const paymentFilesState = useSelector(getBriefViewPaymentFilesState);
  const purchaseOrderProcessing =
    paymentFilesState.po.isUploading || paymentFilesState.po.isRemoving;
  const bankTransferProcessing =
    paymentFilesState.bankTransfer.isUploading ||
    paymentFilesState.bankTransfer.isRemoving;

  const paymentInReview = Boolean(briefView.payment?.inReview);
  const briefPaymentStatus: BRIEF_PAYMENT_STATUS | undefined = briefView.payment
    ?.status as BRIEF_PAYMENT_STATUS;

  const isPaidOnline =
    briefPaymentStatus === BRIEF_PAYMENT_STATUS.PAID &&
    Boolean(briefView.payment?.onlinePayment);

  useEffect(() => {
    const files: { files: File[]; filesType: IBillPaymentUploadTypes }[] = [
      { files: localBankTransferFiles.map(({ file }) => file), filesType: "bankTransfer", },
      { files: localPurchaseOrderFiles.map(({ file }) => file), filesType: "po", },
    ];

    onFilesChange(files);
  }, [localBankTransferFiles.length, localPurchaseOrderFiles.length]);

  useEffect(() => {
    onPaymentTypeChange(activeUploadType);
    dispatch(actions.selectedPaymentType(activeUploadType));
  }, [activeUploadType]);

  useEffect(() => {
    if (Boolean(briefView.payment?.onlinePayment)) setActiveUploadType(BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE)
  }, [briefView.payment?.onlinePayment])

  useEffect(() => {
    if (!!briefView.payment?.bankTransfer?.length) {
      setLocalBankTransferFiles([]);
      setActiveUploadType(BRIEF_PAYMENT_UPLOAD_TYPES.BANK_TRANSFER)
    }
  }, [briefView.payment?.bankTransfer?.length]);

  useEffect(() => {
    if (!!briefView.payment?.po?.length) {
      setLocalPurchaseOrderFiles([]);
      setActiveUploadType(BRIEF_PAYMENT_UPLOAD_TYPES.PO)
    }
  }, [briefView.payment?.po?.length]);

  const toggleAllPaymentDetailsShown = () => setAllPaymentDetailsShown(() => !allPaymentDetailsShown);

  const handleUpload = () => {
    if (activeUploadType === BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE) {
      setPaymentModalOpen(true);
      return;
    }

    if (hasLocalBankTransferFiles) {
      dispatch(
        actions.uploadViewBriefPaymentFile({
          files: localBankTransferFiles.map(({ file }) => file),
          filesType: "bankTransfer",
        })
      );
    }

    if (hasLocalPurchaseOrderFiles) {
      dispatch(
        actions.uploadViewBriefPaymentFile({
          files: localPurchaseOrderFiles.map(({ file }) => file),
          filesType: "po",
        })
      );
    }
  };

  const handleRemove = (file: ILocalFile | IUploadFile, filesType: IBillPaymentUploadTypes) => {
    if (isUploadFile(file)) {
      dispatch(actions.removeViewBriefPaymentFile(file, filesType));
      return;
    }

    switch (filesType) {
      case "po": {
        setLocalPurchaseOrderFiles([
          ...localPurchaseOrderFiles.filter((oldFile) => oldFile !== file),
        ]);
        return;
      }
      case "bankTransfer": {
        setLocalBankTransferFiles([
          ...localBankTransferFiles.filter((oldFile) => oldFile !== file),
        ]);
        return;
      }
      default:
        return;
    }
  };

  useEffect(() => {
    if (!currentUser.id) return;

    if (currentUser.bankTransfer || currentUser.purchaseOrder) {
      setShowBankingDetails(true)
    }
  }, [currentUser])

  return (
    <>
      <div className="SS__category">
        <h4 className="SS__category-title">{t("brief.payment.Payable_to")}:</h4>
        <div className="SSUnit">
          <h4 className="SSUnit__title mb-10">
            {currentUser?.partnerData?.name ?? "-"}
          </h4>

          <div className="SSUnit__content">
            <ul className="BSUnit__list">
              <li className="BSUnit__list-item">
                {currentUser?.partnerData?.bankDetails?.address ?? "-"}
              </li>
              <li className="BSUnit__list-item">
                {t('VAT')}: {currentUser?.partnerData?.taxTreatment == TAX_TREATMENT.NON_VAT_REGISTERED ? "-" : currentUser?.partnerData?.vat}
              </li>
              {allPaymentDetailsShown && (
                <>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Bank_Name")}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.bank_name ?? "-"}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Address")}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.bank_address ?? "-"}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Account_No")}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.account_number ??
                      "-"}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.IBAN_No")}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.iban_number ?? "-"}{" "}
                    {/* 00369710{' '} */}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Swift_Code")}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.swift_code ?? "-"}
                  </li>
                  <li className="BSUnit__list-item">
                    <span className="BSUnit__content-title">
                      {t("brief.payment.Currency")}:
                    </span>{" "}
                    {currentUser?.partnerData?.bankDetails?.account_currency ??
                      "-"}
                  </li>
                </>
              )}
            </ul>
            {
              showBankingDetails && <div
                className="BSUnit__title cursorPointer mt-10 text-center"
                onClick={() => toggleAllPaymentDetailsShown()}
              >
                {allPaymentDetailsShown
                  ? t("common.See_less", { ns: "translationCommon" })
                  : t("common.See_more", { ns: "translationCommon" })}
                {allPaymentDetailsShown ? (
                  <IconsCommon.IconArrowUp className="ml-5" />
                ) : (
                  <IconsCommon.IconArrowDown className="ml-5" />
                )}
              </div>
            }
          </div>
          <div className="SSUnit__content">
            <div className="brief-payment-inline-wrapper">
              {briefView?.payment?.quotation && (
                <UploadComponent
                  viewOnly
                  alternativeFileView
                  viewOnlyText={t('common.View', { ns: "translationCommon" })}
                  fileName={t("Quotation")}
                  dragAndDrop={false}
                  files={[
                    {
                      url: briefView.payment.quotation,
                      name: getFileNameFromUrl(briefView.payment.quotation),
                    },
                  ]}
                  onView={async (file) => {
                    window.open(file.url, "_blank");
                    URL.revokeObjectURL(file.url);
                  }}
                />
              )}

              {isPaidOnline && (
                <div className="status-label approved">paid online</div>
              )}
            </div>

            {briefView?.payment?.invoice?.length && (
              <UploadComponent
                viewOnly
                alternativeFileView
                fileName="Invoice"
                dragAndDrop={false}
                files={briefView.payment.invoice.map((url) => ({
                  url,
                  name: getFileNameFromUrl(url),
                }))}
                onView={async (file) => {
                  if (isUploadFile(file)) {
                    const response: any = await dispatch(
                      coreActions.downloadImage(file.url)
                    );
                    const url = URL.createObjectURL(response.data);

                    window.open(url, "_blank");
                    URL.revokeObjectURL(url);
                    return;
                  }

                  window.open(file.url, "_blank");
                }}
              />
            )}
          </div>

          {briefPaymentStatus === BRIEF_PAYMENT_STATUS.PAID &&
            !isPaidOnline &&
            hasUploadedBankTransferFiles && (
              <>
                <h4 className="brief-payment-files-group-title">
                  {t("brief.payment.Bank_transfer")}:
                </h4>
                <UploadComponent
                  viewOnly
                  dragAndDrop={false}
                  files={briefView.payment.bankTransfer!.map((url) => ({
                    url,
                    name: getFileNameFromUrl(url),
                  }))}
                  onView={async (file) => {
                    if (isUploadFile(file)) {
                      const response: any = await dispatch(
                        coreActions.downloadImage(file.url)
                      );
                      const url = URL.createObjectURL(response.data);

                      window.open(url, "_blank");
                      URL.revokeObjectURL(url);
                      return;
                    }

                    window.open(file.url, "_blank");
                  }}
                />
              </>
            )}

          {briefPaymentStatus === BRIEF_PAYMENT_STATUS.PAID &&
            // !isPaidOnline &&
            hasUploadedPurchaseOrderFiles && (
              <>
                <h4 className="brief-payment-files-group-title">
                  {t("brief.payment.Purchase_Order")}:
                </h4>
                <UploadComponent
                  viewOnly
                  dragAndDrop={false}
                  files={briefView.payment.po!.map((url) => ({
                    url,
                    name: getFileNameFromUrl(url),
                  }))}
                  onView={async (file) => {
                    if (isUploadFile(file)) {
                      const response: any = await dispatch(
                        coreActions.downloadImage(file.url)
                      );
                      const url = URL.createObjectURL(response.data);

                      window.open(url, "_blank");
                      URL.revokeObjectURL(url);
                      return;
                    }

                    window.open(file.url, "_blank");
                  }}
                />
              </>
            )}
        </div>
      </div>

      {![BRIEF_PAYMENT_STATUS.PROCESSING, BRIEF_PAYMENT_STATUS.PAID, BRIEF_PAYMENT_STATUS.REFUND, BRIEF_PAYMENT_STATUS.PARTIAL_REFUND].includes(
        briefPaymentStatus
      ) &&
        briefView.payment && (
          <div className="SS__category">
            <h4 className="SS__category-title">
              {t("brief.payment.Select_payment_method")}:
            </h4>
            <div className="SSUnit">
              <div className="SSUnit__content">
                {!hideSubmitButton && briefView.status === BRIEF_STATUS.PENDING && <p>{t("brief.payment.Once your proposal is approved, you will be notified to complete payment.")}</p>}
                <Row gutter={16} align="middle">
                  <Radio.Group
                    disabled={briefView.status === BRIEF_STATUS.PENDING || briefView.status === BRIEF_STATUS.REJECTED}
                    value={activeUploadType}
                    onChange={(e) => {
                      setActiveUploadType(e.target.value);
                    }}
                  >
                    {/* {canUploadPurchaseOrder && ( */}
                    {(briefView?.owner?.purchaseOrder || !!briefView?.payment?.po?.length) && (
                      <>
                        <Col className="mb-10">
                          <Radio
                            className="qu-radio mr-0"
                            disabled={hasLocalBankTransferFiles}
                            checked={
                              activeUploadType === BRIEF_PAYMENT_UPLOAD_TYPES.PO
                            }
                            value={BRIEF_PAYMENT_UPLOAD_TYPES.PO}
                          >
                            {t("brief.payment.Submit_purchase_order")}
                          </Radio>
                        </Col>

                        {activeUploadType === BRIEF_PAYMENT_UPLOAD_TYPES.PO && (
                          <Col offset={3}>
                            <UploadComponent
                              multiple
                              viewOnly={
                                briefPaymentStatus ===
                                BRIEF_PAYMENT_STATUS.PO_ISSUED
                              }
                              dragAndDrop={false}
                              maxCount={3}
                              accept={".pdf, .jpg, .jpeg, .png"}
                              caption={[
                                t("brief.payment.upload_instructions1"),
                                t("brief.payment.upload_instructions2"),
                              ]}
                              files={
                                hasUploadedPurchaseOrderFiles
                                  ? [
                                    ...localPurchaseOrderFiles,
                                    ...briefView.payment.po!.map((url) => ({
                                      url,
                                      name: getFileNameFromUrl(url),
                                    })),
                                  ]
                                  : localPurchaseOrderFiles
                              }
                              showRemoveButton={
                                briefPaymentStatus ===
                                BRIEF_PAYMENT_STATUS.REJECTED ||
                                (!hasUploadedPurchaseOrderFiles &&
                                  !paymentInReview)
                              }
                              isRemoving={paymentFilesState.po.isRemoving}
                              isUploading={paymentFilesState.po.isUploading}
                              disabled={
                                briefView.status === BRIEF_STATUS.PENDING ||
                                briefView.status === BRIEF_STATUS.REJECTED ||
                                paymentInReview ||
                                hasUploadedBankTransferFiles ||
                                hasLocalBankTransferFiles ||
                                purchaseOrderProcessing
                              }
                              onChange={(files: File[]) => {
                                const newFiles: ILocalFile[] = files.map(
                                  (file) => ({
                                    name: file.name,
                                    url: URL.createObjectURL(file),
                                    file,
                                  })
                                );
                                setLocalPurchaseOrderFiles([
                                  ...newFiles,
                                  ...localPurchaseOrderFiles,
                                ]);
                              }}
                              onRemove={(file) => handleRemove(file, "po")}
                              onView={async (file) => {
                                if (isUploadFile(file)) {
                                  const response: any = await dispatch(
                                    coreActions.downloadImage(file.url)
                                  );
                                  const url = URL.createObjectURL(
                                    response.data
                                  );

                                  window.open(url, "_blank");
                                  URL.revokeObjectURL(url);
                                  return;
                                }

                                window.open(file.url, "_blank");
                              }}
                            />
                          </Col>
                        )}
                      </>
                    )}

                    {(briefView?.owner?.bankTransfer || !!briefView?.payment?.bankTransfer?.length) && (
                      <>
                        <Col className="mt-10 mb-10">
                          <Radio
                            className="qu-radio mr-0"
                            disabled={hasLocalPurchaseOrderFiles}
                            checked={
                              activeUploadType ===
                              BRIEF_PAYMENT_UPLOAD_TYPES.BANK_TRANSFER
                            }
                            value={BRIEF_PAYMENT_UPLOAD_TYPES.BANK_TRANSFER}
                          >
                            {t("brief.payment.Submit_bank_transfer_receipt")}
                          </Radio>
                        </Col>

                        {activeUploadType ===
                          BRIEF_PAYMENT_UPLOAD_TYPES.BANK_TRANSFER && (
                            <Col offset={3}>
                              <UploadComponent
                                multiple
                                dragAndDrop={false}
                                maxCount={3}
                                accept={".pdf, .jpg, .jpeg, .png"}
                                caption={[
                                  t("brief.payment.upload_instructions1"),
                                  t("brief.payment.upload_instructions2"),
                                ]}
                                files={
                                  hasUploadedBankTransferFiles
                                    ? [
                                      ...localBankTransferFiles,
                                      ...briefView.payment.bankTransfer!.map(
                                        (url) => ({
                                          url,
                                          name: getFileNameFromUrl(url),
                                        })
                                      ),
                                    ]
                                    : localBankTransferFiles
                                }
                                showRemoveButton={
                                  briefPaymentStatus ===
                                  BRIEF_PAYMENT_STATUS.REJECTED ||
                                  (!hasUploadedBankTransferFiles && !paymentInReview)
                                }
                                isRemoving={paymentFilesState.bankTransfer.isRemoving}
                                isUploading={
                                  paymentFilesState.bankTransfer.isUploading
                                }
                                disabled={
                                  briefView.status === BRIEF_STATUS.PENDING ||
                                  briefView.status === BRIEF_STATUS.REJECTED ||
                                  paymentInReview ||
                                  bankTransferProcessing ||
                                  (canUploadPurchaseOrder &&
                                    hasLocalPurchaseOrderFiles)
                                }
                                onChange={(files: File[]) => {
                                  const newFiles: ILocalFile[] = files.map(
                                    (file) => ({
                                      name: file.name,
                                      url: URL.createObjectURL(file),
                                      file,
                                    })
                                  );
                                  setLocalBankTransferFiles([
                                    ...newFiles,
                                    ...localBankTransferFiles,
                                  ]);
                                }}
                                onRemove={(file) =>
                                  handleRemove(file, "bankTransfer")
                                }
                                onView={async (file) => {
                                  if (isUploadFile(file)) {
                                    const response: any = await dispatch(
                                      coreActions.downloadImage(file.url)
                                    );
                                    const url = URL.createObjectURL(response.data);

                                    window.open(url, "_blank");
                                    URL.revokeObjectURL(url);
                                    return;
                                  }

                                  window.open(file.url, "_blank");
                                }}
                              />
                            </Col>
                          )}
                      </>
                    )}

                    {(briefView?.owner?.onlinePayment || !!briefView?.payment?.onlinePayment) && (
                      <Col className="mt-10 mb-10">
                        <Radio
                          className="qu-radio mr-0"
                          disabled={hasLocalPurchaseOrderFiles || hasLocalBankTransferFiles}
                          checked={activeUploadType === BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE}
                          value={BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE}
                        >
                          {t('brief.payment.Use_online_payment')}
                          <Tooltip data-html="true" title={t('brief.payment.online_payment_tooltip')}>
                            <span style={{marginLeft: "5px",
                              marginRight: "5px",
                              fontWeight: "bold",
                              fontSize: "12px",
                              fontStyle: "italic",
                              color:"#aaaaaa",
                              cursor: "pointer"}}><InfoCircleOutlined style={{ fontSize: '12px' }} /></span>
                          </Tooltip>
                          <br />
                          <span className="online_payment_sub_label">{t('brief.payment.Powered by Quantum')}</span>
                        </Radio>
                      </Col>
                    )}
                  </Radio.Group>
                </Row>
              </div>
            </div>
          </div>
        )}

      {!hideSubmitButton && briefPaymentStatus !== undefined && ![BRIEF_PAYMENT_STATUS.PROCESSING, BRIEF_PAYMENT_STATUS.PAID, BRIEF_PAYMENT_STATUS.REFUND, BRIEF_PAYMENT_STATUS.PARTIAL_REFUND].includes(briefPaymentStatus) && (
        <div className="SS__category">
          <div className="SSUnit">
            <QButton
              disabled={
                briefView.status === BRIEF_STATUS.PENDING ||
                briefView.status === BRIEF_STATUS.REJECTED ||
                purchaseOrderProcessing ||
                bankTransferProcessing ||
                paymentInReview ||
                (activeUploadType !== BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE &&
                  !hasLocalPurchaseOrderFiles &&
                  !hasLocalBankTransferFiles)
              }
              type="primary"
              className="justify-center full-width"
              onClick={handleUpload}
            >
              {activeUploadType === BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE
                ? t('brief.payment.Pay_online')
                : t('brief.payment.Make_payment')}
            </QButton>
          </div>
        </div>
      )}

      {paymentModalOpen && (
        <BriefPaymentModal
          briefId={Number(briefId)}
          onCancel={() => setPaymentModalOpen(false)}
          onPaySuccess={async () => {
            const res: any = await dispatch(actions.validateClientRequiredFields(Number(briefId)))
            if (res) {
              if (res.missingInfo) {
                setPaymentModalOpen(false);
                setClientRequiredFieldsOpen(true);
              } else {
                setPaymentModalOpen(false);
              }
            }
          }}
        />
      )}

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{t("brief.incomplete-client-profile.Incomplete Client Profile")}</h3>}
        // width={400}
        closeIcon={<IconsCommon.IconClose />}
        visible={clientRequiredFieldsOpen}
        onCancel={() => setClientRequiredFieldsOpen(false)}
        footer={false}
      >
        <div>
          <p className="mb-25">
            {t("brief.incomplete-client-profile.Last_thing_before_campaign_kick-off")}
          </p>
          <div className="grid-row grid-row--smg grid-row--jce">
            <div className="grid-col grid-col--auto">
              <QButton onClick={() => history.push(PATHS.CLIENT_PROFILE)} type="primary">
                {t("brief.incomplete-client-profile.Edit Profile")}
              </QButton>
            </div>
            <div className="grid-col grid-col--auto">
              <QButton
                className="qu-button-outline"
                onClick={() => setClientRequiredFieldsOpen(false)}
              >
                {t("brief.incomplete-client-profile.Cancel")}
              </QButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
