import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Form, QButton, QInput } from "quantum_components";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-icon.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow-left-icon.svg";
import AuthLayout from "../../../../components/Layouts/AuthLayout";
import { LOCALIZATION_LANGUAGES } from "src/constants";
import { generateOTP, verifyOTP } from "../../signUp/signUp.actions";

export default function RegisterSuccessContainer(): ReactElement {
  const queryString = window.location.search;
  const urlSearchParams = new URLSearchParams(queryString);

  const initialValues = {
    id: urlSearchParams.get('id'),
    phone: urlSearchParams.get('phone'),
    otp: ''
  }

  const { i18n } = useTranslation();
  const { t } = useTranslation(["translationUser", "translationCommon"]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleReadyToLogin = () => {
    history.push(`/login`);
  };

  const handleVerifyOTP = async (data: any) => {
    setIsLoading(true);
    await verifyOTP({ id: data.id, otp: data.otp })
    setIsLoading(false);
  };

  const handleGenerateOTP = async () => {
    setIsLoading(true)
    await generateOTP({ id: initialValues.id, phone: initialValues.phone })
    setIsLoading(false)
  }

  const renderOTPModule = () => {
    return (
      <>
        <div className="text-center mt-20 mb-20">{t("OR")}</div>
        <div className="font-size-18 mb-10 text-bold">
          {t("Verify Account via OTP")}
          <br />
        </div>
        <Form
          className="mb-20"
          layout="vertical"
          hideRequiredMark
          initialValues={initialValues}
          onFinish={handleVerifyOTP}
        >
          <Form.Item
            className="d-none"
            label={t("Account Id")}
            name="id"
            rules={[
              {
                required: true,
                message: t('required field')
              }
            ]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <QInput size="large" disabled={true} placeholder={t("Type here")} />
          </Form.Item>
          <Form.Item
            label={t("Your registered phone number")}
            name="phone"
            help={<QButton disabled={isLoading} htmlType="button" size="small" className="qu-button-soft text-capitalize" type="primary" onClick={handleGenerateOTP}>{t("Generate OTP")}</QButton>}
            rules={[
              {
                required: true,
                message: t('required field')
              }
            ]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <QInput size="large" disabled={true} placeholder={t("Type here")} />
          </Form.Item>

          <Form.Item
            label={t("OTP")}
            name="otp"
            rules={[
              {
                required: true,
                message: t('required field')
              }
            ]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <QInput size="large" placeholder={t("Type here")} />
          </Form.Item>
          <QButton disabled={isLoading} htmlType="submit" className="text-capitalize" block type="primary">{t("Verify OTP")}</QButton>
        </Form>
      </>
    );
  };

  return (
    <AuthLayout AuthPageLadyImageMaxWidth={"400px"}>
      <div className="auth-form">
        <h1 className="font-size-26">
          {t("user.signup.successfully_registered")}
          <br />
        </h1>
        <p className="thankyou__text">
          <strong>{t("user.signup.you_are_almost_done")}</strong>
          <br />
        </p>
        <p className="thankyou__desc mb-30">
          {t("user.signup.we_sent_you_an_email")}
          <br />
        </p>
        <QButton
          className="text-capitalize"
          type="primary"
          block
          htmlType="submit"
          onClick={handleReadyToLogin}
        >
          {t("user.signup.ready_to_login")}{" "}
          {i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? (
            <ArrowRight className="ml-5" />
          ) : (
            <ArrowLeft className="ml-5" />
          )}
        </QButton>

        {initialValues.id && initialValues.phone && renderOTPModule()}
      </div>
      <div className="auth-section-footer"></div>
    </AuthLayout>
  );
}
