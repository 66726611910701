import React, { Fragment } from 'react'
import { Row, Col } from 'quantum_components'
import moment from 'moment'
import { IBriefResponse, IUploadPartner } from '../../brief.types'
import { MediaTypeIcon } from './Brief.MediaTypeCard'
import { Descriptions } from 'antd'
import { getFileNameFromUrl } from '../../../../utils'
import { getLocaleCost } from 'src/helpers/currency'
import { BRIEF_ADVERTISEMENT_LANGUAGES } from 'src/constants/brief'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
  partnerIds?: number[]
}

const BriefMedia = ({ briefView, onEdit, viewMode, partnerIds }: Props) => {
  const uploadMaterial = partnerIds
    ? briefView.uploadMaterial.filter((file: IUploadPartner) => partnerIds.includes(file.partnerId))
    : briefView.uploadMaterial

  return (
    <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>Media buying</h4>
        {!viewMode && <span onClick={onEdit}>Edit</span>}
      </div>
      <div className="media-buying mb-15">
        <div className="qu-card-media">
          <Row>
            <Col span={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">Desired Start Date</div>
                <div className="data-view-value">
                  {briefView.mediaStartDate ? moment(briefView.mediaStartDate)?.format('LL') : 'None'}
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">Marketing Objective</div>
                <div className="data-view-value">{briefView.marketingObjective || 'None'}</div>
              </div>
            </Col>
            <Col span={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">Duration</div>
                <div className="data-view-value">{briefView.mediaDuration || 'None'}</div>
              </div>
            </Col>
            <Col span={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">Budget</div>
                <div className="data-view-value">
                  {briefView.budgetValue ? (
                    <>
                      {getLocaleCost(briefView.budgetValue)} {briefView.budgetCurrency} ({briefView.budgetType})
                    </>
                  ) : (
                    <>{'None'}</>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="BSUnit__bList brief-view-mobile-media">
                {briefView.mediaTypes?.map((media: string) => (
                  <MediaTypeIcon key={media} name={media} />
                ))}
              </div>
            </Col>
            <Col>
              {uploadMaterial && (
                <div className="BSUnit__media brief-view-mobile-media">
                  {uploadMaterial.map((upload: any, i: number) => {
                    const name = upload.mediaSubType ? upload.mediaSubType : upload.mediaType
                    return (
                      <div className="media-item" key={i}>
                        <hr className="form__hr" />
                        <div className="media-item-label">{name}</div>
                        <div className="BSUnit__descriptions">
                          {
                            <Descriptions column={1}>
                              {upload.link && (
                                <Descriptions.Item label="Target Url">
                                  <a href={upload.link} target="_blank" rel="noopener noreferrer">
                                    {upload.link}
                                  </a>
                                </Descriptions.Item>
                              )}
                              {Object.entries(BRIEF_ADVERTISEMENT_LANGUAGES).map(([key, value]) => (
                                <Fragment key={key}>
                                  {upload.adTitle[key] && (
                                    <Descriptions.Item label={`${value} Title`}>
                                      {upload.adTitle[key]}
                                    </Descriptions.Item>
                                  )}
                                  {upload.adDescription[key] && (
                                    <Descriptions.Item label={`${value} Description`}>
                                      {upload.adDescription[key]}
                                    </Descriptions.Item>
                                  )}
                                  {upload.imageTargetUrl[key] && (
                                    <Fragment key={key}>
                                      <div className="media-item-image">
                                        {upload.imageTargetUrl[key].includes('.webm') ||
                                        upload.imageTargetUrl[key].includes('.mp4') ||
                                        upload.imageTargetUrl[key].includes('.ogg') ? (
                                          <video width="178" height="100" controls>
                                            <source
                                              src={upload.imageTargetUrl[key]}
                                              type={`video/${
                                                upload.imageTargetUrl[key].split('.')[
                                                  upload.imageTargetUrl[key].split('.').length - 1
                                                ]
                                              }`}
                                            />
                                            Your browser does not support HTML video.
                                          </video>
                                        ) : (
                                          <img src={upload.imageTargetUrl[key]} alt={name} />
                                        )}
                                      </div>
                                      <div className="media-item-hint">
                                        {getFileNameFromUrl(upload.imageTargetUrl[key])}
                                        <span>
                                          {upload.width}
                                          {upload.height && `x ${upload.height}`}
                                        </span>
                                      </div>
                                    </Fragment>
                                  )}
                                </Fragment>
                              ))}
                            </Descriptions>
                          }
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
export default BriefMedia
