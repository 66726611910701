export const MODULE_NAME = 'partnerProfile'

export const SET_PARTNER_PROFILE_SUCCESS = `${MODULE_NAME}/SET_PARTNER_PROFILE_SUCCESS`
export const SET_UNFINISHED = `${MODULE_NAME}/SET_UNFINISHED`
export const SET_CURRENT_STEP = `${MODULE_NAME}/SET_CURRENT_STEP`
export const SET_LAST_FINISHED = `${MODULE_NAME}/SET_LAST_FINISHED`
export const SET_FILLED = `${MODULE_NAME}/SET_FILLED`
export const SET_PARTNER_SETTING = `${MODULE_NAME}/SET_PARTNER_SETTING`
export const SET_FORM = `${MODULE_NAME}/SET_FORM`
export const FETCH_PARTNER_PROFILE_REQUEST = `${MODULE_NAME}/FETCH_PARTNER_PROFILE_REQUEST`
export const FETCH_PARTNER_PROFILE_SUCCESS = `${MODULE_NAME}/FETCH_PARTNER_PROFILE_SUCCESS`
export const FETCH_PARTNER_PROFILE_FAILURE = `${MODULE_NAME}/FETCH_PARTNER_PROFILE_FAILURE`
export const SET_PARTNER_PROFILE_PERCENT = `${MODULE_NAME}/SET_PARTNER_PROFILE_PERCENT`
export const FETCH_PARTNER_PROFILE_LOADING = `${MODULE_NAME}/FETCH_PARTNER_PROFILE_LOADING`

export const IMAGE_UPLOAD_REQUEST = `${MODULE_NAME}/IMAGE_UPLOAD_REQUEST`
export const IMAGE_UPLOAD_SUCCESS = `${MODULE_NAME}/IMAGE_UPLOAD_SUCCESS`
export const IMAGE_UPLOAD_FAILURE = `${MODULE_NAME}/IMAGE_UPLOAD_FAILURE`

export const IMAGE_REMOVE_REQUEST = `${MODULE_NAME}/IMAGE_REMOVE_REQUEST`
export const IMAGE_REMOVE_SUCCESS = `${MODULE_NAME}/IMAGE_REMOVE_SUCCESS`
export const IMAGE_REMOVE_FAILURE = `${MODULE_NAME}/IMAGE_REMOVE_FAILURE`
