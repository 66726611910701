import React, { ReactElement, useEffect } from 'react'
import { useReduxDispatch } from 'src/helpers'
import * as actions from '../request.actions'
import { useSelector } from 'react-redux'
import { getRequestInfo, getRequestList, getRequestListLoading, getRequestParams } from '../request.selectors'
import RequestTableComponent from '../../../../components/requestsTable/RequestTable.component'

export default function RequestListContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const requestList = useSelector(getRequestList)
  const params = useSelector(getRequestParams)
  const requestInfo = useSelector(getRequestInfo)
  const requestListLoading = useSelector(getRequestListLoading)

  useEffect(() => {
    dispatch(actions.fetchRequestInfo())
    dispatch(actions.setParamsAndFetch({ ...params, search: '', offset: 0, limit: 10 }))
    dispatch(actions.setCurrentPage(1))
  }, [])

  return (
    <div className="dashboard-layout">
      <RequestTableComponent requestList={requestList} loading={requestListLoading} requestInfo={requestInfo} />
    </div>
  )
}
