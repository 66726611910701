// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from 'src/helpers'
import { IconsCommon, Modal, QButton, qnotification } from 'quantum_components'
import { actions } from '../specialClients.module'
import * as briefActions from '../../../modules/brief/brief.actions'
import * as logoutActions from '../../../modules/core/core.actions'
import { useHistory, useParams } from 'react-router-dom'
import Logo from 'src/assets/co-branding-logo.png'
import ReCAPTCHA from 'react-google-recaptcha'
import { LOCAL_STORAGE_KEYS, PATHS, LOCALIZATION_LANGUAGES, NOTIFICATION_TYPES } from 'src/constants'
import { ReactComponent as CircleCheckIcon } from 'src/assets/icons/circle-check-icon.svg'
import { GlobalOutlined } from '@ant-design/icons'
import {
  getBrief,
} from '../../../modules/brief/brief.selectors'
import {
  BRIEF_TYPES,
  BRIEF_STEPS,
} from "src/constants/brief";

const closeIcon = <IconsCommon.IconClose />

const clearLocalStorageKeys = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ROLE)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN)
  sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO)
}

const SpecialClientsContainer = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationInviteClient', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const history = useHistory()
  const brief = useSelector(getBrief)
  const [partnerData, setPartnerData] = useState<any>(false)
  const [briefData, setbriefData] = useState<any>()
  const [modalVisible, setModalVisible] = useState<any>(false)
  const [reCaptcha, setRecaptcha] = useState<string>('')
  const [reCaptchaError, setRecaptchaError] = useState<boolean>(false)
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''
  const [isPartnerModalVisible, setIsPartnerModalVisible] = useState<any>(false)
  const [showLangLoader, setShowLangLoader] = useState(false)
  const [langUpdate, setlangUpdate] = useState(0)

  const sysLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION) || 'ar'

  let { id } = useParams<{ id: string }>()

  useEffect(() => {
    clearLocalStorageKeys()
    history.push("/login")
    // fetchCoBrandingData()
  }, [])

  useEffect(() => {
    // i18n.changeLanguage(
    //   sysLang == LOCALIZATION_LANGUAGES.ARABIC ? LOCALIZATION_LANGUAGES.ARABIC : LOCALIZATION_LANGUAGES.ENGLISH
    // )

    i18n.changeLanguage(
      sysLang === LOCALIZATION_LANGUAGES.ARABIC
        ? LOCALIZATION_LANGUAGES.ARABIC
        : LOCALIZATION_LANGUAGES.ENGLISH
    )
    localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION)
      ?? localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALIZATION, LOCALIZATION_LANGUAGES.ARABIC)
  }, [])

  const getLocalizationLanguage = () => {
    return sysLang === LOCALIZATION_LANGUAGES.ENGLISH ? 'العربية' : 'English'
  }

  const changeLanguage = () => {
    setShowLangLoader(true)
    if (sysLang && sysLang === LOCALIZATION_LANGUAGES.ENGLISH) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALIZATION, LOCALIZATION_LANGUAGES.ARABIC)
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ARABIC)
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALIZATION, LOCALIZATION_LANGUAGES.ENGLISH)
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ENGLISH)
    }
    setlangUpdate(langUpdate + 1)
    hideLanguageLoader()
  }

  const hideLanguageLoader = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    setShowLangLoader(false)
  }

  const fetchCoBrandingData = async () => {
    const verifiedInviteClientLink: boolean = await dispatch(actions.getVerifyInviteClient(id))
    if (!verifiedInviteClientLink) {
      qnotification({ type: NOTIFICATION_TYPES.ERROR, message: t("Invite Client Link is not correct") })
      history.push(PATHS.ROOT)
    }

    if (
      localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLE) === 'PARTNER'
    ) {
      setIsPartnerModalVisible(true)
    } else {
      setIsPartnerModalVisible(false)
      let data: any = await dispatch(actions.getCoBrandingData(id))
      setPartnerData(data)
    }
  }

  const handleReCaptcha = (token: string | null) => {
    if (token) {
      setRecaptcha(token)
      setRecaptchaError(false)
    } else {
      setRecaptcha('')
      setRecaptchaError(true)
    }
  }

  const handleContinue = async () => {
    let deleteResponse: any = await dispatch(actions.deleteBrief(briefData.exclusiveBrief.id))
    if (deleteResponse == 200) {
      setModalVisible(false)
      // history.push(`/briefs/create`)

      let updatedBrief = { ...brief };
      updatedBrief[BRIEF_STEPS.TYPE].briefTypes = [BRIEF_TYPES.MEDIA_BUYING];
      await dispatch(briefActions.createBrief(updatedBrief))
    }
  }

  const handleDiscard = () => {
    setModalVisible(false)
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
    history.push(`/briefs/edit/${briefData.exclusiveBrief.id}`)
  }

  const handleWorkWithPartner = async () => {
    clearLocalStorageKeys()
    let updatedBrief = { ...brief };
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = [BRIEF_TYPES.MEDIA_BUYING];

    if (get(partnerData, 'publicProfile.companyLogo'))
      localStorage.setItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO, partnerData.publicProfile.companyLogo)
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID, id)
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID, id)
    }

    if (
      (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) ||
      (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN))
    ) {
      let briefData: any = await dispatch(
        actions.getInviterBriefDetails({
          data: localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
            ? sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            : localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID),
        })
      )
      setbriefData(briefData)
      if (briefData.exclusiveBrief) {
        setModalVisible(true)
      } else {
        // history.push(`/briefs/create`)

        await dispatch(briefActions.createBrief(updatedBrief))
      }
    } else if (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
      // history.push(`/briefs/create`)

      await dispatch(briefActions.createBrief(updatedBrief))
    } else {
      let token: any = await dispatch(actions.getInviterSpecialToken())
      // console.log('handleWorkWithPartner token', token)
      if (token.token) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN, token.token)
        // history.push(`/briefs/create`)
        updatedBrief.id = token.id
        await dispatch(briefActions.createBrief(updatedBrief))
      }
    }
  }

  // const handleCreateBriefClick = async (id?: number) => {
  //   let updatedBrief = { ...brief };
  //   updatedBrief[BRIEF_STEPS.TYPE].briefTypes = [BRIEF_TYPES.MEDIA_BUYING];
  //   if (id) updatedBrief["promotionId"] = id;
  // };

  const handleLogout = async () => {
    await dispatch(logoutActions.logout())
    fetchCoBrandingData()
  }

  return partnerData ? (
    <div className={`co-branding ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}>
      {/*<header className="qu-header">
        <a href="https://www.quantums.com.sa/" target="_blank">
          <img src={Logo} width="150" height="250" />
        </a>
      </header>*/}
      {partnerData?.logo && (
        <img src={partnerData?.logo} className="partner-logo" width="300" />
      )}
      <h1 className="partner-title">
        {t('Excited to see you here', { partnerName: partnerData?.companyName })}
      </h1>
      <p className="partner-desc-para">
        {t('You are a few clicks')}<br />
        <strong>{t('Whats next')}</strong>
        <br />
        {t('Brief us and tell us more about your brand')}
      </p>

      {partnerData && (
        <div>
          <div className="reCaptcha">
            <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleReCaptcha} />
          </div>
          {reCaptchaError && <div className="reCaptcha text-red font-size-12 mt-20">{t('Please complete the CAPTCHA')}</div>}
          <QButton
            className="partner-logo-button"
            type="primary"
            onClick={() => {
              reCaptcha != '' ? handleWorkWithPartner() : setRecaptchaError(true)
            }}
          >
            {t('Lets Work Together')}
          </QButton>
          <div className="footer">
            {get(partnerData, 'companyWebsite') &&
              <a href={partnerData.companyWebsite} target="_blank">
                {t('Learn more about', { partnerName: partnerData?.companyName })}
              </a>
            }
            {sysLang && (
              <p>
                <a onClick={() => changeLanguage()} className="switch">
                  <span className="user-menu-icon">
                    <GlobalOutlined />
                  </span>
                  {getLocalizationLanguage()}
                </a>
              </p>
            )}
          </div>
        </div>
      )}

      <Modal
        wrapClassName={`qu-modal ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}
        title={<h3>{t('You have an existing brief!')}</h3>}
        closeIcon={closeIcon}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={false}
      >
        <div>
          <p className="mb-25">
            {t('Would you like to continue', { partnerName: briefData?.exclusiveBrief?.exclusivePartner?.companyName })}
          </p>
          <div className="align-vertical text-center">
            <QButton onClick={handleDiscard} className="w-280 mb-20" type="primary">
              <span className="mr-5">{t('Continue')}</span>
              <CircleCheckIcon />
            </QButton>
            <button onClick={handleContinue} type="button" className="button button--link mb-10">
              {t('Discard and create new')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  ) : (
    <div className="co-branding">
      <Modal
        wrapClassName="qu-modal"
        title={<h3>{'Preview your invite link'}</h3>}
        closeIcon={closeIcon}
        visible={isPartnerModalVisible}
        onCancel={() => setIsPartnerModalVisible(false)}
        footer={false}
      >
        <div>
          <p className="mb-25">In order to preview your invite link, you will need to be logged out.</p>
          <div className="align-vertical text-center">
            <QButton onClick={handleLogout} className="w-280 mb-20" type="primary">
              <span className="mr-5">Logout And Continue</span>
              <CircleCheckIcon />
            </QButton>
            <button onClick={() => history.push(PATHS.ROOT)} type="button" className="button button--link mb-10">
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SpecialClientsContainer
