import { createSelector } from 'reselect'
import { IState, IDepartmentsList, ITeamMembersResponse, IPermissionsList, IlocateOptions } from './teamMembers.types'
import { MODULE_NAME } from './teamMembers.constants'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getDepartmentsList = createSelector(
  selectState,
  (state: IState): IDepartmentsList[] => state.departmentsList
)

export const getTeamMembersList = createSelector(
  selectState,
  (state: IState): ITeamMembersResponse => state.teamMembersList
)

export const getPermissionsList = createSelector(
  selectState,
  (state: IState): IPermissionsList[] => state.permissionLevels
)

export const getLocateOptions = createSelector(selectState, (state: IState): IlocateOptions[] => state.locateOptions)
