import { PATHS } from 'src/constants'
import { MENU_ITEMS } from '../constants/menu'

export const activeMenuItem = (path: string) => {
  if (path.includes(PATHS.REQUESTS)) {
    return MENU_ITEMS.REQUESTS
  }

  if (path === PATHS.BILLINGS) {
    return MENU_ITEMS.BILLINGS
  }

  if (path.includes('campaigns')) {
    return MENU_ITEMS.CAMPAIGNS
  }

  if (path === PATHS.PARTNER_PROFILE) {
    return MENU_ITEMS.PARTNER_PROFILE
  }

  if (path === PATHS.PUBLIC_PROFILE) {
    return MENU_ITEMS.PUBLIC_PROFILE
  }

  if (path === PATHS.TEAM_MEMBERS) {
    return MENU_ITEMS.TEAM_MEMBERS
  }

  if (path === PATHS.INVITE_CLIENT) {
    return MENU_ITEMS.INVITE_CLIENT
  }

  if (path === PATHS.PARTNER_API) {
    return MENU_ITEMS.PARTNER_API
  }

  return MENU_ITEMS.DASHBOARD
}
