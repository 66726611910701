const MODULE_NAME = 'partner'

export const getCurrentSaasPartner = (): string => `p/saaspartner`
export const getCurrentPartnerUserUrl = (): string => `${MODULE_NAME}/current`
export const getPublicProfileUrl = (): string => `${MODULE_NAME}/public-profile`
export const getPartnerProfileUrl = (): string => `${MODULE_NAME}/partner-profile`
export const getPublicProfileSaveUpdatesUrl = (): string => `${MODULE_NAME}/public-profile/save`
export const getPartnerProfileSaveUpdatesUrl = (): string => `${MODULE_NAME}/partner-profile/save`
export const getPublicProfileUploadUrl = (): string => `${MODULE_NAME}/public-profile/upload`
export const getPartnerSettingsUrl = (): string => `${MODULE_NAME}/get-settings`
export const getPartnerUpdatePasswordUrl = (): string => `${MODULE_NAME}/change-password`
export const getPartnerUpdateEmailUrl = (): string => `${MODULE_NAME}/change-email`
export const getPartnerDetailsUrl = (userId: number): string => `${MODULE_NAME}/marketplace-details/${userId}`
export const getPartnerRequestsListUrl = (): string => `${MODULE_NAME}/request/list`
export const getPartnerRequestInfoUrl = (): string => `${MODULE_NAME}/request/quick-info`
export const getPartnerRequestDetailsUrl = (requestId: number): string => `${MODULE_NAME}/request/${requestId}`
export const getPartnerRequestActionUrl = (): string => `${MODULE_NAME}/request/action-partner`
export const getMediaTypesUploadUrl = (): string => `${MODULE_NAME}/upload`
export const getMediaTypesRemoveMediaUrl = (): string => `${MODULE_NAME}/remove-media` // not used
export const getStatisticCardsUrl = (): string => `${MODULE_NAME}/statistic-cards`
export const getChartsUrl = (): string => `${MODULE_NAME}/charts`
export const getopenRequestsUrl = (): string => `${MODULE_NAME}/request/open`
export const getLiveCampaignsUrl = (): string => `${MODULE_NAME}/campaigns/live`
export const postAddDepartmentUrl = (): string => `${MODULE_NAME}/departments/create`
export const putDepartmentUrl = (departmentId: number): string => `${MODULE_NAME}/departments/edit/${departmentId}`
export const deleteDepartmentUrl = (departmentId: number): string => `${MODULE_NAME}/departments/delete/${departmentId}`
export const postAddTeamMemberUrl = (): string => `${MODULE_NAME}/members/sign-up`
export const patchTeamMemberUrl = (memberId: number): string => `${MODULE_NAME}/members/save/${memberId}`
export const deleteTeamMemberUrl = (userId: number): string => `${MODULE_NAME}/members/delete/${userId}`
export const getTeamMembersListUrl = (): string => `${MODULE_NAME}/departments/tree`
export const postInviteClient = (): string => `${MODULE_NAME}/invite-client/send-invite`
export const getInviteClient = (): string => `${MODULE_NAME}/invite-client`
export const generatePartnerAPITokenUrl = (): string => `${MODULE_NAME}/partnerApi/generate-token`
export const partnerAPIListingUrl = (): string => `${MODULE_NAME}/partnerApi`
export const getDepartmentsListUrl = (): string => `${MODULE_NAME}/departments/list`
export const getUpdatePartnerBillFilesLinksUrl = (): string => `${MODULE_NAME}/bill/edit`
