import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import * as CONSTANTS from './activateAccount.constants'
import { LOCAL_STORAGE_KEYS, PATHS, ROLES, NOTIFICATION_TYPES, LOCALIZATION_LANGUAGES } from 'src/constants'
import { getResponseErrorMessage } from 'src/helpers'
import history from '../../../utils/history'
import localStorage from 'redux-persist/es/storage'
import { qnotification } from 'quantum_components'
import { signInSuccess, signInFailure } from 'src/modules/registration/signIn/signIn.actions'
import { getUser } from '../../core/core.actions'

export const activateAccountRequest = (): IAction => ({
  type: CONSTANTS.ACTIVATE_ACCOUNT_REQUEST,
})

export const activateAccountSuccess = (): IAction => ({
  type: CONSTANTS.ACTIVATE_ACCOUNT_SUCCESS,
})

export const activateAccountFailure = (error: string): IAction => ({
  type: CONSTANTS.ACTIVATE_ACCOUNT_FAILURE,
  error,
})

export const activateAccount = (token: string, briefId?:string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(activateAccountRequest())
    const response = await requestHttp.post(urls.getActivateAccountUrl(), { token })

    if (response.status === 201) {
      dispatch(activateAccountSuccess())
      const { content } = response.data.data
      if (![ROLES.CLIENT].includes(content.role)) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Authorization Forbidden' })
        dispatch(signInFailure('Authorization Forbidden'))
        //@ts-ignore
        return response
      }
      dispatch(signInSuccess(content.email, content.token, content.role))
      await localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ROLE, content.role)
      await localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, content.token)

      if (!(await localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION))) {
        await localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALIZATION, LOCALIZATION_LANGUAGES.ENGLISH)
      }
      dispatch(getUser())
      history.push(PATHS.BRIEF)
    }
    
  } catch (error) {
    dispatch(activateAccountFailure(getResponseErrorMessage(error)))
    history.push(PATHS.SIGN_IN)
  }
}

export const activateAccountCognito = (confirmationCode: string, id:string, email:string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(activateAccountRequest())
    console.log(1112, email,id, confirmationCode)
    const response = await requestHttp.post(urls.getConfirmSignUpUrl(), { confirmationCode, id, email })

    if (response.status === 201) {
      dispatch(activateAccountSuccess())
      const { content } = response.data.data
      if (![ROLES.CLIENT].includes(content.role)) {
        qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Authorization Forbidden' })
        dispatch(signInFailure('Authorization Forbidden'))
        //@ts-ignore
        return response
      }
      dispatch(signInSuccess(content.email, content.token, content.role))
      await localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ROLE, content.role)
      await localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, content.token)

      if (!(await localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION))) {
        await localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALIZATION, LOCALIZATION_LANGUAGES.ENGLISH)
      }
      dispatch(getUser())
      history.push(PATHS.BRIEF)
    }
    
  } catch (error) {
    dispatch(activateAccountFailure(getResponseErrorMessage(error)))
    history.push(PATHS.SIGN_IN)
  }
}
