const MODULE_NAME = 'client'

export const getClientUploadUrl = (): string => `${MODULE_NAME}/profile/upload`

export const getClientDownloadUrl = (): string => `${MODULE_NAME}/media/`

export const getCurrentClientUserUrl = (): string => `${MODULE_NAME}/current`

export const getEditClientUrl = (): string => `${MODULE_NAME}/profile/save`

export const getClientSettingsUrl = (): string => `${MODULE_NAME}/get-settings`

export const getClientProfileUrl = (): string => `${MODULE_NAME}/profile`

export const getClientUpdatePasswordUrl = (): string => `${MODULE_NAME}/change-password`

export const getClientUpdateEmailUrl = (): string => `${MODULE_NAME}/change-email`

export const getMarketplacePartnersUrl = (): string => `${MODULE_NAME}/marketplace`

export const getMarketplacePartnerDetailsUrl = (partnerId: string | number): string =>
  `${MODULE_NAME}/marketplace/details/${partnerId}`

export const getCreateReferralUrl = (): string => `${MODULE_NAME}/referrals/create`

export const getClientCouponsList = (): string => `${MODULE_NAME}/coupons/list`
