import { qnotification } from 'quantum_components'
import NOTIFICATION_TYPES from '../constants/notificationTypes'
import { hasErrorFields } from '../utils/validationRules'
import {
  ICity,
  ICountry,
  IMediaBuyStep,
  IProductStep,
  IServicesStep,
  ITargetingStep,
} from '../modules/brief/brief.types'
import { ICountry as ITeshahelCountry } from '../modules/client/testahelBox/testahelBox.types'
import {
  ICustomizedStep,
  IConsumerResearchStep,
  IServicesStep as ITestahelServicesStep,
} from 'src/modules/client/testahelBox/testahelBox.types'
import { PROMOTION_TYPE } from 'src/constants/brief'

const NOT_REQUIRED_PRODUCT_FIELDS = ['productImages', 'instructions']
const NOT_REQUIRED_PRODUCT_FIELDS_FOR_TESTAHEL = ['duration', 'productImages', 'instructions']
const NOT_REQUIRED_CUSTOMIZED_FIELDS = ['customizeBoxProductImages', 'customizeBoxAdditionalComment']
const NOT_REQUIRED_PRODUCT_FIELDS_ONLY_MEDIA_BUY = [
  'productImages',
  'quantity',
  'distributionItem',
  'briefStartDate',
  'duration',
  'instructions',
]
const NOT_REQUIRED_SERVICES_FIELDS_PACKAGING = ['serviceImages', 'serviceTemperature']
const NOT_REQUIRED_SERVICES_FIELDS = [
  'serviceImages',
  'copackingPriceId',
  'servicePackagingQuantity',
  'serviceTemperature',
]
const NOT_REQUIRED_SERVICES_FIELDS_ONLY_INSERT = [
  'serviceImages',
  'copackingPriceId',
  'servicePackagingQuantity',
  'serviceIsLiquid',
  'serviceIncludingCoupon',
  'serviceDesign',
  'servicePackaging',
  'servicePackageType',
  'serviceTemperature',
]
const NOT_REQUIRED_TARGETING_FIELDS = ['targetDistricts']

export const validateImage = (file: File) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

  if (!isJpgOrPng) {
    qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'You can only upload JPG and PNG images' })
  }
  const isLt20M = file.size / 1024 / 1024 < 20
  if (!isLt20M) {
    qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Image must be smaller than 20MB' })
  }

  return isJpgOrPng && isLt20M
}

export const validateCustomizedStep = (customizedStepFields: ICustomizedStep) => {
  return !hasErrorFields(customizedStepFields, NOT_REQUIRED_CUSTOMIZED_FIELDS)
}

export const validateVideo = (file: File) => {
  const videoType = file.type === 'video/webm' || file.type === 'video/ogg' || file.type === 'video/mp4'
  if (!videoType) {
    qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'You can only upload WEBM, OGG, and MP4 videos' })
  }

  const isLt5M = file.size / 1024 / 1024 < 1000
  if (!isLt5M) {
    qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Video must be smaller than 1GB' })
  }

  return videoType && isLt5M
}

export const validateApplication = (file: File) => {
  const isPdf = file.type === 'application/pdf'

  if (!isPdf) {
    qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'You can only upload PDF applications' })
  }

  const isLt20M = file.size / 1024 / 1024 < 20
  if (!isLt20M) {
    qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'File must be smaller than 20MB' })
  }

  return isPdf && isLt20M
}

export const validateProductStep = (productStepFields: IProductStep, hasSampleStep: boolean) => {
  
  if(!productStepFields.campaignPromotionType || productStepFields.campaignPromotionType === PROMOTION_TYPE.BRAND){
    if(!productStepFields.name || !productStepFields.productDescription){
      return false;
    }
  }

  if(!productStepFields.mediaStartDate){
    return false;
  }
  
  return true;
  
  // const skipProductFields = hasSampleStep ? NOT_REQUIRED_PRODUCT_FIELDS : NOT_REQUIRED_PRODUCT_FIELDS_ONLY_MEDIA_BUY
  
  // if (hasErrorFields(productStepFields, skipProductFields)) return false
  // if (productStepFields.quantity && productStepFields.quantity < 1) return false
  // if (
  //   (productStepFields.name && productStepFields.name.length < 3) ||
  //   (productStepFields.name && productStepFields.name.length > 50)
  // )
  //   return false
  // if (productStepFields.productType === 'Other') return false

  // return true
}

export const validateTestahelProductStep = (productStepFields: IProductStep, hasSampleStep: boolean) => {
  const skipProductFields = hasSampleStep
    ? NOT_REQUIRED_PRODUCT_FIELDS_FOR_TESTAHEL
    : NOT_REQUIRED_PRODUCT_FIELDS_ONLY_MEDIA_BUY

  if (hasErrorFields(productStepFields, skipProductFields)) return false
  if (productStepFields.quantity && productStepFields.quantity < 1) return false
  if (
    (productStepFields.name && productStepFields.name.length < 3) ||
    (productStepFields.name && productStepFields.name.length > 50)
  )
    return false
  if (productStepFields.productType === 'Other') return false

  return true
}

export const validateTargetingStep = (targetingFields: ITargetingStep, countries: ICountry[]) => {
  if(targetingFields.targetCountries.length > 0 && targetingFields.targetCities.length == 0){
    return false;
  }
  return true;
  // const isValid = validateCountriesAndCities(targetingFields, countries)
  // if (!isValid) return false
  // return !hasErrorFields(targetingFields, [])
}

export const validateTestahelTargetingStep = (targetingFields: ITargetingStep, countries: ICountry[]) => {
  const isValid = validateCountriesAndCities(targetingFields, countries)
  if (!isValid) return false
  return !hasErrorFields(targetingFields, ['targetDistricts'])
}

export const validateServicesStep = (servicesStepFields: IServicesStep, onlyInsert: boolean) => {
  const skipServiceFields = servicesStepFields.servicePackaging
    ? NOT_REQUIRED_SERVICES_FIELDS_PACKAGING
    : onlyInsert
    ? NOT_REQUIRED_SERVICES_FIELDS_ONLY_INSERT
    : NOT_REQUIRED_SERVICES_FIELDS

  if (!servicesStepFields.servicePrinting) {
    skipServiceFields.indexOf('printingPriceId') == -1 && skipServiceFields.push('printingPriceId')
  } else {
    skipServiceFields.indexOf('printingPriceId') > -1 &&
      skipServiceFields.splice(skipServiceFields.indexOf('printingPriceId'), 1)
  }

  return !hasErrorFields(servicesStepFields, skipServiceFields)
}

export const validateTestahelServicesStep = (servicesStepFields: ITestahelServicesStep, onlyInsert: boolean) => {
  const skipServiceFields = onlyInsert ? NOT_REQUIRED_SERVICES_FIELDS_ONLY_INSERT : NOT_REQUIRED_SERVICES_FIELDS

  if (!servicesStepFields.servicePrinting) {
    skipServiceFields.indexOf('printingPriceId') == -1 && skipServiceFields.push('printingPriceId')
  } else {
    skipServiceFields.indexOf('printingPriceId') > -1 &&
      skipServiceFields.splice(skipServiceFields.indexOf('printingPriceId'), 1)
  }

  return !hasErrorFields(servicesStepFields, skipServiceFields)
}

export const validateMediaBuyStep = (mediaBuyFields: IMediaBuyStep) => {
  return !hasErrorFields(mediaBuyFields, [])
}

export const validateConsumerResearchStep = (consumerResearchFields: IConsumerResearchStep) => {
  const SKIP_MARKETING_RESEARCH_FIELDS = ['consumerResearchNotes']
  return !hasErrorFields(consumerResearchFields, SKIP_MARKETING_RESEARCH_FIELDS)
}

export const validateCountriesAndCities = (targetingFields: ITargetingStep, settingsCountries: ICountry[]) => {
  // const countries = filterCountries(targetingFields.targetCountries, settingsCountries)
  const countries = targetingFields.targetCountries;
  const cities = targetingFields.targetCities

  if(countries.length > 0 && cities.length == 0){
    return false;
  }
  return true;

  // if (!countries.length) return false

  

  // const countryCities = countries.map((country: ICountry) => {
  //   return cities.filter((city: string) => {
  //     return country.city.find((c: ICity) => c.name === city)
  //   })
  // })

  // return countryCities.every((cities: string[]) => !!cities.length)
}

export const filterCountries = (selectedCountries: string[], countries: ICountry[]) => {
  return !selectedCountries.length ? countries : countries.filter((c: ICountry) => selectedCountries.includes(c.name))
}

export const filterTestahelCountries = (selectedCountries: string[], countries: ITeshahelCountry[]) => {
  return !selectedCountries.length
    ? countries
    : countries.filter((c: ITeshahelCountry) => selectedCountries.includes(c.name))
}
