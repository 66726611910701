export enum CURRENCY {
  USD = 'USD',
  SAR = 'SAR',
  AED = 'AED',
}

export const CURRENCY_SYMBOLS: { [key: string]: string } = {
  [CURRENCY.USD]: 'USD',
  [CURRENCY.AED]: 'AED',
  [CURRENCY.SAR]: 'SAR',
}

export const CURRENCIES = [CURRENCY.SAR, CURRENCY.AED, CURRENCY.USD]
