import moment from "moment"

export const getDateRangeBetweenDates = (startDate:any,days:number) => {
    // padding for 2 weeks
    days += 14;
    let dateArr:any = [];
    let fromDate = new Date(moment(startDate).format("MM-DD-YYYY"));
    let endDate = new Date(moment(startDate).add(days,'days').format("MM-DD-YYYY"));
    while(fromDate < endDate){
      dateArr.push(moment(fromDate).format('MM-DD-YYYY'));
      let newDate = fromDate.setDate(fromDate.getDate() + 1);
      fromDate = new Date(newDate);
    }
    return dateArr
  }

  export const calculateBudgetFeedback = (service: any) => {
    const selectedMetric = service?.metricData?.metricType;
    if (!selectedMetric) return;

    const simplifiedMetric =
      selectedMetric === "CPM" ? "impressions" : "clicks";

    const campaignBudget = Number(service?.metricData?.campaignBudget); //1000
    if (!campaignBudget) return false;

    const impressionsPerDay = Number(service?.impressionsPerDay); //1000
    if (isNaN(impressionsPerDay) || impressionsPerDay <= 0) return;

    // assuming 1% conversion from 1k impressions
    const clicksPerDay = impressionsPerDay * 0.01;

    let dailyBudget = Number(service?.metricData?.dailyBudget);
    if (isNaN(dailyBudget) || dailyBudget <= 0) dailyBudget = 0;

        // if the user specifed minDuration and dailyBudget
    // 10,000 I/D
    // 1,000/CPM
    // CB 10,000 = 10,000/1000 = 10CPM = 10*1000 = 10,000 impressions in total
    // DB 2,000 = 2000/1000 = 2CPM = 2*1000 = 2,000 impressions/day which should be served since the publisher can do 1K I/D = 
    // MD 5

    // 2000DB will give you 1000000/2000 = 500Impressions/day 
    let totalQuantity = 0;
    let dailyQuantity = 0;
    let estimatedDaysForTotalQuantity = 0;
    let estimatedDaysForDailyQuantity = 0;
    let dailyDistribution:any = [];
    let extraDays = 0;
    totalQuantity = Math.ceil(campaignBudget / service["pricePerMetric"][String(selectedMetric).toLowerCase()])
    if(selectedMetric === "CPM"){
      totalQuantity *= 1000;
      estimatedDaysForTotalQuantity = Math.ceil(totalQuantity/impressionsPerDay);
    }else{
      estimatedDaysForTotalQuantity = Math.ceil(totalQuantity/clicksPerDay);
    }
    

    // from here
    if(dailyBudget > 0){
      dailyQuantity = Math.ceil(dailyBudget / service["pricePerMetric"][String(selectedMetric).toLowerCase()])
      if(selectedMetric === "CPM"){
        dailyQuantity *= 1000;
        if(dailyQuantity > impressionsPerDay){
          extraDays = Math.ceil(dailyQuantity/impressionsPerDay);
        }
      }else{
        if(dailyQuantity > impressionsPerDay){
          extraDays = Math.ceil(dailyQuantity/clicksPerDay);
        }
      }

      estimatedDaysForDailyQuantity = Math.ceil(totalQuantity/dailyQuantity);
      
      if(extraDays > 0){
        estimatedDaysForDailyQuantity += extraDays
      }

      // get estimatedDaysForDailyQuantity as days + 21 days as padding and see the start date of the campaign
      if(service.startDate){
        const dr = getDateRangeBetweenDates(service.startDate,estimatedDaysForDailyQuantity);
        if(dr.length > 0){
          dailyDistribution = dr.map((d:any)=>{
            return {
              date:d,
              total:dailyQuantity,
              served:0
            }
          })
        }
      }
    }



    return {
      campaignBudget,
      simplifiedMetric,
      totalQuantity,
      estimatedDaysForTotalQuantity,
      dailyBudget,
      dailyQuantity,
      estimatedDaysForDailyQuantity,
      dailyDistribution,
      impressionsPerDay,
      clicksPerDay,
      selectedMetric
    }


    // return t("budget_helper", {
    //   budget,
    //   amount: Math.ceil(quantity),
    //   simplifiedMetric: t(simplifiedMetric),
    //   timeline: Math.ceil(timeline),
    // });
  };
