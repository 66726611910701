import React, { ReactElement } from 'react'
import { IBrief } from '../../testahelBox.types'
import BriefSummary from './TestahelBrief.summary'
import { IconsCommon, Popover } from 'quantum_components'

export interface IProps {
  brief: IBrief
}

export default function TestahelPopoverSummary({ brief }: IProps): ReactElement {
  const listIcon = <IconsCommon.IconList />

  return (
    <Popover
      overlayClassName="qu-summary-popover"
      content={<BriefSummary brief={brief} />}
      placement="bottomRight"
      trigger="click"
      arrowPointAtCenter
    >
      <div className="qu-summary-button">{listIcon}</div>
    </Popover>
  )
}
