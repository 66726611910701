import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { AxiosResponse } from "axios";

import { FORMATS, LOCAL_STORAGE_KEYS } from "src/constants";
import IAction from "src/interfaces/IAction";
import { requestHttp, urls } from "src/api";
import {
  IBrief,
  IBriefListElement,
  IBriefListResponse,
  IBriefPartnerDetails,
  IBriefPartnerDetailsResponse,
  IBriefRecommendedPartner,
  IBriefRecommendedPartnerResponse,
  IBriefResponse,
  IBriefSettings,
  IBriefSettingsResponse,
  IBriefTableParams,
  ICartItem,
  ICartList,
  ICartServiceItem,
  ICreateBriefResponse,
  IDeleteBriefResponse,
  IMediaBuyStep,
  IPartnerFilter,
  IPartnerFilterSettings,
  IPartnerFilterSettingsResponse,
  IProductStep,
  IProposal,
  IServicesStep,
  ITargetingStep,
  IUpdateBriefResponse,
  IUploadPartner,
  IUploadSettings,
  IUploadStep,
  IViewBriefDetailsResponse,
  IPotentialAudienceReachResponse,
  IPotentialAudienceReach,
  IPackagingPrintingPrice,
} from "./briefv3.types";
import * as CONSTANTS from "./briefv3.constants";
import { getResponseErrorMessage } from "src/helpers";
import {
  BRIEF_STEPS,
  BRIEF_TYPES,
  BRIEF_UPLOAD_TYPES,
  DIALOG_MODALS,
  DISTRIBUTION_TYPE,
  SERVICE_NAMES_FOR_TABLE,
  SERVICE_NAMES_FOR_BRIEF_TABLE,
  BRIEF_PAYMENT_UPLOAD_TYPES,
} from "../../constants/brief";
import { STATUS_CODES } from "../../constants";
import { upload } from "../core/core.actions";
import moment from "moment";
import { IBriefInitState } from "./briefv3.reducer";
import cloneDeep from "lodash/cloneDeep";
import { UPLOAD_ENTITY_KEYS } from "../../constants/upload";
import IUploadFile from "../../interfaces/IUploadFile";
import first from "lodash/first";
import history from "../../utils/history";
import {
  getBriefView,
  getIsRejectionFlow,
  getCopackingPrice,
  getPrintingPrice,
} from "./briefv3.selectors";
import { REQUEST_STATUS } from "../../constants/request";
import jsPDF from "jspdf";
import { IBillPaymentUploadTypes } from "../client/bills/bills.types";
import { isMobile } from "react-device-detect";

export const selectedPaymentType = (
  paymentType: BRIEF_PAYMENT_UPLOAD_TYPES
): IAction => ({
  type: CONSTANTS.SET_SELECTED_PAYMENT_TYPE,
  payload: { paymentType },
});

export const requestStart = (): IAction => ({
  type: CONSTANTS.REQUEST_START,
});

export const requestEnd = (): IAction => ({
  type: CONSTANTS.REQUEST_END,
});

export const setCurrentStep = (step: BRIEF_STEPS): IAction => ({
  type: CONSTANTS.SET_CURRENT_STEP,
  payload: { step },
});

export const setNeedCheckLastStep = (check: boolean): IAction => ({
  type: CONSTANTS.SET_NEED_CHECK_LAST_STEP,
  payload: { check },
});

export const setRejectionFlow = (rejection: boolean): IAction => ({
  type: CONSTANTS.SET_REJECTION_FLOW,
  payload: { rejection },
});

export const setDialogModal = (modal: DIALOG_MODALS | null): IAction => ({
  type: CONSTANTS.SET_DIALOG_MODAL,
  payload: { modal },
});

export const createBriefRequest = (): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_REQUEST,
});

export const createBriefSuccess = (brief: IBrief): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_SUCCESS,
  payload: { brief },
});

export const createBriefPricing = (
  copackingPrice: IPackagingPrintingPrice | null,
  printingPrice: IPackagingPrintingPrice | null
): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_PRICING,
  payload: { copackingPrice, printingPrice },
});

export const createBriefSuccessExclusivePartnerId = (
  exclusivePartnerId: number | null
): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_SUCCESS_EXCLUSIVE_PARTNER_ID,
  payload: { exclusivePartnerId },
});

export const createBriefFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_FAILURE,
  error,
});

export const briefListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_REQUEST,
});

export const briefListSuccess = (briefList: {
  results: IBriefListElement[];
  total: number;
}): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_SUCCESS,
  payload: { briefList },
});

export const briefListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_FAILURE,
  error,
});

export const viewBriefRequest = (): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_REQUEST,
});

export const viewBriefSuccess = (briefDetails: IBrief): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_SUCCESS,
  payload: { briefDetails },
});

export const viewBriefFailure = (error: string): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_FAILURE,
  error,
});

export const setBriefListParams = (params: IBriefTableParams): IAction => ({
  type: CONSTANTS.SET_BRIEF_LIST_PARAMS,
  payload: { params },
});

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
});

export const resetBriefState = (): IAction => ({
  type: CONSTANTS.RESET_BRIEF_STATE,
});

export const setPartnerFilterState = (filter: IPartnerFilter): IAction => ({
  type: CONSTANTS.SET_PARTNER_FILTER,
  payload: { filter },
});

export const resetPartnerFilterState = (): IAction => ({
  type: CONSTANTS.RESET_PARTNER_FILTER,
});

export const deleteBriefRequest = (): IAction => ({
  type: CONSTANTS.DELETE_BRIEF_REQUEST,
});
export const deleteBriefSuccess = (): IAction => ({
  type: CONSTANTS.DELETE_BRIEF_SUCCESS,
});
export const deleteBriefFailure = (error: string): IAction => ({
  type: CONSTANTS.DELETE_BRIEF_FAILURE,
  error,
});

export const fetchPartnerFilterSettingsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_FILTER_SETTINGS_REQUEST,
});

export const fetchPartnerFilterSettingsSuccess = (
  settings: IPartnerFilterSettings
): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_FILTER_SETTINGS_SUCCESS,
  payload: { settings },
});

export const fetchPartnerFilterSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_FILTER_SETTINGS_FAILURE,
  error,
});

export const imageUploadRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_REQUEST,
});

export const imageUploadSuccess = (brief: IBrief): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_SUCCESS,
  payload: { brief },
});

export const imageUploadFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_FAILURE,
  error,
});

export const imageUploadStepRequest = (key: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_STEP_REQUEST,
  payload: { key },
});

export const imageUploadStepSuccess = (
  key: string,
  brief: IBrief
): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_STEP_SUCCESS,
  payload: { key, brief },
});

export const imageUploadStepFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_STEP_FAILURE,
  error,
});

export const imageRemoveRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_REQUEST,
});

export const imageRemoveSuccess = (brief: IBrief): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_SUCCESS,
  payload: { brief },
});

export const imageRemoveFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_FAILURE,
  error,
});

export const fetchBriefSettingsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_BRIEF_SETTINGS_REQUEST,
});

export const fetchBriefSettingsSuccess = (
  settings: IBriefSettings
): IAction => ({
  type: CONSTANTS.FETCH_BRIEF_SETTINGS_SUCCESS,
  payload: { settings },
});

export const fetchBriefSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_SETTINGS_FAILURE,
  error,
});

export const fetchRecommendedPartnersRequest = (): IAction => ({
  type: CONSTANTS.FETCH_RECOMMENDED_PARTNERS_REQUEST,
});

export const fetchRecommendedPartnersSuccess = (
  partners: IBriefRecommendedPartner[]
): IAction => ({
  type: CONSTANTS.FETCH_RECOMMENDED_PARTNERS_SUCCESS,
  payload: { partners },
});

export const fetchRecommendedPartnersFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_RECOMMENDED_PARTNERS_FAILURE,
  error,
});

export const fetchPartnerDetailsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_DETAILS_REQUEST,
});

export const fetchPartnerDetailsSuccess = (
  partner: IBriefPartnerDetails
): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_DETAILS_SUCCESS,
  payload: { partner },
});

export const fetchPartnerDetailsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_DETAILS_FAILURE,
  error,
});

export const fetchCartRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CART_REQUEST,
});

export const fetchCartSuccess = (cart: ICartList): IAction => ({
  type: CONSTANTS.FETCH_CART_SUCCESS,
  payload: { cart },
});

export const fetchCartFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CART_FAILURE,
  error,
});

export const updateCartRequest = (): IAction => ({
  type: CONSTANTS.UPDATE_CART_REQUEST,
});

export const updateCartSuccess = (): IAction => ({
  type: CONSTANTS.UPDATE_CART_SUCCESS,
});

export const updateCartFailure = (): IAction => ({
  type: CONSTANTS.UPDATE_CART_FAILURE,
});

export const setActiveSinglePartner = (partnerId: number | null): IAction => ({
  type: CONSTANTS.SET_ACTIVE_SINGLE_PARTNER,
  payload: { partnerId },
});

export const setTotalQuantity = (totalQuantity: number): IAction => ({
  type: CONSTANTS.SET_TOTAL_QUANTITY,
  payload: { totalQuantity },
});

export const fetchUploadSettingsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_UPLOAD_SETTINGS_REQUEST,
});

export const fetchUploadSettingsSuccess = (
  uploadSettings: IUploadSettings[]
): IAction => ({
  type: CONSTANTS.FETCH_UPLOAD_SETTINGS_SUCCESS,
  payload: { uploadSettings },
});

export const fetchUploadSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_UPLOAD_SETTINGS_FAILURE,
  error,
});

// v3 actions async
export const deleteBriefAsync = async (id:any) => {
  try {
    const response = await requestHttp.delete<any>(
      urls.getV3DeleteBriefUrl(id)
    );
    const { content } = response.data.data;

    return content
  } catch (e) {
    console.log(JSON.stringify(e));
    return null;
  }
};

export const validateClientRequiredFieldsAsync = async(briefId:number) => {
      try {
        const response = await requestHttp.get(urls.getValidateClientRequiredFields(briefId))
        const { content } = response.data.data
        return content
      } catch (error) {
        console.log(error)
      }
    }



export const downloadProtectedMedia = async(url:string) => {
  try {
    const response = await requestHttp.get(urls.getCoreImage(url), {
      skipDownloadProgress: false,
      responseType: 'blob',
    })
  
    return response
  } catch (error) {
    return null;
  }
  
}

export const uploadBriefPaymentFile = async(files:any[],filesType:string, brief:any) => {
  const response:any = await upload(
    urls.getBriefUploadUrl(),
    files,
    brief.cart.briefId,
    BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
    UPLOAD_ENTITY_KEYS.BRIEF_ID,
    false,
    "2"
  );

  if (response.status === 201) {
    const briefFiles:any = {
      id: brief.payment.id,
      po: brief.payment.po?.map((url:any) => url),
      bankTransfer: brief.payment.bankTransfer?.map((url:any) => url),
      version:"2"
    }
    const { content: newFilesUrls }: { content: string[] } = response.data.data
    briefFiles[filesType] = [...newFilesUrls, ...(brief.payment[filesType]?.map((url:any) => url) ?? [])]

    await requestHttp.put(urls.getEditBillUrl(), briefFiles)
  }


  if (response.status === 201) {
    const { content } = response.data.data;
    return content;
  }
  return null;
}

export const removeBriefPaymentFile = async(urlToRemove:string,filesType:string, brief:any) => {
  const data = {
    briefId: String(brief.payment.briefId),
    entity: BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
    urls: [urlToRemove],
    version:"2"
  }
  const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), { data })
  console.log(response)
  if (response.status === 201) {
    const briefFiles:any = {
      id: brief.payment.id,
      po: brief.payment.po?.map((url:any) => url),
      bankTransfer: brief.payment.bankTransfer?.map((url:any) => url),
      version:"2"
    }
    briefFiles[filesType] = briefFiles[filesType]!.filter((oldFileUrl:any) => oldFileUrl !== urlToRemove)

    await requestHttp.put(urls.getEditBillUrl(), briefFiles)
  }
  
  if (response.status === 201) {
    const { content } = response.data.data;
    return content;
  }
  return null;
}

export const fetchEditBriefAsync = async (id: number) => {
  try {
    const response = await requestHttp.get<IViewBriefDetailsResponse>(
      urls.getViewBriefV3Url(id)
    );
    const { content } = response.data.data;

    const parsedBrief = parseBriefResponse(content);
    parsedBrief.id = id;

    return {
      ...parsedBrief,rawResponse:content
    };
  } catch (e) {
    console.log(JSON.stringify(e));
    return null;
  }
};

export const removeImageAsync = async (
  brief: IBrief,
  file: IUploadFile,
  entity: BRIEF_UPLOAD_TYPES
) => {
  try {
    const data = {
      briefId: `${brief.id}`,
      entity: entity,
      urls: [file.url],
      version:2
    };

    const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), {
      data,
    });
    if (response.status === 201) {
      const { content } = response.data.data;
      return content;
    }

    return null;
  } catch (error) {
    console.log(JSON.stringify(error));
    return null;
  }
};

export const uploadImageAsync = async (
  brief: any,
  files: File[],
  entity: BRIEF_UPLOAD_TYPES
) => {
  try {
    if (!brief.id) return null;

    const uploadUrl = urls.getBriefUploadUrl();
    const response = await upload(
      uploadUrl,
      files,
      brief.id,
      entity,
      UPLOAD_ENTITY_KEYS.BRIEF_ID,
      true,
      "2"
    );
    if (response.status === 201) {
      const { content } = response.data.data;
      console.log(content)
      return content;
    }

    return null;
  } catch (error) {
    console.log(JSON.stringify(error));
    return null;
  }
};

export const updateBriefAsync = async (briefDetails:any) => {
  try {
    const response = await requestHttp.put<any>(
      urls.getV3EditBriefUrl(),
      briefDetails
    );
    const { content } = response.data.data;

    return content
  } catch (e) {
    console.log(JSON.stringify(e));
    return null;
  }
};
// v3 actions end



export const fetchBriefList =
  (
    params: IBriefTableParams
  ): ThunkAction<
    Promise<AxiosResponse<IBriefListResponse>>,
    {},
    {},
    AnyAction
  > =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse<IBriefListResponse>> => {
    try {
      dispatch(briefListRequest());
      const response = await requestHttp.get<IBriefListResponse>(
        urls.getBriefListUrl(),
        { params }
      );
      const {
        content: { briefs, total },
      } = response.data.data;

      const briefList = briefs.map((brief) => {
        const productImage = first(brief.productImages)
          ? first(brief.productImages)
          : first(brief.allImages)?.src;

        return {
          key: brief.id,
          thumb:
            brief.isTestahel &&
            brief.briefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
              ? brief.customizeBoxProductImages &&
                brief.customizeBoxProductImages.length > 0
                ? brief.customizeBoxProductImages[0]
                : ""
              : productImage, // for mobile view QTableCardList
          product: {
            campaignName: brief.campaignName,
            mediaStartDate: moment(brief.mediaStartDate).format(
              FORMATS.DATE_LL_FORMAT
            ),
            productFirstImage:
              brief.isTestahel &&
              brief.briefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
                ? brief.customizeBoxProductImages &&
                  brief.customizeBoxProductImages.length > 0
                  ? brief.customizeBoxProductImages[0]
                  : ""
                : productImage,
            title:
              brief.isTestahel &&
              brief.briefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
                ? brief.customizeBoxBriefName
                : brief.name,
            created: moment(brief.createdAt).format(FORMATS.DATE_LL_FORMAT),
            isExistCouponCode: brief.isExistCouponCode,
            isPromotional: brief.isPromotional,
            discountText: brief.discountText,
          },
          services: brief.briefTypes
            ? brief.briefTypes
                // .sort()
                // .reverse()
                .map((elem: string) => {
                  const briefTitle =
                    elem === BRIEF_TYPES.PRODUCT_SAMPLE
                      ? brief.distributionItem ===
                        DISTRIBUTION_TYPE.PRINTED_INSERT
                        ? SERVICE_NAMES_FOR_BRIEF_TABLE.INSERT
                        : SERVICE_NAMES_FOR_BRIEF_TABLE.PRODUCT
                      : SERVICE_NAMES_FOR_BRIEF_TABLE[elem];
                  return {
                    title: briefTitle,
                    productStartDate: brief.briefStartDate
                      ? brief.isTestahel
                        ? moment(brief.briefStartDate).format(
                            FORMATS.DATE_MMM_YYYY_FORMAT
                          )
                        : moment(brief.briefStartDate).format(
                            FORMATS.DATE_LL_FORMAT
                          )
                      : "",
                    customizedStartDate: brief.customizeBoxStartDate
                      ? moment(brief.customizeBoxStartDate).format(
                          FORMATS.DATE_LL_FORMAT
                        )
                      : "",
                    consumerStartDate: brief.consumerResearchStartDate
                      ? moment(brief.consumerResearchStartDate).format(
                          FORMATS.DATE_LL_FORMAT
                        )
                      : "",
                    mediaStartDate: brief.mediaStartDate
                      ? moment(brief.mediaStartDate).format(
                          FORMATS.DATE_LL_FORMAT
                        )
                      : "",
                    productDuration: brief.duration,
                    mediaDuration: brief.mediaDuration,
                    quantity:
                      brief.isTestahel &&
                      brief.briefTypes.includes(
                        BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX
                      )
                        ? brief.customizeBoxDistributionCount
                        : brief.quantity,
                  };
                })
            : [],
          cart: brief.cart,
          status: { value: brief.status, id: brief.id },
          isTestahel: brief.isTestahel,
          paymentStatus: brief.payment ? { value: brief.payment.status } : null,
        } as IBriefListElement;
      });

      dispatch(briefListSuccess({ results: briefList, total: total }));
      return response;
    } catch (error) {
      dispatch(briefListFailure(getResponseErrorMessage(error)));
      return error;
    }
  };

export const fetchViewBriefDetails =
  (
    id: number
  ): ThunkAction<
    Promise<AxiosResponse<IViewBriefDetailsResponse>>,
    {},
    {},
    AnyAction
  > =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse<IViewBriefDetailsResponse>> => {
    try {
      dispatch(viewBriefRequest());
      const response = await requestHttp.get<IViewBriefDetailsResponse>(
        urls.getViewBriefUrl(id)
      );
      const { content } = response.data.data;

      dispatch(viewBriefSuccess(content));

      return response;
    } catch (error) {
      dispatch(viewBriefFailure(getResponseErrorMessage(error)));
      return error;
    }
  };

export const uploadViewBriefPaymentFile =
  ({
    files,
    filesType,
  }: {
    files: File[];
    filesType: IBillPaymentUploadTypes;
  }): ThunkAction<void, {}, {}, AnyAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: any
  ): Promise<void> => {
    try {
      dispatch(viewBriefFileUploadRequest(filesType));
      const brief = getBriefView(getState());
      const isPublicFile = false;
      const response = await upload(
        urls.getBriefUploadUrl(),
        files,
        brief.payment.briefId,
        BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
        UPLOAD_ENTITY_KEYS.BRIEF_ID,
        isPublicFile
      );

      if (response.status === 201) {
        const briefFiles = {
          id: brief.payment.id,
          po: brief.payment.po?.map((url) => url),
          bankTransfer: brief.payment.bankTransfer?.map((url) => url),
        };
        const { content: newFilesUrls }: { content: string[] } =
          response.data.data;

        briefFiles[filesType] = [
          ...newFilesUrls,
          ...(brief.payment[filesType]?.map((url) => url) ?? []),
        ];
        await requestHttp.put(urls.getUpdateBillFilesLinksUrl(), briefFiles);

        dispatch(viewBriefFileUploadSuccess({ filesType, newFilesUrls }));
      }
    } catch (error) {
      dispatch(viewBriefFileUploadFailure({ filesType, error }));
    }
  };

export const viewBriefFileUploadRequest = (
  filesType: IBillPaymentUploadTypes
): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_FILE_UPLOAD_REQUEST,
  payload: { filesType },
});

export const viewBriefFileUploadSuccess = ({
  filesType,
  newFilesUrls,
}: {
  filesType: IBillPaymentUploadTypes;
  newFilesUrls: string[];
}): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_FILE_UPLOAD_SUCCESS,
  payload: { filesType, newFilesUrls },
});

export const viewBriefFileUploadFailure = ({
  filesType,
  error,
}: {
  filesType: IBillPaymentUploadTypes;
  error: string;
}): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_FILE_UPLOAD_FAILURE,
  payload: { filesType, error },
});

export const removeViewBriefPaymentFile =
  (
    file: IUploadFile,
    filesType: IBillPaymentUploadTypes
  ): ThunkAction<void, {}, {}, AnyAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: any
  ): Promise<void> => {
    try {
      dispatch(viewBriefFileRemoveRequest(filesType));

      const urlToRemove = file.url;
      const brief = getBriefView(getState());
      const data = {
        briefId: String(brief.payment.briefId),
        entity: BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES,
        urls: [urlToRemove],
      };
      const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), {
        data,
      });

      if (response.status === 201) {
        const briefFiles = {
          id: brief.payment.id,
          po: brief.payment.po?.map((url) => url),
          bankTransfer: brief.payment.bankTransfer?.map((url) => url),
        };

        briefFiles[filesType] = briefFiles[filesType]!.filter(
          (oldFileUrl) => oldFileUrl !== urlToRemove
        );
        await requestHttp.put(urls.getUpdateBillFilesLinksUrl(), briefFiles);

        dispatch(viewBriefFileRemoveSuccess(filesType, urlToRemove));
      }
    } catch (error) {
      dispatch(viewBriefFileRemoveFailure({ filesType, error }));
    }
  };

export const viewBriefFileRemoveRequest = (
  filesType: IBillPaymentUploadTypes
): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_FILE_REMOVE_REQUEST,
  payload: { filesType },
});

export const viewBriefFileRemoveSuccess = (
  filesType: IBillPaymentUploadTypes,
  urlToRemove: string
): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_FILE_REMOVE_SUCCESS,
  payload: { filesType, urlToRemove },
});

export const viewBriefFileRemoveFailure = ({
  filesType,
  error,
}: {
  filesType: IBillPaymentUploadTypes;
  error: string;
}): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_FILE_REMOVE_FAILURE,
  payload: { filesType, error },
});


export const uploadProductImage =
  (
    brief: IBrief,
    files: File[],
    entity: BRIEF_UPLOAD_TYPES
  ): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      if (!brief.id) {
        return;
      }

      dispatch(imageUploadRequest());
      const uploadUrl = urls.getBriefUploadUrl();

      const response = await upload(
        uploadUrl,
        files,
        brief.id,
        entity,
        UPLOAD_ENTITY_KEYS.BRIEF_ID
      );

      if (response.status === 201) {
        const { content } = response.data.data;
        const newBrief = cloneDeep(brief);

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES) {
          newBrief[BRIEF_STEPS.PRODUCT].productImages = [
            ...newBrief[BRIEF_STEPS.PRODUCT].productImages,
            ...content,
          ];
        }

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES) {
          newBrief[BRIEF_STEPS.SERVICES].serviceImages = [
            ...newBrief[BRIEF_STEPS.SERVICES].serviceImages,
            ...content,
          ];
        }

        dispatch(imageUploadSuccess(newBrief));
      }
    } catch (error) {
      dispatch(imageUploadFailure(error));
    }
  };

export const uploadStepSaveImage =
  (
    brief: IBrief,
    files: File[],
    params: IUploadPartner,
    languageType: string
  ): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      if (!brief.id) {
        return;
      }

      const name = params.mediaSubType ? params.mediaSubType : params.mediaType;
      const key = name + "_" + params.partnerId + "_" + languageType;

      dispatch(imageUploadStepRequest(key));

      const uploadUrl = urls.getBriefUploadUrl();
      const response = await upload(
        uploadUrl,
        files,
        brief.id,
        BRIEF_UPLOAD_TYPES.BRIEFS_UPLOAD_IMAGES,
        UPLOAD_ENTITY_KEYS.BRIEF_ID
      );

      if (response.status === 201) {
        const { content } = response.data.data;
        const newBrief = cloneDeep(brief);

        const uploadMaterial: any = newBrief[BRIEF_STEPS.UPLOAD].uploadMaterial;
        const targetPartner: any = uploadMaterial.find(
          (uploadPartner: IUploadPartner) =>
            uploadPartner.partnerId === params.partnerId &&
            uploadPartner.mediaType === params.mediaType &&
            uploadPartner.mediaSubType === params.mediaSubType
        );
        if (targetPartner) {
          if (targetPartner.imageTargetUrl) {
            targetPartner.imageTargetUrl[languageType] = content[0];
          } else {
            targetPartner.imageTargetUrl = {};
            targetPartner.imageTargetUrl[languageType] = content[0];
          }
        } else {
          uploadMaterial.push({
            partnerId: params.partnerId,
            mediaType: params.mediaType,
            mediaSubType: params.mediaSubType,
            imageTargetUrl: {
              [languageType]: content[0],
            },
            link: null,
            height: params.height,
            width: params.width,
          } as IUploadPartner);
        }

        dispatch(imageUploadStepSuccess(key, newBrief));
      }
    } catch (error) {
      dispatch(imageUploadStepFailure(error));
    }
  };

export const removeImage =
  (
    brief: IBrief,
    file: IUploadFile,
    entity: BRIEF_UPLOAD_TYPES
  ): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse> => {
    try {
      dispatch(imageRemoveRequest());

      const data = {
        briefId: `${brief.id}`,
        entity: entity,
        urls: [file.url],
      };

      const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), {
        data,
      });

      if (response.status === 201) {
        const newBrief = { ...brief };

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES) {
          newBrief[BRIEF_STEPS.PRODUCT].productImages.splice(
            newBrief[BRIEF_STEPS.PRODUCT].productImages.indexOf(file.url),
            1
          );
        }

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES) {
          newBrief[BRIEF_STEPS.SERVICES].serviceImages = [];
        }

        dispatch(imageRemoveSuccess(newBrief));
      }
      return response;
    } catch (error) {
      dispatch(imageRemoveFailure(error));
      return error;
    }
  };

export const removeUploadStepImage =
  (
    brief: IBrief,
    service: any,
    languageType: string
  ): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse> => {
    try {
      dispatch(imageRemoveRequest());

      const data = {
        briefId: `${brief.id}`,
        entity: BRIEF_UPLOAD_TYPES.BRIEFS_UPLOAD_IMAGES,
        urls: [service.imageTargetUrl[languageType]],
      };

      const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), {
        data,
      });

      if (response.status === 201) {
        const newBrief = { ...brief };
        let target: any = newBrief[BRIEF_STEPS.UPLOAD].uploadMaterial.find(
          (item: any) =>
            item.imageTargetUrl[languageType] ===
            service.imageTargetUrl[languageType]
        );

        if (target) {
          target.imageTargetUrl[languageType] = null;
        }

        dispatch(imageRemoveSuccess(newBrief));
      }
      return response;
    } catch (error) {
      dispatch(imageRemoveFailure(error));
      return error;
    }
  };

export const deleteBrief =
  (
    id: number,
    params: IBriefTableParams
  ): ThunkAction<
    Promise<AxiosResponse<IDeleteBriefResponse>>,
    {},
    {},
    AnyAction
  > =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse<IDeleteBriefResponse>> => {
    try {
      dispatch(deleteBriefRequest());
      const response = await requestHttp.delete<IDeleteBriefResponse>(
        urls.getDeleteBriefUrl(id)
      );
      dispatch(deleteBriefSuccess());

      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        dispatch(fetchBriefList(params));
      }

      return response;
    } catch (error) {
      dispatch(deleteBriefFailure(getResponseErrorMessage(error)));
      return error;
    }
  };

export const setParamsAndFetch =
  (params: IBriefTableParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setBriefListParams(params));
    dispatch(fetchBriefList(params));
  };

export const createBrief =
  (brief: IBrief): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(createBriefRequest());
      let requestData;
      if (
        (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) ||
        (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) &&
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN))
      ) {
        requestData = {
          lastFilledStep: BRIEF_STEPS.TYPE,
          ...brief[BRIEF_STEPS.TYPE],
          exclusivePartnerData: localStorage.getItem(
            LOCAL_STORAGE_KEYS.ACCESS_TOKEN
          )
            ? sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            : localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID),
        };
      } else {
        requestData = {
          promotionId: brief.promotionId,
          lastFilledStep: BRIEF_STEPS.TYPE,
          ...brief[BRIEF_STEPS.TYPE],
        };
      }

      const response = await requestHttp.post<ICreateBriefResponse>(
        urls.getCreateBriefUrl(),
        requestData
      );

      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data;
        const newBrief = { ...brief };
        newBrief.id = content.briefId;
        dispatch(createBriefSuccess(newBrief));

        history.push(`/briefs/edit/${content.briefId}`);
        // isMobile ? history.push(`/briefs/v2/edit/${content.briefId}`) : history.push(`/briefs/edit/${content.briefId}`)
      }
    } catch (error) {
      dispatch(createBriefFailure(getResponseErrorMessage(error)));
    }
  };

export const updateBrief =
  (
    step: BRIEF_STEPS,
    brief: IBrief,
    totalBudget?: number
  ): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(createBriefRequest());

      const requestData = {
        id: brief.id,
        lastFilledStep: brief.lastFilledStep,
        status: brief.status,
        ...brief[step],
      };

      if (
        step === BRIEF_STEPS.PARTNER &&
        brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)
      ) {
        requestData["budgetValue"] = totalBudget;
      }

      const response = await requestHttp.patch<IUpdateBriefResponse>(
        urls.getEditBriefUrl() + step,
        requestData
      );
      const { content } = response.data.data;

      const parsedBrief = parseBriefResponse(content);
      parsedBrief.id = brief.id;

      dispatch(
        createBriefPricing(content.copackingPrice, content.printingPrice)
      );
      dispatch(createBriefSuccess(parsedBrief));
    } catch (error) {
      dispatch(createBriefFailure(getResponseErrorMessage(error)));
    }
  };

export const fetchEditBrief =
  (
    id: number
  ): ThunkAction<
    Promise<AxiosResponse<IViewBriefDetailsResponse>>,
    {},
    {},
    AnyAction
  > =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse<IViewBriefDetailsResponse>> => {
    try {
      dispatch(createBriefRequest());
      const response = await requestHttp.get<IViewBriefDetailsResponse>(
        urls.getViewBriefUrl(id)
      );
      const { content } = response.data.data;

      const parsedBrief = parseBriefResponse(content);
      parsedBrief.id = id;

      dispatch(
        createBriefSuccessExclusivePartnerId(content.exclusivePartnerId)
      );
      dispatch(
        createBriefPricing(content.copackingPrice, content.printingPrice)
      );
      dispatch(createBriefSuccess(parsedBrief));

      return response;
    } catch (error) {
      dispatch(createBriefFailure(getResponseErrorMessage(error)));
      return error;
    }
  };

export const fetchRecommendedPartners =
  (
    briefId: number,
    filter: IPartnerFilter
  ): ThunkAction<
    Promise<AxiosResponse<IBriefRecommendedPartnerResponse>>,
    {},
    {},
    AnyAction
  > =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse<IBriefRecommendedPartnerResponse>> => {
    try {
      const params = { briefId, ...filter };
      dispatch(fetchRecommendedPartnersRequest());

      const response = await requestHttp.get<IBriefRecommendedPartnerResponse>(
        urls.getBriefRecommendedPartnersUrl(),
        {
          params,
        }
      );
      const { content } = response.data.data;

      dispatch(fetchRecommendedPartnersSuccess(content.partners));
      return response;
    } catch (error) {
      dispatch(fetchRecommendedPartnersFailure(getResponseErrorMessage(error)));
      return error;
    }
  };

export const fetchPartnerDetails =
  (
    briefId: number,
    partnerId: number
  ): ThunkAction<
    Promise<AxiosResponse<IBriefPartnerDetailsResponse>>,
    {},
    {},
    AnyAction
  > =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse<IBriefPartnerDetailsResponse>> => {
    try {
      dispatch(fetchPartnerDetailsRequest());

      const response = await requestHttp.get<IBriefPartnerDetailsResponse>(
        urls.getBriefPartnerDetailsUrl(briefId, partnerId)
      );
      const { content } = response.data.data;

      dispatch(fetchPartnerDetailsSuccess(content));
      return response;
    } catch (error) {
      dispatch(fetchPartnerDetailsFailure(getResponseErrorMessage(error)));
      return error;
    }
  };

export const addToCart =
  (data: ICartList): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: any
  ): Promise<void> => {
    try {
      dispatch(updateCartRequest());
      const isRejectionFlow = getIsRejectionFlow(getState());
      const url = isRejectionFlow
        ? urls.getAddToCartLinearUrl()
        : urls.getAddToCartUrl();
      const requestData: any = cloneDeep(data);
      requestData.additional.copackingRate = getCopackingPrice(
        getState()
      )?.rate;
      requestData.additional.printingRate = getPrintingPrice(getState())?.rate;
      await requestHttp.post(url, requestData);

      dispatch(updateCartSuccess());
    } catch (error) {
      dispatch(updateCartFailure());
    }
  };

export const fetchCart =
  (briefId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fetchCartRequest());
      const response = await requestHttp.get(
        urls.getFetchCartLinearUrl(briefId)
      );
      const { content } = response.data.data;

      const cart = content;
      cart.additional.items.map((item: ICartItem) => {
        item.isApprovedByUser = !item.services.some(
          (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.REJECTED
        );
        return item;
      });

      dispatch(fetchCartSuccess(cart));
    } catch (error) {
      dispatch(fetchCartFailure(getResponseErrorMessage(error)));
    }
  };

export const fetchBriefSettings =
  (): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fetchBriefSettingsRequest());
      const response = await requestHttp.get<IBriefSettingsResponse>(
        urls.getBriefSettingsUrl()
      );

      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        response.data.data.content.countries.map((ele) => {
          ele.city.unshift(
            {
              id: 0,
              name: `Select All Cities`,
              name_ar: `Select All Cities`,
              country_id: 0,
            },
            {
              id: -1,
              name: `Deselect All Cities`,
              name_ar: `Deselect All Cities`,
              country_id: 0,
            }
          );
        });
        const { content } = response.data.data;
        dispatch(fetchAgeGroups(content));
      }
    } catch (error) {
      dispatch(fetchBriefSettingsFailure(getResponseErrorMessage(error)));
    }
  };

export const fetchAgeGroups =
  (settings: any): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.get(urls.getAgeGroupsUrl());
      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data;
        if (Array.isArray(content)) {
          settings.ageGroups = content;
        }

        dispatch(fetchTargetSegments(settings));
      }
    } catch (error) {
      dispatch(fetchBriefSettingsFailure(getResponseErrorMessage(error)));
    }
  };

export const fetchTargetSegments =
  (settings: any): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.get(urls.getTargetSegmentsUrl());
      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data;
        if (Array.isArray(content)) {
          settings.targetSegments = content;
        }

        dispatch(fetchBriefSettingsSuccess(settings));
      }
    } catch (error) {
      dispatch(fetchBriefSettingsFailure(getResponseErrorMessage(error)));
    }
  };

export const fetchPartnerFilterSettings =
  (): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fetchPartnerFilterSettingsRequest());
      const response = await requestHttp.get<IPartnerFilterSettingsResponse>(
        urls.getPartnerFilterSettingsUrl()
      );

      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data;
        dispatch(fetchPartnerFilterSettingsSuccess(content));
      }
    } catch (error) {
      dispatch(
        fetchPartnerFilterSettingsFailure(getResponseErrorMessage(error))
      );
    }
  };

export const fetchUploadMediaTypes =
  (
    briefId: number
  ): ThunkAction<Promise<AxiosResponse<void>>, {}, {}, AnyAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse<void>> => {
    try {
      dispatch(fetchUploadSettingsRequest());

      const response = await requestHttp.get(
        urls.getBriefUploadMediaUrl(briefId)
      );
      const { content } = response.data.data;

      dispatch(fetchUploadSettingsSuccess(content.data));
      return response;
    } catch (error) {
      dispatch(fetchUploadSettingsFailure(getResponseErrorMessage(error)));
      return error;
    }
  };

export const approveBrief =
  (briefId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    try {
      dispatch(createBriefRequest());
      const response: any = await requestHttp.get(
        urls.getBriefApproveUrl(briefId)
      );
      if (
        response?.data?.status === 409 &&
        response?.data?.error?.payload?.invalid
      ) {
      } else {
        const { content } = response.data.data;
        const parsedBrief = parseBriefResponse(content.brief);
        parsedBrief.id = briefId;

        dispatch(
          createBriefPricing(
            content.brief.copackingPrice,
            content.brief.printingPrice
          )
        );
        dispatch(createBriefSuccess(parsedBrief));
      }

      return response;
    } catch (error) {
      dispatch(createBriefFailure(getResponseErrorMessage(error)));
    }
  };

export const sendProposalRequest = (): IAction => ({
  type: CONSTANTS.EMAIL_PROPOSAL_REQUEST,
});

export const sendProposalSuccess = (): IAction => ({
  type: CONSTANTS.EMAIL_PROPOSAL_SUCCESS,
});

export const sendProposalFailure = (error: string): IAction => ({
  type: CONSTANTS.EMAIL_PROPOSAL_FAILURE,
  error,
});

export const emailProposal =
  (
    briefId: number,
    data: IProposal,
    file: jsPDF
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(sendProposalRequest());

      const blob = file.output("blob");
      const bodyFormData = new FormData();
      bodyFormData.append("file", blob);
      data.emails.forEach((email: string) => {
        bodyFormData.append("emails[]", email);
      });
      bodyFormData.append("message", data.message);
      bodyFormData.append("cc", data.cc + "");

      const config = {
        multipartFormData: true,
        skipDownloadProgress: true,
      };

      await requestHttp.post(
        urls.getSendEmailProposal(briefId),
        bodyFormData,
        config
      );

      dispatch(sendProposalSuccess());
    } catch (error) {
      dispatch(sendProposalFailure(getResponseErrorMessage(error)));
    }
  };

export const payOnlineViewBriefSuccess = (): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_PAY_ONLINE_SUCCESS,
});

const parseBriefResponse = (brief: IBriefResponse) => {
  const newBrief = cloneDeep(IBriefInitState);
  newBrief.paymentEnabled = brief.owner?.paymentEnabled;
  newBrief.status = brief.status;
  newBrief.promotionId = brief.promotionId;
  newBrief.lastFilledStep = brief.lastFilledStep
    ? brief.lastFilledStep
    : BRIEF_STEPS.TYPE;
  newBrief[BRIEF_STEPS.TYPE].briefTypes = brief.briefTypes
    ? brief.briefTypes
    : [];

  newBrief[BRIEF_STEPS.PRODUCT] = {
    campaignName: brief.campaignName,
    campaignPromotionType: brief.campaignPromotionType,
    name: brief.name,
    productImages: brief.productImages ? brief.productImages : [],
    productDescription: brief.productDescription,
    mediaStartDate: brief.mediaStartDate ? moment(brief.mediaStartDate) : null,
    marketingObjective: brief.marketingObjective,
    instructions: brief.instructions,
    quantity: brief.quantity,
    distributionItem: brief.distributionItem,
    productType: brief.productType,
    briefStartDate: brief.briefStartDate ? moment(brief.briefStartDate) : null,
    duration: brief.duration,
    otherProductType: null,
  } as IProductStep;

  newBrief[BRIEF_STEPS.TARGETING] = {
    targetFocus: brief.targetFocus ? brief.targetFocus : [],
    targetAgeGroups: brief.targetAgeGroups ? brief.targetAgeGroups : [],
    targetSegments: brief.targetSegments ? brief.targetSegments : [],
    targetCountries: brief.targetCountries ? brief.targetCountries : [],
    targetCities: brief.targetCities ? brief.targetCities : [],
    customTargets: brief.customTargets ? brief.customTargets : {},
  } as ITargetingStep;

  newBrief[BRIEF_STEPS.SERVICES] = {
    servicePackageType: brief.servicePackageType
      ? brief.servicePackageType
      : [],
    serviceIsLiquid: brief.serviceIsLiquid || false,
    serviceIncludingCoupon: brief.serviceIncludingCoupon || false,
    serviceDesign: brief.serviceDesign || false,
    servicePrinting: brief.servicePrinting || false,
    printingPriceId: brief.printingPriceId,
    servicePackaging: brief.servicePackaging || false,
    copackingPriceId: brief.copackingPriceId,
    servicePackagingQuantity: brief.servicePackagingQuantity,
    serviceImages: brief.serviceImages ? brief.serviceImages : [],
    hasFreeSample: brief.hasFreeSample || false,
    serviceTemperature: brief.serviceTemperature,
  } as IServicesStep;

  newBrief[BRIEF_STEPS.MEDIA_BUY] = {
    mediaStartDate: brief.mediaStartDate ? moment(brief.mediaStartDate) : null,
    mediaDuration: brief.mediaDuration,
    mediaTypes: brief.mediaTypes ? brief.mediaTypes : [],
    marketingObjective: brief.marketingObjective,
    budgetCurrency: brief.budgetCurrency,
    budgetType: brief.budgetType,
    budgetValue: brief.budgetValue,
  } as IMediaBuyStep;

  newBrief[BRIEF_STEPS.UPLOAD] = {
    uploadMaterial: brief.uploadMaterial,
  } as IUploadStep;

  return newBrief;
};

export const getPotentialAudienceReach =
  (
    id: number,
    fields: ITargetingStep
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.post<IPotentialAudienceReachResponse>(
        urls.getPotentialAudienceReachUrl(id),
        fields
      );
      const { content } = response.data.data;
      dispatch(potentialAudienceReachSuccess(content));
    } catch (error) {
      return error;
    }
  };

export const potentialAudienceReachSuccess = (
  potentialAudienceReach: IPotentialAudienceReach
): IAction => ({
  type: CONSTANTS.POTENTIAL_AUDIENCE_REACH_SUCCESS,
  payload: { potentialAudienceReach },
});

export const fetchExclusivePartnerDetails =
  (
    id: number
  ): ThunkAction<
    Promise<AxiosResponse<IViewBriefDetailsResponse>>,
    {},
    {},
    AnyAction
  > =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse<IViewBriefDetailsResponse>> => {
    try {
      const response = await requestHttp.get<IViewBriefDetailsResponse>(
        urls.getExclusivePartnerUrl(id)
      );
      return response?.data?.data?.content?.publicProfile?.companyLogo;
    } catch (error) {
      dispatch(viewBriefFailure(getResponseErrorMessage(error)));
      return error;
    }
  };

export const consumerResearchSendEmail =
  (invited: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      await requestHttp.put(
        invited
          ? urls.postConsumerResearchInvitedClientUrl()
          : urls.postConsumerResearchEmailUrl(),
        {
          type: "consumer research",
        }
      );
    } catch (error) {
      return error;
    }
  };

export const uploadGeneratedQuotation =
  (
    files: any[],
    entity: BRIEF_UPLOAD_TYPES,
    briefId: number
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(requestStart());
      await upload(
        urls.getBriefUploadUrl(),
        files,
        briefId,
        entity,
        UPLOAD_ENTITY_KEYS.BRIEF_ID
      );
      dispatch(requestEnd());
    } catch (error) {
      dispatch(requestEnd());
      // dispatch(uploadTimelineImagesFailure(error))
    }
  };

export const validateClientRequiredFields =
  (briefId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.get(
        urls.getValidateClientRequiredFields(briefId)
      );
      const { content } = response.data.data;
      return content;
    } catch (error) {
      console.log(error);
    }
  };
