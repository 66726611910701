import React, { ReactElement } from 'react'
import { Col, Row } from 'quantum_components'

import imageSample from '../../../../../assets/brief_sample@2x.jpg'
import imageInsert from '../../../../../assets/brief_insert@2x.jpg'

export interface IProps {}
export default function TestahelBriefDistributionPopover(): ReactElement {
  return (
    <div className="distributing-popover">
      <div className="distributing-popover__item">
        <Row gutter={16} align="middle">
          <Col span={10}>
            <img src={imageSample} width="122" height="82" alt="" className="distributing-popover__image" />
          </Col>
          <Col span={14}>
            <div className="p distributing-popover__heading">Sample</div>
            <p className="p p--sm mb-0 tc--light distributing-popover__description">
              A free product sample that's included for your target customer.
            </p>
          </Col>
        </Row>
      </div>
      <div className="distributing-popover__item">
        <Row gutter={16} align="middle">
          <Col span={10}>
            <img src={imageInsert} width="122" height="82" alt="" className="distributing-popover__image" />
          </Col>
          <Col span={14}>
            <div className="p distributing-popover__heading">Insert</div>
            <p className="p p--sm mb-0 tc--light distributing-popover__description">
              A printed insert to build awareness for your target audience.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}
