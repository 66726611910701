import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { getPartnerProfileSettings } from '../../../profile.selectors'
import { QSelect } from 'quantum_components'

interface IProps {
  value: string[]
  onChange: (segments: string[]) => void
  isViewOnly?: boolean
}

const SegmentsInput: FC<IProps> = ({ value, onChange, isViewOnly }) => {
  const settings = useSelector(getPartnerProfileSettings)

  const selectSegments = () => {
    if (settings.targetSegments) {
      return settings.targetSegments.map((elem: any) => ({
        label: elem.name,
        value: elem.name,
      }))
    } else {
      return []
    }
  }

  const handleSelect = (values: string[]) => {
    onChange(values)
  }

  return (
    <QSelect
      mode="multiple"
      size="large"
      showArrow
      placeholder="Select a target"
      value={value}
      onChange={handleSelect}
      options={selectSegments()}
      disabled={isViewOnly}
    />
  )
}

export default SegmentsInput
