import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { QButton } from 'quantum_components'

import { PATHS } from 'src/constants'

import { ReactComponent as ImageCheck } from '../../../../assets/icons/check-icon.svg'
import ClientLayout from '../../../../components/Layouts/ClientLayout'
import * as selectors from '../../../core/core.selectors'
import { IBriefResponse } from '../../brief.types'
import { BRIEF_STATUS } from 'src/constants/brief'

interface IProps {
  approvedUser: boolean
  briefDetails: IBriefResponse
}

export default function SubmittedSuccessfully({ approvedUser, briefDetails }: IProps) {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translation', 'translationCommon'])
  const history = useHistory()
  const currentUser = useSelector(selectors.getCurrentUser)

  const handleFinish = () => {
    history.push(PATHS.BRIEF)
  }

  const headerText = briefDetails?.status === BRIEF_STATUS.PENDING ? t('brief.edit.common.Campaign Submitted Successfully before payment!') : approvedUser ? t('brief.edit.common.Campaign Submitted Successfully!') : t('brief.edit.common.Campaign Created Successfully!')

  const bodyText = briefDetails?.status === BRIEF_STATUS.PENDING
    ? t('brief.edit.common.Your campaign is under review before payment1') + t('brief.edit.common.Your campaign is under review before payment2')
    : approvedUser
      ? t('brief.edit.common.Your campaign is under review1') + t('brief.edit.common.Your campaign is under review2')
      : t('brief.edit.common.Your account is under review1') + t('brief.edit.common.Your account is under review2')

  return (
    <ClientLayout user={currentUser}>
      <div className="brief-step-success">
        <ImageCheck />
        <h1>{headerText}</h1>
        <p>{bodyText}</p>
        <QButton className="w-180" type="primary" onClick={handleFinish}>
          {t("brief.edit.common.Okay!")}
        </QButton>
      </div>
    </ClientLayout>
  )
}
