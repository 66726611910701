import React, { useCallback, useState } from 'react'
import { Badge, IconsCommon, Modal } from 'quantum_components'

import QTestahelBriefCartMenu from './TestahelBrief.CartMenu'

const cartIcon = <IconsCommon.IconCart />
const closeIcon = <IconsCommon.IconClose />

export interface Props {
  badgeNumber?: number
}

const QTestahelBriefCartMobile = ({ badgeNumber = 0 }: Props) => {
  const [visible, setVisible] = useState(false)
  const toggleModal = useCallback(
    (value?: boolean): void => {
      setVisible(value === undefined ? !visible : value)
    },
    [setVisible, visible]
  )
  const onToggleModal = useCallback((): void => {
    toggleModal()
  }, [toggleModal])

  return (
    <div className={`brief-cart-modal`}>
      <button className="cart-button" onClick={onToggleModal}>
        <Badge count={badgeNumber}>
          <span className="cart-menu-icon">{cartIcon}</span>
        </Badge>
      </button>
      <Modal
        wrapClassName="qu-modal cart-modal"
        closeIcon={closeIcon}
        visible={visible}
        onCancel={onToggleModal}
        title={<h3 className="text-center">Cart</h3>}
        footer={null}
      >
        <QTestahelBriefCartMenu />
      </Modal>
    </div>
  )
}
export default QTestahelBriefCartMobile
