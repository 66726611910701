/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Col, QAlert, QBackLink, Row } from 'quantum_components'

import { PATHS } from 'src/constants'
import { BRIEF_PAYMENT_STATUS, BRIEF_STATUS, BRIEF_STEPS, MEDIA_TYPES } from 'src/constants/brief'
import { ALERTS_MSG } from 'src/constants/alerts'
import { REQUEST_STATUS } from 'src/constants/request'
import { useReduxDispatch } from 'src/helpers'
import { rejectedItems } from 'src/helpers/cart'
import { renderRoundedNumber } from 'src/helpers/currency'
import { IBillListElementPaymentDto, IOnlinePayment } from 'src/modules/client/bills/bills.types'

import * as actions from '../brief.actions'
import { ICartItem, ICartServiceItem } from '../brief.types'
import { getBriefView, getCart } from '../brief.selectors'

import BriefEditSummaryComponent from 'src/components/BriefView/BriefEditSummary.component'
import { getCurrentUser } from 'src/modules/core/core.selectors'

const BriefView = () => {
  const { i18n } = useTranslation()
  const currentUser = useSelector(getCurrentUser)
  const { t } = useTranslation(['translation', 'translationCommon'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const briefView = useSelector(getBriefView)
  const cart = useSelector(getCart)
  const { id } = useParams<{id: any}>()
  const hasPreviousRoute = history.action === 'PUSH'

  const briefPaymentStatus = briefView.payment?.status
  const isPaymentRejected = briefPaymentStatus === BRIEF_PAYMENT_STATUS.REJECTED
  const isPaymentInReview = briefView.payment?.inReview
  const isPaymentStepEnabled = currentUser?.paymentEnabled
  const isBriefPendingApproval =
    !isPaymentRejected &&
    !isPaymentInReview &&
    [BRIEF_STATUS.PENDING_APPROVAL, BRIEF_STATUS.PENDING].includes(briefView.status)
  const isBriefApproved = !isPaymentRejected && briefView.status === BRIEF_STATUS.APPROVED

  const fetchSettings = async () => {
    dispatch(actions.fetchBriefSettings())
  }

  useEffect(() => {
    dispatch(actions.viewBriefSuccess({}))
    fetchSettings()

    // return function cleanup() {
    //   dispatch(actions.viewBriefSuccess({}))
    // }
  }, [])

  useEffect(() => {
    dispatch(actions.fetchViewBriefDetails(id))
    dispatch(actions.fetchCart(id))
  }, [id])

  const setCurrentStep = (step: BRIEF_STEPS) => {
    history.push(`/briefs/edit/${id}`)
    dispatch(actions.setNeedCheckLastStep(false))
    dispatch(actions.setCurrentStep(step))
  }

  const setActivePartner = async (partnerId: number) => {
    await dispatch(actions.fetchPartnerDetails(id, partnerId))
    await dispatch(actions.setActiveSinglePartner(partnerId))
  }

  const openPartner = async (partnerId: number) => {
    setCurrentStep(BRIEF_STEPS.PARTNER)
    await setActivePartner(partnerId)
  }

  const rejected = rejectedItems(cart.additional.items)

  const handleFindPartner = () => {
    history.push(`/briefs/rejected/${id}`)
  }

  const handleBack = () => {
    if (hasPreviousRoute) {
      history.goBack()
      return
    }

    history.push(PATHS.BRIEF)
  }

  const getRejectedMessage = (companyName: string, type: string, rejectionReason: string) => {
    const reason = rejectionReason ? ` due to “${rejectionReason}”` : ''

    return (
      <>
        {`${companyName} rejected ${type}${reason}. `}
        <span className={'button--link'} onClick={handleFindPartner}>
          Find another partner
        </span>{' '}
        and submit your brief.
      </>
    )
  }

  const getPaymentRejectionMessage = (payment: IBillListElementPaymentDto) => {
    const fileType = payment.bankTransfer ? t(`brief.payment.bank_receipt`) : t(`brief.payment.po`)
    const reason = t(`brief.payment.${payment.reason}`, payment.reason)
    return t(`brief.payment.payment_rejection`,{type:fileType,reason})
  }

  const getPaymentRefundMessage = (payment: IBillListElementPaymentDto) => {
    const onlinePayment = payment.onlinePayment as IOnlinePayment
    const refundsSumInCents = onlinePayment.refunds.reduce((acc, refund) => acc + refund.amount, 0)
    const amountToRefund = renderRoundedNumber(refundsSumInCents / 100, onlinePayment.currency)

    return `A refund of ${amountToRefund} was issued for this Brief`
  }


  return (
    <div className="qu-brief-view">
      {isBriefApproved && (
        <Row gutter={20} className="pt-40">
          <Col span={24}>
            <QAlert
              message={
                <>
                  <div>{t(ALERTS_MSG.ALERT_BRIEF_APPROVED, {ns: 'translationCommon'})}</div>
                  {[BRIEF_PAYMENT_STATUS.PARTIAL_REFUND, BRIEF_PAYMENT_STATUS.REFUND].includes(briefPaymentStatus) && (
                    <div>{getPaymentRefundMessage(briefView.payment)}</div>
                  )}
                </>
              }
              type="info"
              className="centered"
            />
          </Col>
        </Row>
      )}
      {isBriefPendingApproval && (
        <Row gutter={20} className="pt-40">
          <Col span={24}>
            <QAlert
              message={
                <>
                  <div>{t(ALERTS_MSG.ALERT_BRIEF_VIEW, {ns: 'translationCommon'})}</div>
                  {[BRIEF_PAYMENT_STATUS.PARTIAL_REFUND, BRIEF_PAYMENT_STATUS.REFUND].includes(briefPaymentStatus) && (
                    <div>{getPaymentRefundMessage(briefView.payment)}</div>
                  )}
                </>
              }
              type="info"
              className="centered"
            />
          </Col>
        </Row>
      )}
      {!isPaymentRejected && briefView.status === BRIEF_STATUS.PENDING_ACCOUNT_APPROVAL && (
        <Row gutter={20} className="pt-40">
          <Col span={24}>
            <QAlert message={t(ALERTS_MSG.ALERT_BRIEF_LIST_NOT_EMPTY, {ns: 'translationCommon'})} type="warning" className="centered pt-40" />
          </Col>
        </Row>
      )}
      {!isPaymentRejected && briefView.status === BRIEF_STATUS.SELECT_PARTNERS && (
        <Row gutter={20} className="pt-40">
          <Col span={24}>
            <QAlert message={t(ALERTS_MSG.ALERT_ACCOUNT_APPROVED, {ns: 'translationCommon'})} type="info" className="centered" />
          </Col>
        </Row>
      )}

      {rejected.map((item: ICartItem) => {
        const distributions = item.services.filter(
          (service: ICartServiceItem) =>
            service.type === MEDIA_TYPES.DISTRIBUTION && service.requestStatus === REQUEST_STATUS.REJECTED
        )
        const mediaBuys = item.services.filter(
          (service: ICartServiceItem) =>
            service.type !== MEDIA_TYPES.DISTRIBUTION && service.requestStatus === REQUEST_STATUS.REJECTED
        )

        return (
          <React.Fragment key={item.partnerId}>
            {!isPaymentRejected && distributions.length > 0 && (
              <Row gutter={20} className={'pt-10'}>
                <Col span={24}>
                  <QAlert
                    message={getRejectedMessage(item.companyName, 'Distribution', distributions[0].rejectionReason)}
                    type="warning"
                    className="centered"
                  />
                </Col>
              </Row>
            )}
            {!isPaymentRejected && mediaBuys.length > 0 && (
              <Row gutter={20} className={'pt-10'}>
                <Col span={24}>
                  <QAlert
                    message={getRejectedMessage(item.companyName, 'Media Buy', mediaBuys[0].rejectionReason)}
                    type="warning"
                    className="centered"
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        )
      })}

      {isPaymentRejected && !isPaymentInReview && (
        <Row gutter={20} className={'pt-10'}>
          <Col span={24}>
            <QAlert message={getPaymentRejectionMessage(briefView.payment)} type="warning" className="centered" />
          </Col>
        </Row>
      )}

      {isPaymentInReview && (
        <Row gutter={20} className="pt-10">
          <Col span={24}>
            <QAlert message={t(t(ALERTS_MSG.ALERT_BRIEF_PAYMENT_REVIEW, {ns: 'translationCommon'}), { ns: 'translationCommon' })} type="info" className="centered" />
          </Col>
        </Row>
      )}

      {[BRIEF_PAYMENT_STATUS.PARTIAL_REFUND, BRIEF_PAYMENT_STATUS.REFUND].includes(briefPaymentStatus) &&
        briefView.status !== BRIEF_STATUS.REJECTED &&
        !isBriefPendingApproval &&
        !isBriefApproved && (
          <Row gutter={20} className="pt-10">
            <Col span={24}>
              <QAlert message={getPaymentRefundMessage(briefView.payment)} type="info" className="centered" />
            </Col>
          </Row>
        )}

      <div className={'client-brief-view'}>
        <QBackLink
          title={
            hasPreviousRoute
              ? t('common.Back', { ns: 'translationCommon' })
              : t('common.Back_to', { ns: 'translationCommon' }) + ' ' + t('brief.summary.briefs')
          }
          onClick={handleBack}
        />
        <BriefEditSummaryComponent
          briefView={briefView}
          cart={cart}
          setCurrentStep={setCurrentStep}
          openPartner={openPartner}
          // hidePaymentSection={!isPaymentStepEnabled && [BRIEF_PAYMENT_STATUS.PARTIAL_REFUND, BRIEF_PAYMENT_STATUS.REFUND].includes(
          //   briefView?.payment?.status as BRIEF_PAYMENT_STATUS
          // )}
          hidePaymentSection={!isPaymentStepEnabled}
          hideSubmitPaymentButton={!window.location.pathname.includes("view")}
        />
      </div>
    </div>
  )
}

export default BriefView
