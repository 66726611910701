import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import {
  Form,
  QButton,
  QInput,
  ProfileCard,
  PublicProfileField,
  InfoCard,
  ProgressLabel,
  QAlert,
  Modal,
  IconsCommon,
} from 'quantum_components'

import { actions } from '../publicProfile.module'
import UploadComponent from 'src/components/UploadComponent'
import {
  getPartnerDetails,
  getPartnerDetailsLoading,
  getPartnerPublicPercent,
  getPublicProfile as getPublicProfileFromPublicModule,
  getPublicProfileLoading,
} from '../publicProfile.selectors'
import { getPublicProfile, getForm, getPartnerProfileSettings } from '../../partnerProfile/profile.selectors'
import { getCurrency, getCurrentUser } from '../../../core/core.selectors'
import { getFileNameFromUrl } from 'src/utils'
import { useReduxDispatch } from 'src/helpers'

import IUploadFile from '../../../../interfaces/IUploadFile'
import { TName } from '../../../../interfaces/IAction'

import { ReactComponent as CloseIcon } from 'src/assets/icons/close-icon.svg'
import { ReactComponent as ShopIcon } from 'src/assets/icons/media-types/shop.svg'
import BackgroundImage from 'src/assets/backgroundImage.jpg'
import '../styles.scss'
import { initialState } from '../publicProfile.reducer'
import { pick } from 'lodash'
import { abbreviateNumber } from 'src/helpers/abbreviateNumber'
import PartnerDetailsModal from 'src/components/PartnerDetailsComponents/PartnerDetailsModal'
import * as partnerProfileActions from '../../partnerProfile/Profile.actions'
import {
  calculateLowestValue,
  getDistributionPrices,
  getDynamicDistributionPrices,
  getMediaBuyPartnerPrice,
  getMediaBuyPartnerPriceForMetrics,
  getMediaBuyTypesPrices,
  getMediaBuyTypesPricesForMetrics,
  renderRoundedNumber,
} from 'src/helpers/currency'
import { IPartnerCardService, MediaSubtype } from 'src/modules/brief/brief.types'
import { DISTRIBUTION_TYPE, MEDIA_TYPES } from 'src/constants/brief'
import { DynamicPricingTypeLabels, NAMES_PUBLIC_PROFILE_FIELDS } from 'src/constants/profile'
import { IMediaBuyTypesElement } from '../../partnerProfile/profile.types'

export interface Props {
  removeHandler?: () => void
  removable?: boolean
  value: any
}

const PublicProfile = () => {
  const [form] = Form.useForm()
  const dispatch = useReduxDispatch()
  const getProfile = useSelector(getPublicProfile)
  const getHasUser: any = useSelector(getCurrentUser)
  const partnerForm = useSelector(getForm)
  const partnerDetails = useSelector(getPartnerDetails)
  const partnerDetailsLoading = useSelector(getPartnerDetailsLoading)
  const filterSettings = useSelector(getPartnerProfileSettings)
  const publicPercent = useSelector(getPartnerPublicPercent)
  const publicProfile = useSelector(getPublicProfileFromPublicModule)
  const isUpdating = useSelector(getPublicProfileLoading)
  const currency = useSelector(getCurrency)

  const [loading, setLoading] = useState(true)

  const metrics = Array.from({ length: 5 }, (v, i) =>
    publicProfile.metricsMarketing ? publicProfile.metricsMarketing[i] : ''
  )

  const initialValues = {
    [NAMES_PUBLIC_PROFILE_FIELDS.LISTING_SHORT_DESCRIPTION]: publicProfile.listingShortDescription,
    [NAMES_PUBLIC_PROFILE_FIELDS.HIGHLIGHTS]: publicProfile.highlights?.length ? publicProfile.highlights : [''],
    [NAMES_PUBLIC_PROFILE_FIELDS.METRICS_MARKETING]: metrics,
  }

  const [files, setFiles] = useState<{ [x: string]: IUploadFile[] }>({})
  const [filesObjectLoading, setFilesObjectLoading] = useState({
    companyLogo: false,
    previewCard: false,
    listingHeader: false,
  })

  const [showPreview, setShowPreview] = useState<boolean>(false)

  useEffect(() => {
    if (JSON.stringify(initialState.publicProfile) !== JSON.stringify(getProfile)) {
      getProfile.id !== 0 && setLoading(false)
    }
  }, [getProfile])

  const fetchFilterSettings = () => {
    dispatch(partnerProfileActions.fetchPartnerProfileSettings())
  }

  useEffect(() => {
    fetchFilterSettings()
    // return () => {
    //   dispatch(actions.unMountClearData())
    // }
  }, [])

  const distributionPrices = partnerForm.hasDynamicSamplePricing
    ? getDynamicDistributionPrices(partnerForm)
    : getDistributionPrices(partnerForm)
  const distributionLowestPrice = calculateLowestValue(distributionPrices)
  const servicePrices =
    partnerForm.mbPricingModel === 'metrics'
      ? getMediaBuyTypesPricesForMetrics(partnerForm.mediaBuyTypes)
      : getMediaBuyTypesPrices(partnerForm.mediaBuyTypes)
  const mediaBuyLowestPrice = calculateLowestValue(servicePrices)

  const getMediaTypes = () => {
    partnerForm.mbPricingModel === 'metrics'
      ? partnerForm.mediaBuyTypes.sort(
          (a, b) => getMediaBuyPartnerPriceForMetrics(a) - getMediaBuyPartnerPriceForMetrics(b)
        )
      : partnerForm.mediaBuyTypes.sort((a, b) => getMediaBuyPartnerPrice(a) - getMediaBuyPartnerPrice(b))

    const services = [] as IPartnerCardService[]
    if (partnerForm.isSampleDistribution) {
      if (partnerForm.hasDynamicSamplePricing && partnerForm.dynamicSamplePrices) {
        Object.entries(partnerForm.dynamicSamplePrices).map(([key, value]) => {
          const price = renderRoundedNumber(value, currency)
          const sampleService = {
            name: DISTRIBUTION_TYPE.PRODUCT_SAMPLE + ': ' + DynamicPricingTypeLabels[key],
            price: price,
            type: MEDIA_TYPES.DISTRIBUTION,
          } as IPartnerCardService

          services.push(sampleService)
        })
      } else {
        const price = renderRoundedNumber(partnerForm.pricePerSample, currency)
        const sampleService = {
          name: DISTRIBUTION_TYPE.PRODUCT_SAMPLE,
          price: price,
          type: MEDIA_TYPES.DISTRIBUTION,
        } as IPartnerCardService

        services.push(sampleService)
      }
    }
    if (partnerForm.isInsertDistribution) {
      const price = renderRoundedNumber(partnerForm.pricePerInsert, currency)
      const insertService = {
        name: DISTRIBUTION_TYPE.PRINTED_INSERT,
        price: price,
        type: MEDIA_TYPES.DISTRIBUTION,
      } as IPartnerCardService

      services.push(insertService)
    }

    const mediaBuying = partnerForm.mediaBuyTypes.map((service: IMediaBuyTypesElement) => {
      const mediaSubTypes = filterSettings.mediaSubtypes
      const subType = mediaSubTypes ? mediaSubTypes.find((type: MediaSubtype) => type.name === service.name) : null
      const price =
        partnerForm.mbPricingModel === 'metrics'
          ? getMediaBuyPartnerPriceForMetrics(service)
          : getMediaBuyPartnerPrice(service)

      return {
        name: service.subName ? service.subName : service.name,
        price: renderRoundedNumber(price, currency),
        type: 'Media Buying',
        subtype: subType && subType.subtype,
      } as IPartnerCardService
    })

    return [...services, ...mediaBuying]
  }
  const cardOptions = {
    preview: true,
    serviceLabel: 'ECOMMERCE',
    name: partnerForm.companyName,
    url: partnerForm.companyWebsite,
    description: publicProfile.listingShortDescription
      ? publicProfile.listingShortDescription
      : 'Listing short description here',
    social: [
      {
        name: 'Customers',
        counter: partnerForm.Leads ? abbreviateNumber(+partnerForm.Leads) : 0,
      },
      {
        name: 'Impressions',
        counter: partnerForm.Looks ? abbreviateNumber(+partnerForm.Looks) : 0,
      },
      {
        name: 'Facebook',
        counter: partnerForm.metrics?.Facebook ? abbreviateNumber(+partnerForm.metrics.Facebook) : 0,
      },
      {
        name: 'Instagram',
        counter: partnerForm.metrics?.Instagram ? abbreviateNumber(+partnerForm.metrics.Instagram) : 0,
      },
      {
        name: 'Twitter',
        counter: partnerForm.metrics?.Twitter ? abbreviateNumber(+partnerForm.metrics.Twitter) : 0,
      },
    ],
    servicesPackagePrice: renderRoundedNumber(distributionLowestPrice, currency),
    servicesDevicePrice: renderRoundedNumber(mediaBuyLowestPrice, currency),
    headerImg: publicProfile.previewCardImage ? publicProfile.previewCardImage : BackgroundImage,
    logo: publicProfile.companyLogo ? publicProfile.companyLogo : BackgroundImage,

    popupServices: getMediaTypes(),
  }

  const onFinish = (values: any) => {
    dispatch(actions.fetchPublicProfileSave())
  }

  const onValuesChange = (value: any, allValues: any) => {
    dispatch(
      actions.setPublicProfile({
        ...allValues,
        metricsMarketing: allValues.metricsMarketing.filter((e: string) => e),
        highlights: allValues.highlights.filter((e: string) => e),
      })
    )
  }

  const handleUpload = async (uploads: File[], name: TName) => {
    setFilesObjectLoading(prevState => ({ ...prevState, [name]: true }))
    dispatch(actions.uploadProductImage(uploads, name))
  }

  const handleRemove = async (name: TName) => {
    dispatch(actions.imageRemove(name))
    setFiles({ ...files, [name]: [] })
  }

  const filesMapper = () => {
    const fieldsImages: any = pick(publicProfile, [
      NAMES_PUBLIC_PROFILE_FIELDS.COMPANY_LOGO,
      NAMES_PUBLIC_PROFILE_FIELDS.LISTING_HEADER_IMAGE,
      NAMES_PUBLIC_PROFILE_FIELDS.PREVIEW_CARD_IMAGE,
    ])
    const fieldKeys: string[] = Object.keys(fieldsImages)
    let obj = {}
    fieldKeys.map((key, i) => {
      obj = {
        ...obj,
        //@ts-ignore
        [key]: publicProfile[key]
          ? [
              {
                uid: i + '',
                name: getFileNameFromUrl(fieldsImages[key]),
                status: 'done',
                url: fieldsImages[key],
              } as IUploadFile,
            ]
          : [],
      }
      return key
    })
    setFiles(obj)
  }

  useEffect(() => {
    filesMapper()
    setFilesObjectLoading({
      companyLogo: false,
      previewCard: false,
      listingHeader: false,
    })
  }, [publicProfile])

  const handleOpenPreview = async () => {
    if (!getHasUser.id) {
      return
    }

    await dispatch(actions.fetchPartnerDetails(getHasUser.id))
    setShowPreview(true)
  }

  const handleMore = (e: any) => {
    e.preventDefault()
    handleOpenPreview()
  }

  if (loading) return <p></p>
  return (
    <div className="publicProfile partner-container">
      <Form
        name="publicProfile"
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <div className="mb-20" style={{ maxWidth: '540px' }}>
          <div className="public-profile-title">
            <h1>{partnerForm.companyName ? `${partnerForm.companyName}'s Public Profile` : 'Public Profile'}</h1>
            <h5>
              Clients will see your public profile while searching for Partners in our marketplace. Having 100%
              completed profile with all details, will increase your profile`s chance of getting selected.
            </h5>
          </div>
        </div>
        <Row gutter={20}>
          <Col xs={24} sm={24} md={16}>
            <InfoCard
              headerLeft={<h2 className="h3">Profile Preview Information</h2>}
              headerRight={<ProgressLabel value={publicPercent} label="Completion" />}
            >
              <PublicProfileField
                description={{
                  title: 'Listing Short Description',
                }}
                input={
                  <Form.Item name="listingShortDescription">
                    <QInput
                      className="form-textarea"
                      placeholder="explain about the listing briefly"
                      disabled={getHasUser && getHasUser.partnerPermissionLevel == 'view'}
                    />
                  </Form.Item>
                }
              />
              <PublicProfileField
                description={{
                  title: 'Company Logo',
                }}
                input={
                  <Form.Item>
                    <UploadComponent
                      files={files.companyLogo}
                      onChange={value => {
                        getHasUser && getHasUser.partnerPermissionLevel != 'view' && handleUpload(value, 'companyLogo')
                      }}
                      onRemove={() => {
                        getHasUser && getHasUser.partnerPermissionLevel != 'view' && handleRemove('companyLogo')
                      }}
                      isUploading={filesObjectLoading.companyLogo}
                    />
                  </Form.Item>
                }
              />
              <PublicProfileField
                description={{
                  title: 'Preview Card Image',
                }}
                input={
                  <Form.Item>
                    <UploadComponent
                      files={files.previewCardImage}
                      onChange={value => {
                        getHasUser &&
                          getHasUser.partnerPermissionLevel != 'view' &&
                          handleUpload(value, 'previewCardImage')
                      }}
                      onRemove={() => {
                        getHasUser && getHasUser.partnerPermissionLevel != 'view' && handleRemove('previewCardImage')
                      }}
                      isUploading={filesObjectLoading.previewCard}
                    />
                  </Form.Item>
                }
              />
              <PublicProfileField
                description={{
                  title: 'Listing Header Image',
                }}
                input={
                  <Form.Item>
                    <UploadComponent
                      files={files.listingHeaderImage}
                      onChange={value => {
                        getHasUser &&
                          getHasUser.partnerPermissionLevel != 'view' &&
                          handleUpload(value, 'listingHeaderImage')
                      }}
                      onRemove={() => {
                        getHasUser && getHasUser.partnerPermissionLevel != 'view' && handleRemove('listingHeaderImage')
                      }}
                      isUploading={filesObjectLoading.listingHeader}
                    />
                  </Form.Item>
                }
              />
              <PublicProfileField
                description={{
                  title: 'Highlights',
                  subTitle:
                    'Use these to mention notable accomplishments, awards, or statistics you can share to attract clients.',
                }}
                input={
                  <>
                    <Form.List name="highlights">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            <div className="PPField__inputList">
                              {fields.map(field => (
                                <div key={field.key} className="input">
                                  <Form.Item {...field}>
                                    <QInput
                                      className="form-textarea input-with-close-icon"
                                      placeholder="Ex: First Online Organic Store in Saudi"
                                      disabled={getHasUser && getHasUser.partnerPermissionLevel == 'view'}
                                    />
                                  </Form.Item>
                                  {fields.length > 1 && getHasUser && getHasUser.partnerPermissionLevel != 'view' && (
                                    <CloseIcon className="closeIcon" onClick={() => remove(field.name)} />
                                  )}
                                </div>
                              ))}
                            </div>
                            {getHasUser && getHasUser.partnerPermissionLevel != 'view' && (
                              <div className="PPField__inputAdd" onClick={() => add()}>
                                Add Another Highlight
                              </div>
                            )}
                          </div>
                        )
                      }}
                    </Form.List>
                  </>
                }
              />
              <PublicProfileField
                description={{
                  title: 'Metrics',
                  subTitle:
                    'Use these to showcase impressions and traffic to your website, social platforms, and more.',
                }}
                input={
                  <>
                    <Form.List name="metricsMarketing">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            <div className="PPField__inputList">
                              {fields.map(field => (
                                <div key={field.key} className="input">
                                  {/* name={field.name} fieldKey={field.fieldKey} */}
                                  <Form.Item {...field}>
                                    <QInput
                                      className="form-textarea"
                                      type="text"
                                      placeholder="12,000+ products across 5 different categories"
                                      disabled={getHasUser && getHasUser.partnerPermissionLevel == 'view'}
                                    />
                                  </Form.Item>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      }}
                    </Form.List>
                  </>
                }
              />
            </InfoCard>
            <div className="public-profile-footer">
              <QButton
                disabled={getHasUser && getHasUser.partnerPermissionLevel == 'view'}
                onClick={handleOpenPreview}
                className="qu-button-soft preview"
              >
                Show Preview
              </QButton>
              <QButton
                disabled={isUpdating || (getHasUser && getHasUser.partnerPermissionLevel == 'view')}
                type="primary"
                htmlType="submit"
              >
                Save Updates
              </QButton>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className="profile-card-preview">
              <ProfileCard onClick={handleMore} {...cardOptions} />
              <div className="pt-10 pb-10">
                <QAlert
                  className="centered small transparent"
                  type="info"
                  message="The partner card preview above is shown while clients are searching our marketplace.  When clicked, the full partner listing is shown (click below to preview)."
                />
              </div>
              <QButton onClick={handleOpenPreview} block className="qu-button-soft justify-between ShopIcon">
                Preview Market Listing
                <ShopIcon className="ProfileCard__serviceLabel" />
              </QButton>
            </div>
          </Col>
        </Row>
      </Form>

      <Modal
        wrapClassName="qu-modal qu-modal-details"
        closeIcon={<IconsCommon.IconClose />}
        visible={showPreview}
        onCancel={() => setShowPreview(false)}
        footer={null}
      >
        {showPreview && <PartnerDetailsModal details={partnerDetails} isLoading={partnerDetailsLoading} />}
      </Modal>
    </div>
  )
}
export default PublicProfile
