import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import IAction from 'src/interfaces/IAction'

import * as CONSTANTS from './notifications.constants'
import { requestHttp, urls } from 'src/api'
import { INotification, INotificationIds } from './notifications.types'
import { getResponseErrorMessage } from 'src/helpers'
import { getUser } from '../core/core.actions'
import { NOTIFICATIONS } from '../../constants'

export const resetState = (): IAction => ({
  type: CONSTANTS.NOTIFICATIONS_RESET,
})

export const notificationsListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_NOTIFICATIONS_REQUEST,
})

export const notificationsListSuccess = (notificationsList: INotification[]): IAction => ({
  type: CONSTANTS.FETCH_NOTIFICATIONS_SUCCESS,
  payload: notificationsList,
})

export const notificationsListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_NOTIFICATIONS_FAILURE,
  error,
})

export const updateNotificationsStatusRequest = (notificatonIds: INotificationIds): IAction => ({
  type: CONSTANTS.UPDATE_NOTIFICATIONS_REQUEST,
  payload: notificatonIds,
})

export const updateNotificationsStatusSuccess = (): IAction => ({
  type: CONSTANTS.UPDATE_NOTIFICATIONS_SUCCESS,
})

export const updateNotificationsStatusFailure = (error: string): IAction => ({
  type: CONSTANTS.UPDATE_NOTIFICATIONS_FAILURE,
  error,
})

export const fetchNotificationsList = ({ offset, limit }: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(notificationsListRequest())
    const params = { offset, limit }
    const response = await requestHttp.get(urls.getNotificationsListUrl(), { params })
    const { content } = response.data.data

    await dispatch(notificationsListSuccess(content))

    if (content && content.results.length > 0) {
      const ids = markAsReadNotifications(content.results)
      if (!ids.length) return
      await dispatch(updateNotificationsStatus(ids))
      await dispatch(getUser())
    }
  } catch (error: any) {
    dispatch(notificationsListFailure(getResponseErrorMessage(error)))
  }
}

export const updateNotificationsStatus = (ids: number[]): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await requestHttp.patch(urls.getNotificationsUpdateStatusUrl(), { ids })

    if (response.status === 200) {
      dispatch(updateNotificationsStatusSuccess())
    }
  } catch (error: any) {
    dispatch(updateNotificationsStatusFailure(getResponseErrorMessage(error)))
  }
}

const markAsReadNotifications = (notificationsToUpdate: INotification[]) => {
  const notificationIdsToUpdate = notificationsToUpdate
    .filter((elem: INotification) => elem.status === NOTIFICATIONS.STATUSES.UNREAD)
    .map((elem: INotification) => elem.id)

  if (!notificationIdsToUpdate || !notificationIdsToUpdate.length) {
    return []
  }

  return notificationIdsToUpdate
}
