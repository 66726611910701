import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'antd'

export interface IProps {
  stepper: React.ReactNode
}

export function ClientsProfileHeader({ stepper }: IProps): ReactElement {
  const { t } = useTranslation(['translationUser'])
  return (
    <div className="partner-container-header pt-20">
      <h2>{t('client.profile.edit.edit_profile')}</h2>
      {stepper}
    </div>
  )
}
