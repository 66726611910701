// @ts-nocheck
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { ProductTypeCard, Row, Col, IconsAgeGroups, IconsCommon, IconsProductTypes } from 'quantum_components'
import { CURRENCY_SYMBOLS } from '../../../../../constants/currencies'
import { snakeCase } from 'lodash'
import { TAX_TREATMENT } from 'src/constants/clientProfile'

const { IconAgeGroup } = IconsAgeGroups
const { IconBusiness, IconGender, IconIphone, IconPersonLocation, IconPieChart, IconWebsite } = IconsCommon

const {
  IconProductBabyCare2,
  IconProductBeverage2,
  IconProductBodyWash2,
  IconProductCooking2,
  IconProductFabricCare2,
  IconProductFemCare2,
  IconProductHairCare2,
  IconProductOralCare2,
  IconProductPetRelated2,
  IconProductServices2,
  IconProductSkinCare2,
  IconProductSnackFood2,
  IconProductMedicines2,
} = IconsProductTypes

const MEDIA_TYPES_IMAGE = {
  'Baby Care': <IconProductBabyCare2 />,
  Beverages: <IconProductBeverage2 />,
  'Body Wash': <IconProductBodyWash2 />,
  Cooking: <IconProductCooking2 />,
  'Fabric Care': <IconProductFabricCare2 />,
  'Fem Care': <IconProductFemCare2 />,
  'Hair Care': <IconProductHairCare2 />,
  'Oral Care': <IconProductOralCare2 />,
  'Pet Food & Care': <IconProductPetRelated2 />,
  Services: <IconProductServices2 />,
  'Skin Care': <IconProductSkinCare2 />,
  'Snack Food': <IconProductSnackFood2 />,
  'Vitamins & Drugs': <IconProductMedicines2 />,
}

export interface IProps {
  profile: any
}

export function ClientProfilePreviewBody({ profile }: IProps): ReactElement {
  const { t } = useTranslation(['translationUser', 'translationCommon'])
  const renderProductTypes =
    profile.productTypes &&
    profile.productTypes.map((elem: any) => {
      return (
        <ProductTypeCard
          key={elem}
          icon={MEDIA_TYPES_IMAGE[elem]}
          name={t('brief.product-types.' + snakeCase(elem), { ns: 'translationCommon' })}
        />
      )
    })

  const desktopLayout = (
    <div className="BS BS-container">
      <div className="BS__body">
        <Row gutter={30}>
          <Col xs={24} sm={24} md={7}>
            <h2 className="h6">{t('client.profile.view.basic_information')}</h2>
            <div className={`profile-preview-image mt-10 ${!profile.profilePicture && 'no-image'}`}>
              {profile.profilePicture && <img src={profile.profilePicture} alt={profile.firstName} />}
            </div>
          </Col>
          <Col xs={24} sm={24} md={17} className="pt-30">
            <h3 className="h5">
              {profile.firstName} {profile.lastName}
            </h3>
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.company')}</p>
              <p className="info-row-value">{profile.companyName}</p>
            </div>
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.website')}</p>
              <p className="info-row-value">{profile.companyWebsite}</p>
            </div>
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.email')}</p>
              <p className="info-row-value">{profile.email}</p>
            </div>
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.phone')}</p>
              <p className="info-row-value">{profile.phone}</p>
            </div>
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.location')}</p>
              <p className="info-row-value">{profile.location}</p>
            </div>
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.currency')}</p>
              <p className="info-row-value">{CURRENCY_SYMBOLS[profile.currency]}</p>
            </div>
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.vat_registration_no')}</p>
              <p className="info-row-value">{profile.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED ? 'N/A' : profile.vat}</p>
            </div>
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.cr')}</p>
              <p className="info-row-value">{profile.cr}</p>
            </div>
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.national_address')}</p>
              <p className="info-row-value">{profile.nationalAddress}</p>
            </div>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={30}>
          <Col xs={24} sm={24} md={7}>
            <h2 className="h6">{t('client.profile.view.product_types')}</h2>
          </Col>
          <Col xs={24} sm={24} md={17} className="product-types">
            {renderProductTypes}
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={30}>
          <Col xs={24} sm={24} md={7}>
            <h2 className="h6">{t('client.profile.view.target_audience')}</h2>
          </Col>
          <Col xs={24} sm={24} md={17} className="pt-8">
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.ages')}</p>
              <p className="info-row-value">{profile.ageGroups && profile.ageGroups.join(', ')}</p>
            </div>
            {/* <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.segments')}</p>
              <p className="info-row-value">{profile.segments && profile.segments.join(', ')}</p>
            </div> */}
            <div className="info-row">
              <p className="info-row-key">{t('client.profile.view.gender')}</p>
              <p className="info-row-value">
                {profile.genderFocus &&
                  t('brief.targeting.gender.' + snakeCase(profile.genderFocus.join(', ')), { ns: 'translationCommon' })}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )

  const mobileLayout = (
    <>
      <div className="profile-preview-person mt-40">
        <div className={`profile-preview-image ${!profile.profilePicture && 'no-image'}`}>
          {profile.profilePicture && <img src={profile.profilePicture} alt={profile.firstName} />}
        </div>
        <h2 className="h1 mt-10">{profile.name}</h2>
        <p className="info-row-value">{profile.email}</p>
      </div>
      <div className="BS BS-container-mobile">
        <h3 className="h6">{t('client.profile.view.basic_information')}</h3>
        <div className="info-row">
          <div className="info-row-icon">
            <IconBusiness />
          </div>
          <div>
            <p className="info-row-key">{t('client.profile.view.company')}</p>
            <p className="info-row-value">{profile.companyName}</p>
          </div>
        </div>
        <div className="info-row">
          <div className="info-row-icon">
            <IconWebsite />
          </div>
          <div>
            <p className="info-row-key">{t('client.profile.view.website')}</p>
            <p className="info-row-value">{profile.companyWebsite}</p>
          </div>
        </div>
        <div className="info-row">
          <div className="info-row-icon">
            <IconIphone />
          </div>
          <div>
            <p className="info-row-key">{t('client.profile.view.phone')}</p>
            <p className="info-row-value">{profile.phone}</p>
          </div>
        </div>
        <div className="info-row">
          <div className="info-row-icon">
            <IconPersonLocation />
          </div>
          <div>
            <p className="info-row-key">{t('client.profile.view.location')}</p>
            <p className="info-row-value">{profile.location}</p>
          </div>
        </div>
        <div className="info-row">
          <div className="info-row-icon info-row-icon__white" />
          <div>
            <p className="info-row-key">{t('client.profile.view.currency')}</p>
            <p className="info-row-value">{CURRENCY_SYMBOLS[profile.currency]}</p>
          </div>
        </div>
        <div className="info-row">
          <div className="info-row-icon info-row-icon__white" />
          <div>
            <p className="info-row-key">{t('client.profile.view.vat_registration_no')}</p>
            <p className="info-row-value">{profile.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED ? 'N/A' : profile.vat}</p>
          </div>
        </div>
        <div className="info-row">
          <div className="info-row-icon info-row-icon__white" />
          <div>
            <p className="info-row-key">{t('client.profile.view.cr')}</p>
            <p className="info-row-value">{profile.cr}</p>
          </div>
        </div>
        <div className="info-row">
          <div className="info-row-icon info-row-icon__white" />
          <div>
            <p className="info-row-key">{t('client.profile.view.national_address')}</p>
            <p className="info-row-value">{profile.nationalAddress}</p>
          </div>
        </div>
      </div>
      <div className="BS BS-container-mobile">
        <h3 className="h6">{t('client.profile.view.product_types')}</h3>
        <div className="product-types">{renderProductTypes}</div>
      </div>
      <div className="BS BS-container-mobile">
        <h3 className="h6">{t('client.profile.view.target_audience')}</h3>
        <div className="info-row">
          <div className="info-row-icon">
            <IconAgeGroup />
          </div>
          <div>
            <p className="info-row-key">{t('client.profile.view.ages')}</p>
            <p className="info-row-value">{profile.ageGroups && profile.ageGroups.join(', ')}</p>
          </div>
        </div>
        {/* <div className="info-row">
          <div className="info-row-icon">
            <IconPieChart />
          </div>
          <div>
            <p className="info-row-key">{t('client.profile.view.segments')}</p>
            <p className="info-row-value">{profile.segments && profile.segments.join(', ')}</p>
          </div>
        </div> */}
        <div className="info-row">
          <div className="info-row-icon">
            <IconGender />
          </div>
          <div>
            <p className="info-row-key">{t('client.profile.view.gender')}</p>
            <p className="info-row-value">{profile.genderFocus && profile.genderFocus.join(', ')}</p>
          </div>
        </div>
      </div>
    </>
  )

  return <div className="client-preview-container-body mt-20">{isMobile ? mobileLayout : desktopLayout}</div>
}
