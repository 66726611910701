import getFileNameFromUrl from './getFileNameFromUrl'

export const downloadFile = async src => {
  const response = await fetch(src, {
    mode: 'cors',
    cache: 'no-cache',
  })

  const blob = await response.blob()
  const filename = getFileNameFromUrl(response.url)
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
  a.remove()
}
