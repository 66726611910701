import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'

import * as CONSTANTS from './announcement.constants'
import { getResponseErrorMessage } from 'src/helpers'


export const fetchAllAnnouncementsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_ANNOUNCEMENTS_LIST_REQUEST,
})

export const fetchAllAnnouncementsSuccess = (data: any): IAction => ({
  type: CONSTANTS.FETCH_ANNOUNCEMENTS_LIST_SUCCESS,
  payload: data,
})

export const fetchAllAnnouncementsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_ANNOUNCEMENTS_LIST_FAILURE,
  error,
})

export const fetchAnnouncementByIdRequest = (): IAction => ({
  type: CONSTANTS.FETCH_ANNOUNCEMENT_BY_ID_REQUEST,
})

export const fetchAnnouncementByIdSuccess = (data: any): IAction => ({
  type: CONSTANTS.FETCH_ANNOUNCEMENT_BY_ID_SUCCESS,
  payload: data,
})

export const fetchAnnouncementByIdFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_ANNOUNCEMENT_BY_ID_FAILURE,
  error,
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const fetchAllAnnouncementsList = (queryParams: string): ThunkAction<Promise<AxiosResponse<any>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<any>> => {
  try {
    dispatch(fetchAllAnnouncementsRequest())

    const response = await requestHttp.get(
      urls.getAllAnnouncementsUrl(queryParams)
    )
    const { content } = response.data.data

    dispatch(fetchAllAnnouncementsSuccess({ data: content.results, total: content.total }))
    return response
  } catch (error: any) {
    dispatch(fetchAllAnnouncementsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchAnnouncementById = (id: number): ThunkAction<Promise<AxiosResponse<any>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<any>> => {
  try {
    dispatch(fetchAnnouncementByIdRequest())

    const response = await requestHttp.get(
      urls.getAnnouncementByIdUrl(id)
    )
    const { content } = response.data.data

    dispatch(fetchAnnouncementByIdSuccess(content))
    return response
  } catch (error: any) {
    dispatch(fetchAnnouncementByIdFailure(getResponseErrorMessage(error)))
    return error
  }
}
