import React from 'react'
import { QButton } from 'quantum_components'

import { PATHS } from '../../../../../constants'
import { useHistory } from 'react-router-dom'

import { ReactComponent as ImageCheck } from '../../../../../assets/icons/check-icon.svg'
import ClientLayout from '../../../../../components/Layouts/ClientLayout'
import { useSelector } from 'react-redux'
import * as selectors from '../../../../core/core.selectors'

interface IProps {
  approvedUser: boolean
}

export default function TestahelSubmittedSuccessfully({ approvedUser }: IProps) {
  const history = useHistory()
  const currentUser = useSelector(selectors.getCurrentUser)

  const handleFinish = () => {
    history.push(PATHS.BRIEF)
  }

  const headerText = approvedUser ? 'Brief Submitted Successfully!' : 'Brief Created Successfully!'

  const bodyText = approvedUser
    ? 'Your brief is under review by your selected partners. You’ll be notified as soon as your brief has been ' +
      'approved or rejected. Reviews are generally reviewed within 5 business days.'
    : 'Your account is under review. You will be notified as soon as your account has been approved or rejected. ' +
      'Once approved, you can complete your product brief by selecting and submitting it to ' +
      'Partners in order to start a Campaign.'

  return (
    <ClientLayout user={currentUser}>
      <div className="brief-step-success">
        <ImageCheck />
        <h1>{headerText}</h1>
        <p>{bodyText}</p>
        <QButton className="w-180" type="primary" onClick={handleFinish}>
          Okay!
        </QButton>
      </div>
    </ClientLayout>
  )
}
