import React, { ReactElement } from 'react'
import { QBriefFooter, QButton } from 'quantum_components'
import { ReactComponent as ArrowLeft } from '../../../../../assets/icons/arrow-left-icon.svg'
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrow-right-icon.svg'
import { ReactComponent as LogOut } from 'src/assets/icons/logout-icon-alt.svg'
import { useSelector } from 'react-redux'
import { getBrief, getIsSaving } from '../../testahelBox.selectors'

export interface IProps {
  isZeroStep: boolean
  onBack: () => void
  onNext: () => void
  onFinishLater: () => void
  nextButtonText: string
  disableNext: boolean
  disableBack: boolean
}

export default function TestahelBriefFooter({
  isZeroStep,
  onBack,
  onNext,
  onFinishLater,
  disableNext,
  disableBack,
  nextButtonText,
}: IProps): ReactElement {
  const isSaving = useSelector(getIsSaving)

  return (
    <QBriefFooter
      childrenCenter={
        <>
          {!isZeroStep && (
            <QButton disabled={disableBack} onClick={onBack} className="qu-button-outline">
              <ArrowLeft className="mr-5" />
              Back
            </QButton>
          )}
          <QButton disabled={isSaving || disableNext} onClick={onNext} type="primary">
            {nextButtonText}
            <ArrowRight className="ml-5" />
          </QButton>
        </>
      }
      childrenRight={
        isZeroStep ? null : (
          <QButton disabled={disableNext} onClick={onFinishLater} className="qu-button-outline">
            Save & Finish Later <LogOut className="ml-10" />
          </QButton>
        )
      }
    />
  )
}
