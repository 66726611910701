import React, { ReactElement } from "react";
import { useTranslation, withTranslation } from 'react-i18next'
import { QBriefFooter, QButton } from "quantum_components";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow-left-icon.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-icon.svg";
import { ReactComponent as LogOut } from "src/assets/icons/logout-icon-alt.svg";
import { useSelector } from "react-redux";
import { getIsSaving } from "../../brief.selectors";
import { LOCALIZATION_LANGUAGES } from 'src/constants'

export interface IProps {
  isZeroStep: boolean;
  isPaymentStep?: boolean;
  onBack: () => void;
  onNext: () => void;
  onFinishLater: () => void;
  nextButtonText: string;
  disableNext: boolean;
  disableBack: boolean;
  disableSave?: boolean;
  hideArrow?: boolean;
  className: string;
}

export default function BriefFooter({
  isZeroStep,
  isPaymentStep = false,
  onBack,
  onNext,
  onFinishLater,
  disableNext,
  disableBack,
  hideArrow = false,
  nextButtonText,
  className = ''
}: IProps): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translation', 'translationCommon'])
  const isSaving = useSelector(getIsSaving);

  return (
    <QBriefFooter
      className={isZeroStep && 'isZeroStepActive'}
      childrenCenter={
        <>
          {!isZeroStep && (
            <QButton
              disabled={disableBack}
              onClick={onBack}
              className={
                nextButtonText === 'Submit & Generate Quotation'
                  ? 'qu-button-outline footer-btn-width'
                  : 'qu-button-outline'
              }
            >
              {i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? (
                <ArrowLeft className="mr-5" />
              ) : (
                <ArrowRight className="mr-5" />
              )}
              {t('common.Back', { ns: 'translationCommon' })}
            </QButton>
          )}
          <QButton
            disabled={isSaving || disableNext}
            onClick={onNext}
            type="primary"
            className={nextButtonText === t('brief.edit.common.submit_and_view_quotation') ? 'footer-btn-width' : ''}
          >
            {nextButtonText}
            {!isPaymentStep &&
              (i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? (
                <ArrowRight className="ml-5" />
              ) : (
                <ArrowLeft className="ml-5" />
              ))}
          </QButton>
        </>
      }
      childrenRight={
        isZeroStep ? null : (
          <QButton disabled={isPaymentStep ? false : disableNext} onClick={onFinishLater} className="qu-button-outline">
            {isPaymentStep ? t('brief.edit.common.save_and_pay_later') : t('brief.edit.common.save_and_finish_later')}{' '}
            <LogOut className={i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? 'ml-10' : 'mr-10'} />
          </QButton>
        )
      }
    />
  );
}
