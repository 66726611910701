export default Object.freeze({
  ACCESS_TOKEN: 'accessToken',
  USER_ROLE: 'userRole',
  SIGN_UP_EMAIL: 'signUpEmail',
  SIGN_UP_PHONE: 'signUpPhone',
  SIGN_UP_STEP: 'signUpStep',
  SIGN_UP_STATUS: 'signUpStatus',
  SIGN_IN_STEP: 'signInStep',
  RESET_PASSWORD_STEP: 'resetPasswordStep',
  RESET_PASSWORD_EMAIL: 'resetPasswordEmail',
  RESET_PASSWORD_PHONE: 'resetPasswordPhone',
  INVITER_SPECIAL_ID: 'inviterSpecialId',
  INVITER_SPECIAL_TOKEN: 'inviterSpecialToken',
  INVITER_PARTNER_LOGO: 'inviterPartnerLogo',
  LOCALIZATION: 'localization',
})
