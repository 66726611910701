// @ts-nocheck
import React from 'react'

export interface IProps {
  visible:boolean
}

const LanguageLoader = ({ visible = false }: IProps) => {
  return <>
    {
      visible ? 
      <div id="arabic-loader-container">
        <div className="loader"></div>
      </div>
      :
      ""
    }
  </>
}

export default LanguageLoader
