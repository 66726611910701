import { ReactElement, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { LOCALIZATION_LANGUAGES } from 'src/constants'
import AvailableServiceForm from './briefForms/AvailableService.form'
import BriefV2Footer from './components/BriefV2.footer'
import BriefV2Header from './components/BriefV2Static.header'

enum BRIEF_V2_STEPS {
  SERVICES = 'SERVICES'
}

export default function BriefV2EditContainer(): ReactElement {
  const { i18n } = useTranslation()
  const { id } = useParams<any>()

  const { t } = useTranslation(['translation', 'translationCommon', 'translationInviteClient'])
  const [steps, setSteps] = useState(BRIEF_V2_STEPS.SERVICES)

  return (
    <div className={`qu-brief-layout ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}>
      {isMobile && <BriefV2Header />}

      {steps === BRIEF_V2_STEPS.SERVICES && <AvailableServiceForm id={id} />}

      {isMobile && <BriefV2Footer />}
    </div>
  )
}
