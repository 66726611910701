import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import {
  BRIEF_STEPS,
  BRIEF_TYPES,
  CONSUMER_RESEARCH_MEDIA_TYPES,
  DISTRIBUTION_TYPE,
  MEDIA_GROUP_TYPE,
  SERVICE_NAMES_FOR_TABLE,
} from '../../../../../constants/testahel_brief'
import { Col, Descriptions, Row } from 'antd'
import moment from 'moment'
import { FORMATS } from '../../../../../constants'
import { BriefSummary, IconsCommon } from 'quantum_components'
import { IBriefResponse, IUploadPartner } from '../../testahelBox.types'
import { TestahelMediaTypeIcon } from './TestahelBrief.MediaTypeCard'
import { COLORS } from '../../../../../constants/colors'
import { getFileNameFromUrl } from '../../../../../utils'
import { getBase64 } from '../../../../../utils/getBase64'
import { getLocaleCost } from 'src/helpers/currency'
import { BRIEF_ADVERTISEMENT_LANGUAGES } from 'src/constants/testahel_brief'
import { useSelector } from 'react-redux'
import { getBriefSettings } from '../../testahelBox.selectors'
import imageSurvey from '../../../../../assets/survey.jpg'
import imageChallenge from '../../../../../assets/challenge.jpg'

const checkIcon = <IconsCommon.IconCheck color={COLORS.LIGHT_GREEN} />

const uncheckIcon = <IconsCommon.IconStepIncomplete />

interface IProps {
  briefView: IBriefResponse
  setCurrentStep: (step: BRIEF_STEPS) => void
  hideTitle?: boolean
  title?: string
  subtitle?: string
  viewMode?: boolean
  partnerQuantity?: number
  requestType?: MEDIA_GROUP_TYPE
  partnerIds?: number[]
}

export default function TestahelBriefViewSummary({
  briefView,
  setCurrentStep,
  hideTitle,
  title,
  subtitle,
  viewMode,
  partnerQuantity,
  requestType,
  partnerIds,
}: IProps): ReactElement {
  const isProductSample = briefView.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isCustomizeBox = briefView.briefTypes?.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
  const isMediaBuying = briefView.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
  const isConsumerResearch = briefView.briefTypes?.includes(BRIEF_TYPES.CONSUMER_RESEARCH)

  const briefSettings = useSelector(getBriefSettings)

  const [productImage, setProductImage] = useState<string>('')
  const [serviceImage, setServiceImage] = useState<string>('')
  const [uploadMaterial, setUploadMaterial] = useState<IUploadPartner[]>([])

  const getProductImage = async () => {
    const productImageUrl = briefView.productImages ? briefView.productImages[0] : ''

    if (productImageUrl) {
      const image = await getBase64(productImageUrl)
      setProductImage(image)
    }

    const serviceImageUrl = briefView.serviceImages ? briefView.serviceImages[0] : ''

    if (serviceImageUrl) {
      const image = await getBase64(serviceImageUrl)
      setServiceImage(image)
    }
  }

  const getUploadsBase64 = async (upload: IUploadPartner) => {
    return Promise.resolve(getBase64(upload.imageTargetUrl))
  }

  const getUploadMaterial = async () => {
    const uploads = partnerIds
      ? briefView.uploadMaterial.filter((file: IUploadPartner) => partnerIds.includes(file.partnerId))
      : briefView.uploadMaterial
    if (!uploads) return

    const list = uploads.map((material: IUploadPartner) => {
      // getUploadsBase64(material).then((data: string) => {
      //   material.imageTargetUrl = data
      // })
      return material
    })

    setUploadMaterial(list)
  }

  useEffect(() => {
    async function fetchImages() {
      await getProductImage()
      await getUploadMaterial()
    }
    fetchImages()
  }, [briefView])

  const isRequestMediaBuy = requestType && requestType === MEDIA_GROUP_TYPE.MEDIA_BUY
  const isRequestDistribution =
    requestType && (requestType === MEDIA_GROUP_TYPE.PRINTED_INSERT || requestType === MEDIA_GROUP_TYPE.PRODUCT_SAMPLE)

  const getTitle = () => {
    if (hideTitle) return ''
    if (title) return title

    return 'Consumer Research Brief Summary'
  }
  const distributionItem =
    briefView.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
      ? SERVICE_NAMES_FOR_TABLE.INSERT
      : SERVICE_NAMES_FOR_TABLE.PRODUCT

  const serviceBlock = requestType ? (
    <ul className="BSUnit__list">
      <li className={'BSUnit__list-item'}>
        <i className="i">{checkIcon}</i>
        {requestType}
      </li>
    </ul>
  ) : (
    <ul className="BSUnit__list">
      <li className={`BSUnit__list-item ${!isProductSample && 'uncheck'}`}>
        <i className="i">{isProductSample ? checkIcon : uncheckIcon}</i>
        {distributionItem}
      </li>
      <li className={`BSUnit__list-item ${!isCustomizeBox && 'uncheck'}`}>
        <i className="i">{isCustomizeBox ? checkIcon : uncheckIcon}</i>
        Customize Box
      </li>
      <li className={`BSUnit__list-item ${!isConsumerResearch && 'uncheck'}`}>
        <i className="i">{isConsumerResearch ? checkIcon : uncheckIcon}</i>
        Consumer Research
      </li>
      <li className={`BSUnit__list-item ${!isMediaBuying && 'uncheck'}`}>
        <i className="i">{isMediaBuying ? checkIcon : uncheckIcon}</i>
        Media Buying
      </li>
    </ul>
  )
  return (
    <BriefSummary className="testahel-summary" title={getTitle()} subtitle={subtitle ? subtitle : ''}>
      <div className="BSUnit">
        <div className="BSUnit__heading">
          <h4 className="BSUnit__title">Services</h4>
        </div>
        <div className="BSUnit__content">
          {!viewMode && (
            <span onClick={() => setCurrentStep(BRIEF_STEPS.TYPE)} className="BSUnit__edit" data-html2canvas-ignore>
              Edit
            </span>
          )}
          {serviceBlock}
        </div>
      </div>
      {briefView.name && (
        <div className="BSUnit">
          <div className="BSUnit__heading">
            <h4 className="BSUnit__title">Product Brief</h4>
            <div className="BSUnit__thumb">{productImage && <img src={productImage} alt={briefView.name} />}</div>
          </div>
          <div className="BSUnit__content">
            {!viewMode && (
              <span
                onClick={() => setCurrentStep(BRIEF_STEPS.PRODUCT)}
                className="BSUnit__edit"
                data-html2canvas-ignore
              >
                Edit
              </span>
            )}
            <div className="BSUnit__content-header">
              <h5 className="BSUnit__content-title">{briefView.name}</h5>
              <h6 className="BSUnit__content-subTitle">{briefView.productType}</h6>
            </div>
            {(!requestType || isRequestDistribution) && (
              <div className="BSUnit__descriptions">
                <Descriptions column={1}>
                  <Descriptions.Item label="Format">{briefView.distributionItem || 'None'}</Descriptions.Item>
                  <Descriptions.Item label="Quantity">
                    {Number.isInteger(partnerQuantity) ? partnerQuantity || 'None' : briefView.quantity || 'None'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Desired Start Date">
                    {briefView.briefStartDate
                      ? moment(briefView.briefStartDate).format(FORMATS.DATE_MMM_YYYY_FORMAT)
                      : 'None'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Instructions">{briefView.instructions || 'None'}</Descriptions.Item>
                </Descriptions>
              </div>
            )}
          </div>
        </div>
      )}
      {briefView.customizeBoxBriefName && (
        <div className="BSUnit">
          <div className="BSUnit__heading">
            <h4 className="BSUnit__title">Customize Box</h4>
          </div>
          <div className="BSUnit__content">
            {!viewMode && (
              <span
                onClick={() => setCurrentStep(BRIEF_STEPS.CUSTOMIZED)}
                className="BSUnit__edit"
                data-html2canvas-ignore
              >
                Edit
              </span>
            )}
            <div className="BSUnit__content-header" style={{ padding: '0' }}>
              <h5 className="BSUnit__content-title">{briefView.customizeBoxBriefName}</h5>
            </div>
            <div className="BSUnit__descriptions">
              <Descriptions column={1}>
                <Descriptions.Item label="Quantity">{briefView.customizeBoxDistributionCount}</Descriptions.Item>
                <Descriptions.Item label="Products per Box">{briefView.customizeBoxProductCount}</Descriptions.Item>
                <Descriptions.Item label="Product Types">
                  {briefView.customizeBoxProductType?.join(', ') || 'None'}
                </Descriptions.Item>
                <Descriptions.Item label="Desired Start Date">
                  {briefView.customizeBoxStartDate
                    ? moment(briefView.customizeBoxStartDate).format(FORMATS.DATE_LL_FORMAT)
                    : 'None'}
                </Descriptions.Item>
                <Descriptions.Item label="Instructions">
                  {briefView.customizeBoxAdditionalComment || 'None'}
                </Descriptions.Item>
              </Descriptions>
              <Row gutter={16}>
                {briefView.customizeBoxProductImages &&
                  briefView.customizeBoxProductImages.length > 0 &&
                  briefView.customizeBoxProductImages.map((ele, index) => (
                    <Col key={index} style={{ marginTop: '15px' }}>
                      <div className="BSUnit__thumb">
                        <img src={ele} />
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
        </div>
      )}
      <Fragment>
        <div className="BSUnit">
          <div className="BSUnit__heading">
            <h4 className="BSUnit__title">Targeting</h4>
          </div>
          <div className="BSUnit__content">
            {!viewMode && (
              <span
                onClick={() => setCurrentStep(BRIEF_STEPS.TARGETING)}
                className="BSUnit__edit"
                data-html2canvas-ignore
              >
                Edit
              </span>
            )}
            <div className="BSUnit__descriptions">
              <Descriptions column={1}>
                <Descriptions.Item label="Ages">{briefView.targetAgeGroups?.join(', ') || 'None'}</Descriptions.Item>
                <Descriptions.Item label="Segments">{briefView.targetSegments?.join(', ') || 'None'}</Descriptions.Item>
                <Descriptions.Item label="Interests">
                  {briefView.targetInterests?.join(', ') || 'None'}
                </Descriptions.Item>
                <Descriptions.Item label="Gender">{briefView.targetFocus?.join(', ') || 'None'}</Descriptions.Item>
                <Descriptions.Item label="Countries">
                  {briefView.targetCountries?.join(', ') || 'None'}
                </Descriptions.Item>
                <Descriptions.Item label="Cities">{briefView.targetCities?.join(', ') || 'None'}</Descriptions.Item>
                <Descriptions.Item label="Districts">
                  {briefView.targetDistricts?.join(', ') || 'None'}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </div>
        {((!requestType && isProductSample) || isRequestDistribution) && (
          <div className="BSUnit">
            <div className="BSUnit__heading">
              <h4 className="BSUnit__title">Additional Info</h4>
              <div className="BSUnit__thumb">{serviceImage && <img src={serviceImage} alt="" />}</div>
            </div>
            <div className="BSUnit__content">
              {!viewMode && (
                <span
                  onClick={() => setCurrentStep(BRIEF_STEPS.SERVICES)}
                  className="BSUnit__edit"
                  data-html2canvas-ignore
                >
                  Edit
                </span>
              )}
              <Descriptions column={2}>
                <Descriptions.Item label="Packaging Type">{briefView.servicePackageType || 'None'}</Descriptions.Item>
                <Descriptions.Item label="Temperature Condition">
                  {briefView.serviceTemperature || 'None'}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Samples per Bundle" style={{ verticalAlign: 'baseline' }}>
                  {briefView.servicePackagingQuantity || 'None'}
                </Descriptions.Item> */}
                {/* <Descriptions.Item label="Co-packaging needs">
                  {briefSettings?.copackingPrices?.filter(ele => ele.id == briefView.copackingPriceId).length > 0
                    ? briefSettings?.copackingPrices?.filter(ele => ele.id == briefView.copackingPriceId)[0].name
                    : 'None'}
                </Descriptions.Item> */}
                <Descriptions.Item label="Printing needs">
                  {briefSettings?.printingPrices?.filter(ele => ele.id == briefView.printingPriceId).length > 0
                    ? briefSettings?.printingPrices?.filter(ele => ele.id == briefView.printingPriceId)[0].name
                    : 'None'}
                </Descriptions.Item>
              </Descriptions>
              <ul className="BSUnit__list">
                <li className={`BSUnit__list-item ${!briefView.serviceIsLiquid && 'uncheck'}`}>
                  <i className="i">{briefView.serviceIsLiquid ? checkIcon : uncheckIcon}</i>
                  Sample is Liquid
                </li>
                <li className={`BSUnit__list-item ${!briefView.serviceIncludingCoupon && 'uncheck'}`}>
                  <i className="i">{briefView.serviceIncludingCoupon ? checkIcon : uncheckIcon}</i>
                  Include Coupon / Marketing Material
                </li>
                <li className={`BSUnit__list-item ${!briefView.serviceDesign && 'uncheck'}`}>
                  <i className="i">{briefView.serviceDesign ? checkIcon : uncheckIcon}</i>
                  Require Insert Design
                </li>
                {/* <li className={`BSUnit__list-item ${!briefView.servicePackaging && 'uncheck'}`}>
                  <i className="i">{briefView.servicePackaging ? checkIcon : uncheckIcon}</i>
                  Require Co-Packing Services
                </li> */}
                <li className={`BSUnit__list-item ${!briefView.servicePrinting && 'uncheck'}`}>
                  <i className="i">{briefView.servicePrinting ? checkIcon : uncheckIcon}</i>
                  Require Printing
                </li>
                {/* <li className={`BSUnit__list-item ${!briefView.hasFreeSample && 'uncheck'}`}>
                  <i className="i">{briefView.hasFreeSample ? checkIcon : uncheckIcon}</i>
                  Free Sample
                </li> */}
              </ul>
            </div>
          </div>
        )}
        {((!requestType && isMediaBuying) || isRequestMediaBuy) && (
          <div className="BSUnit">
            <div className="BSUnit__heading">
              <h4 className="BSUnit__title">Media Buying</h4>
            </div>
            <div className="BSUnit__content">
              {!viewMode && (
                <span
                  onClick={() => setCurrentStep(BRIEF_STEPS.MEDIA_BUY)}
                  className="BSUnit__edit"
                  data-html2canvas-ignore
                >
                  Edit
                </span>
              )}
              <div className="BSUnit__descriptions">
                <Descriptions column={1}>
                  <Descriptions.Item label="Desired Start Date">
                    {briefView.mediaStartDate
                      ? moment(briefView.mediaStartDate).format(FORMATS.DATE_LL_FORMAT)
                      : 'None'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Marketing Objective">
                    {briefView.marketingObjective || 'None'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Duration">{briefView.mediaDuration || 'None'}</Descriptions.Item>
                  <Descriptions.Item label="Budget">
                    {briefView.budgetValue ? (
                      <>
                        {getLocaleCost(briefView.budgetValue)} {briefView.budgetCurrency} ({briefView.budgetType})
                      </>
                    ) : (
                      <>{'None'}</>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </div>
              {briefView.mediaBuyEstimates && briefView.mediaBuyEstimates.length > 0 && (
                <div className="BSUnit__descriptions">
                  {briefView.mediaBuyEstimates.map((mediaBuyEst: any) => (
                    <div className="mb-10">
                      <span style={{ fontWeight: 'bold' }}>{mediaBuyEst.companyName} Estimates:</span>
                      <Descriptions column={1} className="mt-8">
                        {mediaBuyEst.campaignDays && (
                          <Descriptions.Item label="Campaign Days">{mediaBuyEst.campaignDays}</Descriptions.Item>
                        )}
                        {mediaBuyEst.impressions && (
                          <Descriptions.Item label="Impressions">{mediaBuyEst.impressions}</Descriptions.Item>
                        )}
                        {mediaBuyEst.clicks && (
                          <Descriptions.Item label="Total Clicks">{mediaBuyEst.clicks}</Descriptions.Item>
                        )}
                        {mediaBuyEst.reach && (
                          <Descriptions.Item label="Total Reach">{mediaBuyEst.reach}</Descriptions.Item>
                        )}
                      </Descriptions>
                    </div>
                  ))}
                </div>
              )}
              <div className="BSUnit__bList">
                {briefView.mediaTypes?.map((media: string) => (
                  <TestahelMediaTypeIcon key={media} name={media} />
                ))}
              </div>
              {uploadMaterial && (
                <div className="BSUnit__media">
                  {uploadMaterial.map((upload: any, i: number) => {
                    const name = upload.mediaSubType ? upload.mediaSubType : upload.mediaType
                    if (
                      name !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
                      name !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
                    ) {
                      return (
                        <div className="media-item" key={i}>
                          <hr className="form__hr" />
                          <div className="media-item-label">{name}</div>
                          <div className="BSUnit__descriptions">
                            {
                              <Descriptions column={1}>
                                {name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ||
                                name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE ? (
                                  upload.crImageTargetUrl.map((ele: string) => (
                                    <Fragment key={ele}>
                                      <div className="media-item-image">
                                        <img src={ele} alt={name} />
                                      </div>
                                      <div className="media-item-hint">{getFileNameFromUrl(ele)}</div>
                                    </Fragment>
                                  ))
                                ) : (
                                  <>
                                    {upload.link && (
                                      <Descriptions.Item label="Target Url">
                                        <a href={upload.link} target="_blank" rel="noopener noreferrer">
                                          {upload.link}
                                        </a>
                                      </Descriptions.Item>
                                    )}
                                    {Object.entries(BRIEF_ADVERTISEMENT_LANGUAGES).map(([key, value]) => (
                                      <Fragment key={key}>
                                        {upload.adTitle[key] && (
                                          <Descriptions.Item label={`${value} Title`}>
                                            {upload.adTitle[key]}
                                          </Descriptions.Item>
                                        )}
                                        {upload.adDescription[key] && (
                                          <Descriptions.Item label={`${value} Description`}>
                                            {upload.adDescription[key]}
                                          </Descriptions.Item>
                                        )}
                                        {upload.imageTargetUrl[key] && (
                                          <Fragment>
                                            <div className="media-item-image">
                                              {upload.imageTargetUrl[key].includes('.webm') ||
                                              upload.imageTargetUrl[key].includes('.mp4') ||
                                              upload.imageTargetUrl[key].includes('.ogg') ? (
                                                <video width="178" height="100" controls>
                                                  <source
                                                    src={upload.imageTargetUrl[key]}
                                                    type={`video/${
                                                      upload.imageTargetUrl[key].split('.')[
                                                        upload.imageTargetUrl[key].split('.').length - 1
                                                      ]
                                                    }`}
                                                  />
                                                  Your browser does not support HTML video.
                                                </video>
                                              ) : (
                                                <img src={upload.imageTargetUrl[key]} alt={name} />
                                              )}
                                            </div>
                                            <div className="media-item-hint">
                                              {getFileNameFromUrl(upload.imageTargetUrl[key])}
                                              <span>
                                                {upload.width}
                                                {upload.height && `x ${upload.height}`}
                                              </span>
                                            </div>
                                          </Fragment>
                                        )}
                                      </Fragment>
                                    ))}
                                  </>
                                )}
                              </Descriptions>
                            }
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {!requestType && isConsumerResearch && (
          <div className="BSUnit">
            <div className="BSUnit__heading">
              <h4 className="BSUnit__title">Consumer Research</h4>
            </div>
            <div className="BSUnit__content">
              {!viewMode && (
                <span
                  onClick={() => setCurrentStep(BRIEF_STEPS.CONSUMER_RESEARCH)}
                  className="BSUnit__edit"
                  data-html2canvas-ignore
                >
                  Edit
                </span>
              )}
              <div className="BSUnit__bList">
                {briefView.consumerResearchMediaType?.map((media: string) => (
                  <div className="BSUnit__bList-item" key={media}>
                    <div className="BSUnit__bList-img">
                      <img
                        key={media}
                        src={media === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ? imageSurvey : imageChallenge}
                      />
                    </div>
                    <div className="BSUnit__bList-ttl">{media.replace(/CR /g, '')}</div>
                  </div>
                ))}
              </div>
              <div className="BSUnit__descriptions">
                <Descriptions column={1}>
                  <Descriptions.Item label="Desired Start Date">
                    {briefView.consumerResearchStartDate
                      ? moment(briefView.consumerResearchStartDate).format(FORMATS.DATE_LL_FORMAT)
                      : 'None'}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Duration">{briefView.consumerResearchDuration || 'None'}</Descriptions.Item> */}
                  <Descriptions.Item label="Objective">{briefView.consumerResearchObjective}</Descriptions.Item>
                  <Descriptions.Item label="Additional Notes">
                    {briefView.consumerResearchNotes || 'None'}
                  </Descriptions.Item>
                </Descriptions>
              </div>
              {uploadMaterial && (
                <div className="BSUnit__media">
                  {uploadMaterial.map((upload: any, i: number) => {
                    const name = upload.mediaSubType ? upload.mediaSubType : upload.mediaType
                    if (
                      name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ||
                      name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
                    ) {
                      return (
                        <div className="media-item" key={i}>
                          <hr className="form__hr" />
                          <div className="media-item-label">{name.replace(/CR /g, '')}</div>
                          <div className="BSUnit__descriptions">
                            {
                              <Descriptions column={1}>
                                {name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ||
                                name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE ? (
                                  upload.crImageTargetUrl.map((ele: string) => (
                                    <Fragment key={ele}>
                                      <div className="media-item-image">
                                        <img src={ele} alt={name} />
                                      </div>
                                      <div className="media-item-hint">{getFileNameFromUrl(ele)}</div>
                                    </Fragment>
                                  ))
                                ) : (
                                  <>
                                    {upload.link && (
                                      <Descriptions.Item label="Target Url">
                                        <a href={upload.link} target="_blank" rel="noopener noreferrer">
                                          {upload.link}
                                        </a>
                                      </Descriptions.Item>
                                    )}
                                    {Object.entries(BRIEF_ADVERTISEMENT_LANGUAGES).map(([key, value]) => (
                                      <Fragment key={key}>
                                        {upload.adTitle[key] && (
                                          <Descriptions.Item label={`${value} Title`}>
                                            {upload.adTitle[key]}
                                          </Descriptions.Item>
                                        )}
                                        {upload.adDescription[key] && (
                                          <Descriptions.Item label={`${value} Description`}>
                                            {upload.adDescription[key]}
                                          </Descriptions.Item>
                                        )}
                                        {upload.imageTargetUrl[key] && (
                                          <Fragment>
                                            <div className="media-item-image">
                                              {upload.imageTargetUrl[key].includes('.webm') ||
                                              upload.imageTargetUrl[key].includes('.mp4') ||
                                              upload.imageTargetUrl[key].includes('.ogg') ? (
                                                <video width="178" height="100" controls>
                                                  <source
                                                    src={upload.imageTargetUrl[key]}
                                                    type={`video/${
                                                      upload.imageTargetUrl[key].split('.')[
                                                        upload.imageTargetUrl[key].split('.').length - 1
                                                      ]
                                                    }`}
                                                  />
                                                  Your browser does not support HTML video.
                                                </video>
                                              ) : (
                                                <img src={upload.imageTargetUrl[key]} alt={name} />
                                              )}
                                            </div>
                                            <div className="media-item-hint">
                                              {getFileNameFromUrl(upload.imageTargetUrl[key])}
                                              <span>
                                                {upload.width}
                                                {upload.height && `x ${upload.height}`}
                                              </span>
                                            </div>
                                          </Fragment>
                                        )}
                                      </Fragment>
                                    ))}
                                  </>
                                )}
                              </Descriptions>
                            }
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    </BriefSummary>
  )
}
