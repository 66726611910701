import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd';
import { Link } from "react-router-dom";
import { LOCAL_STORAGE_KEYS, PATHS } from "../../../constants";
import { ReactComponent as Qlogo } from "../../../assets/quantum-logo.svg";
import AuthPageLadyImage from "../../../assets/auth-page-lady.png";
import * as actions from "src/modules/core/core.actions";
import { useDispatch, useSelector } from "react-redux";
import { getActiveSaasPartner } from "src/modules/core/core.selectors";
import { MenuOutlined } from '@ant-design/icons';
import { SocialList, Dropdown, Row, Col, QButton } from "quantum_components";
import { getSocial } from "src/helpers/social";
import { LOCALIZATION_LANGUAGES } from '../../../constants'
import { isMobile } from "react-device-detect";
import SpinnerCenter from "src/components/Spinner/spinner";

const { Header } = Layout;

interface Props {
  hidePartnerInfo?: Boolean,
  children: React.ReactNode;
  AuthPageLadyImageMaxWidth?: String
}

const AuthLayout = ({ hidePartnerInfo, children, AuthPageLadyImageMaxWidth = "300px" }: Props) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch();
  const sysLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION) || "en";

  const saasPartner = useSelector(getActiveSaasPartner);
  const [langUpdate, setlangUpdate] = useState(0);
  const [showLangLoader, setShowLangLoader] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(
      sysLang == LOCALIZATION_LANGUAGES.ARABIC
        ? LOCALIZATION_LANGUAGES.ARABIC
        : LOCALIZATION_LANGUAGES.ENGLISH
    );

    i18n.changeLanguage(
      sysLang === LOCALIZATION_LANGUAGES.ARABIC
        ? LOCALIZATION_LANGUAGES.ARABIC
        : LOCALIZATION_LANGUAGES.ENGLISH
    );

    dispatch(actions.fetchSaasPartnerDetails());
  }, []);

  const changeLanguage = () => {
    setShowLangLoader(true);
    if (sysLang && sysLang === LOCALIZATION_LANGUAGES.ENGLISH) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ARABIC
      );
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ARABIC);
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ENGLISH
      );
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ENGLISH);
    }
    setlangUpdate(langUpdate + 1);
    window.location.reload()
  };

  const getLocalizationLanguage = () => {
    return sysLang === LOCALIZATION_LANGUAGES.ENGLISH ? "العربية" : "English";
  };

  return (
    <div className={`layout auth ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''} ${hidePartnerInfo ? 'remove-auth-extras' : ''}`} >
      {showLangLoader && <SpinnerCenter />}
      <div className={`auth-section ${hidePartnerInfo ? 'remove-auth-extras' : ''}`}>
        {/* <div className="auth-section-header">
          <Link to={PATHS.SIGN_IN}>
            <Qlogo />
          </Link>
        </div> */}
        <Layout className="auth-section-header-parent">
          <Header className="auth-header-section">
            <div className="image-logo">
              {/* {saasPartner?.publicProfile?.companyLogo && <img src={saasPartner?.publicProfile?.companyLogo} alt="Logo" className="mx-auto" />} */}
            </div>
            {
              isMobile
                ? <Dropdown
                  className="auth-mobile-menu-dropdown"
                  overlay={
                    <Menu className="user-menu-list">
                      {sysLang && <Menu.Item key="1">
                        <a onClick={() => changeLanguage()}>
                          {getLocalizationLanguage()}
                        </a>
                      </Menu.Item>}
                    </Menu>}
                  trigger={['click']}
                  placement="bottomRight"
                  getPopupContainer={(node: any) => node.parentNode}
                >
                  <div className="user-menu">
                    <MenuOutlined />
                  </div>
                </Dropdown>
                : <QButton
                  className="text-capitalize language-btn"
                  type="primary"
                  onClick={() => changeLanguage()}
                >
                  {getLocalizationLanguage()}
                </QButton>
              // <Menu mode="horizontal" className="auth-home-menu">
              //   {sysLang && <Menu.Item key="1">
              //     <a onClick={() => changeLanguage()} className="auth-language-button">
              //       {getLocalizationLanguage()}
              //     </a>
              //   </Menu.Item>}
              // </Menu>
            }
          </Header>
        </Layout>
        <div className={`auth-section-body ${hidePartnerInfo ? 'remove-auth-extras' : ''}`}>
          {!hidePartnerInfo && (
            <>
              <div style={{ maxWidth: `${AuthPageLadyImageMaxWidth}` }}>
                <img src={AuthPageLadyImage} />
                {/* <div>
                  <Link to={PATHS.SIGN_IN}>
                    <Qlogo />
                    {saasPartner?.publicProfile?.companyLogo ? <img src={saasPartner?.publicProfile?.companyLogo} style={{ maxWidth: "160px" }} alt="Logo" className="mx-auto" /> : <div className="mx-auto" style={{ width: '160px' }}></div>}
                  </Link>
                </div> */}
                {/* <h1 className="mb-0 pt-10">
              {saasPartner?.companyName}
            </h1> */}
                {/* <p className="mt-15 mb-0 pb-0 text-justify">
                  {`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? saasPartner?.partnerProfile?.companyDescriptionAr : saasPartner?.partnerProfile?.companyDescription}`}
                </p> */}
              </div>
              <div className="divider-container">
                <div className="divider"></div>
              </div>
            </>
          )}

          <div>{children}</div>
        </div>
        <Row justify="end" align="middle" className="auth-footer-section">
          <Col className="image-logo">
            {/* {saasPartner?.publicProfile?.companyLogo && <img src={saasPartner?.publicProfile?.companyLogo} alt="Logo" className="mx-auto" />} */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AuthLayout;
