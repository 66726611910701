import React, { ReactElement } from 'react'
import { Col, QSelectItem } from 'quantum_components'

import { ReactComponent as BrandStore } from 'src/assets/icons/media-types/brand-store.svg'
import { ReactComponent as BrandStoreActive } from 'src/assets/icons/media-types/brand-store2.svg'
import { ReactComponent as BrandedPrinting } from 'src/assets/icons/media-types/branded-printing.svg'
import { ReactComponent as BrandedPrintingActive } from 'src/assets/icons/media-types/branded-printing2.svg'
import { ReactComponent as CashbackOffers } from 'src/assets/icons/media-types/cashback-offers.svg'
import { ReactComponent as CashbackOffersActive } from 'src/assets/icons/media-types/cashback-offers2.svg'
import { ReactComponent as EmailBlast } from 'src/assets/icons/media-types/email-blast.svg'
import { ReactComponent as EmailBlastActive } from 'src/assets/icons/media-types/email-blast2.svg'
import { ReactComponent as FreeDelivery } from 'src/assets/icons/media-types/free-delivery.svg'
import { ReactComponent as FreeDeliveryActive } from 'src/assets/icons/media-types/free-delivery2.svg'
import { ReactComponent as HomePageBanners } from 'src/assets/icons/media-types/home-page-banners.svg'
import { ReactComponent as HomePageBannersActive } from 'src/assets/icons/media-types/home-page-banners2.svg'
import { ReactComponent as InAppBanners } from 'src/assets/icons/media-types/in-app-banners.svg'
import { ReactComponent as InAppBannersActive } from 'src/assets/icons/media-types/in-app-banners2.svg'
import { ReactComponent as OnlineSurvey } from 'src/assets/icons/media-types/online-survey.svg'
import { ReactComponent as OnlineSurveyActive } from 'src/assets/icons/media-types/online-survey2.svg'
import { ReactComponent as OohMediaPartnership } from 'src/assets/icons/media-types/ooh-media-partnership.svg'
import { ReactComponent as OohMediaPartnershipActive } from 'src/assets/icons/media-types/ooh-media-partnership2.svg'
import { ReactComponent as PaidSmCampaign } from 'src/assets/icons/media-types/paid-sm-campaign.svg'
import { ReactComponent as PaidSmCampaignActive } from 'src/assets/icons/media-types/paid-sm-campaign2.svg'
import { ReactComponent as PopupWindow } from 'src/assets/icons/media-types/popup-window.svg'
import { ReactComponent as PopupWindowActive } from 'src/assets/icons/media-types/popup-window2.svg'
import { ReactComponent as PriceOffPromo } from 'src/assets/icons/media-types/price-off-promo.svg'
import { ReactComponent as PriceOffPromoActive } from 'src/assets/icons/media-types/price-off-promo2.svg'
import { ReactComponent as PushNotifications } from 'src/assets/icons/media-types/push-notifications.svg'
import { ReactComponent as PushNotificationsActive } from 'src/assets/icons/media-types/push-notifications2.svg'
import { ReactComponent as Feed } from 'src/assets/icons/media-types/feed.svg'
import { ReactComponent as FeedActive } from 'src/assets/icons/media-types/feed2.svg'
import { ReactComponent as SkuUplifting } from 'src/assets/icons/media-types/sku-uplifting.svg'
import { ReactComponent as SkuUpliftingActive } from 'src/assets/icons/media-types/sku-uplifting2.svg'
import { ReactComponent as StorePosition } from 'src/assets/icons/media-types/store-positioning.svg'
import { ReactComponent as StorePositionActive } from 'src/assets/icons/media-types/store-positioning2.svg'

import { ReactComponent as Infants } from 'src/assets/icons/age-groups/infant.svg'
import { ReactComponent as Children } from 'src/assets/icons/age-groups/early-youth.svg'
import { ReactComponent as Teens } from 'src/assets/icons/age-groups/young.svg'
import { ReactComponent as Young } from 'src/assets/icons/age-groups/young.svg'
import { ReactComponent as Middle } from 'src/assets/icons/age-groups/middle.svg'
import { ReactComponent as Elderly } from 'src/assets/icons/age-groups/elderly.svg'
import { ReactComponent as Senior } from 'src/assets/icons/age-groups/senior.svg'
// import { ReactComponent as EarlyYouth } from 'src/assets/icons/age-groups/teens.svg'

import { ReactComponent as BabyCare } from 'src/assets/icons/product-types/baby-care.svg'
import { ReactComponent as BabyCareActive } from 'src/assets/icons/product-types/baby-care2.svg'
import { ReactComponent as Beverage } from 'src/assets/icons/product-types/beverage.svg'
import { ReactComponent as BeverageActive } from 'src/assets/icons/product-types/beverage2.svg'
import { ReactComponent as BodyWash } from 'src/assets/icons/product-types/body-wash.svg'
import { ReactComponent as BodyWashActive } from 'src/assets/icons/product-types/body-wash2.svg'
import { ReactComponent as Cooking } from 'src/assets/icons/product-types/cooking.svg'
import { ReactComponent as CookingActive } from 'src/assets/icons/product-types/cooking2.svg'
import { ReactComponent as FabricCare } from 'src/assets/icons/product-types/fabric-care.svg'
import { ReactComponent as FabricCareActive } from 'src/assets/icons/product-types/fabric-care2.svg'
import { ReactComponent as FemCare } from 'src/assets/icons/product-types/fem-care.svg'
import { ReactComponent as FemCareActive } from 'src/assets/icons/product-types/fem-care2.svg'
import { ReactComponent as HairCare } from 'src/assets/icons/product-types/hair-care.svg'
import { ReactComponent as HairCareActive } from 'src/assets/icons/product-types/hair-care2.svg'
import { ReactComponent as OralCare } from 'src/assets/icons/product-types/oral-care.svg'
import { ReactComponent as OralCareActive } from 'src/assets/icons/product-types/oral-care2.svg'
import { ReactComponent as PetRelated } from 'src/assets/icons/product-types/pet-related.svg'
import { ReactComponent as PetRelatedActive } from 'src/assets/icons/product-types/pet-related2.svg'
import { ReactComponent as Services } from 'src/assets/icons/product-types/services.svg'
import { ReactComponent as ServicesActive } from 'src/assets/icons/product-types/services2.svg'
import { ReactComponent as SkinCare } from 'src/assets/icons/product-types/skin-care.svg'
import { ReactComponent as SkinCareActive } from 'src/assets/icons/product-types/skin-care2.svg'
import { ReactComponent as SnackFood } from 'src/assets/icons/product-types/snack-food.svg'
import { ReactComponent as SnackFoodActive } from 'src/assets/icons/product-types/snack-food2.svg'
import { ReactComponent as Medicines } from 'src/assets/icons/product-types/medicines.svg'
import { ReactComponent as MedicinesActive } from 'src/assets/icons/product-types/medicines2.svg'
import { ReactComponent as Other } from 'src/assets/icons/product-types/other.svg'
import { ReactComponent as OtherActive } from 'src/assets/icons/product-types/other2.svg'
import { useTranslation } from 'react-i18next'

const MEDIA_TYPES_IMAGE: { [key: string]: ReactElement } = {
  'Brand Store (Brand bundle)': <BrandStore />,
  'Brand Store (Brand bundle)_active': <BrandStoreActive />,
  'Branded Printing': <BrandedPrinting />,
  'Branded Printing_active': <BrandedPrintingActive />,
  'Cashback Offers': <CashbackOffers />,
  'Cashback Offers_active': <CashbackOffersActive />,
  'Email Blast': <EmailBlast />,
  'Email Blast_active': <EmailBlastActive />,
  'Free Delivery': <FreeDelivery />,
  'Free Delivery_active': <FreeDeliveryActive />,
  'Home Page Banners': <HomePageBanners />,
  'Home Page Banners_active': <HomePageBannersActive />,
  'In App Banners': <InAppBanners />,
  'In App Banners_active': <InAppBannersActive />,
  'Online survey or campaign': <OnlineSurvey />,
  'Online survey or campaign_active': <OnlineSurveyActive />,
  'OOH - Media Partnership': <OohMediaPartnership />,
  'OOH - Media Partnership_active': <OohMediaPartnershipActive />,
  'Paid SM Campaigns': <PaidSmCampaign />,
  'Paid SM Campaigns_active': <PaidSmCampaignActive />,
  'Popup Window': <PopupWindow />,
  'Popup Window_active': <PopupWindowActive />,
  'Price Off Promotion': <PriceOffPromo />,
  'Price Off Promotion_active': <PriceOffPromoActive />,
  'Push Notification': <PushNotifications />,
  'Push Notification_active': <PushNotificationsActive />,
  Feed: <Feed />,
  'Feed Feed_active': <FeedActive />,
  'SKU Uplifting': <SkuUplifting />,
  'SKU Uplifting_active': <SkuUpliftingActive />,
  'Store Positioning': <StorePosition />,
  'Store Positioning_active': <StorePositionActive />,

  Infants: <Infants />,
  Infants_active: <Infants />,
  '18-24 Early Youth': <Children />,
  '18-24 Early Youth_active': <Children />,
  Teens: <Teens />,
  Teens_active: <Teens />,
  '25-35 Young': <Young />,
  '25-35 Young_active': <Young />,
  '36-45 Middle': <Middle />,
  '36-45 Middle_active': <Middle />,
  '55+ Elderly': <Elderly />,
  '55+ Elderly_active': <Elderly />,
  '46-55 Senior': <Senior />,
  '46-55 Senior_active': <Senior />,

  'Infants Birth - 5yrs': <React.Fragment />,
  'Children 6yrs - 12yrs': <React.Fragment />,
  'Teens 13yrs - 19yrs': <React.Fragment />,
  'Young 20yrs - 35yrs': <React.Fragment />,
  'Middle 36yrs - 55yrs': <React.Fragment />,
  'Elderly over 56yrs': <React.Fragment />,
  'Infants Birth - 5yrs_active': <React.Fragment />,
  'Children 6yrs - 12yrs_active': <React.Fragment />,
  'Teens 13yrs - 19yrs_active': <React.Fragment />,
  'Young 20yrs - 35yrs_active': <React.Fragment />,
  'Middle 36yrs - 55yrs_active': <React.Fragment />,
  'Elderly over 56yrs_active': <React.Fragment />,

  'Baby Care': <BabyCare />,
  'Baby Care_active': <BabyCareActive />,
  Beverages: <Beverage />,
  Beverages_active: <BeverageActive />,
  'Body Wash': <BodyWash />,
  'Body Wash_active': <BodyWashActive />,
  Cooking: <Cooking />,
  Cooking_active: <CookingActive />,
  'Fabric Care': <FabricCare />,
  'Fabric Care_active': <FabricCareActive />,
  'Fem Care': <FemCare />,
  'Fem Care_active': <FemCareActive />,
  'Hair Care': <HairCare />,
  'Hair Care_active': <HairCareActive />,
  'Oral Care': <OralCare />,
  'Oral Care_active': <OralCareActive />,
  'Pet Food & Care': <PetRelated />,
  'Pet Food & Care_active': <PetRelatedActive />,
  Services: <Services />,
  Services_active: <ServicesActive />,
  'Skin Care': <SkinCare />,
  'Skin Care_active': <SkinCareActive />,
  'Snack Food': <SnackFood />,
  'Snack Food_active': <SnackFoodActive />,
  'Vitamins & Drugs': <Medicines />,
  'Vitamins & Drugs_active': <MedicinesActive />,
  Other: <Other />,
  Other_active: <OtherActive />,
}

interface ICardProps {
  checked: boolean
  name: string
  onChange: (e: MouseEvent, name: string) => void
}

const subLabel: { [k: string]: string } = {
  Infants: 'Birth - 5yrs',
  Children: '6yrs - 12yrs',
  Teens: '13yrs - 19yrs',
  Early_Youth: '18-24',
  Young: '20yrs - 35yrs',
  Middle: '36yrs - 55yrs',
  Elderly: 'over 56yrs',
}

export function MediaTypeCard({ checked, name, onChange }: ICardProps): ReactElement {
  const imageName = checked ? name + '_active' : name
  const {t} = useTranslation(['translationCommon'])

  return (
    <Col xs={8} key={name}>
      <QSelectItem
        className={'media-card mb-16'}
        label={t(`brief.product-type-options.${name}`,name)}
        sublabel={subLabel[name]}
        checked={checked}
        cardImage={MEDIA_TYPES_IMAGE[imageName]}
        onChange={(e: MouseEvent) => onChange(e, name)}
      />
    </Col>
  )
}

interface IIconProps {
  name: string
}
export function MediaTypeIcon({ name }: IIconProps): ReactElement {
  const imageName = name + '_active'

  return (
    <div className="BSUnit__bList-item">
      <div className="BSUnit__bList-img">{MEDIA_TYPES_IMAGE[imageName]}</div>
      <div className="BSUnit__bList-ttl">{name}</div>
    </div>
  )
}
