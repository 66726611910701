/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import {
  Menu,
  QButton,
  QInput,
  QMenuItem,
  QPagination,
  QSelect,
  QTable,
  Row,
  IconsCommon,
  BillCardItem,
  Dropdown,
} from 'quantum_components'
import { useSelector } from 'react-redux'
import { Col } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import useDebounce from 'src/hooks/useDebounce'
import { getFileNameFromUrl } from 'src/utils'
import { useReduxDispatch } from 'src/helpers'
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_PAYMENT_STATUS_LIST,
  BRIEF_PAYMENT_STATUS_NAMES,
  briefPaymentStatuses,
} from 'src/constants/brief'
import { DEFAULT_BILL_TABLE_PARAMS } from 'src/constants/bill'
import * as coreActions from 'src/modules/core/core.actions'

import * as actions from '../bills.actions'
import { getBillList, getBillListLoading, getBillParams, getPages, getTotalBillList } from '../bills.selectors'
import { IBillListElement, ISelectInputOption } from '../bills.types'

import { ReactComponent as EyeIcon } from 'src/assets/icons/eye-icon.svg'

import UploadComponent from 'src/components/UploadComponent'

const BillsList = () => {
  const { t } = useTranslation(['translationUser', 'translation', 'translationCommon'])
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const billList = useSelector(getBillList).map(bill=>{
    bill.brief.name = bill.brief.campaignName;
    return bill;
  })
  const totalBillList = useSelector(getTotalBillList)
  const billListLoading = useSelector(getBillListLoading)
  const params = useSelector(getBillParams)
  const pages = useSelector(getPages)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const briefPaymentTranslatedStatuses = {
    [BRIEF_PAYMENT_STATUS.PENDING]: {
      classStyle: 'pending',
      label: t('brief.payment-statuses-array.PENDING', { ns: 'translation' }),
    },
    [BRIEF_PAYMENT_STATUS.PROCESSING]: {
      classStyle: 'pending',
      label: t('brief.payment-statuses-array.PROCESSING', { ns: 'translation' }),
    },
    [BRIEF_PAYMENT_STATUS.PARTIAL_REFUND]: {
      classStyle: 'running',
      label: t('brief.payment-statuses-array.PARTIAL_REFUND', { ns: 'translation' }),
    },
    [BRIEF_PAYMENT_STATUS.REFUND]: {
      classStyle: 'rejected',
      label: t('brief.payment-statuses-array.REFUND', { ns: 'translation' }),
    },
    [BRIEF_PAYMENT_STATUS.PAID]: {
      classStyle: 'approved',
      label: t('brief.payment-statuses-array.PAID', { ns: 'translation' }),
    },
    [BRIEF_PAYMENT_STATUS.DECLINED]: {
      classStyle: 'rejected',
      label: t('brief.payment-statuses-array.DECLINED', { ns: 'translation' }),
    },
    [BRIEF_PAYMENT_STATUS.REJECTED]: {
      classStyle: 'rejected',
      label: t('brief.payment-statuses-array.REJECTED', { ns: 'translation' }),
    },
    [BRIEF_PAYMENT_STATUS.PO_ISSUED]: {
      classStyle: 'approved',
      label: t('brief.payment-statuses-array.PO_ISSUED', { ns: 'translation' }),
    },
    [BRIEF_PAYMENT_STATUS.PAYOUT_COMPLETE]: {
      classStyle: 'approved',
      label: t('brief.payment-statuses-array.PAYOUT_COMPLETE', { ns: 'translation' }),
    },
  }

  const tableLoading = {
    spinning: billListLoading,
    indicator: <div className="loader loader-small" />,
  }

  useEffect(() => {
    dispatch(actions.setParamsAndFetch({ ...params, search: debouncedSearch, offset: 0, limit: 10 }))
    dispatch(actions.setCurrentPage(1))

    return () => dispatch(actions.setBillListParams(DEFAULT_BILL_TABLE_PARAMS))
  }, [debouncedSearch])

  const columns = [
    {
      title: t('brief.table-columns.Product', { ns: 'translationCommon' }),
      dataIndex: 'brief',
      key: 'product',
      width: '25%',
      fixed: 'left',
      sorter: true,
      render: (brief, { payment }) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">{brief.image && <img src={brief.image} alt={brief.campaignName ?? brief.name} />}</div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">
                {(brief.campaignName ?? brief.name) || t('common.unnamed_brief', { ns: 'translationCommon' })}
              </h3>
              <div className="q-table-product_created">
                {t('common.created', { ns: 'translationCommon' })} {payment.createdAt}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: t('user.billing.quotation'),
      dataIndex: 'payment',
      key: 'quotation',
      width: '17%',
      render: ({ quotation }) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              viewOnlyText={t('common.View', { ns: 'translationCommon' })}
              alternativeFileView
              dragAndDrop={false}
              files={quotation ? [{ url: quotation, name: getFileNameFromUrl(quotation) }] : []}
              onView={async file => {
                window.open(file.url, '_blank')
                URL.revokeObjectURL(file.url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('user.billing.invoice'),
      dataIndex: 'payment',
      key: 'invoice',
      width: '17%',
      render: ({ invoice }) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              viewOnlyText={t('common.View', { ns: 'translationCommon' })}
              alternativeFileView
              dragAndDrop={false}
              files={invoice?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('user.billing.po'),
      dataIndex: '',
      key: 'po',
      width: '18%',
      render: (bill: IBillListElement) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              alternativeFileView
              viewOnlyText={t('common.View', { ns: 'translationCommon' })}
              dragAndDrop={false}
              files={bill.payment.po.files}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('user.billing.bank_transfer'),
      dataIndex: '',
      key: 'bankTransfer',
      width: '19%',
      render: (bill: IBillListElement) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              alternativeFileView
              viewOnlyText={t('common.View', { ns: 'translationCommon' })}
              dragAndDrop={false}
              files={bill.payment.bankTransfer.files}
              disabled={!bill.payment.bankTransfer.files.length}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: t('user.billing.pay'),
      dataIndex: 'payment',
      key: 'pay',
      width: '19%',
      render: ({ briefId }, bill) => {
        const isBillPaid = ![BRIEF_PAYMENT_STATUS.PENDING,BRIEF_PAYMENT_STATUS.REJECTED].includes(bill.payment.status)
        return isBillPaid ? (
          <span>-</span>
        ) : (
          <QButton
            className="w-100"
            type="primary"
            size="small"
            onClick={(event: MouseEvent) => {
              event.stopPropagation()
              handleView(briefId,bill.version)
            }}
          >
            {t('user.billing.pay')}
          </QButton>
        )
      },
    },
    {
      title: t('user.billing.payment_status'),
      dataIndex: 'payment',
      key: 'paymentStatus',
      width: '19%',
      fixed: 'right',
      render: ({ status, briefId},currentRow) => {
        const paymentStatusClass = briefPaymentStatuses[status]?.classStyle ?? ''
        const paymentStatusLabel = briefPaymentStatuses[status]?.label ?? ''
        const version = currentRow?.version
        return (
          <div>
            {status ? (
              <span className={`status-label ${paymentStatusClass}`}>
                {t(paymentStatusLabel, { ns: 'translation' })}
              </span>
            ) : (
              <span>-</span>
            )}

            <div onClick={e => e.stopPropagation()}>
              <Dropdown overlay={menu(briefId,version)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  const menu = (id: number, version:string) => (
    <Menu className="user-menu-list">
      <QMenuItem key={0} onClick={() => handleView(id,version)} name={t('common.View', { ns: 'translationCommon' })} icon={<EyeIcon />} />
    </Menu>
  )

  const handleChangeSearch = e => {
    setSearch(e.currentTarget.value)
  }

  const handleChangeSelect = (activePaymentStatuses: string[]) => {
    dispatch(actions.setParamsAndFetch({ ...params, offset: 0, paymentStatus: activePaymentStatuses }))
  }

  const handleChangePage = (page, pageSize) => {
    dispatch(actions.setCurrentPage(page))
    dispatch(actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const colKey = {
      product: 'name',
    }
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    }

    dispatch(
      actions.setParamsAndFetch({
        ...params,
        ...sortingParams,
      })
    )
  }

  const handleView = (id,version?) => {
    if(version == "2"){
      history.push(`/briefs/v3/edit/${id}`)  
    }else{
      history.push(`/briefs/view/${id}`)  
    }
  }

  const handleOnRowClick = (bill: IBillListElement) => ({
    onClick: () => handleView(bill.payment.briefId,bill.version),
  })

  const statusOptions: ISelectInputOption[] = BRIEF_PAYMENT_STATUS_LIST.map((opt: BRIEF_PAYMENT_STATUS) => {
    return { label: t(BRIEF_PAYMENT_STATUS_NAMES[opt], { ns: 'translation' }), value: opt }
  })

  return (
    <div className="qu-brief-view">
      <div className="qu-brief-list pb-20">
        <Row
          className={`${isMobile ? 'pt-30 pl-15 pr-15' : 'pt-30 mb-15'} brief-list-header`}
          align="middle"
          justify="space-between"
        >
          <Col>
            <h3>{t('user.billing.my_bills')}</h3>
          </Col>
        </Row>
        <Row
          className={`${isMobile && 'pb-15'} ${isMobile && 'pt-10'}`}
          justify={`${isMobile ? 'center' : 'space-between'}`}
        >
          <Col className={`qt-search ${isMobile && 'pb-5'}`}>
            <QInput
              className={`${isMobile && 'full-width'}`}
              value={search}
              onChange={handleChangeSearch}
              placeholder={t('common.Search', { ns: 'translationCommon' })}
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col className="qt-filter">
            <QSelect
              className={`${isMobile && 'full-width'}`}
              showArrow
              maxTagTextLength={8}
              maxTagCount={2}
              value={params.status}
              onChange={handleChangeSelect}
              mode="multiple"
              size="large"
              placeholder={t('common.Filter_by_status', { ns: 'translationCommon' })}
              options={statusOptions}
            />
          </Col>
        </Row>
        {isMobile ? (
          <div className="pl-15 pr-15">
            <ul className="qu-request-list">
              {billList.map(bill => {
                return (
                  <BillCardItem
                    key={bill.key}
                    bill={bill}
                    onCardClick={() => handleView(bill.payment.briefId,bill.version)}
                    onPayClick={() => handleView(bill.payment.briefId,bill.version)}
                    paymentStatus={briefPaymentTranslatedStatuses}
                    quotationNode={
                      <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                        <UploadComponent
                          viewOnly
                          viewOnlyText={t('common.View', { ns: 'translationCommon' })}
                          alternativeFileView
                          dragAndDrop={false}
                          files={
                            bill.payment.quotation
                              ? [{ url: bill.payment.quotation, name: getFileNameFromUrl(bill.payment.quotation) }]
                              : []
                          }
                          onView={async file => {
                            window.open(file.url, "_blank")
                            URL.revokeObjectURL(file.url)
                          }}
                        />
                      </div>
                    }
                    bankTransferNode={
                      <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                        <UploadComponent
                          viewOnly
                          viewText={t('common.View', { ns: 'translationCommon' })}
                          removeText={t('common.Remove', { ns: 'translationCommon' })}
                          dragAndDrop={false}
                          files={bill.payment.bankTransfer.files}
                          onView={async file => {
                            const response: any = await dispatch(coreActions.downloadImage(file.url))
                            const url = URL.createObjectURL(response.data)

                            window.open(url, '_blank')
                            URL.revokeObjectURL(url)
                          }}
                        />
                      </div>
                    }
                    poNode={
                      <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                        <UploadComponent
                          viewOnly
                          viewText={t('common.View', { ns: 'translationCommon' })}
                          removeText={t('common.Remove', { ns: 'translationCommon' })}
                          dragAndDrop={false}
                          files={bill.payment.po.files}
                          onView={async file => {
                            const response: any = await dispatch(coreActions.downloadImage(file.url))
                            const url = URL.createObjectURL(response.data)

                            window.open(url, '_blank')
                            URL.revokeObjectURL(url)
                          }}
                        />
                      </div>
                    }
                    invoiceNode={
                      <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                        <UploadComponent
                          viewOnly
                          viewOnlyText={t('common.View', { ns: 'translationCommon' })}
                          alternativeFileView
                          dragAndDrop={false}
                          files={bill.payment.invoice?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
                          onView={async file => {
                            const response: any = await dispatch(coreActions.downloadImage(file.url))
                            const url = URL.createObjectURL(response.data)

                            window.open(url, '_blank')
                            URL.revokeObjectURL(url)
                          }}
                        />
                      </div>
                    }
                    quotationText={t('user.billing.quotation')}
                    invoiceText={t('user.billing.invoice')}
                    poText={t('user.billing.po')}
                    bankTransferText={t('user.billing.bank_transfer')}
                    payButtonText={t('user.billing.pay')}
                    payButtonShow={[BRIEF_PAYMENT_STATUS.PENDING,BRIEF_PAYMENT_STATUS.REJECTED,BRIEF_PAYMENT_STATUS.PO_ISSUED].includes(bill.payment.status)}
                  />
                )
              })}
            </ul>
          </div>
        ) : (
          <div className="pt-15 pb-30">
            <QTable
              //@ts-ignore
              onRow={handleOnRowClick}
              onChange={handleTableChange}
              sortDirections={['ascend', 'descend', 'ascend']}
              loading={tableLoading}
              columns={columns}
              dataSource={billList}
              scroll={{ x: 990 }}
              rowClassName="q-table cursorPointer"
            />
          </div>
        )}

        <Row justify={`${isMobile ? 'center' : 'end'}`} className={`${isMobile && 'pt-10 mb-10'}`}>
          <QPagination
            size="default"
            defaultCurrent={1}
            current={pages.currentPage}
            total={totalBillList}
            onChange={handleChangePage}
          />
        </Row>
      </div>
    </div>
  )
}

export default BillsList