import { createSelector } from 'reselect'
import { IState, IMarketplacePartnersParams, IMarketplacePartner } from './marketplace.types'
import { MODULE_NAME } from './marketplace.constants'
import { IBriefPartnerDetails, IPartnerProps } from '../../brief/brief.types'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getMarketplaceParams = createSelector(
  selectState,
  (state: IState): IMarketplacePartnersParams => state.params
)

export const getMarketplacePartnersLoading = createSelector(
  selectState,
  (state: IState): boolean => state.fetchMarketplacePartnersLoading
)

export const getMarketplacePartners = createSelector(
  selectState,
  (state: IState): IMarketplacePartner[] => state.partners
)

export const getActiveSinglePartnerId = createSelector(
  selectState,
  (state: IState): number | null => state.activeSinglePartnerId
)

export const getPartnerDetails = createSelector(
  selectState,
  (state: IState): IBriefPartnerDetails | null => state.partnerDetails
)

export const getPartnerDetailsLoading = createSelector(
  selectState,
  (state: IState): boolean => state.fetchPartnerDetailsLoading
)

export const getTotalPartners = createSelector(selectState, (state: IState): number => state.totalPartners)

export const getPagesForMarketplace = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)

export const getPartnerLocked = createSelector(selectState, (state: IState): boolean => state.partnerLocked)

export const getPartnerPropertiesDetails = createSelector(selectState, (state: IState): IPartnerProps | null => {
  return {
    dynamicSamplePriceTypes: state.partnerDetails?.partnerProperties.dynamicSamplePriceTypes
      ? state.partnerDetails?.partnerProperties.dynamicSamplePriceTypes
      : [],
    dynamicSamplePrices: state.partnerDetails?.partnerProperties?.dynamicSamplePrices,
    hasDynamicSamplePricing: state.partnerDetails?.partnerProperties?.hasDynamicSamplePricing,
  }
})
