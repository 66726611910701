export const MODULE_NAME = 'dashboard'
/* actions */

export const STATS_CARDS_REQUEST = `${MODULE_NAME}/STATS_CARDS_REQUEST`
export const STATS_CARDS_SUCCESS = `${MODULE_NAME}/STATS_CARDS_SUCCESS`
export const STATS_CARDS_FAILURE = `${MODULE_NAME}/STATS_CARDS_FAILURE`

export const CHART_REQUEST = `${MODULE_NAME}/CHART_REQUEST`
export const CHART_SUCCESS = `${MODULE_NAME}/CHART_SUCCESS`
export const CHART_FAILURE = `${MODULE_NAME}/CHART_FAILURE`

export const SET_CHART_PARAMS = `${MODULE_NAME}/SET_CHART_PARAMS`

export const FETCH_OPEN_REQUESTS_REQUEST = `${MODULE_NAME}/FETCH_OPEN_REQUESTS_REQUEST`
export const FETCH_OPEN_REQUESTS_SUCCESS = `${MODULE_NAME}/FETCH_OPEN_REQUESTS_SUCCESS`
export const FETCH_OPEN_REQUESTS_FAILURE = `${MODULE_NAME}/FETCH_OPEN_REQUESTS_FAILURE`

export const FETCH_LIVE_CAMPAIGNS_REQUEST = `${MODULE_NAME}/FETCH_LIVE_CAMPAIGNS_REQUEST`
export const FETCH_LIVE_CAMPAIGNS_SUCCESS = `${MODULE_NAME}/FETCH_LIVE_CAMPAIGNS_SUCCESS`
export const FETCH_LIVE_CAMPAIGNS_FAILURE = `${MODULE_NAME}/FETCH_LIVE_CAMPAIGNS_FAILURE`
