import {
  Card,
  Col,
  Empty,
  QMetricsProgress,
  QPieChart,
  QPieChartSimple,
  Row,
} from "quantum_components";
import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import { calculateRateDateRange } from "src/helpers/campaign";

export default function BudgetUsageV3({ campaignDetails, chartData }: any) {
  const { t } = useTranslation(["translationV2"]);

  const calculateConsumedQuantity = (
    serviceName: string,
    metricType: string,
    totalQuantity: number
  ) => {
    if (chartData?.mediaType !== serviceName) return 0;

    const chartMetricType = metricType === "CPM" ? "impressions" : "clicks";

    if (!chartData["metrics"]) return 0;

    const timeline = chartData["metrics"][chartMetricType];

    const lastElement: any = Object.keys(timeline).pop();
    return lastElement ? timeline[lastElement] : 0;

    // let calculatedTimeline: any = [];

    // for (const [key, value] of Object.entries(timeline)) {
    //   const formattedKey = moment(key).format("MM-DD-YYYY");

    //   const existing = calculatedTimeline.find(
    //     (o: any) => o.date === formattedKey
    //   );
    //   if (existing) {
    //     existing.total = value;
    //   } else {
    //     calculatedTimeline.push({ date: formattedKey, total: value });
    //   }
    // }

    // const consumed =
    //   calculatedTimeline.length > 0
    //     ? calculatedTimeline.reduce(
    //         (acc: number, cur: any) => (acc += cur.total),
    //         0
    //       )
    //     : 0;
    // return consumed;
  };

  const getBudgetCompletionDetails = () => {
    if (lodash.isEmpty(campaignDetails) || lodash.isEmpty(chartData)) return [];

    // check if this campaign is a metric based
    const metricBasedServices =
      campaignDetails?.cart?.additional?.services?.filter((service: any) => {
        if (service?.metricData?.totalQuantity > 0) return true;
        return false;
      });

    const utilisation = metricBasedServices?.filter((c:any)=>chartData.mediaType === c.name).map((service: any) => {
      const consumed = calculateConsumedQuantity(
        service.name,
        service?.metricData?.metricType,
        service?.metricData?.totalQuantity
      );
      const { totalQuantity, campaignBudget, metricType } = service?.metricData;
      const consumedPercentage = Number(
        Number(consumed / totalQuantity) * 100
      ).toFixed(1);
      const consumedAmount = Number(
        campaignBudget * (consumed / totalQuantity)
      ).toFixed(1);
      return {
        name: service.name,
        metricType,
        campaignBudget,
        totalQuantity,
        consumedQuantity: consumed,
        label: t("budget_consumption_helper", {
          consumedAmount,
          consumedPercentage,
          campaignBudget: Number(campaignBudget).toLocaleString(),
        }),
      };
    });

    return utilisation;
  };

  let services = getBudgetCompletionDetails();

  return (
    <>
      {services.map((r: any, index: number) => {
        return (
          <Card className="qu-chart-card qu-metrics-card mb-20">
            <div className="card-header">
              <h3>{t("Budget Details")}</h3>
            </div>
            <div className="grid-row grid-row--jcc">
              <div className="grid-col">
                <div className="mt-20 mb-20 p-20 text-italic" key={index}>
                  <QMetricsProgress
                    value={calculateRateDateRange(
                      Number(
                        r?.campaignBudget *
                          (r?.consumedQuantity / r?.totalQuantity)
                      ),
                      Number(r?.campaignBudget)
                    )}
                    title={t(r?.label)}
                  />
                </div>
              </div>
            </div>
          </Card>
        );
      })}

      {/* {services.length > 0 && <Row gutter={24} align={"middle"} justify={"center"}>
        <Col md={24} className={"p-20"} style={{background:"white",border:"1px dashed #333333"}} >
          
          <h5 className="text-center">{t("Budget Details")}</h5>
          {services.map((r: any, index: number) => {
            return (
              <div className="mb-20 text-italic" key={index}>
                <QMetricsProgress
                  className="mv-15"
                  value={calculateRateDateRange(
                    Number(
                      r?.campaignBudget *
                        (r?.consumedQuantity / r?.totalQuantity)
                    ),
                    Number(r?.campaignBudget)
                  )}
                  title={t(r?.label)}
                />
              </div>
            );
          })}
        </Col>
      </Row>} */}
    </>
  );
}
