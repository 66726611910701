import { IState } from "./announcement.types";
import * as CONSTANTS from "./announcement.constants";

const initialState: IState = {
  loading: false,
  announcements: [],
  params: {
    offset: 0,
    limit: 10,
  },
  totalPromotions: 0,
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  announcementLoading: false,
  announcementDetails: null,
};

export default (state: IState = initialState, action: any): IState => {
  switch (action.type) {
    case CONSTANTS.FETCH_ANNOUNCEMENTS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.FETCH_ANNOUNCEMENTS_LIST_SUCCESS:
      const totalPages = Math.ceil(action.payload.total / state.params.limit)
      return {
        ...state,
        announcements: action.payload.data,
        totalPromotions: action.payload.total,
        pages: { ...state.pages, totalPages },
        loading: false,
      };
    case CONSTANTS.FETCH_ANNOUNCEMENTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.FETCH_ANNOUNCEMENT_BY_ID_REQUEST:
      return {
        ...state,
        announcementLoading: true,
      };
    case CONSTANTS.FETCH_ANNOUNCEMENT_BY_ID_SUCCESS:
      return {
        ...state,
        announcementDetails: action.payload,
        announcementLoading: false,
      };
    case CONSTANTS.FETCH_ANNOUNCEMENT_BY_ID_FAILURE:
      return {
        ...state,
        announcementLoading: false,
      };
    case CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, pages: { ...state.pages, currentPage: action.payload.page } }
    default:
      return state;
  }
};
