import { Col, Divider, Row, Tag } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash from "lodash";
import React, { useEffect, useState, createRef, useRef } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import {
  Modal,
  QButton,
  QInput,
  qnotification,
  QSelect,
} from "quantum_components";
import { useTranslation } from "react-i18next";

const V3NativeTemplatesComponent = ({
  briefSettings,
  state,
  setState,
  arrayIndex,
  disabled,
}) => {
  const { t } = useTranslation(["translationV2", "translation"]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [variableValues, setVariableValues] = useState({});
  const [localActiveTemplate, setLocalActiveTemplate] = useState<any>({});
  const [sanitizedHtml, setSanitizedHtml] = useState({ __html: "" });

  useEffect(() => {
    if (lodash.get(briefSettings, "partnerProfileId") <= 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [briefSettings]);

  

  // useEffect(()=>{
  //   if(lodash.isEmpty(state)) return;
  //   if(lodash.has(state[arrayIndex],'templateId') && lodash.has(state[arrayIndex],'templateVariables')){
  //     let ant = briefSettings?.nativeadTemplates?.find((o) => o.id === lodash.get(state[arrayIndex],'templateId'));
  //     ant = {...ant,values:lodash.get(state[arrayIndex],'templateVariables')};

  //     setVariableValues(ant.values);
  //     setLocalActiveTemplate(ant)
  //     handleSync()
  //   }else{
  //     setVariableValues({})
  //   }
  // },[state,briefSettings])

  useEffect(() => {
    if (lodash.isEmpty(state)) return;
    handleSync();

    if (lodash.get(briefSettings, "partnerProfileId") <= 0) return;

    let existingTemplate = lodash.get(
      state[arrayIndex],
      "activeNativeTemplate",
      {}
    );
    if (lodash.isEmpty(existingTemplate)) {
      // check if there is a mapped template
      const mappedTemplateId = lodash.get(
        state[arrayIndex],
        "mappedTemplate.id",
        -1
      );
      if (
        mappedTemplateId > 0 &&
        briefSettings?.partnerProfileId > 0 &&
        briefSettings?.nativeadTemplates?.find(
          (o: any) => o.id === mappedTemplateId
        )
      ) {
        // select this template
        handleTemplateChange(mappedTemplateId);
      }
    }
  }, [, state, briefSettings]);

  const handleTemplateChange = (selectedTemplateId: any) => {
    let s = [...state];

    s[arrayIndex].activeNativeTemplate = {
      ...briefSettings?.nativeadTemplates?.find(
        (o: any) => o.id === selectedTemplateId
      ),
    };


    s[arrayIndex].activeNativeTemplate["values"] = {};
    setState(s);
  };

  // const handleTemplateChange = (selectedTemplateId) => {
  //   setLocalActiveTemplate(briefSettings?.nativeadTemplates?.find((o) => o.id === selectedTemplateId))
  //   setVariableValues({})
  //   // let ss = state.map((s,index)=>{
  //   //   if(index===arrayIndex){
  //   //     s.activeNativeTemplate = briefSettings?.nativeadTemplates?.find((o) => o.id === selectedTemplateId)
  //   //   }
  //   //   return s;
  //   // })
  //   // setState(ss);
  //   // setState(
  //   //   briefSettings?.nativeadTemplates?.find((o) => o.id === selectedTemplateId)
  //   // );
  // };

  // const handleVariableChange = (placeholder, text) => {
  //   let vv = { ...variableValues, [placeholder]: text };
  //   setVariableValues(vv);
  //   setLocalActiveTemplate({...localActiveTemplate,values:vv})
  //   // let ss = state.map((s,index)=>{
  //   //   if(index === arrayIndex){
  //   //     s.activeNativeTemplate = {...s.activeNativeTemplate,values:vv}
  //   //   }
  //   //   return s;
  //   // })
  //   // setState(ss);
  //   // setState({ ...state, values: vv });
  // };

  const handleVariableChange = (placeholder: any, text: any) => {
    let ev = { ...state[arrayIndex].activeNativeTemplate?.values };
    ev = { ...ev, [placeholder]: text };

    let ant = { ...state[arrayIndex].activeNativeTemplate };
    ant.values = ev;

    let s = [...state];
    s[arrayIndex].activeNativeTemplate = ant;
    setState(s);
  };

  // const getVariableValue = (placeholder) => {
  //   return lodash.get(variableValues,placeholder)
  //   // variableValues[placeholder];
  // };

  const getVariableValue = (placeholder: any) => {
    return lodash.get(
      state[arrayIndex].activeNativeTemplate.values,
      placeholder
    );
  };

  // const handleSync = () => {
  //   // if(!lodash.has(state[arrayIndex].activeNativeTemplate,'rawHtml') || !lodash.has(state[arrayIndex].activeNativeTemplate,'values')) return;
  //   if(!lodash.has(localActiveTemplate,'rawHtml') || !lodash.has(localActiveTemplate,'values')) return;
  //   let { rawHtml, values } = localActiveTemplate;
  //   Object.keys(values).forEach((key) => {
  //     rawHtml = rawHtml.replaceAll(key, values[key]);
  //   });
  //   setSanitizedHtml({__html:rawHtml})

  //   // if(htmlRef.current){
  //   //   htmlRef.current.innerHtml = rawHtml;
  //   // }

  //   // console.log(htmlRef.current)

  //   // document.getElementById(`html_output_${arrayIndex}`).innerHTML = rawHtml;
  // };

  const shouldShowHTML = () => {
    if (
      !lodash.has(state[arrayIndex].activeNativeTemplate, "rawHtml") ||
      !lodash.has(state[arrayIndex].activeNativeTemplate, "values")
    )
      return false;

    // check all the variables if present then show otherwise dont show
    const vars = lodash.get(
      state[arrayIndex],
      "activeNativeTemplate.variables",
      []
    );
    if (
      lodash.isEmpty(
        lodash.get(state[arrayIndex], "activeNativeTemplate.values", {})
      )
    )
      return false;

    const templateVariables = lodash
      .values(lodash.get(state[arrayIndex], "activeNativeTemplate.values", {}))
      .filter((a) => a);
    if (vars.length != templateVariables.length) return false;

    return true;
  };
  shouldShowHTML();

  const handleSync = () => {
    if (!shouldShowHTML()) return;

    let { rawHtml, values, rawJS } = state[arrayIndex].activeNativeTemplate;
    Object.keys(values).forEach((key) => {
      rawHtml = rawHtml.replaceAll(key, values[key]);
    });
    setSanitizedHtml({ __html: rawHtml });

    const htmlOuputDiv = document.getElementById(`html_output_${arrayIndex}`);
    if(htmlOuputDiv){
      htmlOuputDiv.style.background = "transparent";
    }

    if(!lodash.isEmpty(rawJS)){
      addRawJS(rawJS)
    }
  };

  const addRawJS = (rawJS:any) => {
    const script = document.createElement('script');
    script.id = `script_output_${arrayIndex}`;
    script.innerHTML = rawJS;
    script.defer = true;

    const htmlOuputDiv = document.getElementById(`html_output_${arrayIndex}`);
    if(htmlOuputDiv){
      setTimeout(() => {
        try {
          const loadedScript = document.getElementById(`script_output_${arrayIndex}`);
          if(!loadedScript){
            htmlOuputDiv.appendChild(script);
          }
        } catch (error) {}
        
      }, 2000);
    }
  }

  return (
    <>
      {isLoading && <SpinnerCenter />}

      {/* {JSON.stringify(localActiveTemplate)} */}

      {/* {(lodash.isEmpty(localActiveTemplate) || !lodash.has(localActiveTemplate,'name')) && (
        <div>
          {t("No templates found")}{" "}
          <a href="#" onClick={() => !disabled && setShowModal(true)}>
            {t("Add Now.")}
          </a>
        </div>
      )} */}

      {(lodash.isEmpty(state[arrayIndex].activeNativeTemplate) ||
        !lodash.has(state[arrayIndex].activeNativeTemplate, "name")) && (
        <div>
          {t("No templates found")}{" "}
          <a onClick={() => !disabled && setShowModal(true)}>{t("Add Now.")}</a>
        </div>
      )}

      {/* <div>
        {!lodash.isEmpty(localActiveTemplate) && lodash.has(localActiveTemplate,'name') && (
          <>
            <Tag className="tag-preview" onClick={() => !disabled && setShowModal(true)}>{localActiveTemplate.name}</Tag>
            <div id={`html_output_${arrayIndex}`} className="mt-10" style={{maxHeight:'250px',overflow:'auto'}} dangerouslySetInnerHTML={sanitizedHtml} onClick={(e) => {
              e.stopPropagation();
              !disabled && setShowModal(true)
            }}></div>
          </>
        )}
      </div> */}

      <div>
        {!lodash.isEmpty(state[arrayIndex].activeNativeTemplate) &&
          lodash.has(state[arrayIndex].activeNativeTemplate, "name") && (
            <>
              <Tag
                className="tag-preview"
                onClick={() => !disabled && setShowModal(true)}
              >
                {state[arrayIndex].activeNativeTemplate.name}
              </Tag>

              <div
                id={`html_output_${arrayIndex}`}
                className="mt-10 html-output-div"
                dangerouslySetInnerHTML={sanitizedHtml}
                onClick={(e) => {
                  if(lodash.isEmpty(lodash.get(state[arrayIndex],'activeNativeTemplate.rawJS',""))){
                    e.stopPropagation();
                    !disabled && setShowModal(true);
                  }
                }}
              ></div>
            </>
          )}
      </div>

      <Modal
        wrapClassName="qu-modal"
        visible={showModal}
        footer={false}
        closable={true}
        width={675}
        onCancel={(e) => {
          e.stopPropagation();

          if (
            !lodash.isEmpty(state[arrayIndex]?.activeNativeTemplate?.values)
          ) {
            handleSync();
          }
          setShowModal(false);

          // if(lodash.isEmpty(localActiveTemplate)){
          //   qnotification({message:t('Please select a template'),type:'error'});
          //   setShowModal(false);
          //   return false;
          // }

          // if(!lodash.has(localActiveTemplate,'values')){
          //   qnotification({message:t('Please add variable values'),type:'error'});
          //   setShowModal(false);
          //   return false;
          // }else if(Object.values(localActiveTemplate.values).filter(o=>o).length !== localActiveTemplate?.variables?.length){
          //   qnotification({message:t('Not all variable values were replaced'),type:'error'});
          //   setShowModal(false);
          //   return false;
          // }

          // if(localActiveTemplate && localActiveTemplate?.id && localActiveTemplate.values){
          //   let ss = state.map((s,index)=>{
          //     if(index === arrayIndex){
          //       s.activeNativeTemplate = localActiveTemplate
          //       s.templateId = localActiveTemplate.id
          //       s.templateVariables = localActiveTemplate.values
          //     }
          //     return s;
          //   })
          //   setState(ss)
          //   handleSync();
          // }
          // setShowModal(false);
        }}
      >
        <div className="v3-section">
          <h3 className="mb-20">{t("Edit Template")}</h3>
          <QSelect
            // value={localActiveTemplate?.id}
            disabled={
              lodash.get(state[arrayIndex], "mappedTemplate.id", -1) > 0 &&
              briefSettings?.nativeadTemplates?.find(
                (o: any) =>
                  o.id ===
                  lodash.get(state[arrayIndex], "mappedTemplate.id", -1)
              )
            }
            value={state[arrayIndex]?.activeNativeTemplate?.id}
            onChange={handleTemplateChange}
            size="large"
            className="full-width"
            options={briefSettings?.nativeadTemplates?.map((template: any) => {
              return {
                label: template.name,
                value: template.id,
              };
            })}
            placeholder={t("Please select")}
          />
          <Divider></Divider>

          {!lodash.isEmpty(state[arrayIndex]?.activeNativeTemplate) &&
            lodash.has(
              state[arrayIndex]?.activeNativeTemplate,
              "variables"
            ) && (
              <Row gutter={24}>
                <Col md={24} xs={24}>
                  <h6>{t("Replace Variables Values")}</h6>

                  {!lodash.isEmpty(state[arrayIndex]?.activeNativeTemplate) &&
                    state[arrayIndex]?.activeNativeTemplate?.variables.map(
                      (v: any, index: number) => {
                        return (
                          <Row key={index} align="middle" className="mt-20">
                            <Col sm={10} md={10} xs={24}>
                              <label className="required-label">{v.name}</label>
                            </Col>
                            <Col sm={14} md={14} xs={24}>
                              <QInput
                                onChange={(e: any) =>
                                  handleVariableChange(
                                    v.placeholder,
                                    e.target.value
                                  )
                                }
                                value={getVariableValue(v.placeholder)}
                                placeholder={t("Enter here")}
                                size="large"
                              />
                            </Col>
                          </Row>
                        );
                      }
                    )}
                </Col>
              </Row>
            )}
          {/* {!lodash.isEmpty(state) && <QButton size="small" type="primary" className="mt-20" onClick={handleSync}>
            Visualise
          </QButton>}  */}
        </div>
      </Modal>
    </>
  );
};

export default React.memo(V3NativeTemplatesComponent);
