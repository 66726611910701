import { createSelector } from 'reselect'
import { IChartData, IChartsParams, IOpenRequestItem, IState, IStatisticsCards } from './dashboard.types'
import { MODULE_NAME } from './dashboard.constants'
import { renderRoundedNumber } from 'src/helpers/currency'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import { getCurrency } from '../../core/core.selectors'
import { FORMATS } from '../../../constants'
import { IRequestTable } from '../requests/request.types'
import { ICampaignTable } from '../../campaign/campaign.types'
import { first } from 'lodash'
import { campaignsToTable } from '../../../helpers/campaign'
import { CURRENCY } from '../../../constants/currencies'

const moment = extendMoment(Moment)

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getStatisticsCards = createSelector(selectState, (state: IState): IStatisticsCards => state.statisticCards)

export const getChartData = createSelector(selectState, (state: IState): IChartData => state.chartData)

export const getChartParams = createSelector(selectState, (state: IState): IChartsParams => state.params)

export const getParsedChartData = createSelector(selectState, (state: IState): any => {
  const { period } = state.params
  const endPeriod = moment().subtract(period - 1, 'months')
  const startPeriod = moment()
  const range = moment.range(endPeriod, startPeriod)
  const chartPeriod = [] as any
  for (let month of range.by('month')) {
    chartPeriod.push({ name: month.format(FORMATS.DATE_MMM_YYYY_FORMAT), Media: 0, Inserts: 0, Samples: 0, amt: 0 })
  }
  const dataChart = state.chartData.charts.map(month => ({
    name: moment(month.date).format(FORMATS.DATE_MMM_YYYY_FORMAT),
    Media: +month.media.toFixed(),
    Inserts: +month.inserts.toFixed(),
    Samples: +month.samples.toFixed(),
    amt: +month.total.toFixed(),
  }))
  const mergedChartData = chartPeriod.reduce((acc: any, val: any) => {
    const v = dataChart.find(i => i.name === val.name)
    return [...acc, { ...val, ...v }]
  }, [])

  return mergedChartData
})

export const getOpenRequests = createSelector(
  [selectState, getCurrency],
  (state: IState, currency: CURRENCY): IRequestTable[] => {
    const tableList = state.openRequests.map((request: IOpenRequestItem) => {
      return {
        key: request.id,
        product: {
          image: request.productImageUrl ? first(request.productImageUrl) : null,
          title: request.productName ? request.productName : '',
          received: moment(request.lastApprovedDate).format(FORMATS.DATE_LL_FORMAT),
        },
        service: {
          title: request.type,
          startDate: request.startDate ? moment(request.startDate).format(FORMATS.DATE_LL_FORMAT) : '',
          duration: request.duration ? request.duration : null,
          revenue: renderRoundedNumber(request.revenue, currency),
        },
        status: request.status,
      } as IRequestTable
    })

    return tableList
  }
)

export const getOpenRequestsLoading = createSelector(selectState, (state: IState): boolean => state.openRequestsLoading)

export const getLiveCampaigns = createSelector(
  [selectState, getCurrency],
  (state: IState, currency: CURRENCY): ICampaignTable[] => {
    const tableList = campaignsToTable(state.liveCampaigns, currency)

    return tableList
  }
)

export const getLiveCampaignsLoading = createSelector(
  selectState,
  (state: IState): boolean => state.liveCampaignsLoading
)
