import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { PATHS } from '../../../../../constants'
import { QButton } from 'quantum_components'
import { isMobile } from 'react-device-detect'
import { getMatchDec } from 'src/helpers/matchNumber'

export interface IProps {
  percentage: number
}

export function ClientProfilePreviewHeader({ percentage }: IProps): ReactElement {
  const { t } = useTranslation(['translationUser'])
  return (
    <div className="client-preview-container-header">
      <h1 className={isMobile ? 'h2' : 'h1'}>{t('client.profile.view.my_profile')}</h1>
      <div className={`client-preview-container-percentage badge--match-${getMatchDec(percentage)}`}>{percentage}%</div>
      <Link to={PATHS.CLIENT_PROFILE} className="qu-button-wrapper">
        <QButton className="qu-button-soft">{t('client.profile.view.edit_profile')}</QButton>
      </Link>
    </div>
  )
}
