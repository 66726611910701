import * as responsesMocks from './responsesMocks'
import { IMockConfig } from './types'
import { HTTP_METHODS } from '../../constants'

const mocksConfig: IMockConfig[] = [
  {
    path: '/Account/login',
    httpMethod: HTTP_METHODS.POST,
    response: responsesMocks.signIn.success,
  },
  {
    path: '/Account/signUp',
    httpMethod: HTTP_METHODS.POST,
    response: responsesMocks.signUpForm.success,
  },
  {
    path: '/Account/logout',
    httpMethod: HTTP_METHODS.POST,
    response: responsesMocks.signIn.success,
  },
  {
    path: '/Account/test',
    httpMethod: HTTP_METHODS.POST,
    response: responsesMocks.checkAuth.forbidden,
  },
]

export default mocksConfig
