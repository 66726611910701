import { abbreviateNumber } from './abbreviateNumber'
import { IISocialInitState, ISocial } from '../modules/brief/brief.types'
import { SOCIAL_NAMES, SOCIAL_TYPES } from '../constants/partner'

export const getSocial = (socials: ISocial[]) => {
  if (!socials || !socials.length) {
    return [...IISocialInitState]
  }
  const order = [
    SOCIAL_TYPES.LEADS,
    SOCIAL_TYPES.LOOKS,
    SOCIAL_TYPES.FACEBOOK,
    SOCIAL_TYPES.INSTAGRAM,
    SOCIAL_TYPES.TWITTER,
    SOCIAL_TYPES.LINKEDIN,
    SOCIAL_TYPES.YOUTUBE,
  ]
  let temp = order.reduce((c, v, i) => Object.assign(c, { [v]: i }), {})
  //@ts-ignore
  socials.sort((a, b) => temp[a.name] - temp[b.name])

  return socials.map((social: ISocial) => {
    let s = { ...social }
    //@ts-ignore
    s.name = SOCIAL_NAMES[social.name]
    s.counter = s.counter ? abbreviateNumber(+s.counter) : '0'
    if (!s.redirectURL) delete s.redirectURL
    return s
  })
}
