import React, { Fragment, ReactElement, useState } from 'react'
import BriefSwitch from '../../modules/brief/containers/components/Brief.Switch'
import BriefCartSummary from '../../modules/brief/containers/components/Brief.CartSummary'
import ServiceCard from '../../modules/brief/containers/components/Brief.Service'
import BriefProduct from '../../modules/brief/containers/components/Brief.Product'
import BriefMedia from '../../modules/brief/containers/components/Brief.Media'
import BriefTargeting from '../../modules/brief/containers/components/Brief.Targeting'
import BriefAdditional from '../../modules/brief/containers/components/Brief.Additional'
import { BRIEF_PRINT_ID, BRIEF_TYPES, DISTRIBUTION_TYPE, SERVICE_NAMES_FOR_TABLE } from '../../constants/brief'
import { IBriefResponse, ICartList } from '../../modules/brief/brief.types'
import BriefUpload from 'src/modules/brief/containers/components/Brief.Upload'

interface IProps {
  brief: IBriefResponse
  cart: ICartList
  partnerIds?: number[]
  partnerQuantity?: number
}

export default function BriefViewMobileComponent({ brief, cart, partnerIds, partnerQuantity }: IProps): ReactElement {
  const [showSummary, setShowSummary] = useState(false)

  const changeSwitch = (e: any) => {
    const { value } = e.target
    setShowSummary(value)
  }

  const isProductSample = brief.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isMediaBuying = brief.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
  const distributionItem =
    brief.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
      ? SERVICE_NAMES_FOR_TABLE.INSERT
      : SERVICE_NAMES_FOR_TABLE.PRODUCT
  return (
    <div id={BRIEF_PRINT_ID}>
      <div data-html2canvas-ignore>
        <BriefSwitch onChange={changeSwitch} value={showSummary} />
      </div>
      {/* only for partner view campaigns */}
      {/*<div className="brief-label-time mobile-only">*/}
      {/*  <h3>Received from Lays, Inc.</h3>*/}
      {/*  <span>May 8, 2020 • 3 Days Ago</span>*/}
      {/*</div>*/}
      <div className={`${showSummary ? 'hidden-block' : 'visible-block'} `} id="ServiceCard">
        <Fragment>
          
          <BriefProduct briefView={brief} onEdit={() => null} partnerQuantity={partnerQuantity} viewMode />
          <BriefTargeting briefView={brief} onEdit={() => null} viewMode />
          <BriefUpload briefView={brief} onEdit={() => null} partnerIds={partnerIds} viewMode />
        </Fragment>
      </div>
      <div className={`${showSummary ? 'visible-block' : 'hidden-block'}`}>
        <BriefCartSummary setCurrentStep={() => null} cart={cart} onEdit={() => null} viewMode />
      </div>
    </div>
  )
}
