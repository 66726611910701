import React, { useEffect, useState } from 'react'
import { Form, QBriefCart, QSelect } from 'quantum_components'
import { IOptionForSelect, IPartnerFilter } from '../../testahelBox.types'
import { useReduxDispatch } from '../../../../../helpers'
import { actions } from '../../testahelBox.module'
import { useSelector } from 'react-redux'
import {
  getBrief,
  getBriefExclusivePartnerId,
  getCart,
  getPartnerFilter,
  getPartnerFilterSettings,
  getSampleAllocated,
  getTotalQuantity,
} from '../../testahelBox.selectors'
import BriefCartMenu from './TestahelBrief.CartMenu'
import CurrencyDropdown from '../../../../../components/CurrencyDropdown/CurrencyDropdown'
import {
  BRIEF_STEPS,
  BRIEF_TYPES,
  CONSUMER_RESEARCH_MEDIA_TYPES,
  DISTRIBUTION_TYPE,
} from '../../../../../constants/testahel_brief'
import { getCurrency } from 'src/modules/core/core.selectors'
import { getLocaleCost, renderRoundedNumber } from 'src/helpers/currency'

interface Props {}

const TestahelBriefPartnerFilter = ({}: Props) => {
  const dispatch = useReduxDispatch()
  const filter = useSelector(getPartnerFilter)
  const filterSettings = useSelector(getPartnerFilterSettings)
  const cart = useSelector(getCart)
  const totalQty = useSelector(getTotalQuantity)
  const sampleAllocated = useSelector(getSampleAllocated)
  const brief = useSelector(getBrief)
  const briefExclusivePartnerId = useSelector(getBriefExclusivePartnerId)
  const [form] = Form.useForm()
  const currency = useSelector(getCurrency)
  const [budgetCurrent, setBudgetCurrent] = useState<any>('')
  const [budgetNeeded, setBudgetNeeded] = useState<any>('')

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(filter)
  }, [filter])

  useEffect(() => {
    let totalBudget = 0
    cart.additional.items.map(ele => {
      ele.services.map((ele1: any) => {
        if (
          ele1.name !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
          ele1.name !== DISTRIBUTION_TYPE.PRINTED_INSERT &&
          ele1.name !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
          ele1.name !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
        ) {
          totalBudget = totalBudget + ele1.total
        }
      })
    })
    totalBudget
      ? setBudgetCurrent(renderRoundedNumber(totalBudget, currency))
      : setBudgetCurrent(getLocaleCost(totalBudget) + ' ' + currency)
  }, [cart])

  useEffect(() => {
    brief?.BRIEF_MEDIA_BUY?.budgetValue
      ? setBudgetNeeded(renderRoundedNumber(brief?.BRIEF_MEDIA_BUY?.budgetValue, currency))
      : setBudgetNeeded(getLocaleCost(brief?.BRIEF_MEDIA_BUY?.budgetValue) + ' ' + currency)
  }, [brief])

  const setFilterState = (filter: IPartnerFilter) => {
    dispatch(actions.setPartnerFilterState(filter))
  }

  const clearFilter = () => {
    dispatch(actions.resetPartnerFilterState())
  }

  const handleChangeCategory = (values: string[]) => {
    const newFilter = { ...filter }
    newFilter.categories = values

    setFilterState(newFilter)
  }

  const handleChangePrices = (values: string[]) => {
    const newFilter = { ...filter }
    newFilter.pricePerUnit = values

    setFilterState(newFilter)
  }

  const handleChangeMatches = (values: string[]) => {
    const newFilter = { ...filter }
    newFilter.matches = values

    setFilterState(newFilter)
  }

  const handleChangeTypes = (values: string[]) => {
    const newFilter = { ...filter }
    newFilter.types = values

    setFilterState(newFilter)
  }

  const categoriesOptions: IOptionForSelect[] = filterSettings.categories.map((name: string) => {
    return { label: name, value: name }
  })

  const pricesOptions: IOptionForSelect[] = filterSettings.pricePerUnit.map((name: string) => {
    return { label: name, value: name }
  })

  const matchesOptions: IOptionForSelect[] = filterSettings.matches.map((name: string) => {
    return { label: name, value: name }
  })

  const typesOptions: IOptionForSelect[] = filterSettings.types.map((name: string) => {
    return { label: name, value: name }
  })

  const cartItemsNumber = cart.additional.items.length

  const handleChangeCurrency = async () => {
    const updated = { ...brief }
    const lastSteps = [BRIEF_STEPS.PARTNER, BRIEF_STEPS.UPLOAD, BRIEF_STEPS.SUMMARY]
    if (updated.lastFilledStep && lastSteps.includes(updated.lastFilledStep)) return

    updated.lastFilledStep = BRIEF_STEPS.PARTNER
    updated[BRIEF_STEPS.PARTNER].quantity = updated[BRIEF_STEPS.PRODUCT].quantity || 0
    // updated[BRIEF_STEPS.PARTNER].hasFreeSample = updated[BRIEF_STEPS.SERVICES].hasFreeSample

    await dispatch(actions.updateBrief(BRIEF_STEPS.PARTNER, updated))
  }

  return (
    <div className="qu-brief-filter">
      <Form form={form}>
        {briefExclusivePartnerId == null ? (
          <div className="filter-left">
            <Form.Item name="categories" className="mb-0 mr-10">
              <QSelect
                value={filter.categories}
                onChange={handleChangeCategory}
                showArrow
                maxTagCount={0}
                mode="multiple"
                placeholder="Categories"
                options={categoriesOptions}
                getPopupContainer={() => document.body}
                size="large"
                style={{ width: 160 }}
              />
            </Form.Item>
            <Form.Item name="pricePerUnit" className="mb-0 mr-10">
              <QSelect
                value={filter.pricePerUnit}
                onChange={handleChangePrices}
                showArrow
                maxTagCount={0}
                mode="multiple"
                placeholder="Price Per Unit"
                options={pricesOptions}
                getPopupContainer={() => document.body}
                size="large"
                style={{ width: 160 }}
              />
            </Form.Item>
            <Form.Item name="matches" className="mb-0 mr-10">
              <QSelect
                value={filter.matches}
                onChange={handleChangeMatches}
                showArrow
                maxTagCount={0}
                mode="multiple"
                placeholder="Match %"
                options={matchesOptions}
                getPopupContainer={() => document.body}
                size="large"
                style={{ width: 160 }}
              />
            </Form.Item>
            <Form.Item name="types" className="mb-0 mr-10">
              <QSelect
                value={filter.types}
                onChange={handleChangeTypes}
                showArrow
                maxTagCount={0}
                mode="multiple"
                placeholder="Type"
                options={typesOptions}
                getPopupContainer={() => document.body}
                size="large"
                style={{ width: 160 }}
              />
            </Form.Item>
            <div onClick={clearFilter} className="qu-button-link">
              Clear Filters
            </div>
          </div>
        ) : (
          <div className="filter-left"></div>
        )}
      </Form>
      <div className="filter-right">
        <div className="currency_dropdown mr-10">
          <CurrencyDropdown size="large" handleChange={handleChangeCurrency} hideDialog />
        </div>
        <QBriefCart
          menu={<BriefCartMenu />}
          updated
          samplesCurrent={`${totalQty}`}
          samplesNeeded={`${brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE) ? sampleAllocated : ''}`}
          badgeNumber={cartItemsNumber}
          budgetCurrent={budgetCurrent}
          budgetNeeded={brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING) ? budgetNeeded : ''}
        />
      </div>
    </div>
  )
}
export default TestahelBriefPartnerFilter
