enum COMMON {
  REFERRAL_HASH = '#referral',
}

export enum CORE_CONSTANTS {
  CAMPAIGN_MEDIA_BUYS = 'CAMPAIGN_MEDIA_BUYS',
  AGE_GROUPS_GENDERS = 'AGE_GROUPS_GENDERS',
  MEDIA_BUY_TYPES = 'MEDIA_BUY_TYPES',
  REFERRALS = 'REFERRALS',
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD',
}

export enum SOCIAL_LOGIN_TYPES {
  GOOGLE = 'GOOGLE',
}

export default COMMON
