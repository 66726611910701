import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { IUpdatePasswordResponse } from './updatePassword.types'
import * as CONSTANTS from './updatePassword.constants'
import { getResponseErrorMessage } from 'src/helpers'
import { getCurrentUserRole } from '../core/core.selectors'
import { ROLES } from 'src/constants'

export const updatePasswordRequest = (): IAction => ({
  type: CONSTANTS.UPDATE_PASSWORD_REQUEST,
})

export const updatePasswordSuccess = (): IAction => ({
  type: CONSTANTS.UPDATE_PASSWORD_SUCCESS,
})

export const updatePasswordFailure = (error: string): IAction => ({
  type: CONSTANTS.UPDATE_PASSWORD_FAILURE,
  error,
})

export const updatePassword = (passwords: {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}): ThunkAction<Promise<AxiosResponse<IUpdatePasswordResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: any
): Promise<AxiosResponse<IUpdatePasswordResponse>> => {
  try {
    const role = getCurrentUserRole(getState())
    dispatch(updatePasswordRequest())
    const response = await requestHttp.patch<IUpdatePasswordResponse>(
      role === ROLES.CLIENT ? urls.getClientUpdatePasswordUrl() : urls.getPartnerUpdatePasswordUrl(),
      {
        ...passwords,
      }
    )
    dispatch(updatePasswordSuccess())

    return response
  } catch (error: any) {
    dispatch(updatePasswordFailure(getResponseErrorMessage(error)))

    return error
  }
}
