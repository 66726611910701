import React from 'react'
import { MEDIA_GROUP_TYPE, SERVICE_NAMES_FOR_TABLE } from '../../../../constants/brief'

interface Props {
  isProductSample: boolean
  isMediaBuying: boolean
  distributionItem: SERVICE_NAMES_FOR_TABLE
  onEdit: () => void
  viewMode?: boolean
  requestType?: MEDIA_GROUP_TYPE
}
const ServiceCard = ({ isProductSample, isMediaBuying, distributionItem, onEdit, viewMode, requestType }: Props) => {
  return (
    <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>Services</h4>
        {!viewMode && <span onClick={onEdit}>Edit</span>}
      </div>
      <div className="services mb-15">
        {requestType ? (
          <ul className="p p--sm cart-summary__ulist mb-0">
            <li className={'BSUnit__list-item'}>{requestType}</li>
          </ul>
        ) : (
          <ul className="p p--sm cart-summary__ulist mb-0">
            <li className={`BSUnit__list-item ${!isProductSample && 'disabled'}`}>{distributionItem}</li>
            <li className={`BSUnit__list-item ${!isMediaBuying && 'disabled'}`}>Media Buying</li>
          </ul>
        )}
      </div>
    </div>
  )
}

export default ServiceCard
