import get from 'lodash/get'
import { IState } from './marketplace.types'
import * as CONSTANTS from './marketplace.constants'
import IAction from 'src/interfaces/IAction'

const initialState: IState = {
  partners: [],
  params: {
    offset: 0,
    limit: 12,
  },
  fetchMarketplacePartnersLoading: false,
  marketplacePartnersError: '',
  activeSinglePartnerId: null,
  partnerDetails: null,
  fetchPartnerDetailsLoading: false,
  partnerFilterSettingsError: '',
  totalPartners: 0,
  partnerLocked: false,
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_MARKETPLACE_STATE:
      return initialState
    case CONSTANTS.RESET_PARTNER_DETAILS:
      return {
        ...state,
        partnerDetails: initialState.partnerDetails,
      }
    case CONSTANTS.FETCH_MARKETPLACE_PARTNERS_REQUEST:
      return {
        ...state,
        fetchMarketplacePartnersLoading: true,
      }
    case CONSTANTS.FETCH_MARKETPLACE_PARTNERS_SUCCESS:
      const partnersResult:any = get(action, 'payload.partners')
      // @ts-ignore
      const totalPages = Math.ceil(partnersResult.total / state.params.limit)
      return {
        ...state,
        // @ts-ignore
        partners: partnersResult.partners,
        // @ts-ignore
        totalPartners: partnersResult.total,
        // @ts-ignore
        partnerLocked: partnersResult.partnerLocked,
        pages: { ...state.pages, totalPages },
        fetchMarketplacePartnersLoading: false,
      }
    case CONSTANTS.FETCH_MARKETPLACE_PARTNERS_FAILURE:
      return { ...state, marketplacePartnersError: action.error, fetchMarketplacePartnersLoading: false }

    case CONSTANTS.SET_ACTIVE_SINGLE_PARTNER:
      return {
        ...state,
        activeSinglePartnerId: get(action, 'payload.partnerId'),
      }

    case CONSTANTS.FETCH_PARTNER_DETAILS_REQUEST:
      return {
        ...state,
        fetchPartnerDetailsLoading: true,
      }
    case CONSTANTS.FETCH_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        partnerDetails: get(action, 'payload.partner'),
        fetchPartnerDetailsLoading: false,
      }
    case CONSTANTS.FETCH_PARTNER_DETAILS_FAILURE:
      return { ...state, partnerFilterSettingsError: action.error, fetchPartnerDetailsLoading: false }

    case CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, pages: { ...state.pages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_PARTNER_LIST_PARAMS:
      return { ...state, params: get(action, 'payload.params') }
    default:
      return state
  }
}
