import {
  IBriefRecommendedPartner,
  IPartnerCardService,
  IPartnerDetailsService,
  MediaSubtype,
} from '../modules/brief/brief.types'
import { DISTRIBUTION_TYPE, MEDIA_TYPES, MEDIA_TYPES_DURATION } from '../constants/brief'
import {
  BANNER_SUB_TYPES,
  SKU_SUB_TYPES,
  SOCIAL_MEDIA_ADS_SUB_TYPES,
  SURVEY_SUB_TYPES,
} from '../modules/partner/partnerProfile/profile.types'
import { getMediaBuyPrice, getMediaBuyPriceForMetrics, renderRoundedNumber } from './currency'
import { IMarketplacePartner } from '../modules/client/marketplace/marketplace.types'
import { CURRENCY } from '../constants/currencies'
import { DynamicPricingTypeLabels } from 'src/constants/profile'

export const getDuration = (service: IPartnerDetailsService) => {
  if(service.serviceExecutionType) return service.serviceExecutionType;
  
  const perPush = [MEDIA_TYPES.POPUP_WINDOW, MEDIA_TYPES.PUSH_NOTIFICATIONS]
  const perActivity = [
    MEDIA_TYPES.FREE_DELIVERY,
    MEDIA_TYPES.CASHBACK,
    MEDIA_TYPES.PRICE_OFF_PROMO,
    MEDIA_TYPES.SURVEY,
    MEDIA_TYPES.SOCIAL_MEDIA,
  ]
  if (perPush.includes(service.serviceName)) {
    return MEDIA_TYPES_DURATION.PER_PUSH
  }

  if (perActivity.includes(service.serviceName)) {
    return MEDIA_TYPES_DURATION.PER_ACTIVITY
  }

  return service.serviceDuration
}

export const getTypeBySubType = (subType: string) => {
  if (getBannersSubTypes.includes(subType)) {
    return MEDIA_TYPES.HOME_BANNERS
  }

  if (getSkuSubTypes.includes(subType)) {
    return MEDIA_TYPES.SKU
  }

  if (getSurveySubTypes.includes(subType)) {
    return MEDIA_TYPES.SURVEY
  }

  if (getSocialMediaSubTypes.includes(subType)) {
    return MEDIA_TYPES.SOCIAL_MEDIA
  }

  return subType
}

export const getBannersSubTypes = [
  BANNER_SUB_TYPES.HOME_PAGE_WEB,
  BANNER_SUB_TYPES.HOME_PAGE_APP,
  BANNER_SUB_TYPES.CATEGORY_PAGE_WEB,
  BANNER_SUB_TYPES.CATEGORY_PAGE_APP,
  BANNER_SUB_TYPES.EMAIL_BLAST_BANNER,
]

export const getSkuSubTypes = [
  SKU_SUB_TYPES.IN_OFFER,
  SKU_SUB_TYPES.ALL_ITEMS,
  SKU_SUB_TYPES.CATEGORY,
  SKU_SUB_TYPES.SUB_CATEGORY_PAGE,
  SKU_SUB_TYPES.OTHER,
]

export const getSurveySubTypes = [SURVEY_SUB_TYPES.IN_APP_SURVEY, SURVEY_SUB_TYPES.EMAIL, SURVEY_SUB_TYPES.SMS]

export const getSocialMediaSubTypes = [
  SOCIAL_MEDIA_ADS_SUB_TYPES.TWITTER,
  SOCIAL_MEDIA_ADS_SUB_TYPES.FACEBOOK,
  SOCIAL_MEDIA_ADS_SUB_TYPES.SNAPCHAT,
  SOCIAL_MEDIA_ADS_SUB_TYPES.INSTAGRAM,
  SOCIAL_MEDIA_ADS_SUB_TYPES.YOUTUBE,
]

export const getMediaTypes = (
  partner: IBriefRecommendedPartner | IMarketplacePartner,
  mediaSubTypes: MediaSubtype[],
  currency: CURRENCY
) => {
  const services = [] as IPartnerCardService[]
  if (partner.isSampleDistribution) {
    if (partner.hasDynamicSamplePricing) {
      Object.entries(partner.dynamicSamplePrices).map(([key, value]) => {
        const price = renderRoundedNumber(value, currency)
        const sampleService = {
          name: DISTRIBUTION_TYPE.PRODUCT_SAMPLE + ': ' + DynamicPricingTypeLabels[key],
          price: price,
          type: MEDIA_TYPES.DISTRIBUTION,
        } as IPartnerCardService

        services.push(sampleService)
      })
    } else {
      const price = renderRoundedNumber(partner.pricePerSample, currency)
      const sampleService = {
        name: DISTRIBUTION_TYPE.PRODUCT_SAMPLE,
        price: price,
        type: MEDIA_TYPES.DISTRIBUTION,
      } as IPartnerCardService

      services.push(sampleService)
    }
  }
  if (partner.isInsertDistribution) {
    const price = renderRoundedNumber(partner.pricePerInsert, currency)
    const insertService = {
      name: DISTRIBUTION_TYPE.PRINTED_INSERT,
      price: price,
      type: MEDIA_TYPES.DISTRIBUTION,
    } as IPartnerCardService

    services.push(insertService)
  }

  const mediaBuying = partner.availableServices.map((service: IPartnerDetailsService) => {
    const subType = mediaSubTypes.find((type: MediaSubtype) => type.name === service.serviceName)
    const price = partner.mbPricingModel === 'metrics' ? getMediaBuyPriceForMetrics(service) : getMediaBuyPrice(service)
    return {
      name: service.serviceSubName ? service.serviceSubName : service.serviceName,
      price: renderRoundedNumber(price, currency),
      type: 'Media Buying',
      subtype: subType && subType.subtype,
    } as IPartnerCardService
  })

  return [...services, ...mediaBuying]
}
