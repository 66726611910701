import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  Modal,
  Row,
  QPagination,
  IconsCommon,
  QTable,
  QButton,
  QPromotionsCard
} from "quantum_components";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { fetchAllAnnouncementsList, fetchAnnouncementById, fetchAnnouncementByIdSuccess } from "../announcement.actions";
import {
  getAllAnnouncements,
  getDataLoading,
  getAllAnnouncementsTotal,
  getAnnouncementLoading,
  getAnnouncementDetails,
  getPages,
} from "../announcement.selectors";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { FORMATS, LOCALIZATION_LANGUAGES, PATHS } from "src/constants";
import { getBrief, getIsSaving } from "src/modules/brief/brief.selectors";
import { BRIEF_STEPS, BRIEF_TYPES } from "src/constants/brief";
import * as briefActions from "../../../brief/brief.actions";
import { snakeCase } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as CircleCheckIcon } from 'src/assets/icons/circle-check-icon.svg'
import i18n from "src/i18n";
import { renderRoundedNumber } from "src/helpers/currency";
import { CURRENCY } from "src/constants/currencies";
import formats from "src/constants/formats";
import { actions } from "../announcement.module";
import SpinnerCenter from "src/components/Spinner/spinner";

const closeIcon = <IconsCommon.IconClose />

const Announcements = () => {
  const { t } = useTranslation(["translation", "translationCommon"]);
  const { id } = useParams<{ id: any }>()
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(getDataLoading);
  const allAnnouncements = useSelector(getAllAnnouncements);
  const totalAnnouncements = useSelector(getAllAnnouncementsTotal);
  const isAnnouncementLoading = useSelector(getAnnouncementLoading);
  const announcementDetails = useSelector(getAnnouncementDetails);
  const isSaving = useSelector(getIsSaving);
  const pages = useSelector(getPages);
  const [pagination, setPagination] = useState<any>({
    offset: 0,
    limit: 10,
    direction: "desc",
    sortField: "desiredStartingDate",
    search: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);

  const brief = useSelector(getBrief);

  const convertPaginationObjectToQueryParams = (obj: any) => {
    let query = "?";
    Object.entries(obj).forEach((entry: any) => {
      query += entry.join("=") + "&";
    });
    query = query.substring(0, query.length - 1);
    return query;
  };

  useEffect(() => {
    if (id) dispatch(fetchAnnouncementById(id))
    dispatch(
      fetchAllAnnouncementsList(
        convertPaginationObjectToQueryParams(pagination)
      )
    );
  }, []);

  useEffect(() => {
    if (!isAnnouncementLoading && announcementDetails) setVisible(true)
  }, [isAnnouncementLoading, announcementDetails])

  const getServiceQuantity = (duration: string, quantity: number) => {
    if (!duration) return "N/A";
    return `${quantity} ${duration.split(" ")[1]}(s/es)`;
  };

  const handleCreateBrief = async (id: number) => {
    let updatedBrief = { ...brief };
    updatedBrief["promotionId"] = id;
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = [BRIEF_TYPES.MEDIA_BUYING];

    // console.log(updatedBrief)

    await dispatch(briefActions.createBrief(updatedBrief));
  };

  const getThumbnail = (record: any) => {
    if (record.promotionImages && record.promotionImages.length > 0) {
      return record.promotionImages[0]
    }

    return 'https://i.ibb.co/6tH20M9/placeholder-image.png'
  }

  const columns = [
    {
      title: t("brief.promotions.promotion_name"),
      dataIndex: "promotionName",
      key: "promotionName",
      width: "20%",
      render: (text: string, record: any) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {record.promotionImages && (
                <img src={getThumbnail(record)} alt={record.promotionName} />
              )}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">
                {record.promotionName || "-"}
              </h3>
            </div>
          </div>
        );
      },
    },
    {
      title: t("brief.promotions.promotion_description"),
      dataIndex: "promotionDescription",
      key: "promotionDescription",
      width: "20%",
    },
    {
      title: t("brief.table-columns.service", { ns: "translationCommon" }),
      dataIndex: "service",
      key: "service",
      width: "20%",
      render: (text: string, record: any) => {
        return (
          <>
            {record.service.map((pr: any) => {
              return (
                <>
                  <div className="font-size-13">
                    <b>
                      {t(
                        "services.media-buy.types." +
                        snakeCase(pr.name),
                        pr.name,
                        { ns: "translation" }
                      )}
                    </b>
                  </div>
                  <div className="font-size-12 mb-10">
                    {getServiceQuantity(
                      pr.duration,
                      pr.quantity
                    )}
                  </div>
                </>
              );
            })}

            <div className="font-size-12 text-green">
              {record.discount + "% "} {t('brief.promotions.discount')}
            </div>
          </>
        );
      },
    },
    {
      title: t("brief.table-columns.starting_date", {
        ns: "translationCommon",
      }),
      dataIndex: "desiredStartingDate",
      key: "desiredStartingDate",
      width: "15%",
      render: (date: string) => {
        return moment(date).format(FORMATS.DATE_LL_FORMAT);
      },
    },
    {
      title: t("brief.table-columns.action", { ns: "translationCommon" }),
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: (text: string, record: any) => {
        return (
          <QButton
            type="primary"
            size="small"
            onClick={() => record.briefs.length > 0 ? history.push(`/briefs/edit/${record.briefs[0].id}`) : handleCreateBrief(record.id)}
          >
            {record.briefs.length > 0 ? t("brief.resume-brief-button") : t("brief.add-brief-button") + " +"}
          </QButton>
        );
      },
    },
  ];

  const handleChangePage = (pageNumber: number, pageSize: number) => {
    dispatch(actions.setCurrentPage(pageNumber));
    let offset = pageSize * (pageNumber - 1)
    let page = { ...pagination, offset, }

    setCurrentPage(pageNumber)
    setPagination(page);

    dispatch(
      fetchAllAnnouncementsList(
        convertPaginationObjectToQueryParams(page)
      )
    );
  };

  const cancelPromotionModal = () => {
    setVisible(false)
    dispatch(fetchAnnouncementByIdSuccess(null))
    history.push(PATHS.ANNOUNCEMENTS)
  }

  const calculateDuration = (duration: string, quantity: number) => {
    if (!duration) return "N/A";
    return `${quantity} ${quantity > 1 ? t(`common.` + duration.split(" ")[1] + `s`, { ns: 'translationCommon' }) : t(`common.` + duration.split(" ")[1], { ns: 'translationCommon' })}`;
  }

  const calculatePromotionDiscount = (promotion: any) => {
    let services = promotion.service;
    let promotionDiscount = +promotion.discount;

    if (promotionDiscount != 0) {
      return `${i18n.dir() === 'rtl' ? '%' : ''}${promotionDiscount}${i18n.dir() === 'ltr' ? '%' : ''}`
    }

    let total = services.reduce((acc, cur) => acc + Number(cur.total), 0)
    let offerPrice = services.reduce((acc, cur) => acc + Number(cur.offerPrice ?? cur.total), 0)
    let discount = total - offerPrice

    return renderRoundedNumber(discount, CURRENCY.SAR);
  }

  return (
    <div className="qu-brief-view">
      {isMobile && (isLoading || isSaving) && <SpinnerCenter />}

      <div className="qu-brief-list pb-20">
        <Row
          className={`${isMobile ? "pt-15 pl-15 pr-15" : "pt-30 mb-15"
            } brief-list-header`}
          align="middle"
          justify="space-between"
        >
          <Col>
            <h3>{t("brief.promotions.promotions")}</h3>
          </Col>
        </Row>

        {isMobile
          ? (
            <div className="p-5 promotions-carousel">
              {allAnnouncements.map((promotion: any) =>
                <QPromotionsCard
                  promotionThumbnail={promotion.promotionImages && !!promotion.promotionImages.length ? promotion.promotionImages : ["https://i.ibb.co/6tH20M9/placeholder-image.png"]}
                  promotionName={promotion.promotionName}
                  promotionType={promotion.service.map((pr: any) => {
                    return (
                      <>
                        {t(
                          "services.media-buy.types." +
                          snakeCase(pr.name),
                          pr.name,
                          { ns: "translation" }
                        )}
                        {" "}{t("brief.promotions.for")}{" "}
                        {calculateDuration(
                          pr.duration,
                          pr.quantity
                        )}
                      </>
                    );
                  })}
                  promotionTypeHeading={t('brief.promotions.services_bundle')}
                  promotionPrice={
                    i18n.language === LOCALIZATION_LANGUAGES.ARABIC ?
                      `@ ${t('brief.promotions.discount')} ${calculatePromotionDiscount(promotion)}`
                      : `@ ${calculatePromotionDiscount(promotion)} ${t('brief.promotions.discount')}`
                  }
                  promotionDate={t('brief.promotions.starting') + ' ' + moment(promotion.desiredStartingDate).format(formats.DATE_MMMM_FORMAT)}
                  promotionFiles={promotion.promotionFiles && promotion.promotionFiles.length ? promotion.promotionFiles : []}
                  promotionFilesText={t('brief.promotions.file')}
                  buttonText={promotion.briefs.length > 0 ? t("brief.resume-brief-button") : t("brief.add-brief-button") + " +"}
                  onCreateCampaign={() => promotion.briefs.length > 0 ? history.push(`/briefs/edit/${promotion.briefs[0].id}`) : handleCreateBrief(promotion.id)}
                />
              )}
              <div className="mobile-pagination">
                <Button
                  disabled={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? pages.currentPage === pages.totalPages : pages.currentPage === 1}
                  onClick={() => i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? handleChangePage(pages.currentPage + 1, 10) : handleChangePage(pages.currentPage - 1, 10)}
                  className="left-arrow"
                  type="text"
                  shape="circle"
                  icon={<LeftOutlined />}
                />
                <span>{t('common.showing_page', { ns: 'translationCommon' })} {pages.currentPage} {t('common.of', { ns: 'translationCommon' })} {pages.totalPages}</span>
                <Button
                  disabled={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? pages.currentPage === 1 : pages.currentPage === pages.totalPages}
                  onClick={() => i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? handleChangePage(pages.currentPage - 1, 10) : handleChangePage(pages.currentPage + 1, 10)}
                  className="right-arrow"
                  type="text"
                  shape="circle"
                  icon={<RightOutlined />}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="pt-15 pb-30">
                <QTable
                  //@ts-ignore
                  sortDirections={["ascend", "descend", "ascend"]}
                  loading={isLoading || isSaving}
                  columns={columns}
                  dataSource={allAnnouncements}
                  scroll={{ x: 990 }}
                  rowClassName="q-table cursorPointer"
                  locale={{emptyText:t('promotions_empty_str')}}
                />
              </div>
              <Row
                //@ts-ignore
                justify={`${isMobile ? "center" : "end"}`}
                className={`${isMobile && "pt-15 mb-30"}`}
              >
                <QPagination
                  size="default"
                  defaultCurrent={1}
                  current={currentPage}
                  total={totalAnnouncements}
                  onChange={handleChangePage}
                />
              </Row>
            </>
          )}
      </div>

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{t("brief.promotions.promotion_details")}</h3>}
        closeIcon={closeIcon}
        visible={visible}
        onCancel={cancelPromotionModal}
        footer={false}
      >
        <div>
          <p className="mb-25">
            <Row gutter={16}>
              <Col span={10}>{t("brief.promotions.promotion_name")}:</Col>
              <Col>{announcementDetails?.promotionName}</Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>{t("brief.promotions.promotion_description")}:</Col>
              <Col>{announcementDetails?.promotionDescription}</Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>{t("brief.table-columns.service", { ns: "translationCommon" })}:</Col>
              <Col>
                {announcementDetails?.service.map((pr: any) => {
                  return (
                    <>
                      <div className="font-size-13">
                        <b>{t("services.media-buy.types." + snakeCase(pr.name), pr.name, { ns: "translation" })}</b>
                      </div>
                      <div className="font-size-12 mb-10">
                        {getServiceQuantity(pr.duration, pr.quantity)}
                      </div>
                    </>
                  );
                })}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>{t('brief.promotions.Discount')}:</Col>
              <Col>
                <div className="font-size-12 text-green">
                  {announcementDetails?.discount + "% "} {t('brief.promotions.discount')}
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>{t("brief.table-columns.starting_date", { ns: "translationCommon" })}:</Col>
              <Col>{moment(announcementDetails?.desiredStartingDate).format(FORMATS.DATE_LL_FORMAT)}</Col>
            </Row>
          </p>
          <div className="align-vertical text-center">
            <QButton
              onClick={() => {
                announcementDetails?.briefs?.length > 0
                  ? history.push(`/briefs/edit/${announcementDetails.briefs[0].id}`)
                  : handleCreateBrief(announcementDetails.id)
                setVisible(false)
              }}
              className="w-280 mb-20"
              type="primary"
            >
              <span className="mr-5">
                {announcementDetails?.briefs?.length > 0 ? t("brief.resume-brief-button") : t("brief.add-brief-button")}
              </span>
              <CircleCheckIcon />
            </QButton>
            <button
              onClick={cancelPromotionModal}
              type="button"
              className="button button--link mb-10"
            >
              {t("common.Cancel", { ns: "translationCommon" })}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Announcements;
