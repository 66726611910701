import React, { ReactElement } from 'react'
import { IBrief } from '../../testahelBox.types'
import { BRIEF_STEPS, BRIEF_TYPES, CREATE_BRIEF_STEPS } from '../../../../../constants/testahel_brief'
import moment from 'moment'
import { actions } from '../../testahelBox.module'
import { useReduxDispatch } from '../../../../../helpers'
import { FORMATS } from '../../../../../constants'
import { isInsert, isSample } from '../../../../../helpers/testahelBrief'
import { useSelector } from 'react-redux'
import { getBriefSettings } from '../../testahelBox.selectors'
import { getLocaleCost } from 'src/helpers/currency'

export interface IProps {
  brief: IBrief
}

export default function TestahelBriefSummary({ brief }: IProps): ReactElement {
  const productFields = brief[BRIEF_STEPS.PRODUCT]
  const customizeBoxFields = brief[BRIEF_STEPS.CUSTOMIZED]
  const targetingFields = brief[BRIEF_STEPS.TARGETING]
  const servicesFields = brief[BRIEF_STEPS.SERVICES]
  const mediaBuyFields = brief[BRIEF_STEPS.MEDIA_BUY]
  const consumerResearchFields = brief[BRIEF_STEPS.CONSUMER_RESEARCH]
  const lastFilledIndex = brief.lastFilledStep ? CREATE_BRIEF_STEPS.indexOf(brief.lastFilledStep) : 0
  const serviceStepIndex = CREATE_BRIEF_STEPS.indexOf(BRIEF_STEPS.SERVICES)
  const dispatch = useReduxDispatch()

  const setCurrentStep = (step: BRIEF_STEPS) => {
    dispatch(actions.setCurrentStep(step))
  }

  const onlyInsert = isInsert(productFields.distributionItem)
  const onlySample = isSample(productFields.distributionItem)
  const hasSample = brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const hasCustomized = brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
  const hasMediaBuy = brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)
  const hasConsumerResearch = brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)

  const briefSettings = useSelector(getBriefSettings)

  return (
    <div className="cart-summary">
      {brief[BRIEF_STEPS.TYPE].briefTypes.length > 0 && (
        <>
          <a onClick={() => setCurrentStep(BRIEF_STEPS.TYPE)} className="p p--xs tc--light cart-summary__edit">
            Edit
          </a>
          <div className="h6 cart-summary__heading">Services</div>
          <ul className="p p--sm cart-summary__ulist">
            {hasSample && <li>Product Sample Distribution</li>}
            {hasCustomized && <li>Customize Box</li>}
            {hasMediaBuy && <li>Media-Buying</li>}
            {hasConsumerResearch && <li>Consumer Research</li>}
          </ul>
        </>
      )}

      {!hasCustomized && productFields && productFields.name && (
        <>
          <hr className="cart-summary__divider" />
          <a onClick={() => setCurrentStep(BRIEF_STEPS.PRODUCT)} className="p p--xs tc--light cart-summary__edit">
            Edit
          </a>
          <div className="h6 cart-summary__heading">Product Brief</div>
          <dl className="cart-summary__dlist mb-0">
            <dt>Name</dt>
            <dd>{productFields.name}</dd>
            <dt>Type</dt>
            <dd>{productFields.productType}</dd>
            {hasSample && (
              <>
                <dt>Format</dt>
                <dd>{productFields.distributionItem}</dd>
                <dt>Quantity</dt>
                <dd>{productFields.quantity}</dd>
                <dt>Month</dt>
                <dd>
                  {productFields.briefStartDate &&
                    moment(productFields.briefStartDate, FORMATS.DATE_MMM_YYYY_FORMAT).format(
                      FORMATS.DATE_MMM_YYYY_FORMAT
                    )}
                </dd>
              </>
            )}
          </dl>
        </>
      )}

      {hasCustomized && customizeBoxFields && customizeBoxFields.customizeBoxBriefName && (
        <>
          <hr className="cart-summary__divider" />
          <a onClick={() => setCurrentStep(BRIEF_STEPS.CUSTOMIZED)} className="p p--xs tc--light cart-summary__edit">
            Edit
          </a>
          <div className="h6 cart-summary__heading">Customize Box</div>
          <dl className="cart-summary__dlist">
            <dt>Name</dt>
            <dd>{customizeBoxFields.customizeBoxBriefName}</dd>
            <dt>Quantity</dt>
            <dd>{customizeBoxFields.customizeBoxDistributionCount}</dd>
            <dt>Products</dt>
            <dd>{customizeBoxFields.customizeBoxProductCount}</dd>
            <dt>Product Types</dt>
            <dd>{customizeBoxFields.customizeBoxProductType.join(', ')}</dd>
            <dt>Des. Start</dt>
            <dd>
              {moment(customizeBoxFields.customizeBoxStartDate, FORMATS.DATE_LL_FORMAT).format(FORMATS.DATE_LL_FORMAT)}
            </dd>
            {customizeBoxFields.customizeBoxAdditionalComment && (
              <>
                <dt>Instructions</dt>
                <dd>{customizeBoxFields.customizeBoxAdditionalComment}</dd>
              </>
            )}
          </dl>
        </>
      )}

      {targetingFields && targetingFields.targetFocus && targetingFields.targetFocus.length > 0 && (
        <>
          <hr className="cart-summary__divider" />
          <a onClick={() => setCurrentStep(BRIEF_STEPS.TARGETING)} className="p p--xs tc--light cart-summary__edit">
            Edit
          </a>
          <div className="h6 cart-summary__heading">Targeting</div>
          <dl className="cart-summary__dlist mb-0">
            <dt>Ages</dt>
            <dd>{targetingFields.targetAgeGroups.join(', ')}</dd>
            <dt>Segments</dt>
            <dd>{targetingFields.targetSegments.join(', ')}</dd>
            <dt>Interests</dt>
            <dd>{targetingFields.targetInterests.join(', ')}</dd>
            <dt>Gender</dt>
            <dd>{targetingFields.targetFocus.join(', ')}</dd>
            <dt>Countries</dt>
            <dd>{targetingFields.targetCountries.join(', ')}</dd>
            <dt>Cities</dt>
            <dd>{targetingFields.targetCities.join(', ')}</dd>
            {targetingFields.targetDistricts.length > 0 && <dt>Districts</dt>}
            {targetingFields.targetDistricts.length > 0 && <dd>{targetingFields.targetDistricts.join(', ')}</dd>}
          </dl>
        </>
      )}

      {hasSample && servicesFields && lastFilledIndex >= serviceStepIndex && (
        <>
          <hr className="cart-summary__divider" />
          <a onClick={() => setCurrentStep(BRIEF_STEPS.SERVICES)} className="p p--xs tc--light cart-summary__edit">
            Edit
          </a>
          <div className="h6 cart-summary__heading">Services</div>
          <dl className="cart-summary__dlist mb-0">
            {onlySample && (
              <>
                <dt>Packaging</dt>
                <dd>{servicesFields.servicePackageType}</dd>
                <dt>Sample</dt>
                <dd>{servicesFields.serviceIsLiquid ? 'Yes' : 'No'}</dd>
                {servicesFields.serviceTemperature && (
                  <>
                    <dt>Temperature</dt>
                    <dd>{servicesFields.serviceTemperature}</dd>
                  </>
                )}
              </>
            )}
            {onlyInsert && (
              <>
                <dt>Coupon</dt>
                <dd>{servicesFields.serviceIncludingCoupon ? 'Yes' : 'No'}</dd>
                {servicesFields.serviceIncludingCoupon && (
                  <>
                    <dt>Design</dt>
                    <dd>{servicesFields.serviceDesign ? 'Yes' : 'No'}</dd>
                  </>
                )}
                <dt>Printing</dt>
                <dd>{servicesFields.servicePrinting ? 'Yes' : 'No'}</dd>
              </>
            )}
            {/* {onlySample && (
              <>
                <dt>Co-Packaging</dt>
                <dd>{servicesFields.servicePackaging ? 'Yes' : 'No'}</dd>
                {servicesFields.servicePackaging && (
                  <>
                    <dt>Description</dt>
                    <dd>{servicesFields.servicePackagingNeeds}</dd>
                    <dt>Quantity</dt>
                    <dd>{servicesFields.servicePackagingQuantity}</dd>
                  </>
                )}
                <dt>Free Sample</dt>
                <dd>{servicesFields.hasFreeSample ? 'Yes' : 'No'}</dd>
              </>
            )} */}
            {onlySample && (
              <>
                <dt>Printing</dt>
                <dd>{servicesFields.servicePrinting ? 'Yes' : 'No'}</dd>
                {servicesFields.servicePrinting && (
                  <>
                    <dt>Printing needs</dt>
                    <dd>
                      {briefSettings?.printingPrices?.filter(ele => ele.id == servicesFields.printingPriceId).length >
                        0 &&
                        briefSettings?.printingPrices?.filter(ele => ele.id == servicesFields.printingPriceId)[0].name}
                    </dd>
                  </>
                )}
              </>
            )}
          </dl>
        </>
      )}

      {hasMediaBuy && mediaBuyFields && mediaBuyFields.mediaStartDate && (
        <>
          <hr className="cart-summary__divider" />
          <a onClick={() => setCurrentStep(BRIEF_STEPS.MEDIA_BUY)} className="p p--xs tc--light cart-summary__edit">
            Edit
          </a>
          <div className="h6 cart-summary__heading">Media Buying</div>
          <dl className="cart-summary__dlist">
            <dt>Des. Start</dt>
            <dd>
              {mediaBuyFields.mediaStartDate &&
                moment(mediaBuyFields.mediaStartDate, FORMATS.DATE_LL_FORMAT).format(FORMATS.DATE_LL_FORMAT)}
            </dd>
            <dt>Mktg. Obj.</dt>
            <dd>{mediaBuyFields.marketingObjective}</dd>
            <dt>Duration</dt>
            <dd>{mediaBuyFields.mediaDuration}</dd>
            <dt>Types</dt>
            <dd>{mediaBuyFields.mediaTypes.join(', ')}</dd>
            <dt>Budget</dt>
            <dd>
              {getLocaleCost(mediaBuyFields.budgetValue)} {mediaBuyFields.budgetCurrency} ({mediaBuyFields.budgetType})
            </dd>
          </dl>
        </>
      )}

      {hasConsumerResearch && consumerResearchFields && consumerResearchFields.consumerResearchStartDate && (
        <>
          <hr className="cart-summary__divider" />
          <a
            onClick={() => setCurrentStep(BRIEF_STEPS.CONSUMER_RESEARCH)}
            className="p p--xs tc--light cart-summary__edit"
          >
            Edit
          </a>
          <div className="h6 cart-summary__heading">Consumer Research</div>
          <dl className="cart-summary__dlist">
            <dt>Start Date</dt>
            <dd>
              {consumerResearchFields.consumerResearchStartDate &&
                moment(consumerResearchFields.consumerResearchStartDate, FORMATS.DATE_LL_FORMAT).format(
                  FORMATS.DATE_LL_FORMAT
                )}
            </dd>
            {/* <dt>Duration</dt>
            <dd>{consumerResearchFields.consumerResearchDuration}</dd> */}
            <dt>Types</dt>
            <dd>{consumerResearchFields.consumerResearchMediaType.join(', ').replace(/CR /g, '')}</dd>
            <dt>Objective</dt>
            <dd>{consumerResearchFields.consumerResearchObjective}</dd>
          </dl>
        </>
      )}
      {/*<hr className="cart-summary__divider"/>
      <div className="cart-summary__reach">
        <p className="p p--sm mb-0 tc--light">Potential Audience Reach</p>
        <div className="h3 cart-summary__value">189,000,000</div>
      </div>*/}
    </div>
  )
}
