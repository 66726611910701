import React from 'react'
import { Row, Col } from 'antd'
import { IBriefResponse } from '../../testahelBox.types'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getFirstProductImage } from '../../testahelBox.selectors'
import { first } from 'lodash'
import { MEDIA_GROUP_TYPE } from '../../../../../constants/testahel_brief'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
  requestType?: MEDIA_GROUP_TYPE
  partnerQuantity?: number
}

const TestahelBriefProduct = ({ briefView, onEdit, viewMode, requestType, partnerQuantity }: Props) => {
  const firstImage = useSelector(getFirstProductImage)
  const productImage = briefView.productImages ? first(briefView.productImages) : firstImage
  return (
    <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>Product Brief</h4>
        {!viewMode && <span onClick={onEdit}>Edit</span>}
      </div>
      <div className="product-brief">
        <div className="qu-card-item-image mb-25">
          <Row>
            <div className="image pr-10">{productImage && <img src={productImage} alt="product" />}</div>
            <div className="description">
              <h5>{briefView.name}</h5>
              <p>{briefView.productType}</p>
            </div>
          </Row>
        </div>
        {(!requestType || requestType !== MEDIA_GROUP_TYPE.MEDIA_BUY) && (
          <Row gutter={16}>
            <Col span={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">Format</div>
                <div className="data-view-value">{briefView.distributionItem}</div>
              </div>
            </Col>
            <Col span={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">Quantity</div>
                <div className="data-view-value">
                  {Number.isInteger(partnerQuantity) ? partnerQuantity : briefView.quantity}
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">Desired Start Date</div>
                <div className="data-view-value">
                  {' '}
                  {briefView.briefStartDate ? moment(briefView.briefStartDate).format('LL') : 'None'}{' '}
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="data-view mb-15">
                <div className="data-view-label">Duration</div>
                <div className="data-view-value">{briefView.duration || 'None'}</div>
              </div>
            </Col>
            <Col span={24}>
              <div className="data-view mb-15">
                <div className="data-view-label">Instructions</div>
                <div className="data-view-value">{briefView.instructions || 'None'} </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default TestahelBriefProduct
