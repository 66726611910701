import React, { useEffect, useState } from 'react'
import { ClientSidebar } from 'quantum_components'

import { useDispatch, useSelector } from 'react-redux'
import { getForm, getPartnerPartnerPercent } from '../../profile.selectors'

import {
  AVAILABLE_SERVICES,
  AVAILABLE_SERVICES_VALUES,
  CUSTOMERS_AND_LOCALE,
  GENERAL_COMPANY_INFO,
  PARTNER_STEPS,
  SALES_INFORMATION,
} from '../../../../../constants/profile'
import { checkValue, DATA_BY_STEP_PARTNER_PROFILE, pickStepData } from 'src/helpers/profile'
import { omit, pick } from 'lodash'
import { PARTNER_STEPS_EDIT } from '../ProfilePartner.container'
import { actions } from '../../partnerProfile.module'

export interface IProps {
  currentStep: PARTNER_STEPS
}

const PROPS_BY_VALUES = {
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_DISTRIBUTION]]: [
    AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_PRICE],
  ],
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_DISTRIBUTION]]: [
    AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_PRICE],
  ],
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING]]: [
    AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING_TYPES],
  ],
}
const NAMES_BY_VALUES = {
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_DISTRIBUTION]]: AVAILABLE_SERVICES.SAMPLE_DISTRIBUTION,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_PRICE]]: AVAILABLE_SERVICES.SAMPLE_PRICE,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_DISTRIBUTION]]: AVAILABLE_SERVICES.INSERT_DISTRIBUTION,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_PRICE]]: AVAILABLE_SERVICES.INSERT_PRICE,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.WAREHOUSE_ADDRESS]]: AVAILABLE_SERVICES.WAREHOUSE_ADDRESS,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING]]: AVAILABLE_SERVICES.MEDIA_BUYING,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING_TYPES]]: AVAILABLE_SERVICES.MEDIA_BUYING_TYPES,
}

export default function PartnersProfileSidebar({ currentStep }: IProps) {
  const dispatch = useDispatch()
  const [unfilledSteps, setUnfilledSteps] = useState([''])
  const [filledSteps, setFilledSteps] = useState([''])

  const partnerForm = useSelector(getForm)
  const percent = useSelector(getPartnerPartnerPercent)

  const initialMetrics = {
    Facebook: '',
    Instagram: '',
    Twitter: '',
  }

  const getAvailableServiceFieldData = (): any => {
    const stepData = pick(partnerForm, Object.values(AVAILABLE_SERVICES_VALUES))
    let fieldToOmit = [] as string[]
    const checkedFields = Object.keys(stepData).reduce((acc, key) => {
      //@ts-ignore
      if (typeof stepData[key] === 'boolean' && !stepData[key] && key === 'isMediaBuy') {
        //@ts-ignore
        fieldToOmit.push(PROPS_BY_VALUES[key])
      }
      //@ts-ignore
      return { ...acc, [key]: stepData[key] }
    }, {})
    return omit(checkedFields, fieldToOmit)
  }

  const stepData =
    currentStep !== PARTNER_STEPS.AVAILABLE
      ? pickStepData({ ...partnerForm, metrics: { ...initialMetrics, ...partnerForm.metrics } }, currentStep)
      : getAvailableServiceFieldData()

  const description =
    'We encourage you to complete as much of your profile as possible.' +
    ' This greatly increases your chance of being selected as a partner and helps clients find your listing in our marketplace.'
  const title = 'Profile Completion'

  useEffect(() => {
    let newFilledSteps = [] as string[]
    let newUnfilledSteps = [] as string[]
    PARTNER_STEPS_EDIT.forEach(step => {
      if (
        Object.values(pick(partnerForm, Object.keys(DATA_BY_STEP_PARTNER_PROFILE[step]))).every(elem =>
          checkValue(elem, currentStep)
        )
      ) {
        newFilledSteps = [...newFilledSteps, step]
      } else if (
        Object.values(pick(partnerForm, Object.keys(DATA_BY_STEP_PARTNER_PROFILE[step]))).some(
          elem => !checkValue(elem, currentStep)
        )
      ) {
        newUnfilledSteps = [...newUnfilledSteps, step]
      }
    })
    setFilledSteps(newFilledSteps)
    setUnfilledSteps(newUnfilledSteps)
    dispatch(actions.setFilledList(newFilledSteps))
    dispatch(actions.setUnfinishedList(newUnfilledSteps))
  }, [partnerForm])
  const Steps: string[] = Object.values(PARTNER_STEPS)

  const titles: string[] = ['General Company Info', 'Sales Information', 'Customers & Locale', 'Available Services']
  const PropertiesOfSteps: string[][] = [
    Object.values(GENERAL_COMPANY_INFO),
    Object.values(SALES_INFORMATION),
    Object.values(CUSTOMERS_AND_LOCALE),
    Object.values(pick(NAMES_BY_VALUES, Object.keys(getAvailableServiceFieldData()))),
  ]

  return (
    <div className="partner-container-sidebar">
      <ClientSidebar
        titles={titles}
        unfinishedSteps={unfilledSteps}
        propertiesOfSteps={PropertiesOfSteps}
        currentStep={currentStep}
        title={title}
        description={description}
        //@ts-ignore
        filledPropertiesOfStep={Object.keys(stepData).map(e => checkValue(stepData[e], currentStep, e))}
        filledSteps={filledSteps}
        filledStepsPropertiesLength={0}
        completion={percent}
        steps={Steps}
      />
    </div>
  )
}
