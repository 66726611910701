import { useDispatch, useSelector } from 'react-redux'
import { getCurrency } from '../../modules/core/core.selectors'
import React, { Fragment, useState } from 'react'
import { CURRENCY } from '../../constants/currencies'
import * as actions from '../../modules/core/core.actions'
import { currencyOptions } from '../../helpers/currency'
import { QSelect } from 'quantum_components'
import DialogModal from '../DialogModal/DialogModal'
import SpinnerComponent from '../Spinner/SpinnerComponent'
import SpinnerCenter from '../Spinner/spinner'

interface IProps {
  handleChange?: () => Promise<void>
  size?: string
  hideDialog?: boolean
}

const CurrencyDropdown = ({ handleChange, size, hideDialog = false }: IProps) => {
  const dispatch = useDispatch()

  const currency = useSelector(getCurrency)
  const [currencyModal, setCurrencyModal] = useState<boolean>(false)
  const [newCurrency, setNewCurrency] = useState<CURRENCY | null>(null)

  const [loading, setLoading] = useState<boolean>(false)

  const handleCurrency = async (currency: CURRENCY) => {
    if (hideDialog) {
      await dispatch(actions.updateCurrency(currency))
      handleChange && (await handleChange())
      setLoading(true)
      window.location.reload()
    } else {
      setNewCurrency(currency)
      setCurrencyModal(true)
    }
  }

  const onProceedModal = async () => {
    setLoading(true)
    if (!newCurrency) return
    await dispatch(actions.updateCurrency(newCurrency))
    closeDialogModal()
    handleChange && (await handleChange())

    window.location.reload()
  }

  const closeDialogModal = () => {
    setCurrencyModal(false)
    setNewCurrency(null)
  }

  if (loading) {
    return <SpinnerCenter color="#ffffff" />
  }

  return (
    <Fragment>
      <QSelect
        value={currency}
        onChange={handleCurrency}
        options={currencyOptions}
        className="full-width"
        placeholder="Currency"
        size={size}
        showArrow
      />
      <DialogModal
        modalOpen={currencyModal}
        question={'Changing a currency will reload the page.'}
        description={'You may lose all unsaved data.'}
        submitButtonText={'Cancel'}
        cancelButtonText={'Confirm'}
        onClose={closeDialogModal}
        onProceed={onProceedModal}
        revert
      />
    </Fragment>
  )
}

export default CurrencyDropdown
