import { createStore, Store, applyMiddleware, compose, combineReducers, Reducer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { persistStore, persistReducer, Persistor } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  reducer as rootReducer,
  MODULE_NAME as CORE_MODULE_NAME,
  initialState as coreInitialState,
} from 'src/modules/core'

import { reducer as signInReducer, MODULE_NAME as SIGN_IN_MODULE_NAME } from 'src/modules/registration/signIn'
import { reducer as signUpReducer, MODULE_NAME as SIGN_UP_MODULE_NAME } from 'src/modules/registration/signUp'
import { reducer as briefReducer, MODULE_NAME as BRIEF_MODULE_NAME } from 'src/modules/brief'
import { reducer as briefV2Reducer, MODULE_NAME as BRIEF_V2_MODULE_NAME } from 'src/modules/briefV2'
import { reducer as briefV3Reducer, MODULE_NAME as BRIEF_V3_MODULE_NAME } from 'src/modules/briefV3'
import { reducer as partnerReducer, MODULE_NAME as PARTNER_MODULE_NAME } from 'src/modules/partner/partnerProfile'
import {
  reducer as publicProfileReducer,
  MODULE_NAME as PUBLIC_PROFILE_MODULE_NAME,
} from 'src/modules/partner/publicProfile'
import { reducer as clientReducer, MODULE_NAME as CLIENT_MODULE_NAME } from 'src/modules/client/clientProfile'
import { reducer as marketplaceReducer, MODULE_NAME as MARKETPLACE_MODULE_NAME } from 'src/modules/client/marketplace'
import { reducer as testahelBoxReducer, MODULE_NAME as TESTAHEL_BOX_MODULE_NAME } from 'src/modules/client/testahelBox'
import { reducer as requestReducer, MODULE_NAME as REQUEST_MODULE_NAME } from 'src/modules/partner/requests'
import { reducer as dashboardReducer, MODULE_NAME as DASHBOARD_MODULE_NAME } from 'src/modules/partner/dashboard'
import { reducer as notificationsReducer, MODULE_NAME as NOTIFICATIONS_MODULE_NAME } from 'src/modules/notifications'
import { reducer as campaignReducer, MODULE_NAME as CAMPAIGN_MODULE_NAME } from 'src/modules/campaign'
import { reducer as referralReducer, MODULE_NAME as REFERRAL_MODULE_NAME } from 'src/modules/client/referral'
import { reducer as updateEmailReducer, MODULE_NAME as UPDATE_EMAIL_MODULE_NAME } from 'src/modules/updateEmail'
import { reducer as teamMembersReducer, MODULE_NAME as PUBLIC_TEAM_MEMBERS_NAME } from 'src/modules/partner/teamMembers'
import { reducer as billReducer, MODULE_NAME as BILL_MODULE_NAME } from 'src/modules/client/bills'
import { reducer as billingsReducer, MODULE_NAME as BILLINGS_MODULE_NAME } from 'src/modules/billings'
import { reducer as announcementReducer, MODULE_NAME as ANNOUNCEMENTS_MODULE_NAME } from 'src/modules/client/announcement'
import { reducer as campaignv3Reducer, MODULE_NAME as CAMPAIGN_V3_MODULE_NAME } from 'src/modules/campaignV3'
import {
  reducer as invitingClientReducer,
  MODULE_NAME as PUBLIC_INVITE_CLIENT_NAME,
} from 'src/modules/partner/invitingClient'
import { reducer as partnerAPIReducer, MODULE_NAME as PUBLIC_PARTNER_API_NAME } from 'src/modules/partner/partnerAPI'
import {
  reducer as specialClientsReducer,
  MODULE_NAME as PUBLIC_SPECIAL_CLIENTS_NAME,
} from 'src/modules/specialClients'
import {
  reducer as deploymentDetailsReducer,
  MODULE_NAME as DEPLOYMENT_DETAILS_MODULE_NAME,
} from 'src/modules/deploymentDetails'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [],
}

const createRootReducer = (): Reducer => {
  return combineReducers({
    [CORE_MODULE_NAME]: rootReducer,
    [SIGN_IN_MODULE_NAME]: signInReducer,
    [SIGN_UP_MODULE_NAME]: signUpReducer,
    [BRIEF_MODULE_NAME]: briefReducer,
    [BRIEF_V2_MODULE_NAME]: briefV2Reducer,
    [BRIEF_V3_MODULE_NAME]: briefV3Reducer,
    [PARTNER_MODULE_NAME]: partnerReducer,
    [CLIENT_MODULE_NAME]: clientReducer,
    [PUBLIC_PROFILE_MODULE_NAME]: publicProfileReducer,
    [CLIENT_MODULE_NAME]: clientReducer,
    [MARKETPLACE_MODULE_NAME]: marketplaceReducer,
    [TESTAHEL_BOX_MODULE_NAME]: testahelBoxReducer,
    [REQUEST_MODULE_NAME]: requestReducer,
    [DASHBOARD_MODULE_NAME]: dashboardReducer,
    [NOTIFICATIONS_MODULE_NAME]: notificationsReducer,
    [CAMPAIGN_MODULE_NAME]: campaignReducer,
    [REFERRAL_MODULE_NAME]: referralReducer,
    [UPDATE_EMAIL_MODULE_NAME]: updateEmailReducer,
    [PUBLIC_TEAM_MEMBERS_NAME]: teamMembersReducer,
    [PUBLIC_INVITE_CLIENT_NAME]: invitingClientReducer,
    [PUBLIC_PARTNER_API_NAME]: partnerAPIReducer,
    [PUBLIC_SPECIAL_CLIENTS_NAME]: specialClientsReducer,
    [BILL_MODULE_NAME]: billReducer,
    [BILLINGS_MODULE_NAME]: billingsReducer,
    [ANNOUNCEMENTS_MODULE_NAME]: announcementReducer,
    [DEPLOYMENT_DETAILS_MODULE_NAME]: deploymentDetailsReducer,
    [CAMPAIGN_V3_MODULE_NAME]: campaignv3Reducer,
  })
}

const persistedReducer = persistReducer(persistConfig, createRootReducer())

export default (): { store: Store; persistor: Persistor } => {
  const composer: Function = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose
  const store: Store = createStore(
    persistedReducer,
    {
      [CORE_MODULE_NAME]: coreInitialState,
    },
    composer(applyMiddleware(thunk))
  )
  const persistor = persistStore(store)

  return { store, persistor }
}
