import { createSelector } from 'reselect'

import { IBillListElement, IBillTableParams, IState } from './bills.types'
import { MODULE_NAME } from './bills.constants'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getBillListLoading = createSelector(selectState, (state: IState): boolean => state.billListLoading)

export const getBillList = createSelector(selectState, (state: IState): IBillListElement[] => state.billList.results)

export const getTotalBillList = createSelector(selectState, (state: IState): number => state.billList.total)

export const getBillParams = createSelector(selectState, (state: IState): IBillTableParams => state.params)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)
