import { PATHS, ROLES } from '../../constants'
import getUserRole from '../../helpers/getUserRole'

const requireHome = async (to: any, from: any, next: any) => {
  const role = await getUserRole()
  const isClient = role === ROLES.CLIENT
  const isPartner = role === ROLES.PARTNER

  if (to.location.pathname === PATHS.ROOT) {
    if (isClient) next.redirect(PATHS.BRIEF)
    if (isPartner) next.redirect(PATHS.PARTNER_DASHBOARD)
  }
  next()
}

export default requireHome
