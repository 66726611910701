import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import {
  getBriefSettings,
  getCart,
  getPartnerDetails,
  getPartnerFilterSettings,
} from "../../brief.selectors";
import {
  Col,
  Form,
  QButton,
  QCheckbox,
  QRadio,
  QSelect,
  QSelectItem,
  Row,
} from "quantum_components";
import { Popover, Select } from "antd";
import {
  BANNERS_BY_MEDIA_TYPE,
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  GENDER,
  MEDIA_TYPES,
  MEDIA_TYPES_DURATION,
  TARGETING_TOGGLES,
} from "../../../../constants/brief";
import {
  IBrief,
  ICity,
  ICountry,
  IOptionForSelect,
  IPartnerDetailsService,
  ITargetingStep,
  ITargettingToggle,
  MediaSubtype,
} from "../../brief.types";
import { isMobile } from "react-device-detect";

import { ReactComponent as LogOut } from "src/assets/icons/logout-icon-alt.svg";
import PopoverSummary from "../components/Brief.PopoverSummary";
import { AgeGroupCard } from "../components/Brief.AgeGroupCard";
import { cloneDeep, first, snakeCase } from "lodash";
import {
  filterCountries,
  validateCountriesAndCities,
} from "../../../../helpers/validation";
import { LOCAL_STORAGE_KEYS, LOCALIZATION_LANGUAGES } from "src/constants";
import { useReduxDispatch } from "src/helpers";
import { actions } from "../../brief.module";
import { useParams } from "react-router-dom";
import { getPartnerProfileSettings } from "src/modules/partner/partnerProfile/profile.selectors";
import { getCurrency } from "src/modules/core/core.selectors";
import { getDuration } from "src/helpers/mediaTypes";
import {
  MEDIA_TYPES_NAMES_FOR_PRICE,
  PRICE_FIELDS_MEDIA_TYPES,
} from "src/modules/partner/partnerProfile/profile.types";

import { ReactComponent as HomeBanner } from "src/assets/icons/media-types/home-page-banners2.svg";
import { ReactComponent as BrandStore } from "src/assets/icons/media-types/brand-store2.svg";
import { ReactComponent as FreeDelivery } from "src/assets/icons/media-types/free-delivery2.svg";
import { ReactComponent as OnlineSurvey } from "src/assets/icons/media-types/online-survey2.svg";
import { ReactComponent as OohMediaPartnership } from "src/assets/icons/media-types/ooh-media-partnership2.svg";
import { ReactComponent as PopupWindow } from "src/assets/icons/media-types/popup-window2.svg";
import { ReactComponent as PriceOffPromo } from "src/assets/icons/media-types/price-off-promo2.svg";
import { ReactComponent as PushNotifications } from "src/assets/icons/media-types/push-notifications2.svg";
import { ReactComponent as Feed } from "src/assets/icons/media-types/feed2.svg";
import { ReactComponent as SkuUplifting } from "src/assets/icons/media-types/sku-uplifting2.svg";
import { ReactComponent as CashbackOffers } from "src/assets/icons/media-types/cashback-offers2.svg";
import { ReactComponent as CustomMedia } from "src/assets/icons/media-types/custom-media.svg";

interface TargetingErrors {
  gender: string;
  country: string;
  ageGroup: string;
}

interface IAgeGroup {
  value: string;
  label: string;
  sublabel: string;
  img: string;
}

type CheckboxFields = "gender" | "country" | "ageGroup";

interface IProps {
  brief: IBrief;
  targetingFields: ITargetingStep;
  onChange: (targetingFields: ITargetingStep) => void;
  handleTargetingServices?: (services: any[]) => void;
  onFinishLater: () => void;
  disabledFinishLater: boolean;
}

export default function TargetingForm({
  brief,
  targetingFields,
  onChange,
  handleTargetingServices,
  onFinishLater,
  disabledFinishLater,
}: IProps): ReactElement {
  const [newFields,setNewFields] = useState<any>({})

  useEffect(()=>{
    if(!targetingFields) return
    const updatedFields = {...targetingFields};

    const newCustomTargets = {}
    
    settings.targetingKeys.forEach((tk:any)=>{
      newCustomTargets[tk.name] = updatedFields.customTargets[tk.name] ?? undefined
    })

    setNewFields({...updatedFields,customTargets:newCustomTargets});
  },[targetingFields])


  const { i18n } = useTranslation();
  const dispatch = useReduxDispatch();
  const { id } = useParams<{ id: any }>();
  const { t } = useTranslation(["translation", "translationCommon"]);
  const cart = useSelector(getCart);
  const settings = useSelector(getBriefSettings);
  const partnerDetails = useSelector(getPartnerDetails);
  const filterSettings = useSelector(getPartnerFilterSettings);
  const partnerProfileSettings = useSelector(getPartnerProfileSettings);
  const mediaSubTypes = filterSettings.mediaSubtypes.length
    ? filterSettings.mediaSubtypes
    : partnerProfileSettings.mediaSubtypes;
  const currency = useSelector(getCurrency);

  const [form] = Form.useForm();
  const [errors, setErrors] = useState<TargetingErrors>({
    gender: "",
    country: "",
    ageGroup: "",
  } as TargetingErrors);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [cityError, setCityError] = useState<boolean>(false);

  const [showGender, setShowGender] = useState<boolean>(false);
  const [showAgeGroup, setShowAgeGroup] = useState<boolean>(false);
  const [showLocation, setShowLocation] = useState<boolean>(false);

  const [inviteClient, setInviteClient] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);

  const { Option, OptGroup } = Select;

  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {

    if(inviteClient){
      setShowOverlay(false)
      return
    }

    if(settings?.targetingKeys?.length > 0){
      setShowOverlay(false)
      return
    }

    setShowOverlay(true);


    // if (
    //   (!partnerDetails) ||
    //   settings?.targetingKeys?.length < 1
    // ) {
    //   console.log(partnerDetails,settings)
    //   setShowOverlay(true);
    // } else {
    //   setShowOverlay(false);
    // }
  }, [partnerDetails, settings, inviteClient]);

  useEffect(() => {
    if (cart?.additional?.items.length) {
      setSelectedServices(cart?.additional?.items[0].services);
    }
  }, [cart]);

  useEffect(() => {
    if (settings.targetingToggles.length > 0) {
      setShowGender(
        settings.targetingToggles.filter((item: ITargettingToggle) => {
          return item.control_name === TARGETING_TOGGLES.GENDER;
        })[0].control_toggle
      );
      setShowAgeGroup(
        settings.targetingToggles.filter((item: ITargettingToggle) => {
          return item.control_name === TARGETING_TOGGLES.AGE_GROUP;
        })[0].control_toggle
      );
      setShowLocation(
        settings.targetingToggles.filter((item: ITargettingToggle) => {
          return item.control_name === TARGETING_TOGGLES.LOCATION;
        })[0].control_toggle
      );
    }

    getAvailableServices();
  }, [settings]);

  useEffect(() => {
    form.setFieldsValue(targetingFields);
  });

  // useEffect(() => {
  //   const filtered = filterCountries(
  //     targetingFields.targetCountries,
  //     settings.countries
  //   );
  //   filtered.map((ele) => {
  //     ele.city[
  //       ele.city.findIndex((selectVal) => {
  //         return selectVal.id == -1;
  //       })
  //     ].country_id = -1;
  //     ele.city[
  //       ele.city.findIndex((selectVal) => {
  //         return selectVal.id == 0;
  //       })
  //     ].country_id = -1;
  //     if (
  //       ele.city.filter((val) =>
  //         targetingFields.targetCities.includes(val.name)
  //       ).length ==
  //       ele.city.length - 2
  //     ) {
  //       ele.city[
  //         ele.city.findIndex((selectVal) => {
  //           return selectVal.id == -1;
  //         })
  //       ].country_id = 0;
  //     } else {
  //       ele.city[
  //         ele.city.findIndex((selectVal) => {
  //           return selectVal.id == 0;
  //         })
  //       ].country_id = 0;
  //     }
  //   });
  //   setCountries(filtered);
  // }, [targetingFields, settings]);

  const getAvailableServices = async () => {
    const inviterID = await localStorage.getItem(
      LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID
    );
    const inviterToken = await localStorage.getItem(
      LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
    );
    if (inviterID && inviterToken) {
      setInviteClient(true);
      if (settings.partnerProfileId > 0) {
        setActivePartner(settings.partnerProfileId);
      }
    }
  };

  const setActivePartner = async (partnerId: number | null) => {
    if (!partnerId) return;
    await dispatch(actions.setActiveSinglePartner(partnerId));
    if (partnerId) {
      await dispatch(actions.fetchPartnerDetails(id, partnerId));
    }
    dispatch(actions.fetchPartnerFilterSettings());
  };

  const handleGender = (e: any) => {
    ReactGA.event({
      category: `Create/Edit Brief ${
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ? "- Invited Client"
          : ""
      } - Gender Focus`,
      action: e.target.id,
      label: e.target.id,
      value: 1,
    });
    const fields = { ...targetingFields };
    const { id } = e.target;
    fields.targetFocus = [id];
    validateCheckboxes(fields.targetFocus, "gender");
    onChange(fields);
  };

  const validateCheckboxes = (items: any[], fieldName: CheckboxFields) => {
    const name = fieldName === "ageGroup" ? "age group" : fieldName;
    const newErrors = { ...errors };
    const msg =
      items.length > 0 ? "" : `Should be at least one target ${name}.`;
    newErrors[fieldName] = msg;
    setErrors(newErrors);
  };

  const handleCountry = (e: any) => {
    const fields = { ...targetingFields };
    const { id } = e.target;

    const countries = fields.targetCountries;

    countries.includes(id)
      ? countries.splice(countries.indexOf(id), 1)
      : countries.push(id);

    const countriesObj = settings.countries.filter((c: ICountry) =>
      countries.includes(c.name)
    );
    if (!countriesObj.length) {
      fields.targetCities = [];
    }

    const selectedCountryCities = countriesObj
      .map((c: ICountry) => c.city.map((c: ICity) => c.name))
      .flat();
    fields.targetCities = fields.targetCities.filter((c: string) =>
      selectedCountryCities.includes(c)
    );

    validateCheckboxes(countries, "country");
    const isValid = validateCountriesAndCities(fields, settings.countries);
    setCityError(!isValid);

    onChange(fields);
  };

  const handleCityChange = (values: string[], cities: any[]) => {
    countries.forEach((country) => {
      if (
        cities
          .filter((val) => val.key === "0")
          .find((val) => val.country == country.name)
      ) {
        country.city.map((val) => {
          if (!values.includes(val.name)) {
            values.push(val.name);
          }
        });
      }

      if (
        cities
          .filter((val) => val.key === "-1")
          .find((val) => val.country == country.name)
      ) {
        country.city.map((val) => {
          if (values.includes(val.name)) {
            values.splice(values.indexOf(val.name), 1);
          }
        });
      }
    });

    values = values.filter(
      (val) =>
        val.search("Select All Cities") == -1 &&
        val.search("Deselect All Cities") == -1
    );
    const fields = { ...targetingFields };

    const countries1 = cities.map((c) => c.country);
    const uniqueCountries = countries1.filter((v, i, a) => a.indexOf(v) === i);

    fields.targetCities = values;
    fields.targetCountries = uniqueCountries;

    validateCheckboxes(fields.targetCountries, "country");
    const isValid = validateCountriesAndCities(fields, settings.countries);
    setCityError(!isValid);

    onChange(fields);
  };

  const handleAgeGroup = (e: any, name: string) => {
    const fields = { ...targetingFields };
    const items = fields.targetAgeGroups;
    !items.includes(name) &&
      ReactGA.event({
        category: `Create/Edit Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? "- Invited Client"
            : ""
        } - Age Groups`,
        action: name,
        label: name,
        value: 1,
      });
    items.includes(name)
      ? items.splice(items.indexOf(name), 1)
      : items.push(name);
    validateCheckboxes(items, "ageGroup");

    onChange(fields);
  };

  const handleSegmentsChange = (values: string[]) => {
    const fields = { ...targetingFields };
    let difference = values.filter((x) => !fields.targetSegments.includes(x));
    difference.length > 0 &&
      ReactGA.event({
        category: `Create/Edit Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? "- Invited Client"
            : ""
        } - Segments`,
        action: difference[0],
        label: difference[0],
        value: 1,
      });
    fields.targetSegments = values;
    onChange(fields);
  };

  const segments: IOptionForSelect[] = settings.targetSegments.map(
    (type: any) => {
      return { id: "targetSegments", label: type.name, value: type.name };
    }
  );

  const ageGroups = () => {
    const reducer = (s: any) => {
      const value = s.from + (s.to ? "-" + s.to + " " + s.name : "+ " + s.name);
      const sublabel = s.from + (s.to ? "-" + s.to : "+");
      const label =
        i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? s.name_ar : s.name;
      const img = s.src;

      return { label, sublabel, value, img } as IAgeGroup;
    };

    return settings.ageGroups.map((group: any) => reducer(group));
  };

  const getPriceField = (service: IPartnerDetailsService) => {
    // @ts-ignore
    const field = MEDIA_TYPES_NAMES_FOR_PRICE[service.serviceName];
    if (field) return field;

    if (!field) {
      if (service.serviceDuration) return PRICE_FIELDS_MEDIA_TYPES.DURATION;
      if (service.servicePriceForPush) return PRICE_FIELDS_MEDIA_TYPES.PUSH;
      if (service.servicePriceForActivity)
        return PRICE_FIELDS_MEDIA_TYPES.ACTIVITY;
      return PRICE_FIELDS_MEDIA_TYPES.DURATION;
    }
  };

  const handleServices = async (e: any, record: any) => {
    const index = selectedServices.findIndex(
      (ele: any) =>
        ele.name === record.serviceSubName &&
        ele.type ===
          mediaSubTypes.find(
            (type: MediaSubtype) => type.name === record.serviceName
          ).subtype
    );
    if (index !== -1) {
      const filteredServices: any = cloneDeep(selectedServices);
      filteredServices.splice(index, 1);
      handleTargetingServices(filteredServices);
      setSelectedServices(filteredServices);
    } else {
      const duration = getDurationForAllMediaTypes(record);
      const subType = mediaSubTypes.find(
        (type: MediaSubtype) => type.name === record.serviceName
      );
      const priceField = getPriceField(record);
      const price = record[priceField];
      const data: any = {
        name: record.serviceSubName,
        type: subType ? subType.subtype : "",
        amount: price,
        currency: currency,
        quantity: 0,
        total: 0,
        duration: duration,
      };
      handleTargetingServices([...selectedServices, data]);
      setSelectedServices((oldArray) => [...oldArray, data]);
    }
  };

  const getDurationForAllMediaTypes = (service: IPartnerDetailsService) => {
    // @ts-ignore
    let duration = getDuration(service);
    if (duration) return duration;

    if (!duration) {
      if (service.servicePriceForPush) return MEDIA_TYPES_DURATION.PER_PUSH;
      if (service.servicePriceForActivity)
        return MEDIA_TYPES_DURATION.PER_ACTIVITY;
      return MEDIA_TYPES_DURATION.PER_ACTIVITY;
    }
  };

  const MEDIA_TYPES_IMAGE: { [key: string]: ReactElement } = {
    [MEDIA_TYPES.HOME_BANNERS]: <HomeBanner />,
    [MEDIA_TYPES.BRAND_STORE]: <BrandStore />,
    [MEDIA_TYPES.FREE_DELIVERY]: <FreeDelivery />,
    [MEDIA_TYPES.SURVEY]: <OnlineSurvey />,
    [MEDIA_TYPES.SOCIAL_MEDIA]: <OohMediaPartnership />,
    [MEDIA_TYPES.POPUP_WINDOW]: <PopupWindow />,
    [MEDIA_TYPES.PRICE_OFF_PROMO]: <PriceOffPromo />,
    [MEDIA_TYPES.PUSH_NOTIFICATIONS]: <PushNotifications />,
    [MEDIA_TYPES.FEED]: <Feed />,
    [MEDIA_TYPES.SKU]: <SkuUplifting />,
    [MEDIA_TYPES.CASHBACK]: <CashbackOffers />,
    [MEDIA_TYPES.CUSTOM_MEDIA_SERVICE]: <CustomMedia />,
  };

  const getCardImage = (name: string) => {
    return MEDIA_TYPES_IMAGE[name]
      ? MEDIA_TYPES_IMAGE[name]
      : MEDIA_TYPES_IMAGE[MEDIA_TYPES.CUSTOM_MEDIA_SERVICE];
  };

  // const showOverlay = inviteClient ? (!showGender && !showAgeGroup && !showLocation && !partnerDetails) : (!showGender && !showAgeGroup && !showLocation)
  const overlay = () => {
    return (
      <>
        <div className="overlay-container">
          <div className="overlay-content">
            <QButton
              className="qu-button-outline"
              disabled={false}
              onClick={() => {
                return;
              }}
              type="primary"
            >
              {t("brief.edit.step3.coming_soon")}
            </QButton>
          </div>
        </div>
      </>
    );
  };

  const handleSelectChange = (values: string[], key: string) => {
    
    let updatedValues = [];
    if(values.includes('Select All') && values.includes('Un-select All')){
      updatedValues = values.filter(v=>v!=='Select All' && v!=='Un-select All')
    }else if(values.includes('Select All')){
      updatedValues = settings.targetingKeys.filter((a:any)=>a.name===key)[0].options
    }
    else if(values.includes('Un-select All')){
      updatedValues = []
    }else{
      updatedValues = values
    }
    
    const customTargets: any = {
      ...newFields.customTargets,
      [key]: updatedValues,
    };
    const fields = { ...newFields, customTargets};
    
    onChange(fields);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      // initialValues={targetingFields}
    >
      <div className={`form form-brief form--horizontal`}>
        <div className="form__head">
          <div className="form__number">
            {isMobile ? (
              <span>{`2. ${t(BRIEF_STEPS_NAMES[BRIEF_STEPS.TARGETING])}`}</span>
            ) : (
              "2"
            )}
          </div>
          <h3>{t("brief.edit.step3.who_do_you_want_to_target")}</h3>
        </div>
        <PopoverSummary brief={brief} />
        <div className="form__cont position_relative">
          {showOverlay && overlay()}

          {settings.targetingKeys.map((targetingKey: any) => {
            return (
              <>
                <Row gutter={16} align="middle" key={targetingKey.name}>
                  <Col xs={24} sm={24} md={10}>
                    <label htmlFor={targetingKey.name} className="form-label">
                      {targetingKey.name}
                    </label>
                  </Col>
                  <Col xs={24} sm={24} md={14}>
                    <QSelect
                        className="w-100-percent"
                        // @ts-ignore
                        value={
                          targetingFields?.customTargets &&
                          targetingFields?.customTargets[targetingKey.name]
                        }
                        onChange={(values: string[]) =>
                          handleSelectChange(values, targetingKey.name)
                        }
                        showSearch
                        showArrow
                        maxTagTextLength={14}
                        maxTagCount={2}
                        mode="multiple"
                        size="large"
                        placeholder={`select ${targetingKey.name}`}
                        options={[{
                          id: 'select_all',
                          label: t('Select All'),
                          value: 'Select All',
                        },{
                          id: 'un_select_all',
                          label: t('Un-select All'),
                          value: 'Un-select All',
                        },...targetingKey?.options?.map((option: any) => {
                          return {
                            id: option,
                            label: option,
                            value: option,
                          };
                        })]}
                      />
                    {/* <Form.Item className="mb-0" name={targetingKey.name}>
                      
                    </Form.Item> */}
                  </Col>
                </Row>
                <hr className="form__hr" />
              </>
            );
          })}

          {/* {(showGender || showOverlay) && (
            <>
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="targetFocus" className="form-label">
                    {t('brief.edit.step3.gender_focus')}
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Row gutter={16} align="middle">
                    <Col flex="auto">
                      <Form.Item className="mb-0" name={GENDER.FEMALE}>
                        <QRadio
                          className="mr-0"
                          onChange={handleGender}
                          checked={
                            first(targetingFields.targetFocus) === GENDER.FEMALE
                          }
                        >
                          {t('brief.targeting.gender.' + snakeCase(GENDER.FEMALE), { ns: 'translationCommon' })}
                        </QRadio>
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item className="mb-0" name={GENDER.MALE}>
                        <QRadio
                          className="mr-0"
                          onChange={handleGender}
                          checked={
                            first(targetingFields.targetFocus) === GENDER.MALE
                          }
                        >
                          {t('brief.targeting.gender.' + snakeCase(GENDER.MALE), { ns: 'translationCommon' })}
                        </QRadio>
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item className="mb-0" name={GENDER.BOTH}>
                        <QRadio
                          className="mr-0"
                          onChange={handleGender}
                          checked={
                            first(targetingFields.targetFocus) === GENDER.BOTH
                          }
                        >
                          {t('brief.targeting.gender.' + snakeCase(GENDER.BOTH), { ns: 'translationCommon' })}
                        </QRadio>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr className="form__hr" />
            </>
          )} */}

          {/* {(showAgeGroup || showOverlay) && (
            <>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="ageGroups" className="form-label">
                    {t('brief.edit.step3.age_groups')}
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Row gutter={16} justify={"center"} align="middle" className="targeting-age-group">
                    {ageGroups().map((group: IAgeGroup) => {
                      const isChecked = targetingFields.targetAgeGroups.includes(group.value);
                      return (
                        <AgeGroupCard
                          key={group.value}
                          checked={isChecked}
                          label={group.label}
                          sublabel={group.sublabel}
                          img={group.img}
                          onChange={(e: any) => handleAgeGroup(e, group.value)}
                        />
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <hr className="form__hr" />
            </>
          )} */}

          {/* <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="targetSegments" className="form-label">
                * Segments
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="targetSegments"
                rules={[
                  {
                    required: true,
                    message: 'Please choose at least one target segment.',
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <QSelect
                  value={targetingFields.targetSegments}
                  onChange={handleSegmentsChange}
                  showSearch
                  showArrow
                  maxTagTextLength={14}
                  maxTagCount={2}
                  mode="multiple"
                  size="large"
                  placeholder="Select Segments"
                  options={segments}
                  filterOption={(input: string, option: IOptionForSelect) =>
                    option.label.toLowerCase().includes(input.toLocaleLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row> 
          <hr className="form__hr" /> */}

          {/* {(showLocation || showOverlay) && (
            <>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="targetFocus" className="form-label mt-5">
                    {t('brief.edit.step3.countries')}
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Row gutter={16} align="middle">
                    {settings.countries.map((country: ICountry) => {
                      return (
                        country.name === "Saudi Arabia" && (
                          <Col key={country.id} xs={24}>
                            <Form.Item className="mb-0" name={country.name}>
                              <QCheckbox
                                checked={targetingFields.targetCountries.includes(
                                  country.name
                                )}
                                onChange={handleCountry}
                                className="test-class"
                              >
                                {`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && country.name_ar ? country.name_ar : country.name}`}
                              </QCheckbox>
                            </Form.Item>
                          </Col>
                        )
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <hr className="form__hr" />
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="targetSegments" className="form-label">
                    {t('brief.edit.step3.cities')}
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Form.Item className="mb-0" name="targetCities">
                    <QSelect
                      value={targetingFields.targetCities}
                      onChange={handleCityChange}
                      showSearch
                      showArrow
                      mode="multiple"
                      size="large"
                      placeholder={t('brief.edit.step3.select_cities')}
                    >
                      {countries.map((country: ICountry) => {
                        return (
                          <OptGroup key={country.id} label={`${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && country.name_ar ? country.name_ar : country.name}`}>
                            {country.city.map((city: ICity) => {
                              return (
                                city.country_id >= 0 && (
                                  <Option
                                    key={city.id}
                                    country={country.name}
                                    value={city.name}
                                  >
                                    {city.id > 0 ? `${i18n.language === LOCALIZATION_LANGUAGES.ARABIC && city.name_ar ? city.name_ar : city.name}` : t('brief.edit.step3.' + city.name)}
                                  </Option>
                                )
                              );
                            })}
                          </OptGroup>
                        );
                      })}
                    </QSelect>
                  </Form.Item>
                  {cityError && (
                    <div className="text-red font-size-12 mt-5 mb-10">
                      {t('brief.edit.step3.choose_city')}
                    </div>
                  )}
                </Col>
              </Row>
              <hr className="form__hr" />
            </>
          )} */}

          {(partnerDetails || showOverlay) && inviteClient && (
            <Row gutter={16} align="middle" className="invite-flow-services">
              <Col xs={24} sm={24} md={10}>
                <label htmlFor="targetSegments" className="form-label">
                  {t("brief.summary.Services")}
                </label>
              </Col>
              <Col xs={24} sm={24} md={14}>
                <Row gutter={16} justify="center" align="middle">
                  {partnerDetails?.availableServices.map(
                    (record: any, index: number) => (
                      <Popover
                        key={index}
                        overlayClassName="qu-banner-popover"
                        content={
                          <div>
                            <div className="banner-popover__image">
                              {record?.serviceImageUrl ? (
                                <img
                                  src={record?.serviceImageUrl}
                                  alt={record.serviceSubName}
                                />
                              ) : (
                                getCardImage(record.serviceName)
                              )}
                            </div>
                            <div className="mt--100 p fw-500">
                              {t(
                                "services.media-buy.types." +
                                  snakeCase(
                                    record.serviceSubName || record.serviceName
                                  ),
                                record.serviceSubName || record.serviceName,
                                { ns: "translation" }
                              )}
                            </div>
                            <div className="p p--sm tc--light">
                              {record.serviceDescription}
                            </div>
                          </div>
                        }
                        placement="rightTop"
                        trigger="hover"
                        getPopupContainer={(node: any) => node.parentNode}
                      >
                        <Col xs={8} key={record.serviceSubName}>
                          <QSelectItem
                            className={"media-card mb-16"}
                            label={record.serviceSubName || record.serviceName}
                            checked={
                              selectedServices.length &&
                              mediaSubTypes.length &&
                              selectedServices.find(
                                (e: any) =>
                                  e.name === record.serviceSubName &&
                                  e.type ===
                                    mediaSubTypes.find(
                                      (type: MediaSubtype) =>
                                        type.name === record.serviceName
                                    ).subtype
                              )
                            }
                            cardImage={
                              record?.serviceImageUrl ? (
                                <img
                                  src={record?.serviceImageUrl}
                                  alt={record.serviceSubName}
                                />
                              ) : (
                                getCardImage(record.serviceName)
                              )
                            }
                            onChange={(e: MouseEvent) =>
                              handleServices(e, record)
                            }
                          />
                        </Col>
                      </Popover>
                    )
                  )}
                </Row>
              </Col>
            </Row>
          )}
          {isMobile && (
            <div className="form-brief-footer">
              <QButton
                disabled={disabledFinishLater}
                onClick={onFinishLater}
                className="qu-button-outline mt-30 full-width"
              >
                {t("brief.edit.common.save_and_finish_later")}{" "}
                <LogOut className="ml-10" />
              </QButton>
            </div>
          )}
        </div>
      </div>
    </Form>
  );
}
