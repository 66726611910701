import { Col, Divider, Row, Tag } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash, { includes } from "lodash";
import React, { useEffect, useState } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import { Modal, QButton, QInput, QSelect } from "quantum_components";
import { useTranslation } from "react-i18next";

const V3Logic = ({
  briefSettings,
  state,
  setState,
  arrayIndex,
  canEdit,
  isLoading,
  setIsLoading,
}: any) => {
  const { t } = useTranslation(["translationV2"]);
  const [showModal, setShowModal] = useState(false);

  const [selectedKey, setSelectedKey] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  let targetingLogic = lodash.isEmpty(state[arrayIndex]["targetingLogic"])
    ? []
    : state[arrayIndex]["targetingLogic"];
  console.log("tx", targetingLogic);

  //   const [logic, setLogic] = useState<any>(lodash.isEmpty(state[arrayIndex]['targetingLogic']) ? [
  //     {
  //       type: "group",
  //       value: "$and",
  //       items: [],
  //     },
  //   ]:state[arrayIndex]['targetingLogic']);

  const [keys, setKeys] = useState([]);
  const [actions, setActions] = useState([
    {
      label: t("is equal to"),
      value: "$eq",
    },
    {
      label: t("is not equal to"),
      value: "$ne",
    },
    {
      label: t("contains"),
      value: "$regex",
    },
  ]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (lodash.has(briefSettings, "MINIMUM_DISTRIBUTION_LOGISTIC_COUNT")) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setKeys(
        briefSettings?.targetingKeys?.map((k: any) => {
          return { label: k.name, value: k.name };
        })
      );
    }
  }, [briefSettings]);

  const handleKeyChange = (key: any) => {
    setSelectedKey(key);
    const values = briefSettings?.targetingKeys.find((k: any) => k.name === key)
      .options;
    setValues(
      values.map((v: any) => {
        return { label: v, value: v };
      })
    );
  };

  const handleAddRule = () => {
    if (!selectedKey || !selectedAction || !selectedValue) return;

    console.log(state[arrayIndex]["targetingLogic"])
    // check if there is an existing object in the array
    const existingLogic = [...state[arrayIndex]["targetingLogic"]];
    const totalObjectsWithinArray = existingLogic.length;
    if (totalObjectsWithinArray < 1) {
      existingLogic.push({
        type: "group",
        value: "$or",
        items: [],
      });
    }

    const existingItems = existingLogic[existingLogic.length - 1]["items"];
    existingItems.push({
      type: "rule",
      key: selectedKey,
      action: selectedAction,
      value: selectedValue,
    });

    let ss = state.map((s: any, index: any) => {
      if (index === arrayIndex) {
        s.targetingLogic = existingLogic;
      }
      return s;
    });
    setState(ss);

    setSelectedKey("");
    setSelectedAction("");
    setSelectedValue("");
    setValues([]);
  };

  const handleAndGroup = () => {
    const existingLogic = [...state[arrayIndex]["targetingLogic"]];
    existingLogic.push({
      type: "group",
      value: "$and",
      items: [],
    });

    let ss = state.map((s: any, index: any) => {
      if (index === arrayIndex) {
        s.targetingLogic = existingLogic;
      }
      return s;
    });
    setState(ss);

    setSelectedKey("");
    setSelectedAction("");
    setSelectedValue("");
    setValues([]);
  };

  const handleOrGroup = () => {
    const logic = [...state[arrayIndex]["targetingLogic"]];
    if (logic.length < 1 || logic[logic.length - 1]["items"]?.length < 1)
      return;
      
    const existingLogic = [...state[arrayIndex]["targetingLogic"]];
    existingLogic.push({
      type: "group",
      value: "$or",
      items: [],
    });

    let ss = state.map((s: any, index: any) => {
      if (index === arrayIndex) {
        s.targetingLogic = existingLogic;
      }
      return s;
    });
    setState(ss);

    setSelectedKey("");
    setSelectedAction("");
    setSelectedValue("");
    setValues([]);
  };

  const handleLogicRemove = (index: number, itemIndex: number) => {
    const log = [...state[arrayIndex]["targetingLogic"]];

    // pull at specified position
    let arr = [...log[index]["items"]];
    lodash.pullAt(arr, [itemIndex]);
    log[index]["items"] = arr;

    // check if after pull there are elements, otherwise remove the parent group as well
    if (arr.length < 1) {
      lodash.pullAt(log, [index]);
    }
    let ss = state.map((s: any, index: any) => {
      if (index === arrayIndex) {
        s.targetingLogic = log;
      }
      return s;
    });
    setState(ss);
  };

  const handleSaveLogic = () => {
    // check the last key in logic and see if it has items
    const logic = [...state[arrayIndex]["targetingLogic"]];
    if (logic.length < 1 || logic[logic.length - 1]["items"]?.length < 1)
      return;

    // const query: any = {};

    // logic?.forEach((row: any, index: number) => {
    //   let entries = row?.items?.map((item: any, itemIndex: number) => {
    //     return {
    //       [item.key]: {
    //         [item.action]: item.value,
    //       },
    //     };
    //   });

    //   if (query[row?.value]?.length > 0) {
    //     entries = [...query[row?.value], ...entries];
    //   }
    //   query[row?.value] = entries;
    // });

    const ss = [...state];
    ss[arrayIndex]["targetingLogic"] = logic;
    setState(ss);

    setSelectedKey("");
    setSelectedAction("");
    setSelectedValue("");
    // setLogic([]);
    setShowModal(false);
  };

  const handleGroupRemove = (index: number) => {
    const log = [...state[arrayIndex]["targetingLogic"]];
    lodash.pullAt(log, [index]);
    const ss = [...state];
    ss[arrayIndex]["targetingLogic"] = log;
    setState(ss);
  };

  const defineAction = (action: string) => {
    if (action === "$eq") return t("is equal to");
    if (action === "$ne") return t("is not equal to");
    if (action === "$regex") return t("contains");
  };

  return (
    <div>
      {(lodash.isEmpty(state[arrayIndex]?.targetingLogic) ||
        Object.values(state[arrayIndex]?.targetingLogic).every((o) =>
          lodash.isEmpty(o)
        )) && (
        <div>
          {t("No Logic Yet")}{" "}
          <a onClick={() => canEdit && setShowModal(true)}>{t("Add Now.")}</a>
        </div>
      )}

      <div>
        {!lodash.isEmpty(targetingLogic) && (
          <Tag
            className="mb-5 tag-preview"
            style={{ whiteSpace: "pre-wrap" }}
            onClick={() => setShowModal(true)}
          >
            {canEdit ? t("Edit Logic") : t("View Logic")}
          </Tag>
        )}
      </div>

      <Modal
        wrapClassName="qu-modal"
        visible={showModal}
        footer={false}
        closable={true}
        width={675}
        onCancel={(e) => {
          e.stopPropagation();

          handleSaveLogic();

          setSelectedKey("");
          setSelectedAction("");
          setSelectedValue("");
          setShowModal(false);
        }}
      >
        <div className="v3-section">
          <h3 className="mb-20">{t("Edit Logic")}</h3>

          {/* Control */}
          <div>

            <Row
              align={"middle"}
              justify={"center"}
              gutter={24}
              className="v3-targeting"
            >
              <Col sm={6} xs={24}>
                <label>{t("Select Key")}</label>
                <QSelect
                  // disabled={targetingLogic.length < 1}
                  className="full-width "
                  showArrow
                  placeholder={t("Please select")}
                  options={keys}
                  value={selectedKey}
                  onChange={handleKeyChange}
                  size="large"
                  disabled={!canEdit}
                />
              </Col>
              <Col sm={6} xs={24}>
                <label>{t("Select Action")}</label>
                <QSelect
                  // disabled={targetingLogic.length < 1}
                  className="full-width "
                  showArrow
                  placeholder={t("Please select")}
                  options={actions}
                  value={selectedAction}
                  onChange={(action: string) => setSelectedAction(action)}
                  size="large"
                  disabled={!canEdit}
                />
              </Col>
              <Col sm={6} xs={24}>
                <label>{t("Select Value")}</label>
                <QSelect
                  // disabled={targetingLogic.length < 1}
                  className="full-width "
                  showArrow
                  placeholder={t("Please select")}
                  options={values}
                  value={selectedValue}
                  onChange={(value: string) => setSelectedValue(value)}
                  size="large"
                  disabled={!canEdit}
                />
              </Col>
              <Col sm={6} xs={24}>
                <QButton
                  // disabled={targetingLogic.length < 1}
                  size="small"
                  type="primary"
                  htmlType="button"
                  className="mt-20  h-40 full-width qu-button-soft"
                  onClick={handleAddRule}
                  disabled={!canEdit}
                >
                  {t("Add Rule")}
                </QButton>
              </Col>
            </Row>
            <Row align={"middle"} justify={"start"} className={"mt-20"}>
              <Col>{t("Or")}</Col>
              <Col className="pl-10">
              <QButton
                size="small"
                type="primary"
                htmlType="button"
                className="qu-button qu-button-soft"
                onClick={handleOrGroup}
                disabled={!canEdit}
              >
                {t("Add Group")}
              </QButton>
              </Col>
              
            </Row>
          </div>

          {/* View */}
          <Divider></Divider>
          {!lodash.isEmpty(targetingLogic) && (
            <h3 className="mb-20">{t("Preview")}</h3>
          )}
          {!lodash.isEmpty(targetingLogic) &&
            targetingLogic.map((logic: any, index: number) => {
              return (
                <div key={index} className={"mt-10 mb-5"}>
                  {
                    index != 0 && <div className="text-blue mb-5 text-uppercase">
                      <a
                        className="text-red"
                        onClick={() => canEdit && handleGroupRemove(index)}
                      >
                        X
                      </a>
                      &nbsp;{t(logic.value.split("$"))}
                    </div>
                  }
                  <div>
                    {
                      logic.items.length == 0 && <div>{t("No rules added in this group yet")}</div>
                    }
                    {logic.items.map((item: any, itemsIndex: number) => {
                      return (
                        <div
                          style={{
                            padding: "10px",
                            background: "#f7dfd654",
                          }}
                        >
                          <Row
                            gutter={24}
                            key={itemsIndex + 99}
                            align={"middle"}
                            className={"mb-5"}
                          >
                            <Col md={7} xs={24}>
                              <QInput
                                value={item.key}
                                size="middle"
                                disabled={true}
                              />{" "}
                            </Col>
                            <Col md={7} xs={24}>
                              <QInput
                                value={defineAction(item.action)}
                                size="middle"
                                disabled={true}
                              />{" "}
                            </Col>
                            <Col md={7} xs={24}>
                              <QInput
                                value={item.value}
                                size="middle"
                                disabled={true}
                              />{" "}
                            </Col>
                            <Col md={3} xs={24}>
                              <QButton
                                htmlType="button"
                                size="small"
                                className="qu-button-soft full-width"
                                onClick={() =>
                                  handleLogicRemove(index, itemsIndex)
                                }
                                disabled={!canEdit}
                              >
                                {t("del")}
                              </QButton>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          <div className="mt-30">
            <QButton
              htmlType="button"
              type="primary"
              size="middle"
              className="mx-auto"
              onClick={() => handleSaveLogic()}
              disabled={!canEdit}
            >
              {t("Save Logic")}
            </QButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(V3Logic);
