import IAction from '../../../interfaces/IAction'
import * as CONSTANTS from './profile.constants'
import { PARTNER_STEPS, PRICING_MODEL } from '../../../constants/profile'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { requestHttp, urls } from '../../../api'
import { getResponseErrorMessage } from '../../../helpers'
import { fetchPublicProfileFailure, fetchPublicProfileSuccess } from '../publicProfile/publicProfile.actions'
import { IForm, IPartnerProfileResponse } from './profile.types'
import { PARTNER_UPLOAD_TYPES } from 'src/constants/partner'
import { fetchCurrencySuccess, upload } from '../../core/core.actions'
import { UPLOAD_ENTITY_KEYS } from 'src/constants/upload'
import { getForm } from './profile.selectors'
import { cloneDeep } from 'lodash'
import { MEDIA_TYPES } from 'src/constants/brief'
import { AxiosResponse } from 'axios'
import { calculateProfilePercent } from 'src/helpers/profile'
import { STATUS_CODES } from 'src/constants'

export interface Step {
  name: string
  status: string
}
export const setCurrentStep = (step: PARTNER_STEPS): IAction => ({
  type: CONSTANTS.SET_CURRENT_STEP,
  payload: { step },
})

export const setUnfinishedList = (step: string[]): IAction => ({
  type: CONSTANTS.SET_UNFINISHED,
  payload: { step },
})

export const setLastFilled = (step: PARTNER_STEPS): IAction => ({
  type: CONSTANTS.SET_LAST_FINISHED,
  payload: { step },
})

export const setFilledList = (step: string[]): IAction => ({
  type: CONSTANTS.SET_FILLED,
  payload: { step },
})

export const setPartnerSettings = (settings: boolean[]): IAction => ({
  type: CONSTANTS.SET_PARTNER_SETTING,
  payload: { settings },
})

export const fetchPartnerProfileLoading = (loading: boolean): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_PROFILE_LOADING,
  payload: { loading },
})

export const fetchPartnerProfileSave =
  (payload: any): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.patch(urls.getPartnerProfileSaveUpdatesUrl(), {
        ...payload,
        impressionRate: payload.mbPricingModel === PRICING_MODEL.DURATION ? null : payload.impressionRate,
        conversionRate: payload.mbPricingModel === PRICING_MODEL.DURATION ? null : payload.conversionRate,
        reachRate: payload.mbPricingModel === PRICING_MODEL.DURATION ? null : payload.reachRate,
        vat: payload.vat ? payload.vat : null,
      })
      const { content } = response.data.data
      dispatch(fetchPartnerProfileSuccess(content))
      dispatch(fetchCurrencySuccess(content.currency))
    } catch (error: any) {
      dispatch(fetchPublicProfileFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchPartnerProfileSettings =
  (): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.get(`${urls.getPartnerSettingsUrl()}`)
      response.data.data.content.countries.map((ele: any) => {
        ele.city.unshift(
          {
            id: 0,
            name: `Select All ${ele.name} Cities`,
            name_ar: `Select All ${ele.name} Cities`,
            country_id: 0,
          },
          {
            id: -1,
            name: `Deselect All ${ele.name} Cities`,
            name_ar: `Deselect All ${ele.name} Cities`,
            country_id: 0,
          }
        )
      })
      const { content } = response.data.data
      dispatch(fetchAgeGroups(content))
    } catch (error: any) {
      dispatch(fetchPublicProfileFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchAgeGroups =
  (settings: any): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.get(urls.getAgeGroupsUrl())
      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data
        settings.ageGroups = content
        dispatch(fetchTargetSegments(settings))
      }
    } catch (error: any) {
      dispatch(fetchPublicProfileFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchTargetSegments =
  (settings: any): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.get(urls.getTargetSegmentsUrl())
      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data
        settings.targetSegments = content
        dispatch(setPartnerSettings(settings))
      }
    } catch (error: any) {
      dispatch(fetchPublicProfileFailure(getResponseErrorMessage(error)))
    }
  }

export const setForm = (form: {}): IAction => ({
  type: CONSTANTS.SET_FORM,
  payload: { form },
})

export const fetchPartnerProfile =
  (): ThunkAction<Promise<AxiosResponse<IPartnerProfileResponse>>, {}, {}, AnyAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: any
  ): Promise<AxiosResponse<IPartnerProfileResponse>> => {
    try {
      dispatch(fetchPartnerProfileLoading(true))
      const response = await requestHttp.get(`${urls.getPartnerProfileUrl()}`)
      const { content } = response.data.data
      dispatch(fetchPartnerProfileSuccess(content))
      const {
        listingShortDescription,
        companyLogo,
        previewCardImage,
        listingHeaderImage,
        highlights,
        metricsMarketing,
      } = content.publicProfile
      dispatch(
        fetchPublicProfileSuccess({
          ...content.publicProfile,
          listingShortDescription: listingShortDescription ? listingShortDescription : '',
          companyLogo: companyLogo ? companyLogo : '',
          previewCardImage: previewCardImage ? previewCardImage : '',
          listingHeaderImage: listingHeaderImage ? listingHeaderImage : '',
          highlights: highlights ? highlights : [],
          metricsMarketing: metricsMarketing ? metricsMarketing : [],
        })
      )
      dispatch(fetchPartnerProfileLoading(false))

      const {
        partnerProfile: { form },
      } = getState()
      dispatch(setPartnerProfilePercent(calculateProfilePercent(form)))
      return response
    } catch (error: any) {
      dispatch(fetchPublicProfileFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const fetchPartnerProfileSuccess = (partnerProfile: IForm): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_PROFILE_SUCCESS,
  payload: { partnerProfile },
})

export const setPartnerProfilePercent = (percent: number) => ({
  type: CONSTANTS.SET_PARTNER_PROFILE_PERCENT,
  percent: percent,
})

export const imageUploadRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_REQUEST,
})

export const imageUploadSuccess = (): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_SUCCESS,
})

export const imageUploadFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_FAILURE,
  error,
})
export const uploadProductImage =
  (partnerId: number, files: File[], entity: PARTNER_UPLOAD_TYPES): ThunkAction<Promise<string>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<string> => {
    try {
      if (!partnerId) {
        return ''
      }
      dispatch(imageUploadRequest())
      const uploadUrl = urls.getMediaTypesUploadUrl()

      const response = await upload(uploadUrl, files, partnerId, entity, UPLOAD_ENTITY_KEYS.PARTNER_ID)
      if (response.status === 201) {
        const { content } = response.data.data

        dispatch(imageUploadSuccess())
        return content[0]
      } else return ''
    } catch (error: any) {
      dispatch(imageUploadFailure(error))
      return ''
    }
  }

export const removeImage =
  (nameMediaType: MEDIA_TYPES, subNameMediaType?: string): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<any> => {
    try {
      const form = getForm(getState())
      dispatch(imageRemoveRequest())

      const newForm = cloneDeep(form)

      newForm.mediaBuyTypes = newForm.mediaBuyTypes.map(type => {
        if (subNameMediaType && type.subName === subNameMediaType) {
          return { ...type, previewImage: undefined }
        } else if (!subNameMediaType && type.name === nameMediaType) {
          return { ...type, previewImage: undefined }
        }
        return type
      })
      dispatch(imageRemoveSuccess(newForm))
      return null
    } catch (error: any) {
      dispatch(imageRemoveFailure(error))
      return error
    }
  }
export const imageRemoveRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_REQUEST,
})

export const imageRemoveSuccess = (form: any): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_SUCCESS,
  payload: { form },
})

export const imageRemoveFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_FAILURE,
  error,
})
