import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { AxiosResponse } from "axios";
import * as CONSTANTS from "./core.constants";
import IAction from "src/interfaces/IAction";
import localStorage from "redux-persist/es/storage";
import { LOCAL_STORAGE_KEYS, PATHS, ROLES } from "../../constants";
import { requestHttp, urls } from "../../api";
import {
  ICoreConstants,
  ICoreSettings,
  IDownloadResponse,
  IMediaUrl,
  IUploadResponse,
} from "./core.types";
import { downloadFile } from "../../utils/downloadFile";
import history from "../../utils/history";
import { getResponseErrorMessage } from "../../helpers";
import { ICurrenciesResponse } from "./core.types";
import { BRIEF_UPLOAD_TYPES } from "../../constants/brief";
import { BRIEF_UPLOAD_TYPES as TESTAHEL_BRIEF_UPLOAD_TYPES } from "../../constants/testahel_brief";
import { UPLOAD_ENTITY_KEYS } from "../../constants/upload";
import { PARTNER_UPLOAD_TYPES } from "src/constants/partner";
import { fetchPartnerProfile } from "../partner/partnerProfile/Profile.actions";
import { fetchClientInfo } from "../client/clientProfile/Profile.actions";
import { CURRENCY } from "../../constants/currencies";
import { CORE_CONSTANTS } from "../../constants/common";

export const setProgress = (progress: number): IAction => ({
  type: CONSTANTS.SET_PROGRESS,
  payload: { progress },
});

export const resetProgress = (): IAction => ({
  type: CONSTANTS.RESET_PROGRESS,
});

export const logoutRequest = (): IAction => ({
  type: CONSTANTS.LOGOUT_REQUEST,
});
export const logoutSuccess = (): IAction => ({
  type: CONSTANTS.LOGOUT_SUCCESS,
});
export const logoutFailure = (): IAction => ({
  type: CONSTANTS.LOGOUT_FAILURE,
});

export const fetchCurrencySuccess = (currency: CURRENCY): IAction => ({
  type: CONSTANTS.FETCH_CURRENCIES_SUCCESS,
  payload: { currency },
});

export const fetchCurrencyFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CURRENCIES_FAILURE,
  error,
});

export const uploadRequest = (): IAction => ({
  type: CONSTANTS.UPLOAD_REQUEST,
});
export const uploadSuccess = (fileUrls: string[]): IAction => ({
  type: CONSTANTS.UPLOAD_SUCCESS,
  payload: { fileUrls },
});
export const uploadFailure = (error: string): IAction => ({
  type: CONSTANTS.UPLOAD_FAILURE,
  error,
});

export const downloadRequest = (): IAction => ({
  type: CONSTANTS.DOWNLOAD_REQUEST,
});

export const downloadSuccess = (): IAction => ({
  type: CONSTANTS.DOWNLOAD_SUCCESS,
});

export const downloadFailure = (error: string): IAction => ({
  type: CONSTANTS.DOWNLOAD_FAILURE,
  error,
});

export const saasRequest = (): IAction => ({
  type: CONSTANTS.SAAS_REQUEST,
});

export const saasSuccess = (data: any): IAction => ({
  type: CONSTANTS.SAAS_SUCCESS,
  payload: data,
});

export const saasFailure = (error: string): IAction => ({
  type: CONSTANTS.SAAS_FAILURE,
  error,
});

export const getUserRequest = (): IAction => ({
  type: CONSTANTS.GET_CURRENT_USER_REQUEST,
});

export const getUserSuccess = (user: any): IAction => ({
  type: CONSTANTS.GET_CURRENT_USER_SUCCESS,
  payload: user,
});

export const getUserFailure = (error: string): IAction => ({
  type: CONSTANTS.GET_CURRENT_USER_FAILURE,
  error,
});

export const logout =
  (): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(logoutRequest());
      await localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

      dispatch(logoutSuccess());
      dispatch(getUserFailure(""));
    } catch (error) {
      dispatch(logoutFailure());
    }
  };

export const setServerError = (
  data: string,
  type: "text" | "html" = "text"
): IAction => ({
  type: CONSTANTS.SET_SERVER_ERROR,
  payload: { data, type },
});

export const resetServerError = (): IAction => ({
  type: CONSTANTS.RESET_SERVER_ERROR,
});

export const upload = async (
  url: string,
  files: File[],
  entityId?: number,
  entity?: BRIEF_UPLOAD_TYPES | PARTNER_UPLOAD_TYPES,
  entityKey?: UPLOAD_ENTITY_KEYS,
  isPublicFile?: boolean,
  version?: string
) => {
  try {
    const bodyFormData = new FormData();

    files.forEach((file: File) => {
      bodyFormData.append("files", file);
    });

    entityKey && bodyFormData.append(entityKey, String(entityId));
    entity && bodyFormData.append("entity", entity);
    version && bodyFormData.append("version", version);
    isPublicFile === false &&
      bodyFormData.append("isPublic", String(isPublicFile));

    const config = {
      multipartFormData: true,
      skipDownloadProgress: true,
    };

    const response = await requestHttp.post<IUploadResponse>(
      url,
      bodyFormData,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const uploadTeshahelMedia = async (
  url: string,
  files: File[],
  entityId?: number,
  entity?: TESTAHEL_BRIEF_UPLOAD_TYPES | PARTNER_UPLOAD_TYPES,
  entityKey?: UPLOAD_ENTITY_KEYS
) => {
  try {
    const bodyFormData = new FormData();

    files.forEach((file: File) => {
      bodyFormData.append("files", file);
    });

    entityKey && bodyFormData.append(entityKey, `${entityId}`);
    entity && bodyFormData.append("entity", entity);

    const config = {
      multipartFormData: true,
      skipDownloadProgress: true,
    };

    const response = await requestHttp.post<IUploadResponse>(
      url,
      bodyFormData,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const downloadMedia =
  (entityId: string | number): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(downloadRequest());
      const url = urls.getClientDownloadUrl() + entityId;

      const response = await requestHttp.get<IDownloadResponse>(url);

      if (response.status === 200) {
        const { content } = response.data.data;

        content.forEach((media: IMediaUrl) => {
          downloadFile(media.src);
        });

        dispatch(downloadSuccess());
      }
    } catch (error) {
      dispatch(downloadFailure(error));
    }
  };

export const fetchCurrency =
  (): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.get<ICurrenciesResponse>(
        urls.getUserCurrency(),
        {
          skipDownloadProgress: true,
        }
      );

      const { content } = response.data.data;
      dispatch(fetchCurrencySuccess(content.currency));
    } catch (error) {
      dispatch(fetchCurrencyFailure(getResponseErrorMessage(error)));
    }
  };

export const updateCurrency =
  (
    currency: CURRENCY
  ): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
  ): Promise<AxiosResponse> => {
    try {
      const response = await requestHttp.patch<ICurrenciesResponse>(
        `${urls.updateUserCurrency()}?newCurrency=${currency}`
      );

      const { content } = response.data.data;
      dispatch(fetchCurrencySuccess(content.currency));

      return response;
    } catch (error) {
      dispatch(fetchCurrencyFailure(getResponseErrorMessage(error)));
      return error;
    }
  };

export const getUser =
  (): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const token = await localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      if (!token) {
        return Promise.resolve();
      }

      const role = await localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLE);
      const getUrl =
        role === ROLES.CLIENT
          ? urls.getCurrentClientUserUrl
          : urls.getCurrentPartnerUserUrl;

      dispatch(getUserRequest());
      const response = await requestHttp.get(getUrl());

      if (response.status === 200) {
        const { content } = response.data.data;
        dispatch(getUserSuccess(content));

        //@ts-ignore
        content.role === ROLES.CLIENT
          ? dispatch(fetchClientInfo(content))
          : dispatch(fetchPartnerProfile());
      } else {
        dispatch(logout());
        history.push(PATHS.SIGN_IN);
      }

      return response;
    } catch (error) {
      dispatch(getUserFailure(getResponseErrorMessage(error)));
      dispatch(logout());
      return error;
    }
  };

export const coreSettingsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_SETTINGS_REQUEST,
});

export const coreSettingsSuccess = (settings: ICoreSettings): IAction => ({
  type: CONSTANTS.FETCH_SETTINGS_SUCCESS,
  payload: settings,
});

export const coreSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_SETTINGS_FAILURE,
  error,
});

export const fetchCoreSettings =
  (data?: CORE_CONSTANTS[]): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(coreSettingsRequest());
      const params = { set: data };
      const response = await requestHttp.get(urls.getCoreSettings(), {
        params,
      });

      const { content } = response.data.data;
      dispatch(coreSettingsSuccess(content));
    } catch (error) {
      dispatch(coreSettingsFailure(getResponseErrorMessage(error)));
    }
  };

export const coreConstantsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CONSTANTS_REQUEST,
});

export const coreConstantsSuccess = (constants: ICoreConstants): IAction => ({
  type: CONSTANTS.FETCH_CONSTANTS_SUCCESS,
  payload: constants,
});

export const coreConstantsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CONSTANTS_FAILURE,
  error,
});

export const fetchConstants =
  (data?: CORE_CONSTANTS[]): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(coreConstantsRequest());
      const params = { set: data };
      const response = await requestHttp.get(urls.getCoreConstants(), {
        params,
      });

      const { content } = response.data.data;
      dispatch(coreConstantsSuccess(content));
    } catch (error) {
      dispatch(coreConstantsFailure(getResponseErrorMessage(error)));
    }
  };

export const downloadImageAsync = async (url: string) => {
  try {
    const response = await requestHttp.get(urls.getCoreImage(url), {
      skipDownloadProgress: false,
      responseType: "blob",
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const downloadImage =
  (url: string): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    try {
      const response = await requestHttp.get(urls.getCoreImage(url), {
        skipDownloadProgress: false,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      return error;
    }
  };

export const fetchSaasPartnerDetails =
  (): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(saasRequest());
      const response = await requestHttp.get<any>(urls.getCurrentSaasPartner());

      const { content } = response.data.data;
      dispatch(saasSuccess(content));
    } catch (error) {
      dispatch(saasFailure(getResponseErrorMessage(error)));
    }
  };
