import React, { Fragment, ReactElement, useState } from 'react'
import BriefCartSummary from '../../modules/brief/containers/components/Brief.CartSummary'
import { IBriefResponse, ICartList } from '../../modules/brief/brief.types'
import { Col, QAlert, Row } from 'quantum_components'
import BriefViewSummary from '../../modules/brief/containers/components/Brief.View.Summary'
import { isMobile } from 'react-device-detect'
import BriefSwitch from '../../modules/brief/containers/components/Brief.Switch'
import BriefQuotation from '../../modules/brief/containers/briefPayment/Brief.Quotation'
import {
  BRIEF_PAYMENT_UPLOAD_TYPES,
  BRIEF_PRINT_ID,
  BRIEF_STATUS,
  BRIEF_STEPS,
  BRIEF_TYPES,
  DISTRIBUTION_TYPE,
  SERVICE_NAMES_FOR_TABLE,
} from '../../constants/brief'
import BriefProduct from '../../modules/brief/containers/components/Brief.Product'
import BriefMedia from '../../modules/brief/containers/components/Brief.Media'
import BriefTargeting from '../../modules/brief/containers/components/Brief.Targeting'
import BriefAdditional from '../../modules/brief/containers/components/Brief.Additional'
import { IBillPaymentUploadTypes } from '../../modules/client/bills/bills.types'
import { canEditBrief, canPartiallyEditBrief } from '../../helpers/rules'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'src/modules/core/core.selectors'
import BriefUpload from 'src/modules/brief/containers/components/Brief.Upload'
import { useHistory } from 'react-router-dom'
import { PATHS } from 'src/constants'
import { useTranslation } from 'react-i18next'

interface IProps {
  briefView: IBriefResponse
  cart: ICartList
  setCurrentStep: (step: BRIEF_STEPS) => void
  openPartner: (id: number) => void
  hideProposalButtons?: boolean
  hidePaymentSection?: boolean
  rejection?: boolean
  hideSubmitPaymentButton?: boolean
  onPaymentFilesChange?: (files: { files: File[]; filesType: IBillPaymentUploadTypes }[]) => void
  onPaymentTypeChange?: (uploadType: BRIEF_PAYMENT_UPLOAD_TYPES) => void
}

export default function BriefEditSummaryComponent({
  briefView,
  cart,
  setCurrentStep,
  openPartner,
  rejection,
  hideProposalButtons = false,
  hidePaymentSection = false,
  hideSubmitPaymentButton = true,
  onPaymentFilesChange,
  onPaymentTypeChange,
}: IProps): ReactElement {
  const { t } = useTranslation(["translation"]);
  const [showPartner, setShowPartner] = useState(false)
  const currentUser = useSelector(getCurrentUser)
  const isPaymentStepEnabled = currentUser?.paymentEnabled
  const history = useHistory();

  const changeSwitch = (value: any) => setShowPartner(value.target.value)
  const isProductSample = briefView.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isMediaBuying = briefView.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)

  const distributionItem =
    briefView.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
      ? SERVICE_NAMES_FOR_TABLE.INSERT
      : SERVICE_NAMES_FOR_TABLE.PRODUCT

  const hideBriefEdit = !canEditBrief(briefView.status)
  const hideCartEdit = (!rejection && !canPartiallyEditBrief(briefView.status))

  return isMobile ? (
    <div id={BRIEF_PRINT_ID}>
      <div data-html2canvas-ignore>
        <BriefSwitch onChange={changeSwitch} value={showPartner} />
      </div>
      {briefView.status === BRIEF_STATUS.MISSING_INFO && window.location.pathname.includes('/briefs/view') && <div data-html2canvas-ignore>
        <QAlert
          message={
            <a onClick={() => history.push(PATHS.CLIENT_PROFILE)}>{t("brief.incomplete-client-profile.Fill in required details in your profile in order to proceed with this Campaign")}</a>
          }
          type="warning"
          className="centered pt-10"
        />
      </div>}
      <div className={`${showPartner ? 'hidden-block' : 'visible-block'}`} id="ServiceCard">
        <Fragment>
          {/* <ServiceCard
            isProductSample={isProductSample}
            isMediaBuying={isMediaBuying}
            distributionItem={distributionItem}
            onEdit={() => setCurrentStep(BRIEF_STEPS.TYPE)}
            viewMode={hideBriefEdit}
          /> */}
          <BriefProduct
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.PRODUCT)}
            viewMode={hideBriefEdit}
          />
          <BriefTargeting
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.TARGETING)}
            viewMode={hideBriefEdit}
          />
          <BriefUpload
            briefView={briefView}
            onEdit={() => setCurrentStep(BRIEF_STEPS.UPLOAD)}
            viewMode={hideBriefEdit}
          />
        </Fragment>
      </div>
      <div className={`${showPartner ? 'visible-block' : 'hidden-block'} `}>
        <BriefCartSummary
          briefView={briefView}
          setCurrentStep={setCurrentStep}
          onEdit={openPartner}
          cart={cart}
          viewMode={hideCartEdit}
          hidePaymentSection={hidePaymentSection}
          hideProposalButtons={hideProposalButtons}
          hideSubmitPaymentButton={hideSubmitPaymentButton}
          onPaymentFilesChange={onPaymentFilesChange}
          onPaymentTypeChange={onPaymentTypeChange}
        />
      </div>
      {!isEmpty(cart?.additional.items) &&
        (
          <Col span={24} id="brief_quotation_print_area" hidden>
            <BriefQuotation briefView={briefView} cart={cart} />
          </Col>
        )
      }
    </div>
  ) : (
    <Row gutter={20} className="pt-20 mb-30" id={BRIEF_PRINT_ID}>
      {briefView.status === BRIEF_STATUS.MISSING_INFO && window.location.pathname.includes('/briefs/view') && <Col span={24}>
        <QAlert
          message={
            <a onClick={() => history.push(PATHS.CLIENT_PROFILE)}>{t("brief.incomplete-client-profile.Fill in required details in your profile in order to proceed with this Campaign")}</a>
          }
          type="warning"
          className="centered pb-20"
        />
      </Col>}
      <Col span={16}>
        <BriefViewSummary briefView={briefView} setCurrentStep={setCurrentStep} viewMode={hideBriefEdit} />
      </Col>
      <Col span={8}>
        <BriefCartSummary
          briefView={briefView}
          setCurrentStep={setCurrentStep}
          onEdit={openPartner}
          cart={cart}
          viewMode={hideCartEdit}
          hidePaymentSection={hidePaymentSection}
          hideProposalButtons={hideProposalButtons}
          hideSubmitPaymentButton={hideSubmitPaymentButton}
          onPaymentFilesChange={onPaymentFilesChange}
          onPaymentTypeChange={onPaymentTypeChange}
        />
      </Col>

      {!isEmpty(cart?.additional.items) &&
        (
          // <div style={{width: "50%", position: "absolute", opacity: 0, pointerEvents:"none"}}>
          <Col span={24} id="brief_quotation_print_area" hidden>
            <BriefQuotation briefView={briefView} cart={cart} />
          </Col>
          // </div>
        )
      }
    </Row>
  )
}
