export const MODULE_NAME = 'marketplace'
/* actions */

export const FETCH_MARKETPLACE_PARTNERS_REQUEST = `${MODULE_NAME}/FETCH_MARKETPLACE_PARTNERS_REQUEST`
export const FETCH_MARKETPLACE_PARTNERS_SUCCESS = `${MODULE_NAME}/FETCH_MARKETPLACE_PARTNERS_SUCCESS`
export const FETCH_MARKETPLACE_PARTNERS_FAILURE = `${MODULE_NAME}/FETCH_MARKETPLACE_PARTNERS_FAILURE`

export const RESET_MARKETPLACE_STATE = `${MODULE_NAME}/RESET_MARKETPLACE_STATE`
export const RESET_PARTNER_DETAILS = `${MODULE_NAME}/RESET_PARTNER_DETAILS`

export const SET_ACTIVE_SINGLE_PARTNER = `${MODULE_NAME}/SET_ACTIVE_SINGLE_PARTNER`

export const FETCH_PARTNER_DETAILS_REQUEST = `${MODULE_NAME}/FETCH_PARTNER_DETAILS_REQUEST`
export const FETCH_PARTNER_DETAILS_SUCCESS = `${MODULE_NAME}/FETCH_PARTNER_DETAILS_SUCCESS`
export const FETCH_PARTNER_DETAILS_FAILURE = `${MODULE_NAME}/FETCH_PARTNER_DETAILS_FAILURE`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`
export const SET_PARTNER_LIST_PARAMS = `${MODULE_NAME}/SET_PARTNER_LIST_PARAMS`
