import { TAXES_PERCENT, DECIMAL_DIGIT, MEDIA_TYPES } from '../constants/brief'
import { ICalculation, ICartItem, ICartServiceItem } from '../modules/brief/brief.types'
import { REQUEST_STATUS } from '../constants/request'
import cloneDeep from 'lodash/cloneDeep'
import { getTypeBySubType } from './mediaTypes'

export const calculation = (items: ICartItem[], managementFees: number, vat:any): ICalculation => {
  let result = {
    subTotal: 0,
    managementFee: 0,
    taxes: 0,
    total: 0,
  } as ICalculation
  let total = 0

  const subTotal = calcCartItemSubTotal(items)

  result.subTotal = subTotal
  total += subTotal

  const managementFee = calcManagementFee(total, managementFees)
  result.managementFee = managementFee

  total += managementFee
  const taxes = calcTaxes(total, vat)
  result.taxes = taxes

  total += taxes
  result.total = +total.toFixed(DECIMAL_DIGIT)

  return result
}

export const calcSubTotal = (
  subTotal: number,
  logisticSamplePrice: number,
  logisticInsertPrice: number,
  stickerPrice: number
) => {
  return subTotal + logisticSamplePrice + logisticInsertPrice + stickerPrice
}

export const calcManagementFee = (subTotal: number, managementFees: number) => {
  return +((subTotal * managementFees) / 100).toFixed(DECIMAL_DIGIT)
}

export const calcTaxes = (subTotal: number, vat: any) => {
  const TAX_CHARGED = vat ? TAXES_PERCENT : 0

  return +((subTotal * TAX_CHARGED) / 100).toFixed(DECIMAL_DIGIT)
}

export const calcLogisticPrice = (logisticRate: number, quantity: number, minQuantity: number) => {
  const newQuantity = quantity < minQuantity ? minQuantity : quantity
  const logisticPrice = logisticRate * newQuantity
  return +logisticPrice.toFixed(DECIMAL_DIGIT)
}

export const calcStickerPrice = (stickerCost: number, quantity: number) => {
  return +(stickerCost * quantity).toFixed(DECIMAL_DIGIT)
}

export const calcCartItemSubTotal = (items: ICartItem[]) => {
  if (!items.length) {
    return 0
  }

  return items.reduce((acc: number, item: ICartItem) => {
    const total = item.subTotal ? item.subTotal : 0
    return acc + total
  }, 0)
}

export const calcCartItemServicesSubTotal = (services: ICartServiceItem[]) => {
  if (!services.length) {
    return 0
  }

  return services.reduce((acc: number, item: ICartServiceItem) => {
    const total = item.total ? item.total : 0
    return acc + total
  }, 0)
}

export const calcDistributionQuantity = (cartItems: ICartItem[]) => {
  let total = 0
  cartItems.forEach((item: ICartItem) => {
    const distributionServices = item.services.filter(
      (service: ICartServiceItem) => service.type === MEDIA_TYPES.DISTRIBUTION
    )
    const sum = distributionServices.reduce((acc: number, service: ICartServiceItem) => {
      const quantity = service.quantity ? service.quantity : 0
      return acc + quantity
    }, 0)
    total += sum
  })

  return total
}

export const rejectedItems = (items: ICartItem[]) => {
  const cartItems = cloneDeep(items)
  return cartItems.map((item: ICartItem) => {
    item.services = item.services.filter(
      (service: ICartServiceItem) => service.requestStatus === REQUEST_STATUS.REJECTED
    )

    return item
  })
}

export const getRejectedServices = (cartItems: ICartItem[]) => {
  const rejectedCartItems = rejectedItems(cartItems)
  const services = rejectedCartItems.flatMap((item: ICartItem) => item.services.map(s => getTypeBySubType(s.name)))

  return [...new Set(services)]
}

export const blockService = (service: ICartServiceItem) => {
  if (!service.requestStatus) return false
  const blockedStatuses = [REQUEST_STATUS.ACCEPTED, REQUEST_STATUS.REVIEW_NOW]

  return blockedStatuses.includes(service.requestStatus)
}

export const hasMediaBuyItems = (items: ICartItem[]) => {
  const services = items.map((item: ICartItem) => item.services).flat()
  return services.some((services: ICartServiceItem) => services.type !== MEDIA_TYPES.DISTRIBUTION)
}

export const hasDistributionItems = (items: ICartItem[]) => {
  const services = items.map((item: ICartItem) => item.services).flat()
  return services.some((services: ICartServiceItem) => services.type === MEDIA_TYPES.DISTRIBUTION)
}

export const partnerCalculation = (items: ICartItem[], vat:any): ICalculation => {
  let result = {
    subTotal: 0,
    taxes: 0,
    total: 0,
  } as ICalculation
  let total = 0

  items.forEach((item: ICartItem) => {
    total += calcCartItemServicesSubTotal(item.services)
  })

  result.subTotal = total

  const taxes = calcTaxes(total, vat)
  result.taxes = taxes

  total += taxes
  result.total = +total.toFixed(DECIMAL_DIGIT)

  return result
}
