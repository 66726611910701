export const getBase64 = async url => {
  const data = await fetch(url, { mode: 'cors', cache: 'no-cache' })
  const blob = await data.blob()
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}
