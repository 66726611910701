import React from 'react'
import { Spin } from 'quantum_components'
import { LoadingOutlined } from '@ant-design/icons'
import './index.scss'

interface IProps {
  text?: string
  className?: string
}

const SpinnerComponent = ({ text, className = '' }: IProps) => {
  return (
    <div className={`spinner-component ${className}`}>
      <Spin indicator={<LoadingOutlined className="spinner-icon" />} />
      {text && <div className="spinner-text">{text}</div>}
    </div>
  )
}

export default SpinnerComponent
