import { IRequestTableParams } from 'src/modules/partner/requests/request.types'

export enum REQUEST_STATUS {
  REVIEW_NOW = 'REVIEW NOW',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
}

export enum REQUEST_ACTIONS {
  REJECT = 'REJECT',
  APPROVE = 'APPROVE',
  PENDING = 'PENDING',
  EXTEND = 'EXTEND',
}

export const requestStatusesLabel = {
  [REQUEST_STATUS.REVIEW_NOW]: {
    classStyle: 'review',
    label: REQUEST_STATUS.REVIEW_NOW,
  },
  [REQUEST_STATUS.REJECTED]: {
    classStyle: 'rejected',
    label: REQUEST_STATUS.REJECTED,
  },
  [REQUEST_STATUS.EXPIRED]: {
    classStyle: 'expired',
    label: REQUEST_STATUS.EXPIRED,
  },
  [REQUEST_STATUS.ACCEPTED]: {
    classStyle: 'approved',
    label: REQUEST_STATUS.ACCEPTED,
  },
  [REQUEST_STATUS.PENDING]: {
    classStyle: 'pending',
    label: REQUEST_STATUS.PENDING,
  },
}

export const DEFAULT_REQUEST_TABLE_PARAMS: IRequestTableParams = {
  offset: 0,
  limit: 10,
  direction: 'desc',
  sortField: 'createdAt',
  search: '',
  paymentStatus: [],
}

export const REQUEST_AWAITING_DAYS = 7
