import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../helpers'
import { fetchCurrency, getUser } from '../../../modules/core/core.actions'
import * as selectors from '../../../modules/core/core.selectors'
import { PATHS, ROLES } from '../../../constants'
import getIsLoggedIn from '../../../helpers/getIsLoggedIn'
import getUserRole from '../../../helpers/getUserRole'
import ClientLayout from '../ClientLayout'
import PartnerLayout from '../PartnerLayout'
import MarketplaceLayout from '../MarketplaceLayout'
import AnnouncementLayout from '../AnnouncementLayout'

interface Props {
  children: React.ReactNode
}

const MainLayout = ({ children }: Props) => {
  const dispatch = useReduxDispatch()
  const location = useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [role, setRole] = useState<ROLES>(ROLES.UNKNOWN)
  const currentUser = useSelector(selectors.getCurrentUser)
  const profilePicture = useSelector(selectors.getProfilePicture)

  useEffect(() => {
    !window.location.pathname.includes('/p/') && dispatch(getUser())
  }, [])

  useEffect(() => {
    if (!currentUser.id) return

    dispatch(fetchCurrency())
  }, [currentUser])

  const isClient = role === ROLES.CLIENT
  const isPartner = role === ROLES.PARTNER

  const removeParamFromPath = (url: string) => {
    const newPath = url.split('/')
    newPath.pop()
    return newPath.join('/')
  }

  // check is the path is brief path or co-branding path for clients only
  const checkBriefFormPath = () => {
    if (location.pathname.includes(PATHS.CREATE_BRIEF)) {
      return true
    }

    if (removeParamFromPath(PATHS.BRIEF_EDIT) === removeParamFromPath(location.pathname)) {
      return true
    }

    if (removeParamFromPath(PATHS.BRIEF_EDIT_V2) === removeParamFromPath(location.pathname)) {
      return true
    }

    if (removeParamFromPath(PATHS.BRIEF_EDIT_V3) === removeParamFromPath(location.pathname)) {
      return true
    }

    if (removeParamFromPath(PATHS.BRIEF_REJECTED) === removeParamFromPath(location.pathname)) {
      return true
    }

    if (removeParamFromPath(PATHS.SPECIAL_CLIENTS) === removeParamFromPath(location.pathname)) {
      return true
    }

    if (location.pathname.includes(PATHS.TESTAHEL_BRIEF_CREATE)) {
      return true
    }

    if (removeParamFromPath(PATHS.TESTAHEL_BRIEF_EDIT) === removeParamFromPath(location.pathname)) {
      return true
    }

    return false
  }

  const isBriefPath = checkBriefFormPath()

  const fetchUser = async () => {
    const isLoggedIn = await getIsLoggedIn()
    const role = await getUserRole()

    setIsAuthenticated(isLoggedIn)
    setRole(role)
  }

  useEffect(() => {
    fetchUser()
  })
  const isMarketplacePath = location.pathname.includes(PATHS.MARKETPLACE)
  const isAnnouncementPath = location.pathname.includes(PATHS.ANNOUNCEMENTS)
  const isDeploymentDetailsPath = location.pathname.includes(PATHS.DEPLOYMENT_DETAILS)
  const renderLayout = (children: React.ReactNode) => {
    if (!isAuthenticated || isDeploymentDetailsPath) {
      return children
    }

    if (isClient) {
      if (isMarketplacePath) return <MarketplaceLayout user={currentUser}>{children}</MarketplaceLayout>
      if (isAnnouncementPath) return <ClientLayout user={currentUser}>{children}</ClientLayout>
      return isBriefPath ? children : <ClientLayout user={currentUser}>{children}</ClientLayout>
    }

    if (isPartner && removeParamFromPath(PATHS.SPECIAL_CLIENTS) !== removeParamFromPath(location.pathname)) {
      return <PartnerLayout user={currentUser}>{children}</PartnerLayout>
    }

    return children
  }

  return <>{renderLayout(children)}</>
}

export default MainLayout
