export enum CHART_TYPES {
    SIGNUP_REQUESTS_CLIENT = 'SIGNUP_REQUESTS_CLIENT',
    SIGNUP_REQUESTS_PARTNER = 'SIGNUP_REQUESTS_PARTNER',
    APPROVED_CLIENTS = 'APPROVED_CLIENTS',
    APPROVED_PARTNERS = 'APPROVED_PARTNERS',
    SAMPLE_DISTRIBUTION_ACTIVE = 'SAMPLE_DISTRIBUTION_ACTIVE',
    SAMPLE_DISTRIBUTION_COMPLETE = 'SAMPLE_DISTRIBUTION_COMPLETE',
    TRANSACTIONS = 'TRANSACTIONS',
    BRIEFS = 'BRIEFS',
    BRIEF_CONVERSION = 'BRIEF_CONVERTION',
    CAMPAIGNS = 'CAMPAIGNS',
    CAMPAIGN_COMPLETION = 'CAMPAIGN_COMPLETION',
    REVENUE = 'REVENUE',
    CAMPAIGN_TYPE = 'CAMPAIGN_TYPE',
    ACTIVE_USERS = 'ACTIVE_USERS',
  }
  
  export enum DATE_PRESET {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    THREE_MONTHS = 'THREE_MONTHS',
    SIX_MONTHS = 'SIX_MONTHS',
    YEAR = 'YEAR',
  }
  
  export const BUTTON_DATES_NAMES: { [key: string]: string } = {
    [DATE_PRESET.DAY]: '24 hours',
    [DATE_PRESET.WEEK]: '1 week',
    [DATE_PRESET.MONTH]: '1 month',
    [DATE_PRESET.THREE_MONTHS]: '3 months',
    [DATE_PRESET.SIX_MONTHS]: '6 months',
    [DATE_PRESET.YEAR]: '1 year',
  }
  
  export const BUTTON_DAYS_NUMBER: { [key: string]: number } = {
    [DATE_PRESET.DAY]: 1,
    [DATE_PRESET.WEEK]: 7,
    [DATE_PRESET.MONTH]: 30,
    [DATE_PRESET.THREE_MONTHS]: 90,
    [DATE_PRESET.SIX_MONTHS]: 183,
    [DATE_PRESET.YEAR]: 365,
  }
  
  export const BUTTON_DATES = [
    DATE_PRESET.WEEK,
    DATE_PRESET.MONTH,
    DATE_PRESET.THREE_MONTHS,
    DATE_PRESET.SIX_MONTHS,
    DATE_PRESET.YEAR,
  ]
  
  export const ACTIVE_USER_BUTTON_DATES = [
    DATE_PRESET.DAY,
    DATE_PRESET.WEEK,
    DATE_PRESET.MONTH,
    DATE_PRESET.THREE_MONTHS,
    DATE_PRESET.YEAR,
  ]
  