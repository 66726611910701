/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React from 'react'
import { useTranslation } from 'react-i18next'
import { QSelect, Row, Col, Radio, QRadio } from 'quantum_components'
import {
  TARGET_TYPE,
  TARGET_TYPE_LABELS,
  TARGET_AUDIENCE_VALUES as VALUES,
  TARGET_AUDIENCE_INITIAL_VALUES,
} from 'src/constants/clientProfile'

import { MediaTypeCard } from '../components/MediaTypeCard'
import { AgeGroupCard } from 'src/modules/brief/containers/components/Brief.AgeGroupCard'
import { useSelector } from 'react-redux'
import { useReduxDispatch } from '../../../../../helpers'
import { actions } from '../../clientProfile.module'
import { LOCALIZATION_LANGUAGES, LOCAL_STORAGE_KEYS } from 'src/constants'

import { getSettings, getFilledData } from '../../profile.selectors'
import { createObj } from '../../../../../helpers/createObjectWithOldFields'
import { first } from 'lodash'

const genders = ['Male', 'Female', 'Both']

interface IAgeGroup {
  value: string
  label: string
  sublabel: string
  img: string
}

export function ClientsTargetBody() {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationUser', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const { segments, ageGroups } = useSelector(getSettings)
  const filledData = useSelector(getFilledData)

  const sysLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION) || 'en'

  const stepData = createObj(TARGET_AUDIENCE_INITIAL_VALUES, filledData)

  const changeStepValue = (field, value) =>
    dispatch(
      actions.setStepData({
        ...stepData,
        [field]: value,
      })
    )

  const getOptions = (options: string[]) =>
    options.map(el => ({
      label: sysLang === LOCALIZATION_LANGUAGES.ARABIC ? el.name_ar : el.name,
      value: sysLang === LOCALIZATION_LANGUAGES.ARABIC ? el.name_ar : el.name,
    }))

  const onChangeSelectHandler = field => value => changeStepValue(field, value)

  const onChangeCheckboxHandler = value => event => {
    const checked = event.target.checked
    const items = stepData[VALUES[TARGET_TYPE.GENDER_FOCUS]]
    if (checked && !items.includes(value)) {
      items.push(value)
    } else if (!checked && items.includes(value)) {
      items.splice(items.indexOf(value), 1)
    }

    changeStepValue(VALUES[TARGET_TYPE.GENDER_FOCUS], items)
  }
  const handleMediaType = (e: MouseEvent, name: string) => {
    const items = stepData[VALUES[TARGET_TYPE.AGE_GROUPS]]
    items.includes(name) ? items.splice(items.indexOf(name), 1) : items.push(name)

    changeStepValue(VALUES[TARGET_TYPE.AGE_GROUPS], items)
  }

  const getAgeGroups = () => {
    const reducer = (s: any) => {
      const value = s.from + (s.to ? '-' + s.to + ' ' + s.name : '+ ' + s.name)
      const sublabel = s.from + (s.to ? '-' + s.to : '+')
      const label = i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? s.name_ar : s.name
      const img = s.src

      return { label, sublabel, value, img } as IAgeGroup
    }

    return ageGroups.map((group: any) => reducer(group))
  }

  return (
    <div className={`form form-brief form--horizontal mb-20`}>
      <div className="form__head">
        <h3>{t('client.profile.edit.target_audience_you')}</h3>
      </div>
      <div className="form__cont">
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="distributeQuantity" className="form-label">
              {t(TARGET_TYPE_LABELS.GENDER_FOCUS)}
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Radio.Group
              name={VALUES[TARGET_TYPE.GENDER_FOCUS]}
              value={first(stepData[VALUES[TARGET_TYPE.GENDER_FOCUS]])}
              onChange={e => changeStepValue(VALUES[TARGET_TYPE.GENDER_FOCUS], [e.target.value])}
            >
              {genders.map(gender => (
                <QRadio key={gender} value={gender}>
                  {t(`brief.targeting.gender.${gender}`, gender, { ns: 'translationCommon' })}
                </QRadio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="ageGroups" className="form-label">
              {t(TARGET_TYPE_LABELS.AGE_GROUPS)}
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Row gutter={16} justify="center">
              {getAgeGroups().map((group: string) => {
                const isChecked = stepData[VALUES[TARGET_TYPE.AGE_GROUPS]].includes(group.value)
                // return <AgeGroupCard key={name.name} checked={isChecked} name={name} onChange={handleMediaType} dataType="ageGroups" />
                return (
                  <AgeGroupCard
                    key={group.value}
                    checked={isChecked}
                    label={group.label}
                    sublabel={group.sublabel}
                    img={group.img}
                    onChange={(e: any) => handleMediaType(e, group.value)}
                  />
                )
              })}
            </Row>
          </Col>
        </Row>
        {/* <hr className="form__hr" />
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="targetedSegments" className="form-label">
              {t(TARGET_TYPE_LABELS.TARGETED_SEGMENTS)}
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <QSelect
              showArrow
              maxTagTextLength={8}
              maxTagCount={2}
              value={stepData[VALUES[TARGET_TYPE.TARGETED_SEGMENTS]]}
              onChange={onChangeSelectHandler(VALUES[TARGET_TYPE.TARGETED_SEGMENTS])}
              mode="multiple"
              size="large"
              placeholder={t('client.profile.edit.select_segments')}
              options={getOptions(segments)}
              className="full-width"
            />
          </Col>
        </Row> */}
      </div>
    </div>
  )
}
