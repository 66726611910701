import React from 'react'

import { Form, QButton, QInput } from 'quantum_components'

import { ICartServiceItem } from '../../../modules/client/testahelBox/testahelBox.types'
import { renderRoundedNumber } from '../../../helpers/currency'
import './styles.scss'
import { REQUEST_STATUS } from '../../../constants/request'
import { useSelector } from 'react-redux'
import { getPartnerLocked } from 'src/modules/client/marketplace/marketplace.selectors'
import { getCurrentUser } from 'src/modules/core/core.selectors'
import { DISTRIBUTION_TYPE } from 'src/constants/testahel_brief'
import { REGEXES } from 'src/constants'

const checkedCard = (
  <svg viewBox="0 0 18 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M13.866 12.076c.3-.02.555-.206.673-.468l2.39-5.313a.797.797 0 00-.725-1.123h-11.8l-.465-1.326-.002.001a.794.794 0 00-.75-.535H.797a.797.797 0 100 1.594h1.825l3.068 8.766H4.25a1.594 1.594 0 101.594 1.593h7.437a1.594 1.594 0 101.594-1.593H7.379l-.398-1.137zm-7.431-1.101l-1.473-4.21H14.97l-1.688 3.753z" />
      <path
        d="M15.223.501c.325-.015.656.094.916.33a1.4 1.4 0 01.431.944 1.32 1.32 0 01-.352.955L11.22 8.128c-.281.304-.66.466-1.043.48a1.495 1.495 0 01-1.084-.405l-2.81-2.81a1.304 1.304 0 010-1.85 1.304 1.304 0 011.85 0l1.997 1.998L14.343.915c.236-.26.555-.398.88-.414z"
        stroke="#D15E28"
      />
    </g>
  </svg>
)

export interface Props {
  service: ICartServiceItem
  imageUrl?: string
  selected?: boolean
  onInputChange: (e: any, name: string) => void
  onClickAdd: (service: ICartServiceItem) => void
  onClickRemove: (service: ICartServiceItem) => void
  viewMode?: boolean
  details?: any
}

const TestahelMobileServiceCard = ({
  selected = false,
  imageUrl,
  service,
  onInputChange,
  onClickAdd,
  onClickRemove,
  viewMode,
  details,
  ...props
}: Props) => {
  const price = renderRoundedNumber(service.amount, service.currency)
  const total = renderRoundedNumber(service.total, service.currency)
  const isDisabled = !(service.quantity && service.quantity > 0 && !service.quantity.toString().split('.')[1])
  const partnerLocked = useSelector(getPartnerLocked)
  const currentUser = useSelector(getCurrentUser)

  const getApproxDistributionTime = (service: any) => {
    if (
      (service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE || service.name === DISTRIBUTION_TYPE.PRINTED_INSERT) &&
      details &&
      Object.keys(details?.monthlyOrderRange).length > 0
    ) {
      let weeks = Math.round(
        (parseInt(service.quantity) / details?.monthlyOrderRange?.min +
          parseInt(service.quantity) / details?.monthlyOrderRange?.max) /
          2
      )
      if (service.quantity && parseInt(service.quantity) > 0 && parseInt(service.quantity) % 1 === 0) {
        return `Approx ${weeks === 0 || weeks === 1 ? 1 + ' week' : weeks + ' weeks'}  for ${
          details?.companyName
        } to distribute ${service.quantity} samples`
      } else {
        return ''
      }
    }
  }

  return (
    <div className={`qu-services-item ${selected ? 'selected' : ''}`}>
      <div className="checked-icon">{checkedCard}</div>
      <div className="qu-services-body">
        {imageUrl && (
          <div className="qu-services-thumbnail">
            <img src={imageUrl} alt="thumbnail" />
          </div>
        )}
        <div className="qu-services-form">
          <div className="qu-services-name">
            {service.name.replace(/CR /g, '')}
            {service.requestStatus === REQUEST_STATUS.REJECTED && (
              <span className={'text-red font-size-10 ml-5'}>(rejected)</span>
            )}
          </div>
          <div className="qu-services-description">
            <span className="qu-services-type">{service.type}</span>
            <span
              className={`qu-services-prise ${partnerLocked && !currentUser.isApproved ? 'cell-pricing-blur' : ''}`}
            >
              {price}
            </span>
          </div>
          {!viewMode && (
            <div className="qu-services-input">
              <Form>
                <Form.Item
                  className="mb-0"
                  name="quantity"
                  rules={[
                    {
                      pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                      message: 'Invalid quantity!',
                    },
                  ]}
                  validateTrigger={['onBlur', 'onChange']}
                >
                  <QInput
                    id={service.name}
                    value={service.quantity}
                    size="large"
                    placeholder="Please enter quantity."
                    suffix="QTY"
                    type="number"
                    min={0}
                    onChange={(e: any) => onInputChange(e, service.name)}
                    disabled={selected}
                  />
                </Form.Item>
              </Form>
            </div>
          )}
          {!selected && <span className="mt-5">{getApproxDistributionTime(service)}</span>}
        </div>
      </div>
      {!viewMode && (
        <div className="qu-services-footer">
          <div className="qu-services-value">{total}</div>
          <div className="qu-services-controls">
            {selected ? (
              <div className="remove-button" onClick={() => onClickRemove(service)}>
                Remove from Card
              </div>
            ) : (
              <QButton
                disabled={isDisabled}
                className="qu-button-outline"
                size="small"
                onClick={() => onClickAdd(service)}
              >
                Add To Cart
              </QButton>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default TestahelMobileServiceCard
