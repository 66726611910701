export const MODULE_NAME = 'bill'
/* actions */

export const FETCH_BILLS_REQUEST = `${MODULE_NAME}/FETCH_BILLS_REQUEST`
export const FETCH_BILLS_SUCCESS = `${MODULE_NAME}/FETCH_BILLS_SUCCESS`
export const FETCH_BILLS_FAILURE = `${MODULE_NAME}/FETCH_BILLS_FAILURE`

export const SET_BILL_LIST_PARAMS = `${MODULE_NAME}/SET_BILL_LIST_PARAMS`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`

export const FILE_UPLOAD_REQUEST = `${MODULE_NAME}/FILE_UPLOAD_REQUEST`
export const FILE_UPLOAD_SUCCESS = `${MODULE_NAME}/FILE_UPLOAD_SUCCESS`
export const FILE_UPLOAD_FAILURE = `${MODULE_NAME}/FILE_UPLOAD_FAILURE`

export const FILE_REMOVE_REQUEST = `${MODULE_NAME}/FILE_REMOVE_REQUEST`
export const FILE_REMOVE_SUCCESS = `${MODULE_NAME}/FILE_REMOVE_SUCCESS`
export const FILE_REMOVE_FAILURE = `${MODULE_NAME}/FILE_REMOVE_FAILURE`
