import axios, { AxiosError, AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios'
import get from 'lodash/get'

import * as util from './../utils'
import { mockAxios } from './mocks'
import localStorage from 'redux-persist/es/storage'
import { LOCAL_STORAGE_KEYS } from '../constants'
import { store } from '../store'
import * as coreActions from 'src/modules/core/core.actions'

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IExtendedAxiosRequestConfig extends AxiosRequestConfig {
  /**
   * serverInterceptor will skip the included statuses
   */
  statusesNoErrors?: number[]
  /**
   * serverInterceptor will be skipped if true
   */
  skipError?: boolean

  multipartFormData?: boolean

  skipDownloadProgress?: boolean
}

interface IAxiosExtendedInstance extends AxiosInstance {
  get<T = any, R = AxiosResponse<T>>(url: string, config?: IExtendedAxiosRequestConfig): Promise<R>
  delete<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: IExtendedAxiosRequestConfig): Promise<R>
  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: IExtendedAxiosRequestConfig): Promise<R>
  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: IExtendedAxiosRequestConfig): Promise<R>
  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: IExtendedAxiosRequestConfig): Promise<R>
}

const instance = axios.create({
  baseURL: util.getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
}) as IAxiosExtendedInstance

mockAxios(instance)

instance.interceptors.request.use((config: IExtendedAxiosRequestConfig) => {
  localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN).then((token: string | null) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
  })

  localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN).then((token1: string | null) => {
    let APIURL: any = config.url
    if (
      (APIURL == 'create-temp-account' ||
        APIURL == 'client/user-checkpoint' ||
        APIURL == 'brief/create' ||
        APIURL.includes('brief/delete/') ||
        APIURL == 'brief/brief-settings' ||
        APIURL.includes('brief/view/') ||
        APIURL.includes('cart/linear/') ||
        APIURL == 'brief/upload' ||
        APIURL == 'brief/edit-step/BRIEF_PRODUCT' ||
        APIURL == 'brief/edit-step/BRIEF_TARGETING' ||
        APIURL == 'brief/remove-media' ||
        APIURL == 'brief/edit-step/BRIEF_SERVICES' ||
        APIURL == 'brief/edit-step/BRIEF_MEDIA_BUY' ||
        APIURL == 'brief/final-approve/' ||
        APIURL == 'brief/edit-step/BRIEF_TYPE' ||
        APIURL == 'agegroups/list' ||
        APIURL.includes('brief/get-audience/') ||
        APIURL.includes('partner/single/') ||
        APIURL == 'brief/focus-group' ||
        APIURL == 'brief/filter-settings' ||
        APIURL == 'cart/upsert' ||
        APIURL == 'segments/list') &&
      token1
    ) {
      config.headers.Authorization = `Bearer ${token1}`
    }
  })

  if (!config.skipDownloadProgress) {
    store.dispatch(coreActions.setProgress(0))

    config.onDownloadProgress = progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      store.dispatch(coreActions.setProgress(percentCompleted))

      if (percentCompleted > 99) {
        setTimeout(() => store.dispatch(coreActions.resetProgress()), 300)
      }
    }
  }

  if (config.multipartFormData) {
    config.headers.contentType = 'multipart/form-data'
  }

  return config
})

instance.interceptors.response.use(undefined, async (error: AxiosError): Promise<any> => {
  const response = get(error, 'message.isAxiosError') ? get(error, 'message.response') : get(error, 'response')

  const errorData = response || error

  return Promise.reject(errorData)
})

export default instance
