import React from 'react'
import { QButton } from 'quantum_components'

const checkIcon = (
  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M437.019 74.98C388.667 26.629 324.38 0 256 0 187.619 0 123.332 26.629 74.98 74.98 26.629 123.332 0 187.62 0 256s26.629 132.667 74.98 181.019C123.332 485.371 187.62 512 256 512s132.667-26.629 181.019-74.98C485.371 388.667 512 324.38 512 256s-26.629-132.667-74.981-181.02zm-58.713 120.093L235.241 338.139c-2.929 2.929-6.768 4.393-10.606 4.393-3.839 0-7.678-1.464-10.607-4.393l-80.334-80.333c-5.858-5.857-5.858-15.354 0-21.213 5.857-5.858 15.355-5.858 21.213 0l69.728 69.727 132.458-132.46c5.857-5.858 15.355-5.858 21.213 0 5.858 5.858 5.858 15.355 0 21.213z" />
  </svg>
)

export interface Props {
  onOkay: () => void
}

const DiscountThanks = ({ onOkay }: Props) => {
  return (
    <div className="discount-submit">
      <div className="grid-row grid-row--jcc">
        <div className="grid-col grid-col--auto">
          <div className="w-96 text-lightgreen">{checkIcon}</div>
        </div>
      </div>
      <div className="mt--200 h1 discount-submit__title">Thank you for participating in our referral program!</div>
      <p className="mt--50 font-size-24 tc--light">
        Once all the emails you referred sign-up to our platform, <strong>you will recieve a 5% discount</strong> on
        your next product brief
      </p>
      <div className="grid-row grid-row--jcc mt-70 mb-20">
        <div className="grid-col grid-col--auto">
          <QButton onClick={onOkay} className="w-180" type="primary">
            Okay
          </QButton>
        </div>
      </div>
    </div>
  )
}
export default DiscountThanks
