export const MODULE_NAME = 'referral'
/* actions */

export const RESET_REFERRAL_STATE = `${MODULE_NAME}/RESET_REFERRAL_STATE`

export const CREATE_REFERRAL_REQUEST = `${MODULE_NAME}/CREATE_REFERRAL_REQUEST`
export const CREATE_REFERRAL_SUCCESS = `${MODULE_NAME}/CREATE_REFERRAL_SUCCESS`
export const CREATE_REFERRAL_FAILURE = `${MODULE_NAME}/CREATE_REFERRAL_FAILURE`

export const FETCH_COUPONS_REQUEST = `${MODULE_NAME}/FETCH_COUPONS_REQUEST`
export const FETCH_COUPONS_SUCCESS = `${MODULE_NAME}/FETCH_COUPONS_SUCCESS`
export const FETCH_COUPONS_FAILURE = `${MODULE_NAME}/FETCH_COUPONS_FAILURE`
