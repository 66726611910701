// @ts-nocheck
import React, { ReactElement, useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { isMobile } from 'react-device-detect'
import * as actions from 'src/modules/partner/requests/request.actions'
import {
  Col,
  Dropdown,
  IconsCommon,
  Menu,
  QInput,
  QMenuItem,
  QPagination,
  QSelect,
  QTable,
  RequestCardItem,
  Row,
} from 'quantum_components'
import { useSelector } from 'react-redux'
import { getPages, getRequestParams, getTotalRequestList } from 'src/modules/partner/requests/request.selectors'
import useDebounce from 'src/hooks/useDebounce'
import {
  IRequestInfo,
  IRequestTable,
  IRequestTableProduct,
  IRequestTableService,
} from 'src/modules/partner/requests/request.types'
import { REQUEST_STATUS, requestStatusesLabel } from 'src/constants/request'
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_PAYMENT_STATUS_NAMES,
  briefPaymentStatuses,
  MEDIA_GROUP_TYPE,
} from 'src/constants/brief'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-icon.svg'
import { getLocaleCost, renderRoundedNumber } from '../../helpers/currency'
import { getCurrency } from '../../modules/core/core.selectors'
import { CURRENCY_SYMBOLS } from '../../constants/currencies'
import { IBillListElementPaymentDto } from '../../modules/client/bills/bills.types'
import { ISelectInputOption } from '../../modules/billings/billings.types'

interface IProps {
  requestList: IRequestTable[]
  loading: boolean
  hideSearch?: boolean
  requestInfo?: IRequestInfo
  hidePagination?: boolean
}

export default function RequestTableComponent({
  requestList,
  loading,
  hideSearch,
  requestInfo,
  hidePagination,
}: IProps): ReactElement {
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const params = useSelector(getRequestParams)
  const totalRequestList = useSelector(getTotalRequestList)
  const pages = useSelector(getPages)
  const currency = useSelector(getCurrency)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const tableLoading = {
    spinning: loading,
    indicator: <div className="loader loader-small" />,
  }

  useEffect(() => {
    if (hideSearch) return
    dispatch(actions.setParamsAndFetch({ ...params, search: debouncedSearch, offset: 0, limit: 10 }))
    dispatch(actions.setCurrentPage(1))
  }, [debouncedSearch])

  const handleChangeSearch = (e: any) => {
    setSearch(e.currentTarget.value)
  }

  const handleChangeSelect = (activePaymentStatuses: string[]) => {
    dispatch(actions.setParamsAndFetch({ ...params, offset: 0, paymentStatus: activePaymentStatuses }))
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setCurrentPage(page))
    await dispatch(actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleView = async (requestId: number) => {
    history.push(`/requests/${requestId}`)
  }

  const menu = (id: number) => (
    <Menu className="user-menu-list">
      <QMenuItem key={id} onClick={() => handleView(id)} name="View" icon={<EyeIcon />} />
    </Menu>
  )

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      width: '30%',
      fixed: 'left',
      render: (product: IRequestTableProduct, record: IRequestTable) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.image && <img src={product.image} alt={product.title} />}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.title || ''}</h3>
              <div className="q-table-product_created">
                <span className={record.status === REQUEST_STATUS.REVIEW_NOW ? 'text-primary' : ''}>
                  Received {product.received}
                </span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Services',
      dataIndex: 'service',
      key: 'service',
      width: '20%',
      render: (service: IRequestTableService) => {
        return (
          <ul className="q-table-list">
            <li className="q-table-list_item font-weight-500">
              <span className="mr-10">
                {service.title === MEDIA_GROUP_TYPE.MEDIA_BUY ? (
                  <IconsCommon.IconDevices />
                ) : (
                  <IconsCommon.IconSample />
                )}
              </span>
              {service.title}
            </li>
          </ul>
        )
      },
    },
    {
      title: 'Start & Duration',
      dataIndex: 'service',
      key: 'service',
      width: '20%',
      render: (service: IRequestTableService) => (
        <div>
          <div>{service.startDate}</div>
          <div>{service.duration}</div>
        </div>
      ),
    },
    {
      title: 'Revenue',
      dataIndex: 'service',
      key: 'service',
      width: '15%',
      render: (service: IRequestTableService) => (
        <span className="font-size-14 font-weight-600">{service.revenue}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status: REQUEST_STATUS) => {
        return (
          <span className={`status-label ${requestStatusesLabel[status] && requestStatusesLabel[status].classStyle}`}>
            {requestStatusesLabel[status] ? requestStatusesLabel[status].label : REQUEST_STATUS.REVIEW_NOW}
          </span>
        )
      },
    },
    {
      title: 'Payment Status',
      dataIndex: 'payment',
      key: 'paymentStatus',
      width: '19%',
      fixed: 'right',
      render: (payment: IBillListElementPaymentDto | null, record: IRequestTable) => {
        const paymentStatus = payment?.status
        return (
          <div>
            {paymentStatus ? (
              <span className={`status-label ${briefPaymentStatuses[paymentStatus]?.classStyle}`}>
                {briefPaymentStatuses[paymentStatus]?.label}
              </span>
            ) : (
              <span>-</span>
            )}

            {/* QUA-1449, QP-492 - prevent event bubbling to onRowClick */}
            <div onClick={e => e.stopPropagation()}>
              <Dropdown overlay={menu(record.key)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  const handleOnRowClick = (request: IRequestTable) => ({
    onClick: (event: any) => history.push(`/requests/${request.key}`),
  })

  const statusOptions: ISelectInputOption[] = [
    { label: BRIEF_PAYMENT_STATUS_NAMES[BRIEF_PAYMENT_STATUS.PENDING], value: BRIEF_PAYMENT_STATUS.PENDING },
    { label: BRIEF_PAYMENT_STATUS_NAMES[BRIEF_PAYMENT_STATUS.PAID], value: BRIEF_PAYMENT_STATUS.PAID },
  ]

  return (
    <div className="qt">
      <div className="qu-statistic-wrapper">
        <h1>Requests</h1>
        {requestInfo && (
          <ul className="qu-statistic-list">
            <li className="qu-statistic-item">
              <span className="qu-statistic-value">{requestInfo.total}</span>
              <span className="qu-statistic-name">Total Requests</span>
            </li>
            <li className="qu-statistic-item">
              <span className="qu-statistic-value">{requestInfo.acceptance}%</span>
              <span className="qu-statistic-name">Acceptance</span>
            </li>
            <li className="qu-statistic-item">
              <span className="qu-statistic-value">
                -{getLocaleCost(Math.abs(requestInfo.revenueLost))} {CURRENCY_SYMBOLS[currency]}
              </span>
              <span className="qu-statistic-name">Revenue Lost</span>
            </li>
            <li className="qu-statistic-item">
              <span className="qu-statistic-value">{renderRoundedNumber(requestInfo.open, currency)}</span>
              <span className="qu-statistic-name">Open Requests</span>
            </li>
          </ul>
        )}
      </div>
      {!hideSearch && (
        <Row className="qt-header" justify="space-between">
          <Col className="qt-search">
            <QInput
              className={`${isMobile && 'full-width'}`}
              value={search}
              onChange={handleChangeSearch}
              placeholder="Search"
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>

          {!isMobile && (
            <Col className="qt-filter">
              <QSelect
                showArrow
                maxTagTextLength={8}
                maxTagCount={2}
                value={params.status}
                onChange={handleChangeSelect}
                mode="multiple"
                size="large"
                placeholder="Filter by status"
                options={statusOptions}
              />
            </Col>
          )}
        </Row>
      )}
      {isMobile ? (
        <ul className="qu-request-list">
          {requestList.map((item: IRequestTable) => (
            <RequestCardItem
              key={item.key}
              data={item}
              onClick={() => handleView(item.key)}
              paymentStatus={briefPaymentStatuses}
            />
          ))}
        </ul>
      ) : (
        <div className="qt-body">
          <QTable
            //@ts-ignore
            onRow={handleOnRowClick}
            //@ts-ignore
            loading={tableLoading}
            columns={columns}
            dataSource={requestList}
            rowClassName="q-table cursorPointer"
          />
        </div>
      )}
      {!hidePagination && (
        <div className="qt-footer">
          <QPagination
            size="small"
            defaultCurrent={1}
            current={pages.currentPage}
            total={totalRequestList}
            onChange={handleChangePage}
          />
        </div>
      )}
    </div>
  )
}
