import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import { FORMATS, LOCAL_STORAGE_KEYS, STATUS_CODES } from 'src/constants'
import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from 'src/helpers'
import { IBriefPartnerDetailsResponse, IBriefSettingsResponse } from '../brief/brief.types'

export const fetchBriefSettings = (): ThunkAction<Promise<AxiosResponse<void>>, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<void>> => {
    try {
        const response = await requestHttp.get(urls.getBriefSettingsUrl())
        return response
    } catch (error) {
        console.log(error)
    }
}

export const fetchPartnerDetails = (briefId: number, partnerId: number): ThunkAction<Promise<AxiosResponse<IBriefPartnerDetailsResponse>>, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IBriefPartnerDetailsResponse>> => {
    try {
        const response = await requestHttp.get<IBriefPartnerDetailsResponse>(urls.getBriefPartnerDetailsUrl(briefId, partnerId))
        return response
    } catch (error) {
        return error
    }
}