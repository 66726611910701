import { ReactElement, useEffect, useRef, useState } from "react";
import { isMobile } from 'react-device-detect'
import { GoogleLogin } from 'react-google-login'
import { MenuOutlined, ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { gapi } from 'gapi-script';
import ReCAPTCHA from 'react-google-recaptcha'
import AOS from "aos";
import "aos/dist/aos.css";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Lottie from 'react-lottie';

import targetedLottieImage from '../../../../assets/home/targeted-lottie-img.json';
import ourGlobalLottieImage from '../../../../assets/home/our-global-lottie-img.json';
import { ReactComponent as LogoBlackSVG } from '../../../../assets/home/logo-black.svg'
import { ReactComponent as LogoWhiteSVG } from '../../../../assets/home/logo-white.svg'
import targetingImage from '../../../../assets/home/targeting.png'
import companiesImage from '../../../../assets/home/companies.png'
import laptopImage from '../../../../assets/home/laptop.png'
import tabletImage from '../../../../assets/home/tablet.png'
import mobileImage from '../../../../assets/home/mobile.png'

import { Row, Col, QButton, Form, QInput, Modal, Dropdown } from 'quantum_components'
import { Layout, Menu } from 'antd';
import { LOCALIZATION_LANGUAGES, LOCAL_STORAGE_KEYS, PATHS, ROLES } from "src/constants";
import history from "src/utils/history";
import { SOCIAL_LOGIN_TYPES } from "src/constants/common";
import { SocialFormValues } from "../../signUp/containers/SignUp.container";
import { useReduxDispatch } from "src/helpers";
import * as actions from "../../signIn/signIn.actions";
import * as signupActions from 'src/modules/registration/signUp/signUp.actions'
import { Store } from "antd/lib/form/interface";
import { useTranslation } from "react-i18next";
import SpinnerCenter from "src/components/Spinner/spinner";

const { Header } = Layout;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''

export default function HomeContainer({
  hidePartnerInfo,
  specialClientData,
}): ReactElement {
  const ref = useRef(null);
  const dispatch = useReduxDispatch();
  const { t } = useTranslation(["translationUser"]);
  const { i18n } = useTranslation();
  const [form] = Form.useForm()
  const [loginForm] = Form.useForm()
  const clientId = process.env.REACT_APP_GOOGLE_LOGIN_KEY
  const sysLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALIZATION) || "en";

  const [hideGoogleLogin, setHideGoogleLogin] = useState(false);
  const [reCaptcha, setRecaptcha] = useState<string>('')
  const [reCaptchaError, setRecaptchaError] = useState<boolean>(false)
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [signinSuccess, setSigninSuccess] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [signupEmail, setSignupEmail] = useState("");
  const [langUpdate, setlangUpdate] = useState(0);
  const [showLangLoader, setShowLangLoader] = useState(false);

  const [isTargetedLottieImagePlaying, setIsTargetedLottieImagePlaying] = useState(false);
  const [isOurGlobalLottieImagePlaying, setIsOurGlobalLottieImagePlaying] = useState(false);
  const [targetedKey, setTargetedKey] = useState(0);
  const [ourGlobalKey, setOurGlobalKey] = useState(0);

  useEffect(() => {
    gapi.load('client:auth2', initClient);

    // init and refresh the AOS library
    AOS.init();
    AOS.refresh();

    i18n.changeLanguage(
      sysLang == LOCALIZATION_LANGUAGES.ARABIC
        ? LOCALIZATION_LANGUAGES.ARABIC
        : LOCALIZATION_LANGUAGES.ENGLISH
    );

    i18n.changeLanguage(
      sysLang === LOCALIZATION_LANGUAGES.ARABIC
        ? LOCALIZATION_LANGUAGES.ARABIC
        : LOCALIZATION_LANGUAGES.ENGLISH
    );

    if (
      !window.location.host.includes('localhost') &&
      !window.location.host.includes('saas-dev') &&
      !window.location.host.includes('live') &&
      !window.location.host.includes('prod') &&
      !window.location.host.includes('srmg')
    ) {
      history.push(PATHS.SIGN_IN)
    }

    const fifthSectionObserver = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setIsTargetedLottieImagePlaying(true);
        setTargetedKey(Math.floor(Math.random() * 1000 + 1))
      } else {
        setIsTargetedLottieImagePlaying(false);
        setTargetedKey(Math.floor(Math.random() * 1000 + 1))
      }
    });
    fifthSectionObserver.observe(document.querySelector(".fifth-section"));

    const sixthSectionObserver = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setIsOurGlobalLottieImagePlaying(true);
        setOurGlobalKey(Math.floor(Math.random() * 1000 + 1))
      } else {
        setIsOurGlobalLottieImagePlaying(false);
        setOurGlobalKey(Math.floor(Math.random() * 1000 + 1))
      }
    });
    sixthSectionObserver.observe(document.querySelector(".sixth-section"));
  }, [])

  function initClient() {
    gapi.client.init({
      clientId: clientId,
      scope: ''
    });
  };

  const getLocalizationLanguage = () => {
    return sysLang === LOCALIZATION_LANGUAGES.ENGLISH ? "العربية" : "English";
  };

  const changeLanguage = () => {
    setShowLangLoader(true);
    if (sysLang && sysLang === LOCALIZATION_LANGUAGES.ENGLISH) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ARABIC
      );
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ARABIC);
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALIZATION,
        LOCALIZATION_LANGUAGES.ENGLISH
      );
      i18n.changeLanguage(LOCALIZATION_LANGUAGES.ENGLISH);
    }
    setlangUpdate(langUpdate + 1);
    window.location.reload()
  };


  const handleRegisterClick = () => {
    // ref.current?.scrollIntoView({ behavior: 'smooth' });
    history.push(PATHS.SIGN_UP)
  }

  const handleLoginClick = () => {
    // setShowLoginModal(true)
    history.push(PATHS.SIGN_IN)
  }

  const handleLoginSuccess = async (data: any) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = "";
    let specialId = "";
    let briefId = "";
    const resumeBriefId = urlParams.has("resumeBriefId")
      ? urlParams.get("resumeBriefId")
      : "";

    if (specialClientData && specialClientData.p) {
      // data coming from sign in modal in invite client
      token = specialClientData.t;
      specialId = specialClientData.p;
      briefId = specialClientData.b;
    } else {
      // data coming from query string
      token = urlParams.has("t") ? urlParams.get("t") : "";
      specialId = urlParams.has("p") ? urlParams.get("p") : "";
      briefId = urlParams.has("b") ? urlParams.get("b") : null;
    }

    const formValues = {
      email: data.profileObj.email,
      firstName: data.profileObj.givenName,
      lastName: data.profileObj.familyName,
      socialToken: data.tokenId,
      socialType: SOCIAL_LOGIN_TYPES.GOOGLE,
      role: ROLES.CLIENT
    } as SocialFormValues
    dispatch(actions.socialSignUp(formValues, token, specialId, briefId, resumeBriefId))
  }

  const handleLoginFailure = async (error: any) => {
    if (error.error === 'idpiframe_initialization_failed') setHideGoogleLogin(true)
    else setHideGoogleLogin(false)
    console.log('error', error)
  }

  const hanldeSignUp = async (values: Store) => {
    // if (!reCaptcha) {
    //   setRecaptchaError(true)
    //   return
    // }
    const response = await dispatch(signupActions.quickSignUp(values.email))
    if (response && response.status === 201) {
      form.resetFields();
      setSignupSuccess(true);
      setSignupEmail(values.email);
    }
  }

  const handleLogin = async (values: Store) => {
    const response = await dispatch(actions.quickSignIn(values.email))
    if (response && response.status === 200) {
      // setShowLoginModal(false)
      loginForm.resetFields()
      setSigninSuccess(true)
    }
  }

  const handleReCaptcha = (token: string | null) => {
    if (token) {
      setRecaptcha(token)
      setRecaptchaError(false)
    } else {
      setRecaptcha('')
      setRecaptchaError(true)
    }
  }

  return (
    showLangLoader
      ? <SpinnerCenter />
      : <div className={`auth-home-parallax ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}>
        {
          (
            window.location.host.includes('localhost') ||
            window.location.host.includes('saas-dev') ||
            window.location.host.includes('live') ||
            window.location.host.includes('prod') ||
            window.location.host.includes('srmg')
          ) &&
          <ParallaxProvider>
            <Layout>
              <Header className="home-header">
                <div className="image-logo">
                  <LogoWhiteSVG />
                </div>

                {
                  isMobile
                    ? <Dropdown
                      className="mobile-menu-dropdown"
                      overlay={
                        <Menu className="user-menu-list">
                          <Menu.Item key="1">
                            <a onClick={handleRegisterClick}>
                              {t("user.home.Create account")}
                            </a>
                          </Menu.Item>
                          <Menu.Item key="2">
                            <a onClick={handleLoginClick}>
                              {t("user.home.Advertiser Login")}
                            </a>
                          </Menu.Item>
                          <Menu.Item key="3">
                            <a href={`${window.location.origin}:8081`} target="_blank">
                              {t("user.home.Publisher Login")}
                            </a>
                          </Menu.Item>
                          {sysLang && <Menu.Item key="4">
                            <a onClick={() => changeLanguage()}>
                              {getLocalizationLanguage()}
                            </a>
                          </Menu.Item>}
                        </Menu>}
                      trigger={['click']}
                      placement="bottomRight"
                      getPopupContainer={(node: any) => node.parentNode}
                    >
                      <div className="user-menu">
                        <MenuOutlined />
                      </div>
                    </Dropdown>
                    : <Menu mode="horizontal" className="home-menu">
                      <Menu.Item key="1">
                        <QButton type="primary" size='large' onClick={handleRegisterClick} className="create-account-button">
                          {t("user.home.Create account")}
                        </QButton>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <QButton type="primary" size='large' onClick={handleLoginClick} className="create-account-button">
                          {t("user.home.Advertiser Login")}
                        </QButton>
                      </Menu.Item>
                      <Menu.Item key="3">
                        <QButton type="primary" size='large' onClick={() => window.open(`${window.location.origin}:8081`, '_blank')} className="create-account-button">
                          {t("user.home.Publisher Login")}
                        </QButton>
                      </Menu.Item>
                      {sysLang && <Menu.Item key="4">
                        <QButton onClick={() => changeLanguage()} type="primary" shape="round" size='large' className="language-button">
                          {getLocalizationLanguage()}
                        </QButton>
                      </Menu.Item>}
                    </Menu>
                }
              </Header>
            </Layout>

            <Row className="first-section">
              <Col xs={24} md={12} className="left-side">
                <p><LogoWhiteSVG /> {t("user.home.Ads")}</p>
                <p><span>{t("user.home.Maximize your reach with our global media group from the Middle East")}</span></p>
                <QButton type="primary" shape="round" size='large' className="start-here-button" onClick={handleRegisterClick}>
                  {t("user.home.Create an ad")}{i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? <ArrowLeftOutlined style={{ paddingTop: '2px', paddingRight: '7px' }} /> : <ArrowRightOutlined style={{ paddingTop: '2px' }} />}
                </QButton>
              </Col>
              <Col xs={0} md={12}>
                <Parallax
                  className="parallax-girl-img"
                  speed={0}
                  translateX={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [10, 0] : [-10, 10]}
                  disabled={isMobile}
                >
                  <div className="image-bg"></div>
                </Parallax>
              </Col>
            </Row>

            <Row justify="center" align="middle" className="second-section">
              <Col xs={24} md={20} lg={18} xl={14} xxl={10}>
                <div
                  className="p1"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  <LogoWhiteSVG />
                  &nbsp;
                  <span>
                    {t("user.home.Ads")}
                  </span>
                </div>
                <p
                  className="p2"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >{t("user.home.Connect with your ideal audience when it matters most, all within a budget that suits you.")}</p>
              </Col>
            </Row>

            <Row justify="center" align="middle" className="third-section-1">
              <Col xs={24} md={20} lg={18} xl={14} xxl={10}>
                <div
                  className="p1"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  {t("user.home.Interactive Ad Formats")}
                </div>
                <p
                  className="p2"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  {t("user.home.Take User experience to the Next Level")}
                </p>
              </Col>
            </Row>

            {/* <Row justify="center" align="middle" className="third-section">
              <Col xs={24} md={12}>
                <p>
                  <span
                    data-aos="fade-in"
                    data-aos-duration="1200"
                    data-aos-mirror="true"
                    data-aos-easing="ease-in-out"
                    data-aos-once="true"
                  >
                    {t("user.home.Diverse Ad Types to Suit Every Campaign Goal")}
                  </span>
                  <br />
                  <QButton
                    type="primary"
                    shape="round"
                    size='large'
                    className="start-here-button"
                    onClick={handleRegisterClick}
                    data-aos="fade-in"
                    data-aos-duration="1200"
                    data-aos-mirror="true"
                    data-aos-easing="ease-in-out"
                    data-aos-once="true"
                  >
                    {t("user.home.Create an ad")}{i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? <ArrowLeftOutlined style={{ paddingTop: '2px', paddingRight: '7px' }} /> : <ArrowRightOutlined style={{ paddingTop: '2px' }} />}
                  </QButton>
                </p>
              </Col>
              <Col xs={24} md={12} className="devices-right-section">
                <Parallax
                  className="parallax-laptop-img"
                  disabled={isMobile}
                  easing="ease"
                  speed={5}
                  translateX={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [-10, 0] : [-20, 2]}
                  translateY={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [-10, 0] : [-20, 2]}
                >
                  <img src={laptopImage} />
                </Parallax>
                <Parallax
                  className="parallax-tablet-img"
                  disabled={isMobile}
                  easing="ease"
                  speed={5}
                  translateX={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [30, 0] : [70, -10]}
                  translateY={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [-20, 5] : [-20, 5]}
                >
                  <img src={tabletImage} />
                </Parallax>
                <Parallax
                  className="parallax-mobile-img"
                  disabled={isMobile}
                  easing="ease"
                  speed={40}
                  translateY={[25, 0]}
                >
                  <img src={mobileImage} />
                </Parallax>
              </Col>
            </Row> */}

            <Row gutter={32} justify="center" align="middle" className="third-section-2">
              <Col xs={24} md={8} xl={10} xxl={12} className="left-side">
                <p
                  className="p1"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  {t("user.home.Product customization and Add-to-Cart format")}
                </p>
                <p
                  className="p2"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  {t("user.home.Generate direct sales from your advertising Spaces maximize your ad spaces with our interactive banners. They enable users to add products to their cart and choose colors directly from the ad, boosting engagement and driving sales efficiently.")}
                </p>
              </Col>
              <Col xs={24} md={16} xl={14} xxl={12} className="right-side">
                <Parallax
                  disabled={isMobile}
                  easing="ease"
                  speed={5}
                  translateX={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [0, 10] : [10, 0]}
                >
                  <iframe scrolling="no" src={'iframes-home-page/shoes2/index.html'}></iframe>
                </Parallax>
              </Col>
            </Row>

            <Row gutter={32} justify="center" align="middle" className="third-section-3">
              <Col xs={24} md={13} xxl={12} className="left-side">
                <Parallax
                  disabled={isMobile}
                  easing="ease"
                  speed={5}
                  translateX={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [10, 0] : [0, 10]}
                >
                  <div className="left-side-div">
                    <iframe className="first-iframe" scrolling="no" src="iframes-home-page/tire/dist/index.html"></iframe>
                    <iframe scrolling="no" src="iframes-home-page/carcopy/index.html"></iframe>
                  </div>
                </Parallax>
              </Col>
              <Col xs={24} md={11} xxl={12} className="right-side">
                <p
                  className="p1"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  {t("user.home.Experience multiple offers in one interactive ad")}
                </p>
                <p
                  className="p2"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  {t("user.home.Explore multiple offers effortlessly with our single-ad format. It enhances customer engagement and decision-making by presenting a variety of deals and products in one convenient space, turning each ad into a hub of opportunities.")}
                </p>
              </Col>
            </Row>

            <Row gutter={32} justify="center" align="middle" className="third-section-4">
              <Col xs={24} md={8} xl={10} xxl={12} className="left-side">
                <p
                  className="p1"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  {t("user.home.Animated Ads for Maximum conversion")}
                </p>
                <p
                  className="p2"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  {t("user.home.Maximize conversions with Animated Ads. Their dynamic and engaging design captures attention quickly, enhancing viewer interaction and effectiveness on digital platforms.")}
                </p>
              </Col>
              <Col xs={24} md={16} xl={14} xxl={12} className="right-side">
                <Parallax
                  disabled={isMobile}
                  easing="ease"
                  speed={5}
                  translateX={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [0, 10] : [10, 0]}
                >
                  <div className="iframes-container">
                    <iframe scrolling="no" className="iframe-left" src="iframes-home-page/cloths/index.html"></iframe>

                    <div className="iframe-right">
                      <iframe scrolling="no" className="iframe-right-1" src="iframes-home-page/camera/index.html"></iframe>
                      <iframe scrolling="no" className="iframe-right-2" src="iframes-home-page/shoe/index.html"></iframe>
                    </div>
                  </div>
                </Parallax>
              </Col>
            </Row>

            <Row justify="center" align="middle" className="fourth-section">
              <Col span={24}>
                <div
                  className="p1"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  <LogoWhiteSVG />
                  &nbsp;
                  <span>
                    {t("user.home.Numbers")}
                  </span>
                </div>
                <Row
                  justify='center'
                  className="stats"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  <Col xs={12} sm={4}>
                    <span className="top-labels">1972</span>
                    <br />
                    <span className="bottom-labels">{t("user.home.Established")}</span>
                  </Col>
                  <Col xs={12} sm={4}>
                    <span className="top-labels">+2000</span>
                    <br />
                    <span className="bottom-labels">{t("user.home.Employees")}</span>
                  </Col>
                  <Col xs={12} sm={4}>
                    <span className="top-labels">11</span>
                    <br />
                    <span className="bottom-labels">{t("user.home.Countries")}</span>
                  </Col>
                  <Col xs={12} sm={4}>
                    <span className="top-labels">+38</span>
                    <br />
                    <span className="bottom-labels">{t("user.home.Nationalities")}</span>
                  </Col>
                </Row>
                <Row
                  justify='center'
                  className="stats"
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  <Col xs={12} sm={4}>
                    <span className="top-labels">4</span>
                    <br />
                    <span className="bottom-labels">{t("user.home.Continents")}</span>
                  </Col>
                  <Col xs={12} sm={4}>
                    <span className="top-labels">+30</span>
                    <br />
                    <span className="bottom-labels">{t("user.home.Titles")}</span>
                  </Col>
                  <Col xs={12} sm={4}>
                    <span className="top-labels">+25</span>
                    <br />
                    <span className="bottom-labels">{t("user.home.Websites")}</span>
                  </Col>
                  <Col xs={12} sm={4}>
                    <span className="top-labels">165m</span>
                    <br />
                    <span className="bottom-labels">{t("user.home.Reach")}</span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row justify="center" align="middle" className="fifth-section">
              <Col
                key={targetedKey}
                xs={24}
                md={14}
              >
                {/* <Parallax
                  disabled={isMobile}
                  easing="ease"
                  speed={10}
                  translateX={[-30, 0]}
                  translateY={[30, 0]}
                >
                  <img src={targetingImage} className="dashboard-img" />
                </Parallax> */}
                <Lottie
                  options={{
                    loop: false,
                    autoplay: false,
                    animationData: targetedLottieImage,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                      className: "lottie-svg-class",
                      id: "lottie-svg-id"
                    }
                  }}
                  isPlaying={isTargetedLottieImagePlaying}
                />
              </Col>
              <Col
                xs={24}
                md={10}
                data-aos="fade-in"
                data-aos-duration="1200"
                data-aos-mirror="true"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
              >
                <p className="p1">{t("user.home.Targeting to hit target audience's bullseye.")}</p>
                <p className="p2">{t("user.home.Our ad targeting strategy is like hitting the bullseye. We use smart analytics to make sure each ad reaches and resonates with the right audience, ensuring effective engagement and better results.")}</p>
              </Col>
            </Row>

            <Row justify="center" align="middle" className="sixth-section">
              <Col
                xs={24}
                md={10}
                data-aos="fade-in"
                data-aos-duration="1200"
                data-aos-mirror="true"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
              >
                <p className="p1">{t("user.home.Global media group from the Middle East")}</p>
                <p className="p2">{t("user.home.A portfolio of news and culture companies that brings people closer through information, world-class journalism and technology")}</p>
              </Col>
              <Col
                key={ourGlobalKey}
                xs={24}
                md={14}
              >
                {/* <Parallax
                  disabled={isMobile}
                  easing="ease"
                  speed={0}
                  translateX={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [10, 0] : [-20, -5]}
                  translateY={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? [30, 0] : [30, 0]}
                >
                  <img src={companiesImage} className="companies-img" />
                </Parallax> */}
                <Lottie
                  options={{
                    loop: false,
                    autoplay: false,
                    animationData: ourGlobalLottieImage,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                      className: "lottie-svg-class",
                      id: "lottie-svg-id"
                    }
                  }}
                  isPlaying={isOurGlobalLottieImagePlaying}
                />
              </Col>
            </Row>

            <Row justify="center" align="middle" className="seventh-section">
              <Col
                data-aos="fade-in"
                data-aos-duration="1200"
                data-aos-mirror="true"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
              >
                <p>{t("user.home.Stand out in the world of advertising.")}<br />{t("user.home.Launch your campaign today with us!")}</p>
              </Col>
            </Row>

            <div className="eighth-ninth-section">
              <div className="create-ad-button">
                <QButton
                  type="primary"
                  shape="round"
                  size='large'
                  className="start-here-button"
                  onClick={handleRegisterClick}
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  data-aos-mirror="true"
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                >
                  {t("user.home.Create an ad")}{i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? <ArrowLeftOutlined style={{ paddingTop: '2px', paddingRight: '7px' }} /> : <ArrowRightOutlined style={{ paddingTop: '2px' }} />}
                </QButton>
              </div>
              {/* <Parallax
                className="eighth-section"
                disabled={isMobile}
                easing="ease"
                speed={0}
                translateY={[-5, 5]}
              >
                <Row
                  ref={ref}
                  justify="center"
                  align="middle"
                >
                  <Col
                    xs={24}
                    sm={18}
                    md={12}
                    lg={8}
                    className="signup-section"
                    data-aos="fade-in"
                    data-aos-duration="1200"
                    data-aos-mirror="true"
                    data-aos-easing="ease-in-out"
                    data-aos-once="true"
                  >
                    <h1>
                      {t("user.home.Sign up with email")}
                    </h1>
                    {!signupSuccess &&
                      <>
                        <p>
                          {t("user.home.Enter your email address to create an account.")}
                        </p>
                        <Form layout="vertical" form={form} onFinish={hanldeSignUp}>
                          <Form.Item
                            name="email"
                            rules={[{ required: true, message: t("user.home.Email is required") }, { max: 255, type: 'email', whitespace: true, message: t("user.home.Please enter a valid email") }]}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <QInput
                              placeholder={t("user.home.Company Email")}
                              size="large"
                            />
                          </Form.Item>

                          <div className="form-footer">
                            <QButton
                              type="primary"
                              htmlType="submit"
                              className="submit-button"
                              block
                            >
                              {t("user.home.Continue")}
                            </QButton>

                            <div className="or-footer-div">
                              <div className="or-line"></div>
                              <p className="or-text">{t("user.home.OR")}</p>
                              <div className="or-line"></div>
                            </div>

                            <div className="google-login-section">
                              <GoogleLogin
                                disabled={hideGoogleLogin}
                                className='full-width ant-btn qu-button ant-btn-primary ant-btn-block google-login-button'
                                clientId={clientId}
                                buttonText={t("user.home.Sign in with Google")}
                                onSuccess={handleLoginSuccess}
                                onFailure={handleLoginFailure}
                                cookiePolicy={'single_host_origin'}
                              />
                              {
                                hideGoogleLogin && <div className="google-signin-hint">
                                  {t("user.home.We do not offer Sign In With Google in Private Window")}
                                </div>
                              }
                            </div>
                          </div>
                        </Form>
                      </>
                    }

                    {signupSuccess &&
                      <>
                        <p
                          data-aos="fade-in"
                          data-aos-duration="1200"
                          data-aos-mirror="true"
                          data-aos-easing="ease-in-out"
                          data-aos-once="true"
                        >
                          {t("user.home.Thank you for signing up!")}
                        </p>
                        <p
                          data-aos="fade-in"
                          data-aos-duration="1200"
                          data-aos-mirror="true"
                          data-aos-easing="ease-in-out"
                          data-aos-once="true"
                        >
                          {t("user.home.We've sent a verification link to your email")} <strong>{signupEmail}</strong>
                        </p>
                      </>
                    }
                  </Col>
                </Row>
              </Parallax> */}

              <Row justify="center" align="middle" className="ninth-section">
                <Col xs={6} sm={8}>
                  <p>
                    <LogoBlackSVG />
                  </p>
                </Col>
                <Col xs={24} sm={8} className="powered-text">
                  <span>
                    {t("user.home.Powered by")}
                    &nbsp;
                    <a href="https://www.quantums.com.sa" target="_blank">{t("user.home.Quantum")}</a>
                  </span>
                </Col>
                <Col xs={18} sm={8}>
                  <p>
                    <span>{t("user.home.© 2024 SRMG | All Rights Reserved.")}</span>
                  </p>
                </Col>
              </Row>
            </div>

            <Modal
              wrapClassName={`qu-modal login-home-modal ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}
              width={560}
              mask={true}
              title={<h2>{t("user.home.Sign in with email")}</h2>}
              centered={true}
              visible={showLoginModal}
              closable={true}
              onCancel={() => {
                loginForm.resetFields()
                setShowLoginModal(false)
                setSigninSuccess(false)
              }}
              footer={null}
            >
              <Row gutter={32} className="login-home-section">
                <Col className="signup-section">
                  {!signinSuccess &&
                    <>
                      <h3>{t("user.home.Enter the email you signed up with, and we’ll send a magic link to your inbox.")}</h3>
                      <Form
                        layout="vertical"
                        form={loginForm}
                        onFinish={handleLogin}
                      >
                        <Form.Item
                          name="email"
                          rules={[{ required: true, message: t("user.home.Email is required") }, { max: 255, type: 'email', whitespace: true, message: t("user.home.Please enter a valid email") }]}
                          validateTrigger={['onChange', 'onBlur']}
                        >
                          <QInput
                            placeholder={t("user.home.Company Email")}
                            size="large"
                          />
                        </Form.Item>

                        <div className="form-footer">
                          <QButton
                            type="primary"
                            htmlType="submit"
                            className="submit-button"
                            block
                          >
                            {t("user.home.Continue")}
                          </QButton>
                        </div>
                      </Form>
                    </>}
                  {signinSuccess &&
                    <p
                      data-aos="fade-in"
                      data-aos-duration="1200"
                      data-aos-mirror="true"
                      data-aos-easing="ease-in-out"
                      data-aos-once="true"
                    >
                      {t("user.home.Email sent.")} <br />{t("user.home.Click on the link in the email to login.")}
                    </p>
                  }
                </Col>
              </Row>
            </Modal>
          </ParallaxProvider>
        }
      </div>
  );
}
