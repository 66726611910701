import React from 'react'
import { useTranslation } from 'react-i18next'
import ClientHeader from '../../Headers/ClientHeader'
import { ICurrentUser } from '../../../modules/core/core.types'
import { LOCALIZATION_LANGUAGES } from '../../../constants'

interface Props {
  children: React.ReactNode
  user: ICurrentUser
}

const ClientLayout = ({ children, user }: Props) => {
  const { i18n } = useTranslation()
  return (
    <div className={`layout client qu-print-brief ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? 'html-rtl' : ''}`}>
      <div className="client-layout">
        <ClientHeader user={user} />
        <div className="client-main">
          <div className="client-content">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ClientLayout
