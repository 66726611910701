export const MODULE_NAME = 'publicProfile'

export const FETCH_PUBLIC_PROFILE_REQUEST = `${MODULE_NAME}/FETCH_PUBLIC_PROFILE_REQUEST`
export const FETCH_PUBLIC_PROFILE_SUCCESS = `${MODULE_NAME}/FETCH_PUBLIC_PROFILE_SUCCESS`
export const FETCH_PUBLIC_PROFILE_FAILURE = `${MODULE_NAME}/FETCH_PUBLIC_PROFILE_FAILURE`

export const SET_PUBLIC_PROFILE = `${MODULE_NAME}/SET_PUBLIC_PROFILE`
export const SET_PUBLIC_PROFILE_PERCENT = `${MODULE_NAME}/SET_PUBLIC_PROFILE_PERCENT`

export const IMAGE_UPLOAD_SUCCESS = `${MODULE_NAME}/IMAGE_UPLOAD_SUCCESS`
export const IMAGE_UPLOAD_FAILURE = `${MODULE_NAME}/IMAGE_UPLOAD_FAILURE`
export const IMAGE_REMOVE = `${MODULE_NAME}/IMAGE_REMOVE`

export const UNMOUNT_CLEAR_DATA = `${MODULE_NAME}/UNMOUNT_CLEAR_DATA`

export const FETCH_PARTNER_DETAILS_REQUEST = `${MODULE_NAME}/FETCH_PARTNER_DETAILS_REQUEST`
export const FETCH_PARTNER_DETAILS_SUCCESS = `${MODULE_NAME}/FETCH_PARTNER_DETAILS_SUCCESS`
export const FETCH_PARTNER_DETAILS_FAILURE = `${MODULE_NAME}/FETCH_PARTNER_DETAILS_FAILURE`
