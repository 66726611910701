import { IForm } from 'src/modules/partner/partnerProfile/profile.types'
import { isNumber, omit, pick } from 'lodash'
import {
  AVAILABLE_SERVICES_INITIAL_VALUES,
  CUSTOMERS_AND_LOCALE_INITIAL_VALUES,
  GENERAL_COMPANY_INFO,
  GENERAL_COMPANY_INFO_INITIAL_VALUES,
  GENERAL_COMPANY_INFO_VALUES,
  PARTNER_STEPS,
  SALES_INFORMATION_INITIAL_VALUES,
  SALES_INFORMATION_VALUES,
  CUSTOMERS_AND_LOCALE_VALUES,
  AVAILABLE_SERVICES_VALUES,
} from 'src/constants/profile'
import { SOCIAL_TYPES } from 'src/constants/partner'

export const checkValue = (e: any, currentStep?: string, field?: string) => {
  if (typeof e === 'boolean') {
    return currentStep !== PARTNER_STEPS.AVAILABLE ? true : field === 'isMediaBuy' ? e : true
  }
  return (
    (isNumber(e) && !!e) ||
    (!!e && typeof e === 'object' && Object.values(e).some(i => !!`${i}`)) ||
    !!((e && e.length && !Array.isArray(e)) || (e && Array.isArray(e) && e.some(v => v)))
  )
}
export const calculateProfilePercent = (form: IForm): number => {
  const originForm = formFields(form)

  const values = Object.values(originForm)
  const filled = values.filter(e => checkValue(e))
  const percent = (filled.length / values.length) * 100
  return Math.ceil(percent)
}
export const DATA_BY_STEP_PARTNER_PROFILE = {
  [PARTNER_STEPS.GENERAL]: GENERAL_COMPANY_INFO_INITIAL_VALUES,
  [PARTNER_STEPS.SALES]: SALES_INFORMATION_INITIAL_VALUES,
  [PARTNER_STEPS.CUSTOMERS]: CUSTOMERS_AND_LOCALE_INITIAL_VALUES,
  [PARTNER_STEPS.AVAILABLE]: AVAILABLE_SERVICES_INITIAL_VALUES,
}

const METRICS_BY_STEP = {
  [PARTNER_STEPS.GENERAL]: [SOCIAL_TYPES.FACEBOOK, SOCIAL_TYPES.INSTAGRAM, SOCIAL_TYPES.TWITTER],
  [PARTNER_STEPS.SALES]: [SOCIAL_TYPES.LEADS, SOCIAL_TYPES.LOOKS],
} as { [key in PARTNER_STEPS]: string[] }

export const pickStepData = (partnerForm: IForm, currentStep: PARTNER_STEPS): Partial<IForm> => {
  const stepData = pick(partnerForm, Object.keys(DATA_BY_STEP_PARTNER_PROFILE[currentStep]))

  if (Object.keys(METRICS_BY_STEP).includes(currentStep)) {
    if (currentStep === PARTNER_STEPS.SALES) {
      const res = omit(
        { ...stepData, ...pick(stepData.metrics, METRICS_BY_STEP[currentStep]) },
        GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.SOCIAL_MEDIA_URLS]
      )
      return pick(res, Object.keys(DATA_BY_STEP_PARTNER_PROFILE[currentStep]))
    }
    //@ts-ignore
    return { ...stepData, metrics: pick(stepData.metrics, METRICS_BY_STEP[currentStep]) }
  }
  return stepData
}

export const formFields = (form: IForm) => {
  return {
    ...pick(form, Object.values(GENERAL_COMPANY_INFO_VALUES)),
    ...pick(form, Object.values(SALES_INFORMATION_VALUES)),
    ...pick(form, Object.values(CUSTOMERS_AND_LOCALE_VALUES)),
    ...pick(form, Object.values(AVAILABLE_SERVICES_VALUES)),
  }
}
