import React, { FC, useCallback, useEffect } from 'react'
import { InfoCard, QInput, QTextArea, PublicProfileField, QRadio, Form, Radio } from 'quantum_components'

import { actions } from '../../../partnerProfile.module'

import '../../../styles.scss'
import { useReduxDispatch } from 'src/helpers'
import { useSelector } from 'react-redux'
import { getForm } from '../../../profile.selectors'
import { REGEXES } from 'src/constants'
import LocationSearchInput from 'src/modules/client/clientProfile/containers/components/LocationSearchInput'
import { debounce, pick } from 'lodash'
import { GENERAL_COMPANY_INFO, GENERAL_COMPANY_INFO_VALUES, PARTNER_STEPS } from 'src/constants/profile'

interface IProps {
  setError: (stepName: string, value: boolean) => void
  isViewOnly?: boolean
}

export const StepOneContainer: FC<IProps> = ({ setError, isViewOnly }) => {
  const dispatch = useReduxDispatch()
  const partnerForm = useSelector(getForm)
  const stepFields = pick(partnerForm, Object.values(GENERAL_COMPANY_INFO_VALUES))
  const initialValues = { ...stepFields }
  const [form] = Form.useForm()

  const onValuesChange = (changedValues: object, allValues: typeof initialValues) => {
    const newMetrics = {
      ...stepFields.metrics,
      ...Object.keys(allValues.metrics || {}).reduce((acc, item) => {
        //@ts-ignore
        return { ...acc, [item]: allValues.metrics[item] ? allValues.metrics[item] : '' }
      }, {}),
    }
    const formValues = {
      ...allValues,
      metrics: newMetrics,
    }
    dispatch(actions.setForm(formValues))
  }

  const delayedChange = useCallback(
    //@ts-ignore
    debounce((c, a) => onValuesChange(c, a), 500),
    []
  )

  const onSelectLocation = (field: string) => (value: string) => {
    form.setFieldsValue({ ...form.getFieldsValue(), [field]: value })
    onValuesChange({ [field]: value }, { ...form.getFieldsValue() })
  }

  const validation = async (rule: any, v: string | number | null) => {
    const value = v === null ? '' : `${v}`

    if (value.length && !RegExp(REGEXES.URL).test(value)) {
      return await Promise.reject('Please enter correct website URL including https://')
    }
    return await Promise.resolve()
  }

  const validateFields = async () => {
    try {
      await form.validateFields()
      setError(PARTNER_STEPS.GENERAL, false)
    } catch (error: any) {
      setError(PARTNER_STEPS.GENERAL, true)
    }
  }

  useEffect(() => {
    validateFields()
  }, [partnerForm])

  return (
    <div className="first_content">
      <InfoCard headerLeft={<h3>General Information</h3>}>
        <Form form={form} initialValues={initialValues} onValuesChange={delayedChange}>
          <PublicProfileField
            description={{
              title: 'Company Name',
            }}
            input={
              <Form.Item className="mb-0" name="companyName">
                <QInput placeholder="Enter Company name" size="large" disabled={isViewOnly} />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: 'Company Description',
            }}
            input={
              <>
                <Form.Item className="mb-0" name="companyDescription">
                  <QTextArea
                    className="form-textarea"
                    placeholder="Tell prospects who you are and what types of products you sell."
                    style={{ height: '110px' }}
                    disabled={isViewOnly}
                  />
                </Form.Item>
                <span style={{ fontSize: '11px' }}>
                  Both company name and description will appear on your company listing in our partner marketplace
                </span>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: 'Company Website',
            }}
            input={
              <>
                <Form.Item
                  className="mb-0"
                  name="companyWebsite"
                  rules={[
                    {
                      validator: async (rule, value: string) => await validation(rule, value),
                    },
                  ]}
                >
                  <QInput placeholder="Enter URL" size="large" disabled={isViewOnly} />
                </Form.Item>
                <span
                  style={{
                    fontSize: '11px',
                  }}
                >
                  You Full URL including https://
                </span>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: 'Headquarters Address',
            }}
            input={
              <>
                <Form.Item className="mb-0" name="headquarterAddress">
                  <LocationSearchInput
                    onSelectHandler={onSelectLocation(
                      GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.HEADQUARTERS_ADDRESS]
                    )}
                    placeholder="Headquarters Address"
                    isViewOnly={isViewOnly}
                  />
                </Form.Item>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: 'Social Media Followers',
              subTitle:
                'Social media stats are displayed in our marketplace, giving clients an idea of your company`s audience size.',
            }}
            input={
              <>
                <Form.Item name={['metrics', 'Facebook']}>
                  <QInput placeholder="Facebook Followers" size="large" type="number" disabled={isViewOnly} />
                </Form.Item>
                <Form.Item name={['metrics', 'Instagram']}>
                  <QInput placeholder="Instagram Followers" size="large" type="number" disabled={isViewOnly} />
                </Form.Item>
                <Form.Item className="mb-0" name={['metrics', 'Twitter']}>
                  <QInput placeholder="Twitter Followers" size="large" type="number" disabled={isViewOnly} />
                </Form.Item>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: 'Automatically approve requests?',
            }}
            input={
              <>
                <Form.Item name="isAutomaticallyApprovedRequest">
                  <Radio.Group className="qu-radio-group" disabled={isViewOnly}>
                    <QRadio value={true}>Yes</QRadio>
                    <QRadio value={false}>No</QRadio>
                  </Radio.Group>
                </Form.Item>
                <p className="p--xs">
                  If you select Yes, requests will automatically be approved and you`ll be obligated to provide
                  requested services.
                </p>
              </>
            }
          />
        </Form>
      </InfoCard>
    </div>
  )
}
