import React, { ReactElement, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Col, Form, Popover, QButton, QRadio, QDatePicker, QInput, QSelect, QTextArea, Row } from 'quantum_components'
import { ICustomizedStep, IOptionForSelect, IProductStep } from '../../testahelBox.types'
import {
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  BRIEF_TYPES,
  BRIEF_UPLOAD_TYPES,
  DISTRIBUTION_TYPE,
  PRODUCT_NAME_MAX,
  PRODUCT_NAME_MIN,
} from '../../../../../constants/testahel_brief'
import { useSelector } from 'react-redux'
import { disabledDatesForThirtyDays } from '../../../../../helpers/datesHelper'

import { getBrief, getBriefSettings, getIsImagesUploading } from '../../testahelBox.selectors'
import moment from 'moment'
import { FORMATS, LOCAL_STORAGE_KEYS, REGEXES } from '../../../../../constants'
import UploadComponent from '../../../../../components/UploadComponent'
import { useReduxDispatch } from 'src/helpers'
import { actions } from '../../testahelBox.module'
import TestahelBriefDistributionPopover from '../components/TestahelBrief.distributionPopover'
import { isMobile } from 'react-device-detect'
import { ReactComponent as LogOut } from 'src/assets/icons/logout-icon-alt.svg'
import TestahelPopoverSummary from '../components/TestahelBrief.PopoverSummary'
import IUploadFile from '../../../../../interfaces/IUploadFile'
import { getFileNameFromUrl } from '../../../../../utils'
import VALIDATE_STATUSES from '../../../../../constants/validateStatuses'
import { isInsert, isSample } from '../../../../../helpers/testahelBrief'
import { VALIDATION_MESSAGE } from '../../../../../constants/validationMessages'

interface IProps {
  customizedFields: ICustomizedStep
  onChange: (customizedFields: ICustomizedStep) => void
  onFinishLater: () => void
  disabledFinishLater: boolean
}

export default function TestahelCustomizedBoxForm({
  customizedFields,
  onChange,
  onFinishLater,
  disabledFinishLater,
}: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const settings = useSelector(getBriefSettings)
  const brief = useSelector(getBrief)
  const isImagesUploading = useSelector(getIsImagesUploading)
  const [distributingError, setDistributingError] = useState('')
  const [files, setFiles] = useState<IUploadFile[]>([])
  const [nameError, setNameError] = useState<string>('')
  const [dateError, setDateError] = useState<string>('')
  const [tableKey, setTableKey] = useState(0)
  const productsQuantityOptions: IOptionForSelect[] = [1, 2, 3, 4, 5].map((option: any) => {
    return { label: option, value: option }
  })

  const [form] = Form.useForm()

  const productTypeSettings = settings.productTypes.map((type: string) => {
    return { label: type, value: type }
  })
  const productTypeOptions: IOptionForSelect[] = [...productTypeSettings]

  useEffect(() => {
    const fields = { ...customizedFields }
    form.setFieldsValue(fields)
  })

  const validateDate = (value: string | moment.Moment | null) => {
    if (!value) {
      setDateError('Please choose brief start date.')
      return
    }

    setDateError('')
  }

  const filesMapper = () => {
    const list = customizedFields.customizeBoxProductImages
      ? customizedFields.customizeBoxProductImages.map((url: string, index: number) => {
          return {
            uid: index + '',
            name: getFileNameFromUrl(url),
            status: 'done',
            url: url,
          } as IUploadFile
        })
      : []
    setFiles(list)
    setTableKey(tableKey + 1)
  }

  useEffect(() => {
    filesMapper()
  }, [customizedFields.customizeBoxProductImages.length])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fields = { ...customizedFields }
    const { id, value, type } = e.target

    if (value) {
      // @ts-ignore
      fields[id] = type === 'number' ? +value : value.trimLeft()
    } else {
      // @ts-ignore
      fields[id] = null
    }

    if (id === 'customizeBoxBriefName') {
      validateName(value.trimLeft())
    }
    onChange(fields)
  }

  const handleSelectChange = (value: string, option: IOptionForSelect) => {
    if (!option) {
      return
    }

    const fields = { ...customizedFields }
    // @ts-ignore
    fields.customizeBoxProductCount = value
    onChange(fields)
  }

  const handleProductType = (values: string[]) => {
    const fields = { ...customizedFields }
    // @ts-ignore
    fields.customizeBoxProductType = values
    onChange(fields)
  }

  const handleDatePicker = (date: moment.Moment) => {
    const fields = { ...customizedFields }
    fields.customizeBoxStartDate = date
    validateDate(date)
    onChange(fields)
  }

  const handleUpload = async (uploads: File[]) => {
    if (uploads.length === 0) {
      setTableKey(tableKey + 1)
      return
    } else if (
      uploads.length > Number(customizedFields.customizeBoxProductCount) ||
      customizedFields.customizeBoxProductImages.length + uploads.length >
        Number(customizedFields.customizeBoxProductCount)
    ) {
      setTableKey(tableKey + 1)
      return
    }

    dispatch(actions.uploadProductImage(brief, uploads, BRIEF_UPLOAD_TYPES.BRIEFS_CUSTOMIZED_IMAGES))
  }

  const handleRemove = async () => {
    await dispatch(actions.removeImage(brief, files[0], BRIEF_UPLOAD_TYPES.BRIEFS_CUSTOMIZED_IMAGES))
  }

  const validateName = (value: string | null) => {
    if (!value) {
      setNameError(VALIDATION_MESSAGE.PRODUCT_NAME)
      return
    }

    if (value.length < PRODUCT_NAME_MIN || value.length > PRODUCT_NAME_MAX) {
      setNameError(VALIDATION_MESSAGE.CUSTOMIZE_BOX_NAME_MIN_MAX)
      return
    }

    setNameError('')
  }

  return (
    <Form form={form} layout="vertical" hideRequiredMark initialValues={customizedFields}>
      <div className={`form form-brief form--horizontal`}>
        <div className="form__head">
          <div className="form__number">
            {isMobile ? <span>{`1. ${BRIEF_STEPS_NAMES[BRIEF_STEPS.PRODUCT]}`}</span> : '1'}
          </div>
          <h3>Customize your own Testahel Box</h3>
        </div>
        <TestahelPopoverSummary brief={brief} />
        <div className="form__cont">
          <React.Fragment>
            <Row gutter={16} align="middle">
              <Col xs={24} sm={24} md={10}>
                <label htmlFor="customizeBoxBriefName" className="form-label mb-25">
                  * Name your box
                </label>
              </Col>
              <Col xs={24} sm={24} md={14}>
                <Form.Item
                  className="mb-0"
                  name="customizeBoxBriefName"
                  validateStatus={nameError ? VALIDATE_STATUSES.ERROR : VALIDATE_STATUSES.SUCCESS}
                  help={nameError ? nameError : VALIDATION_MESSAGE.CUSTOMIZE_BOX_NAME}
                >
                  <QInput
                    onBlur={() => validateName(customizedFields.customizeBoxBriefName)}
                    value={customizedFields.customizeBoxBriefName}
                    onChange={handleInputChange}
                    placeholder="Name your box"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <hr className="form__hr" />
            <Row gutter={16} align="middle">
              <Col xs={24} sm={24} md={10}>
                <label htmlFor="customizeBoxDistributionCount" className="form-label">
                  * How many samples do you want to distribute?
                </label>
              </Col>
              <Col xs={24} sm={24} md={14}>
                <Form.Item
                  className="mb-0"
                  name="customizeBoxDistributionCount"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                      message: VALIDATION_MESSAGE.MIN_QUANTITY,
                    },
                  ]}
                  validateTrigger={['onBlur']}
                >
                  <QInput
                    value={customizedFields.customizeBoxDistributionCount}
                    onChange={handleInputChange}
                    type="number"
                    min={1}
                    placeholder="Enter quantity"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <hr className="form__hr" />
            <Row gutter={16} align="middle">
              <Col xs={24} sm={24} md={10}>
                <label htmlFor="customizeBoxProductCount" className="form-label">
                  * How many products per box?
                </label>
              </Col>
              <Col xs={24} sm={24} md={14}>
                <Form.Item className="mb-0" name="customizeBoxProductCount" validateTrigger={['onBlur']}>
                  <QSelect
                    value={customizedFields.customizeBoxProductCount}
                    onChange={handleSelectChange}
                    showSearch
                    size="large"
                    placeholder="Select products"
                    options={productsQuantityOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <hr className="form__hr" />
            <Row gutter={16} align="middle">
              <Col xs={24} sm={24} md={10}>
                <label htmlFor="customizeBoxProductType" className="form-label">
                  * Which product types do your samples fall under?
                </label>
              </Col>
              <Col xs={24} sm={24} md={14}>
                <Form.Item
                  className="mb-0"
                  name="customizeBoxProductType"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose at least one product type.',
                    },
                  ]}
                  validateTrigger={['onBlur', 'onChange']}
                >
                  <QSelect
                    value={customizedFields.customizeBoxProductType}
                    onChange={handleProductType}
                    showSearch
                    showArrow
                    maxTagTextLength={14}
                    maxTagCount={2}
                    mode="multiple"
                    size="large"
                    placeholder="Select type of the product"
                    options={productTypeOptions}
                    filterOption={(input: string, option: IOptionForSelect) =>
                      option.label.toLowerCase().includes(input.toLocaleLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <hr className="form__hr" />
          </React.Fragment>
          <React.Fragment>
            <Row gutter={16} align="middle">
              <Col xs={24} sm={24} md={10}>
                <label htmlFor="customizeBoxStartDate" className="form-label mb-25">
                  * What is your desired start date?
                </label>
              </Col>
              <Col xs={24} sm={24} md={14}>
                <Form.Item
                  className="mb-0"
                  name="customizeBoxStartDate"
                  validateStatus={dateError ? VALIDATE_STATUSES.ERROR : VALIDATE_STATUSES.SUCCESS}
                  help={
                    dateError ? (
                      dateError
                    ) : (
                      <>
                        A <b>minimum</b> of 30 days is needed before the start of your campaign
                      </>
                    )
                  }
                >
                  <QDatePicker
                    value={customizedFields.customizeBoxStartDate}
                    format={FORMATS.DATE_FORMAT}
                    onChange={handleDatePicker}
                    disabledDate={disabledDatesForThirtyDays}
                    onBlur={() => validateDate(customizedFields.customizeBoxStartDate)}
                    className="full-width"
                    size="large"
                    placeholder="Select Desired Starting Date"
                  />
                </Form.Item>
              </Col>
            </Row>
            <hr className="form__hr" />
          </React.Fragment>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="customizeBoxProductImages" className="form-label mt-35">
                Upload Product Images
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                help={`You can upload maximum of ${Number(customizedFields.customizeBoxProductCount)} images`}
              >
                <UploadComponent
                  key={tableKey}
                  defaultFileList={[...files]}
                  heading={'Upload one image per product'}
                  files={files}
                  onChange={handleUpload}
                  onRemove={handleRemove}
                  isUploading={isImagesUploading}
                  multiple={true}
                  maxCount={Number(customizedFields.customizeBoxProductCount)}
                  isTestahel={true}
                  disabled={Number(customizedFields.customizeBoxProductCount) <= 0}
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="customizeBoxAdditionalComment" className="form-label">
                Additional Comments
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="customizeBoxAdditionalComment"
                rules={[
                  {
                    max: 500,
                    message: VALIDATION_MESSAGE.ADDITIONAL_INSTRUCTION,
                  },
                ]}
              >
                <QTextArea
                  value={customizedFields.customizeBoxAdditionalComment}
                  onChange={handleInputChange}
                  rows={3}
                  cols={3}
                  placeholder="Let us know if you have additional instructions for this brief."
                  className="form-textarea"
                />
              </Form.Item>
            </Col>
          </Row>
          {isMobile && (
            <div className="form-brief-footer">
              <QButton
                disabled={disabledFinishLater}
                onClick={onFinishLater}
                className="qu-button-outline mt-30 full-width"
              >
                Save & Finish Later <LogOut className="ml-10" />
              </QButton>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}
