import IAction, { TName } from 'src/interfaces/IAction'
import * as CONSTANTS from './publicProfile.constants'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { requestHttp, urls } from 'src/api'
import { getResponseErrorMessage } from '../../../helpers'
import { IPublicProfilePayload, IPublicProfileResponse } from './publicProfile.types'

import { imageUploadRequest } from '../../brief/brief.actions'
import { upload } from '../../core/core.actions'
import { IBriefPartnerDetails, IBriefPartnerDetailsResponse } from '../../brief/brief.types'
import { AxiosResponse } from 'axios'

export const fetchPublicProfileRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PUBLIC_PROFILE_REQUEST,
})

export const fetchPublicProfileSuccess = (publicProfile: IPublicProfileResponse): IAction => ({
  type: CONSTANTS.FETCH_PUBLIC_PROFILE_SUCCESS,
  payload: { publicProfile },
})

export const fetchPublicProfileFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PUBLIC_PROFILE_FAILURE,
  error,
})

export const setPublicProfile = (publicProfile: any): IAction => ({
  type: CONSTANTS.SET_PUBLIC_PROFILE,
  payload: { publicProfile },
})

export const imageUploadSuccess = (changedData: { name: TName; content: string }): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_SUCCESS,
  changedFilesPayload: changedData,
})

export const imageUploadFailure = (changedData: Object): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_FAILURE,
  payload: changedData,
})
export const unMountClearData = (): IAction => ({
  type: CONSTANTS.UNMOUNT_CLEAR_DATA,
})

export const fetchPublicProfileSave = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: any
): Promise<void> => {
  try {
    dispatch(fetchPublicProfileRequest())
    const {
      publicProfile: { publicProfile },
    } = getState()
    const payload: IPublicProfilePayload = {
      highlights: publicProfile.highlights,
      listingShortDescription: publicProfile.listingShortDescription ? publicProfile.listingShortDescription : null,
      companyLogo: publicProfile.companyLogo ? publicProfile.companyLogo : null,
      previewCardImage: publicProfile.previewCardImage ? publicProfile.previewCardImage : null,
      listingHeaderImage: publicProfile.listingHeaderImage ? publicProfile.listingHeaderImage : null,
      metricsMarketing: publicProfile.metricsMarketing ? publicProfile.metricsMarketing.filter((e: any) => e) : [],
    }
    const response = await requestHttp.patch(urls.getPublicProfileSaveUpdatesUrl(), payload)
    const { content } = response.data.data
    dispatch(fetchPublicProfileSuccess(content))
  } catch (error: any) {
    dispatch(fetchPublicProfileFailure(getResponseErrorMessage(error)))
  }
}

export const uploadProductImage = (files: File[], name: TName): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(imageUploadRequest())

    const uploadUrl = urls.getPublicProfileUploadUrl()

    const response = await upload(uploadUrl, files)

    if (response.status === 201) {
      const { content } = response.data.data

      dispatch(imageUploadSuccess({ name, content }))
    }
  } catch (error: any) {
    dispatch(imageUploadFailure(error))
  }
}

export const imageRemove = (name: TName): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE,
  name: name,
})

export const setPublicProfilePercent = (percent: number) => ({
  type: CONSTANTS.SET_PUBLIC_PROFILE_PERCENT,
  percent: percent,
})

export const fetchPartnerDetailsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_DETAILS_REQUEST,
})

export const fetchPartnerDetailsSuccess = (partner: IBriefPartnerDetails): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_DETAILS_SUCCESS,
  payload: { partner },
})

export const fetchPartnerDetailsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_DETAILS_FAILURE,
  error,
})

export const fetchPartnerDetails = (
  userId: number
): ThunkAction<Promise<AxiosResponse<IBriefPartnerDetailsResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IBriefPartnerDetailsResponse>> => {
  try {
    dispatch(fetchPartnerDetailsRequest())

    const response = await requestHttp.get<IBriefPartnerDetailsResponse>(urls.getPartnerDetailsUrl(userId))
    const { content } = response.data.data

    dispatch(fetchPartnerDetailsSuccess(content))
    return response
  } catch (error: any) {
    dispatch(fetchPartnerDetailsFailure(getResponseErrorMessage(error)))
    return error
  }
}
