import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { QBriefStep, ClientSidebar, Col, Row } from 'quantum_components'
import SpinnerCenter from 'src/components/Spinner/spinner'

import { actions } from '../clientProfile.module'
import {
  CLIENT_STEPS,
  CLIENT_STEPS_NAMES,
  CLIENT_STEPS_SIDE_BAR,
  BASIC_INFO_NAMES,
  TARGET_TYPE,
  PRODUCT_TYPE,
  BASIC_INFO_INITIAL_VALUES,
  PRODUCT_TYPE_INITIAL_VALUES,
  TARGET_AUDIENCE_INITIAL_VALUES,
  BASIC_INFO,
  BASIC_INFO_VALUES,
  TAX_TREATMENT,
  BASIC_INFO_NAMES_WITHOUT_VAT,
} from 'src/constants/clientProfile'
import { useReduxDispatch } from 'src/helpers'
import {
  getCurrentStep,
  getIsLoading,
  getFilledData,
  getIsUpdating,
  getClientProfilePercent,
} from '../profile.selectors'

import { ClientsProfileHeader } from './Layout/profile-header'

import { BRIEF_STEP_STATUS } from 'src/constants/brief'

import { ClientsProfileBody } from './Layout/profile-body'
import { ClientsProductBody } from './Layout/product-body'
import { ClientsTargetBody } from './Layout/target-body'

import { getCurrentUser } from 'src/modules/core/core.selectors'
import { createObj } from 'src/helpers/createObjectWithOldFields'
import { hasErrorFields } from 'src/utils/validationRules'

import { isMobile } from 'react-device-detect'
import { omit, pick, map, cloneDeep } from 'lodash'
import { ClientsProfileFooter } from './Layout/profile-footer'

export const CLIENT_STEPS_EDIT: CLIENT_STEPS[] = [
  CLIENT_STEPS.BASIC_INFORMATION,
  CLIENT_STEPS.PRODUCT_TYPE,
  CLIENT_STEPS.TARGET_AUDIENCE,
]

const DATA_BY_STEP = {
  [CLIENT_STEPS.BASIC_INFORMATION]: BASIC_INFO_INITIAL_VALUES,
  [CLIENT_STEPS.PRODUCT_TYPE]: PRODUCT_TYPE_INITIAL_VALUES,
  [CLIENT_STEPS.TARGET_AUDIENCE]: TARGET_AUDIENCE_INITIAL_VALUES,
}

interface Props {
  children: React.ReactNode
}

const checkValue = (e: any) => !!((e && e.length && !Array.isArray(e)) || (e && Array.isArray(e) && e.some(v => v)))

const ProfileClientContainer = ({ children }: Props) => {
  const { t } = useTranslation(['translationUser', 'translationCommon'])
  const currentStep = useSelector(getCurrentStep)
  const dispatch = useReduxDispatch()
  const user = useSelector(getCurrentUser)
  const filledData = useSelector(getFilledData)
  const isLoading = useSelector(getIsLoading)
  const isUpdating = useSelector(getIsUpdating)
  const percent = useSelector(getClientProfilePercent)

  const stepData = createObj(DATA_BY_STEP[currentStep], filledData)
  stepData.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED ? delete stepData.vat : delete stepData.taxTreatment

  const [stepsInfo, setStepsInfo] = useState<{ filledSteps: string[]; unfilledSteps: string[] }>({
    filledSteps: [],
    unfilledSteps: [],
  })
  const [disabled, setDisabled] = useState(false)
  const fetchSettingsAndInfo = async () => {
    dispatch(actions.fetchClientSettings())
    dispatch(actions.fetchClientInfo(user))
  }

  const PropertiesOfSteps: string[][] = [
    Object.values(BASIC_INFO_NAMES_WITHOUT_VAT).map(name => t(name)),
    Object.values(PRODUCT_TYPE).map(name => t(name)),
    Object.values(TARGET_TYPE).map(name => t(name)),
  ]

  useEffect(() => {
    if (user && user.id) fetchSettingsAndInfo()
  }, [user])

  useEffect(() => {
    let newFilledSteps = [] as string[]
    let newUnfilledSteps = [] as string[]

    CLIENT_STEPS_EDIT.forEach(step => {
      if (Object.values(pick(filledData, Object.keys(DATA_BY_STEP[step]))).every(elem => checkValue(elem))) {
        newFilledSteps = [...newFilledSteps, step]
      } else if (Object.values(pick(filledData, Object.keys(DATA_BY_STEP[step]))).some(elem => !checkValue(elem))) {
        newUnfilledSteps = [...newUnfilledSteps, step]
      }
    })
    setStepsInfo({ filledSteps: newFilledSteps, unfilledSteps: newUnfilledSteps })
    updatePercent()
  }, [filledData])

  const updatePercent = () => {
    const newFilledData: any = cloneDeep(filledData)
    if (newFilledData.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED) {
      delete newFilledData.vat
    } else if (newFilledData.taxTreatment === TAX_TREATMENT.VAT_REGISTERED) {
      delete newFilledData.taxTreatment
    }
    const values = Object.values(newFilledData)
    const filled = values.filter(e => checkValue(e))
    const percent = (filled.length / values.length) * 100
    dispatch(actions.setPercent(Math.ceil(percent)))
  }

  const handleNext = () => {
    const currentIndex = CLIENT_STEPS_EDIT.indexOf(currentStep)
    if (currentIndex === CLIENT_STEPS_EDIT.length - 1) {
      return
    }
    dispatch(actions.setCurrentStep(CLIENT_STEPS_EDIT[currentIndex + 1]))
    dispatch(actions.setFilledList(CLIENT_STEPS_EDIT[currentIndex]))
  }

  const handleBack = () => {
    const index = CLIENT_STEPS_EDIT.indexOf(currentStep)
    if (index === 0) {
      return
    }
    dispatch(actions.setCurrentStep(CLIENT_STEPS_EDIT[index - 1]))
  }

  const handleSave = () => {
    dispatch(actions.updateClient(filledData))
  }

  const getStatus = (step: CLIENT_STEPS) => {
    const currentIndex = CLIENT_STEPS_EDIT.indexOf(step)
    const index = CLIENT_STEPS_EDIT.indexOf(currentStep)

    if (currentIndex === index) {
      return BRIEF_STEP_STATUS.CURRENT
    }
    if (stepsInfo.filledSteps.includes(step)) {
      return BRIEF_STEP_STATUS.PASSED
    }
    return BRIEF_STEP_STATUS.DEFAULT
  }

  const validation = (formFields: any) => {
    setDisabled(() =>
      hasErrorFields(
        formFields,
        Object.keys(
          omit(filledData, [BASIC_INFO_VALUES[BASIC_INFO.FIRST_NAME], BASIC_INFO_VALUES[BASIC_INFO.LAST_NAME], BASIC_INFO_VALUES[BASIC_INFO.CR], BASIC_INFO_VALUES[BASIC_INFO.NATIONAL_ADDRESS]])
        ),
        formFields?.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED
          ? [BASIC_INFO_VALUES[BASIC_INFO.FIRST_NAME], BASIC_INFO_VALUES[BASIC_INFO.LAST_NAME], BASIC_INFO_VALUES[BASIC_INFO.CR], BASIC_INFO_VALUES[BASIC_INFO.NATIONAL_ADDRESS]]
          : [BASIC_INFO_VALUES[BASIC_INFO.FIRST_NAME], BASIC_INFO_VALUES[BASIC_INFO.LAST_NAME], BASIC_INFO_VALUES[BASIC_INFO.VAT], BASIC_INFO_VALUES[BASIC_INFO.CR], BASIC_INFO_VALUES[BASIC_INFO.NATIONAL_ADDRESS]]
      )
    )
  }

  const getTitles = () => {
    let arr = [] as string[]
    Object.values(CLIENT_STEPS_SIDE_BAR).map(step => {
      arr.push(t(step))
    })
    return arr
  }

  useEffect(() => {
    validation(filledData)
  }, [filledData])

  useEffect(() => {
    setDisabled(isUpdating)
  }, [isUpdating])

  const steps = () => (
    <div className="qu-stepper" style={{ margin: '20px 0' }}>
      <div className="steps profile-steps">
        {CLIENT_STEPS_EDIT.map(step => {
          return (
            <QBriefStep
              status={getStatus(step)}
              key={step}
              title={t(CLIENT_STEPS_NAMES[step])}
              onClickPath={() => {
                dispatch(actions.setCurrentStep(step))
              }}
            />
          )
        })}
      </div>
    </div>
  )

  if (!user.id || isLoading) return <SpinnerCenter />

  return (
    <Fragment>
      {isUpdating && <SpinnerCenter />}
      {isMobile ? (
        <div className="layout profile">
          <div className="profile-main">
            <Row gutter={20}>
              <Col xs={24} sm={24} md={16}>
                <div className="profile-header">
                  <ClientsProfileHeader stepper={steps()} />
                </div>
              </Col>
              <Col xs={24}>
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={16}>
                    <div className="profile-content">{children}</div>
                    <div>
                      {currentStep === CLIENT_STEPS.BASIC_INFORMATION && <ClientsProfileBody />}
                      {currentStep === CLIENT_STEPS.PRODUCT_TYPE && <ClientsProductBody />}
                      {currentStep === CLIENT_STEPS.TARGET_AUDIENCE && <ClientsTargetBody />}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={16}>
                <ClientsProfileFooter
                  currentStep={currentStep}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleSave={handleSave}
                  handleNext={handleNext}
                />
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="layout profile">
          <div className="profile-main">
            <Row gutter={20}>
              <Col xs={24} sm={24} md={16}>
                <div className="profile-header">
                  <ClientsProfileHeader stepper={steps()} />
                </div>
              </Col>
              <Col xs={24}>
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={16}>
                    <div className="profile-content">{children}</div>
                    <div>
                      {currentStep === CLIENT_STEPS.BASIC_INFORMATION && <ClientsProfileBody />}
                      {currentStep === CLIENT_STEPS.PRODUCT_TYPE && <ClientsProductBody />}
                      {currentStep === CLIENT_STEPS.TARGET_AUDIENCE && <ClientsTargetBody />}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <div className="profile-sidebar">
                      <ClientSidebar
                        completion={percent}
                        title={t('client.profile.edit.profile_completion')}
                        currentStep={currentStep}
                        steps={Object.values(CLIENT_STEPS)}
                        filledSteps={stepsInfo.filledSteps}
                        filledPropertiesOfStep={Object.keys(stepData).map(e => checkValue(stepData[e]))}
                        filledStepsPropertiesLength={0}
                        propertiesOfSteps={PropertiesOfSteps}
                        description=""
                        unfinishedSteps={stepsInfo.unfilledSteps}
                        titles={getTitles()}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={16}>
                <ClientsProfileFooter
                  currentStep={currentStep}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleSave={handleSave}
                  handleNext={handleNext}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default ProfileClientContainer
