import React from 'react'
import { BANNERS_BY_MEDIA_TYPE, MEDIA_TYPES } from 'src/constants/brief'
import { PRICING_MODEL } from 'src/constants/profile'

interface Props {
  preview: {
    mbPricingModel?: string
    name?: MEDIA_TYPES
    subName?: string
    description?: string
    url?: string
    avgImpressions?: number
    duration?: string
    pricePerActivity?: string
    priceForDuration?: string
    priceForImpression?: string
    pricePerPush?: string
    minOrderValue?: number
    dailyImpressions?: number
    CPM?: string
    dailyClicks?: number
    CPC?: string
    dailyReach?: number
    CPR?: string
  }
}

export default function PreviewMediaTypes({ preview }: Props) {
  const {
    mbPricingModel,
    name,
    subName,
    description,
    url,
    avgImpressions,
    duration,
    pricePerActivity,
    priceForDuration,
    priceForImpression,
    pricePerPush,
    minOrderValue,
    dailyImpressions,
    CPM,
    dailyClicks,
    CPC,
    dailyReach,
    CPR,
  } = preview
  const previewImg = (url || BANNERS_BY_MEDIA_TYPE[name!]?.image) ?? null
  return (
    <div className="qu-modal-media-types-aside">
      <div className="BS preview-container">
        <div className="badge badge--preview">Preview</div>
        <header className="preview-container-header">
          <h5 className="p">{subName || name}</h5>
          <p className="preview-container-description">{description}</p>
        </header>
        <div className="preview-container-image">
          <img src={previewImg} alt="Preview" />
        </div>
        <footer className="preview-container-footer">
          {avgImpressions && mbPricingModel === PRICING_MODEL.DURATION && (
            <div className="preview-container-footer-row">
              <h6>Avg Impressions {!!duration && duration}</h6>
              <p>{avgImpressions}</p>
            </div>
          )}
          {priceForDuration && mbPricingModel === PRICING_MODEL.DURATION && (
            <div className="preview-container-footer-row">
              <h6>Price for Duration</h6>
              <p>{priceForDuration}</p>
            </div>
          )}
          {priceForImpression && mbPricingModel === PRICING_MODEL.DURATION && (
            <div className="preview-container-footer-row">
              <h6>Price for Impression</h6>
              <p>{priceForImpression}</p>
            </div>
          )}
          {minOrderValue && (
            <div className="preview-container-footer-row">
              <h6>Minimum Order Value</h6>
              <p>{minOrderValue}</p>
            </div>
          )}

          {pricePerActivity && mbPricingModel === PRICING_MODEL.DURATION && (
            <div className="preview-container-footer-row">
              <h6>Price per Activity {duration}</h6>
              <p>{pricePerActivity}</p>
            </div>
          )}

          {pricePerPush && (
            <div className="preview-container-footer-row">
              <h6>Price for Push</h6>
              <p>{pricePerPush}</p>
            </div>
          )}
          {mbPricingModel === PRICING_MODEL.METRICS && (
            <>
              {dailyImpressions && (
                <div className="preview-container-footer-row">
                  <h6>Daily Impressions</h6>
                  <p>{dailyImpressions}</p>
                </div>
              )}
              {CPM && (
                <div className="preview-container-footer-row">
                  <h6>CPM</h6>
                  <p>{CPM}</p>
                </div>
              )}
              {dailyClicks && (
                <div className="preview-container-footer-row">
                  <h6>Daily Clicks</h6>
                  <p>{dailyClicks}</p>
                </div>
              )}
              {CPC && (
                <div className="preview-container-footer-row">
                  <h6>CPC</h6>
                  <p>{CPC}</p>
                </div>
              )}
              {dailyReach && (
                <div className="preview-container-footer-row">
                  <h6>Daily Reach</h6>
                  <p>{dailyReach}</p>
                </div>
              )}
              {CPR && (
                <div className="preview-container-footer-row">
                  <h6>CPR</h6>
                  <p>{CPR}</p>
                </div>
              )}
            </>
          )}
        </footer>
      </div>
      <p className="p--sm tc--light ta--center mt-25 mb-40">
        When viewing your marketplace listing, this popover appears as clients hover over this media type.
      </p>
    </div>
  )
}
