export default {
  success: {
    status: 200,
    data: {
      status: 1,
      message: 'Success',
      phone: '+380111111111',
    },
  },
  failure: {
    status: 422,
    data: {
      status: 0,
      message: 'Data entered incorrectly',
    },
  },
}
