export enum CLIENT_STEPS {
  BASIC_INFORMATION = 'BASIC_INFORMATION',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  TARGET_AUDIENCE = 'TARGET_AUDIENCE',
}

export const CLIENT_STEPS_NAMES: { [key: string]: string } = {
  [CLIENT_STEPS.BASIC_INFORMATION]: 'client.profile.edit.basic_information_step',
  [CLIENT_STEPS.PRODUCT_TYPE]: 'client.profile.edit.product_types_step',
  [CLIENT_STEPS.TARGET_AUDIENCE]: 'client.profile.edit.target_audience_step',
}
export const CLIENT_STEPS_SIDE_BAR: { [key: string]: string } = {
  [CLIENT_STEPS.BASIC_INFORMATION]: 'client.profile.view.basic_information',
  [CLIENT_STEPS.PRODUCT_TYPE]: 'client.profile.view.product_types',
  [CLIENT_STEPS.TARGET_AUDIENCE]: 'client.profile.view.target_audience',
}

export enum BASIC_INFO_LABELS {
  PROFILE_PICTURE = 'client.profile.edit.profile_picture',
  FIRST_NAME = 'client.profile.edit.first_name',
  LAST_NAME = 'client.profile.edit.last_name',
  COMPANY_NAME = 'client.profile.edit.company_name',
  COMPANY_WEBSITE = 'client.profile.edit.company_website',
  OFFICIAL_EMAIL = 'client.profile.edit.official_email',
  PHONE_NUMBER = 'client.profile.edit.phone_number',
  LOCATION = 'client.profile.edit.location',
  CURRENCY = 'client.profile.edit.currency',
  VAT = 'client.profile.edit.vat_registration_no',
  TAX_TREATMENT = 'client.profile.edit.tax_treatment',
  CR = 'client.profile.edit.cr',
  NATIONAL_ADDRESS = 'client.profile.edit.national_address',
}

export enum BASIC_INFO {
  PROFILE_PICTURE = 'Profile Picture',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  COMPANY_NAME = 'Company Name',
  COMPANY_WEBSITE = 'Company Website',
  OFFICIAL_EMAIL = 'Official Email',
  PHONE_NUMBER = 'Phone Number',
  LOCATION = 'Location',
  CURRENCY = 'Currency',
  VAT = 'VAT',
  TAX_TREATMENT = 'Tax Treatment',
  CR = 'CR',
  NATIONAL_ADDRESS = 'National Address',
}

export const BASIC_INFO_NAMES: { [key: string]: string } = {
  [BASIC_INFO.PROFILE_PICTURE]: 'client.profile.edit.profile_picture',
  [BASIC_INFO.FIRST_NAME]: 'client.profile.edit.first_name',
  [BASIC_INFO.LAST_NAME]: 'client.profile.edit.last_name',
  [BASIC_INFO.COMPANY_NAME]: 'client.profile.edit.company_name',
  [BASIC_INFO.COMPANY_WEBSITE]: 'client.profile.edit.company_website',
  [BASIC_INFO.OFFICIAL_EMAIL]: 'client.profile.edit.official_email',
  [BASIC_INFO.PHONE_NUMBER]: 'client.profile.edit.phone_number',
  [BASIC_INFO.LOCATION]: 'client.profile.edit.location',
  [BASIC_INFO.CURRENCY]: 'client.profile.edit.currency',
  [BASIC_INFO.VAT]: 'client.profile.edit.vat_registration_no',
  [BASIC_INFO.TAX_TREATMENT]: 'client.profile.edit.tax_treatment',
  [BASIC_INFO.CR]: 'client.profile.edit.cr',
  [BASIC_INFO.NATIONAL_ADDRESS]: 'client.profile.edit.national_address',
}

export const BASIC_INFO_NAMES_WITHOUT_VAT: { [key: string]: string } = {
  [BASIC_INFO.PROFILE_PICTURE]: 'client.profile.edit.profile_picture',
  [BASIC_INFO.FIRST_NAME]: 'client.profile.edit.first_name',
  [BASIC_INFO.LAST_NAME]: 'client.profile.edit.last_name',
  [BASIC_INFO.COMPANY_NAME]: 'client.profile.edit.company_name',
  [BASIC_INFO.COMPANY_WEBSITE]: 'client.profile.edit.company_website',
  [BASIC_INFO.OFFICIAL_EMAIL]: 'client.profile.edit.official_email',
  [BASIC_INFO.PHONE_NUMBER]: 'client.profile.edit.phone_number',
  [BASIC_INFO.LOCATION]: 'client.profile.edit.location',
  [BASIC_INFO.CURRENCY]: 'client.profile.edit.currency',
  [BASIC_INFO.TAX_TREATMENT]: 'client.profile.edit.tax_treatment',
  [BASIC_INFO.CR]: 'client.profile.edit.cr',
  [BASIC_INFO.NATIONAL_ADDRESS]: 'client.profile.edit.national_address',
}

export enum PRODUCT_TYPE {
  PRODUCT_TYPES = 'client.profile.edit.product_types',
}

export enum TARGET_TYPE_LABELS {
  GENDER_FOCUS = 'client.profile.edit.gender_focus',
  AGE_GROUPS = 'client.profile.edit.age_groups',
  TARGETED_SEGMENTS = 'client.profile.edit.segments',
}

export enum TARGET_TYPE {
  GENDER_FOCUS = 'client.profile.edit.gender_focus',
  AGE_GROUPS = 'client.profile.edit.age_groups',
  // TARGETED_SEGMENTS = 'client.profile.edit.segments',
}

export const BASIC_INFO_VALUES: { [key: string]: string } = {
  [BASIC_INFO.PROFILE_PICTURE]: 'profilePicture',
  [BASIC_INFO.FIRST_NAME]: 'firstName',
  [BASIC_INFO.LAST_NAME]: 'lastName',
  [BASIC_INFO.COMPANY_NAME]: 'companyName',
  [BASIC_INFO.COMPANY_WEBSITE]: 'companyWebsite',
  [BASIC_INFO.OFFICIAL_EMAIL]: 'email',
  [BASIC_INFO.PHONE_NUMBER]: 'phone',
  [BASIC_INFO.LOCATION]: 'location',
  [BASIC_INFO.CURRENCY]: 'currency',
  [BASIC_INFO.VAT]: 'vat',
  [BASIC_INFO.TAX_TREATMENT]: 'taxTreatment',
  [BASIC_INFO.CR]: 'cr',
  [BASIC_INFO.NATIONAL_ADDRESS]: 'nationalAddress',
}

export const PRODUCT_TYPE_VALUES: { [key: string]: string } = {
  [PRODUCT_TYPE.PRODUCT_TYPES]: 'productTypes',
}

export const TARGET_AUDIENCE_VALUES: { [key: string]: string } = {
  [TARGET_TYPE.GENDER_FOCUS]: 'genderFocus',
  [TARGET_TYPE.AGE_GROUPS]: 'ageGroups',
  // [TARGET_TYPE.TARGETED_SEGMENTS]: 'segments',
}

export const BASIC_INFO_INITIAL_VALUES: { [key: string]: any } = {
  [BASIC_INFO_VALUES[BASIC_INFO.PROFILE_PICTURE]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.FIRST_NAME]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.LAST_NAME]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.COMPANY_NAME]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.COMPANY_WEBSITE]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.OFFICIAL_EMAIL]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.PHONE_NUMBER]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.LOCATION]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.CURRENCY]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.VAT]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.TAX_TREATMENT]]: 'VAT Registered',
  [BASIC_INFO_VALUES[BASIC_INFO.CR]]: '',
  [BASIC_INFO_VALUES[BASIC_INFO.NATIONAL_ADDRESS]]: '',
}

export const PRODUCT_TYPE_INITIAL_VALUES: { [key: string]: any } = {
  [PRODUCT_TYPE_VALUES[PRODUCT_TYPE.PRODUCT_TYPES]]: [],
}

export const TARGET_AUDIENCE_INITIAL_VALUES: { [key: string]: any } = {
  [TARGET_AUDIENCE_VALUES[TARGET_TYPE.GENDER_FOCUS]]: [],
  [TARGET_AUDIENCE_VALUES[TARGET_TYPE.AGE_GROUPS]]: [],
  // [TARGET_AUDIENCE_VALUES[TARGET_TYPE.TARGETED_SEGMENTS]]: [],
}

export enum TAX_TREATMENT {
  VAT_REGISTERED = 'VAT Registered',
  NON_VAT_REGISTERED = 'Non-VAT Registered',
}
