import { createSelector } from 'reselect'
import { IInvitedClients, IInvitedClientsTableParams, IState } from './invitingClient.types'
import { MODULE_NAME } from './invitingClient.constants'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getInvitedClients = createSelector(selectState, (state: IState): any[] => {
  let invitedClients = state.invitedClients.map(item => {
    return {
      email: item.email,
      key: item.id,
      id: item.id,
      partnerId: item.partnerId,
      status: item.status,
      updated_at: item.updatedAt,
    }
  })
  return invitedClients.length > 0 && invitedClients[0].id > 0 ? invitedClients : []
})

export const getPages = createSelector(
  selectState,
  (state: IState): { totalPages: number; currentPage: number } => state.pages
)

export const getTotalRecordsCount = createSelector(selectState, (state: IState): number => state.total)

export const getRequestParams = createSelector(selectState, (state: IState): IInvitedClientsTableParams => state.params)

export const getInvitedClientsListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.invitedClientsListLoading
)
