import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconsCommon, Modal, QButton, qnotification } from 'quantum_components'
import { CardNumber, Cvv, ExpiryDate, Frames } from 'frames-react'

import NOTIFICATION_TYPES from 'src/constants/notificationTypes'
import { requestHttp, urls } from 'src/api'
import { renderRoundedNumber } from 'src/helpers/currency'
import { getCurrency } from 'src/modules/core/core.selectors'

import { actions } from '../../brief.module'
import { getCart, getSelectedPayment } from '../../brief.selectors'
import { getCurrentUser } from '../../../core/core.selectors'
import {
  TAX_TREATMENT,
} from 'src/constants/clientProfile'

import SpinnerComponent from 'src/components/Spinner/SpinnerComponent'
import SpinnerCenter from 'src/components/Spinner/spinner'
import { useTranslation } from 'react-i18next'

interface Props {
  briefId: number
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void
  onPaySuccess?: () => void
}

export default function BriefPaymentModal({
  briefId,
  onCancel = () => { },
  onPaySuccess = () => { },
}: Props): ReactElement {
  const { t } = useTranslation(["translation", "translationCommon"]);
  const dispatch = useDispatch()

  const [framesLoading, setFramesLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  const [showPaymentMethodIcon, setShowPaymentMethodIcon] = useState(false)
  const [activePaymentMethod, setActivePaymentMethod] = useState<string | null>(null)
  const [activePaymentMethodIcon, setActivePaymentMethodIcon] = useState<any>(null)

  const [cardValid, setCardValid] = useState(false)

  const [hasCvvError, setHasCvvError] = useState(false)
  const [hasExpiryDateError, setHasExpiryDateError] = useState(false)
  const [hasCardNumberError, setHasCardNumberError] = useState(false)

  const cart = useSelector(getCart)
  const currency = useSelector(getCurrency)
  const selectedPaymentType = useSelector(getSelectedPayment)
  const currentUser: any = useSelector(getCurrentUser)
  const [onlinePaymentSelected, setOnlinePaymentSelected] = useState(false)
  const cartTotal = (onlinePaymentSelected && currentUser.partnerData.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED) || (currentUser.partnerData.taxTreatment === TAX_TREATMENT.VAT_REGISTERED && !cart.additional.taxes ) ? cart.additional.cartTotal * 1.15 : cart.additional.cartTotal
  const totalSum = renderRoundedNumber(cartTotal ? cartTotal : 0, currency)
  
  useEffect(() => {
    if (selectedPaymentType === 'ONLINE') {
      setOnlinePaymentSelected(true)
    } else {
      setOnlinePaymentSelected(false)
      // setNewCartTotal(0)
    }
  }, [selectedPaymentType])

  useEffect(() => {
    const paymentMethodsIconsByName = {
      visa: <IconsCommon.IconVisa />,
      mastercard: <IconsCommon.IconMastercard />,
      maestro: <IconsCommon.IconMaestro />,
      mada: <IconsCommon.IconMada />,
      jcb: <IconsCommon.IconJcb />,
      discover: <IconsCommon.IconDiscover />,
      'diners club': <IconsCommon.IconDinnersClub />,
      'american express': <IconsCommon.IconAmericanExpress />,
    }

    // @ts-ignore
    setActivePaymentMethodIcon(paymentMethodsIconsByName[activePaymentMethod?.toLowerCase()] ?? null)
  }, [activePaymentMethod])

  return (
    <Modal
      visible
      wrapClassName="qu-modal iframesHeight"
      title={<h3>{t('brief.payment.Card_Info')}</h3>}
      width={600}
      closeIcon={<IconsCommon.IconClose />}
      onCancel={onCancel}
      footer={false}
    >
      {submitting && <SpinnerCenter text={t('brief.payment.Processing_payment') + "..."} />}

      <Frames
        config={{
          publicKey: process.env.REACT_APP_CHECKOUT_PUBLIC_KEY || '',
        }}
        ready={async () => {
          await dispatch(actions.fetchCart(briefId))
          setFramesLoading(false)
        }}
        frameValidationChanged={({ element, isValid, isEmpty }) => {
          if (isValid || isEmpty) {
            if (element === 'card-number' && !isEmpty) {
              setShowPaymentMethodIcon(true)
            }

            switch (element) {
              case 'cvv':
                setHasCvvError(false)
                return
              case 'expiry-date':
                setHasExpiryDateError(false)
                return
              case 'card-number':
                setHasCardNumberError(false)
                return
              default:
                return
            }
          }

          if (element === 'card-number') {
            setShowPaymentMethodIcon(true)
          }

          switch (element) {
            case 'cvv':
              setHasCvvError(true)
              return
            case 'expiry-date':
              setHasExpiryDateError(true)
              return
            case 'card-number':
              setHasCardNumberError(true)
              return
            default:
              return
          }
        }}
        paymentMethodChanged={({ paymentMethod }) => {
          if (!paymentMethod) {
            setActivePaymentMethod(null)
            setShowPaymentMethodIcon(true)
          } else {
            setHasCardNumberError(false)
            setActivePaymentMethod(paymentMethod)
            setShowPaymentMethodIcon(true)
          }
        }}
        cardValidationChanged={({ isValid }) => {
          setCardValid(isValid)
        }}
        cardSubmitted={() => {
          setSubmitting(true)
        }}
        cardTokenized={async ({ token }) => {
          try {
            const response = await requestHttp.post(urls.getPayCardUrl(), { briefId, token })

            if (response.status === 200) {
              dispatch(actions.payOnlineViewBriefSuccess())
              onPaySuccess()
            }
          } finally {
            Frames.enableSubmitForm()
            setSubmitting(false)
          }
        }}
        cardTokenizationFailed={() => {
          Frames.enableSubmitForm()
          setSubmitting(false)
          qnotification({ type: NOTIFICATION_TYPES.ERROR, message: t('alert.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN', { ns: 'translationCommon' }) })
        }}
      >
        {framesLoading && <SpinnerComponent className="spinner-bg" />}

        <span className={`input-label ${hasCardNumberError && 'input-label--error'}`}>{t('brief.payment.Card_number')}</span>
        <div className={`input-container mt-10 ${hasCardNumberError && 'input-container--error'}`}>
          <div className="icon-container">
            <IconsCommon.IconCard />
          </div>
          <CardNumber />
          <div className={`icon-container payment-method ${showPaymentMethodIcon && 'show'}`}>
            {activePaymentMethodIcon}
          </div>
          <div className="icon-container">
            {hasCardNumberError && !activePaymentMethod && <IconsCommon.IconWarningRound />}
          </div>
        </div>
        <div className="date-and-code">
          <div>
            <span className={`input-label ${hasExpiryDateError && 'input-label--error'}`}>{t('brief.payment.Expiry_date')}</span>
            <div className={`input-container mr-10 ${hasExpiryDateError && 'input-container--error'}`}>
              <div className="icon-container">
                <IconsCommon.IconExpiryDateIcon />
              </div>
              <ExpiryDate />
              <div className="icon-container">{hasExpiryDateError && <IconsCommon.IconWarningRound />}</div>
            </div>
          </div>

          <div>
            <span className={`input-label ${hasCvvError && 'input-label--error'}`}>{t('brief.payment.Security_code')}</span>
            <div className={`input-container ${hasCvvError && 'input-container--error'}`}>
              <div className="icon-container">
                <IconsCommon.IconCvv />
              </div>
              <Cvv />
              <div className="icon-container">{hasCvvError && <IconsCommon.IconWarningRound />}</div>
            </div>
          </div>
        </div>

        <QButton
          type="primary"
          className="justify-center full-width mt-10"
          disabled={!cardValid || submitting}
          onClick={() => {
            if (cardValid && !submitting) {
              Frames.submitCard()
              return
            }
          }}
        >
          {t('brief.payment.Pay')} {totalSum}
        </QButton>
      </Frames>
    </Modal>
  )
}
