import { IState } from './updateEmail.types'
import * as CONSTANTS from './updateEmail.constants'
import IAction from 'src/interfaces/IAction'

const initialState: IState = {
  isLoading: false,
  updateEmailError: '',
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.UPDATE_EMAIL_REQUEST:
      return { ...state, isLoading: true }
    case CONSTANTS.UPDATE_EMAIL_SUCCESS:
      return { ...state, isLoading: false }
    case CONSTANTS.UPDATE_EMAIL_FAILURE:
      return { ...state, updateEmailError: action.error, isLoading: false }
    default:
      return state
  }
}
