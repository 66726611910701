import IAction from 'src/interfaces/IAction'
import { IState } from './briefV2.types'

const initialState: IState = {

}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    default:
      return state
  }
}
