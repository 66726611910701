import { GUARD_META, PATHS, ROLES } from '../../constants'
import getUserRole from '../../helpers/getUserRole'

const requireRole = async (to: any, from: any, next: any) => {
  const role = await getUserRole()
  const isClient = role === ROLES.CLIENT
  const isPartner = role === ROLES.PARTNER

  if (to.meta[GUARD_META.CLIENT_ONLY] && isPartner) {
    next.redirect(PATHS.ROOT)
  }

  if (to.meta[GUARD_META.PARTNER_ONLY] && isClient) {
    next.redirect(PATHS.ROOT)
  }

  next()
}

export default requireRole
