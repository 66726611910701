import React, { useState, useEffect, useReducer } from 'react'
import { QTreeControls, QTreeCollapse } from 'quantum_components'

import { useSelector } from 'react-redux'
import { useReduxDispatch } from 'src/helpers'
import { actions } from '../teamMembers.module'
import { getCurrentUser } from '../../../core/core.selectors'
import { getLocateOptions, getPermissionsList, getDepartmentsList, getTeamMembersList } from '../teamMembers.selectors'
import { PATHS } from 'src/constants'
import history from 'src/utils/history'

const TeamMembersContainer = () => {
  const dispatch = useReduxDispatch()
  const departmentsList = useSelector(getDepartmentsList)
  const teamMembersList = useSelector(getTeamMembersList)
  const PermissionLevels = useSelector(getPermissionsList)
  const LocationOptions = useSelector(getLocateOptions)
  const currentUser: any = useSelector(getCurrentUser)

  const [membersList, setmembersList] = useState<any>(teamMembersList)
  const [departmentFormDetails, setDepartmentFormDetails] = useState<any>()
  const [memberFormDetails, setMemberFormDetails] = useState<any>()

  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const [tableKey, setTableKey] = useState(1)
  const [tableKey1, setTableKey1] = useState(-1)

  useEffect(() => {
    dispatch(actions.fetchDepartmentsList())
    dispatch(actions.fetchTeamMembersList())
  }, [])

  useEffect(() => {
    // create new department form with null values and also pass positions and departments etc
    setDepartmentFormDetails({
      departmentName: '',
      locateOptions: {
        position: null,
        department: null,
      },

      positions: LocationOptions,
      departments: [{ label: 'Select Position', value: null }, ...departmentsList],
      isDepartmentModalOpen: false,
      isOwner: false,
      isSubmitButtonDisable: false,
    })

    // create new team member form with null values and also pass departments and permissions etc
    setMemberFormDetails({
      firstName: '',
      lastName: '',
      email: '',
      partnerDepartmentId: '',
      partnerPermissionLevel: '',

      departments: departmentsList,
      permissions: PermissionLevels,
      isMemberModalOpen: false,
      isAddMemberbuttonDisable: departmentsList.length == 1 ? true : false,
      isOwner: false,
      isSubmitButtonDisable: false,
    })

    setTableKey(tableKey + 1)
  }, [departmentsList])

  useEffect(() => {
    setmembersList(teamMembersList)
    setTableKey1(tableKey1 - 1)
  }, [teamMembersList])

  // create/update department
  const AddEditDepartmentHandleChange = async (data: any) => {
    let departmentData = {
      name: data.formData.departmentName,
      locate_position: data.formData.locateOptions.position,
      locate_department_id: data.formData.locateOptions.department,
    }
    let response: any =
      data.id > 0
        ? await dispatch(actions.updateDepartment(departmentData, data.id))
        : await dispatch(actions.saveNewDepartment(departmentData))
    if (response == 200 || response == 201) {
      departmentFormDetails.isDepartmentModalOpen = false
      setTableKey(tableKey + 1)
    } else {
      departmentFormDetails.isSubmitButtonDisable = false
      setTableKey(tableKey + 1)
    }
  }

  // click on edit department and show the details in the modal
  const EditDepartmentHandleChange = (data: any) => {
    let filteredDepartmentsList = departmentsList.filter(ele => ele.value != data.id)
    memberFormDetails.isMemberModalOpen = false
    setDepartmentFormDetails({
      id: data.id,
      departmentName: data.name,
      locateOptions: {
        position: null,
        department: null,
      },

      positions: LocationOptions,
      departments: [{ label: 'Select Position', value: null }, ...filteredDepartmentsList],
      isDepartmentModalOpen: true,
      isOwner: data.isOwner,
      isSubmitButtonDisable: false,
    })
    setTableKey(tableKey + 1)
  }

  // create/update team member
  const AddEditMemberHandleChange = async (data: any) => {
    let departmentData = {
      firstName: data.formData.firstName,
      lastName: data.formData.lastName,
      email: data.formData.email,
      partnerDepartmentId: data.formData.partnerDepartmentId,
      partnerPermissionLevel: data.formData.partnerPermissionLevel,
    }
    let response: any =
      data.id > 0
        ? await dispatch(actions.updateTeamMember(departmentData, data.id, currentUser.id))
        : await dispatch(actions.saveNewTeamMember(departmentData))
    if (response == 200 || response == 201) {
      memberFormDetails.isMemberModalOpen = false
      setTableKey(tableKey + 1)
    } else {
      memberFormDetails.isSubmitButtonDisable = false
      setTableKey(tableKey + 1)
    }
  }

  // click on edit team member and show the details in the modal
  const EditMemberHandleChange = (data: any) => {
    departmentFormDetails.isDepartmentModalOpen = false
    setMemberFormDetails({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      partnerDepartmentId: data.partnerDepartmentId,
      partnerPermissionLevel: data.partnerPermissionLevel,

      departments: departmentsList,
      permissions: PermissionLevels,
      isMemberModalOpen: true,
      isAddMemberbuttonDisable: departmentsList.length == 1 ? true : false,
      isOwner: data.isOwner,
      isSubmitButtonDisable: false,
    })
    setTableKey(tableKey + 1)
  }

  // expand all records in the tree section table
  function expandAllHandleChange() {
    let membersData: any = membersList
    membersData.isOpen = true
    membersData.subMembers.map((ele: any) => {
      ele.isOpen = true
    })
    setmembersList(membersData)
    forceUpdate()
  }

  // collapse all records in the tree section table
  function collapseAllHandleChange() {
    let membersData: any = membersList
    membersData.isOpen = false
    membersData.subMembers.map((ele: any) => {
      ele.isOpen = false
    })
    setmembersList(membersData)
    forceUpdate()
  }

  // delete department or team member record
  const deleteDepartmentOrMemberHandleChange = (e: any) => {
    departmentFormDetails.isDepartmentModalOpen = false
    memberFormDetails.isMemberModalOpen = false
    if (e.isDepartmentOrMember == 'department') {
      dispatch(actions.deleteDepartment(e.id))
    } else {
      dispatch(actions.deleteTeamMember(e.id))
    }
  }

  return (
    <div className="dashboard-layout">
      {currentUser?.partnerPermissionLevel == 'admin' && (
        <div className="dashboard-content">
          <h1 className="only-desktop">Team Members</h1>
          {departmentsList[0].value > 0 && tableKey > 1 && (
            <QTreeControls
              key={tableKey}
              departmentFormValues={departmentFormDetails}
              SubmitDepartment={AddEditDepartmentHandleChange}
              teamMemberFormValues={memberFormDetails}
              SubmitTeamMember={AddEditMemberHandleChange}
              expandAll={expandAllHandleChange}
              collapseAll={collapseAllHandleChange}
            />
          )}
          {membersList.id > 0 && currentUser.id > 0 && (
            <div style={{ paddingBottom: '50px' }}>
              <QTreeCollapse
                key={tableKey1}
                teamMembersList={membersList}
                submitDeleteDepartmentOrMember={deleteDepartmentOrMemberHandleChange}
                editDepartment={EditDepartmentHandleChange}
                editMember={EditMemberHandleChange}
                ownEmail={currentUser.email}
                loggedInUserDetails={{
                  partnerPermissionLevel: currentUser.partnerPermissionLevel,
                  isOwner: currentUser.partnerDepartment?.isOwner,
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TeamMembersContainer
