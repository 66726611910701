import IAction from '../../../interfaces/IAction'
import * as CONSTANTS from './profile.constants'
import get from 'lodash/get'
import { PARTNER_STEPS_EDIT } from './containers/ProfilePartner.container'
import { IForm, IPartner, IPartnerSettings } from './profile.types'
import { CURRENCY } from '../../../constants/currencies'
import { clone, omit } from 'lodash'

const settingsInitState: IPartnerSettings = {
  ageGroups: [],
  productCategories: [],
  targetSegments: [],
  mediaTypes: [],
  countries: [],
  currencies: [],
  rejectionReasons: [],
  mediaSubtypes: [],
}

export const initialState: IPartner = {
  unfinishedSteps: [],
  filledSteps: [],
  currentStep: PARTNER_STEPS_EDIT[0],
  form: {
    companyName: '',
    companyDescription: '',
    companyWebsite: '',
    headquarterAddress: '',
    metrics: {
      Leads: 0,
      Looks: 0,
      Facebook: 0,
      Instagram: 0,
      Twitter: 0,
    },
    Leads: '',
    Looks: '',
    isAutomaticallyApprovedRequest: false,
    productCategories: [],
    amountOfOrders: 0,
    costOfOrder: 0,
    currency: CURRENCY.SAR,
    genderFocus: {},
    ageGroups: {},
    segments: {},
    partnerGenders: null,
    partnerAgeGroups: null,
    partnerTargetSegments: null,
    countries: [],
    cities: [],
    isSampleDistribution: false,
    pricePerSample: 0,
    hasDistributionTypePricing: false,
    hasDynamicSamplePricing: false,
    hasSampleProductTypePricing: false,
    dynamicSamplePriceTypes: null,
    dynamicSamplePrices: null,
    isInsertDistribution: false,
    pricePerInsert: 0,
    warehouseAddresses: [''],
    isMediaBuy: false,
    impressionRate: 0,
    conversionRate: 0,
    reachRate: 0,
    advertisementLanguage: [],
    mbPricingModel: '',
    mediaBuyTypes: [],
    publicProfile: {
      id: 0,
      userId: 0,
      listingShortDescription: '',
      companyLogo: '',
      previewCardImage: '',
      listingHeaderImage: '',
      highlights: [''],
      metricsMarketing: [''],
    },
  },
  settings: settingsInitState,
  partnerProfilePercent: 0,
  fetchPartnerLoading: true,
  imagesUploading: false,
  imagesUploadingError: '',
  imagesRemoving: false,
  imagesRemovingError: '',
}

export default (state: IPartner = initialState, action: IAction): IPartner => {
  switch (action.type) {
    case CONSTANTS.SET_UNFINISHED:
      const unfinishedSteps = get(action, ['payload', 'step'])
      return { ...state, unfinishedSteps }
    case CONSTANTS.SET_CURRENT_STEP:
      const currentStep = get(action, ['payload', 'step'])
      return { ...state, currentStep }
    case CONSTANTS.SET_FILLED:
      const filledSteps = get(action, ['payload', 'step'])
      return { ...state, filledSteps }
    case CONSTANTS.SET_PARTNER_SETTING:
      const settings = get(action, ['payload', 'settings'])
      return { ...state, settings: settings }
    case CONSTANTS.SET_FORM:
      const form = get(action, ['payload', 'form'])
      return { ...state, form: { ...state.form, ...form } }
    case CONSTANTS.FETCH_PARTNER_PROFILE_SUCCESS:
      const partnerProfile: IForm = get(action, 'payload.partnerProfile')
      const newMetrics = omit(clone(partnerProfile.metrics), ['Looks', 'Leads'])
      return {
        ...state,
        form: {
          ...partnerProfile,
          cities: partnerProfile.cities ? partnerProfile.cities : [],
          countries: partnerProfile.countries ? partnerProfile.countries : [],
          Leads: partnerProfile.metrics ? `${partnerProfile.metrics.Leads}` : '',
          Looks: partnerProfile.metrics ? `${partnerProfile.metrics.Looks}` : '',
          metrics: newMetrics,
        },
      }
    case CONSTANTS.SET_PARTNER_PROFILE_PERCENT:
      const percent = action.percent ? action.percent : 0
      return { ...state, partnerProfilePercent: percent }
    case CONSTANTS.FETCH_PARTNER_PROFILE_LOADING:
      const loading: boolean = get(action, 'payload.loading')
      return {
        ...state,
        fetchPartnerLoading: loading,
      }
    case CONSTANTS.IMAGE_UPLOAD_REQUEST:
      return { ...state, imagesUploading: true }
    case CONSTANTS.IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        imagesUploading: false,
      }
    case CONSTANTS.IMAGE_UPLOAD_FAILURE:
      return { ...state, imagesUploadingError: action.error, imagesUploading: false }

    case CONSTANTS.IMAGE_REMOVE_REQUEST:
      return { ...state, imagesRemoving: true }
    case CONSTANTS.IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        form: get(action, 'payload.form'),
        imagesRemoving: false,
      }
    case CONSTANTS.IMAGE_REMOVE_FAILURE:
      return { ...state, imagesRemovingError: action.error, imagesRemoving: false }

    default:
      return state
  }
}
