import { IState } from './specialClients.types'
import IAction from 'src/interfaces/IAction'

export const initialState: IState = {}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    default:
      return state
  }
}
