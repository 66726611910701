import React, { ReactElement } from 'react'

export default function BriefV2StaticHeader(): ReactElement {
  return (
    <header className="qu-header qu-brief-v2-header">
      <span className="welcome">Create your campaign</span>
      <div className="qu-header-controls"></div>
    </header>
  )
}
