import React, { ReactElement, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Col, Form, Popover, QButton, QRadio, QDatePicker, QInput, QSelect, QTextArea, Row } from 'quantum_components'
import { IOptionForSelect, IProductStep } from '../../testahelBox.types'
import {
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  BRIEF_TYPES,
  BRIEF_UPLOAD_TYPES,
  DISTRIBUTION_TYPE,
  PRODUCT_NAME_MAX,
  PRODUCT_NAME_MIN,
} from '../../../../../constants/testahel_brief'
import { useSelector } from 'react-redux'
import { getBrief, getBriefSettings, getIsImagesUploading } from '../../testahelBox.selectors'
import moment from 'moment'
import { FORMATS, LOCAL_STORAGE_KEYS, REGEXES } from '../../../../../constants'
import UploadComponent from '../../../../../components/UploadComponent'
import { useReduxDispatch } from 'src/helpers'
import { actions } from '../../testahelBox.module'
import TestahelBriefDistributionPopover from '../components/TestahelBrief.distributionPopover'
import { isMobile } from 'react-device-detect'
import { ReactComponent as LogOut } from 'src/assets/icons/logout-icon-alt.svg'
import TestahelPopoverSummary from '../components/TestahelBrief.PopoverSummary'
import { disabledDates } from '../../../../../helpers/datesHelper'
import IUploadFile from '../../../../../interfaces/IUploadFile'
import { getFileNameFromUrl } from '../../../../../utils'
import VALIDATE_STATUSES from '../../../../../constants/validateStatuses'
import { isInsert, isSample } from '../../../../../helpers/testahelBrief'
import { VALIDATION_MESSAGE } from '../../../../../constants/validationMessages'

interface IProps {
  productFields: IProductStep
  onChange: (productFields: IProductStep) => void
  onFinishLater: () => void
  disabledFinishLater: boolean
}

export default function TestahelProductBriefForm({
  productFields,
  onChange,
  onFinishLater,
  disabledFinishLater,
}: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const settings = useSelector(getBriefSettings)
  const brief = useSelector(getBrief)
  const isImagesUploading = useSelector(getIsImagesUploading)
  const [distributingError, setDistributingError] = useState('')
  const [files, setFiles] = useState<IUploadFile[]>([])
  const [nameError, setNameError] = useState<string>('')
  const [dateError, setDateError] = useState<string>('')
  const [form] = Form.useForm()

  const hasProductSample = brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE)

  useEffect(() => {
    const fields = { ...productFields }
    if (isOtherType) {
      const otherValue = fields.productType === 'Other' ? null : fields.productType
      fields.otherProductType = otherValue
      fields.productType = 'Other'
    }
    form.setFieldsValue(fields)
  })

  const isOtherType = productFields.productType && !settings.productTypes.includes(productFields.productType)

  const validateName = (value: string | null) => {
    if (!value) {
      setNameError(VALIDATION_MESSAGE.PRODUCT_NAME)
      return
    }

    if (value.length < PRODUCT_NAME_MIN || value.length > PRODUCT_NAME_MAX) {
      setNameError(VALIDATION_MESSAGE.PRODUCT_NAME_MIN_MAX)
      return
    }

    setNameError('')
  }

  const validateDate = (value: string | moment.Moment | null) => {
    if (!value) {
      setDateError('Please choose brief start date.')
      return
    }

    setDateError('')
  }

  const filesMapper = () => {
    const list = productFields.productImages
      ? productFields.productImages.map((url: string, index: number) => {
          return {
            uid: index + '',
            name: getFileNameFromUrl(url),
            status: 'done',
            url: url,
          } as IUploadFile
        })
      : []
    setFiles(list)
  }

  useEffect(() => {
    filesMapper()
  }, [productFields.productImages.length])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fields = { ...productFields }
    const { id, value, type } = e.target

    if (id === 'name') {
      validateName(value.trimLeft())
    }

    if (value) {
      // @ts-ignore
      fields[id] = type === 'number' ? +value : value.trimLeft()
    } else {
      // @ts-ignore
      fields[id] = null
    }
    onChange(fields)
  }

  const handleDurationChange = (value: string) => {
    ReactGA.event({
      category: `Create/Edit Brief ${
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ? '- Invited Client'
          : ''
      } - How long should the distribution last?`,
      action: value,
      label: value,
      value: 1,
    })
    const fields = { ...productFields }
    fields.duration = value

    onChange(fields)
  }

  const handleProductTypeChange = (value: string) => {
    ReactGA.event({
      category: `Create/Edit Brief ${
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ? '- Invited Client'
          : ''
      } - What is the product type?`,
      action: value,
      label: value,
      value: 1,
    })
    const fields = { ...productFields }
    fields.productType = value

    onChange(fields)
  }

  const handleOtherProductType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fields = { ...productFields }
    const { value } = e.target
    if (value) {
      fields.productType = value
      fields.otherProductType = value
    } else {
      fields.productType = 'Other'
      fields.otherProductType = null
    }

    onChange(fields)
  }

  const handleDistributionItems = (e: any) => {
    ReactGA.event({
      category: `Create/Edit Brief ${
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ? '- Invited Client'
          : ''
      } - What are you distributing?`,
      action: e.target.id,
      label: e.target.id,
      value: 1,
    })
    const fields = { ...productFields }
    const { id } = e.target
    fields.distributionItem = id
    validateDistributionItem(fields.distributionItem)
    onChange(fields)
  }

  const handleDatePicker = (date: moment.Moment) => {
    const fields = { ...productFields }
    fields.briefStartDate = date ? moment(date).date(1) : date
    validateDate(date)
    onChange(fields)
  }

  const handleUpload = async (uploads: File[]) => {
    if (files.length > 0) {
      await dispatch(actions.removeImage(brief, files[0], BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES))
    }

    dispatch(actions.uploadProductImage(brief, uploads, BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES))
  }

  const handleRemove = async () => {
    await dispatch(actions.removeImage(brief, files[0], BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES))
  }

  const productTypeSettings = settings.productTypes.map((type: string) => {
    return { label: type, value: type }
  })

  const productTypeOptions: IOptionForSelect[] = [...productTypeSettings, { label: 'Other', value: 'Other' }]

  const durationOptions: IOptionForSelect[] = settings.briefDurations.map((type: string) => {
    return { label: type, value: type }
  })

  const validateDistributionItem = (item: DISTRIBUTION_TYPE | null) => {
    const msg = !item ? VALIDATION_MESSAGE.DISTRIBUTION_TYPE : ''
    setDistributingError(msg)
  }

  return (
    <Form form={form} layout="vertical" hideRequiredMark initialValues={productFields}>
      <div className={`form form-brief form--horizontal`}>
        <div className="form__head">
          <div className="form__number">
            {isMobile ? <span>{`1. ${BRIEF_STEPS_NAMES[BRIEF_STEPS.PRODUCT]}`}</span> : '1'}
          </div>
          <h3>Tell us about your product</h3>
        </div>
        <TestahelPopoverSummary brief={brief} />
        <div className="form__cont">
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="name" className="form-label mb-25">
                * Product name
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="name"
                validateStatus={nameError ? VALIDATE_STATUSES.ERROR : VALIDATE_STATUSES.SUCCESS}
                help={nameError ? nameError : VALIDATION_MESSAGE.PRODUCT_NAME}
              >
                <QInput
                  onBlur={() => validateName(productFields.name)}
                  value={productFields.name}
                  onChange={handleInputChange}
                  placeholder="Enter name"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          {hasProductSample && (
            <React.Fragment>
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="quantity" className="form-label">
                    * How many samples do you want to distribute?
                  </label>
                </Col>
                <Col xs={24} sm={24} md={7}>
                  <Form.Item
                    className="mb-0"
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                        message: VALIDATION_MESSAGE.MIN_QUANTITY,
                      },
                    ]}
                    validateTrigger={['onBlur']}
                  >
                    <QInput
                      value={productFields.quantity}
                      onChange={handleInputChange}
                      type="number"
                      min={1}
                      placeholder="Enter quantity"
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <hr className="form__hr" />
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="distributeQuantity" className="form-label">
                    * What are you distributing?
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Popover
                    overlayClassName="qu-distributing-popover"
                    content={<TestahelBriefDistributionPopover />}
                    placement="rightTop"
                    getPopupContainer={(node: any) => node.parentNode}
                  >
                    <Row gutter={16} align="middle">
                      <Col>
                        <Form.Item className="mb-0" name={DISTRIBUTION_TYPE.PRODUCT_SAMPLE}>
                          <QRadio
                            checked={isSample(productFields.distributionItem)}
                            onChange={handleDistributionItems}
                            className="test-class"
                          >
                            Sample
                          </QRadio>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item className="mb-0" name={DISTRIBUTION_TYPE.PRINTED_INSERT}>
                          <QRadio
                            checked={isInsert(productFields.distributionItem)}
                            onChange={handleDistributionItems}
                            className="test-class"
                          >
                            Insert
                          </QRadio>
                        </Form.Item>
                      </Col>
                      {distributingError.length > 0 && (
                        <div style={{ paddingLeft: '8px', fontSize: '12px', color: '#ff4d4f' }}>
                          {distributingError}
                        </div>
                      )}
                    </Row>
                  </Popover>
                </Col>
              </Row>
              <hr className="form__hr" />
            </React.Fragment>
          )}
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="productType" className="form-label">
                * What is the product type?
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="productType"
                rules={[
                  {
                    required: true,
                    message: 'Please choose product type.',
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <QSelect
                  value={isOtherType ? 'Other' : productFields.productType}
                  onChange={handleProductTypeChange}
                  showSearch
                  size="large"
                  placeholder="Select type of the product"
                  options={productTypeOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          {isOtherType && (
            <Row gutter={16} align="middle">
              <Col xs={24} sm={24} md={10} />
              <Col xs={24} sm={24} md={14}>
                <Form.Item
                  name="otherProductType"
                  className="mt-20 mb-0"
                  rules={[
                    {
                      required: true,
                      message: VALIDATION_MESSAGE.OTHER_PRODUCT_TYPE,
                    },
                  ]}
                  validateTrigger={['onBlur', 'onChange']}
                >
                  <QInput
                    value={productFields.otherProductType}
                    onChange={handleOtherProductType}
                    placeholder="Enter your product type."
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <hr className="form__hr" />
          {hasProductSample && (
            <React.Fragment>
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="briefStartDate" className="form-label mb-25">
                    * What is your desired campaign month?
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Form.Item
                    className="mb-0"
                    name="briefStartDate"
                    validateStatus={dateError ? VALIDATE_STATUSES.ERROR : VALIDATE_STATUSES.SUCCESS}
                    help={dateError ? dateError : 'Chosen month depends on space availability'}
                  >
                    <QDatePicker
                      value={productFields.briefStartDate}
                      format={FORMATS.DATE_MMM_YYYY_FORMAT}
                      onChange={handleDatePicker}
                      disabledDate={(current: moment.Moment) => {
                        return moment().add(0, 'month') >= current || moment().add(12, 'month') <= current
                      }}
                      onBlur={() => validateDate(productFields.briefStartDate)}
                      className="full-width"
                      size="large"
                      placeholder="Select desired campaign month"
                      picker="month"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <hr className="form__hr" />
            </React.Fragment>
          )}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="productImage" className="form-label mt-35">
                Upload an image of your product
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item className="mb-0">
                <UploadComponent
                  files={files}
                  onChange={handleUpload}
                  onRemove={handleRemove}
                  isUploading={isImagesUploading}
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="instructions" className="form-label">
                Do you have additional instructions?
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="instructions"
                rules={[
                  {
                    max: 500,
                    message: VALIDATION_MESSAGE.ADDITIONAL_INSTRUCTION,
                  },
                ]}
              >
                <QTextArea
                  value={productFields.instructions}
                  onChange={handleInputChange}
                  rows={3}
                  cols={3}
                  placeholder="Let us know if you have additional instructions for this brief."
                  className="form-textarea"
                />
              </Form.Item>
            </Col>
          </Row>
          {isMobile && (
            <div className="form-brief-footer">
              <QButton
                disabled={disabledFinishLater}
                onClick={onFinishLater}
                className="qu-button-outline mt-30 full-width"
              >
                Save & Finish Later <LogOut className="ml-10" />
              </QButton>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}
