import React, { Fragment, ReactElement, useEffect, useRef } from 'react'
import ReactGA from 'react-ga'
import { Col, IconsCommon, Modal, ProfileCard, QAlert, QFilterTag, QSelect, Row } from 'quantum_components'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  getActiveSinglePartnerId,
  getBriefExclusivePartnerId,
  getCart,
  getDialogModal,
  getIsRejectionFlow,
  getPartnerFilter,
  getPartnerFilterSettings,
  getRecommendedPartners,
  getRecommendedPartnersLoading,
  getSampleAllocated,
  getTotalQuantity,
} from '../../testahelBox.selectors'
import TestahelBriefPartnerFilterMobile from '../components/TestahelBrief.PartnerFilterMobile'
import { isMobile } from 'react-device-detect'
import {
  IBrief,
  IBriefRecommendedPartner,
  ICartItem,
  ICartServiceItem,
  IMediaBuyInitState,
  IOptionForSelect,
  IPartnerCard,
  IPartnerFilter,
  IServiceInitState,
} from '../../testahelBox.types'
import { actions } from '../../testahelBox.module'
import { useReduxDispatch } from '../../../../../helpers'
import QTestahelBriefCartMobile from '../components/TestahelBrief.CartMobile'
import {
  BRIEF_SORT_TYPE,
  BRIEF_STEPS,
  BRIEF_TYPES,
  CONSUMER_RESEARCH_MEDIA_TYPES,
  DIALOG_MODALS,
  DISTRIBUTION_TYPE,
  KEEP_MODAL,
} from '../../../../../constants/testahel_brief'
import { getCurrency } from '../../../../core/core.selectors'
import {
  calculateLowestValue,
  getDistributionPrices,
  getDynamicDistributionPrices,
  getMediaBuyPrice,
  getMediaBuyPriceForMetrics,
  getMediaBuyPrices,
  getMediaBuyPricesForMetrics,
  renderRoundedNumber,
} from '../../../../../helpers/currency'
import {
  calcDistributionQuantity,
  getRejectedServices,
  hasDistributionItems,
  hasMediaBuyItems,
} from '../../../../../helpers/cart'
import SpinnerComponent from '../../../../../components/Spinner/SpinnerComponent'
import TestahelBriefPartnerSinglePageForm from './TestahelBriefPartnerSinglePage.form'
import DialogModal from '../../../../../components/DialogModal/DialogModal'

import defaultImage from '../../../../../assets/profile-default.svg'
import { getSocial } from '../../../../../helpers/social'
import { PATHS } from '../../../../../constants'
import { getMediaTypes } from '../../../../../helpers/mediaTypes'
import { getModalData, isSample } from '../../../../../helpers/testahelBrief'
import { cloneDeep } from 'lodash'
import CurrencyDropdown from '../../../../../components/CurrencyDropdown/CurrencyDropdown'
import { ALERTS_MSG } from '../../../../../constants/alerts'
import { REQUEST_STATUS } from '../../../../../constants/request'

const closeIcon = <IconsCommon.IconClose />
const arrowIcon = <IconsCommon.IconArrowHalf />

interface IProps {
  brief: IBrief
  saveMediaBuy?: () => Promise<void>
}

export default function TestahelBriefPartnerForm({ brief, saveMediaBuy }: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const filter = useSelector(getPartnerFilter)
  const filterSettings = useSelector(getPartnerFilterSettings)
  const partners = useSelector(getRecommendedPartners)
  const isLoading = useSelector(getRecommendedPartnersLoading)
  const cart = useSelector(getCart)
  const partnerId = useSelector(getActiveSinglePartnerId)
  const sampleAllocated = useSelector(getSampleAllocated)
  const totalQuantity = useSelector(getTotalQuantity)
  const dialogModal = useSelector(getDialogModal)
  const isRejectionFlow = useSelector(getIsRejectionFlow)
  const currency = useSelector(getCurrency)
  const briefExclusivePartnerId = useSelector(getBriefExclusivePartnerId)

  const { id } = useParams<{id: any}>()
  const singlePartnerRef = useRef()
  const briefId = id
  const history = useHistory()

  const fetchPartners = async () => {
    const rejection = getRejectedServices(cart.additional.items)
    const mergedFilter = !rejection.length ? filter : { ...filter, rejection }

    await dispatch(actions.fetchRecommendedPartners(briefId, mergedFilter))
  }

  const setActivePartner = async (id: number | null) => {
    await dispatch(actions.setActiveSinglePartner(id))
    if (id) {
      await dispatch(actions.fetchPartnerDetails(briefId, id))
    }
  }

  const calcTotalQuantity = async () => {
    const distributionQuantity = calcDistributionQuantity(cart.additional.items)
    await dispatch(actions.setTotalQuantity(distributionQuantity))
  }

  const resetModals = async () => {
    setActivePartner(null)
    closeDialogModal()
  }

  const fetchCart = async () => {
    await dispatch(actions.fetchCart(id))
  }

  useEffect(() => {
    setActivePartner(null)
    fetchCart()

    return () => {
      resetModals()
    }
  }, [])

  useEffect(() => {
    calcTotalQuantity()
    if (isRejectionFlow) {
      fetchPartners()
    }
  }, [cart])

  useEffect(() => {
    fetchPartners()
  }, [filter])

  const getCardsPartners = () => {
    const cards = partners.map((partner: IBriefRecommendedPartner) => {
      let card = {} as IPartnerCard
      const distributionPrices = partner.hasDynamicSamplePricing
        ? getDynamicDistributionPrices(partner)
        : getDistributionPrices(partner)
      const servicePrices =
        partner.mbPricingModel === 'metrics'
          ? getMediaBuyPricesForMetrics(partner.availableServices)
          : getMediaBuyPrices(partner.availableServices)
      const distributionLowestPrice = calculateLowestValue(distributionPrices)
      const mediaBuyLowestPrice = calculateLowestValue(servicePrices)

      card.id = partner.id
      card.selected = cart.additional.items.some((item: ICartItem) => item.partnerId === partner.id)
      card.headerImg = partner.mainImageUrl || defaultImage
      card.logo = partner.logoUrl
      card.name = partner.name
      card.url = partner.companyWebsite
      card.description = partner.companyDescription
      card.serviceLabel = partner.companyLabel

      if (briefExclusivePartnerId == null) card.servicesMatch = partner.match

      card.servicesPackagePrice = renderRoundedNumber(distributionLowestPrice, currency)
      card.servicesDevicePrice = renderRoundedNumber(mediaBuyLowestPrice, currency)
      card.social = getSocial(partner.social)
      if (Object.keys(partner.monthlyOrderRange).length > 0)
        card.social.push({
          name: 'Monthly Order Volume',
          counter: partner.monthlyOrderRange.text ? partner.monthlyOrderRange.text : '',
        })

      partner.mbPricingModel === 'metrics'
        ? partner.availableServices.sort((a, b) => getMediaBuyPriceForMetrics(a) - getMediaBuyPriceForMetrics(b))
        : partner.availableServices.sort((a, b) => getMediaBuyPrice(a) - getMediaBuyPrice(b))
      card.popupServices = getMediaTypes(partner, filterSettings.mediaSubtypes, currency)
      card.other = partner.other
      return card
    })

    return cards
  }

  const setFilterState = (filter: IPartnerFilter) => {
    dispatch(actions.setPartnerFilterState(filter))
  }

  const handleRemove = (e: any, tag: string) => {
    const newFilter = { ...filter }

    Object.keys(newFilter).map((key: string) => {
      //@ts-ignore
      const filterList = newFilter[key]

      if (filterList.includes(tag)) {
        filterList.splice(filterList.indexOf(tag), 1)
      }
    })

    setFilterState(newFilter)
  }

  const handleChangeSort = (value: BRIEF_SORT_TYPE) => {
    const newFilter = { ...filter }
    newFilter.sortBy = value

    setFilterState(newFilter)
  }

  const renderSelectedFilter = () => {
    const filterTags = { ...filter }
    delete filterTags.sortBy

    const tags = Object.values(filterTags).flat()
    return (
      tags.length > 0 && (
        <div className="qu-brief-tags">
          {tags.map((tag: string) => (
            <QFilterTag onCancel={(e: any) => handleRemove(e, tag)} key={tag} tagName={tag} className="mr-10" />
          ))}
        </div>
      )
    )
  }

  const onOpenModal = async (e: MouseEvent, partnerId: string, partnerName: string) => {
    ReactGA.event({
      category: 'Create/Edit Brief - Recommended Partner',
      action: partnerName,
      label: partnerName,
      value: 1,
    })
    await setActivePartner(+partnerId)
  }

  const closeModal = async () => {
    if (singlePartnerRef?.current) {
      // @ts-ignore
      const res = await singlePartnerRef.current.updateCart()
      if (res === KEEP_MODAL) return
    }

    await setActivePartner(null)
  }

  const getCartItemServices = () => {
    if (!partnerId) {
      return []
    }

    const targetItem = cart.additional.items.find((item: ICartItem) => item.partnerId === +partnerId)
    return targetItem ? targetItem.services : []
  }

  const sortOptions: IOptionForSelect[] = filterSettings.sortBy.map((name: BRIEF_SORT_TYPE) => {
    return { label: name, value: name }
  })
  const partnerCards = getCardsPartners()
  const recommendedPartners = partnerCards.filter((p: IPartnerCard) => !p.other)
  const othersPartners = partnerCards.filter((p: IPartnerCard) => p.other)
  const partnerServices = getCartItemServices()
  const isBudgetAllocatedModal = dialogModal === DIALOG_MODALS.BUDGET_ALLOCATED_MISMATCH
  const isSampleAllocatedModal = dialogModal === DIALOG_MODALS.SAMPLE_ALLOCATED_MISMATCH
  const isSampleBudgetAllocatedModal = dialogModal === DIALOG_MODALS.SAMPLE_BUDGET_ALLOCATED_MISMATCH
  const isRejectedServiceModal = dialogModal === DIALOG_MODALS.HAS_REJECTED_SERVICES
  const isBlockNextModal = dialogModal === DIALOG_MODALS.BLOCK_NEXT
  const isCancelModal = dialogModal === DIALOG_MODALS.CANCEL
  const isMediaBuyModal = dialogModal === DIALOG_MODALS.MEDIA_BUY
  const isDistributionModal = dialogModal === DIALOG_MODALS.DISTRIBUTION
  const isCRMediaModal = dialogModal === DIALOG_MODALS.CRMEDIA
  const distributionType = isSample(brief[BRIEF_STEPS.PRODUCT].distributionItem)
    ? DISTRIBUTION_TYPE.PRODUCT_SAMPLE
    : DISTRIBUTION_TYPE.PRINTED_INSERT

  const rejectedPartners = cart.additional.items.reduce((acc: string[], item: ICartItem) => {
    if (item.services.some((s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.REJECTED)) {
      acc.push(item.companyName)
    }

    return acc
  }, [])

  useEffect(() => {
    if (recommendedPartners.length) {
      setActivePartner(+recommendedPartners[0].id)
    }
  }, [recommendedPartners.length])

  const handleLeftArrow = async () => {
    if (!partnerId) {
      return
    }

    const activeCard = partnerCards.find((card: IPartnerCard) => card.id === partnerId)
    if (!activeCard) {
      return
    }
    const activeCardIndex = partnerCards.indexOf(activeCard)

    if (activeCardIndex > 0) {
      //@ts-ignore
      await singlePartnerRef.current.updateCart()
      setActivePartner(partnerCards[activeCardIndex - 1].id)
    }
  }

  const handleRightArrow = async () => {
    if (!partnerId) {
      return
    }

    const activeCard = partnerCards.find((card: IPartnerCard) => card.id === partnerId)
    if (!activeCard) {
      return
    }
    const activeCardIndex = partnerCards.indexOf(activeCard)

    if (activeCardIndex < partnerCards.length - 1) {
      //@ts-ignore
      await singlePartnerRef.current.updateCart()
      setActivePartner(partnerCards[activeCardIndex + 1].id)
    }
  }

  let totalBudget = 0
  cart.additional.items.map(ele => {
    ele.services.map((ele1: any) => {
      if (
        ele1.name !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
        ele1.name !== DISTRIBUTION_TYPE.PRINTED_INSERT &&
        ele1.name !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
        ele1.name !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
      ) {
        totalBudget = totalBudget + ele1.total
      }
    })
  })
  const modalData = isCRMediaModal
    ? getModalData(dialogModal, totalQuantity, sampleAllocated, distributionType)
    : isBudgetAllocatedModal || isSampleBudgetAllocatedModal
    ? getModalData(
        dialogModal,
        totalQuantity,
        sampleAllocated,
        distributionType,
        totalBudget,
        brief.BRIEF_MEDIA_BUY.budgetValue || 0,
        currency
      )
    : getModalData(dialogModal, totalQuantity, sampleAllocated, distributionType)

  const updateBrief = async () => {
    const briefForSave = { ...brief }

    briefForSave[BRIEF_STEPS.PARTNER].quantity = totalQuantity
    // briefForSave[BRIEF_STEPS.PARTNER].hasFreeSample = briefForSave[BRIEF_STEPS.SERVICES].hasFreeSample
    briefForSave.lastFilledStep = BRIEF_STEPS.PARTNER
    if (briefForSave[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)) {
      await dispatch(actions.updateBrief(BRIEF_STEPS.PARTNER, briefForSave, totalBudget))
    } else {
      await dispatch(actions.updateBrief(BRIEF_STEPS.PARTNER, briefForSave))
    }

    // const packagingQuantity = briefForSave[BRIEF_STEPS.SERVICES].servicePackagingQuantity
    // if (packagingQuantity && totalQuantity < packagingQuantity) {
    //   briefForSave[BRIEF_STEPS.SERVICES].servicePackagingQuantity = totalQuantity
    //   await dispatch(actions.updateBrief(BRIEF_STEPS.SERVICES, briefForSave))
    // }
  }

  const updateMediaBuyBrief = async () => {
    const updatedBrief = { ...brief }
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = [BRIEF_TYPES.PRODUCT_SAMPLE]
    updatedBrief.lastFilledStep = BRIEF_STEPS.PARTNER
    updatedBrief[BRIEF_STEPS.MEDIA_BUY] = cloneDeep(IMediaBuyInitState)

    dispatch(actions.updateBrief(BRIEF_STEPS.TYPE, updatedBrief))
    dispatch(actions.updateBrief(BRIEF_STEPS.MEDIA_BUY, updatedBrief))
  }

  const updateDistributionBrief = async () => {
    const updatedBrief = { ...brief }
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = [BRIEF_TYPES.MEDIA_BUYING]
    updatedBrief.lastFilledStep = BRIEF_STEPS.PARTNER
    updatedBrief[BRIEF_STEPS.SERVICES] = cloneDeep(IServiceInitState)
    updatedBrief[BRIEF_STEPS.PRODUCT].briefStartDate = null
    updatedBrief[BRIEF_STEPS.PRODUCT].distributionItem = null
    updatedBrief[BRIEF_STEPS.PRODUCT].quantity = null
    updatedBrief[BRIEF_STEPS.PRODUCT].duration = null

    dispatch(actions.updateBrief(BRIEF_STEPS.TYPE, updatedBrief))
    dispatch(actions.updateBrief(BRIEF_STEPS.PRODUCT, updatedBrief))
    dispatch(actions.updateBrief(BRIEF_STEPS.SERVICES, updatedBrief))
  }

  const checkSelectedItems = async (hasMediaBuy: boolean, hasDist: boolean) => {
    if (brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING) && !hasMediaBuy) {
      await dispatch(actions.setDialogModal(DIALOG_MODALS.MEDIA_BUY))
      return true
    }

    if (brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE) && !hasDist) {
      dispatch(actions.setDialogModal(DIALOG_MODALS.DISTRIBUTION))
      return true
    }

    return false
  }

  const checkModals = async () => {
    await closeDialogModal()
    const hasMediaBuy = hasMediaBuyItems(cart.additional.items)
    const hasDist = hasDistributionItems(cart.additional.items)

    const res = await checkSelectedItems(hasMediaBuy, hasDist)
    if (res) return

    let totalBudget = 0
    cart.additional.items.map(ele => {
      ele.services.map((ele1: any) => {
        if (
          ele1.name !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
          ele1.name !== DISTRIBUTION_TYPE.PRINTED_INSERT &&
          ele1.name !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
          ele1.name !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
        ) {
          totalBudget = totalBudget + ele1.total
        }
      })
    })

    if (
      sampleAllocated !== totalQuantity &&
      brief?.BRIEF_MEDIA_BUY?.budgetValue &&
      totalBudget !== brief?.BRIEF_MEDIA_BUY?.budgetValue
    ) {
      await dispatch(actions.setDialogModal(DIALOG_MODALS.SAMPLE_BUDGET_ALLOCATED_MISMATCH))
      return
    }

    if (brief?.BRIEF_MEDIA_BUY?.budgetValue && totalBudget !== brief?.BRIEF_MEDIA_BUY?.budgetValue) {
      await dispatch(actions.setDialogModal(DIALOG_MODALS.BUDGET_ALLOCATED_MISMATCH))
      return
    }

    if (sampleAllocated !== totalQuantity) {
      await dispatch(actions.setDialogModal(DIALOG_MODALS.SAMPLE_ALLOCATED_MISMATCH))
      return
    }

    await updateBrief()
    dispatch(actions.setCurrentStep(BRIEF_STEPS.UPLOAD))
  }

  const closeDialogModal = () => {
    dispatch(actions.setDialogModal(null))
  }

  const forceUpdateCart = async () => {
    if (singlePartnerRef?.current) {
      // @ts-ignore
      await singlePartnerRef.current.updateCart(true)
      closeDialogModal()
      setActivePartner(null)
    }
  }

  const onProceedModal = async () => {
    if (dialogModal === DIALOG_MODALS.CRMEDIA) {
      closeDialogModal()
      return
    }

    if (isRejectedServiceModal) {
      forceUpdateCart()
    }
    if (isBlockNextModal) {
      history.push(PATHS.BRIEF)
    }
    if (isCancelModal) {
      closeDialogModal()
      setActivePartner(null)
    }
    if (isMediaBuyModal) {
      await updateMediaBuyBrief()
      await checkModals()
    }
    if (isDistributionModal) {
      await updateDistributionBrief()
      await checkModals()
    }

    if (isBudgetAllocatedModal || isSampleAllocatedModal || isSampleBudgetAllocatedModal) {
      await updateBrief()
      dispatch(actions.setCurrentStep(BRIEF_STEPS.UPLOAD))
    }
  }

  const handleChangeCurrency = async () => {
    const updated = { ...brief }
    const lastSteps = [BRIEF_STEPS.PARTNER, BRIEF_STEPS.UPLOAD, BRIEF_STEPS.SUMMARY]
    if (updated.lastFilledStep && lastSteps.includes(updated.lastFilledStep)) return

    updated.lastFilledStep = BRIEF_STEPS.PARTNER
    updated[BRIEF_STEPS.PARTNER].quantity = updated[BRIEF_STEPS.PRODUCT].quantity || 0
    // updated[BRIEF_STEPS.PARTNER].hasFreeSample = updated[BRIEF_STEPS.SERVICES].hasFreeSample

    await dispatch(actions.updateBrief(BRIEF_STEPS.PARTNER, updated))
  }

  return (
    <div className="qu-channels-content">
      {renderSelectedFilter()}
      <div className="qu-channels-header">
        <Row gutter={16} align="bottom" justify="space-between">
          {isRejectionFlow && !isLoading && (
            <Col span={24}>
              <QAlert
                message={'Find a New Partner:'}
                description={
                  <div>
                    {!!rejectedPartners.length && (
                      <div>
                        Remove from cart <b>{rejectedPartners.join(', ')}</b> services that were rejected.
                      </div>
                    )}
                    <div>{ALERTS_MSG.ALERT_REJECTION_FLOW_ADD}</div>
                  </div>
                }
                type="info"
                className="pb-25"
              />
            </Col>
          )}
          <Col>
            <h1>
              <span className="mr-10">
                {briefExclusivePartnerId == null
                  ? 'Work with Testahel'
                  : `${recommendedPartners.length > 0 ? 'Work with ' + recommendedPartners[0].name : ''}`}
              </span>
              {isMobile && <QTestahelBriefCartMobile />}
            </h1>
            {briefExclusivePartnerId == null ? (
              <p>Collaborate with Testahel and effectively meet your marketing goals and target audiences</p>
            ) : (
              <p>
                {recommendedPartners.length > 0
                  ? `Collaborate with ${recommendedPartners[0].name} and effectively meet your marketing goals and target audiences.`
                  : ''}
              </p>
            )}
          </Col>
          {/* {briefExclusivePartnerId == null && (
            <Col flex="300px">
              <div className="qu-channels-filter">
                <span>Sort By:</span>
                <QSelect
                  showArrow
                  defaultValue={BRIEF_SORT_TYPE.BEST_MATCH}
                  options={sortOptions}
                  onChange={handleChangeSort}
                  size="large"
                  style={{ width: 200 }}
                />
              </div>
            </Col>
          )} */}
        </Row>
      </div>
      {isMobile && (
        <Fragment>
          <CurrencyDropdown handleChange={handleChangeCurrency} size={'large'} hideDialog />
          <TestahelBriefPartnerFilterMobile />
        </Fragment>
      )}
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        recommendedPartners.length > 0 && (
          <div className="qu-channels-grid">
            <Row gutter={16}>
              {recommendedPartners.map((partner: IPartnerCard) => (
                <Col key={partner.id} xs={24} sm={12} md={6}>
                  <ProfileCard
                    onClick={(e: MouseEvent) => onOpenModal(e, `${partner.id}`, `${partner.name}`)}
                    {...partner}
                  />
                </Col>
              ))}
            </Row>
          </div>
        )
      )}
      {!isLoading && !recommendedPartners.length && <Row justify="center">No partners...</Row>}
      {othersPartners.length > 0 && (
        <>
          <div className="qu-channels-header mt-50">
            <Row gutter={16} align="bottom" justify="space-between">
              <Col>
                <h1>
                  <span>Other Channels</span>
                </h1>
                <p>These are channels that don't match most or all your desired criteria.</p>
              </Col>
            </Row>
          </div>
          <div className="qu-channels-grid">
            <Row gutter={16}>
              {othersPartners.map((partner: IPartnerCard) => (
                <Col key={partner.id} xs={24} sm={12} md={6}>
                  <ProfileCard
                    onClick={(e: MouseEvent) => onOpenModal(e, `${partner.id}`, `${partner.name}`)}
                    {...partner}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}

      <Modal
        wrapClassName="qu-modal qu-modal-details testahel-qu-modal-details"
        closeIcon={closeIcon}
        visible={!!partnerId}
        onCancel={closeModal}
        footer={
          <div className="footer-arrows">
            <div onClick={handleLeftArrow} className="footer-arrows-left">
              {arrowIcon}
            </div>
            <div onClick={handleRightArrow} className="footer-arrows-right">
              {arrowIcon}
            </div>
          </div>
        }
      >
        {partnerId && (
          <TestahelBriefPartnerSinglePageForm
            ref={singlePartnerRef}
            partnerServices={partnerServices}
            briefId={briefId}
            onClose={closeModal}
            updateBrief={updateBrief}
            saveMediaBuy={saveMediaBuy}
          />
        )}
      </Modal>

      {dialogModal && (
        <DialogModal
          modalOpen={!!dialogModal}
          question={modalData.question}
          description={modalData.description}
          submitButtonText={modalData.submitButtonText}
          cancelButtonText={modalData.cancelButtonText}
          onClose={closeDialogModal}
          onProceed={onProceedModal}
        />
      )}
    </div>
  )
}
