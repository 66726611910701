import IAction from 'src/interfaces/IAction'
import * as CONSTANTS from './teamMembers.constants'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { requestHttp, urls } from 'src/api'
import { IDepartmentsList, ITeamMembersResponse } from './teamMembers.types'
import { getPermissionsList } from './teamMembers.selectors'
import { actions } from '../../core/core.module'

export const fetchDepartmentsListSuccess = (departmentsList: IDepartmentsList[]): IAction => ({
  type: CONSTANTS.FETCH_DEPARTMENTS_LIST_SUCCESS,
  payload: { departmentsList },
})

export const fetchTeamMembersListSuccess = (teamMembers: ITeamMembersResponse): IAction => ({
  type: CONSTANTS.FETCH_TEAM_MEMBERS_LIST_SUCCESS,
  payload: { teamMembers },
})

export const saveNewDepartment = (payload: any): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<number> => {
  try {
    const response = await requestHttp.post(urls.postAddDepartmentUrl(), payload)
    if (response.data.status == 201) {
      dispatch(fetchDepartmentsList())
      dispatch(fetchTeamMembersList())
    }
    return response.data.status
  } catch (error: any) {
    return error
  }
}

export const updateDepartment = (payload: any, id: number): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<number> => {
  try {
    const response = await requestHttp.put(urls.putDepartmentUrl(id), payload)
    if (response.data.status == 200) {
      dispatch(fetchDepartmentsList())
      dispatch(fetchTeamMembersList())
    }
    return response.data.status
  } catch (error: any) {
    return error
  }
}

export const deleteDepartment = (payload: number): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  try {
    const response = await requestHttp.delete(urls.deleteDepartmentUrl(payload))
    dispatch(fetchDepartmentsList())
    dispatch(fetchTeamMembersList())
    return response
  } catch (error: any) {
    return error
  }
}

export const saveNewTeamMember = (payload: any): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<number> => {
  try {
    const response = await requestHttp.post(urls.postAddTeamMemberUrl(), payload)
    if (response.data.status == 201) dispatch(fetchTeamMembersList())
    return response.data.status
  } catch (error: any) {
    return error
  }
}

export const updateTeamMember = (
  payload: any,
  id: number,
  loggedin_id: number
): ThunkAction<void, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<number> => {
  try {
    const response = await requestHttp.patch(urls.patchTeamMemberUrl(id), payload)
    if (response.data.status == 200) {
      dispatch(fetchTeamMembersList())
      if (id === loggedin_id) {
        dispatch(actions.getUser())
      }
    }
    return response.data.status
  } catch (error: any) {
    return error
  }
}

export const deleteTeamMember = (payload: number): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  try {
    const response = await requestHttp.delete(urls.deleteTeamMemberUrl(payload))
    dispatch(fetchTeamMembersList())
    return response
  } catch (error: any) {
    return error
  }
}

export const fetchDepartmentsList = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<IDepartmentsList[]> => {
  try {
    const response = await requestHttp.get(urls.getDepartmentsListUrl())
    const content = new Array()
    response.data.data.content.map((ele: any) => {
      content.push({
        label: ele.name,
        value: ele.id,
        isOwner: ele.isOwner,
      })
    })
    dispatch(fetchDepartmentsListSuccess(content))
    return content
  } catch (error: any) {
    return error
  }
}

export const fetchTeamMembersList = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: any
): Promise<ITeamMembersResponse> => {
  try {
    const response = await requestHttp.get(urls.getTeamMembersListUrl())
    const permissionLevels = getPermissionsList(getState())
    let modiiedContent = response.data.data.content
    modiiedContent.map((ele: any) => {
      ele.partnerMembers.map((ele2: any) => {
        permissionLevels.map((ele1: any) => {
          if (ele1.value == ele2.partnerPermissionLevel) ele2.partnerPermissionLevelLabel = ele1.label
        })
      })
    })

    let content: any
    let isAccountOwnerAccount = modiiedContent.filter((ele: any) => ele.isOwner)
    content = isAccountOwnerAccount[0]
    content['isOpen'] = true
    content['subMembers'] = []
    modiiedContent.map((ele: any) => {
      if (!ele.isOwner) {
        content['subMembers'].push({
          ...ele,
          isOpen: true,
        })
      }
    })
    dispatch(fetchTeamMembersListSuccess(content))
    return content
  } catch (error: any) {
    return error
  }
}
