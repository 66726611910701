import IAction from 'src/interfaces/IAction'
import * as CONSTANTS from './invitingClient.constants'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { requestHttp, urls } from 'src/api'
import { IInvitedClients, IInvitedClientsTableParams } from './invitingClient.types'

export const getInvitedClientsSuccess = (invitedClients: IInvitedClients[], total: number): IAction => ({
  type: CONSTANTS.FETCH_INVITED_CLIENTS_LIST_SUCCESS,
  payload: { invitedClients, total },
})

export const inviteClient = (payload: any): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<number> => {
  try {
    const response = await requestHttp.post(urls.postInviteClient(), payload)
    return response.data.status
  } catch (error: any) {
    return error
  }
}

export const getInviteClients = (params: IInvitedClientsTableParams): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<IInvitedClients[]> => {
  try {
    const response = await requestHttp.get(urls.getInviteClient(), { params })
    dispatch(getInvitedClientsSuccess(response.data.data.content.results, response.data.data.content.total))
    return response.data.data.content
  } catch (error: any) {
    return error
  }
}

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const setParamsAndFetch = (
  params: IInvitedClientsTableParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(setRequestListParams(params))
  await dispatch(getInviteClients(params))
}

export const setRequestListParams = (params: IInvitedClientsTableParams): IAction => ({
  type: CONSTANTS.SET_REQUEST_LIST_PARAMS,
  payload: { params },
})
