import get from 'lodash/get'

import IAction from 'src/interfaces/IAction'
import { DEFAULT_BILL_TABLE_PARAMS } from 'src/constants/bill'
import { IState, IBillingsListElement, IBillingPaymentUploadTypes, IBillingsPayment } from './billings.types'
import * as CONSTANTS from './billings.constants'

const initialState: IState = {
  fetchingBillListError: '',
  billListLoading: true,
  billList: {
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  params: DEFAULT_BILL_TABLE_PARAMS,
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.FETCH_BILLS_REQUEST:
      return {
        ...state,
        billListLoading: true,
      }
    case CONSTANTS.FETCH_BILLS_SUCCESS:
      const billList = get(action, 'payload.billList') || {total:0,results:[]};
      const totalPages = Math.ceil(billList?.total / state.params.limit)
      return {
        ...state,
        billList,
        pages: { ...state.pages, totalPages },
        billListLoading: false,
      }
    case CONSTANTS.FETCH_BILLS_FAILURE:
      return { ...state, fetchingBillListError: action.error, billListLoading: false }

    case CONSTANTS.SET_BILL_LIST_PARAMS:
      return { ...state, params: get(action, 'payload.params') }

    case CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, pages: { ...state.pages, currentPage: get(action, 'payload.page') } }

    case CONSTANTS.FILE_UPLOAD_REQUEST: {
      const filesType: IBillingPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isUploading = true
      updatedBill.payment![filesType].uploadingError = ''

      const updatedBillList = state.billList.results.map(bill => (bill.id === updatedBill.id ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_UPLOAD_SUCCESS: {
      const filesType: IBillingPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isUploading = false

      const updatedBillList = state.billList.results.map(bill => (bill.id === updatedBill.id ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_UPLOAD_FAILURE: {
      const filesType: IBillingPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isUploading = false
      updatedBill.payment![filesType].uploadingError = get(action, 'payload.error')

      const updatedBillList = state.billList.results.map(bill => (bill.id === updatedBill.id ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_REMOVE_REQUEST: {
      const filesType: IBillingPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isRemoving = true
      updatedBill.payment![filesType].removingError = ''

      const updatedBillList = state.billList.results.map(bill => (bill.id === updatedBill.id ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_REMOVE_SUCCESS: {
      const filesType: IBillingPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isRemoving = false

      const updatedBillList = state.billList.results.map(bill => (bill.id === updatedBill.id ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    case CONSTANTS.FILE_REMOVE_FAILURE: {
      const filesType: IBillingPaymentUploadTypes = get(action, 'payload.filesType')
      const updatedBill: IBillingsListElement = get(action, 'payload.bill')
      updatedBill.payment![filesType].isRemoving = false
      updatedBill.payment![filesType].removingError = get(action, 'payload.error')

      const updatedBillList = state.billList.results.map(bill => (bill.id === updatedBill.id ? updatedBill : bill))
      return { ...state, billList: { ...state.billList, results: updatedBillList } }
    }

    default:
      return state
  }
}
