import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import BriefTypeForm from './briefForms/BriefType.form'
import BriefFooter from './components/Brief.footer'
import { BRIEF_FORM_HEADER_TITLE, BRIEF_STEPS, BRIEF_TYPES } from '../../../constants/brief'
import BriefHeader from './components/Brief.header'
import { actions } from '../brief.module'
import { actions as specialClientsActions } from '../../specialClients/specialClients.module'
import { useSelector } from 'react-redux'
import { getBrief } from '../brief.selectors'
import { LOCAL_STORAGE_KEYS, PATHS } from '../../../constants'
import { IconsCommon, Modal, QButton } from 'quantum_components'
import { ReactComponent as CircleCheckIcon } from 'src/assets/icons/circle-check-icon.svg'

const closeIcon = <IconsCommon.IconClose />

export default function BriefCreateContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const history = useHistory()

  const brief = useSelector(getBrief)

  const [briefTypes, setBriefTypes] = useState<BRIEF_TYPES[]>([])
  const [briefData, setbriefData] = useState<any>()
  const [modalVisible, setModalVisible] = useState<any>(false)
  const [partnerLogo, setPartnerLogo] = useState<any>('')

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO)) {
      setPartnerLogo(localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO))
    }
    getInviterBriefDetails()
    return () => {
      dispatch(actions.resetBriefState())
    }
  }, [])

  const getInviterBriefDetails = async () => {
    if (
      (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) ||
      (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN))
    ) {
      let briefData: any = await dispatch(
        specialClientsActions.getInviterBriefDetails({
          data: localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
            ? sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            : localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID),
        })
      )
      setbriefData(briefData)
      if (briefData.exclusiveBrief && briefData.exclusiveBrief != null) {
        setModalVisible(true)
      }
    }
  }

  const handleFinishLater = async () => {
    await saveBrief()
    history.push(PATHS.BRIEF)
  }

  const handleNext = async () => {
    if (briefTypes.includes(BRIEF_TYPES.CONSUMER_RESEARCH)) {
      history.push(PATHS.TESTAHEL_BRIEF_CREATE)
      return
    }
    await saveBrief()
    if (sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) {
      sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
    }
  }

  const updateBriefBeforeSave = () => {
    const updatedBrief = { ...brief }
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = briefTypes

    return updatedBrief
  }

  const saveBrief = async () => {
    const briefForSave = updateBriefBeforeSave()
    await dispatch(actions.createBrief(briefForSave))
  }

  const handleBriefTypeStep = (briefTypes: BRIEF_TYPES[]) => {
    setBriefTypes(briefTypes)
  }

  const canProceed = () => {
    return !!briefTypes.length
  }

  const handleContinue = async () => {
    let deleteResponse: any = await dispatch(specialClientsActions.deleteBrief(briefData.exclusiveBrief.id))
    if (deleteResponse == 200) {
      setModalVisible(false)
      history.push(`/briefs/create`)
    }
  }

  const handleDiscard = () => {
    setModalVisible(false)
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
    history.push(`/briefs/edit/${briefData.exclusiveBrief.id}`)
  }

  return (
    <div className="qu-brief-layout">
      {(localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO) && (
          <header className="qu-header partner-logo-header">
            <img src={partnerLogo} className="partner-logo-brief" />
          </header>
        )}
      <BriefHeader
        title={BRIEF_FORM_HEADER_TITLE.CREATE}
        stepper={null}
        onClickCancel={() => {
          if (
            localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
            sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ) {
            if (sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) {
              history.push(`/p/${sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`)
              sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            } else {
              history.push(`/p/${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`)
            }
          } else {
            history.push(PATHS.BRIEF)
          }
        }}
      />
      <div className={`qu-brief-main ${partnerLogo ? 'partner-logo-qu-brief-main' : ''}`}>
        <BriefTypeForm briefTypes={briefTypes} onChange={handleBriefTypeStep} />
      </div>
      <BriefFooter
        className=''
        disableNext={!canProceed()}
        disableBack={false}
        isZeroStep={true}
        onBack={() => null}
        onNext={handleNext}
        onFinishLater={handleFinishLater}
        nextButtonText={'Next'}
      />

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{'You have an existing brief!'}</h3>}
        closeIcon={closeIcon}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false)
          history.push(`/p/${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)}`)
        }}
        footer={false}
      >
        <div>
          <p className="mb-25">
            Would you like to continue your brief with {briefData?.exclusiveBrief?.exclusivePartner?.companyName}?
          </p>
          <div className="align-vertical text-center">
            <QButton onClick={handleDiscard} className="w-280 mb-20" type="primary">
              <span className="mr-5">Continue</span>
              <CircleCheckIcon />
            </QButton>
            <button onClick={handleContinue} type="button" className="button button--link mb-10">
              Discard and create new
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
