export enum MENU_ITEMS {
  DASHBOARD = 'Dashboard',
  REQUESTS = 'Requests',
  CAMPAIGNS = 'Campaigns',
  PARTNER_PROFILE = 'Partner Profile',
  PUBLIC_PROFILE = 'Public Profile',
  TEAM_MEMBERS = 'Team Members',
  INVITE_CLIENT = 'Invite a Client',
  BILLINGS = 'Billings',
  PARTNER_API = 'Partner API',
}
