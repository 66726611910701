import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { snakeCase } from 'lodash'
import { IconsCommon, Popover, QTable, Modal } from "quantum_components";
import {
  IAvailableServiceItem,
  IBriefPartnerDetails,
  IPartnerDetailsService,
  IServiceItemPricing,
  MediaSubtype,
} from "../../modules/brief/brief.types";
import { getPartnerPropertiesDetails } from "../../modules/client/marketplace/marketplace.selectors";
import {
  BRIEF_STEPS,
  DISTRIBUTION_TYPE,
  MEDIA_TYPES,
  MEDIA_TYPES_DURATION,
  BRIEF_TYPES,
  BANNERS_BY_MEDIA_TYPE,
} from "../../constants/brief";
import { useSelector } from "react-redux";
import { getCurrency, getCurrentUser } from "../../modules/core/core.selectors";
import {
  getLocaleCostWithoutCurrencyNDecimals,
  renderRoundedNumber,
} from "../../helpers/currency";
import {
  getBrief,
  getPartnerFilterSettings,
  getPartnerProperties,
} from "../../modules/brief/brief.selectors";
import { REQUEST_STATUS } from "../../constants/request";
import { getPartnerProfileSettings } from "src/modules/partner/partnerProfile/profile.selectors";
import { IPartner, MEDIA_TYPES_NAMES_FOR_PRICE, PRICE_FIELDS_MEDIA_TYPES } from "src/modules/partner/partnerProfile/profile.types";
import { getDuration } from "../../helpers/mediaTypes";
import { isInsert, isSample, onlyDistribution } from "../../helpers/brief";
import { getPartnerLocked } from "src/modules/client/marketplace/marketplace.selectors";
import { DynamicPricingTypeLabels, PRICING_MODEL } from "src/constants/profile";
import { cloneDeep } from "lodash";
import { getPartnerDetails } from "src/modules/partner/publicProfile/publicProfile.selectors";
import { Tooltip } from "antd";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";

const closeIcon = <IconsCommon.IconClose />;

interface Props {
  details: IBriefPartnerDetails;
  selectedServices: IAvailableServiceItem[];
  onChangeServices: (selected: IAvailableServiceItem[]) => void;
  editMode: boolean;
  showModal?: () => void;
  isBrief?: boolean;
}

export default function DetailsServicesTable({
  details,
  selectedServices,
  onChangeServices,
  editMode,
  showModal,
  isBrief,
}: Props): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translation', 'translationCommon', 'translationMarketplace'])
  const filterSettings = useSelector(getPartnerFilterSettings);
  const partnerProfileSettings = useSelector(getPartnerProfileSettings);
  const brief = useSelector(getBrief);
  const mediaSubTypes = details.mediaSubtypes
    ? details.mediaSubtypes
    : filterSettings.mediaSubtypes.length
      ? filterSettings.mediaSubtypes
      : partnerProfileSettings.mediaSubtypes;
  const currency = useSelector(getCurrency);
  const partnerLocked = useSelector(getPartnerLocked);
  const currentUser = useSelector(getCurrentUser);
  const partnerProperties = useSelector(getPartnerProperties);
  const partnerDetails = useSelector(getPartnerDetails);
  const partnerPropertiesDetails = useSelector(getPartnerPropertiesDetails);

  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [activeService, setActiveService] = useState<any>({});

  const [imagesLoading, setImagesLoading] = useState<boolean>(false);
  const onlyInsert = isBrief
    ? isInsert(brief[BRIEF_STEPS.PRODUCT].distributionItem)
    : true;
  const onlySample = isBrief
    ? isSample(brief[BRIEF_STEPS.PRODUCT].distributionItem)
    : true;
  const isOnlyDistribution = onlyDistribution(
    brief[BRIEF_STEPS.TYPE].briefTypes
  );

  const [availableServices, setAvailableServices] = useState<
    IPartnerDetailsService[]
  >([]);
  const [seeMore, setSeeMore] = useState<number>(0);
  const [columns, setColumns] = useState<any>([]);
  const [propertiesPartner, setPropertiesPartner] = useState<any>(
    partnerProperties.hasDynamicSamplePricing !== undefined
      ? partnerProperties
      : partnerPropertiesDetails?.hasDynamicSamplePricing !== undefined
        ? partnerPropertiesDetails
        : partnerDetails?.partnerProperties
  );

  useEffect(() => {
    async function calculateMyData() {
      if (isBrief) {
        if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)) {
          let localAvailableServices: any = [];

          details.availableServices.filter((ele) => {
            const name = ele.serviceSubName
              ? ele.serviceSubName
              : ele.serviceName;
            const targetService = selectedServices.find(
              (s: IAvailableServiceItem) => s.key === name
            );
            targetService && localAvailableServices.push(ele);
          });

          details.availableServices.filter((ele) => {
            if (
              brief.BRIEF_MEDIA_BUY.mediaTypes.includes(ele.serviceName) &&
              !localAvailableServices.includes(ele)
            )
              localAvailableServices.push(ele);
          });

          setAvailableServices(localAvailableServices);

          setSeeMore(0);
          await seeMoreLess(true);
        }
      } else {
        setAvailableServices(details.availableServices);
      }
    }

    calculateMyData();
  }, [details]);

  const seeMoreLess = async (isSeeMore: boolean) => {
    if (isSeeMore) {
      if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)) {
        let localAvailableServices: any = [];

        details.availableServices.filter((ele) => {
          const name = ele.serviceSubName
            ? ele.serviceSubName
            : ele.serviceName;
          const targetService = selectedServices.find(
            (s: IAvailableServiceItem) => s.key === name
          );
          targetService && localAvailableServices.push(ele);
        });

        details.availableServices.filter((ele) => {
          if (
            brief.BRIEF_MEDIA_BUY.mediaTypes.includes(ele.serviceName) &&
            !localAvailableServices.includes(ele)
          )
            localAvailableServices.push(ele);
        });

        details.availableServices.filter((ele) => {
          if (!localAvailableServices.includes(ele))
            localAvailableServices.push(ele);
        });

        setAvailableServices(localAvailableServices);

        // setSeeMore(2)
      }
    } else {
      if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)) {
        let localAvailableServices: any = [];

        details.availableServices.filter((ele) => {
          const name = ele.serviceSubName
            ? ele.serviceSubName
            : ele.serviceName;
          const targetService = selectedServices.find(
            (s: IAvailableServiceItem) => s.key === name
          );
          targetService && localAvailableServices.push(ele);
        });

        details.availableServices.filter((ele) => {
          if (
            brief.BRIEF_MEDIA_BUY.mediaTypes.includes(ele.serviceName) &&
            !localAvailableServices.includes(ele)
          )
            localAvailableServices.push(ele);
        });
        setAvailableServices(localAvailableServices);

        setSeeMore(1);
      }
    }

    servicesData();
  };

  const openModal = (record: IAvailableServiceItem) => {
    // console.log(record.key)
    // showImageModal = true;
    setActiveService(record);
    setShowImageModal(true);

    // console.log(showImageModal,activeService)
  };

  const closeModal = () => {
    // setActiveService({key:'',type:'',product:'',pricing:{amount:0,currency:currency,duration:MEDIA_TYPES_DURATION.PER_DAY,quantity:0,total:0}})
    setActiveService({});
    setShowImageModal(false);
    // showImageModal = false;
  };

  useEffect(() => {
    getImages(details.availableServices);
  }, [details]);

  const handleChangeNew = (record: IAvailableServiceItem) => {
    // console.log("called");
    // console.log(selectedServices)

    let checkService = selectedServices.find(
      (ele: IAvailableServiceItem) => ele.key === record.key
    );
    if (checkService) {
      handleRemove(checkService, selectedServices);
    } else {
      let fields = [...selectedServices];
      fields = [...fields, record];
      onChangeServices(fields);
      // selectedServices = [...selectedServices, record];
      handleChange([], fields);
    }
  };

  useEffect(() => {
    async function calculateColumns() {
      if (details.mbPricingModel === PRICING_MODEL.DURATION) {
        setColumns([
          {
            title: t('brief.table-columns.Campaigns', { ns: 'translationCommon' }),
            dataIndex: "product",
            width: "35%",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (text: string, record: IAvailableServiceItem) => {
              const rejected = record.requestStatus === REQUEST_STATUS.REJECTED;

              const handleClick = () => {
                if (!record.blocked || !showModal) return;
                showModal();
              };

              const itemImg =
                (record.details?.serviceImageUrl ||
                  BANNERS_BY_MEDIA_TYPE[record.details!.serviceName]?.image) ??
                BANNERS_BY_MEDIA_TYPE[MEDIA_TYPES.CUSTOM_MEDIA_SERVICE]?.image;

              return record.type === MEDIA_TYPES.DISTRIBUTION ? (
                <div className="" onClick={handleClick}>
                  <span className="cell-services">
                    {text}
                    {record.productSubType && ":"}
                  </span>
                  {record.productSubType && (
                    <p className="product-sub-type">
                      {DynamicPricingTypeLabels[record.productSubType]}
                    </p>
                  )}
                  {rejected && (
                    <span className="text-red font-size-12 ml-5">
                      (rejected)
                    </span>
                  )}
                </div>
              ) : (
                <div className="box-center" onClick={handleClick}>
                  <img
                    src={itemImg}
                    alt="Service Image"
                    className="image-within-available-services-table"
                  />
                  <span className="cell-services">
                    {t('services.media-buy.types.' + snakeCase(text), text, { ns: 'translation' })}
                    {record.productSubType && ":"}
                  </span>
                  {record.productSubType && (
                    <p className="product-sub-type">
                      {DynamicPricingTypeLabels[record.productSubType]}
                    </p>
                  )}
                  {rejected && (
                    <span className="text-red font-size-12 ml-5">
                      (rejected)
                    </span>
                  )}
                </div>
              );
            },
          },
          {
            title: t('brief.table-columns.Type', { ns: 'translationCommon' }),
            dataIndex: "type",
            width: "25%",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (type: MEDIA_TYPES) => (
              <div className="cell-type">
                {type === MEDIA_TYPES.DISTRIBUTION ? (
                  <IconsCommon.IconSample />
                ) : (
                  <IconsCommon.IconDevices />
                )}
                <span>{type ? t('services.sub-types.' + snakeCase(type)) : '-'}</span>
              </div>
            ),
          },
          {
            title: t('brief.table-columns.Pricing', { ns: 'translationCommon' }),
            dataIndex: "pricing",
            width: "20%",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (
              pricing: IServiceItemPricing,
              record: IAvailableServiceItem
            ) =>
              record.type === MEDIA_TYPES.DISTRIBUTION ? (
                <div
                  className={`cell-pricing ${partnerLocked && !currentUser.isApproved
                    ? "cell-pricing-blur"
                    : ""
                    }`}
                >
                  {renderRoundedNumber(pricing.amount, pricing.currency)}
                  {/* <span>{pricing.duration}</span> */}
                </div>
              ) : (
                <div
                  className={`cell-pricing ${partnerLocked && !currentUser.isApproved
                    ? "cell-pricing-blur"
                    : ""
                    }`}
                >
                  {renderRoundedNumber(pricing.amount, pricing.currency)}
                  <span> {pricing.duration &&
                    t('services.pricing-duration.' + snakeCase(pricing.duration))}</span>
                </div>
              ),
          },
          {
            title: t('brief.table-columns.action', { ns: 'translationCommon' }),
            dataIndex: "action",
            width: "20%",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (
              serviceImpressions: any,
              record: IAvailableServiceItem
            ) => (
              <div className="cell-type">
                {
                  editMode &&
                  <span
                    onClick={() => {
                      handleChangeNew(record);
                    }}
                    className="cursorPointer"
                  >
                    {selectedServices.find(
                      (ele: IAvailableServiceItem) => ele.key === record.key
                    ) ? (
                      <Tooltip title={t("Remove from cart")}>
                        {""}
                        <MinusCircleFilled style={{ fontSize: '21px', color: 'black' }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t("Add to cart")}>
                        {""}
                        <PlusCircleFilled style={{ fontSize: '21px', color: 'black' }} />
                      </Tooltip>

                    )}
                  </span>
                }

                <span
                  className="cursorPointer ml-1"
                  onClick={() => {
                    openModal(record);
                  }}
                >
                  <Tooltip title={t("Details")}>
                    {""}
                    <IconsCommon.IconInfo />
                  </Tooltip>
                </span>
              </div>
            ),
          },
        ]);
      } else if (details.mbPricingModel === PRICING_MODEL.METRICS) {
        setColumns([
          {
            title: "PRODUCT",
            dataIndex: "product",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (text: string, record: IAvailableServiceItem) => {
              const rejected = record.requestStatus === REQUEST_STATUS.REJECTED;

              const handleClick = () => {
                if (!record.blocked || !showModal) return;
                showModal();
              };

              return record.type === MEDIA_TYPES.DISTRIBUTION ? (
                <div className="cursorPointer" onClick={handleClick}>
                  <span className="cell-services">
                    {text}
                    {record.productSubType && ":"}
                  </span>
                  {record.productSubType && (
                    <p className="product-sub-type">
                      {DynamicPricingTypeLabels[record.productSubType]}
                    </p>
                  )}
                  {rejected && (
                    <span className="text-red font-size-12 ml-5">
                      (rejected)
                    </span>
                  )}
                </div>
              ) : (
                <Popover
                  overlayClassName="qu-banner-popover popover-padded"
                  content={contentMedia(record)}
                  placement="right"
                  getPopupContainer={(node: any) => node.parentNode}
                >
                  <div className="cursorPointer" onClick={handleClick}>
                    <span className="cell-services">
                      {text}
                      {record.productSubType && ":"}
                    </span>
                    {record.productSubType && (
                      <p className="product-sub-type">
                        {DynamicPricingTypeLabels[record.productSubType]}
                      </p>
                    )}
                    {rejected && (
                      <span className="text-red font-size-12 ml-5">
                        (rejected)
                      </span>
                    )}
                  </div>
                </Popover>
              );
            },
          },
          {
            title: "Type",
            dataIndex: "type",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (type: MEDIA_TYPES) => (
              <div className="cell-type">
                {type === MEDIA_TYPES.DISTRIBUTION ? (
                  <IconsCommon.IconSample />
                ) : (
                  <IconsCommon.IconDevices />
                )}
                <span>{type}</span>
              </div>
            ),
          },
          {
            title: "Pricing",
            dataIndex: "pricing",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (
              pricing: IServiceItemPricing,
              record: IAvailableServiceItem
            ) =>
              record.type === MEDIA_TYPES.DISTRIBUTION ? (
                <div
                  className={`cell-pricing ${partnerLocked && !currentUser.isApproved
                    ? "cell-pricing-blur"
                    : ""
                    }`}
                >
                  {renderRoundedNumber(pricing.amount, pricing.currency)}
                  <span>{pricing.duration}</span>
                </div>
              ) : (
                <div>-</div>
              ),
          },
          {
            title: "Daily Impression",
            dataIndex: "serviceDailyImpressions",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (
              serviceDailyImpressions: any,
              record: IAvailableServiceItem
            ) =>
              record.type !== MEDIA_TYPES.DISTRIBUTION && (
                <div
                  className={`cell-pricing ${partnerLocked && !currentUser.isApproved
                    ? "cell-pricing-blur"
                    : ""
                    }`}
                >
                  {serviceDailyImpressions
                    ? getLocaleCostWithoutCurrencyNDecimals(
                      serviceDailyImpressions
                    )
                    : "-"}
                  <br />
                  {record.details?.serviceCostPerImpression && (
                    <p>
                      CPM{" "}
                      <span>
                        {renderRoundedNumber(
                          record.details?.serviceCostPerImpression,
                          currency
                        )}
                      </span>
                    </p>
                  )}
                </div>
              ),
          },
          {
            title: "Daily Click",
            dataIndex: "serviceDailyClicks",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (serviceDailyClicks: any, record: IAvailableServiceItem) =>
              record.type !== MEDIA_TYPES.DISTRIBUTION && (
                <div
                  className={`cell-pricing ${partnerLocked && !currentUser.isApproved
                    ? "cell-pricing-blur"
                    : ""
                    }`}
                >
                  {serviceDailyClicks
                    ? getLocaleCostWithoutCurrencyNDecimals(serviceDailyClicks)
                    : "-"}
                  <br />
                  {record.details?.serviceCostPerClick && (
                    <p>
                      CPC{" "}
                      <span>
                        {renderRoundedNumber(
                          record.details?.serviceCostPerClick,
                          currency
                        )}
                      </span>
                    </p>
                  )}
                </div>
              ),
          },
          {
            title: "Daily Reach",
            dataIndex: "serviceDailyReach",
            align: i18n.dir() === 'ltr' ? 'left' : 'right',
            render: (serviceDailyReach: any, record: IAvailableServiceItem) =>
              record.type !== MEDIA_TYPES.DISTRIBUTION && (
                <div
                  className={`cell-pricing ${partnerLocked && !currentUser.isApproved
                    ? "cell-pricing-blur"
                    : ""
                    }`}
                >
                  {serviceDailyReach
                    ? getLocaleCostWithoutCurrencyNDecimals(serviceDailyReach)
                    : "-"}
                  <br />
                  {record.details?.serviceCostPerReach && (
                    <p>
                      CPR{" "}
                      <span>
                        {renderRoundedNumber(
                          record.details?.serviceCostPerReach,
                          currency
                        )}
                      </span>
                    </p>
                  )}
                </div>
              ),
          },
        ]);
      }
    }

    calculateColumns();
  }, [details.mbPricingModel, brief]);

  useEffect(() => {
    if (
      !brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE) &&
      brief.BRIEF_MEDIA_BUY.marketingObjective &&
      columns[2]?.title === "Pricing" &&
      details.mbPricingModel === PRICING_MODEL.METRICS
    ) {
      const data = cloneDeep(columns);
      data.splice(2, 1);
      setColumns(data);
    }
  }, [columns]);

  const newDistribution = (distributionType: DISTRIBUTION_TYPE) => {
    const isSample = distributionType === DISTRIBUTION_TYPE.PRODUCT_SAMPLE;
    const amount = isSample ? details.pricePerSample : details.pricePerInsert;
    const duration = isSample
      ? MEDIA_TYPES_DURATION.PER_SAMPLE
      : MEDIA_TYPES_DURATION.PER_INSERT;

    return {
      key: distributionType,
      product: distributionType,
      type: MEDIA_TYPES.DISTRIBUTION,
      pricing: {
        amount: amount,
        currency: currency,
        quantity: null,
        total: 0,
        duration: duration,
      },
    };
  };

  const getImages = async (services: IPartnerDetailsService[]) => {
    setImagesLoading(true);
    const promises = await services.map((service: IPartnerDetailsService) => {
      return new Promise(function (resolve: any, reject: any) {
        const img = new Image();
        img.src = service.serviceImageUrl;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);
    setImagesLoading(false);
  };

  const getPriceField = (service: IPartnerDetailsService) => {
    if(service.servicePricePerExecution) return "servicePricePerExecution";

    // @ts-ignore
    const field = MEDIA_TYPES_NAMES_FOR_PRICE[service.serviceName]
    if (field) return field;

    if (!field) {
      if (service.serviceDuration) return PRICE_FIELDS_MEDIA_TYPES.DURATION;
      if (service.servicePriceForPush) return PRICE_FIELDS_MEDIA_TYPES.PUSH;
      if (service.servicePriceForActivity) return PRICE_FIELDS_MEDIA_TYPES.ACTIVITY;
      return PRICE_FIELDS_MEDIA_TYPES.DURATION;
    }
  }

  // @ts-ignore
  const getDurationForAllMediaTypes = (service: IPartnerDetailsService) => {
    // @ts-ignore
    let duration = getDuration(service);
    if (duration) return duration;

    if (!duration) {
      if (service.servicePriceForPush) return MEDIA_TYPES_DURATION.PER_PUSH;
      if (service.servicePriceForActivity) return MEDIA_TYPES_DURATION.PER_ACTIVITY;
      return MEDIA_TYPES_DURATION.PER_ACTIVITY
    }

  }

  const servicesData = () => {
    const services = [] as IAvailableServiceItem[];

    if (details.isSampleDistribution && onlySample) {
      const targetService = selectedServices.find(
        (s: IAvailableServiceItem) => s.key === DISTRIBUTION_TYPE.PRODUCT_SAMPLE
      );

      targetService && !targetService.productSubType
        ? services.push(targetService)
        : propertiesPartner?.hasDynamicSamplePricing
          ? propertiesPartner?.dynamicSamplePriceTypes &&
          propertiesPartner.dynamicSamplePriceTypes.map((ele: any) => {
            services.push({
              key: DISTRIBUTION_TYPE.PRODUCT_SAMPLE + ele,
              product: DISTRIBUTION_TYPE.PRODUCT_SAMPLE,
              productSubType: ele,
              type: MEDIA_TYPES.DISTRIBUTION,
              pricing: {
                amount: propertiesPartner.dynamicSamplePrices
                  ? propertiesPartner.dynamicSamplePrices[ele]
                  : 0,
                currency: currency,
                quantity:
                  selectedServices.find(
                    (ele1: any) => ele1.productSubType === ele
                  )?.pricing.quantity || null,
                total:
                  selectedServices.find(
                    (ele1: any) => ele1.productSubType === ele
                  )?.pricing.total || 0,
                duration: MEDIA_TYPES_DURATION.PER_SAMPLE,
              },
            });
          })
          : services.push(newDistribution(DISTRIBUTION_TYPE.PRODUCT_SAMPLE));
    }

    if (details.isInsertDistribution && onlyInsert) {
      const targetService = selectedServices.find(
        (s: IAvailableServiceItem) => s.key === DISTRIBUTION_TYPE.PRINTED_INSERT
      );
      targetService
        ? services.push(targetService)
        : services.push(newDistribution(DISTRIBUTION_TYPE.PRINTED_INSERT));
    }

    const mediaTypeServices = availableServices?.map(
      (service: IPartnerDetailsService) => {
        const name = service.serviceSubName
          ? service.serviceSubName
          : service.serviceName;

        const duration = getDurationForAllMediaTypes(service)

        const targetService = selectedServices.find(
          (s: IAvailableServiceItem) => s.key === name
        );

        if (targetService) {
          return {
            ...targetService,
            details: service,
            serviceCostPerClick: service.serviceCostPerClick,
            serviceCostPerImpression: service.serviceCostPerImpression,
            serviceCostPerReach: service.serviceCostPerReach,
            serviceDailyClicks: service.serviceDailyClicks,
            serviceDailyImpressions: service.serviceDailyImpressions,
            serviceDailyReach: service.serviceDailyReach,
          };
        } else {
          //@ts-ignore
          const priceField = getPriceField(service)


          // console.log(service)

          //@ts-ignore
          const price = service[priceField];

          console.log(price)


          const subType = mediaSubTypes.find(
            (type: MediaSubtype) => type.name === service.serviceName
          );

          // console.log(name,priceField,price,duration,subType)


          return {
            key: name,
            product: name,
            type: subType ? subType.subtype : "",
            pricing: {
              amount: price,
              currency: currency,
              quantity: null,
              total: 0,
              duration: duration,
            },
            details: service,
            blocked: isOnlyDistribution,
            serviceCostPerClick: service.serviceCostPerClick,
            serviceCostPerImpression: service.serviceCostPerImpression,
            serviceCostPerReach: service.serviceCostPerReach,
            serviceDailyClicks: service.serviceDailyClicks,
            serviceDailyImpressions: service.serviceDailyImpressions,
            serviceDailyReach: service.serviceDailyReach,
          };
        }
      }
    );

    const updatedMediaTypeServices = mediaTypeServices.filter(ele => ele.pricing.amount)
    const available_Services = [];
    if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE))
      available_Services.push(...services);
    if (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING))
      available_Services.push(...updatedMediaTypeServices);

    return isBrief ? available_Services : [...services, ...updatedMediaTypeServices];
  };

  const contentMedia = (item: IAvailableServiceItem) => {
    if (!item.key) return;
    const itemImg = (item.details?.serviceImageUrl || BANNERS_BY_MEDIA_TYPE[item.details!.serviceName]?.image) ?? BANNERS_BY_MEDIA_TYPE[MEDIA_TYPES.CUSTOM_MEDIA_SERVICE]?.image;
    return (
      <div className="content-media-popup-container">
        <div className="p fw-500">
          {t('services.media-buy.types.' + snakeCase(item.product), item.product, { ns: 'translation' })}
        </div>
        {item.details && (
          <div className="p p--sm tc--light persist-whitespace">
            {item.details.serviceDescription}
          </div>
        )}
        {item.details && item.details.serviceImpressions > 0 && (
          <div className="grid-row grid-row--aic grid-row--jcb mt--100">
            <div className="grid-col grid-col--auto p p--ms fw-700">
              {t('marketplace.Avg_Impressions', { ns: 'translationMarketplace' })}
            </div>
            <div className="grid-col grid-col--auto p p--sm">
              {item.details.serviceImpressions}
            </div>
          </div>
        )}
        {item.details && (
          <div className="mt--100 banner-popover__image">
            <img src={itemImg} width="292" height="170" alt="" />
          </div>
        )}
      </div>
    );
  };

  const handleRemove = (
    uncheckedService: IAvailableServiceItem,
    selectedRows: IAvailableServiceItem[]
  ) => {
    let services = [...selectedRows];
    services.splice(services.indexOf(uncheckedService), 1);
    selectedServices = [...services];
    onChangeServices(services);
  };

  // @ts-ignore
  const data: IAvailableServiceItem[] = servicesData();
  const selectedRowKeys = selectedServices?.map(
    (service: IAvailableServiceItem) =>
      service.key &&
        service.key === DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
        service.productSubType
        ? service.key + service.productSubType
        : service.key
  );
  // console.log(selectedRowKeys, "selected");

  const handleChange = async (
    selectedKeys: string[],
    selectedRows: IAvailableServiceItem[]
  ) => {
    // console.log(selectedRows, "rows");

    const previousSelectedServices = selectedServices.find(
      (ele) =>
        ele.product === DISTRIBUTION_TYPE.PRODUCT_SAMPLE && ele.productSubType
    );
    const newSelectedServices = selectedRows.filter(
      (ele) =>
        ele.product === DISTRIBUTION_TYPE.PRODUCT_SAMPLE && ele.productSubType
    );

    if (
      propertiesPartner.dynamicSamplePriceTypes?.length &&
      propertiesPartner.dynamicSamplePriceTypes?.length > 0 &&
      newSelectedServices?.length ===
      propertiesPartner.dynamicSamplePriceTypes?.length
    ) {
      const uncheckedService = newSelectedServices.filter(
        (ele, index) => ele.productSubType
      );
      uncheckedService.map((ele, index) => {
        if (index !== 0) {
          handleRemove(ele, selectedRows);
          selectedRows.splice(selectedRows.indexOf(ele), 1);
        }
      });
    } else if (
      newSelectedServices.length > 1 &&
      newSelectedServices.length &&
      previousSelectedServices
    ) {
      const uncheckedService = newSelectedServices.find(
        (ele) => ele.productSubType === previousSelectedServices.productSubType
      );
      if (uncheckedService) {
        handleRemove(uncheckedService, selectedRows);
      } else {
        onChangeServices(selectedRows);
      }
    } else {
      onChangeServices(selectedRows);
    }

    if (isBrief) {
      if (
        selectedRows.find((ele) => !ele.productSubType) ||
        details.availableServices.length
      ) {
        if (!selectedRows.find((ele) => !ele.productSubType)) {
          if (data.length === selectedRows.length) setSeeMore(0);
          else if (data.length > selectedRows.length) setSeeMore(2);
          else setSeeMore(1);
        }
      } else {
        setSeeMore(0);
      }
    }
  };

  const rowSelection = {
    onChange: handleChange,
    getCheckboxProps: (record: IAvailableServiceItem) => {
      return {
        disabled: !editMode || record.blocked,
        name: record.key,
      };
    },
    selectedRowKeys: selectedRowKeys,
  };


  return (
    <div className="qu-details-table">
      <QTable
        loading={imagesLoading}
        className="qu-details-table"
        //@ts-ignore
        size="small"
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      {seeMore === 1 && (
        <span
          className="cursorPointer"
          style={{ color: "#1379AC" }}
          onClick={() => seeMoreLess(true)}
        >
          See More
        </span>
      )}
      {seeMore === 2 && (
        <span
          className="cursorPointer"
          style={{ color: "#1379AC" }}
          onClick={() => seeMoreLess(false)}
        >
          See Less
        </span>
      )}
      <Modal
        wrapClassName="qu-modal qu-modal-details"
        visible={showImageModal}
        closable={true}
        centered={true}
        closeIcon={closeIcon}
        onCancel={closeModal}
        footer={null}
      >
        {contentMedia(activeService)}
      </Modal>
    </div>
  );
}
