import { Col, Modal, QAvailableServiceCard, QButton, Row } from "quantum_components";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SpinnerCenter from "src/components/Spinner/spinner";
import { BANNERS_BY_MEDIA_TYPE, MEDIA_TYPES } from "src/constants/brief";
import { actions } from "../../briefV2.module";

interface IProps {
  id: string
}

export default function AvailableServiceForm({ id }: IProps): ReactElement {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [services, setServices] = useState([])
  const [tableKey, setTableKey] = useState(0)
  const [serviceModal, setServiceModal] = useState({ isVisible: false, data: null });

  useEffect(() => {
    fetchCoreSettings()
  }, [])

  const fetchCoreSettings = async () => {
    setLoading(true)
    const res: any = await dispatch(actions.fetchBriefSettings())
    if (res.status === 200) {
      const res1: any = await dispatch(actions.fetchPartnerDetails(Number(id), res.data.data.content.partnerProfileId));
      if (res1.status === 200) {
        setServices(res1.data.data.content.availableServices)
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handleSelectService = async (record: any, index: number) => {
    const localServices = services;
    localServices[index].isSelected = localServices[index].isSelected ? localServices[index].isSelected = false : localServices[index].isSelected = true
    setServices(localServices)
    setTableKey(tableKey + 1)
  }

  return (
    <div className="available-services" key={tableKey}>
      {loading && <SpinnerCenter />}
      <div className="title">
        <h3>What Services do you want to add to your campaign?</h3>
      </div>
      <div className="single-service">
        {
          services && !!services.length && services.map((ele, index) =>
            <QAvailableServiceCard
              key={index}
              headerImg={(ele?.serviceImageUrl ||
                BANNERS_BY_MEDIA_TYPE[ele!.serviceName]?.image) ??
                BANNERS_BY_MEDIA_TYPE[MEDIA_TYPES.CUSTOM_MEDIA_SERVICE]?.image}
              name={ele.serviceName}
              description={ele.serviceDescription}
              isSelected={ele.isSelected}
              onLearnMoreClick={() => setServiceModal({ isVisible: true, data: ele })}
              handleSelect={() => handleSelectService(ele, index)}
            />
          )
        }
      </div>

      <Modal
        wrapClassName="qu-modal"
        closable={false}
        visible={serviceModal.isVisible}
        onCancel={() => setServiceModal({ isVisible: false, data: null })}
        footer={false}
      >
        <div>
          <div className="AvailableServiceModal__thumb">
            <img src={(serviceModal?.data?.serviceImageUrl ||
              BANNERS_BY_MEDIA_TYPE[serviceModal?.data?.serviceName]?.image) ??
              BANNERS_BY_MEDIA_TYPE[MEDIA_TYPES.CUSTOM_MEDIA_SERVICE]?.image} alt="thumbnail" />
          </div>

          <div className="AvailableServiceModal__header">
            <div className="AvailableServiceModal__header-info">
              <span className="AvailableServiceModal__header-name">
                {serviceModal?.data?.serviceName}
              </span>
              <div className="AvailableServiceModal__header-description">
                {serviceModal?.data?.serviceDescription}
              </div>
            </div>
          </div>

          <div className="AvailableServiceModal__header">
            <div className="AvailableServiceModal__header-info">
              <span className="AvailableServiceModal__header-name">
                Dimensions
              </span>
              <div className="AvailableServiceModal__header-description">
                <Row>
                  <Col span={10}>
                    <span className="text-heading">Height</span>
                    <span className="text-value">150px</span>
                  </Col>
                  <Col span={10}>
                    <span className="text-heading">Width</span>
                    <span className="text-value">150px</span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="AvailableServiceModal__header">
            <div className="AvailableServiceModal__header-info">
              <span className="AvailableServiceModal__header-name">
                Impressions
              </span>
              <div className="AvailableServiceModal__header-description">
                <Row>
                  <Col span={8}>
                    <span className="text-heading">Reach</span>
                    <span className="text-value">20K</span>
                  </Col>
                  <Col span={8}>
                    <span className="text-heading">Views</span>
                    <span className="text-value">10K</span>
                  </Col>
                  <Col span={8}>
                    <span className="text-heading">Clicks</span>
                    <span className="text-value">33.5K</span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="align-vertical text-center mt-20">
            <QButton
              onClick={() => setServiceModal({ isVisible: false, data: null })}
              className="w-150"
              type="primary"
              size="small"
            >
              Close
            </QButton>
          </div>
        </div>
      </Modal>
    </div>
  );
}
