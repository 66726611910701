export const MODULE_NAME = 'announcements'
/* actions */

export const FETCH_ANNOUNCEMENTS_LIST_REQUEST = `${MODULE_NAME}/FETCH_ANNOUNCEMENTS_LIST_REQUEST`
export const FETCH_ANNOUNCEMENTS_LIST_SUCCESS = `${MODULE_NAME}/FETCH_ANNOUNCEMENTS_LIST_SUCCESS`
export const FETCH_ANNOUNCEMENTS_LIST_FAILURE = `${MODULE_NAME}/FETCH_ANNOUNCEMENTS_LIST_FAILURE`

export const FETCH_ANNOUNCEMENT_BY_ID_REQUEST = `${MODULE_NAME}/FETCH_ANNOUNCEMENT_BY_ID_REQUEST`
export const FETCH_ANNOUNCEMENT_BY_ID_SUCCESS = `${MODULE_NAME}/FETCH_ANNOUNCEMENT_BY_ID_SUCCESS`
export const FETCH_ANNOUNCEMENT_BY_ID_FAILURE = `${MODULE_NAME}/FETCH_ANNOUNCEMENT_BY_ID_FAILURE`

export const SET_CURRENT_PAGE = `${MODULE_NAME}/SET_CURRENT_PAGE`
