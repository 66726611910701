import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import * as CONSTANTS from './dashboard.constants'
import {
  IChartDataResponse,
  IStatisticsCards,
  IStatisticsCardsResponse,
  IChartsParams,
  IChartData,
  IOpenRequestResponse,
  IOpenRequestItem,
  ILiveCampaignsResponse,
} from './dashboard.types'
import { getResponseErrorMessage } from 'src/helpers'
import { ICampaign } from '../../campaign/campaign.types'

export const statisticsCardsRequest = (): IAction => ({
  type: CONSTANTS.STATS_CARDS_REQUEST,
})
export const statisticsCardsSuccess = (statisticCards: IStatisticsCards): IAction => ({
  type: CONSTANTS.STATS_CARDS_SUCCESS,
  payload: { statisticCards },
})
export const statisticsCardsFailure = (error: string): IAction => ({
  type: CONSTANTS.STATS_CARDS_FAILURE,
  error,
})
export const fetchStatisticsCards = (): ThunkAction<
  Promise<AxiosResponse<IStatisticsCardsResponse>>,
  {},
  {},
  AnyAction
> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IStatisticsCardsResponse>> => {
  try {
    dispatch(statisticsCardsRequest())
    const response = await requestHttp.get<IStatisticsCardsResponse>(urls.getStatisticCardsUrl())
    const { content } = response.data.data
    dispatch(statisticsCardsSuccess(content.statisticCards))
    return response
  } catch (error: any) {
    dispatch(statisticsCardsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const chartDataRequest = (): IAction => ({
  type: CONSTANTS.CHART_REQUEST,
})
export const chartDataSuccess = (chartData: IChartData): IAction => ({
  type: CONSTANTS.CHART_SUCCESS,
  payload: { chartData },
})
export const chartDataFailure = (error: string): IAction => ({
  type: CONSTANTS.CHART_FAILURE,
  error,
})
export const fetchChartData = (
  params: IChartsParams
): ThunkAction<Promise<AxiosResponse<IChartDataResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IChartDataResponse>> => {
  try {
    dispatch(chartDataRequest())
    dispatch(setChartParams(params))
    const response = await requestHttp.get<IChartDataResponse>(urls.getChartsUrl(), { params })
    const { content } = response.data.data
    dispatch(chartDataSuccess(content))
    return response
  } catch (error: any) {
    dispatch(chartDataFailure(getResponseErrorMessage(error)))
    return error
  }
}
export const setChartParams = (params: IChartsParams): IAction => ({
  type: CONSTANTS.SET_CHART_PARAMS,
  payload: { params },
})

export const openRequestsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_OPEN_REQUESTS_REQUEST,
})
export const openRequestsSuccess = (openRequests: IOpenRequestItem[]): IAction => ({
  type: CONSTANTS.FETCH_OPEN_REQUESTS_SUCCESS,
  payload: { openRequests },
})
export const openRequestsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_OPEN_REQUESTS_FAILURE,
  error,
})
export const fetchOpenRequests = (): ThunkAction<
  Promise<AxiosResponse<IOpenRequestResponse>>,
  {},
  {},
  AnyAction
> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IOpenRequestResponse>> => {
  try {
    dispatch(openRequestsRequest())
    const response = await requestHttp.get<IOpenRequestResponse>(urls.getopenRequestsUrl())
    const { content } = response.data.data
    dispatch(openRequestsSuccess(content.requests))
    return response
  } catch (error: any) {
    dispatch(openRequestsFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const liveCampaignsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_LIVE_CAMPAIGNS_REQUEST,
})
export const liveCampaignsSuccess = (liveCampaigns: ICampaign[]): IAction => ({
  type: CONSTANTS.FETCH_LIVE_CAMPAIGNS_SUCCESS,
  payload: { liveCampaigns },
})
export const liveCampaignsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_LIVE_CAMPAIGNS_FAILURE,
  error,
})
export const fetchLiveCampaigns = (): ThunkAction<
  Promise<AxiosResponse<ILiveCampaignsResponse>>,
  {},
  {},
  AnyAction
> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<ILiveCampaignsResponse>> => {
  try {
    dispatch(liveCampaignsRequest())
    const response = await requestHttp.get<ILiveCampaignsResponse>(urls.getLiveCampaignsUrl())
    const { content } = response.data.data
    dispatch(liveCampaignsSuccess(content.campaigns))
    return response
  } catch (error: any) {
    dispatch(liveCampaignsFailure(getResponseErrorMessage(error)))
    return error
  }
}
