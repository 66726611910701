import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, Button } from 'antd'
import { SearchOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { isMobile } from 'react-device-detect'
import * as actions from '../campaign.actions'
import {
  Dropdown,
  Menu,
  QInput,
  QMenuItem,
  QPagination,
  QTable,
  IconsCommon,
  Col,
  QSelect,
  Row,
  QCampaignsTableCardList
} from 'quantum_components'
import { useSelector } from 'react-redux'
import {
  getPages,
  getCampaignList,
  getCampaignListLoading,
  getCampaignParams,
  getTotalCampaignList,
  getCardList,
} from '../campaign.selectors'
import useDebounce from 'src/hooks/useDebounce'
import { ICampaignTable, ICampaignTableProduct, ICampaignTableService } from '../campaign.types'
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg'
import { DISTRIBUTION_TYPE } from '../../../constants/brief'
import { CAMPAIGN_STATUS, statusFilterOptions } from '../../../constants/campaign'
import { getCurrency, getCurrentUserRole } from '../../core/core.selectors'
import ROLES from '../../../constants/roles'
import { snakeCase } from 'lodash'
import SpinnerCenter from 'src/components/Spinner/spinner'
import i18n from 'src/i18n'
import { LOCALIZATION_LANGUAGES } from 'src/constants'

export default function ClientCampaignsContainer(): ReactElement {
  const { t } = useTranslation(['translationCampaign', 'translation', 'translationCommon'])
  const { Option } = Select

  const campaignStatusesLabel = {
    [CAMPAIGN_STATUS.COMPLETED]: {
      classStyle: 'expired',
      label: t('campaign.campaign-statuses-array.COMPLETED'),
    },
    [CAMPAIGN_STATUS.ONGOING]: {
      classStyle: 'accepted',
      label: t('campaign.campaign-statuses-array.ONGOING'),
    },
    [CAMPAIGN_STATUS.ALL]: {
      classStyle: '',
      label: t('campaign.campaign-statuses-array.ALL'),
    },
  }

  const history = useHistory()
  const dispatch = useReduxDispatch()
  const campaignList = useSelector(getCampaignList)
  const cardList = useSelector(getCardList);
  const campaignListLoading = useSelector(getCampaignListLoading)
  const params = useSelector(getCampaignParams)
  const totalCampaignList = useSelector(getTotalCampaignList)
  const pages = useSelector(getPages)
  const role = useSelector(getCurrentUserRole)
  const currency = useSelector(getCurrency)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const tableLoading = {
    spinning: campaignListLoading,
    indicator: <div className="loader loader-small" />,
  }

  const colKey = {
    status: 'status',
  }

  const menu = (id: number) => (
    <Menu className="user-menu-list">
      <QMenuItem key={0} onClick={() => handleView(id)} name={t('common.View', { ns: 'translationCommon' })} icon={<EyeIcon />} />
    </Menu>
  )

  useEffect(() => {
    cardList.map(card => {
      card.services.map(service => {
        service.name = t("services.media-buy.types." + snakeCase(service.name), service.name, { ns: "translation" });
        service.durationText = getCartLabel(service.duration, service.quantity)
      })
    })
  }, [cardList])

  const getCartLabel = (duration: string, quantity: number) => {
    if (!duration) return t("brief.cart.quantity");
    return `${quantity > 1 ? t(`common.` + duration.split(" ")[1] + `s`, { ns: 'translationCommon' }) : t(`common.` + duration.split(" ")[1], { ns: 'translationCommon' })}`;
  }

  useEffect(() => {
    if (role === ROLES.UNKNOWN) {
      return
    }

    dispatch(actions.setParamsAndFetch(role, { ...params, search: debouncedSearch, offset: 0, limit: 10 }))
    dispatch(actions.setCurrentPage(1))
  }, [debouncedSearch, role])

  const handleChangeSearch = (e: any) => {
    setSearch(e.currentTarget.value)
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setCurrentPage(page))
    await dispatch(actions.setParamsAndFetch(role, { ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleChangeSelect = async (status: string) => {
    await dispatch(actions.setParamsAndFetch(role, { ...params, offset: 0, status: status }))
  }

  const handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      //@ts-ignore
      sort: colKey[sorter.columnKey],
    }
    const newParams = {
      ...params,
      ...sortingParams,
    }
    await dispatch(actions.setParamsAndFetch(role, newParams))
  }

  const handleView = (campaignId: number) => history.push(`/campaigns/v3/${campaignId}`)

  const isDistribution = (service: string) =>
    service === DISTRIBUTION_TYPE.PRODUCT_SAMPLE || service === DISTRIBUTION_TYPE.PRINTED_INSERT

  const isMediaBuy = (service: string) =>
    service !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE && service !== DISTRIBUTION_TYPE.PRINTED_INSERT

  const translateDiscountText = (discountText: any) => {
    let buffer = new Array()
    buffer = discountText.split("%");

    if (buffer[0] == 0) return t("brief.cart." + snakeCase(buffer[1]), { ns: 'translation' });

    return buffer[0] + "% " + t("brief.cart." + snakeCase(buffer[1]), { ns: 'translation' });
  }

  const columns = [
    {
      title: t('brief.table-columns.Product', { ns: 'translationCommon' }),
      dataIndex: 'product',
      key: 'product',
      width: '50%',
      fixed: 'left',
      render: (product: ICampaignTableProduct) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.image && <img src={product.image} alt={product.title} />}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.title}</h3>
              <div className="q-table-product_created">{t('common.Started', { ns: 'translationCommon' })} {product.startDate}</div>
              {product.discountText && (
                <div className="text-green line-height-10 font-size-12">{translateDiscountText(product.discountText)}</div>
              )}
            </div>
          </div>
        )
      },
    },
    {
      title: t('brief.table-columns.Start', { ns: 'translationCommon' }),
      dataIndex: 'service',
      key: 'startData',
      width: '25%',
      render: (service: ICampaignTableService) => {
        const distributions = service.types.filter(isDistribution)
        const mediaBuys = service.types.filter(isMediaBuy)

        return (
          <ul className="q-table-list">
            {distributions.length > 0 && (
              <li key={'productStartDate'} className="q-table-list_item">
                {service.productStartDate}
              </li>
            )}
            {mediaBuys.length > 0 && (
              <li key={'mediaStartDate'} className="q-table-list_item">
                {service.mediaStartDate}
              </li>
            )}
          </ul>
        )
      },
    },
    {
      title: t('brief.table-columns.Status', { ns: 'translationCommon' }),
      dataIndex: 'status',
      key: 'status',
      width: '25%',
      fixed: 'right',
      sorter: true,
      render: (status: CAMPAIGN_STATUS, record: ICampaignTable) => (
        <div>
          <span className={`status-label ${campaignStatusesLabel[status] && campaignStatusesLabel[status].classStyle}`}>
            {campaignStatusesLabel[status] ? t(campaignStatusesLabel[status].label) : ''}
          </span>
          {/*QUA-1449, QP-492 - prevent event bubbling to onRowClick*/}
          {/* <div onClick={e => e.stopPropagation()}>
            <Dropdown overlay={menu(record.key)} trigger={['click']} placement="bottomRight">
              <div className="q-table_menu">
                <IconsCommon.IconEllipsisV />
              </div>
            </Dropdown>
          </div> */}
        </div>
      ),
    },
  ]

  const handleOnRowClick = (campaign: ICampaignTable) => ({
    onClick: (event: any) => {
      if(campaign.version == "2"){
        history.push(`/campaigns/v3/${campaign.key}`)
      }else{
        history.push(`/campaigns/${campaign.key}`)
      }
    },
  })

  return (
    <div className={`qt client-campaigns ${!isMobile && "pb-20"}`}>
      {isMobile && campaignListLoading && <SpinnerCenter />}

      <div className={`qu-statistic-wrapper pt-15 ${isMobile && "pl-15 pr-15"}`}>
        <h3>{t('panel-header.nav.campaigns', { ns: 'translationCommon' })}</h3>
      </div>

      <Row className={`qt-header qt-header-campaigns ${isMobile && "pl-5 pr-5"}`} justify="space-between">
        <Col className={`qt-search ${isMobile && "pb-5"}`}>
          <QInput
            className={`${isMobile && 'full-width'}`}
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('common.Search', { ns: 'translationCommon' })}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col className="qt-filter">
          <QSelect
            onChange={handleChangeSelect}
            size="large"
            placeholder={t('common.Filter_by_status', { ns: 'translationCommon' })}
          >
            {statusFilterOptions.map(
              (
                value: { label: string; value: CAMPAIGN_STATUS },
                index: number,
                array: { label: string; value: CAMPAIGN_STATUS }[]
              ) => (
                <Option key={index} value={`${value.value}`}>
                  {t(value.label)}
                </Option>
              )
            )}
          </QSelect>
        </Col>
      </Row>

      {isMobile ? (
        <div className="p-5 campaigns-table-card">
          <QCampaignsTableCardList
            //@ts-ignore
            cardList={cardList}
            onViewClick={(briefId) => handleView(briefId)}
            onEditClick={(briefId) => handleView(briefId)}
            labelStatus={campaignStatusesLabel}
            isActiveCampaign={true}
            dummyCampaignNameText={t("common.unnamed_brief", { ns: "translationCommon" })}
            dateStartedText={t('common.Started', { ns: 'translationCommon' })}
            dateCreatedText={t('common.created', { ns: 'translationCommon' })}
            startsText={t('common.starts', { ns: 'translationCommon' })}
            promotionText={t("brief.promotions.promotion", { ns: "translation" })}
            statusText={t("brief.table-columns.Status", { ns: "translationCommon" })}
            paymentStatusText={t("brief.table-columns.Payment_Status", { ns: "translationCommon" })}
            viewText={t('common.View', { ns: 'translationCommon' })}
            editText={t('common.edit', { ns: 'translationCommon' })}
          />
          <div className="mobile-pagination pb-20">
            <Button
              disabled={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? pages.currentPage === pages.totalPages : pages.currentPage === 1}
              onClick={() => i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? handleChangePage(pages.currentPage + 1, 10) : handleChangePage(pages.currentPage - 1, 10)}
              className="left-arrow"
              type="text"
              shape="circle"
              icon={<LeftOutlined />}
            />
            <span>{t('common.showing_page', { ns: 'translationCommon' })} {pages.currentPage} {t('common.of', { ns: 'translationCommon' })} {pages.totalPages}</span>
            <Button
              disabled={i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? pages.currentPage === 1 : pages.currentPage === pages.totalPages}
              onClick={() => i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? handleChangePage(pages.currentPage - 1, 10) : handleChangePage(pages.currentPage + 1, 10)}
              className="right-arrow"
              type="text"
              shape="circle"
              icon={<RightOutlined />}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="pt-15 pb-30">
            <QTable
              //@ts-ignore
              onRow={handleOnRowClick}
              //@ts-ignore
              loading={tableLoading}
              columns={columns}
              dataSource={campaignList}
              sortDirections={['ascend', 'descend', 'ascend']}
              scroll={{ x: 990 }}
              rowClassName="q-table cursorPointer"
              onChange={handleTableChange}
              locale={{emptyText:t('active_campaigns_empty_str',{ns:'translation'})}}
            />
          </div>
          <Row
            justify={`${isMobile ? "center" : "end"}`}
            className={`${isMobile && "pt-15 mb-30"}`}
          >
            <QPagination
              size="default"
              defaultCurrent={1}
              current={pages.currentPage}
              total={totalCampaignList}
              onChange={handleChangePage}
            />
          </Row>
        </>
      )}
    </div>
  )
}
