import React from 'react'
import { MediaTypeModalLayout } from 'quantum_components'
import { MEDIA_TYPES } from 'src/constants/brief'
import { useSelector } from 'react-redux'
import { getForm } from '../../profile.selectors'
import SingleForm from './MediaTypesForms/SingleForm'
import MultiForm from './MediaTypesForms/MultiForm'

interface Props {
  nameMediaType: string
  isChecked: boolean
  visible: boolean
  onCancel: () => void
  onFinish: (values: any) => void
}

export default function MediaTypesSwitcher({ nameMediaType, isChecked, visible, onCancel, onFinish }: Props) {
  const { mediaBuyTypes } = useSelector(getForm)

  const formProps = {
    isChecked,
    onFinish,
    formData: isChecked ? mediaBuyTypes.find(i => i.name === nameMediaType) : { name: nameMediaType },
  }

  const getContent = (name: string) => {
    switch (name) {
      case MEDIA_TYPES.HOME_BANNERS:
      case MEDIA_TYPES.SKU:
      case MEDIA_TYPES.SURVEY:
      case MEDIA_TYPES.SOCIAL_MEDIA:
        return <MultiForm {...formProps} />
      case MEDIA_TYPES.BRAND_STORE:
      case MEDIA_TYPES.POPUP_WINDOW:
      case MEDIA_TYPES.PUSH_NOTIFICATIONS:
      case MEDIA_TYPES.FEED:
      case MEDIA_TYPES.PRICE_OFF_PROMO:
      case MEDIA_TYPES.FREE_DELIVERY:
      case MEDIA_TYPES.CASHBACK:
        return <SingleForm {...formProps} />
      default:
        break
    }
  }

  return (
    <MediaTypeModalLayout onCancel={onCancel} visible={visible}>
      <div className="qu-modal-media-types-container">
        <header className="form__head">
          <h3>{nameMediaType}</h3>
        </header>
        {getContent(nameMediaType)}
      </div>
    </MediaTypeModalLayout>
  )
}
