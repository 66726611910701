import React, { useEffect, useState } from 'react'
import { QButton, Form, QInput, Row, Col, Radio, QRadio, IconsCommon, QCheckbox } from 'quantum_components'
import { MEDIA_TYPES_DURATION } from 'src/constants/brief'
import { REGEXES } from 'src/constants'
import { useSelector } from 'react-redux'
import { getForm, getPartnerProfileSettings } from 'src/modules/partner/partnerProfile/profile.selectors'
import { isMobile } from 'react-device-detect'
import { FIELDS_MEDIA_TYPES } from '../../../profile.types'
import PreviewMediaTypes from '../PreviewMediaTypes'
import ImageUploadInput from '../CustomInputs/ImageUploadInput'
import { clone } from 'lodash'
import { renderRoundedNumber } from 'src/helpers/currency'
import { getCurrency } from 'src/modules/core/core.selectors'
import { PRICING_MODEL } from 'src/constants/profile'

interface Props {
  formData: any
  isChecked: boolean
  onFinish: (values: any, shouldDelete?: boolean) => void
}

export default function SingleForm({ formData, onFinish, isChecked }: Props) {
  const [updatedFormData, setUpdatedFormData] = useState<any>()
  const [tableKey, setTableKey] = useState(1)
  const [form] = Form.useForm()

  const { mediaSubtypes } = useSelector(getPartnerProfileSettings)
  const currency = useSelector(getCurrency)
  const { mbPricingModel } = useSelector(getForm)

  const settingsMediaType = mediaSubtypes.find(type => type.name === formData.name)
  const fieldsToCheck = settingsMediaType?.availableFields.map(f => f.name)
  const [isShowPreview, setIsShowPreview] = useState(false)
  const [preview, setPreview] = useState({
    mbPricingModel: mbPricingModel,
    name: formData.name,
    description: formData?.description,
    url: formData?.previewImage,
    avgImpressions: formData?.avgImpressions,
    duration: formData?.duration,
    pricePerActivity: formData?.pricePerActivity,
    priceForDuration: formData?.priceForDuration,
    priceForImpression: formData?.priceForImpression,
    pricePerPush: formData?.pricePerPush,
    minOrderValue: formData?.minOrderValue,
    dailyImpressions: formData?.dailyImpressions,
    CPM: formData?.costPerImpression ? renderRoundedNumber(formData?.costPerImpression, currency) : '',
    dailyClicks: formData?.dailyClicks,
    CPC: formData?.costPerClick ? renderRoundedNumber(formData?.costPerClick, currency) : '',
    dailyReach: formData?.dailyReach,
    CPR: formData?.costPerReach ? renderRoundedNumber(formData?.costPerReach, currency) : '',
  })

  useEffect(() => {
    const localFormData = clone(formData)
    if (localFormData.marketingMetrics === null) {
      localFormData.marketingMetrics = []
    }
    setUpdatedFormData(localFormData)
  }, [formData])

  useEffect(() => {
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }, [updatedFormData])

  const handleOnChange = (values: any, allValues: any) => {
    setPreview({
      ...preview,
      description: allValues.description,
      url: allValues.previewImage,
      avgImpressions: allValues.avgImpressions,
      duration: allValues.duration,
      pricePerActivity: allValues.pricePerActivity,
      priceForDuration: allValues.priceForDuration,
      priceForImpression: allValues.priceForImpression,
      pricePerPush: allValues.pricePerPush,
      minOrderValue: allValues.minOrderValue,
      dailyImpressions: allValues.dailyImpressions,
      CPM: allValues.costPerImpression ? renderRoundedNumber(allValues.costPerImpression, currency) : '',
      dailyClicks: allValues.dailyClicks,
      CPC: allValues.costPerClick ? renderRoundedNumber(allValues.costPerClick, currency) : '',
      dailyReach: allValues.dailyReach,
      CPR: allValues.costPerReach ? renderRoundedNumber(allValues.costPerReach, currency) : '',
    })
  }

  const handleOnFinish = (shouldDelete?: boolean) => async (values: any) => {
    if (mbPricingModel === PRICING_MODEL.METRICS) {
      values.dailyClicks = values.dailyClicks ? values.dailyClicks : null
      values.costPerClick = values.costPerClick ? values.costPerClick : null
      values.dailyImpressions = values.dailyImpressions ? values.dailyImpressions : null
      values.costPerImpression = values.costPerImpression ? values.costPerImpression : null
      values.dailyReach = values.dailyReach ? values.dailyReach : null
      values.costPerReach = values.costPerReach ? values.costPerReach : null
    }

    if (!shouldDelete) await form.validateFields()
    if (onFinish) onFinish({ ...values }, shouldDelete)
  }

  const onChangeCheckboxHandler = (value: string) => (event: any) => {
    if (updatedFormData.marketingMetrics && updatedFormData.marketingMetrics.includes(value)) {
      updatedFormData.marketingMetrics.splice(updatedFormData.marketingMetrics.indexOf(value), 1)
      if (value === 'Impression') {
        updatedFormData[FIELDS_MEDIA_TYPES.DAILY_IMPRESSION] = 0
        updatedFormData[FIELDS_MEDIA_TYPES.CPM] = 0
      } else if (value === 'Click') {
        updatedFormData[FIELDS_MEDIA_TYPES.DAILY_CLICKS] = 0
        updatedFormData[FIELDS_MEDIA_TYPES.CPC] = 0
      } else {
        updatedFormData[FIELDS_MEDIA_TYPES.DAILY_REACH] = 0
        updatedFormData[FIELDS_MEDIA_TYPES.CPR] = 0
      }
    } else {
      if (updatedFormData.marketingMetrics) {
        updatedFormData.marketingMetrics.push(value)
      } else {
        updatedFormData.marketingMetrics = []
        updatedFormData.marketingMetrics.push(value)
      }
    }
    form.setFieldsValue(updatedFormData)
    setUpdatedFormData(updatedFormData)
    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  return (
    <div className="qu-modal-media-types-content">
      {!isShowPreview && (
        <Form
          key={tableKey}
          layout="vertical"
          form={form}
          initialValues={updatedFormData}
          onValuesChange={handleOnChange}
          onFinish={handleOnFinish()}
          className="qu-modal-media-types-form"
        >
          <div className="qu-modal-media-types-block">
            <label htmlFor="description" className="form-label mb-5">
              Description
            </label>
            <Form.Item className="mb-0" name="description" help={settingsMediaType?.description}>
              <QInput placeholder="Enter a description" size="large" />
            </Form.Item>
          </div>

          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.IMAGE) && (
            <div className="qu-modal-media-types-block">
              <h4 className="form-label mb-5">Image</h4>
              <Form.Item className="mb-0" name={FIELDS_MEDIA_TYPES.IMAGE}>
                {/* @ts-ignore */}
                <ImageUploadInput />
              </Form.Item>
            </div>
          )}

          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.VIDEO) && (
            <div className="qu-modal-media-types-block">
              <h4 className="form-label mb-5">Video</h4>
              <Form.Item className="mb-0" name={FIELDS_MEDIA_TYPES.VIDEO}>
                {/* @ts-ignore */}
                <ImageUploadInput caption={['Maz file size 1GB.']} heading={'Upload Video'} accept={'video/*'} />
              </Form.Item>
            </div>
          )}

          <div className="qu-modal-media-types-block">
            {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DURATION) && mbPricingModel === PRICING_MODEL.DURATION && (
              <>
                <h4 className="form-label mb-5">Duration</h4>
                <p className="p--xs tc--light mb-0">For what duration can clients purchase this media?</p>
                <Form.Item className="mb-0" name={FIELDS_MEDIA_TYPES.DURATION}>
                  <Radio.Group className="qu-radio-group">
                    <QRadio value={MEDIA_TYPES_DURATION.PER_DAY}>Per Day</QRadio>
                    <QRadio value={MEDIA_TYPES_DURATION.PER_WEEK}>Per Week</QRadio>
                    <QRadio value={MEDIA_TYPES_DURATION.PER_MONTH}>Per Month</QRadio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            <Row gutter={20} className="mt-15">
              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.AVG_IMPRESSIONS) &&
                mbPricingModel === PRICING_MODEL.DURATION && (
                  <Col span={12}>
                    <Form.Item
                      className="mb-0"
                      rules={[
                        {
                          message: 'Invalid amount!',
                          pattern: REGEXES.AMOUNT_REGEX,
                        },
                      ]}
                      name={FIELDS_MEDIA_TYPES.AVG_IMPRESSIONS}
                    >
                      <QInput type="number" placeholder="Enter Value" size="large" />
                    </Form.Item>
                    <label
                      htmlFor={FIELDS_MEDIA_TYPES.AVG_IMPRESSIONS}
                      className="form-label form-label-secondary mt-5"
                    >
                      Avg Impressions
                    </label>
                  </Col>
                )}
              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_DURATION) && mbPricingModel === PRICING_MODEL.DURATION && (
                <Col span={12}>
                  <Form.Item
                    className="mb-0"
                    name={FIELDS_MEDIA_TYPES.PRICE_DURATION}
                    rules={[
                      {
                        message: 'Invalid amount!',
                        pattern: REGEXES.AMOUNT_REGEX,
                      },
                    ]}
                  >
                    <QInput type="number" placeholder="Enter Value" size="large" />
                  </Form.Item>
                  <label htmlFor={FIELDS_MEDIA_TYPES.PRICE_DURATION} className="form-label form-label-secondary mt-5">
                    Price for Duration
                  </label>
                </Col>
              )}
              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.MIN_ORDER_VALUE) && (
                <Col span={12}>
                  <Form.Item
                    className="mb-0"
                    rules={[
                      {
                        message: 'Invalid amount!',
                        pattern: REGEXES.AMOUNT_REGEX,
                      },
                    ]}
                    name={FIELDS_MEDIA_TYPES.MIN_ORDER_VALUE}
                  >
                    <QInput type="number" placeholder="Enter Value" size="large" />
                  </Form.Item>
                  <label htmlFor={FIELDS_MEDIA_TYPES.MIN_ORDER_VALUE} className="form-label form-label-secondary mt-5">
                    Minimum Order Value
                  </label>
                </Col>
              )}
              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_ACTIVITY) && mbPricingModel === PRICING_MODEL.DURATION && (
                <Col span={12}>
                  <Form.Item
                    className="mb-0"
                    name={FIELDS_MEDIA_TYPES.PRICE_ACTIVITY}
                    rules={[
                      {
                        message: 'Invalid amount!',
                        pattern: REGEXES.AMOUNT_REGEX,
                      },
                    ]}
                  >
                    <QInput type="number" placeholder="Enter Value" size="large" />
                  </Form.Item>
                  <label htmlFor={FIELDS_MEDIA_TYPES.PRICE_ACTIVITY} className="form-label form-label-secondary mt-5">
                    Price per Activity
                  </label>
                </Col>
              )}
              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_IMPRESSION) &&
                mbPricingModel === PRICING_MODEL.DURATION && (
                  <Col span={12}>
                    <Form.Item
                      className="mb-0"
                      name={FIELDS_MEDIA_TYPES.PRICE_IMPRESSION}
                      rules={[
                        {
                          message: 'Invalid amount!',
                          pattern: REGEXES.AMOUNT_REGEX,
                        },
                      ]}
                    >
                      <QInput type="number" placeholder="Enter Value" size="large" />
                    </Form.Item>
                    <label
                      htmlFor={FIELDS_MEDIA_TYPES.PRICE_IMPRESSION}
                      className="form-label form-label-secondary mt-5"
                    >
                      Price for Impression
                    </label>
                  </Col>
                )}
              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_PUSH) && mbPricingModel === PRICING_MODEL.DURATION && (
                <Col span={12}>
                  <Form.Item
                    className="mb-0"
                    name={FIELDS_MEDIA_TYPES.PRICE_PUSH}
                    rules={[
                      {
                        message: 'Invalid amount!',
                        pattern: REGEXES.AMOUNT_REGEX,
                      },
                    ]}
                  >
                    <QInput type="number" placeholder="Enter Value" size="large" />
                  </Form.Item>
                  <label htmlFor={FIELDS_MEDIA_TYPES.PRICE_PUSH} className="form-label form-label-secondary mt-5">
                    Price for Push
                  </label>
                </Col>
              )}
            </Row>
          </div>

          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.AD_TITLE_CHARACTER_LIMIT) && (
            <div className="qu-modal-media-types-block">
              <h4 className="form-label mb-5">Ad Title Character Count</h4>
              <Row gutter={20}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    className="mb-0"
                    name={FIELDS_MEDIA_TYPES.AD_TITLE_CHARACTER_LIMIT}
                    rules={[
                      {
                        message: 'Invalid number!',
                        pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                      },
                    ]}
                  >
                    <QInput placeholder="Enter Value" type="number" size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.AD_DESCRIPTION_CHARACTER_LIMIT) && (
            <div className="qu-modal-media-types-block">
              <h4 className="form-label mb-5">Ad Description Character Count</h4>
              <Row gutter={20}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    className="mb-0"
                    name={FIELDS_MEDIA_TYPES.AD_DESCRIPTION_CHARACTER_LIMIT}
                    rules={[
                      {
                        message: 'Invalid number!',
                        pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                      },
                    ]}
                  >
                    <QInput placeholder="Enter Value" type="number" size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.MARKETING_METRICS) && mbPricingModel === PRICING_MODEL.METRICS && (
            <div className="qu-modal-media-types-block">
              <h4 className="form-label mb-5">Marketing Metrics</h4>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item className="mb-0" name={FIELDS_MEDIA_TYPES.MARKETING_METRICS}>
                    <QCheckbox
                      checked={updatedFormData?.marketingMetrics?.includes('Impression')}
                      className="test-class"
                      onChange={onChangeCheckboxHandler('Impression')}
                    >
                      Impression
                    </QCheckbox>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item className="mb-0" name={FIELDS_MEDIA_TYPES.MARKETING_METRICS}>
                    <QCheckbox
                      checked={updatedFormData?.marketingMetrics?.includes('Click')}
                      className="test-class"
                      onChange={onChangeCheckboxHandler('Click')}
                    >
                      Click
                    </QCheckbox>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item className="mb-0" name={FIELDS_MEDIA_TYPES.MARKETING_METRICS}>
                    <QCheckbox
                      checked={updatedFormData?.marketingMetrics?.includes('Reach')}
                      className="test-class"
                      onChange={onChangeCheckboxHandler('Reach')}
                    >
                      Reach
                    </QCheckbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={6} className="mb-10 mt-10">
                {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DAILY_IMPRESSION) && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      className="mb-0"
                      name={FIELDS_MEDIA_TYPES.DAILY_IMPRESSION}
                      rules={
                        updatedFormData?.marketingMetrics?.includes('Impression')
                          ? [
                              {
                                message: 'Invalid number!',
                                pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                              },
                            ]
                          : []
                      }
                    >
                      <QInput
                        disabled={!updatedFormData?.marketingMetrics?.includes('Impression')}
                        type="number"
                        placeholder="Daily Impressions"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                )}

                {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DAILY_CLICKS) && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      className="mb-0"
                      name={FIELDS_MEDIA_TYPES.DAILY_CLICKS}
                      rules={
                        updatedFormData?.marketingMetrics?.includes('Click')
                          ? [
                              {
                                message: 'Invalid number!',
                                pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                              },
                            ]
                          : []
                      }
                    >
                      <QInput
                        disabled={!updatedFormData?.marketingMetrics?.includes('Click')}
                        type="number"
                        placeholder="Daily Clicks"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                )}

                {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DAILY_REACH) && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      className="mb-0"
                      name={FIELDS_MEDIA_TYPES.DAILY_REACH}
                      rules={
                        updatedFormData?.marketingMetrics?.includes('Reach')
                          ? [
                              {
                                message: 'Invalid number!',
                                pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                              },
                            ]
                          : []
                      }
                    >
                      <QInput
                        disabled={!updatedFormData?.marketingMetrics?.includes('Reach')}
                        type="number"
                        placeholder="Daily Reach"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row gutter={6}>
                {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.CPM) && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      className="mb-0"
                      name={FIELDS_MEDIA_TYPES.CPM}
                      rules={
                        updatedFormData?.marketingMetrics?.includes('Impression')
                          ? [
                              {
                                message: 'Invalid amount!',
                                pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                              },
                            ]
                          : []
                      }
                    >
                      <QInput
                        disabled={!updatedFormData?.marketingMetrics?.includes('Impression')}
                        type="number"
                        placeholder="CPM"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                )}

                {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.CPC) && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      className="mb-0"
                      name={FIELDS_MEDIA_TYPES.CPC}
                      rules={
                        updatedFormData?.marketingMetrics?.includes('Click')
                          ? [
                              {
                                message: 'Invalid amount!',
                                pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                              },
                            ]
                          : []
                      }
                    >
                      <QInput
                        disabled={!updatedFormData?.marketingMetrics?.includes('Click')}
                        type="number"
                        placeholder="CPC"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                )}

                {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.CPR) && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      className="mb-0"
                      name={FIELDS_MEDIA_TYPES.CPR}
                      rules={
                        updatedFormData?.marketingMetrics?.includes('Reach')
                          ? [
                              {
                                message: 'Invalid amount!',
                                pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                              },
                            ]
                          : []
                      }
                    >
                      <QInput
                        disabled={!updatedFormData?.marketingMetrics?.includes('Reach')}
                        type="number"
                        placeholder="CPR"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </div>
          )}

          <div className="qu-modal-media-types-block qu-modal-media-types-footer">
            <QButton onClick={() => setIsShowPreview(true)} className="qu-button-soft mb-10">
              Show Preview
            </QButton>
            <QButton type="primary" htmlType="submit">
              Save & Add Media
            </QButton>
            {isChecked && (
              <QButton
                className="qu-button-outline mt-10"
                type="primary"
                htmlType="button"
                onClick={() => handleOnFinish(true)(updatedFormData)}
              >
                Remove Media
              </QButton>
            )}
          </div>
        </Form>
      )}
      {(isMobile ? isShowPreview : true) && (
        <>
          <PreviewMediaTypes preview={preview} />
          {isMobile && isShowPreview && (
            <QButton onClick={() => setIsShowPreview(false)} className="qu-button-outline" block>
              <IconsCommon.IconArrowLeft className="mr-5" />
              Back
            </QButton>
          )}
        </>
      )}
    </div>
  )
}
