import get from 'lodash/get'

import { IState } from './campaignV3.types'
import * as CONSTANTS from './campaignV3.constants'
import IAction from 'src/interfaces/IAction'
import { CAMPAIGN_STATUS, GALLERY_PER_PAGE } from '../../constants/campaign'
import { cloneDeep } from 'lodash'
import { CLIENT_SURVEY_PER_PAGE } from '../../constants/survey'
import moment from 'moment'
import { getDateFromToday, getIsoDate } from 'src/helpers/datesHelper'
import { DATE_PRESET } from 'src/constants/dashboard'

export const ISurveyAnswerInitState = {
  title: null,
  quantity: null,
}

export const ISurveyQuestionInitState = {
  title: null,
  chartType: null,
  answers: [cloneDeep(ISurveyAnswerInitState)],
}

export const ISurveyInitState = {
  title: null,
  campaignId: null,
  isEqualAnswers: true,
  partnerIds: [],
  questions: [cloneDeep(ISurveyQuestionInitState)],
}

const initialState: IState = {
  fetchingCampaignListError: '',
  campaignInfoError: '',
  campaignDetailsError: '',
  campaignListLoading: true,
  campaignDetailsLoading: true,
  campaignList: {
    stats: {
      lifetime: 0,
      upcoming: 0,
    },
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  params: {
    offset: 0,
    limit: 10,
  },
  campaignDetails: null,
  settings: {
    partnerList: [],
    briefCities: [],
    ageGroups: {},
    genders: { Male: 0, Female: 0 },
    totalQuantity: 0,
    allocatedQuantity: 0,
    campaignStatus: CAMPAIGN_STATUS.ONGOING,
    mediaBuyMetrics: [],
  },
  campaignPartnerDistribution: [],
  campaignSettingsLoading: false,
  campaignSettingsError: '',
  campaignProps: null,
  campaignPropsLoading: false,
  campaignPropsError: '',
  campaignQuantityInfo: {
    assigned: 0,
    distributed: 0,
    remaining: 0,
  },
  campaignQuantityError: '',
  campaignUpdating: false,
  campaignAnalyticsInfo: null,
  campaignAnalyticsInfoLoading: false,
  campaignAnalyticsInfoError: '',
  campaignAnalyticsData: null,
  campaignAnalyticsDataLoading: false,
  campaignAnalyticsDataError: '',
  campaignTimelineError: '',
  campaignDistAnalyticsFilter: {
    partnerIds: [],
    cityIds: [],
    ageGroups: [],
  },
  campaignMediaBuyAnalyticsFilter: {
    partnerId: null,
    mediaType: '',
    beforeDate: getIsoDate(getDateFromToday(DATE_PRESET.WEEK)),
    afterDate: getIsoDate(moment().endOf('day'))
  },
  campaignMediaBuyAnalyticsData: null,
  campaignMediaBuyAnalyticsLoading: false,
  campaignSurveyList: [],
  campaignSurveyLoading: false,
  campaignSurveySaving: false,

  campaignMediaBuyFilter: [],
  campaignMediaBuyFilterLoading: false,
  campaignMediaBuyData: [],
  campaignMediaBuyDataLoading: false,

  campaignTimelineList: {
    results: [],
    total: 0,
  },
  campaignTimelineListLoading: false,
  uploadingTimelineImage: false,
  timelinePages: {
    totalPages: 1,
    currentPage: 1,
  },
  clientSurveys: {
    results: [],
    total: 0,
  },
  clientSurveysLoading: false,
  clientSurveysError: '',
  surveysPages: {
    totalPages: 1,
    currentPage: 1,
  },
  surveyParams: {
    offset: 0,
    limit: CLIENT_SURVEY_PER_PAGE,
    partnerIds: [],
  },
  campaignGalleryList: {
    results: [],
    total: 0,
  },
  campaignGalleryListLoading: false,
  savingGallery: false,
  galleryPages: {
    totalPages: 1,
    currentPage: 1,
  },

  galleryParams: {
    offset: 0,
    limit: GALLERY_PER_PAGE,
  },
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    default:
      return state
  }
}
