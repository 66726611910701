import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import { useReduxDispatch } from "src/helpers";
import BriefTypeForm from "./briefForms/BriefType.form";
import BriefFooter from "./components/Brief.footer";
import {
  ADDITIONAL_STEPS,
  BRIEF_FORM_HEADER_TITLE,
  BRIEF_PAYMENT_UPLOAD_TYPES,
  BRIEF_STATUS,
  BRIEF_STEP_STATUS,
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  BRIEF_TYPES,
  CREATE_BRIEF_STEPS,
  DISTRIBUTION_TYPE,
  MAIN_PRINT_ID,
  PROMOTION_TYPE,
  BRIEF_UPLOAD_TYPES,
  BRIEF_PAYMENT_STATUS,
} from "../../../constants/brief";
import BriefHeader from "./components/Brief.header";
import ProductBriefForm from "./briefForms/ProductBrief.form";
import { actions } from "../brief.module";
import { actions as SpecialClientsActions } from "../../specialClients/specialClients.module";
import { useSelector } from "react-redux";
import {
  getBrief,
  getBriefSettings,
  getCart,
  getCurrentStep,
  getNeedCheckLastStep,
  getPotentialAudienceReachData,
  getUploadSettings,
  getUploadStepLoading,
  getBriefExclusivePartnerId,
  getIsSaving,
  getBriefView,
  getBriefViewPaymentFilesState,
  getUpdatingCart,
  getRecommendedPartnersLoading,
  getPartnerDetails,
} from "../brief.selectors";
import TargetingForm from "./briefForms/Targeting.form";
import ServicesForm from "./briefForms/Services.form";
import {
  Col,
  IconsCommon,
  Modal,
  QBriefStep,
  QButton,
  qnotification,
  Row,
} from "quantum_components";
import {
  IAdEstimation,
  ICartItem,
  ICartList,
  IMediaBuyInitState,
  IMediaBuyStep,
  IProductInitState,
  IProductStep,
  IServiceInitState,
  IServicesStep,
  ITargetingInitState,
  ITargetingStep,
  IUploadPartner,
} from "../brief.types";
import {
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
  PATHS,
  LOCALIZATION_LANGUAGES,
} from "../../../constants";
import BriefSummary from "./components/Brief.summary";
import cloneDeep from "lodash/cloneDeep";
import MediaBuyForm from "./briefForms/MediaBuy.form";
import BriefDialog from "src/components/BriefDialog/Brief.Dialog";
import * as selectors from "../../core/core.selectors";
import SummaryForm from "./briefForms/Summary.form";
import BriefPartnerForm from "./briefForms/BriefPartner.form";
import UploadForm from "./briefForms/Upload.form";
import FinalSummary from "./briefForms/FinalSummary";
import Payment from "./briefForms/Payment";
import SubmittedSuccessfully from "./briefForms/SubmittedSuccessfully";
import {
  canEditBrief,
  canEditBriefWithPaymentStatus,
} from "../../../helpers/rules";
import {
  validateMediaBuyStep,
  validateProductStep,
  validateServicesStep,
  validateTargetingStep,
} from "../../../helpers/validation";
import { isInsert, isSample } from "../../../helpers/brief";
import { getIsoDate } from "../../../helpers/datesHelper";
import SpinnerCenter from "../../../components/Spinner/spinner";
import { ReactComponent as CircleCheckIcon } from "src/assets/icons/circle-check-icon.svg";
import { calculation, hasMediaBuyItems } from "src/helpers/cart";
import { IBillPaymentUploadTypes } from "../../client/bills/bills.types";
import BriefPaymentModal from "./components/Brief.PaymentModal";
import { ReactComponent as ArrowRight } from "src/assets/icons/arrow-right-icon.svg";
import { requestHttp, urls } from "src/api";
import {
  getBriefQuotationPdf,
  togglePrintClass,
} from "../../../utils/getScreenPage";
import UpdatedSuccessfully from "./briefForms/UpdatedSuccessfully";
import { SignInContainer } from "src/modules/registration/signIn";
import { getLoggedInStatus } from "src/modules/registration/signIn/signIn.selectors";
import { SignUpContainer } from "src/modules/registration/signUp";

const closeIcon = <IconsCommon.IconClose />;

const clearLocalStorageKeys = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ROLE);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN);
  sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO);
};

const clearLocalStorageAfterLogin = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN);
  sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO);
};

export default function BriefEditContainer(): ReactElement {
  const { i18n } = useTranslation();
  const { t } = useTranslation([
    "translation",
    "translationCommon",
    "translationInviteClient",
  ]);
  const dispatch = useReduxDispatch();
  const history = useHistory();

  const brief = useSelector(getBrief);
  const briefView = useSelector(getBriefView);

  const currentStep = useSelector(getCurrentStep);
  const needCheckLastStep = useSelector(getNeedCheckLastStep);
  const currentUser = useSelector(selectors.getCurrentUser);
  const settings = useSelector(getBriefSettings);
  const cart = useSelector(getCart);
  const partnerDetails = useSelector(getPartnerDetails);

  const userLoggedInStatus = useSelector(getLoggedInStatus);

  const potentialAudienceReachData = useSelector(getPotentialAudienceReachData);
  const uploadSettings = useSelector(getUploadSettings);
  const imageLoadingList = useSelector(getUploadStepLoading);
  const briefExclusivePartnerId = useSelector(getBriefExclusivePartnerId);
  const isSaving = useSelector(getIsSaving);
  const isUpdatingCart = useSelector(getUpdatingCart);
  const isRecommendedPartnersLoading = useSelector(
    getRecommendedPartnersLoading
  );

  const hasMediaBuy = hasMediaBuyItems(cart.additional.items);

  const productInitState = cloneDeep(IProductInitState);
  const targetInitState = cloneDeep(ITargetingInitState);
  const servicesInitState = cloneDeep(IServiceInitState);
  const mediaBuyInitState = cloneDeep(IMediaBuyInitState);

  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [clientRequiredFieldsOpen, setClientRequiredFieldsOpen] = useState<{ visible: boolean, type: string }>({ visible: false, type: '' });

  const [briefTypes, setBriefTypes] = useState<BRIEF_TYPES[]>([]);
  const [productStepFields, setProductStepFields] =
    useState<IProductStep>(productInitState);
  const [targetingStepFields, setTargetingStepFields] =
    useState<ITargetingStep>(targetInitState);
  const [targetingServices, setTargetingServices] =
    useState<any[]>([]);
  const [servicesStepFields, setServicesStepFields] =
    useState<IServicesStep>(servicesInitState);
  const [mediaBuyStepFields, setMediaBuyStepFields] =
    useState<IMediaBuyStep>(mediaBuyInitState);
  const [addMediaBuyStep, setAddMediaBuy] = useState<boolean>(false);
  const [addProductSample, setProductSample] = useState<boolean>(false);
  const [additionalStep, setAdditionalStep] = useState<ADDITIONAL_STEPS | null>(
    null
  );
  const [skipUploadStep, setSkipUploadStep] = useState<boolean>(false);
  const [formSteps, setFormSteps] = useState<BRIEF_STEPS[]>([]);
  const [modalVisible, setModalVisible] = useState<any>(false);
  const [partnerData, setPartnerData] = useState<any>(false);
  const [partnerLogo, setPartnerLogo] = useState<any>("");
  const [uploadError, setUploadError] = useState(false);

  const [localInviterId, setLocalInviterId] = useState("");
  const [localPartnerToken, setLocalPartnerToken] = useState("");

  const [showLoginContainer, setShowLoginContainer] = useState(false);
  const [showSignupContainer, setShowSignupContainer] = useState(false);

  const [discountCodeErrorModal, setDiscountCodeErrorModal] = useState({
    title: "",
    visible: false,
    description: "",
  });

  const uploadFormRef = useRef();

  const { id: briefId }: any = useParams();

  useEffect(() => {
    if (
      userLoggedInStatus &&
      localPartnerToken &&
      localInviterId &&
      modalVisible
    ) {
      clearLocalStorageAfterLogin();
      setModalVisible(false);
    }
  }, [userLoggedInStatus, localInviterId, localPartnerToken, modalVisible]);

  const filterAndRemoveSteps = (stepName?: string) => {
    if (!stepName) return CREATE_BRIEF_STEPS;
    return CREATE_BRIEF_STEPS.filter((step: string) => step !== stepName);
  };

  let disableEditWithPaymentStatus = false;
  if (briefView.payment) {
    if (
      canEditBriefWithPaymentStatus(briefView.payment.status) &&
      briefView.payment.inReview
    ) {
      //if payment_status is pending && submitted for admin approval
      disableEditWithPaymentStatus = true;
    } else if (!canEditBriefWithPaymentStatus(briefView.payment.status)) {
      //if payment_status is not pending
      disableEditWithPaymentStatus = true;
    }
  }

  useEffect(() => {
    dispatch(actions.resetBriefState())
  }, [briefId])

  useEffect(() => {
    if (!mediaBuyStepFields.budgetType) {
      const fields = { ...mediaBuyStepFields };
      // @ts-ignore
      fields["budgetType"] = "Lifetime";
      setMediaBuyStepFields(fields);
    }
  }, [mediaBuyStepFields]);

  const fetchSettings = async () => {
    dispatch(actions.fetchBriefSettings());
    const inviterID = await localStorage.getItem(
      LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID
    );
    const inviterToken = await localStorage.getItem(
      LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
    );
    if (inviterID && inviterToken) {
      let data = await dispatch(
        SpecialClientsActions.getCoBrandingData(inviterID)
      );
      setPartnerData(data);
    }
  };

  const getTempTokenDetails = () => {
    return {
      t: localPartnerToken,
      p: localInviterId,
      b: brief.id,
    };
  };

  const handleContinue = () => {
    setShowLoginContainer(true);
    setShowSignupContainer(false);

    // setModalVisible(false);
    // const inviteToken = await localStorage.getItem(
    //   LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
    // );
    // const partnerData = await localStorage.getItem(
    //   LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID
    // );
    // clearLocalStorageKeys();
    // history.push(`/p/invite`);
    // window.open(`/login?t=${inviteToken}&p=${partnerData}&b=${brief.id}`, "_blank");
  };

  const handleDiscard = () => {
    setShowLoginContainer(false);
    setShowSignupContainer(true);
    // setModalVisible(false);
    // const inviteToken = await localStorage.getItem(
    //   LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
    // );
    // const partnerData = await localStorage.getItem(
    //   LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID
    // );
    // window.location.href = `/signup/client?t=${inviteToken}&p=${partnerData}&b=${brief.id}`;
  };

  const fetchBrief = async () => {
    await dispatch(actions.fetchEditBrief(briefId));
  };

  const fetchFilterSettings = () => {
    dispatch(actions.fetchPartnerFilterSettings());
  };

  const fetchCart = () => {
    dispatch(actions.fetchCart(briefId));
  };

  useEffect(() => {
    if (brief.paymentEnabled == null) return;
    const steps = brief.paymentEnabled
      ? filterAndRemoveSteps()
      : filterAndRemoveSteps(BRIEF_STEPS.PAYMENT);
    updateStepper(steps);
  }, [briefTypes, briefTypes.length, brief]);

  useEffect(() => {
    fetchSettings();
    fetchBrief();
    fetchCart();
    // setFormSteps(filterAndRemoveSteps(currentUser.paymentEnabled?BRIEF_STEPS.PAYMENT:''))

    return () => {
      dispatch(actions.resetBriefState());
    };
  }, []);

  useEffect(() => {
    async function getExclusivePartnerData() {
      const accessToken = await localStorage.getItem(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN
      );
      if (accessToken && briefExclusivePartnerId) {
        const data = await dispatch(
          actions.fetchExclusivePartnerDetails(briefExclusivePartnerId)
        );
        setPartnerLogo(data);
        return;
      }

      const inviterPartnerLogo = await localStorage.getItem(
        LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO
      );
      if (inviterPartnerLogo && briefExclusivePartnerId) {
        setPartnerLogo(inviterPartnerLogo);
      }
    }
    getExclusivePartnerData();
  }, [briefExclusivePartnerId]);

  useEffect(() => {
    if (currentUser.isApproved) {
      fetchFilterSettings();
    }
  }, [currentUser]);

  useEffect(() => {
    const fields = { ...productStepFields };
    fields.productImages = [...brief[BRIEF_STEPS.PRODUCT].productImages];
    setProductStepFields(fields);
  }, [brief[BRIEF_STEPS.PRODUCT].productImages.length]);

  useEffect(() => {
    const fields = { ...productStepFields };
    fields.quantity = brief[BRIEF_STEPS.PRODUCT].quantity;
    setProductStepFields(fields);
  }, [brief[BRIEF_STEPS.PRODUCT].quantity]);

  useEffect(() => {
    const fields = { ...servicesStepFields };
    fields.serviceImages = [...brief[BRIEF_STEPS.SERVICES].serviceImages];
    setServicesStepFields(fields);
  }, [brief[BRIEF_STEPS.SERVICES].serviceImages.length]);

  useEffect(() => {
    const fields = { ...servicesStepFields };
    fields.servicePackagingQuantity =
      brief[BRIEF_STEPS.SERVICES].servicePackagingQuantity;
    setServicesStepFields(fields);
  }, [brief[BRIEF_STEPS.SERVICES].servicePackagingQuantity]);

  useEffect(() => {
    setBriefTypes(brief[BRIEF_STEPS.TYPE].briefTypes);
  }, [brief[BRIEF_STEPS.TYPE]]);

  useEffect(() => {
    const fields = { ...mediaBuyStepFields };
    fields.budgetValue = brief[BRIEF_STEPS.MEDIA_BUY].budgetValue;
    setMediaBuyStepFields(fields);
  }, [brief[BRIEF_STEPS.MEDIA_BUY].budgetValue]);

  useEffect(() => {
    if (cart.additional.items.length > 0) {
      const skipUploadStep = !hasMediaBuy;
      setSkipUploadStep(skipUploadStep);

      if (skipUploadStep && currentStep === BRIEF_STEPS.UPLOAD) {
        showFinalSummary();
      }
    }
  }, [cart.additional.items]);

  useEffect(() => {
    setBriefTypes(brief[BRIEF_STEPS.TYPE].briefTypes);
    setCurrentStep(BRIEF_STEPS.PRODUCT);

    if (brief[BRIEF_STEPS.PRODUCT]) {
      setProductStepFields(cloneDeep(brief[BRIEF_STEPS.PRODUCT]));
    }

    if (brief[BRIEF_STEPS.TARGETING]) {
      setTargetingStepFields(cloneDeep(brief[BRIEF_STEPS.TARGETING]));
    }

    if (brief[BRIEF_STEPS.SERVICES]) {
      setServicesStepFields(cloneDeep(brief[BRIEF_STEPS.SERVICES]));
    }

    if (brief[BRIEF_STEPS.MEDIA_BUY]) {
      setMediaBuyStepFields(cloneDeep(brief[BRIEF_STEPS.MEDIA_BUY]));
    }

    if (
      brief.lastFilledStep &&
      brief.lastFilledStep !== BRIEF_STEPS.TYPE &&
      needCheckLastStep
    ) {
      setCurrentStep(brief.lastFilledStep);
    }

    if (forceShowFinalStep) {
      showFinalSummary();
    }
  }, [brief.id]);

  useEffect(() => {
    const containers = document.getElementsByClassName("qu-brief-main");
    if (containers) {
      containers[0].scrollTo({ top: 0, behavior: "smooth" });
    }
    if (currentStep === BRIEF_STEPS.TARGETING)
      dispatch(
        actions.getPotentialAudienceReach(briefId, brief[BRIEF_STEPS.TARGETING])
      );

    if (
      disableEdit &&
      currentStep !== BRIEF_STEPS.UPLOAD &&
      currentStep !== BRIEF_STEPS.SUMMARY &&
      currentStep !== BRIEF_STEPS.PAYMENT
    ) {
      dispatch(actions.setCurrentStep(BRIEF_STEPS.UPLOAD));
    }

    if (
      disableEditWithPaymentStatus &&
      currentStep !== BRIEF_STEPS.UPLOAD &&
      currentStep !== BRIEF_STEPS.SUMMARY &&
      currentStep !== BRIEF_STEPS.PAYMENT
    ) {
      dispatch(actions.setCurrentStep(BRIEF_STEPS.UPLOAD));
    }
  }, [currentStep]);

  const updateStepper = (stepsData?: any[]) => {
    const steps = stepsData ? stepsData : [...formSteps];

    if (hasMediaBuyStep && hasSampleStep) {
      setFormSteps(
        filterAndRemoveSteps(!brief.paymentEnabled ? BRIEF_STEPS.PAYMENT : "")
      );
      return;
    }

    if (hasMediaBuyStep) {
      const servicesIndex = steps.indexOf(BRIEF_STEPS.SERVICES);
      if (servicesIndex > 0) {
        steps.splice(servicesIndex, 1);
      }

      // if (!steps.includes(BRIEF_STEPS.MEDIA_BUY)) {
      //   const partnerIndex = steps.indexOf(BRIEF_STEPS.PARTNER)
      //   steps.splice(partnerIndex, 0, BRIEF_STEPS.MEDIA_BUY)
      // }
    }

    if (hasSampleStep) {
      const mediaBuyIndex = steps.indexOf(BRIEF_STEPS.MEDIA_BUY);
      if (mediaBuyIndex > 0) {
        steps.splice(mediaBuyIndex, 1);
      }

      if (!steps.includes(BRIEF_STEPS.SERVICES)) {
        const targetingIndex = steps.indexOf(BRIEF_STEPS.TARGETING);
        steps.splice(targetingIndex + 1, 0, BRIEF_STEPS.SERVICES);
      }
    }

    setFormSteps(steps);
  };

  const handleFinishLater = async () => {
    if (uploadError) return;
    if (imageLoadingList.length > 0) return;

    if (!isPaymentStep) {
      await saveBrief();
    }

    const inviterID = await localStorage.getItem(
      LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID
    );
    const inviterToken = await localStorage.getItem(
      LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
    );
    if (inviterID && inviterToken) {
      setModalVisible(true);
    } else {
      history.push(PATHS.BRIEF);
    }
  };

  const isZeroStep = currentStep === BRIEF_STEPS.TYPE;
  const isProductStep = currentStep === BRIEF_STEPS.PRODUCT;
  const isServiceStep = currentStep === BRIEF_STEPS.SERVICES;
  const isTargetingStep = currentStep === BRIEF_STEPS.TARGETING;
  const isMediaBuyStep = currentStep === BRIEF_STEPS.MEDIA_BUY;
  const isPartnerStep = currentStep === BRIEF_STEPS.PARTNER;
  const isUploadStep = currentStep === BRIEF_STEPS.UPLOAD;
  const isPaymentStep = currentStep === BRIEF_STEPS.PAYMENT;
  // const isFinalSummary = additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY
  // const isSummary = currentStep === BRIEF_STEPS.SUMMARY
  const isFinalSummary = currentStep === BRIEF_STEPS.SUMMARY;
  const showFooter = !isPartnerStep || isFinalSummary;

  const forceShowFinalStep =
    !canEditBrief(brief.status) && brief.lastFilledStep !== BRIEF_STEPS.UPLOAD;

  const hasMediaBuyStep = briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING);
  const hasSampleStep = briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE);
  const needShowDialogToAddMediaBuy = isServiceStep && !hasMediaBuyStep;
  const needShowDialogToAddProductSample = isMediaBuyStep && !hasSampleStep;

  const summaryPreviousStep = BRIEF_STEPS.TARGETING;
  const finalSummaryPreviousStep = skipUploadStep
    ? BRIEF_STEPS.PARTNER
    : BRIEF_STEPS.UPLOAD;
  const needShowSummary =
    !currentUser.isApproved && currentStep === summaryPreviousStep;
  const paymentStepEnabled = brief.paymentEnabled;
  const disableEdit = !canEditBrief(brief.status);
  const isDraft = brief.status === BRIEF_STATUS.DRAFT;
  const isSelectPartners = brief.status === BRIEF_STATUS.SELECT_PARTNERS;
  const blockEditBrief =
    (disableEdit && isProductStep) ||
    (disableEdit && !isFinalSummary) ||
    (disableEdit && skipUploadStep && isFinalSummary);

  const onlyInsert = isInsert(productStepFields.distributionItem);
  const onlySample = isSample(productStepFields.distributionItem);

  const lastFilledStepIndex = brief.lastFilledStep
    ? formSteps.indexOf(brief.lastFilledStep)
    : 0;

  const [activeUploadType, setActiveUploadType] =
    useState<BRIEF_PAYMENT_UPLOAD_TYPES>(BRIEF_PAYMENT_UPLOAD_TYPES.PO);

  const [bankTransferFiles, setBankTransferFiles] = useState<File[]>([]);
  const [purchaseOrderFiles, setPurchaseOrderFiles] = useState<File[]>([]);

  const hasLocalBankTransferFiles = Boolean(bankTransferFiles.length);
  const hasLocalPurchaseOrderFiles = Boolean(purchaseOrderFiles.length);

  const paymentFilesState = useSelector(getBriefViewPaymentFilesState);
  const purchaseOrderProcessing =
    paymentFilesState.bankTransfer.isUploading ||
    paymentFilesState.bankTransfer.isRemoving;
  const bankTransferProcessing =
    paymentFilesState.po.isUploading || paymentFilesState.po.isRemoving;
  const paymentInReview = Boolean(briefView.payment?.inReview);
  const paymentStatus = briefView.payment?.status === BRIEF_PAYMENT_STATUS.PAID;
  const submitPaymentButtonDisabled =
    purchaseOrderProcessing ||
    bankTransferProcessing ||
    paymentInReview ||
    paymentStatus ||
    (activeUploadType !== BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE &&
      !hasLocalPurchaseOrderFiles &&
      !hasLocalBankTransferFiles);

  const onPaymentFilesChange = (
    files: { files: File[]; filesType: IBillPaymentUploadTypes }[]
  ) => {
    const bankTransfer = files.filter(
      ({ filesType }) => filesType === "bankTransfer"
    );
    const purchaseOrder = files.filter(({ filesType }) => filesType === "po");

    setBankTransferFiles(bankTransfer[0].files);
    setPurchaseOrderFiles(purchaseOrder[0].files);
  };

  const onPaymentTypeChange = (uploadType: BRIEF_PAYMENT_UPLOAD_TYPES) => {
    setActiveUploadType(uploadType);
  };

  const showSignupLoginModal = async () => {
    const inviterID = await localStorage.getItem(
      LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID
    );
    const inviterToken = await localStorage.getItem(
      LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
    );

    setLocalInviterId(inviterID);
    setLocalPartnerToken(inviterToken);

    if (inviterID && inviterToken) {
      setModalVisible(true);
      return;
    }
  };

  const handleSubmitBrief = async () => {
    if (isDraft || isSelectPartners) {
      const inviterID = await localStorage.getItem(
        LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID
      );
      const inviterToken = await localStorage.getItem(
        LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
      );

      if (inviterID && inviterToken) {
        setModalVisible(true);
        return;
      }

      const markup = document.getElementById("brief_quotation_print_area");
      if (!markup) return;

      const pdf = await getBriefQuotationPdf(markup, "en");
      // pdf.save('Brief Quotation.pdf')

      let blob = pdf.output("blob");
      await dispatch(
        actions.uploadGeneratedQuotation(
          [blob],
          BRIEF_UPLOAD_TYPES.BRIEFS_QUOTATION_FILES,
          briefId
        )
      );

      const response: any = await dispatch(actions.approveBrief(brief.id!));
      if (
        response?.data?.status === 409 &&
        response?.data?.error?.payload?.invalid
      ) {
        await dispatch(actions.createBriefFailure(response.data.error.message));
        setDiscountCodeErrorModal({
          title: `${cart.couponCode}: Discount code invalid`,
          visible: true,
          description: response.data.error.message,
        });
        return;
      }

      if (currentUser?.isApproved && paymentStepEnabled) {
        setCurrentStep(BRIEF_STEPS.PAYMENT);
        return;
      }

      setAdditionalStep(ADDITIONAL_STEPS.SAVED);
      // history.push(PATHS.BRIEF)
      return;
    }

    if (currentUser?.isApproved && paymentStepEnabled) {
      setCurrentStep(BRIEF_STEPS.PAYMENT);
      return;
    }

    setAdditionalStep(ADDITIONAL_STEPS.SAVED);
    // history.push(PATHS.BRIEF)
  };

  const onProceedDiscountCodeErrorModal = async () => {
    const cartData = { ...cart };
    const cartItems = cartData.additional.items;

    const result = calculation(cartItems, settings.managementFee, settings.partnerVat);

    cartData.additional.managementFee = result.managementFee;
    cartData.additional.taxes = result.taxes;
    cartData.additional.cartTotal = result.total;
    cartData.couponCode = "";

    delete cartData.sampleAllocated;

    await dispatch(actions.addToCart(cartData));
    await dispatch(actions.fetchCart(briefId));

    setDiscountCodeErrorModal({
      title: "",
      visible: false,
      description: "",
    });
  };

  const submitButtonText = () => {
    if (isPaymentStep) {
      return (briefView.status === BRIEF_STATUS.PENDING || briefView.status === BRIEF_STATUS.REJECTED)
        ? t("brief.payment.Done")
        : activeUploadType === BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE
          ? t("brief.payment.Pay_online")
          : t("brief.payment.Make_payment");
    }

    if (isTargetingStep && (!currentUser.id || !currentUser.isApproved)) {
      return t("brief.edit.common.save");
    }

    if (
      additionalStep === ADDITIONAL_STEPS.SUMMARY &&
      (!currentUser.id || !currentUser.isApproved)
    ) {
      return t("brief.edit.common.save");
    }

    // if (additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY &&  !paymentStepEnabled) {
    if (isFinalSummary && !paymentStepEnabled) {
      return t("brief.edit.common.submit_brief");
    }

    if (!isPaymentStep) {
      return t("common.Next", { ns: "translationCommon" });
    }

    return isDraft
      ? t("brief.edit.common.submit_and_view_quotation")
      : t("common.save_brief", { ns: "translationCommon" });
  };

  const handleMediaBuyDialog = (e: MouseEvent, value: boolean) => {
    setAddMediaBuy(value);
  };

  const handleProductSampleDialog = (e: MouseEvent, value: boolean) => {
    setProductSample(value);
  };

  const showFinalSummary = () => {
    // setAdditionalStep(ADDITIONAL_STEPS.FINAL_SUMMARY)
    setCurrentStep(BRIEF_STEPS.SUMMARY);
    setAdditionalStep(ADDITIONAL_STEPS.FINAL_SUMMARY);
  };

  const saveMediaBuy = async () => {
    setAdditionalStep(null);
    setAddMediaBuy(false);
    briefTypes.push(BRIEF_TYPES.MEDIA_BUYING);
    await updateBriefTypes(BRIEF_TYPES.MEDIA_BUYING);
  };

  const saveProductSample = async () => {
    setAdditionalStep(null);
    setProductSample(false);
    briefTypes.push(BRIEF_TYPES.PRODUCT_SAMPLE);
    await updateBriefTypes(BRIEF_TYPES.PRODUCT_SAMPLE);
  };

  const handleNext = async () => {
    const index = formSteps.indexOf(currentStep);
    const lastIndex = index === formSteps.length - 1;

    const bannersWithNoMedia = brief.BRIEF_UPLOAD.uploadMaterial.filter((upload:any)=>{
      if(upload.mediaType === 'Banner' && (!upload.imageTargetUrl.english && !upload.imageTargetUrl.arabic)){
        return upload
      }
    })
    if(bannersWithNoMedia.length > 0){
      qnotification({type:'error',message:t('Atleast one of the desktop/mobile media is required for Banner Ads')})
      return;
    }

    // if the user is on targeting step and is coming from invite client show the modal without showing the summary
    // console.log(currentStep);
    if (isTargetingStep && !currentUser?.isApproved) {
      await saveBrief();
      !!targetingServices.length && await handleAddToCard();
      showSignupLoginModal();
      // console.log("user is on targeting and coming from invite client");
      return;
    }

    if (isFinalSummary && paymentStepEnabled === true) {
      await saveBrief();
      await handleSubmitBrief();
      briefView.status === BRIEF_STATUS.MISSING_INFO && setClientRequiredFieldsOpen({ visible: true, type: 'edit' });
      return;
    }

    if (
      additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY &&
      paymentStepEnabled === false
    ) {
      await saveBrief();
      // console.log("payment enabled false")
      return;
    }

    if (brief.promotionId && isTargetingStep) {
      await saveBrief();
      setCurrentStep(BRIEF_STEPS.UPLOAD);
      return;
    }

    if (!currentUser.isApproved && isTargetingStep && !needShowSummary) {
      await saveBrief();
      return setAdditionalStep(ADDITIONAL_STEPS.CONGRATS);
    }

    if (!additionalStep && isPaymentStep) {
      if (briefView.status === BRIEF_STATUS.PENDING) return setAdditionalStep(ADDITIONAL_STEPS.CONGRATS);
      if (briefView.status === BRIEF_STATUS.REJECTED) return history.push(PATHS.BRIEF);

      if (activeUploadType === BRIEF_PAYMENT_UPLOAD_TYPES.ONLINE) {
        setPaymentModalOpen(true);
        return;
      }

      if (hasLocalBankTransferFiles) {
        await dispatch(
          actions.uploadViewBriefPaymentFile({
            files: bankTransferFiles,
            filesType: "bankTransfer",
          })
        );
      }

      if (hasLocalPurchaseOrderFiles) {
        await dispatch(
          actions.uploadViewBriefPaymentFile({
            files: purchaseOrderFiles,
            filesType: "po",
          })
        );
      }

      return setAdditionalStep(ADDITIONAL_STEPS.CONGRATS);
    }

    // show dialog Media Buy
    if (!additionalStep && needShowDialogToAddMediaBuy && !addMediaBuyStep) {
      setAdditionalStep(ADDITIONAL_STEPS.MEDIA_BUY_DIALOG);
      await saveBrief();

      return;
    }

    // show dialog Product
    if (
      !additionalStep &&
      needShowDialogToAddProductSample &&
      !addProductSample
    ) {
      setAdditionalStep(ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG);
      await saveBrief();

      return;
    }

    // set&show media buy step
    if (
      additionalStep === ADDITIONAL_STEPS.MEDIA_BUY_DIALOG &&
      addMediaBuyStep
    ) {
      await saveMediaBuy();
      setCurrentStep(BRIEF_STEPS.MEDIA_BUY);

      return;
    }

    // set Product Sample
    if (
      additionalStep === ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG &&
      addProductSample
    ) {
      await saveProductSample();
      setCurrentStep(BRIEF_STEPS.PRODUCT);

      return;
    }

    // show summary
    if (
      additionalStep !== ADDITIONAL_STEPS.SUMMARY &&
      !addMediaBuyStep &&
      needShowSummary
    ) {
      await saveBrief();
      setAdditionalStep(ADDITIONAL_STEPS.SUMMARY);

      return;
    }

    if (
      !currentUser.isApproved &&
      additionalStep === ADDITIONAL_STEPS.SUMMARY
    ) {
      await handleSubmitBrief();
      return;
    }

    // show final summary
    if (isUploadStep && !needShowDialogToAddMediaBuy) {
      // @ts-ignore
      const hasErrors = uploadFormRef.current.validateFields();

      if (hasErrors) return;
      else if (uploadError) return;

      if (imageLoadingList.length > 0) return;

      await saveBrief();
      showFinalSummary();

      return;
    }

    await saveBrief();
    setAdditionalStep(null);
    if (!lastIndex) {
      setCurrentStep(formSteps[index + 1]);
    }
  };

  const handleBack = () => {
    const index = formSteps.indexOf(currentStep);

    if (brief.promotionId && isUploadStep) {
      setCurrentStep(BRIEF_STEPS.TARGETING);
      return;
    }

    if (additionalStep === ADDITIONAL_STEPS.MEDIA_BUY_DIALOG) {
      setAdditionalStep(null);
      setCurrentStep(BRIEF_STEPS.SERVICES);
      return;
    }

    if (additionalStep === ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG) {
      setAdditionalStep(null);
      setCurrentStep(BRIEF_STEPS.MEDIA_BUY);
      return;
    }

    if (additionalStep === ADDITIONAL_STEPS.SUMMARY) {
      setAdditionalStep(null);
      setCurrentStep(summaryPreviousStep);
      return;
    }

    if (additionalStep === ADDITIONAL_STEPS.FINAL_SUMMARY) {
      setAdditionalStep(null);
      setCurrentStep(finalSummaryPreviousStep);
      return;
    }

    if (index === 0 || index === 1) {
      return;
    }

    setCurrentStep(formSteps[index - 1]);
  };

  const setCurrentStep = (step: BRIEF_STEPS) => {
    setAdditionalStep(null);
    dispatch(actions.setCurrentStep(step));
  };

  const forceUpdateProductStep = async () => {
    const updatedBrief = { ...brief };
    const productFields = { ...productStepFields };

    productFields.briefStartDate = null;
    productFields.distributionItem = null;
    productFields.quantity = null;
    productFields.duration = null;

    setProductStepFields(productFields);
    updatedBrief[BRIEF_STEPS.PRODUCT] = productFields;

    await dispatch(actions.updateBrief(BRIEF_STEPS.PRODUCT, updatedBrief));
  };

  const forceUpdateServicesStep = async () => {
    if (!brief.id) {
      return;
    }

    const updatedBrief = { ...brief };

    if (onlyInsert) {
      servicesInitState.servicePrinting = servicesStepFields.servicePrinting;
      servicesInitState.printingPriceId = servicesStepFields.printingPriceId;
      servicesInitState.serviceIncludingCoupon =
        servicesStepFields.serviceIncludingCoupon;
      servicesInitState.serviceDesign = servicesStepFields.serviceDesign;
      servicesInitState.serviceImages = servicesStepFields.serviceImages;
      servicesInitState.copackingPriceId = null;
    }

    if (onlySample) {
      servicesInitState.servicePackageType =
        servicesStepFields.servicePackageType;
      servicesInitState.servicePrinting = servicesStepFields.servicePrinting;
      servicesInitState.printingPriceId = servicesStepFields.printingPriceId;
      servicesInitState.servicePackaging = servicesStepFields.servicePackaging;
      servicesInitState.copackingPriceId = servicesStepFields.copackingPriceId;
      servicesInitState.servicePackagingQuantity =
        servicesStepFields.servicePackagingQuantity;
      servicesInitState.serviceIsLiquid = servicesStepFields.serviceIsLiquid;
      servicesInitState.hasFreeSample = servicesStepFields.hasFreeSample;
      servicesInitState.serviceImages = servicesStepFields.serviceImages;
    }

    setServicesStepFields(servicesInitState);
    updatedBrief[BRIEF_STEPS.SERVICES] = servicesInitState;

    await dispatch(actions.updateBrief(BRIEF_STEPS.SERVICES, updatedBrief));
  };

  const forceUpdateMediaBuyStep = () => {
    if (!brief.id) {
      return;
    }

    const updatedBrief = { ...brief };
    setMediaBuyStepFields(mediaBuyInitState);
    updatedBrief[BRIEF_STEPS.MEDIA_BUY] = mediaBuyInitState;

    dispatch(actions.updateBrief(BRIEF_STEPS.MEDIA_BUY, updatedBrief));
  };

  const updateBriefBeforeSave = () => {
    const updatedBrief = { ...brief };
    const currentStepIndex = formSteps.indexOf(currentStep);

    if (currentStepIndex > lastFilledStepIndex) {
      updatedBrief.lastFilledStep = currentStep;
    }

    if (isZeroStep) {
      updatedBrief[BRIEF_STEPS.TYPE].briefTypes = briefTypes;
      if (!hasSampleStep && lastFilledStepIndex > 0) {
        forceUpdateProductStep();
        forceUpdateServicesStep();
      }

      if (!hasMediaBuyStep && lastFilledStepIndex > 0) {
        forceUpdateMediaBuyStep();
      }
    }

    if (isProductStep) {
      const productFields = { ...productStepFields };
      productFields.mediaStartDate = productFields.mediaStartDate
        ? getIsoDate(productFields.mediaStartDate)
        : null;
      productFields.instructions =
        productStepFields.instructions === ""
          ? null
          : productStepFields.instructions;

      updatedBrief[BRIEF_STEPS.PRODUCT] = productFields;

      if ((onlyInsert || onlySample) && lastFilledStepIndex > 1) {
        forceUpdateServicesStep();
      }
    }

    if (isTargetingStep) {
      updatedBrief[BRIEF_STEPS.TARGETING] = targetingStepFields;
    }

    if (isServiceStep) {
      updatedBrief[BRIEF_STEPS.SERVICES] = servicesStepFields;
    }

    if (isMediaBuyStep) {
      const mediaBuyFields = { ...mediaBuyStepFields };
      mediaBuyFields.mediaStartDate = mediaBuyFields.mediaStartDate
        ? getIsoDate(mediaBuyFields.mediaStartDate)
        : null;
      updatedBrief[BRIEF_STEPS.MEDIA_BUY] = mediaBuyFields;
    }

    return updatedBrief;
  };

  const updateBriefTypes = async (type: BRIEF_TYPES) => {
    const updatedBrief = { ...brief };
    updatedBrief[BRIEF_STEPS.TYPE].briefTypes = briefTypes;

    if (type === BRIEF_TYPES.PRODUCT_SAMPLE) {
      updatedBrief.lastFilledStep = BRIEF_STEPS.PRODUCT;
    }
    if (type === BRIEF_TYPES.MEDIA_BUYING) {
      updatedBrief.lastFilledStep = BRIEF_STEPS.MEDIA_BUY;
    }

    await dispatch(actions.updateBrief(BRIEF_STEPS.TYPE, updatedBrief));
  };

  const saveBrief = async () => {
    const briefForSave = updateBriefBeforeSave();
    await dispatch(actions.updateBrief(currentStep, briefForSave));
  };

  const checkStatus = (step: BRIEF_STEPS) => {
    const stepIndex = formSteps.indexOf(step);
    const lockedSteps = [
      BRIEF_STEPS.PARTNER,
      BRIEF_STEPS.UPLOAD,
      BRIEF_STEPS.SUMMARY,
      BRIEF_STEPS.PAYMENT,
    ];

    if (
      disableEdit &&
      step !== BRIEF_STEPS.UPLOAD &&
      step !== BRIEF_STEPS.SUMMARY &&
      step !== BRIEF_STEPS.PAYMENT
    ) {
      return BRIEF_STEP_STATUS.DISABLED;
    }

    if (
      disableEditWithPaymentStatus &&
      step !== BRIEF_STEPS.UPLOAD &&
      step !== BRIEF_STEPS.SUMMARY &&
      step !== BRIEF_STEPS.PAYMENT
    ) {
      return BRIEF_STEP_STATUS.DISABLED;
    }

    if (brief.promotionId && step === BRIEF_STEPS.PARTNER) {
      return BRIEF_STEP_STATUS.DISABLED;
    }

    if (!currentUser.isApproved && lockedSteps.includes(step)) {
      return BRIEF_STEP_STATUS.DISABLED;
    }

    if (currentStep === step) {
      return BRIEF_STEP_STATUS.CURRENT;
    }

    if (lastFilledStepIndex >= stepIndex) {
      return BRIEF_STEP_STATUS.PASSED;
    }

    return BRIEF_STEP_STATUS.DEFAULT;
  };

  const handleStepperClick = (step: BRIEF_STEPS) => {
    if (!canProceed()) return;
    setCurrentStep(step);
  };

  const steps = !isZeroStep && (
    <div className="qu-stepper">
      <div className="steps">
        {formSteps.map(
          (step: BRIEF_STEPS, index: number) =>
            step !== BRIEF_STEPS.TYPE && (
              <QBriefStep
                key={step}
                title={`${index}. ${t(BRIEF_STEPS_NAMES[step])}`}
                onClickPath={() => handleStepperClick(step)}
                status={checkStatus(step)}
              />
            )
        )}
      </div>
    </div>
  );

  const handleBriefTypeStep = (briefTypes: BRIEF_TYPES[]) => {
    setBriefTypes(briefTypes);
  };

  const handleProductStep = (fields: IProductStep) => {
    if (
      fields.distributionItem === DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
      lastFilledStepIndex > 1
    ) {
      const newBrief = { ...brief };
      newBrief.lastFilledStep = BRIEF_STEPS.TARGETING;
      dispatch(actions.createBriefSuccess(newBrief));
    }

    setProductStepFields(fields);
  };

  const handleTargetingStep = (fields: ITargetingStep) => {
    setTargetingStepFields(fields);
    dispatch(actions.getPotentialAudienceReach(briefId, fields));
  };

  const handleServicesStep = (fields: IServicesStep) => {
    setServicesStepFields(fields);
  };

  const handleTargetingServices = (services: any) => {
    setTargetingServices(services);
  };

  const handleMediaBuyStep = (fields: IMediaBuyStep) => {
    setMediaBuyStepFields(fields);
  };

  const newCartItem = () => {
    if (!targetingServices.length) {
      return null
    }

    return {
      partnerId: partnerDetails!.id,
      companyName: partnerDetails!.companyName,
      companyLogoUrl: partnerDetails!.companyLogoUrl,
      companyWebsite: partnerDetails!.companyWebsite,
      services: targetingServices,
      subTotal: 0,
      logisticSampleRate: 0,
      logisticInsertRate: 0,
      logisticSamplePrice: 0,
      logisticInsertPrice: 0,
      stickerCost: 0,
      stickerPrice: 0,
      isApprovedByUser: true,
      priceModel: partnerDetails?.mbPricingModel,
    } as ICartItem
  }

  const handleAddToCard = async () => {
    const cartItem = newCartItem()
    const cartItems = [...cart.additional.items]
    const targetItem = cartItems.find((item: ICartItem) => item.partnerId === partnerDetails?.id)

    if (targetItem) {
      cartItems.splice(cartItems.indexOf(targetItem), 1)
    }

    if (cartItem) {
      cartItems.push(cartItem)
    }

    const managementFeeRate = settings.managementFee
    const result = calculation(cartItems, managementFeeRate, settings.partnerVat)

    const estimationsData: IAdEstimation[] = cart.mediaBuyEstimates || []

    const cartData = {
      briefId,
      additional: {
        managementFee: result.managementFee,
        managementFeeRate,
        taxes: result.taxes,
        cartTotal: result.total,
        items: cartItems,
      },
      mediaBuyEstimates: estimationsData,
    } as ICartList

    await dispatch(actions.addToCart(cartData))
    await dispatch(actions.fetchCart(briefId))
  }

  const handleUploadStep = (fields: IUploadPartner[]) => {
    setUploadError(false);
    if (fields.length > 0) {
      fields.map((ele: any) => {
        const uploadSetting: any = uploadSettings.find(
          (elem) => elem.partnerId === ele.partnerId
        );
        const validationData = uploadSetting.mediaTypes.find(
          (elem: any) =>
            (elem.subName ? elem.subName : elem.name) ===
            (ele.mediaSubType ? ele.mediaSubType : ele.mediaType)
        );
        if (ele?.adTitle?.english && validationData?.adTitleCharacterLimit) {
          if (
            ele?.adTitle?.english.trim().length >
            validationData?.adTitleCharacterLimit
          ) {
            setUploadError(true);
            return;
          }
        }
        if (ele?.adTitle?.arabic && validationData?.adTitleCharacterLimit) {
          if (
            ele?.adTitle?.arabic.trim().length >
            validationData?.adTitleCharacterLimit
          ) {
            setUploadError(true);
            return;
          }
        }
        if (
          ele?.adDescription?.english &&
          validationData?.adDescriptionCharacterLimit
        ) {
          if (
            ele?.adDescription?.english.trim().length >
            validationData?.adDescriptionCharacterLimit
          ) {
            setUploadError(true);
            return;
          }
        }
        if (
          ele?.adDescription?.arabic &&
          validationData?.adDescriptionCharacterLimit
        ) {
          if (
            ele?.adDescription?.arabic.trim().length >
            validationData?.adDescriptionCharacterLimit
          ) {
            setUploadError(true);
            return;
          }
        }
      });
    }
    brief[BRIEF_STEPS.UPLOAD].uploadMaterial = fields;
  };

  const canProceed = () => {
    if (isZeroStep && briefTypes.length === 0) return false;
    if (isProductStep)
      return validateProductStep(productStepFields, hasSampleStep);
    if (isTargetingStep)
      return validateTargetingStep(targetingStepFields, settings.countries);
    if (isServiceStep)
      return validateServicesStep(servicesStepFields, onlyInsert);
    if (isMediaBuyStep) return validateMediaBuyStep(mediaBuyStepFields);

    return true;
  };

  const renderCurrentStepForm = () => {
    if (isProductStep) {
      return (
        <ProductBriefForm
          productFields={{ ...productStepFields }}
          onChange={handleProductStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      );
    }

    if (isTargetingStep) {
      return (
        <TargetingForm
          brief={brief}
          targetingFields={targetingStepFields}
          onChange={handleTargetingStep}
          handleTargetingServices={handleTargetingServices}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      );
    }

    if (isServiceStep) {
      return (
        <ServicesForm
          brief={brief}
          servicesFields={servicesStepFields}
          onChange={handleServicesStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      );
    }

    if (isMediaBuyStep) {
      return (
        <MediaBuyForm
          brief={brief}
          mediaBuyFields={mediaBuyStepFields}
          onChange={handleMediaBuyStep}
          onFinishLater={handleFinishLater}
          disabledFinishLater={!canProceed()}
        />
      );
    }

    return null;
  };

  if (additionalStep === ADDITIONAL_STEPS.CONGRATS) {
    return <SubmittedSuccessfully approvedUser={currentUser.isApproved} briefDetails={briefView} />;
  }

  if (additionalStep === ADDITIONAL_STEPS.SAVED) {
    return <UpdatedSuccessfully />;
  }

  if (!brief.lastFilledStep) {
    return (
      <div
        className={`qu-brief-layout ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? "html-rtl" : ""
          }`}
      >
        <BriefHeader
          title={t(BRIEF_FORM_HEADER_TITLE.EDIT, { ns: "translationCommon" })}
          stepper={null}
          onClickCancel={async () => {
            const inviterToken = await localStorage.getItem(
              LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
            );
            if (inviterToken) {
              const inviterID = await localStorage.getItem(
                LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID
              );
              history.push(`/p/${inviterID}`);
              return;
            }

            history.push(PATHS.BRIEF);
          }}
        />
        <div className="qu-brief-main">
          <SpinnerCenter />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`qu-brief-layout ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? "html-rtl" : ""
        }`}
    >
      {partnerLogo && (
        <header className="qu-header partner-logo-header">
          <img src={partnerLogo} className="partner-logo-brief" />
        </header>
      )}
      <BriefHeader
        title={t(BRIEF_FORM_HEADER_TITLE.EDIT, { ns: "translationCommon" })}
        stepper={steps}
        showPartnerFilter={false}
        onClickCancel={async () => {
          const inviterToken = await localStorage.getItem(
            LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN
          );
          if (inviterToken) {
            const inviterID = await localStorage.getItem(
              LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID
            );
            history.push(`/p/${inviterID}`);
            return;
          }

          history.push(PATHS.BRIEF);
        }}
      />
      <div
        className={`qu-brief-main ${isPartnerStep ? "qu-brief-partner height-auto" : ""
          } ${partnerLogo ? "partner-logo-qu-brief-main" : ""}`}
        id={MAIN_PRINT_ID}
      >
        {((isSaving && !isRecommendedPartnersLoading) || isUpdatingCart) && (
          <SpinnerCenter
            text={
              isSaving && isFinalSummary && (isDraft || isSelectPartners)
                ? t("brief.edit.common.generating_quotation") + "…"
                : ""
            }
          />
        )}

        {additionalStep === ADDITIONAL_STEPS.SUMMARY && (
          <SummaryForm briefId={brief.id!} setCurrentStep={setCurrentStep} />
        )}

        {additionalStep === ADDITIONAL_STEPS.MEDIA_BUY_DIALOG && (
          <BriefDialog
            checked={addMediaBuyStep}
            onChange={handleMediaBuyDialog}
            title={"Media Buying"}
          />
        )}

        {additionalStep === ADDITIONAL_STEPS.PRODUCT_SAMPLE_DIALOG && (
          <BriefDialog
            checked={addProductSample}
            onChange={handleProductSampleDialog}
            title={"Product Sample or Printed Insert"}
          />
        )}

        {isFinalSummary && !isPaymentStep && (
          <FinalSummary
            onFinishLater={handleFinishLater}
            briefId={brief.id!}
            setCurrentStep={setCurrentStep}
          />
        )}

        {isZeroStep && !additionalStep && (
          <BriefTypeForm
            briefTypes={briefTypes}
            onChange={handleBriefTypeStep}
          />
        )}
        {isPartnerStep && !additionalStep && (
          <BriefPartnerForm brief={brief} saveMediaBuy={saveMediaBuy} />
        )}
        {isUploadStep && !additionalStep && (
          <UploadForm
            ref={uploadFormRef}
            brief={brief}
            onChange={handleUploadStep}
            onFinishLater={handleFinishLater}
            disabledFinishLater={!canProceed()}
            skipUploadStep={skipUploadStep}
            showFinalSummary={showFinalSummary}
          />
        )}
        {isPaymentStep && (
          <Payment
            briefId={brief.id!}
            setCurrentStep={setCurrentStep}
            onFilesChange={onPaymentFilesChange}
            onPaymentTypeChange={onPaymentTypeChange}
            hideSubmitButton={true}
          />
        )}
        {!isZeroStep &&
          !additionalStep &&
          !isPartnerStep &&
          !isUploadStep &&
          !isPaymentStep &&
          !isFinalSummary && (
            <div className="qu-brief-content brief-step">
              <Row gutter={16}>
                <Col sm={24} md={17}>
                  {renderCurrentStepForm()}
                </Col>
                <Col sm={24} md={7}>
                  <div className="qu-summary-wrapper">
                    <BriefSummary
                      brief={brief}
                      potentialAudienceReachData={potentialAudienceReachData}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
      </div>
      {showFooter && (
        <BriefFooter
          className=""
          disableNext={
            (isPaymentStep && submitPaymentButtonDisabled) || !canProceed()
          }
          disableBack={blockEditBrief}
          isZeroStep={isProductStep}
          isPaymentStep={isPaymentStep}
          hideArrow={
            additionalStep === ADDITIONAL_STEPS.SUMMARY &&
            (!currentUser.id || !currentUser.isApproved)
          }
          onBack={handleBack}
          onNext={
            isFinalSummary && !paymentStepEnabled
              ? handleSubmitBrief
              : handleNext
          }
          onFinishLater={handleFinishLater}
          nextButtonText={submitButtonText()}
        />
      )}
      <Modal
        wrapClassName={`qu-modal ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? "html-rtl" : ""
          }`}
        title={
          <h3>
            {t("Get started with Retail Media", {
              ns: "translationInviteClient",
            })}
          </h3>
        }
        closeIcon={closeIcon}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={false}
      >
        <div>
          <p className="mb-25">
            {t("Youre just a few steps away", {
              partnerName: partnerData?.companyName,
              ns: "translationInviteClient",
            })}
          </p>
          <div className="align-vertical text-center">
            {!showSignupContainer && (
              <QButton
                onClick={handleDiscard}
                className="w-280 mb-20"
                type="primary"
              >
                <span className="mr-5">
                  {t("Sign Up Now", { ns: "translationInviteClient" })}
                </span>
                <CircleCheckIcon />
              </QButton>
            )}

            {!showLoginContainer && (
              <button
                onClick={handleContinue}
                type="button"
                className="button button--link mb-10"
              >
                {t("I already have an account", {
                  ns: "translationInviteClient",
                })}
              </button>
            )}

            <hr className="form__hr" />

            {showSignupContainer && (
              <SignUpContainer
                hidePartnerInfo={true}
                specialClientData={getTempTokenDetails()}
              />
            )}

            {showLoginContainer && (
              <SignInContainer
                hidePartnerInfo={true}
                specialClientData={getTempTokenDetails()}
              />
            )}
          </div>
        </div>
      </Modal>

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{discountCodeErrorModal.title}</h3>}
        width={400}
        closeIcon={<IconsCommon.IconClose />}
        visible={discountCodeErrorModal.visible}
        onCancel={() =>
          setDiscountCodeErrorModal({
            title: "",
            visible: false,
            description: "",
          })
        }
        footer={false}
      >
        <div>
          <p className="mb-25">{discountCodeErrorModal.description}</p>
          <div className="align-vertical text-center">
            <QButton
              onClick={onProceedDiscountCodeErrorModal}
              className="w-280 mb-20"
              type="primary"
            >
              <span className="mr-5">Remove Code & Review Brief</span>
            </QButton>
          </div>
        </div>
      </Modal>

      {paymentModalOpen && (
        <BriefPaymentModal
          briefId={Number(briefId)}
          onCancel={() => setPaymentModalOpen(false)}
          onPaySuccess={async () => {
            const res: any = await dispatch(actions.validateClientRequiredFields(Number(briefId)))
            if (res) {
              if (res.missingInfo) {
                setPaymentModalOpen(false);
                setClientRequiredFieldsOpen({ visible: true, type: 'paid' });
              } else {
                setPaymentModalOpen(false);
                setAdditionalStep(ADDITIONAL_STEPS.CONGRATS);
              }
            }
          }}
        />
      )}

      <Modal
        wrapClassName="qu-modal"
        title={<h3>{t("brief.incomplete-client-profile.Incomplete Client Profile")}</h3>}
        // width={400}
        closeIcon={<IconsCommon.IconClose />}
        visible={clientRequiredFieldsOpen.visible}
        onCancel={() => {
          setClientRequiredFieldsOpen({ visible: false, type: '' });
          clientRequiredFieldsOpen.type === 'paid' && setAdditionalStep(ADDITIONAL_STEPS.CONGRATS);
        }}
        footer={false}
      >
        <div>
          <p className="mb-25">
            {t("brief.incomplete-client-profile.Last_thing_before_campaign_kick-off")}
          </p>
          <div className="grid-row grid-row--smg grid-row--jce">
            <div className="grid-col grid-col--auto">
              <QButton onClick={() => history.push(PATHS.CLIENT_PROFILE)} type="primary">
                {t("brief.incomplete-client-profile.Edit Profile")}
              </QButton>
            </div>
            <div className="grid-col grid-col--auto">
              <QButton
                className="qu-button-outline"
                onClick={() => {
                  setClientRequiredFieldsOpen({ visible: false, type: '' });
                  clientRequiredFieldsOpen.type === 'paid' && setAdditionalStep(ADDITIONAL_STEPS.CONGRATS);
                }}
              >
                {t("brief.incomplete-client-profile.Cancel")}
              </QButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
