import { IState } from './referral.types'
import * as CONSTANTS from './referral.constants'

const initialState: IState = {
  creatingReferralError: '',
  creatingReferral: false,
  couponsLoading: false,
  coupons: [],
}

export default (state: IState = initialState, action: any): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_REFERRAL_STATE:
      return initialState

    case CONSTANTS.CREATE_REFERRAL_REQUEST:
      return { ...state, creatingReferral: true }
    case CONSTANTS.CREATE_REFERRAL_SUCCESS:
      return { ...state, creatingReferral: false }
    case CONSTANTS.CREATE_REFERRAL_FAILURE:
      return { ...state, creatingReferral: false, creatingReferralError: action.error }

    case CONSTANTS.FETCH_COUPONS_REQUEST:
      return { ...state, couponsLoading: true }
    case CONSTANTS.FETCH_COUPONS_SUCCESS:
      return { ...state, couponsLoading: false, coupons: action.payload }
    case CONSTANTS.FETCH_COUPONS_FAILURE:
      return { ...state, couponsLoading: false }
    default:
      return state
  }
}
