import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { requestHttp, urls } from '../../../api'
import { getResponseErrorMessage } from '../../../helpers'
import IAction from '../../../interfaces/IAction'
import * as CONSTANTS from './referral.constants'
import { ICoupon, IReferral } from './referral.types'

export const createReferralRequest = (): IAction => ({
  type: CONSTANTS.CREATE_REFERRAL_REQUEST,
})

export const createReferralSuccess = (): IAction => ({
  type: CONSTANTS.CREATE_REFERRAL_SUCCESS,
})

export const createReferralFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_REFERRAL_FAILURE,
  error,
})

export const createReferral = (data: IReferral): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse> => {
  try {
    dispatch(createReferralRequest())
    const response = await requestHttp.post(urls.getCreateReferralUrl(), data)
    dispatch(createReferralSuccess())
    return response
  } catch (error: any) {
    dispatch(createReferralFailure(getResponseErrorMessage(error)))
    return error
  }
}

export const fetchCouponsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_COUPONS_REQUEST,
})

export const fetchCouponsSuccess = (coupons: ICoupon[]): IAction => ({
  type: CONSTANTS.FETCH_COUPONS_SUCCESS,
  payload: coupons,
})

export const fetchCouponsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_COUPONS_FAILURE,
  error,
})

export const fetchCoupons = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(fetchCouponsRequest())
    const response = await requestHttp.get(urls.getClientCouponsList())
    const { content } = response.data.data
    dispatch(fetchCouponsSuccess(content))
  } catch (error: any) {
    dispatch(fetchCouponsFailure(getResponseErrorMessage(error)))
  }
}
