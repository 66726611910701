import get from 'lodash/get'
import { IChartData, IState, IStatisticsCards } from './dashboard.types'
import * as CONSTANTS from './dashboard.constants'
import IAction from 'src/interfaces/IAction'
import { CURRENCY } from 'src/constants/currencies'

export const statisticCardsInitState: IStatisticsCards = {
  lifetimeEarnings: {
    amount: 0,
    count: 0,
  },
  liveCampaigns: {
    amount: 0,
    count: 0,
  },
  openRequests: {
    amount: 0,
    count: 0,
  },
  currency: CURRENCY.SAR,
}
export const chartDataInitState: IChartData = {
  statistics: {
    requests: 0,
    earnings: 0,
    impressions: 0,
    currency: CURRENCY.SAR,
  },
  charts: [],
}
const initialState: IState = {
  statisticCards: statisticCardsInitState,
  statisticCardsLoading: false,
  statisticCardsError: '',
  chartData: chartDataInitState,
  chartDataLoading: false,
  chartDataError: '',
  params: { period: 12 },
  openRequests: [],
  openRequestsLoading: false,
  openRequestsError: '',
  liveCampaigns: [],
  liveCampaignsLoading: false,
  liveCampaignsError: '',
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.STATS_CARDS_REQUEST:
      return { ...state, statisticCardsLoading: true }
    case CONSTANTS.STATS_CARDS_SUCCESS:
      return { ...state, statisticCards: get(action, 'payload.statisticCards'), statisticCardsLoading: false }
    case CONSTANTS.STATS_CARDS_FAILURE:
      return { ...state, statisticCardsError: action.error, statisticCardsLoading: false }

    case CONSTANTS.CHART_REQUEST:
      return { ...state, chartDataLoading: true }
    case CONSTANTS.CHART_SUCCESS:
      const chartData:any = get(action, 'payload.chartData')
      // @ts-ignore
      const { impressions } = chartData.statistics
      return {
        ...state,
        chartData: {
          // @ts-ignore
          ...chartData,
          // @ts-ignore
          statistics: { ...chartData.statistics, impressions: impressions ? impressions : 0 },
        },
        chartDataLoading: false,
      }
    case CONSTANTS.CHART_FAILURE:
      return { ...state, chartDataError: action.error, chartDataLoading: false }

    case CONSTANTS.SET_CHART_PARAMS:
      return { ...state, params: get(action, 'payload.params') }

    case CONSTANTS.FETCH_OPEN_REQUESTS_REQUEST:
      return { ...state, openRequestsLoading: true }
    case CONSTANTS.FETCH_OPEN_REQUESTS_SUCCESS:
      return { ...state, openRequests: get(action, 'payload.openRequests'), openRequestsLoading: false }
    case CONSTANTS.FETCH_OPEN_REQUESTS_FAILURE:
      return { ...state, openRequestsError: action.error, openRequestsLoading: false }

    case CONSTANTS.FETCH_LIVE_CAMPAIGNS_REQUEST:
      return { ...state, liveCampaignsLoading: true }
    case CONSTANTS.FETCH_LIVE_CAMPAIGNS_SUCCESS:
      return { ...state, liveCampaigns: get(action, 'payload.liveCampaigns'), liveCampaignsLoading: false }
    case CONSTANTS.FETCH_LIVE_CAMPAIGNS_FAILURE:
      return { ...state, liveCampaignsError: action.error, liveCampaignsLoading: false }
    default:
      return state
  }
}
