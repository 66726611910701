import React from 'react'
import { Dropdown, IconsCommon, Menu, QMenuItem, QTable } from 'quantum_components'
import { useSelector } from 'react-redux'
import { getLiveCampaigns, getLiveCampaignsLoading } from '../../dashboard.selectors'
import { DISTRIBUTION_TYPE } from 'src/constants/brief'
import { REQUEST_STATUS } from 'src/constants/request'
import { campaignStatusesLabel, CAMPAIGN_STATUS } from 'src/constants/campaign'
import { ICampaignTable, ICampaignTableProduct, ICampaignTableService } from 'src/modules/campaign/campaign.types'
import { useHistory } from 'react-router-dom'
import { ReactComponent as DeviceIcon } from 'src/assets/icons/devices-icon.svg'
import { renderRoundedNumber } from '../../../../../helpers/currency'
import { getCurrency } from '../../../../core/core.selectors'

export default function CampaignsTable() {
  const history = useHistory()
  const liveCampaignsData = useSelector(getLiveCampaigns)
  const liveCampaignsLoading = useSelector(getLiveCampaignsLoading)
  const currency = useSelector(getCurrency)

  const isDistribution = (service: string) =>
    service === DISTRIBUTION_TYPE.PRODUCT_SAMPLE || service === DISTRIBUTION_TYPE.PRINTED_INSERT

  const isMediaBuy = (service: string) =>
    service !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE && service !== DISTRIBUTION_TYPE.PRINTED_INSERT

  const columnsRequests = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      width: '30%',
      fixed: 'left',
      sorter: true,
      render: (product: ICampaignTableProduct) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.image && <img src={product.image} alt={product.title} />}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.title}</h3>
              <div className="q-table-product_created">Started {product.startDate}</div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Services',
      dataIndex: 'service',
      key: 'service',
      width: '20%',
      render: (service: ICampaignTableService) => {
        const distributions = service.types.filter(isDistribution)
        const mediaBuys = service.types.filter(isMediaBuy)

        return (
          <ul className="q-table-list">
            {distributions.length > 0 && (
              <li className="q-table-list_item font-weight-500">
                <span className="mr-10">
                  <IconsCommon.IconSample />
                </span>
                Distribution
              </li>
            )}
            {mediaBuys.length > 0 && (
              <li className="q-table-list_item font-weight-500">
                <span className="mr-10">
                  <DeviceIcon />
                </span>
                Media Buy
              </li>
            )}
          </ul>
        )
      },
    },
    {
      title: 'Start & Duration',
      dataIndex: 'service',
      key: 'service',
      width: '20%',
      render: (service: ICampaignTableService) => {
        const distributions = service.types.filter(isDistribution)
        const mediaBuys = service.types.filter(isMediaBuy)

        return (
          <React.Fragment>
            {distributions.length > 0 && (
              <div>
                <div>{service.productStartDate}</div>
                <div>{service.productDuration}</div>
              </div>
            )}

            {mediaBuys.length > 0 && (
              <div>
                <div>{service.mediaStartDate}</div>
                <div>{service.mediaDuration}</div>
              </div>
            )}
          </React.Fragment>
        )
      },
    },
    {
      title: 'Earnings',
      dataIndex: 'service',
      key: 'revenue',
      width: '15%',
      sorter: true,
      render: (service: ICampaignTableService) => (
        <span className="font-size-14 font-weight-600">{service.revenue}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status: CAMPAIGN_STATUS, record: ICampaignTable) => (
        <>
          <span className={`status-label ${campaignStatusesLabel[status] && campaignStatusesLabel[status].classStyle}`}>
            {campaignStatusesLabel[status] ? campaignStatusesLabel[status].label : REQUEST_STATUS.REVIEW_NOW}
          </span>
          {/* QUA-1449, QP-492 - prevent event bubbling to onRowClick */}
          <div onClick={e => e.stopPropagation()}>
            <Dropdown overlay={menu(record.key)} trigger={['click']} placement="bottomRight">
              <div className="q-table_menu">
                <IconsCommon.IconEllipsisV />
              </div>
            </Dropdown>
          </div>
        </>
      ),
    },
  ]
  const menu = (id: number) => (
    <Menu className="user-menu-list">
      <QMenuItem key={0} onClick={() => history.push(`/campaigns/${id}`)} name="View" icon={<IconsCommon.IconEye />} />
    </Menu>
  )

  const handleOnRowClick = (campaign: ICampaignTable) => ({
    onClick: (event: any) => history.push(`/campaigns/${campaign.key}`),
  })

  return (
    <div className="dashboard-table-wrapper">
      <QTable
        //@ts-ignore
        onRow={handleOnRowClick}
        loading={liveCampaignsLoading}
        columns={columnsRequests}
        dataSource={liveCampaignsData}
        className="cursorPointer"
      />
    </div>
  )
}
