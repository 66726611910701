import React from 'react'
import { Row, Col } from 'quantum_components'
import { IBriefResponse } from '../../testahelBox.types'
import { useSelector } from 'react-redux'
import { getBriefSettings, getServiceImage } from '../../testahelBox.selectors'
import { first } from 'lodash'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
}

const TestahelBriefAdditional = ({ briefView, onEdit, viewMode }: Props) => {
  const serviceImage = useSelector(getServiceImage)
  const briefSettings = useSelector(getBriefSettings)

  const image = briefView.serviceImages ? first(briefView.serviceImages) : serviceImage
  return (
    <div className="qu-view-card m-10">
      <div className="qu-card-header">
        <h4>Additional info</h4>
        {!viewMode && <span onClick={onEdit}>Edit</span>}
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">Packaging Type</div>
            <div className="data-view-value">{briefView.servicePackageType?.join(', ') || 'None'}</div>
          </div>
        </Col>
        <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">Temperature Condition</div>
            <div className="data-view-value">{briefView.serviceTemperature || 'None'}</div>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        {/* <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">Samples per Bundle</div>
            <div className="data-view-value">{briefView.servicePackagingQuantity || 'None'}</div>
          </div>
        </Col>
        <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">Co-packaging needs</div>
            <div className="data-view-value">{briefView.servicePackagingNeeds || 'None'}</div>
          </div>
        </Col> */}
        <Col span={12}>
          <div className="data-view mb-15">
            <div className="data-view-label">Printing needs</div>
            <div className="data-view-value">
              {briefSettings?.printingPrices?.filter(ele => ele.id == briefView.printingPriceId).length > 0
                ? briefSettings?.printingPrices?.filter(ele => ele.id == briefView.printingPriceId)[0].name
                : 'None'}
            </div>
          </div>
        </Col>
      </Row>
      <div className="additional-info mb-15">
        <ul className="p p--sm cart-summary__ulist mb-0">
          <li className={briefView.serviceIsLiquid ? '' : 'disabled'}>Sample is Liquid</li>
          <li className={briefView.serviceIncludingCoupon ? '' : 'disabled'}>Include Coupon / Marketing Material</li>
          <li className={briefView.serviceDesign ? '' : 'disabled'}>Require Insert Design</li>
          {/* <li className={briefView.servicePackaging ? '' : 'disabled'}>Require Co-Packing Services</li> */}
          <li className={briefView.servicePrinting ? '' : 'disabled'}>Require Printing</li>
          {/* <li className={briefView.hasFreeSample ? '' : 'disabled'}>Has Free Sample</li> */}
        </ul>
      </div>
      <div className="qu-view-card-image">{image && <img src={image} alt="product" />}</div>
    </div>
  )
}
export default TestahelBriefAdditional
