import { BRIEF_PAYMENT_STATUS, BRIEF_STATUS } from '../constants/brief'

export const canEditBrief = (status: BRIEF_STATUS) => {
  const allowEditStatuses = [
    BRIEF_STATUS.DRAFT,
    BRIEF_STATUS.PENDING,
    BRIEF_STATUS.SELECT_PARTNERS,
    BRIEF_STATUS.PENDING_ACCOUNT_APPROVAL,
  ]
  return allowEditStatuses.includes(status)
}

export const canPartiallyEditBrief = (status: BRIEF_STATUS) => {
  const allowEditStatuses = [
    BRIEF_STATUS.DRAFT,
    BRIEF_STATUS.PENDING,
    BRIEF_STATUS.REJECTED,
    BRIEF_STATUS.PARTIALLY_REJECTED,
    BRIEF_STATUS.SELECT_PARTNERS,
    BRIEF_STATUS.PENDING_ACCOUNT_APPROVAL,
  ]

  return allowEditStatuses.includes(status)
}

export const canEditBriefWithPaymentStatus = (status: BRIEF_PAYMENT_STATUS) => {
  const allowEditStatuses = [BRIEF_PAYMENT_STATUS.PENDING]
  return allowEditStatuses.includes(status)
}
