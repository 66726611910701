import React from 'react'
import { PARTNER_STEPS, PARTNER_STEPS_NAMES } from '../../../../../constants/profile'
import { PARTNER_STEPS_EDIT } from '../ProfilePartner.container'
import { BRIEF_STEP_STATUS } from '../../../../../constants/brief'
import { QBriefStep } from 'quantum_components'
import { actions } from '../../partnerProfile.module'
import { useSelector } from 'react-redux'
import { getCurrentStep, getFilledSteps } from '../../profile.selectors'
import { useReduxDispatch } from '../../../../../helpers'

export default function PartnersProfileHeader() {
  const dispatch = useReduxDispatch()
  const currentStep = useSelector(getCurrentStep)
  const filledStepList = useSelector(getFilledSteps)
  const currentIndex = PARTNER_STEPS_EDIT.indexOf(currentStep)

  const getStatus = (step: PARTNER_STEPS) => {
    const index = PARTNER_STEPS_EDIT.indexOf(step)
    if (index === currentIndex) {
      return BRIEF_STEP_STATUS.CURRENT
    }
    if (filledStepList.includes(step)) {
      return BRIEF_STEP_STATUS.PASSED
    }
    return BRIEF_STEP_STATUS.DEFAULT
  }

  function changeStep(step: any) {
    dispatch(actions.setCurrentStep(step))
  }

  const steps = () => (
    <div className="qu-stepper mv-20">
      <div className="steps profile-steps">
        {PARTNER_STEPS_EDIT.map((step, index) => {
          return (
            <QBriefStep
              key={step}
              title={`${index + 1}. ${PARTNER_STEPS_NAMES[step]}`}
              status={getStatus(step)}
              onClickPath={() => changeStep(step)}
            />
          )
        })}
      </div>
    </div>
  )

  return (
    <div className="partner-container-header">
      <h1 style={{ fontSize: '34px' }}>Partner Profile</h1>
      {steps()}
    </div>
  )
}
