import { IRequest, IRequestTable } from '../modules/partner/requests/request.types'
import moment from 'moment'
import { FORMATS } from '../constants'
import { renderRoundedNumber } from './currency'
import { CURRENCY } from '../constants/currencies'
import { MEDIA_GROUP_TYPE } from '../constants/brief'

export const requestsToTable = (requests: IRequest[], currency: CURRENCY): IRequestTable[] => {
  if (!requests) {
    return []
  }

  return requests.map((request: IRequest) => {
    const startDate =
      request.type === MEDIA_GROUP_TYPE.MEDIA_BUY
        ? request.startMediaDate
          ? moment(request.startMediaDate).format(FORMATS.DATE_LL_FORMAT)
          : '-'
        : request.startDate
        ? moment(request.startDate).format(FORMATS.DATE_LL_FORMAT)
        : '-'

    return {
      key: request.id,
      product: {
        image: request.productImageUrl ? request.productImageUrl[0] : null,
        title: request.productName,
        received: request.lastApprovedDate ? moment(request.lastApprovedDate).format(FORMATS.DATE_LL_FORMAT) : '-',
      },
      service: {
        title: request.type,
        startDate: startDate,
        duration: request.duration,
        revenue: renderRoundedNumber(request.revenue, currency),
      },
      status: request.status,
      payment: request.payment,
    } as IRequestTable
  })
}
