import React, { ReactElement } from 'react'
import { Col, Popover, QSelectItem, Row } from 'quantum_components'

import { ReactComponent as BrandStore } from 'src/assets/icons/media-types/brand-store.svg'
import { ReactComponent as BrandStoreActive } from 'src/assets/icons/media-types/brand-store2.svg'
import { ReactComponent as CashbackOffers } from 'src/assets/icons/media-types/cashback-offers.svg'
import { ReactComponent as CashbackOffersActive } from 'src/assets/icons/media-types/cashback-offers2.svg'
import { ReactComponent as FreeDelivery } from 'src/assets/icons/media-types/free-delivery.svg'
import { ReactComponent as FreeDeliveryActive } from 'src/assets/icons/media-types/free-delivery2.svg'
import { ReactComponent as HomePageBanners } from 'src/assets/icons/media-types/home-page-banners.svg'
import { ReactComponent as HomePageBannersActive } from 'src/assets/icons/media-types/home-page-banners2.svg'
import { ReactComponent as OnlineSurvey } from 'src/assets/icons/media-types/online-survey.svg'
import { ReactComponent as OnlineSurveyActive } from 'src/assets/icons/media-types/online-survey2.svg'
import { ReactComponent as OohMediaPartnership } from 'src/assets/icons/media-types/ooh-media-partnership.svg'
import { ReactComponent as OohMediaPartnershipActive } from 'src/assets/icons/media-types/ooh-media-partnership2.svg'
import { ReactComponent as PopupWindow } from 'src/assets/icons/media-types/popup-window.svg'
import { ReactComponent as PopupWindowActive } from 'src/assets/icons/media-types/popup-window2.svg'
import { ReactComponent as PriceOffPromo } from 'src/assets/icons/media-types/price-off-promo.svg'
import { ReactComponent as PriceOffPromoActive } from 'src/assets/icons/media-types/price-off-promo2.svg'
import { ReactComponent as PushNotifications } from 'src/assets/icons/media-types/push-notifications.svg'
import { ReactComponent as PushNotificationsActive } from 'src/assets/icons/media-types/push-notifications2.svg'
import { ReactComponent as Feed } from 'src/assets/icons/media-types/feed.svg'
import { ReactComponent as FeedActive } from 'src/assets/icons/media-types/feed2.svg'
import { ReactComponent as SkuUplifting } from 'src/assets/icons/media-types/sku-uplifting.svg'
import { ReactComponent as SkuUpliftingActive } from 'src/assets/icons/media-types/sku-uplifting2.svg'
import BrandStoreBanner from 'src/assets/icons/banner-types/brand-store.svg'
import CashbackOffersBanner from 'src/assets/icons/banner-types/cashback-offer.svg'
import FreeDeliveryBanner from 'src/assets/icons/banner-types/free-delivery.svg'
import HomePageBannersBanner from 'src/assets/icons/banner-types/home-page-banners.svg'
import PopupWindowBanner from 'src/assets/icons/banner-types/popup-window.svg'
import PriceOffPromoBanner from 'src/assets/icons/banner-types/price-off-promotion.svg'
import PushNotificationsBanner from 'src/assets/icons/banner-types/push-notifications.svg'
import SkuUpliftingBanner from 'src/assets/icons/banner-types/sku-uplifting.svg'
import SocialMediaBanner from 'src/assets/icons/banner-types/social-media-ads.svg'
import SurveyBanner from 'src/assets/icons/banner-types/survey.svg'
import { BANNERS_BY_MEDIA_TYPE, MEDIA_TYPES } from '../../../../../constants/testahel_brief'

const BANNER_TYPES_IMAGE: { [key: string]: any } = {
  [MEDIA_TYPES.BRAND_STORE]: {
    image: BrandStoreBanner,
    description: '',
  },
  [MEDIA_TYPES.CASHBACK]: {
    image: CashbackOffersBanner,
    description: 'Offer cash back incentives to buyers of your product.',
  },
  [MEDIA_TYPES.FREE_DELIVERY]: {
    image: FreeDeliveryBanner,
    description: '',
  },
  [MEDIA_TYPES.HOME_BANNERS]: {
    image: HomePageBannersBanner,
    description: 'The largest and most prominent piece of real estate on a website.',
  },
  [MEDIA_TYPES.POPUP_WINDOW]: {
    image: PopupWindowBanner,
    description: 'An ad that appears either in a modal or popup window after visiting a page.',
  },
  [MEDIA_TYPES.SURVEY]: {
    image: SurveyBanner,
    description: 'Conduct research by allowing visitors to complete a brief survey ',
  },
  [MEDIA_TYPES.PRICE_OFF_PROMO]: {
    image: PriceOffPromoBanner,
    description: 'An ad that appears either in a modal or popup window after visiting a page.',
  },
  [MEDIA_TYPES.PUSH_NOTIFICATIONS]: {
    image: PushNotificationsBanner,
    description: 'Trigger a push notification with an ad either in an app or via SMS.',
  },
  [MEDIA_TYPES.FEED]: {
    image: PushNotificationsBanner,
    description: 'Advertise with video on a partner’s platform feed.',
  },
  [MEDIA_TYPES.SKU]: {
    image: SkuUpliftingBanner,
    description: 'Promote an individual product listing prominently on a website.',
  },
  [MEDIA_TYPES.SOCIAL_MEDIA]: {
    image: SocialMediaBanner,
    description: 'Advertise on a partner’s social media feed or channel.',
  },
}

const MEDIA_TYPES_IMAGE: { [key: string]: ReactElement } = {
  [MEDIA_TYPES.BRAND_STORE]: <BrandStore />,
  [MEDIA_TYPES.BRAND_STORE + '_active']: <BrandStoreActive />,
  [MEDIA_TYPES.CASHBACK]: <CashbackOffers />,
  [MEDIA_TYPES.CASHBACK + '_active']: <CashbackOffersActive />,
  [MEDIA_TYPES.FREE_DELIVERY]: <FreeDelivery />,
  [MEDIA_TYPES.FREE_DELIVERY + '_active']: <FreeDeliveryActive />,
  [MEDIA_TYPES.HOME_BANNERS]: <HomePageBanners />,
  [MEDIA_TYPES.HOME_BANNERS + '_active']: <HomePageBannersActive />,
  [MEDIA_TYPES.SURVEY]: <OnlineSurvey />,
  [MEDIA_TYPES.SURVEY + '_active']: <OnlineSurveyActive />,
  [MEDIA_TYPES.SOCIAL_MEDIA]: <OohMediaPartnership />,
  [MEDIA_TYPES.SOCIAL_MEDIA + '_active']: <OohMediaPartnershipActive />,
  [MEDIA_TYPES.POPUP_WINDOW]: <PopupWindow />,
  [MEDIA_TYPES.POPUP_WINDOW + '_active']: <PopupWindowActive />,
  [MEDIA_TYPES.PRICE_OFF_PROMO]: <PriceOffPromo />,
  [MEDIA_TYPES.PRICE_OFF_PROMO + '_active']: <PriceOffPromoActive />,
  [MEDIA_TYPES.PUSH_NOTIFICATIONS]: <PushNotifications />,
  [MEDIA_TYPES.PUSH_NOTIFICATIONS + '_active']: <PushNotificationsActive />,
  [MEDIA_TYPES.FEED]: <Feed />,
  [MEDIA_TYPES.FEED + '_active']: <FeedActive />,
  [MEDIA_TYPES.SKU]: <SkuUplifting />,
  [MEDIA_TYPES.SKU + '_active']: <SkuUpliftingActive />,
}

const content = (name: string) => {
  return (
    <div>
      <div className="banner-popover__image">
        <img src={BANNERS_BY_MEDIA_TYPE[name].image} alt={name} />
      </div>
      <div className="mt--100 p fw-500">{name}</div>
      <div className="p p--sm tc--light">{BANNERS_BY_MEDIA_TYPE[name].description}</div>
    </div>
  )
}

interface ICardProps {
  checked: boolean
  name: string
  onChange: (e: MouseEvent, name: string) => void
}

export function TestahelMediaTypeCard({ checked, name, onChange }: ICardProps): ReactElement {
  const imageName = checked ? name + '_active' : name

  return (
    <Popover
      overlayClassName="qu-banner-popover"
      content={BANNERS_BY_MEDIA_TYPE[name] ? content(name) : null}
      placement="rightTop"
      trigger="hover"
      getPopupContainer={(node: any) => node.parentNode}
    >
      <Col xs={8} key={name}>
        <QSelectItem
          className={'media-card mb-16'}
          label={name}
          checked={checked}
          cardImage={MEDIA_TYPES_IMAGE[imageName]}
          onChange={(e: MouseEvent) => onChange(e, name)}
        />
      </Col>
    </Popover>
  )
}

interface IIconProps {
  name: string
}

export function TestahelMediaTypeIcon({ name }: IIconProps): ReactElement {
  const imageName = name + '_active'

  return (
    <div className="BSUnit__bList-item">
      <div className="BSUnit__bList-img">{MEDIA_TYPES_IMAGE[imageName]}</div>
      <div className="BSUnit__bList-ttl">{name}</div>
    </div>
  )
}
