import React, { Fragment, ReactElement, useEffect } from 'react'
import {
  BRIEF_STEPS,
  BRIEF_TYPES,
  DISTRIBUTION_TYPE,
  SERVICE_NAMES_FOR_TABLE,
} from '../../../../../constants/testahel_brief'
import { isMobile } from 'react-device-detect'
import { Col, Row } from 'quantum_components'

import TestahelServiceCard from '../components/TestahelBrief.Service'
import TestahelBriefProduct from '../components/TestahelBrief.Product'
import TestahelBriefMedia from '../components/TestahelBrief.Media'
import TestahelBriefTargeting from '../components/TestahelBrief.Targeting'
import TestahelBriefAdditional from '../components/TestahelBrief.Additional'
import TestahelBriefViewSummary from '../components/TestahelBrief.View.Summary'
import { useSelector } from 'react-redux'
import { getBriefView } from '../../testahelBox.selectors'
import * as actions from '../../testahelBox.actions'
import { useReduxDispatch } from '../../../../../helpers'

interface IProps {
  briefId: number
  setCurrentStep: (step: BRIEF_STEPS) => void
}

export default function TestahelSummaryForm({ briefId, setCurrentStep }: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const briefView = useSelector(getBriefView)

  useEffect(() => {
    dispatch(actions.fetchViewBriefDetails(briefId))
  }, [briefId])

  const isProductSample = briefView.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isCustomizeBox = briefView.briefTypes?.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
  const isMediaBuying = briefView.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
  const isConsumerResearch = briefView.briefTypes?.includes(BRIEF_TYPES.CONSUMER_RESEARCH)
  const distributionItem =
    briefView.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
      ? SERVICE_NAMES_FOR_TABLE.INSERT
      : SERVICE_NAMES_FOR_TABLE.PRODUCT

  return (
    <div className="qu-brief-content brief-step">
      <div className="brief-step-header">
        <h1>Here's summary of your brief</h1>
        <p>Please make sure that all details are correct before confirming</p>
      </div>
      <Row gutter={16} align="middle" justify="center">
        <Col xs={24} sm={20}>
          {isMobile ? (
            <Fragment>
              <TestahelServiceCard
                isProductSample={isProductSample}
                isCustomizeBox={isCustomizeBox}
                isMediaBuying={isMediaBuying}
                isConsumerResearch={isConsumerResearch}
                distributionItem={distributionItem}
                onEdit={() => setCurrentStep(BRIEF_STEPS.TYPE)}
              />
              <TestahelBriefProduct briefView={briefView} onEdit={() => setCurrentStep(BRIEF_STEPS.PRODUCT)} />
              <TestahelBriefTargeting briefView={briefView} onEdit={() => setCurrentStep(BRIEF_STEPS.TARGETING)} />
              <TestahelBriefAdditional briefView={briefView} onEdit={() => setCurrentStep(BRIEF_STEPS.SERVICES)} />
              {isMediaBuying && (
                <TestahelBriefMedia briefView={briefView} onEdit={() => setCurrentStep(BRIEF_STEPS.MEDIA_BUY)} />
              )}
            </Fragment>
          ) : (
            <TestahelBriefViewSummary briefView={briefView} setCurrentStep={setCurrentStep} hideTitle />
          )}
        </Col>
      </Row>
    </div>
  )
}
