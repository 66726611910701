import { INotificationResponse, IState } from './notifications.types'
import * as CONSTANTS from './notifications.constants'
import { NOTIFICATIONS } from 'src/constants'

const initialState: IState = {
  notificationsList: {
    results: [],
    total: 0,
  },
  isLoading: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONSTANTS.NOTIFICATIONS_RESET:
      return initialState
    case CONSTANTS.FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CONSTANTS.FETCH_NOTIFICATIONS_SUCCESS:
      const notificationsList = { ...state.notificationsList } as INotificationResponse
      notificationsList.results = [...notificationsList.results, ...action.payload.results]
      notificationsList.total = action.payload.total

      return {
        ...state,
        notificationsList,
        isLoading: false,
      }
    case CONSTANTS.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CONSTANTS.UPDATE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: false,
      }
    case CONSTANTS.UPDATE_NOTIFICATIONS_SUCCESS:
      const updated = { ...state.notificationsList } as INotificationResponse
      updated.results = updated.results.map(elem => ({
        ...elem,
        status: NOTIFICATIONS.STATUSES.READ,
      }))

      return {
        ...state,
        isLoading: false,
        notificationsList: updated,
      }
    case CONSTANTS.UPDATE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        createGroupError: action.error,
      }
    default:
      return state
  }
}
