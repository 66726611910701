import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import { FORMATS, LOCAL_STORAGE_KEYS } from 'src/constants'
import { CONSUMER_RESEARCH_MEDIA_TYPES, COUNTRY_NAME } from '../../../constants/testahel_brief'
import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import {
  IBrief,
  IBriefListElement,
  IBriefListResponse,
  IBriefPartnerDetails,
  IBriefPartnerDetailsResponse,
  IBriefRecommendedPartner,
  IBriefRecommendedPartnerResponse,
  IBriefResponse,
  IBriefSettings,
  IBriefSettingsResponse,
  IBriefTableParams,
  ICartItem,
  ICartList,
  ICartServiceItem,
  ICreateBriefResponse,
  ICustomizedStep,
  IDeleteBriefResponse,
  IMediaBuyStep,
  IPartnerFilter,
  IPartnerFilterSettings,
  IPartnerFilterSettingsResponse,
  IProductStep,
  IProposal,
  IServicesStep,
  ITargetingStep,
  IUpdateBriefResponse,
  IUploadPartner,
  IUploadSettings,
  IUploadStep,
  IViewBriefDetailsResponse,
  IPackagingPrintingPrice,
  IConsumerResearchStep,
} from './testahelBox.types'
import * as CONSTANTS from './testahelBox.constants'
import { getResponseErrorMessage } from 'src/helpers'
import {
  BRIEF_STEPS,
  BRIEF_TYPES,
  BRIEF_UPLOAD_TYPES,
  DIALOG_MODALS,
  DISTRIBUTION_TYPE,
  SERVICE_NAMES_FOR_TABLE,
} from '../../../constants/testahel_brief'
import { STATUS_CODES } from '../../../constants'
import { uploadTeshahelMedia } from '../../core/core.actions'
import moment from 'moment'
import { IBriefInitState } from './testahelBox.reducer'
import cloneDeep from 'lodash/cloneDeep'
import { UPLOAD_ENTITY_KEYS } from '../../../constants/upload'
import IUploadFile from '../../../interfaces/IUploadFile'
import first from 'lodash/first'
import history from '../../../utils/history'
import { getIsRejectionFlow, getPrintingPrice } from './testahelBox.selectors'
import { REQUEST_STATUS } from '../../../constants/request'
import jsPDF from 'jspdf'

export const setCurrentStep = (step: BRIEF_STEPS): IAction => ({
  type: CONSTANTS.SET_CURRENT_STEP,
  payload: { step },
})

export const setNeedCheckLastStep = (check: boolean): IAction => ({
  type: CONSTANTS.SET_NEED_CHECK_LAST_STEP,
  payload: { check },
})

export const setRejectionFlow = (rejection: boolean): IAction => ({
  type: CONSTANTS.SET_REJECTION_FLOW,
  payload: { rejection },
})

export const setDialogModal = (modal: DIALOG_MODALS | null): IAction => ({
  type: CONSTANTS.SET_DIALOG_MODAL,
  payload: { modal },
})

export const createBriefRequest = (): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_REQUEST,
})

export const createBriefSuccess = (brief: IBrief): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_SUCCESS,
  payload: { brief },
})

export const createBriefPricing = (printingPrice: IPackagingPrintingPrice | null): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_PRICING,
  payload: { printingPrice },
})

export const createBriefSuccessExclusivePartnerId = (exclusivePartnerId: number | null): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_SUCCESS_EXCLUSIVE_PARTNER_ID,
  payload: { exclusivePartnerId },
})

export const createBriefFailure = (error: string): IAction => ({
  type: CONSTANTS.CREATE_BRIEF_FAILURE,
  error,
})

export const briefListRequest = (): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_REQUEST,
})

export const briefListSuccess = (briefList: { results: IBriefListElement[]; total: number }): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_SUCCESS,
  payload: { briefList },
})

export const briefListFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_FAILURE,
  error,
})

export const viewBriefRequest = (): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_REQUEST,
})

export const viewBriefSuccess = (briefDetails: IBrief): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_SUCCESS,
  payload: { briefDetails },
})

export const viewBriefFailure = (error: string): IAction => ({
  type: CONSTANTS.VIEW_BRIEF_FAILURE,
  error,
})

export const setBriefListParams = (params: IBriefTableParams): IAction => ({
  type: CONSTANTS.SET_BRIEF_LIST_PARAMS,
  payload: { params },
})

export const setCurrentPage = (page: number): IAction => ({
  type: CONSTANTS.SET_CURRENT_PAGE,
  payload: { page },
})

export const resetBriefState = (): IAction => ({
  type: CONSTANTS.RESET_BRIEF_STATE,
})

export const setPartnerFilterState = (filter: IPartnerFilter): IAction => ({
  type: CONSTANTS.SET_PARTNER_FILTER,
  payload: { filter },
})

export const resetPartnerFilterState = (): IAction => ({
  type: CONSTANTS.RESET_PARTNER_FILTER,
})

export const deleteBriefRequest = (): IAction => ({ type: CONSTANTS.DELETE_BRIEF_REQUEST })
export const deleteBriefSuccess = (): IAction => ({ type: CONSTANTS.DELETE_BRIEF_SUCCESS })
export const deleteBriefFailure = (error: string): IAction => ({ type: CONSTANTS.DELETE_BRIEF_FAILURE, error })

export const fetchPartnerFilterSettingsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_FILTER_SETTINGS_REQUEST,
})

export const fetchPartnerFilterSettingsSuccess = (settings: IPartnerFilterSettings): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_FILTER_SETTINGS_SUCCESS,
  payload: { settings },
})

export const fetchPartnerFilterSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_FILTER_SETTINGS_FAILURE,
  error,
})

export const imageUploadRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_REQUEST,
})

export const imageUploadSuccess = (brief: IBrief): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_SUCCESS,
  payload: { brief },
})

export const imageUploadFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_FAILURE,
  error,
})

export const imageUploadStepRequest = (key: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_STEP_REQUEST,
  payload: { key },
})

export const imageUploadStepSuccess = (key: string, brief: IBrief): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_STEP_SUCCESS,
  payload: { key, brief },
})

export const imageUploadStepFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_UPLOAD_STEP_FAILURE,
  error,
})

export const imageRemoveRequest = (): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_REQUEST,
})

export const imageRemoveSuccess = (brief: IBrief): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_SUCCESS,
  payload: { brief },
})

export const imageRemoveFailure = (error: string): IAction => ({
  type: CONSTANTS.IMAGE_REMOVE_FAILURE,
  error,
})

export const fetchBriefSettingsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_BRIEF_SETTINGS_REQUEST,
})

export const fetchBriefSettingsSuccess = (settings: IBriefSettings): IAction => ({
  type: CONSTANTS.FETCH_BRIEF_SETTINGS_SUCCESS,
  payload: { settings },
})

export const fetchBriefSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_BRIEFS_SETTINGS_FAILURE,
  error,
})

export const fetchRecommendedPartnersRequest = (): IAction => ({
  type: CONSTANTS.FETCH_RECOMMENDED_PARTNERS_REQUEST,
})

export const fetchRecommendedPartnersSuccess = (partners: IBriefRecommendedPartner[]): IAction => ({
  type: CONSTANTS.FETCH_RECOMMENDED_PARTNERS_SUCCESS,
  payload: { partners },
})

export const fetchRecommendedPartnersFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_RECOMMENDED_PARTNERS_FAILURE,
  error,
})

export const fetchPartnerDetailsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_DETAILS_REQUEST,
})

export const fetchPartnerDetailsSuccess = (partner: IBriefPartnerDetails): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_DETAILS_SUCCESS,
  payload: { partner },
})

export const fetchPartnerDetailsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_PARTNER_DETAILS_FAILURE,
  error,
})

export const fetchCartRequest = (): IAction => ({
  type: CONSTANTS.FETCH_CART_REQUEST,
})

export const fetchCartSuccess = (cart: ICartList): IAction => ({
  type: CONSTANTS.FETCH_CART_SUCCESS,
  payload: { cart },
})

export const fetchCartFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_CART_FAILURE,
  error,
})

export const updateCartRequest = (): IAction => ({
  type: CONSTANTS.UPDATE_CART_REQUEST,
})

export const updateCartSuccess = (): IAction => ({
  type: CONSTANTS.UPDATE_CART_SUCCESS,
})

export const updateCartFailure = (): IAction => ({
  type: CONSTANTS.UPDATE_CART_FAILURE,
})

export const setActiveSinglePartner = (partnerId: number | null): IAction => ({
  type: CONSTANTS.SET_ACTIVE_SINGLE_PARTNER,
  payload: { partnerId },
})

export const setTotalQuantity = (totalQuantity: number): IAction => ({
  type: CONSTANTS.SET_TOTAL_QUANTITY,
  payload: { totalQuantity },
})

export const fetchUploadSettingsRequest = (): IAction => ({
  type: CONSTANTS.FETCH_UPLOAD_SETTINGS_REQUEST,
})

export const fetchUploadSettingsSuccess = (uploadSettings: IUploadSettings[]): IAction => ({
  type: CONSTANTS.FETCH_UPLOAD_SETTINGS_SUCCESS,
  payload: { uploadSettings },
})

export const fetchUploadSettingsFailure = (error: string): IAction => ({
  type: CONSTANTS.FETCH_UPLOAD_SETTINGS_FAILURE,
  error,
})

export const fetchBriefList =
  (params: IBriefTableParams): ThunkAction<Promise<AxiosResponse<IBriefListResponse>>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IBriefListResponse>> => {
    try {
      dispatch(briefListRequest())
      const response = await requestHttp.get<IBriefListResponse>(urls.getTestahelBriefListUrl(), { params })
      const {
        content: { briefs, total },
      } = response.data.data

      const briefList = briefs.map(brief => {
        const productImage = first(brief.productImages) ? first(brief.productImages) : first(brief.allImages)?.src

        return {
          key: brief.id,
          thumb: productImage, // for mobile view QTableCardList
          product: {
            productFirstImage: productImage,
            title: brief.name,
            created: moment(brief.createdAt).format(FORMATS.DATE_LL_FORMAT),
            isExistCouponCode: brief.isExistCouponCode,
            discountText: brief.discountText,
          },
          services: brief.briefTypes
            ? brief.briefTypes
                .sort()
                .reverse()
                .map((elem: string) => {
                  const briefTitle =
                    elem === BRIEF_TYPES.MEDIA_BUYING
                      ? SERVICE_NAMES_FOR_TABLE.MEDIA
                      : brief.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
                      ? SERVICE_NAMES_FOR_TABLE.INSERT
                      : SERVICE_NAMES_FOR_TABLE.PRODUCT
                  return {
                    title: briefTitle,
                    productStartDate: brief.briefStartDate
                      ? moment(brief.briefStartDate).format(FORMATS.DATE_LL_FORMAT)
                      : '',
                    mediaStartDate: brief.mediaStartDate
                      ? moment(brief.mediaStartDate).format(FORMATS.DATE_LL_FORMAT)
                      : '',
                    productDuration: brief.duration,
                    mediaDuration: brief.mediaDuration,
                    quantity: brief.quantity,
                  }
                })
            : [],
          status: { value: brief.status, id: brief.id },
        } as IBriefListElement
      })

      dispatch(briefListSuccess({ results: briefList, total: total }))
      return response
    } catch (error: any) {
      dispatch(briefListFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const fetchViewBriefDetails =
  (id: number): ThunkAction<Promise<AxiosResponse<IViewBriefDetailsResponse>>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IViewBriefDetailsResponse>> => {
    try {
      dispatch(viewBriefRequest())
      const response = await requestHttp.get<IViewBriefDetailsResponse>(urls.getTestahelViewBriefUrl(id))
      const { content } = response.data.data
      dispatch(viewBriefSuccess(content))

      return response
    } catch (error: any) {
      dispatch(viewBriefFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const uploadProductImage =
  (brief: IBrief, files: File[], entity: BRIEF_UPLOAD_TYPES): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      if (!brief.id) {
        return
      }

      dispatch(imageUploadRequest())
      const uploadUrl = urls.getTestahelBriefUploadUrl()

      const response = await uploadTeshahelMedia(uploadUrl, files, brief.id, entity, UPLOAD_ENTITY_KEYS.BRIEF_ID)

      if (response.status === 201) {
        const { content } = response.data.data
        const newBrief = cloneDeep(brief)

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_CUSTOMIZED_IMAGES) {
          newBrief[BRIEF_STEPS.CUSTOMIZED].customizeBoxProductImages = [
            ...newBrief[BRIEF_STEPS.CUSTOMIZED].customizeBoxProductImages,
            ...content,
          ]
        }

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES) {
          newBrief[BRIEF_STEPS.PRODUCT].productImages = [...newBrief[BRIEF_STEPS.PRODUCT].productImages, ...content]
        }

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES) {
          newBrief[BRIEF_STEPS.SERVICES].serviceImages = [...newBrief[BRIEF_STEPS.SERVICES].serviceImages, ...content]
        }

        dispatch(imageUploadSuccess(newBrief))
      }
    } catch (error: any) {
      dispatch(imageUploadFailure(error))
    }
  }

export const uploadStepSaveImage =
  (brief: IBrief, files: File[], params: IUploadPartner, languageType: string): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      if (!brief.id) {
        return
      }

      const name = params.mediaSubType ? params.mediaSubType : params.mediaType
      const key = name + '_' + params.partnerId + '_' + languageType

      dispatch(imageUploadStepRequest(key))

      const uploadUrl = urls.getTestahelBriefUploadUrl()
      const response: any = await uploadTeshahelMedia(
        uploadUrl,
        files,
        brief.id,
        BRIEF_UPLOAD_TYPES.BRIEFS_UPLOAD_IMAGES,
        UPLOAD_ENTITY_KEYS.BRIEF_ID
      )

      if (response.status === 201) {
        const { content } = response.data.data
        const newBrief = cloneDeep(brief)

        const uploadMaterial: any = newBrief[BRIEF_STEPS.UPLOAD].uploadMaterial
        const targetPartner: any = uploadMaterial.find(
          (uploadPartner: IUploadPartner) =>
            uploadPartner.partnerId === params.partnerId &&
            uploadPartner.mediaType === params.mediaType &&
            uploadPartner.mediaSubType === params.mediaSubType
        )

        if (
          params.mediaType === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ||
          params.mediaType === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
        ) {
          if (targetPartner) {
            targetPartner.crImageTargetUrl.push(...content)
          } else {
            uploadMaterial.push({
              partnerId: params.partnerId,
              mediaType: params.mediaType,
              mediaSubType: params.mediaSubType,
              imageTargetUrl: params.imageTargetUrl,
              crImageTargetUrl: content,
              link: null,
              height: params.height,
              width: params.width,
            } as IUploadPartner)
          }
        } else {
          if (targetPartner) {
            if (targetPartner.imageTargetUrl) {
              targetPartner.imageTargetUrl[languageType] = content[0]
            } else {
              targetPartner.imageTargetUrl = {}
              targetPartner.imageTargetUrl[languageType] = content[0]
            }
          } else {
            uploadMaterial.push({
              partnerId: params.partnerId,
              mediaType: params.mediaType,
              mediaSubType: params.mediaSubType,
              imageTargetUrl: {
                [languageType]: content[0],
              },
              crImageTargetUrl: [],
              link: null,
              height: params.height,
              width: params.width,
            } as IUploadPartner)
          }
        }

        dispatch(imageUploadStepSuccess(key, newBrief))
      }
    } catch (error: any) {
      dispatch(imageUploadStepFailure(error))
    }
  }

export const removeImage =
  (
    brief: IBrief,
    file: IUploadFile,
    entity: BRIEF_UPLOAD_TYPES
  ): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse> => {
    try {
      dispatch(imageRemoveRequest())

      const data = {
        briefId: `${brief.id}`,
        entity: entity,
        urls: [file.url],
      }

      const response = await requestHttp.delete(urls.getTestahelBriefRemoveMediaUrl(), { data })

      if (response.status === 201) {
        const newBrief = { ...brief }

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_CUSTOMIZED_IMAGES) {
          newBrief[BRIEF_STEPS.CUSTOMIZED].customizeBoxProductImages.splice(
            newBrief[BRIEF_STEPS.CUSTOMIZED].customizeBoxProductImages.indexOf(file.url),
            1
          )
        }

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES) {
          newBrief[BRIEF_STEPS.PRODUCT].productImages.splice(
            newBrief[BRIEF_STEPS.PRODUCT].productImages.indexOf(file.url),
            1
          )
        }

        if (entity === BRIEF_UPLOAD_TYPES.BRIEFS_SERVICE_IMAGES) {
          newBrief[BRIEF_STEPS.SERVICES].serviceImages = []
        }

        dispatch(imageRemoveSuccess(newBrief))
      }
      return response
    } catch (error: any) {
      dispatch(imageRemoveFailure(error))
      return error
    }
  }

export const removeUploadStepImage =
  (
    brief: IBrief,
    service: any,
    languageType: string,
    url: string = ''
  ): ThunkAction<Promise<AxiosResponse>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse> => {
    try {
      dispatch(imageRemoveRequest())

      const data = {
        briefId: `${brief.id}`,
        entity: BRIEF_UPLOAD_TYPES.BRIEFS_UPLOAD_IMAGES,
        urls: [url ? url : service.imageTargetUrl[languageType]],
      }

      const response = await requestHttp.delete(urls.getBriefRemoveMediaUrl(), { data })

      if (response.status === 201) {
        const newBrief = { ...brief }

        if (url) {
          const name = service.mediaSubType ? service.mediaSubType : service.mediaType
          let target: any = newBrief[BRIEF_STEPS.UPLOAD].uploadMaterial.find((item: any) =>
            item.mediaSubType ? item.mediaSubType === name : item.mediaType === name
          )
          target.crImageTargetUrl.splice(target.crImageTargetUrl.indexOf(url), 1)
        } else {
          let target: any = newBrief[BRIEF_STEPS.UPLOAD].uploadMaterial.find(
            (item: any) => item.imageTargetUrl[languageType] === service.imageTargetUrl[languageType]
          )

          if (target) {
            target.imageTargetUrl[languageType] = null
          }
        }

        dispatch(imageRemoveSuccess(newBrief))
      }
      return response
    } catch (error: any) {
      dispatch(imageRemoveFailure(error))
      return error
    }
  }

export const deleteBrief =
  (
    id: number,
    params: IBriefTableParams
  ): ThunkAction<Promise<AxiosResponse<IDeleteBriefResponse>>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IDeleteBriefResponse>> => {
    try {
      dispatch(deleteBriefRequest())
      const response = await requestHttp.delete<IDeleteBriefResponse>(urls.getDeleteTestahelBriefUrl(id))
      dispatch(deleteBriefSuccess())

      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        dispatch(fetchBriefList(params))
      }

      return response
    } catch (error: any) {
      dispatch(deleteBriefFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const setParamsAndFetch =
  (params: IBriefTableParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setBriefListParams(params))
    dispatch(fetchBriefList(params))
  }

export const createBrief =
  (brief: IBrief): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(createBriefRequest())
      let requestData
      if (
        (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)) ||
        (localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) &&
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN))
      ) {
        requestData = {
          lastFilledStep: BRIEF_STEPS.TYPE,
          ...brief[BRIEF_STEPS.TYPE],
          exclusivePartnerData: localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
            ? sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            : localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID),
          isTestahel: true,
        }
      } else {
        requestData = {
          lastFilledStep: BRIEF_STEPS.TYPE,
          ...brief[BRIEF_STEPS.TYPE],
          isTestahel: true,
        }
      }

      const response = await requestHttp.post<ICreateBriefResponse>(urls.getCreateTestahelBriefUrl(), requestData)

      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data
        const newBrief = { ...brief }
        newBrief.id = content.briefId
        dispatch(createBriefSuccess(newBrief))

        history.push(`/testahel-brief/edit/${content.briefId}`)
      }
    } catch (error: any) {
      dispatch(createBriefFailure(getResponseErrorMessage(error)))
    }
  }

export const updateBrief =
  (step: BRIEF_STEPS, brief: IBrief, totalBudget?: number): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(createBriefRequest())

      const requestData = {
        id: brief.id,
        lastFilledStep: brief.lastFilledStep,
        status: brief.status,
        ...brief[step],
      }

      if (step === BRIEF_STEPS.PARTNER && brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING)) {
        requestData['budgetValue'] = totalBudget
      }

      const response = await requestHttp.patch<IUpdateBriefResponse>(urls.getEditTestahelBriefUrl() + step, requestData)
      const { content } = response.data.data

      const parsedBrief = parseBriefResponse(content)
      parsedBrief.id = brief.id
      dispatch(createBriefPricing(content.printingPrice))
      dispatch(createBriefSuccess(parsedBrief))
    } catch (error: any) {
      dispatch(createBriefFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchEditBrief =
  (id: number): ThunkAction<Promise<AxiosResponse<IViewBriefDetailsResponse>>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IViewBriefDetailsResponse>> => {
    try {
      dispatch(createBriefRequest())
      const response = await requestHttp.get<IViewBriefDetailsResponse>(urls.getTestahelViewBriefUrl(id))
      const { content } = response.data.data

      const parsedBrief = parseBriefResponse(content)
      parsedBrief.id = id
      dispatch(createBriefSuccessExclusivePartnerId(content.exclusivePartnerId))
      dispatch(createBriefPricing(content.printingPrice))
      dispatch(createBriefSuccess(parsedBrief))

      return response
    } catch (error: any) {
      dispatch(createBriefFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const fetchRecommendedPartners =
  (
    briefId: number,
    filter: IPartnerFilter
  ): ThunkAction<Promise<AxiosResponse<IBriefRecommendedPartnerResponse>>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IBriefRecommendedPartnerResponse>> => {
    try {
      const params = { briefId, ...filter }
      dispatch(fetchRecommendedPartnersRequest())

      const response = await requestHttp.get<IBriefRecommendedPartnerResponse>(
        urls.getTestahelBriefRecommendedPartnersUrl(),
        {
          params,
        }
      )
      const { content } = response.data.data

      dispatch(fetchRecommendedPartnersSuccess(content.partners))
      return response
    } catch (error: any) {
      dispatch(fetchRecommendedPartnersFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const fetchPartnerDetails =
  (
    briefId: number,
    partnerId: number
  ): ThunkAction<Promise<AxiosResponse<IBriefPartnerDetailsResponse>>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IBriefPartnerDetailsResponse>> => {
    try {
      dispatch(fetchPartnerDetailsRequest())

      const response = await requestHttp.get<IBriefPartnerDetailsResponse>(
        urls.getTestahelBriefPartnerDetailsUrl(briefId, partnerId)
      )
      const { content } = response.data.data

      dispatch(fetchPartnerDetailsSuccess(content))
      return response
    } catch (error: any) {
      dispatch(fetchPartnerDetailsFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const addToCart =
  (data: ICartList): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
    try {
      dispatch(updateCartRequest())
      const isRejectionFlow = getIsRejectionFlow(getState())
      const url = isRejectionFlow ? urls.getAddToCartLinearUrl() : urls.getAddToCartUrl()
      const requestData: any = cloneDeep(data)
      // requestData.additional.copackingRate = getCopackingPrice(getState())?.rate
      requestData.additional.printingRate = getPrintingPrice(getState())?.rate
      await requestHttp.post(url, requestData)

      dispatch(updateCartSuccess())
    } catch (error: any) {
      dispatch(updateCartFailure())
    }
  }

export const fetchCart =
  (briefId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fetchCartRequest())
      const response = await requestHttp.get(urls.getFetchCartLinearUrl(briefId))
      const { content } = response.data.data

      const cart = content
      cart.additional.items.map((item: ICartItem) => {
        item.isApprovedByUser = !item.services.some(
          (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.REJECTED
        )
        return item
      })

      dispatch(fetchCartSuccess(cart))
    } catch (error: any) {
      dispatch(fetchCartFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchBriefSettings =
  (): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fetchBriefSettingsRequest())
      const response = await requestHttp.get<IBriefSettingsResponse>(urls.getTestahelBriefSettingsUrl())

      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        response.data.data.content.countries.map(ele => {
          ele.city.unshift(
            {
              id: 0,
              name: `Select All ${ele.name} Cities`,
              name_ar: `Select All ${ele.name} Cities`,
              country_id: 0,
              districts: [],
            },
            {
              id: -1,
              name: `Deselect All ${ele.name} Cities`,
              name_ar: `Deselect All ${ele.name} Cities`,
              country_id: 0,
              districts: [],
            }
          )
        })
        const { content } = response.data.data
        dispatch(fetchAgeGroups(content))
      }
    } catch (error: any) {
      dispatch(fetchBriefSettingsFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchAgeGroups =
  (settings: any): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.get(urls.getTestahelAgeGroupsUrl())
      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data
        if (Array.isArray(content)) {
          settings.ageGroups = content
        }

        dispatch(fetchTargetSegments(settings))
      }
    } catch (error: any) {
      dispatch(fetchBriefSettingsFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchTargetSegments =
  (settings: any): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await requestHttp.get(urls.getTestahelTargetSegmentsUrl())
      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data
        if (Array.isArray(content)) {
          settings.targetSegments = content
        }
        settings.countries = settings.countries.filter((ele: any) => ele.name.includes(COUNTRY_NAME.SAUDI_ARABIA))
        dispatch(fetchBriefSettingsSuccess(settings))
      }
    } catch (error: any) {
      dispatch(fetchBriefSettingsFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchPartnerFilterSettings =
  (): ThunkAction<void, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(fetchPartnerFilterSettingsRequest())
      const response = await requestHttp.get<IPartnerFilterSettingsResponse>(urls.getTestahelPartnerFilterSettingsUrl())

      if (response.status === STATUS_CODES.BASE_SUCCESS) {
        const { content } = response.data.data
        dispatch(fetchPartnerFilterSettingsSuccess(content))
      }
    } catch (error: any) {
      dispatch(fetchPartnerFilterSettingsFailure(getResponseErrorMessage(error)))
    }
  }

export const fetchUploadMediaTypes =
  (briefId: number): ThunkAction<Promise<AxiosResponse<void>>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<void>> => {
    try {
      dispatch(fetchUploadSettingsRequest())

      const response = await requestHttp.get(urls.getTestahelBriefUploadMediaUrl(briefId))
      const { content } = response.data.data

      dispatch(fetchUploadSettingsSuccess(content.data))
      return response
    } catch (error: any) {
      dispatch(fetchUploadSettingsFailure(getResponseErrorMessage(error)))
      return error
    }
  }

export const approveBrief =
  (briefId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    try {
      dispatch(createBriefRequest())
      const response: any = await requestHttp.get(urls.getTestahelBriefApproveUrl(briefId))
      if (response?.data?.status === 409 && response?.data?.error?.payload?.invalid) {
      } else {
        const { content } = response.data.data
        const parsedBrief = parseBriefResponse(content)
        parsedBrief.id = briefId
        dispatch(createBriefPricing(content.printingPrice))
        dispatch(createBriefSuccess(parsedBrief))
      }

      return response
    } catch (error: any) {
      dispatch(createBriefFailure(getResponseErrorMessage(error)))
    }
  }

export const sendProposalRequest = (): IAction => ({
  type: CONSTANTS.EMAIL_PROPOSAL_REQUEST,
})

export const sendProposalSuccess = (): IAction => ({
  type: CONSTANTS.EMAIL_PROPOSAL_SUCCESS,
})

export const sendProposalFailure = (error: string): IAction => ({
  type: CONSTANTS.EMAIL_PROPOSAL_FAILURE,
  error,
})

export const emailProposal =
  (briefId: number, data: IProposal, file: jsPDF): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(sendProposalRequest())

      const blob = file.output('blob')
      const bodyFormData = new FormData()
      bodyFormData.append('file', blob)
      data.emails.forEach((email: string) => {
        bodyFormData.append('emails[]', email)
      })
      bodyFormData.append('message', data.message)
      bodyFormData.append('cc', data.cc + '')

      const config = {
        multipartFormData: true,
        skipDownloadProgress: true,
      }

      await requestHttp.post(urls.getTestahelSendEmailProposal(briefId), bodyFormData, config)

      dispatch(sendProposalSuccess())
    } catch (error: any) {
      dispatch(sendProposalFailure(getResponseErrorMessage(error)))
    }
  }

const parseBriefResponse = (brief: IBriefResponse) => {
  const newBrief = cloneDeep(IBriefInitState)
  newBrief.status = brief.status

  newBrief.lastFilledStep = brief.lastFilledStep ? brief.lastFilledStep : BRIEF_STEPS.TYPE
  newBrief[BRIEF_STEPS.TYPE].briefTypes = brief.briefTypes ? brief.briefTypes : []

  newBrief[BRIEF_STEPS.CUSTOMIZED] = {
    customizeBoxBriefName: brief.customizeBoxBriefName,
    customizeBoxDistributionCount: brief.customizeBoxDistributionCount,
    customizeBoxProductCount: brief.customizeBoxProductCount,
    customizeBoxProductType: brief.customizeBoxProductType ? brief.customizeBoxProductType : [],
    customizeBoxStartDate: brief.customizeBoxStartDate ? moment(brief.customizeBoxStartDate) : null,
    customizeBoxProductImages: brief.customizeBoxProductImages ? brief.customizeBoxProductImages : [],
    customizeBoxAdditionalComment: brief.customizeBoxAdditionalComment,
  } as ICustomizedStep

  newBrief[BRIEF_STEPS.PRODUCT] = {
    name: brief.name,
    quantity: brief.quantity,
    distributionItem: brief.distributionItem,
    productType: brief.productType,
    briefStartDate: brief.briefStartDate ? moment(brief.briefStartDate) : null,
    duration: brief.duration,
    productImages: brief.productImages ? brief.productImages : [],
    instructions: brief.instructions,
  } as IProductStep

  newBrief[BRIEF_STEPS.TARGETING] = {
    targetFocus: brief.targetFocus ? brief.targetFocus : [],
    targetAgeGroups: brief.targetAgeGroups ? brief.targetAgeGroups : [],
    targetSegments: brief.targetSegments ? brief.targetSegments : [],
    targetInterests: brief.targetInterests ? brief.targetInterests : [],
    targetCountries: brief.targetCountries ? brief.targetCountries : [],
    targetCities: brief.targetCities ? brief.targetCities : [],
    targetDistricts: brief.targetDistricts ? brief.targetDistricts : [],
  } as ITargetingStep

  newBrief[BRIEF_STEPS.SERVICES] = {
    servicePackageType: brief.servicePackageType ? brief.servicePackageType : [],
    serviceIsLiquid: brief.serviceIsLiquid || false,
    serviceIncludingCoupon: brief.serviceIncludingCoupon || false,
    serviceDesign: brief.serviceDesign || false,
    servicePrinting: brief.servicePrinting || false,
    // servicePackaging: brief.servicePackaging || false,
    // servicePackagingNeeds: brief.servicePackagingNeeds,
    // servicePackagingQuantity: brief.servicePackagingQuantity,
    serviceImages: brief.serviceImages ? brief.serviceImages : [],
    // hasFreeSample: brief.hasFreeSample || false,
    printingPriceId: brief.printingPriceId,
    serviceTemperature: brief.serviceTemperature,
  } as IServicesStep

  newBrief[BRIEF_STEPS.MEDIA_BUY] = {
    mediaStartDate: brief.mediaStartDate ? moment(brief.mediaStartDate) : null,
    mediaDuration: brief.mediaDuration,
    mediaTypes: brief.mediaTypes ? brief.mediaTypes : [],
    marketingObjective: brief.marketingObjective,
    budgetCurrency: brief.budgetCurrency,
    budgetType: brief.budgetType,
    budgetValue: brief.budgetValue,
  } as IMediaBuyStep

  newBrief[BRIEF_STEPS.CONSUMER_RESEARCH] = {
    consumerResearchStartDate: brief.consumerResearchStartDate ? moment(brief.consumerResearchStartDate) : null,
    // consumerResearchDuration: brief.consumerResearchDuration,
    consumerResearchMediaType: brief.consumerResearchMediaType ? brief.consumerResearchMediaType : [],
    consumerResearchObjective: brief.consumerResearchObjective,
    consumerResearchNotes: brief.consumerResearchNotes,
  } as IConsumerResearchStep

  newBrief[BRIEF_STEPS.UPLOAD] = {
    uploadMaterial: brief.uploadMaterial,
  } as IUploadStep

  return newBrief
}

// export const fetchExclusivePartnerDetails =
//   (id: number): ThunkAction<Promise<AxiosResponse<IViewBriefDetailsResponse>>, {}, {}, AnyAction> =>
//     async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<AxiosResponse<IViewBriefDetailsResponse>> => {
//       try {
//         const response = await requestHttp.get<IViewBriefDetailsResponse>(urls.getExclusivePartnerUrl(id))
//         return response?.data?.data?.content?.publicProfile?.companyLogo
//       } catch (error: any) {
//         dispatch(viewBriefFailure(getResponseErrorMessage(error)))
//         return error
//       }
//     }

// export const consumerResearchSendEmail =
//   (invited: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
//     async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
//       try {
//         await requestHttp.put(invited ? urls.postConsumerResearchInvitedClientUrl() : urls.postConsumerResearchEmailUrl(), {
//           type: 'consumer research',
//         })
//       } catch (error: any) {
//         return error
//       }
//     }
