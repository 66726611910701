import React from 'react'
import { IconsCommon, QTable } from 'quantum_components'
import { useSelector } from 'react-redux'
import { getOpenRequests, getOpenRequestsLoading } from '../../dashboard.selectors'
import { IRequestTable } from 'src/modules/partner/requests/request.types'
import { MEDIA_GROUP_TYPE } from 'src/constants/brief'
import { requestStatusesLabel, REQUEST_STATUS } from 'src/constants/request'
import { useHistory } from 'react-router-dom'
import { renderRoundedNumber } from '../../../../../helpers/currency'
import { getCurrency } from '../../../../core/core.selectors'

export default function RequestsTable() {
  const dataRequests = useSelector(getOpenRequests)
  const openRequestsLoading = useSelector(getOpenRequestsLoading)
  const currency = useSelector(getCurrency)

  const history = useHistory()

  const columnsRequests = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      width: '30%',
      fixed: 'left',
      // sorter: (a, b) => a.product.title.length - b.product.title.length,
      render: (product: any, request: IRequestTable) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              {product.image && <img src={product.image} alt={product.title} />}
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.title}</h3>
              <div className="q-table-product_created">
                {/* need add class "text-primary" only for REVIEW NOW status */}
                <span className={request.status === REQUEST_STATUS.REVIEW_NOW ? 'text-primary' : ''}>
                  Received {product.received}
                </span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Services',
      dataIndex: 'service',
      key: 'service',
      width: '20%',
      render: (service: any) => (
        <ul className="q-table-list">
          <li className="q-table-list_item font-weight-500">
            <span className="mr-10">
              {service.title === MEDIA_GROUP_TYPE.MEDIA_BUY ? <IconsCommon.IconDevices /> : <IconsCommon.IconSample />}
            </span>
            {service.title}
          </li>
        </ul>
      ),
    },
    {
      title: 'Start & Duration',
      dataIndex: 'service',
      key: 'service',
      width: '20%',
      render: (service: any) => (
        <div>
          <div>{service.startDate}</div>
          <div>{service.duration}</div>
        </div>
      ),
    },
    {
      title: 'Earnings Potential',
      dataIndex: 'service',
      key: 'service',
      width: '15%',
      render: (service: any) => {
        return service.revenue ? (
          <span className="font-size-14 font-weight-600">{service.revenue}</span>
        ) : (
          <span>-</span>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status: REQUEST_STATUS) => (
        <span className={`status-label ${requestStatusesLabel[status] && requestStatusesLabel[status].classStyle}`}>
          {requestStatusesLabel[status] ? requestStatusesLabel[status].label : REQUEST_STATUS.REVIEW_NOW}
        </span>
      ),
    },
  ]
  const handleOnRowClick = (record: IRequestTable, rowIndex: any) => ({
    onClick: (event: any) => history.push(`/requests/${record.key}`),
  })
  return (
    <div className="dashboard-table-wrapper">
      <QTable
        //@ts-ignore
        onRow={handleOnRowClick}
        loading={openRequestsLoading}
        columns={columnsRequests}
        dataSource={dataRequests}
        rowClassName="cursorPointer"
      />
    </div>
  )
}
