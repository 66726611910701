import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useReduxDispatch } from 'src/helpers'
import { isMobile } from 'react-device-detect'
import * as actions from '../request.actions'
import * as partnerActions from '../../partnerProfile/Profile.actions'
import { Col, QAlert, QBackLink, Row } from 'quantum_components'
import { useSelector } from 'react-redux'
import { getRequestDetails, getRequestDetailsLoading } from '../request.selectors'
import { BRIEF_TYPES, DISTRIBUTION_TYPE, MEDIA_GROUP_TYPE, SERVICE_NAMES_FOR_TABLE } from '../../../../constants/brief'
import { ALERTS_MSG } from '../../../../constants/alerts'
import BriefSwitch from '../../../brief/containers/components/Brief.Switch'
import BriefCartSummary from '../../../brief/containers/components/Brief.CartSummary'
import ServiceCard from '../../../brief/containers/components/Brief.Service'
import BriefProduct from '../../../brief/containers/components/Brief.Product'
import BriefMedia from '../../../brief/containers/components/Brief.Media'
import BriefTargeting from '../../../brief/containers/components/Brief.Targeting'
import BriefAdditional from '../../../brief/containers/components/Brief.Additional'
import BriefViewSummary from '../../../brief/containers/components/Brief.View.Summary'
import SpinnerCenter from '../../../../components/Spinner/spinner'
import RequestForm from './components/RequestAside.Form'
import { REQUEST_AWAITING_DAYS, REQUEST_STATUS } from '../../../../constants/request'
import { daysPastFromToday, getDaysAgoText } from '../../../../helpers/datesHelper'
import moment from 'moment'
import { FORMATS } from '../../../../constants'
import { calcDistributionQuantity } from '../../../../helpers/cart'
import * as campaignActions from '../../../campaign/campaign.actions'
import { TABS_NAMES } from '../../../../constants/tabs'

export default function RequestDetailsContainer(): ReactElement {
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const requestDetails = useSelector(getRequestDetails)
  const requestDetailsLoading = useSelector(getRequestDetailsLoading)

  const [showSummary, setShowSummary] = useState(false)
  const { id } = useParams<{id: any}>()

  useEffect(() => {
    dispatch(actions.fetchRequestDetails(id))
    dispatch(partnerActions.fetchPartnerProfileSettings())
  }, [])

  const changeSwitch = (e: any) => {
    const { value } = e.target
    setShowSummary(value)
  }

  if (!requestDetails || requestDetailsLoading) {
    return <SpinnerCenter />
  }

  const brief = requestDetails.brief
  const cart = requestDetails.cart

  const partnerQuantity = calcDistributionQuantity(cart.additional.items)
  const isProductSample = brief.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE)
  const isMediaBuying = brief.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
  const distributionItem =
    brief.distributionItem === DISTRIBUTION_TYPE.PRINTED_INSERT
      ? SERVICE_NAMES_FOR_TABLE.INSERT
      : SERVICE_NAMES_FOR_TABLE.PRODUCT

  const isExpired = requestDetails.status === REQUEST_STATUS.EXPIRED
  const isReviewNow = requestDetails.status === REQUEST_STATUS.REVIEW_NOW

  const isRequestMediaBuy = requestDetails.type === MEDIA_GROUP_TYPE.MEDIA_BUY
  const isRequestDistribution =
    requestDetails.type === MEDIA_GROUP_TYPE.PRINTED_INSERT || requestDetails.type === MEDIA_GROUP_TYPE.PRODUCT_SAMPLE

  const summaryTitle = `Received from ${requestDetails.briefOwner?.companyName || ''}`

  const summarySubtitle = () => {
    const dateMoment = moment(requestDetails.approvedDate)
    const approvedDate = moment(requestDetails.approvedDate).format(FORMATS.DATE_LL_FORMAT)
    const days = daysPastFromToday(dateMoment)
    const text = getDaysAgoText(days)

    return approvedDate + '. ' + text
  }

  const respondDays = () => {
    const date = moment(requestDetails.approvedDate)
    const daysLeft = REQUEST_AWAITING_DAYS - daysPastFromToday(date)
    return daysLeft < 0 ? 0 : daysLeft
  }
  const handleBack = () => {
    dispatch(campaignActions.setActiveTab(TABS_NAMES.REQUESTS))
    history.goBack()
  }

  const alertMessage = isExpired
    ? 'This Request is Expired.'
    : ALERTS_MSG.ALERT_REQUEST_REVIEW_NOW + ` You have ${respondDays()} days to respond.`

  return (
    <div className="dashboard-layout qu-brief-view small-mobile">
      <QBackLink title={'Back to Requests'} onClick={handleBack} />

      {(isReviewNow || isExpired) && (
        <div className="pt-40">
          <QAlert message={alertMessage} type="warning" className="centered" />
        </div>
      )}
      {isMobile ? (
        <Fragment>
          <BriefSwitch onChange={changeSwitch} value={showSummary} />
          {showSummary ? (
            <Fragment>
              <BriefCartSummary
                setCurrentStep={() => null}
                cart={cart}
                onEdit={() => null}
                viewMode
                hideProposalButtons
              />
              <RequestForm requestId={id} />
            </Fragment>
          ) : (
            <Fragment>
              <ServiceCard
                isProductSample={isProductSample}
                isMediaBuying={isMediaBuying}
                distributionItem={distributionItem}
                onEdit={() => null}
                requestType={requestDetails.type}
                viewMode
              />
              <BriefProduct
                briefView={brief}
                onEdit={() => null}
                viewMode
                requestType={requestDetails.type}
                partnerQuantity={partnerQuantity}
              />
              {isRequestMediaBuy && (
                <BriefMedia briefView={brief} onEdit={() => null} partnerIds={[requestDetails.partnerId]} viewMode />
              )}
              <BriefTargeting briefView={brief} onEdit={() => null} viewMode />
              {isRequestDistribution && <BriefAdditional briefView={brief} onEdit={() => null} viewMode />}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Row gutter={20} className="pt-20">
          <Col span={16}>
            <BriefViewSummary
              briefView={brief}
              setCurrentStep={() => null}
              title={summaryTitle}
              subtitle={summarySubtitle()}
              partnerQuantity={partnerQuantity}
              viewMode
              requestType={requestDetails.type}
              partnerIds={[requestDetails.partnerId]}
            />
          </Col>
          <Col span={8}>
            <BriefCartSummary
              setCurrentStep={() => null}
              cart={cart}
              onEdit={() => null}
              viewMode
              hideProposalButtons
            />
            <RequestForm requestId={id} />
          </Col>
        </Row>
      )}
    </div>
  )
}
