import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from 'react'
import { Col, Form, QButton, QInput, Row } from 'quantum_components'
import { IBrief, ICartServiceItem, IUploadPartner, IUploadSettings } from '../../testahelBox.types'
import { useSelector } from 'react-redux'
import { getUploadSettings, getUploadStepLoading } from '../../testahelBox.selectors'
import { REGEXES } from '../../../../../constants'
import { isMobile } from 'react-device-detect'
import { ReactComponent as LogOut } from '../../../../../assets/icons/logout-icon-alt.svg'
import {
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  BRIEF_TYPES,
  CONSUMER_RESEARCH_MEDIA_TYPES,
  MEDIA_TYPES,
} from '../../../../../constants/testahel_brief'
import TestahelPopoverSummary from '../components/TestahelBrief.PopoverSummary'
import UploadComponent from '../../../../../components/UploadComponent'
import { actions } from '../../testahelBox.module'
import { useReduxDispatch } from '../../../../../helpers'
import IUploadFile from '../../../../../interfaces/IUploadFile'
import { getFileNameFromUrl } from '../../../../../utils'
import { IMediaBuyTypesElement } from '../../../../partner/partnerProfile/profile.types'
import { VALIDATION_MESSAGE } from '../../../../../constants/validationMessages'
import { ADVERTISEMENT_LANGUAGES } from 'src/constants/profile'

interface IProps {
  brief: IBrief
  onChange: (uploadFields: IUploadPartner[]) => void
  onFinishLater: () => void
  disabledFinishLater: boolean
  skipUploadStep: boolean
  showFinalSummary: () => void
}

const TestahelUploadForm = forwardRef(
  (
    { brief, onChange, onFinishLater, disabledFinishLater, skipUploadStep, showFinalSummary }: IProps,
    ref
  ): ReactElement => {
    const dispatch = useReduxDispatch()
    const uploadSettings = useSelector(getUploadSettings)
    const imageLoadingList = useSelector(getUploadStepLoading)
    const [form] = Form.useForm()
    const [errors, setErrors] = useState({})
    const [tableKey, setTableKey] = useState(0)

    const uploadFields = brief[BRIEF_STEPS.UPLOAD].uploadMaterial

    useEffect(() => {
      if (skipUploadStep) {
        showFinalSummary()
      }
      uploadFields.forEach(item => validate(item))
    }, [])

    useImperativeHandle(ref, () => ({
      validateFields() {
        return Object.values(errors).some(error => !!error)
      },
    }))

    const getObjectKey = (object: IUploadPartner) => {
      const name = object.mediaSubType ? object.mediaSubType : object.mediaType
      return name + '_' + object.partnerId
    }

    const validate = (object: IUploadPartner) => {
      const newErrors = { ...errors }
      const key = getObjectKey(object)

      if (!object.link) {
        //@ts-ignore
        newErrors[key] = ''
        setErrors(newErrors)

        return
      }

      const isValid = object.link.match(REGEXES.URL)

      //@ts-ignore
      newErrors[key] = isValid ? '' : VALIDATION_MESSAGE.WEBSITE_URL
      setErrors(newErrors)
    }

    const fetchUploadMedia = async () => {
      if (!brief.id) {
        return
      }
      await dispatch(actions.fetchUploadMediaTypes(brief.id))
    }

    useEffect(() => {
      if (
        brief.BRIEF_TYPE.briefTypes.length === 1 &&
        (brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX) ||
          brief.BRIEF_TYPE.briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE))
      ) {
      } else fetchUploadMedia()
    }, [])

    const getFileObject = (service: IUploadPartner, languageType: string, index = 0) => {
      const targetMaterial: any = uploadFields.find(
        (item: IUploadPartner) =>
          item.partnerId === service.partnerId &&
          item.mediaType === service.mediaType &&
          item.mediaSubType === service.mediaSubType
      )
      const name = service.mediaSubType ? service.mediaSubType : service.mediaType
      if (name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY || name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE) {
        if (!targetMaterial || !targetMaterial.crImageTargetUrl.length || !targetMaterial.crImageTargetUrl[index]) {
          return null
        }
        return {
          uid: service.partnerId + name + Math.random(),
          name: getFileNameFromUrl(targetMaterial.crImageTargetUrl[index]),
          status: 'done',
          url: targetMaterial.crImageTargetUrl[index],
        } as IUploadFile
      } else {
        if (!targetMaterial || !targetMaterial.imageTargetUrl || !targetMaterial.imageTargetUrl[languageType]) {
          return null
        }
        return {
          uid: service.partnerId + name,
          name: getFileNameFromUrl(targetMaterial.imageTargetUrl[languageType]),
          status: 'done',
          url: targetMaterial.imageTargetUrl[languageType],
        } as IUploadFile
      }
    }

    const formBuilder = () => {
      const data = [] as IUploadPartner[]
      uploadSettings.forEach((setting: IUploadSettings) => {
        const mediaTypes = setting.mediaTypes

        setting.services.forEach((service: ICartServiceItem) => {
          if (!service.type || service.type === MEDIA_TYPES.DISTRIBUTION) {
            return
          }

          const target = uploadFields.find((item: IUploadPartner) => {
            const itemName = item.mediaSubType ? item.mediaSubType : item.mediaType
            return item.partnerId === setting.partnerId && itemName === service.name
          })

          if (target) {
            data.push(target)
          } else {
            const mediaBuyType = mediaTypes.find((media: IMediaBuyTypesElement) => {
              const name = media.subName ? media.subName : media.name
              return name === service.name
            })
            data.push({
              partnerId: setting.partnerId,
              mediaType: mediaBuyType ? mediaBuyType.name : service.name,
              mediaSubType: mediaBuyType ? mediaBuyType.subName : service.name,
              imageTargetUrl: {
                arabic: null,
                english: null,
              },
              crImageTargetUrl: [],
              link: null,
              adTitle: {
                arabic: null,
                english: null,
              },
              adDescription: {
                arabic: null,
                english: null,
              },
              width: mediaBuyType?.width ? mediaBuyType?.width : 800,
              height: mediaBuyType?.height ? mediaBuyType?.height : 600,
            } as IUploadPartner)
          }
        })
      })

      return data
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, service: IUploadPartner) => {
      let fields = [...uploadFields]
      const { value } = e.target

      const target = fields.find(
        (item: IUploadPartner) =>
          item.partnerId === service.partnerId &&
          item.mediaType === service.mediaType &&
          item.mediaSubType === service.mediaSubType
      )

      if (!target) {
        return
      }

      target.link = value

      validate(target)
      onChange(fields)
    }

    const handleRemove = async (service: IUploadPartner, languageType: string, f: any = null) => {
      if (f) {
        await dispatch(
          actions.removeUploadStepImage(
            brief,
            service,
            languageType,
            service.crImageTargetUrl.filter(n => n === f.url)[0]
          )
        )
      } else {
        await dispatch(actions.removeUploadStepImage(brief, service, languageType))
      }
    }

    const handleUpload = async (
      uploads: File[],
      params: any,
      languageType: string,
      isSurveyChallenge: boolean = false
    ) => {
      if (isSurveyChallenge) {
        if (uploads.length === 0) {
          setTableKey(tableKey + 1)
          return
        } else if (
          params.mediaType === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ||
          params.mediaType === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
        ) {
          if (uploads.length > 5 || params.crImageTargetUrl.length + uploads.length > 5) {
            setTableKey(tableKey + 1)
            return
          }
        }
      }

      if (!isSurveyChallenge && params.imageTargetUrl && params.imageTargetUrl[languageType]) {
        await handleRemove(params, languageType)
      }

      dispatch(actions.uploadStepSaveImage(brief, uploads, params, languageType))
    }

    const handleInputFieldChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      service: IUploadPartner,
      objectname: string,
      fieldName: string
    ) => {
      let fields = [...uploadFields]
      const { value } = e.target

      const target: any = fields.find(
        (item: IUploadPartner) =>
          item.partnerId === service.partnerId &&
          item.mediaType === service.mediaType &&
          item.mediaSubType === service.mediaSubType
      )

      if (!target) {
        return
      }

      if (target[objectname]) {
        target[objectname][fieldName] = value
      } else {
        target[objectname] = {
          english: null,
          arabic: null,
        }
        target[objectname][fieldName] = value
      }
      onChange(fields)
    }

    const data = formBuilder()

    return (
      <div className="qu-brief-content brief-step">
        <div className="brief-step-header only-desktop">
          <h1>Upload your media</h1>
          <p className="mb-0">
            Make sure to upload separate media and fill in separate title/description for each language
          </p>
          <p>Media not ready? You can always upload later.</p>
        </div>
        <Form form={form} layout="vertical" hideRequiredMark>
          <div className={`form form-brief form--horizontal`}>
            {isMobile && (
              <div className="form__head">
                <div className="form__number">
                  <span>{BRIEF_STEPS_NAMES[BRIEF_STEPS.UPLOAD]}</span>
                </div>
                <h3>Upload your media</h3>
                <p>Make sure to upload separate media and fill in separate title/description for each language</p>
                <p>Media not ready? You can always upload later.</p>
              </div>
            )}
            <TestahelPopoverSummary brief={brief} />
            <div className="form__cont">
              {uploadSettings.map((settings: IUploadSettings, index: number) => {
                const isLast = uploadSettings.length - 1 === index
                const services = data.filter((item: IUploadPartner) => item.partnerId === settings.partnerId)
                if (!services.length) {
                  return null
                }
                return (
                  <React.Fragment key={settings.partnerId}>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={10}>
                        <div className="qu-brand-label">
                          <div className="qu-brand-logo">
                            {settings.companyLogoUrl && (
                              <img src={settings.companyLogoUrl} alt={settings.companyName} />
                            )}
                          </div>
                          <div className="qu-brand-info">
                            <h4 className="qu-brand-name">{settings.companyName}</h4>
                            <p className="qu-brand-subtitle text-truncate">{settings.companyWebsite}</p>
                            <p className="qu-brand-subtitle text-nowrap">
                              Ad language(s) supported:{' '}
                              {settings.advertisementLanguage.length
                                ? settings.advertisementLanguage.join(', ')
                                : 'english'}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={14}>
                        {services.map((service: IUploadPartner, index1: number) => {
                          const isLast = services.length - 1 === index1
                          const key = getObjectKey(service)
                          //@ts-ignore
                          const error = errors[key]
                          const name = service.mediaSubType ? service.mediaSubType : service.mediaType

                          const serviceValidations = settings.mediaTypes.find(
                            ele => (ele.subName ? ele.subName : ele.name) === name
                          )

                          const caption =
                            service.mediaType === MEDIA_TYPES.HOME_BANNERS ||
                            service.mediaType === MEDIA_TYPES.SOCIAL_MEDIA
                              ? [
                                  `${service.width}px by ${service.height}px image size recommended.`,
                                  'Max file size 1MB in JPG, JPEG, or PNG format.',
                                ]
                              : undefined
                          return (
                            <React.Fragment key={name}>
                              <p className="form-brief-subtitle mb-15">{name.replace(/CR /g, '')}</p>
                              {(service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
                                service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE &&
                                settings.advertisementLanguage.length === 0) ||
                                (settings.advertisementLanguage.includes(ADVERTISEMENT_LANGUAGES.ENGLISH) &&
                                  (name === MEDIA_TYPES.FEED ? (
                                    <Form.Item>
                                      <UploadComponent
                                        files={
                                          service.imageTargetUrl.english ? [getFileObject(service, 'english')] : []
                                        }
                                        onChange={(f: File[]) => handleUpload(f, service, 'english')}
                                        onRemove={() => handleRemove(service, 'english')}
                                        disabled={imageLoadingList.length > 0}
                                        isUploading={imageLoadingList.includes(key + '_english')}
                                        heading={'Upload English Advertisement Video'}
                                        caption={['File accepted: WEBM, OGG, MP4', 'Max file size 1GB.']}
                                        accept=".webm, .ogg, .mp4"
                                        isVideo={true}
                                      />
                                    </Form.Item>
                                  ) : (
                                    <Form.Item>
                                      <UploadComponent
                                        files={
                                          service.imageTargetUrl.english ? [getFileObject(service, 'english')] : []
                                        }
                                        onChange={(f: File[]) => handleUpload(f, service, 'english')}
                                        onRemove={() => handleRemove(service, 'english')}
                                        disabled={imageLoadingList.length > 0}
                                        isUploading={imageLoadingList.includes(key + '_english')}
                                        caption={caption}
                                        heading={'Upload English Advertisement Image'}
                                      />
                                    </Form.Item>
                                  )))}

                              {service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
                                service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE &&
                                settings.advertisementLanguage.includes(ADVERTISEMENT_LANGUAGES.ARABIC) &&
                                (name === MEDIA_TYPES.FEED ? (
                                  <Form.Item>
                                    <UploadComponent
                                      files={service.imageTargetUrl.arabic ? [getFileObject(service, 'arabic')] : []}
                                      onChange={(f: File[]) => handleUpload(f, service, 'arabic')}
                                      onRemove={() => handleRemove(service, 'arabic')}
                                      disabled={imageLoadingList.length > 0}
                                      isUploading={imageLoadingList.includes(key + '_arabic')}
                                      heading={'Upload Arabic Advertisement Video'}
                                      caption={['File accepted: WEBM, OGG, MP4', 'Max file size 1GB.']}
                                      accept=".webm, .ogg, .mp4"
                                      isVideo={true}
                                    />
                                  </Form.Item>
                                ) : (
                                  <Form.Item>
                                    <UploadComponent
                                      files={service.imageTargetUrl.arabic ? [getFileObject(service, 'arabic')] : []}
                                      onChange={(f: File[]) => handleUpload(f, service, 'arabic')}
                                      onRemove={() => handleRemove(service, 'arabic')}
                                      disabled={imageLoadingList.length > 0}
                                      isUploading={imageLoadingList.includes(key + '_arabic')}
                                      caption={caption}
                                      heading={'Upload Arabic Advertisement Image'}
                                    />
                                  </Form.Item>
                                ))}
                              {(service.mediaType === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ||
                                service.mediaType === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE) && (
                                <Form.Item>
                                  <UploadComponent
                                    key={tableKey + Math.random()}
                                    defaultFileList={service.crImageTargetUrl
                                      .filter(n => n)
                                      .map((ele, ind) => getFileObject(service, '', ind))}
                                    files={service.crImageTargetUrl
                                      .filter(n => n)
                                      .map((ele, ind) => getFileObject(service, '', ind))}
                                    onChange={(f: File[]) => handleUpload(f, service, 'surveyChallenge', true)}
                                    onRemove={(f: any) => handleRemove(service, 'arabic', f)}
                                    disabled={imageLoadingList.length > 0}
                                    isUploading={imageLoadingList.includes(key + '_arabic')}
                                    caption={caption}
                                    heading={'Upload upto 5 images'}
                                    multiple={true}
                                    maxCount={5}
                                    isTestahel={true}
                                  />
                                </Form.Item>
                              )}

                              {service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
                                service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE && (
                                  <Form.Item className="mb-0">
                                    <QInput
                                      disabled={!service?.imageTargetUrl?.english && !service?.imageTargetUrl?.arabic}
                                      value={service.link}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputChange(e, service)
                                      }
                                      placeholder="Enter target URL"
                                      size="large"
                                    />
                                  </Form.Item>
                                )}
                              {!!error && <div className="text-red font-size-12 mt-5 mb-10">{error}</div>}

                              {service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
                                service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE && (
                                  <Row gutter={32} className="mt-20">
                                    {(settings.advertisementLanguage.length === 0 ||
                                      settings.advertisementLanguage.includes(ADVERTISEMENT_LANGUAGES.ENGLISH)) && (
                                      <Col span={12}>
                                        <Form.Item
                                          className="mb-0"
                                          rules={
                                            serviceValidations?.adTitleCharacterLimit
                                              ? [
                                                  {
                                                    max: serviceValidations?.adTitleCharacterLimit,
                                                    whitespace: true,
                                                    message: `Title should not be greater than ${serviceValidations?.adTitleCharacterLimit} characters.`,
                                                  },
                                                ]
                                              : []
                                          }
                                          validateTrigger={['onBlur', 'onChange']}
                                          name={`titleEnglish${index}${index1}`}
                                        >
                                          <QInput
                                            disabled={!service.imageTargetUrl.english}
                                            defaultValue={service?.adTitle?.english}
                                            value={service?.adTitle?.english}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                              handleInputFieldChange(e, service, 'adTitle', 'english')
                                            }
                                            placeholder="Enter Ad title in English"
                                            size="large"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {settings.advertisementLanguage.includes(ADVERTISEMENT_LANGUAGES.ARABIC) && (
                                      <Col span={12}>
                                        <Form.Item
                                          className="mb-0"
                                          rules={
                                            serviceValidations?.adTitleCharacterLimit
                                              ? [
                                                  {
                                                    max: serviceValidations?.adTitleCharacterLimit,
                                                    whitespace: true,
                                                    message: `Title should not be greater than ${serviceValidations?.adTitleCharacterLimit} characters.`,
                                                  },
                                                  {
                                                    pattern: REGEXES.ARABIC_CHARACTERS_NUMBERS_SPACES,
                                                    message:
                                                      'Title should accept only Arabic language, numbers, & spaces.',
                                                  },
                                                ]
                                              : [
                                                  {
                                                    pattern: REGEXES.ARABIC_CHARACTERS_NUMBERS_SPACES,
                                                    message:
                                                      'Title should accept only Arabic language, numbers, & spaces.',
                                                  },
                                                ]
                                          }
                                          validateTrigger={['onBlur', 'onChange']}
                                          name={`titleArabic${index}${index1}`}
                                        >
                                          <QInput
                                            disabled={!service.imageTargetUrl.arabic}
                                            defaultValue={service?.adTitle?.arabic}
                                            value={service?.adTitle?.arabic}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                              handleInputFieldChange(e, service, 'adTitle', 'arabic')
                                            }
                                            placeholder="Enter Ad title in Arabic"
                                            size="large"
                                            className="arabicLanguage"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                  </Row>
                                )}

                              {service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
                                service.mediaType !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE && (
                                  <Row gutter={32} className="mt-20">
                                    {(settings.advertisementLanguage.length === 0 ||
                                      settings.advertisementLanguage.includes(ADVERTISEMENT_LANGUAGES.ENGLISH)) && (
                                      <Col span={12}>
                                        <Form.Item
                                          className="mb-0"
                                          rules={
                                            serviceValidations?.adDescriptionCharacterLimit
                                              ? [
                                                  {
                                                    max: serviceValidations?.adDescriptionCharacterLimit,
                                                    whitespace: true,
                                                    message: `Description should not be greater than ${serviceValidations?.adDescriptionCharacterLimit} characters.`,
                                                  },
                                                ]
                                              : []
                                          }
                                          validateTrigger={['onBlur', 'onChange']}
                                          name={`descriptionEnglish${index}${index1}`}
                                        >
                                          <QInput
                                            disabled={!service.imageTargetUrl.english}
                                            defaultValue={service?.adDescription?.english}
                                            value={service?.adDescription?.english}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                              handleInputFieldChange(e, service, 'adDescription', 'english')
                                            }
                                            placeholder="Enter Ad description in English"
                                            size="large"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {settings.advertisementLanguage.includes(ADVERTISEMENT_LANGUAGES.ARABIC) && (
                                      <Col span={12}>
                                        <Form.Item
                                          className="mb-0"
                                          rules={
                                            serviceValidations?.adDescriptionCharacterLimit
                                              ? [
                                                  {
                                                    max: serviceValidations?.adDescriptionCharacterLimit,
                                                    whitespace: true,
                                                    message: `Description should not be greater than ${serviceValidations?.adDescriptionCharacterLimit} characters.`,
                                                  },
                                                  {
                                                    pattern: REGEXES.ARABIC_CHARACTERS_NUMBERS_SPACES,
                                                    message:
                                                      'Description should accept only Arabic language, numbers, & spaces.',
                                                  },
                                                ]
                                              : [
                                                  {
                                                    pattern: REGEXES.ARABIC_CHARACTERS_NUMBERS_SPACES,
                                                    message:
                                                      'Description should accept only Arabic language, numbers, & spaces.',
                                                  },
                                                ]
                                          }
                                          validateTrigger={['onBlur', 'onChange']}
                                          name={`descriptionArabic${index}${index1}`}
                                        >
                                          <QInput
                                            disabled={!service.imageTargetUrl.arabic}
                                            defaultValue={service?.adDescription?.arabic}
                                            value={service?.adDescription?.arabic}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                              handleInputFieldChange(e, service, 'adDescription', 'arabic')
                                            }
                                            placeholder="Enter Ad description in Arabic"
                                            size="large"
                                            className="arabicLanguage"
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                  </Row>
                                )}

                              {!isLast && <hr className="form__hr" />}
                            </React.Fragment>
                          )
                        })}
                      </Col>
                    </Row>
                    {!isLast && <hr className="form__hr" />}
                  </React.Fragment>
                )
              })}
              {isMobile && (
                <div className="form-brief-footer">
                  <QButton
                    disabled={disabledFinishLater}
                    onClick={onFinishLater}
                    className="qu-button-outline mt-30 full-width"
                  >
                    Save & Finish Later <LogOut className="ml-10" />
                  </QButton>
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
    )
  }
)

export default TestahelUploadForm
