const MODULE_NAME = 'brief'

export const getCreateBriefUrl = (): string => `${MODULE_NAME}/create`

export const getV3EditBriefUrl = (): string => `${MODULE_NAME}/edit`

export const getEditBriefUrl = (): string => `${MODULE_NAME}/edit-step/`

export const getBriefListUrl = (): string => `${MODULE_NAME}/list`

export const getDeleteBriefUrl = (id: number): string => `${MODULE_NAME}/delete/${id}`

export const getV3DeleteBriefUrl = (id: number): string => `${MODULE_NAME}/delete-sku/${id}`

export const getBriefSettingsUrl = (): string => `${MODULE_NAME}/brief-settings`

export const getAgeGroupsUrl = (): string => `agegroups/list`

export const getTargetSegmentsUrl = (): string => `segments/list`

export const getViewBriefUrl = (id: number): string => `${MODULE_NAME}/view/${id}`

export const getViewBriefV3Url = (id: number): string => `${MODULE_NAME}/view-sku/${id}`

export const getBriefUploadUrl = (): string => `${MODULE_NAME}/upload`

export const getEditBillUrl = (): string => `${MODULE_NAME}/bill/edit`

export const getPartnerBriefUploadUrl = (): string => `partner/${MODULE_NAME}/upload`

export const getBriefRemoveMediaUrl = (): string => `${MODULE_NAME}/remove-media`

export const getPartnerBriefRemoveMediaUrl = (): string => `partner/${MODULE_NAME}/remove-media`

export const getPartnerFilterSettingsUrl = (): string => `${MODULE_NAME}/filter-settings`

export const getBriefRecommendedPartnersUrl = (): string => `${MODULE_NAME}/recommended-partners`

export const getBriefPartnerDetailsUrl = (briefId: number, partnerId: number): string =>
  `${MODULE_NAME}/${briefId}/partner/single/${partnerId}`

export const getBriefUploadMediaUrl = (briefId: number): string => `${MODULE_NAME}/media-buy-service/${briefId}`

export const getBriefApproveUrl = (briefId: number): string => `${MODULE_NAME}/final-approve/${briefId}`

export const getSendEmailProposal = (briefId: number): string => `${MODULE_NAME}/${briefId}/email-proposal`

export const getPotentialAudienceReachUrl = (briefId: number): string => `${MODULE_NAME}/get-audience/${briefId}`

export const postConsumerResearchEmailUrl = (): string => `${MODULE_NAME}/createLead`

export const postConsumerResearchInvitedClientUrl = (): string => `invite-createLead`

export const getPayCardUrl = (): string => `${MODULE_NAME}/payment/card`

export const getValidateClientRequiredFields = (briefId: number): string => `${MODULE_NAME}/validate-client-required-fields/${briefId}`
