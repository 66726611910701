import { IState } from './signUp.types'
import * as CONSTANTS from './signUp.constants'
import IAction from 'src/interfaces/IAction'

const initialState: IState = {
  signUpError: '',
  isLoading: false,
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_STATE:
      return initialState
    case CONSTANTS.SIGN_UP_REQUEST:
      return {
        ...state,
        signUpError: initialState.signUpError,
        isLoading: true,
      }
    case CONSTANTS.SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CONSTANTS.SIGN_UP_FAILURE:
      return { ...state, signUpError: action.error, isLoading: false }
    case CONSTANTS.SIGN_UP_RESET_ERROR:
      return { ...state, signUpError: initialState.signUpError }
    default:
      return state
  }
}
