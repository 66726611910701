import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { snakeCase } from 'lodash'
import ReactGA from 'react-ga'
import { ICartItem, ICartList, ICartServiceItem, IProposal, IBriefResponse } from '../../brief.types'
import { IconsCommon, Modal, QBriefCartItem, QBriefCartProduct, QButton, ServicesSummary } from 'quantum_components'
import { Descriptions } from 'antd'
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_PAYMENT_UPLOAD_TYPES,
  BRIEF_PRINT_ID,
  BRIEF_STATUS,
  BRIEF_STEPS,
  DISTRIBUTION_TYPE,
  MEDIA_TYPES,
  MIN_LOGISTIC_PRICE_TEXT,
} from '../../../../constants/brief'
import {
  TAX_TREATMENT,
} from 'src/constants/clientProfile'
import { renderRoundedNumber } from '../../../../helpers/currency'
import { isMobile } from 'react-device-detect'
import { REQUEST_STATUS } from '../../../../constants/request'
import { useHistory, useParams } from 'react-router-dom'
import { COLORS } from '../../../../constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBrief,
  getBriefSettings,
  getBriefView,
  getProposalLoading,
  getTotalQuantity,
  getSelectedPayment,
} from '../../brief.selectors'
import { getBriefProposalPdf, togglePrintClass } from '../../../../utils/getScreenPage'
import EmailProposalForm from '../../../../components/BriefView/EmailProposalForm.component'
import { actions } from '../../brief.module'
import { getCurrency, getCurrentUser, getCurrentUserRole } from '../../../core/core.selectors'
import ROLES from '../../../../constants/roles'
import { getBase64 } from '../../../../utils/getBase64'
import { canEditBrief } from '../../../../helpers/rules'
import { DynamicPricingTypeLabels, PRICING_MODEL } from 'src/constants/profile'
import BriefPayment from './Brief.Payment'
import { IBillPaymentUploadTypes } from '../../../client/bills/bills.types'

const checkIcon = <IconsCommon.IconCheck color={COLORS.LIGHT_GREEN} />
const uncheckIcon = <IconsCommon.IconStepIncomplete className="i" />
const arrowIcon = <IconsCommon.IconArrowRight />
const closeIcon = <IconsCommon.IconClose />

interface Props {
  cart: ICartList
  setCurrentStep: (step: BRIEF_STEPS) => void
  onEdit: (id: number) => void
  viewMode?: boolean
  briefView?: IBriefResponse
  hideProposalButtons?: boolean
  hidePaymentSection?: boolean
  hideSubmitPaymentButton?: boolean
  onPaymentFilesChange?: (files: { files: File[]; filesType: IBillPaymentUploadTypes }[]) => void
  onPaymentTypeChange?: (uploadType: BRIEF_PAYMENT_UPLOAD_TYPES) => void
}

export default function BriefCartSummary({
  cart,
  setCurrentStep,
  onEdit,
  viewMode,
  briefView,
  hideProposalButtons = false,
  hidePaymentSection = false,
  hideSubmitPaymentButton = false,
  onPaymentFilesChange,
  onPaymentTypeChange,
}: Props): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translation', 'translationCommon'])
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams<{id: any}>()
  const briefId = id
  const currentUser: any = useSelector(getCurrentUser)
  const settings = useSelector(getBriefSettings)
  const emailProposalLoading = useSelector(getProposalLoading)
  const MINIMUM_DISTRIBUTION_LOGISTIC_COUNT = settings.MINIMUM_DISTRIBUTION_LOGISTIC_COUNT
  const [openProposalModal, setOpenProposalModal] = useState<boolean>(false)
  const role = useSelector(getCurrentUserRole)
  const currency = useSelector(getCurrency)
  const brief = useSelector(getBrief)
  const viewBrief = useSelector(getBriefView)
  const selectedPaymentType = useSelector(getSelectedPayment)
  const [onlinePaymentSelected, setOnlinePaymentSelected] = useState(false)


  const briefStatus = briefView ? briefView.status : brief.status
  const briefPaymentStatus: BRIEF_PAYMENT_STATUS | undefined = briefView?.payment?.status as BRIEF_PAYMENT_STATUS
  const disableEdit = !canEditBrief(briefStatus)

  const [generatingPdf, setGeneratingPdf] = useState<boolean>(false)
  const [cartItems, setCartItems] = useState<ICartItem[]>([])
  const [sampleAllocated, setSampleAllocated] = useState(0)

  const disabledButton = emailProposalLoading

  // hideSubmitPaymentButton = currentUser.paymentEnabled === null ? false: hideSubmitPaymentButton && !currentUser.paymentEnabled
  useEffect(() => {
    if (selectedPaymentType === 'ONLINE') {
      setOnlinePaymentSelected(true)
    } else {
      setOnlinePaymentSelected(false)
      // setNewCartTotal(0)
    }
  }, [selectedPaymentType])

  useEffect(() => {
    getLogos()
    if (cart.sampleAllocated) setSampleAllocated(cart.sampleAllocated)
    else setSampleAllocated(0)
  }, [cart])

  const getUploadsBase64 = async (item: ICartItem) => {
    if (!item.companyLogoUrl) return ''
    return Promise.resolve(getBase64(item.companyLogoUrl))
  }

  const getLogos = async () => {
    const items = cart.additional.items
    if (!items) return

    const list = items.map((item: ICartItem) => {
      getUploadsBase64(item).then((data: string) => {
        item.companyLogoUrl = data
      })
      return item
    })

    setCartItems(list)
  }

  const handleEdit = (partnerId: number) => {
    onEdit(partnerId)
  }

  const togglePrint = (value: boolean) => {
    const el = document.getElementById('ServiceCard')
    return value ? el?.classList.add('visible-block') : el?.classList.remove('visible-block')
  }

  const renderServiceItem = (service: ICartServiceItem, priceModel: string) => {
    const label = (
      <span className="label-column">
        <span>
          {service.productSubType
            ? service.name + ': ' + DynamicPricingTypeLabels[service.productSubType]
            : t('services.media-buy.types.' + snakeCase(service.name), service.name)}
        </span>
        {service.type === MEDIA_TYPES.DISTRIBUTION ? (
          <small>{`${renderRoundedNumber(service.amount, currency)} x ${service.quantity}`}</small>
        ) : window.location.pathname.includes('view') ? (
          (!viewBrief.marketingObjective || priceModel === PRICING_MODEL.DURATION) && (
            <small>{`${renderRoundedNumber(service.amount, currency)} x ${service.quantity}`}</small>
          )
        ) : (
          (!brief.BRIEF_MEDIA_BUY.marketingObjective || priceModel === PRICING_MODEL.DURATION) && (
            <small>{`${renderRoundedNumber(service.amount, currency)} x ${service.quantity}`}</small>
          )
        )}
      </span>
    )

    return (
      <Descriptions.Item key={service.name} label={label}>
        {
          <div style={{ textDecoration: (brief.promotionId || viewBrief.promotionId) ? 'line-through' : 'inherit', color: (brief.promotionId || viewBrief.promotionId) ? 'lightgray' : 'inherit' }}>{renderRoundedNumber(service.total, currency)}</div>
        }
        {
          (brief.promotionId || viewBrief.promotionId) &&
          <div>{renderRoundedNumber(service.offerPrice, currency)}</div>
        }
      </Descriptions.Item>
    )
  }

  const renderAdditional = (amount: number, name: string, taxes: any = false) => {

    if(currentUser.partnerData.taxTreatment == TAX_TREATMENT.NON_VAT_REGISTERED){
      if(name === t('brief.cart.Taxes')){
        if(!onlinePaymentSelected){
          return
        }
      }
    }

    if (!amount) {
      return
    }


    const price = renderRoundedNumber(amount, currency)

    return (
      <div className="SSUnit__descriptions">
        <Descriptions column={1}>
          <Descriptions.Item label={name}>
            <span className="label-column text-right">
              {name === 'Discount' ? <span className="text-red">- {price}</span> : <span>{price}</span>}
              {/*{taxes && <small>{t('brief.cart.Plus_taxes')}</small>}*/}
            </span>
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const handleFindPartner = () => {
    history.push(`/briefs/rejected/${briefId}`)
  }

  const translateDiscountText = (discountText: any) => {
    let buffer = new Array()
    buffer = discountText.split("%");

    if (buffer[0] == 0) return t("brief.cart." + snakeCase(buffer[1]));

    return buffer[0] + "% " + t("brief.cart." + snakeCase(buffer[1]));
  }

  const taxes = (onlinePaymentSelected && currentUser.partnerData.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED) || (currentUser.partnerData.taxTreatment === TAX_TREATMENT.VAT_REGISTERED && !cart.additional.taxes ) ? cart.additional.servicesTotal * 0.15 : cart.additional.taxes
  const cartTotal = (onlinePaymentSelected && currentUser.partnerData.taxTreatment === TAX_TREATMENT.NON_VAT_REGISTERED) || (currentUser.partnerData.taxTreatment === TAX_TREATMENT.VAT_REGISTERED && !cart.additional.taxes ) ? cart.additional.cartTotal * 1.15 : cart.additional.cartTotal

  const content = (
    <div className="SS__partners">
      <div className="SS__category">
        <h4 className="SS__category-title">{t('common.Ecommerce', { ns: 'translationCommon' })}</h4>
        {cartItems.map((item: ICartItem) => {
          const distributions = item.services.filter((s: ICartServiceItem) => s.type === MEDIA_TYPES.DISTRIBUTION)
          const media = item.services.filter((s: ICartServiceItem) => s.type !== MEDIA_TYPES.DISTRIBUTION)

          const isDistributionAccepted = distributions.some(
            (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.ACCEPTED
          )
          const isDistributionRejected = distributions.some(
            (s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.REJECTED
          )
          const isMediaBuyAccepted = media.some((s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.ACCEPTED)
          const isMediaBuyRejected = media.some((s: ICartServiceItem) => s.requestStatus === REQUEST_STATUS.REJECTED)

          return (
            <div key={item.partnerId} className="SSUnit">
              <div className="SSUnit__heading">
                <div className="SSUnit__title-frame">
                  {item.companyLogoUrl && (
                    <div className="SSUnit__thumb">
                      <img src={item.companyLogoUrl} alt={item.companyName} />
                    </div>
                  )}
                  <h4 className="SSUnit__title">{item.companyName}</h4>
                </div>
                {briefView?.promotionId || brief.promotionId || disableEdit || viewMode || (currentUser && currentUser.partnerPermissionLevel == 'view') ? null : (
                  <span onClick={() => handleEdit(item.partnerId)} className="SSUnit__edit" data-html2canvas-ignore>
                    {t('common.edit', { ns: 'translationCommon' })}
                  </span>
                )}
              </div>
              <div className="SSUnit__content">
                {distributions.length > 0 && (
                  <div className={`SSUnit__descriptions ${isDistributionRejected && 'rejected'}`}>
                    <Descriptions column={1} title={t('brief.cart.Distribution')}>
                      {distributions.map((service: ICartServiceItem) => (
                        <React.Fragment key={item.partnerId + service.name}>
                          {renderServiceItem(service, item.priceModel)}
                        </React.Fragment>
                      ))}
                    </Descriptions>
                    {isDistributionAccepted && (
                      <div className="SSUnit__sLabel accepted">
                        <IconsCommon.IconCheck className="i" color={COLORS.LIGHT_GREEN} />
                        {t('brief.cart.Accepted')}
                      </div>
                    )}
                    {isDistributionRejected && (
                      <React.Fragment>
                        <div className="SSUnit__sLabel rejected">
                          {uncheckIcon}
                          {t('brief.cart.Rejected')}
                        </div>
                        <QButton
                          disabled={currentUser && currentUser.partnerPermissionLevel == 'view'}
                          onClick={handleFindPartner}
                          className="SSUnit__btn"
                          type="primary"
                          block
                          data-html2canvas-ignore
                        >
                          {t('brief.cart.Find_new_partner')}
                          {arrowIcon}
                        </QButton>
                      </React.Fragment>
                    )}
                  </div>
                )}
                {media.length > 0 && (
                  <div className={`SSUnit__descriptions ${isMediaBuyRejected && 'rejected'}`}>
                    <Descriptions column={1} title={t('brief.cart.Media_Buying')}>
                      {media.map((service: ICartServiceItem) => renderServiceItem(service, item.priceModel))}
                    </Descriptions>
                    {isMediaBuyAccepted && (
                      <div className="SSUnit__sLabel accepted">
                        <IconsCommon.IconCheck className="i" color={COLORS.LIGHT_GREEN} />
                        {t('brief.cart.Accepted')}
                      </div>
                    )}
                    {isMediaBuyRejected && (
                      <React.Fragment>
                        <div className="SSUnit__sLabel rejected">
                          {uncheckIcon}
                          {t('brief.cart.Rejected')}
                        </div>
                        <QButton
                          disabled={currentUser && currentUser.partnerPermissionLevel == 'view'}
                          onClick={handleFindPartner}
                          className="SSUnit__btn"
                          type="primary"
                          block
                          data-html2canvas-ignore
                        >
                          {t('brief.cart.Find_new_partner')}
                          {arrowIcon}
                        </QButton>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className="SS__total">
        {!!cart.couponCode && (
          <div className="qu-discount-badge mb-15">
            {checkIcon}
            {cart?.additional?.discountText === '0% Discount Applied' ? t("brief.cart.Discount_Applied") : translateDiscountText(cart?.additional?.discountText)}
          </div>
        )}
        {renderAdditional(cart.additional.servicesTotal, t('brief.cart.Services_Total'))}
        {!!cart.couponCode && renderAdditional((brief.promotionId || viewBrief.promotionId) ? cart.additional.promotionCouponFee : cart.additional.couponFee, t('brief.cart.Discount'))}
        {renderAdditional((brief.promotionId || viewBrief.promotionId) ? (cart.additional.servicesTotal - cart.additional.promotionCouponFee) : cart.additional.subTotal, t('brief.cart.Subtotal'))}
        {renderAdditional((brief.promotionId || viewBrief.promotionId) ? cart.additional.promotionTaxes : taxes, t('brief.cart.Taxes'))}
        {renderAdditional((brief.promotionId || viewBrief.promotionId) ? cart.additional.promotionCartTotal : cartTotal, t('brief.cart.Total'), true)}
      </div>
    </div>
  )
//end of content
  return (
    <>
      {isMobile ? (
        <>
          {!hidePaymentSection && briefView && briefView.status !== BRIEF_STATUS.DRAFT && (
            <div className="qu-view-card m-10">
              <BriefPayment
                briefView={briefView}
                onFilesChange={onPaymentFilesChange}
                onPaymentTypeChange={onPaymentTypeChange}
                hideSubmitButton={hideSubmitPaymentButton}
              />
            </div>
          )}
          <div className="qu-view-card m-10">{content}</div>
        </>
      ) : (
        <>
          {!hidePaymentSection && briefView && briefView.status !== BRIEF_STATUS.DRAFT && (
            <ServicesSummary
              title={
                briefPaymentStatus &&
                  [BRIEF_PAYMENT_STATUS.PROCESSING, BRIEF_PAYMENT_STATUS.PAID].includes(briefPaymentStatus)
                  ? t('brief.payment.Payment_info')
                  : t('brief.payment.Make_payment')
              }
              className="mb-20"
            >
              <BriefPayment
                briefView={briefView}
                onFilesChange={onPaymentFilesChange}
                onPaymentTypeChange={onPaymentTypeChange}
                hideSubmitButton={hideSubmitPaymentButton}
              />
            </ServicesSummary>
          )}
          <ServicesSummary title={t('brief.cart.Services_Summary')}>{content}</ServicesSummary>
        </>
      )}
    </>
  )
}
