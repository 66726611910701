import { createSelector } from 'reselect'
import { IState, IServerError, ICurrentUser, ICoreSettings, ICoreConstants } from './core.types'
import { MODULE_NAME } from './core.constants'
import ROLES from '../../constants/roles'
import { CURRENCY } from '../../constants/currencies'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getActiveSaasPartner = createSelector(selectState, (state: IState): any => state.saasUser)

export const getShowProgress = createSelector(selectState, (state: IState): boolean => state.showProgress)

export const getProgress = createSelector(selectState, (state: IState): number => state.progress)

export const getServerError = createSelector(selectState, (state: IState): IServerError | null => state.serverError)

export const getCurrentUser = createSelector(selectState, (state: IState): ICurrentUser => state.currentUser)

export const getCurrentUserRole = createSelector(selectState, (state: IState): ROLES => state.currentUser.role)

export const getProfilePicture = createSelector(selectState, (state): string => state.currentUser.profilePicture)

export const getCoreSettings = createSelector(selectState, (state: IState): ICoreSettings | null => state.coreSettings)

export const getCoreConstants = createSelector(
  selectState,
  (state: IState): ICoreConstants | null => state.coreConstants
)

export const getCurrency = createSelector(selectState, (state: IState): CURRENCY => state.currency)

export const getNotificationCount = createSelector(
  selectState,
  (state: IState): number => state.currentUser.unreadNotificationCount
)
