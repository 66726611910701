import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { IconsCommon, QButton } from 'quantum_components'
import { CLIENT_STEPS } from 'src/constants/clientProfile'
import { LOCALIZATION_LANGUAGES } from 'src/constants'

export interface IProps {
  currentStep: string
  disabled: boolean
  handleBack: () => void
  handleSave: () => void
  handleNext: () => void
}

export function ClientsProfileFooter({
  currentStep,
  disabled,
  handleSave,
  handleNext,
  handleBack,
}: IProps): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCommon'])
  return (
    <div className="profile-footer_custom">
      <div className="left">
        {currentStep.includes(CLIENT_STEPS.TARGET_AUDIENCE) ? (
          <QButton onClick={handleBack} className="qu-button-outline mr-10">
            {i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? (
              <IconsCommon.IconArrowLeft className="mr-5" />
            ) : (
              <IconsCommon.IconArrowRight className="mr-5" />
            )}

            {t('common.Back')}
          </QButton>
        ) : (
          <QButton disabled={disabled} onClick={handleSave} className="qu-button-soft mr-10">
            {t('common.Save_Updates')}
          </QButton>
        )}
      </div>
      <div className="right">
        {!(
          currentStep.includes(CLIENT_STEPS.BASIC_INFORMATION) || currentStep.includes(CLIENT_STEPS.TARGET_AUDIENCE)
        ) && (
          <QButton
            onClick={handleBack}
            type="primary"
            className={`qu-button-outline ${i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? 'mr-15' : 'ml-15'}`}
          >
            {i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? (
              <IconsCommon.IconArrowLeft className="mr-5" />
            ) : (
              <IconsCommon.IconArrowRight className="mr-5" />
            )}

            {t('common.Back')}
          </QButton>
        )}

        {currentStep.includes(CLIENT_STEPS.TARGET_AUDIENCE) ? (
          <QButton disabled={disabled} onClick={handleSave} className="qu-button-soft">
            {t('common.Save_Updates')}
          </QButton>
        ) : (
          <QButton onClick={handleNext} type="primary">
            {t('common.Next')}

            {i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? (
              <IconsCommon.IconArrowRight className="ml-5" />
            ) : (
              <IconsCommon.IconArrowLeft className="ml-5" />
            )}
          </QButton>
        )}
      </div>
    </div>
  )
}
