import { IBillTableParams } from '../modules/client/bills/bills.types'

export const DEFAULT_BILL_TABLE_PARAMS: IBillTableParams = {
  offset: 0,
  limit: 10,
  direction: 'desc',
  sortField: 'createdAt',
  search: '',
  paymentStatus: [],
}
