import { ROLES } from '../../../constants'

export default {
  success: {
    status: 200,
    data: {
      status: 1,
      message: 'Success',
      token: 'my-token-is-ok',
      role: ROLES.CLIENT,
    },
  },
  failure: {
    status: 422,
    data: {
      status: 0,
      message: 'Username or password entered incorrectly',
    },
  },
}
