import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, InfoCard, PublicProfileField, QCheckbox, QSelect, Row } from 'quantum_components'
import { Select } from 'antd'

import { useReduxDispatch } from 'src/helpers'
import { actions } from '../../../partnerProfile.module'
import { getForm, getPartnerProfileSettings } from '../../../profile.selectors'
import { debounce, pick } from 'lodash'

import { ICity, ICountry, IForm } from '../../../profile.types'

import '../../../styles.scss'
import { CUSTOMERS_AND_LOCALE_VALUES } from 'src/constants/profile'
import AgeGroupCardInput from '../../components/CustomInputs/AgeGroupCardInput'
import GenderFocusInput from '../../components/CustomInputs/GenderFocusInput'
import SegmentsInput from '../../components/CustomInputs/SegmentsInput'

interface IProps {
  setError: (stepNmae: string, value: boolean) => void
  isViewOnly?: boolean
}

export const StepThreeContainer: FC<IProps> = ({ setError, isViewOnly }) => {
  const [formRef] = Form.useForm()
  const dispatch = useReduxDispatch()
  const settings = useSelector(getPartnerProfileSettings)
  const partnerForm = useSelector(getForm)
  const stepFields = pick(partnerForm, Object.values(CUSTOMERS_AND_LOCALE_VALUES))

  const partnerGenders = stepFields.partnerGenders ? stepFields.partnerGenders : []
  const partnerAgeGroups = stepFields.partnerAgeGroups ? stepFields.partnerAgeGroups : []
  const partnerTargetSegments = stepFields.partnerTargetSegments ? stepFields.partnerTargetSegments : []

  const initialValues = { partnerGenders, partnerAgeGroups, partnerTargetSegments }

  useEffect(() => {
    dispatch(
      actions.setForm(
        pick({ partnerAgeGroups, partnerTargetSegments, partnerGenders }, [
          'partnerAgeGroups',
          'partnerTargetSegments',
          'partnerGenders',
        ])
      )
    )
  }, [])

  const [form, setForm] = useState<IForm>({
    ...partnerForm,
    countries: partnerForm.countries ? partnerForm.countries : [],
    cities: partnerForm.cities ? partnerForm.cities : [],
  })

  const { Option, OptGroup } = Select

  const [countries, setCountries] = useState<ICountry[]>([])

  useEffect(() => {
    setCountries(settings.countries)
  }, [settings.countries])

  useEffect(() => {
    const selectedCountries = form.countries
    const filtered = !selectedCountries.length
      ? settings.countries
      : settings.countries.filter((c: ICountry) => selectedCountries.includes(c.name))

    filtered.map(ele => {
      ele.city[
        ele.city.findIndex(selectVal => {
          return selectVal.id == -1
        })
      ].country_id = -1
      ele.city[
        ele.city.findIndex(selectVal => {
          return selectVal.id == 0
        })
      ].country_id = -1
      if (ele.city.filter(val => form.cities.includes(val.name)).length == ele.city.length - 2) {
        ele.city[
          ele.city.findIndex(selectVal => {
            return selectVal.id == -1
          })
        ].country_id = 0
      } else {
        ele.city[
          ele.city.findIndex(selectVal => {
            return selectVal.id == 0
          })
        ].country_id = 0
      }
    })
    setCountries(filtered)
  }, [form])

  const handleChangeCountries = (event: any) => {
    const newForm = { ...form }
    const { id } = event.target

    newForm.countries.includes(id)
      ? newForm.countries.splice(newForm.countries.indexOf(id), 1)
      : newForm.countries.push(id)

    const countriesObj = settings.countries.filter((c: ICountry) => newForm.countries.includes(c.name))
    if (!countriesObj.length) {
      newForm.cities = []
    }

    const selectedCountryCities = countriesObj.map((c: ICountry) => c.city.map((c: ICity) => c.name)).flat()
    newForm.cities = newForm.cities.filter((c: string) => selectedCountryCities.includes(c))

    formRef.setFieldsValue({ cities: newForm.cities })
    setForm(newForm)
    dispatch(
      actions.setForm({
        ...partnerForm,
        countries: newForm.countries.length > 0 ? newForm.countries : [],
        cities: newForm.cities.length > 0 ? newForm.cities : [],
      })
    )
  }

  const handleChangeCities = (values: string[], cities: any[]) => {
    countries.forEach(country => {
      if (cities.filter(val => val.key === '0').find(val => val.country == country.name)) {
        country.city.map(val => {
          if (!values.includes(val.name)) {
            values.push(val.name)
          }
        })
      }

      if (cities.filter(val => val.key === '-1').find(val => val.country == country.name)) {
        country.city.map(val => {
          if (values.includes(val.name)) {
            values.splice(values.indexOf(val.name), 1)
          }
        })
      }
    })

    values = values.filter(val => val.search('Select All') == -1 && val.search('Deselect All') == -1)
    const newForm = { ...form }

    const countries1 = cities.map(c => c.country)
    const uniqueCountries = countries1.filter((v, i, a) => a.indexOf(v) === i)

    newForm.cities = values
    newForm.countries = uniqueCountries
    formRef.setFieldsValue({ cities: newForm.cities })

    setForm(newForm)
    dispatch(
      actions.setForm({
        ...partnerForm,
        countries: newForm.countries.length > 0 ? newForm.countries : [],
        cities: newForm.cities.length > 0 ? newForm.cities : [],
      })
    )
  }

  const checkboxesCountries = () => {
    if (!settings.countries) {
      return []
    }

    return settings.countries.map((country: ICountry) => (
      <Form.Item className="mb-0" name={country.name} key={country.id}>
        <QCheckbox
          checked={form.countries.includes(country.name)}
          className="test-class"
          onChange={handleChangeCountries}
          disabled={isViewOnly}
        >
          {country.name}
        </QCheckbox>
      </Form.Item>
    ))
  }

  const onValuesChange = (changedValues: any, values: typeof initialValues) => {
    dispatch(actions.setForm(pick({ ...values }, ['partnerAgeGroups', 'partnerTargetSegments', 'partnerGenders'])))
  }

  const delayedChange = useCallback(
    //@ts-ignore
    debounce((c, a) => onValuesChange(c, a), 500),
    []
  )

  return (
    <div className="third_content">
      <Form form={formRef} initialValues={initialValues} onValuesChange={delayedChange}>
        <InfoCard headerLeft={<h3>Customers & Locale</h3>}>
          <PublicProfileField
            description={{ title: 'What gender does your store focus?' }}
            input={
              <Form.Item className="mb-0 full-width" name="partnerGenders">
                {/* @ts-ignore */}
                <GenderFocusInput isViewOnly={isViewOnly} />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{ title: 'What age groups do you target?' }}
            input={
              <>
                <Row gutter={8} align="middle">
                  <Form.Item noStyle name="partnerAgeGroups">
                    {/* @ts-ignore */}
                    <AgeGroupCardInput isViewOnly={isViewOnly} />
                  </Form.Item>
                </Row>
              </>
            }
          />
          <PublicProfileField
            description={{
              title: 'What segments do you target?',
            }}
            input={
              <Form.Item className="mb-0" name="partnerTargetSegments">
                {/* @ts-ignore */}
                <SegmentsInput isViewOnly={isViewOnly} />
              </Form.Item>
            }
          />
          <PublicProfileField
            description={{
              title: 'What countries do you serve?',
            }}
            input={<>{checkboxesCountries()}</>}
          />
          <PublicProfileField
            description={{
              title: <span>What cities do you serve?</span>,
            }}
            input={
              <Form.Item className="mb-0" name="cities">
                <QSelect
                  defaultValue={form.cities}
                  mode="multiple"
                  size="large"
                  showArrow
                  showSearch
                  placeholder="Select Cities"
                  onChange={handleChangeCities}
                  disabled={isViewOnly}
                >
                  {countries.map((country: ICountry) => {
                    return (
                      <OptGroup key={country.id} label={country.name}>
                        {country.city.map((city: ICity) => {
                          return (
                            city.country_id >= 0 && (
                              <Option key={city.id} country={country.name} value={city.name}>
                                {city.name}
                              </Option>
                            )
                          )
                        })}
                      </OptGroup>
                    )
                  })}
                </QSelect>
              </Form.Item>
            }
          />
        </InfoCard>
      </Form>
    </div>
  )
}
