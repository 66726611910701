import React, { ReactElement, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { InputNumber } from 'antd'
import { Col, Form, QButton, QCheckbox, QDatePicker, QRadio, QSelect, Radio, Row } from 'quantum_components'
import { IBrief, IMediaBuyStep, IOptionForSelect } from '../../testahelBox.types'
import { useSelector } from 'react-redux'
import { getBriefSettings } from '../../testahelBox.selectors'
import { FORMATS, LOCAL_STORAGE_KEYS, REGEXES } from '../../../../../constants'
import { isMobile } from 'react-device-detect'
import { ReactComponent as LogOut } from '../../../../../assets/icons/logout-icon-alt.svg'
import { BRIEF_STEPS, BRIEF_STEPS_NAMES, MARKETING_OBJECTIVES } from '../../../../../constants/testahel_brief'
import TestahelPopoverSummary from '../components/TestahelBrief.PopoverSummary'
import moment from 'moment'
import { afterDays, disabledDates } from '../../../../../helpers/datesHelper'
import { TestahelMediaTypeCard } from '../components/TestahelBrief.MediaTypeCard'
import { getCurrency } from 'src/modules/core/core.selectors'

interface IProps {
  brief: IBrief
  mediaBuyFields: IMediaBuyStep
  onChange: (mediaBuyFields: IMediaBuyStep) => void
  onFinishLater: () => void
  disabledFinishLater: boolean
}

export default function TestahelMediaBuyForm({
  brief,
  mediaBuyFields,
  onChange,
  onFinishLater,
  disabledFinishLater,
}: IProps): ReactElement {
  const settings = useSelector(getBriefSettings)
  const currency = useSelector(getCurrency)
  const [errorMedia, setMediaErrors] = useState<string>('')
  const [marketingObjectiveError, setMarketingObjectiveError] = useState<string>('')
  const [mediaStartDateIsDifferent, setMediaStartDateIsDifferent] = useState<boolean>(false)
  const [selectAllTypes, setSelectAllTypes] = useState<boolean>(false)
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(mediaBuyFields)
  })

  const briefStartDate = brief[BRIEF_STEPS.PRODUCT].briefStartDate || afterDays(7)

  const setMediaStartDate = () => {
    const fields = { ...mediaBuyFields }
    fields.mediaStartDate = moment(briefStartDate)
    onChange(fields)
  }

  useEffect(() => {
    if (!mediaBuyFields.mediaStartDate) {
      setMediaStartDate()
      return
    }

    const isBriefStartDate =
      moment(mediaBuyFields.mediaStartDate, FORMATS.DATE_FORMAT).format(FORMATS.DATE_FORMAT) ===
      moment(briefStartDate, FORMATS.DATE_FORMAT).format(FORMATS.DATE_FORMAT)

    setMediaStartDateIsDifferent(!isBriefStartDate)
  }, [])

  useEffect(() => {
    form.validateFields(['mediaStartDate'])
  }, [mediaStartDateIsDifferent])

  useEffect(() => {
    if (settings.mediaTypes.length === 0) {
      return
    }

    const isSelectedAll = mediaBuyFields.mediaTypes.length === settings.mediaTypes.length
    setSelectAllTypes(isSelectedAll)
  }, [mediaBuyFields.mediaTypes.length])

  const validateMediaType = (items: string[]) => {
    const msg = items.length > 0 ? '' : 'Should be at least one media type.'
    setMediaErrors(msg)
  }

  const handleMediaType = (e: MouseEvent, name: string) => {
    const fields = { ...mediaBuyFields }
    const items = fields.mediaTypes
    !items.includes(name) &&
      ReactGA.event({
        category: `Create/Edit Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? '- Invited Client'
            : ''
        } - Media/Ad Type(s)`,
        action: name,
        label: name,
        value: 1,
      })
    items.includes(name) ? items.splice(items.indexOf(name), 1) : items.push(name)
    validateMediaType(items)

    onChange(fields)
  }

  const resetMediaDate = (isDifferent: boolean) => {
    const fields = { ...mediaBuyFields }
    if (isDifferent) {
      form.setFieldsValue({ mediaStartDate: null })
      fields.mediaStartDate = null
    } else {
      fields.mediaStartDate = moment(briefStartDate)
    }

    onChange(fields)
  }

  const handleChangeRadio = (e: any) => {
    const value = e.target.value
    setMediaStartDateIsDifferent(value)
    // resetMediaDate(value)
  }

  const handleDatePicker = (date: moment.Moment) => {
    const fields = { ...mediaBuyFields }
    fields.mediaStartDate = date

    onChange(fields)
  }

  const handleMarketingProperties = (e: any) => {
    const fields = { ...mediaBuyFields }
    const { id } = e.target
    fields.marketingObjective = id
    validateCheckboxes(fields.marketingObjective)
    onChange(fields)
  }

  const validateCheckboxes = (item: string) => {
    setMarketingObjectiveError(item != '' ? '' : `Should be at least one Marketing Objective.`)
  }

  const handleSelectChange = (value: string, option: IOptionForSelect) => {
    if (!option) {
      return
    }

    const fields = { ...mediaBuyFields }
    // @ts-ignore
    fields[option.id] = value
    ReactGA.event({
      category: `Create/Edit Brief ${
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ? '- Invited Client'
          : ''
      } - How long should the media run?`,
      action: value,
      label: value,
      value: 1,
    })
    onChange(fields)
  }

  const handleSelectAll = (e: any) => {
    const fields = { ...mediaBuyFields }
    const isChecked = e.target.checked
    setSelectAllTypes(isChecked)
    fields.mediaTypes = isChecked ? [...settings.mediaTypes] : []

    fields.mediaTypes.map(ele => {
      ReactGA.event({
        category: `Create/Edit Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? '- Invited Client'
            : ''
        } - Media/Ad Type(s)`,
        action: ele,
        label: ele,
        value: 1,
      })
    })

    onChange(fields)
  }

  const handleSelectChangeBudgetType = (value: string, option: IOptionForSelect) => {
    if (!option) {
      return
    }

    const fields = { ...mediaBuyFields }
    // @ts-ignore
    fields[option.id] = value
    onChange(fields)
  }

  const handleSelectChangeBudgetValue = () => {
    const fields = { ...mediaBuyFields }
    if (form.getFieldValue('budgetValue') > 0) {
      // @ts-ignore
      fields.budgetValue = form.getFieldValue('budgetValue')
    } else {
      // @ts-ignore
      fields.budgetValue = null
    }
    fields.budgetCurrency = currency
    // @ts-ignore
    onChange(fields)
  }

  const mediaDuration: IOptionForSelect[] = settings.mediaDurations.map((type: string) => {
    return { id: 'mediaDuration', label: type, value: type }
  })

  const budgetType: IOptionForSelect[] = [{ id: 'budgetType', label: 'Lifetime', value: 'Lifetime' }]

  const renderMediaStartDate = moment(briefStartDate, FORMATS.DATE_LL_FORMAT).format(FORMATS.DATE_LL_FORMAT)

  return (
    <Form form={form} layout="vertical" hideRequiredMark initialValues={mediaBuyFields}>
      <div className={`form form-brief form--horizontal`}>
        <div className="form__head">
          <div className="form__number">
            {isMobile ? <span>{`4. ${BRIEF_STEPS_NAMES[BRIEF_STEPS.MEDIA_BUY]}`}</span> : '4'}
          </div>
          <h3>Tell us about the media you're interested in</h3>
        </div>
        <TestahelPopoverSummary brief={brief} />
        <div className="form__cont">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="mediaStartDateIsDifferent" className="form-label">
                * What is your desired start date?
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Row gutter={16} align="middle">
                <Col xs={24}>
                  <Radio.Group
                    className="qu-radio-group vertical"
                    name="mediaStartDateIsDifferent"
                    value={mediaStartDateIsDifferent}
                    onChange={handleChangeRadio}
                  >
                    <QRadio value={false}>{renderMediaStartDate}</QRadio>
                    <QRadio value={true}>A different date</QRadio>
                  </Radio.Group>
                </Col>
                {mediaStartDateIsDifferent && (
                  <Col xs={24}>
                    <Form.Item
                      className="mt-15 mb-0 full-width"
                      name="mediaStartDate"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose media start date.',
                        },
                      ]}
                      validateTrigger={['onBlur', 'onChange']}
                    >
                      <QDatePicker
                        value={mediaBuyFields.mediaStartDate}
                        format={FORMATS.DATE_FORMAT}
                        onChange={handleDatePicker}
                        disabledDate={disabledDates}
                        className="full-width"
                        size="large"
                        placeholder="Select Desired Starting Date"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>

          <hr className="form__hr" />
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="mediaDuration" className="form-label">
                * How long should the media run?
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="mediaDuration"
                rules={[
                  {
                    required: true,
                    message: 'Please choose media duration.',
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <QSelect
                  value={mediaBuyFields.mediaDuration}
                  onChange={handleSelectChange}
                  showSearch
                  size="large"
                  placeholder="Select Duration"
                  options={mediaDuration}
                />
              </Form.Item>
            </Col>
          </Row>

          <hr className="form__hr" />
          <Row gutter={16} align="top">
            <Col xs={24} sm={24} md={8}>
              <label htmlFor="marketingObjective" className="form-label">
                * What is your Marketing Objective?
              </label>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <label htmlFor="marketingObjective" className="form-label">
                Awareness:
              </label>
              <Row gutter={16} align="middle">
                <Col xs={24}>
                  <Form.Item className="mb-0" name={MARKETING_OBJECTIVES.BRAND_AWARENESS}>
                    <QRadio
                      className="mr-0"
                      onChange={handleMarketingProperties}
                      checked={mediaBuyFields.marketingObjective === MARKETING_OBJECTIVES.BRAND_AWARENESS}
                    >
                      {MARKETING_OBJECTIVES.BRAND_AWARENESS}
                    </QRadio>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item className="mb-0" name={MARKETING_OBJECTIVES.REACH}>
                    <QRadio
                      className="mr-0"
                      onChange={handleMarketingProperties}
                      checked={mediaBuyFields.marketingObjective === MARKETING_OBJECTIVES.REACH}
                    >
                      {MARKETING_OBJECTIVES.REACH}
                    </QRadio>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <label htmlFor="marketingObjective" className="form-label">
                Consideration:
              </label>
              <Row gutter={16} align="middle">
                <Col xs={24}>
                  <Form.Item className="mb-0" name={MARKETING_OBJECTIVES.LEAD_GENERATION}>
                    <QRadio
                      className="mr-0"
                      onChange={handleMarketingProperties}
                      checked={mediaBuyFields.marketingObjective === MARKETING_OBJECTIVES.LEAD_GENERATION}
                    >
                      {MARKETING_OBJECTIVES.LEAD_GENERATION}
                    </QRadio>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item className="mb-0" name={MARKETING_OBJECTIVES.VIDEO_VIEW}>
                    <QRadio
                      className="mr-0"
                      onChange={handleMarketingProperties}
                      checked={mediaBuyFields.marketingObjective === MARKETING_OBJECTIVES.VIDEO_VIEW}
                    >
                      {MARKETING_OBJECTIVES.VIDEO_VIEW}
                    </QRadio>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item className="mb-0" name={MARKETING_OBJECTIVES.USER_ENGAGEMENT}>
                    <QRadio
                      className="mr-0"
                      onChange={handleMarketingProperties}
                      checked={mediaBuyFields.marketingObjective === MARKETING_OBJECTIVES.USER_ENGAGEMENT}
                    >
                      {MARKETING_OBJECTIVES.USER_ENGAGEMENT}
                    </QRadio>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item className="mb-0" name={MARKETING_OBJECTIVES.BRAND_RECOGNITION}>
                    <QRadio
                      className="mr-0"
                      onChange={handleMarketingProperties}
                      checked={mediaBuyFields.marketingObjective === MARKETING_OBJECTIVES.BRAND_RECOGNITION}
                    >
                      {MARKETING_OBJECTIVES.BRAND_RECOGNITION}
                    </QRadio>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <label htmlFor="marketingObjective" className="form-label">
                Conversion:
              </label>
              <Row gutter={16} align="middle">
                <Col xs={24}>
                  <Form.Item className="mb-0" name={MARKETING_OBJECTIVES.DRIVE_SALES}>
                    <QRadio
                      className="mr-0"
                      onChange={handleMarketingProperties}
                      checked={mediaBuyFields.marketingObjective === MARKETING_OBJECTIVES.DRIVE_SALES}
                    >
                      {MARKETING_OBJECTIVES.DRIVE_SALES}
                    </QRadio>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16} align="top">
            <Col xs={24} sm={24} md={8}></Col>
            <Col xs={24} sm={24} md={16}>
              {marketingObjectiveError && (
                <div className="text-red font-size-12 mt-5 mb-10">{marketingObjectiveError}</div>
              )}
            </Col>
          </Row>

          <hr className="form__hr" />
          <Row gutter={16} align="top">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="budgetType" className="form-label">
                * Budget ({currency})
              </label>
            </Col>
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                className="mb-0"
                name="budgetType"
                rules={[
                  {
                    required: true,
                    message: 'Please choose budget type.',
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <QSelect
                  value={mediaBuyFields.budgetType}
                  onChange={handleSelectChangeBudgetType}
                  size="large"
                  placeholder="Select Budget Type"
                  options={budgetType}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={7}>
              <Form.Item
                className="mb-0"
                name="budgetValue"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Invalid amount!',
                    pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <InputNumber
                  onChange={handleSelectChangeBudgetValue}
                  min={1}
                  placeholder="Enter amount"
                  size="large"
                  precision={2}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <hr className="form__hr" />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="targetFocus" className="form-label">
                * Media/Ad Type(s)
              </label>
              {errorMedia && <div className="text-red font-size-12 mt-10">{errorMedia}</div>}
              <p className="p--xs mt-10 tc--light">
                Choose the media or ad types where you believe your target audience is most likely to respond.
              </p>
              <div>
                <QCheckbox checked={selectAllTypes} onChange={handleSelectAll} className="mb-20">
                  Select all
                </QCheckbox>
              </div>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Row gutter={16} justify="center" align="middle">
                {settings.mediaTypes.map((name: string) => {
                  const isChecked = mediaBuyFields.mediaTypes.includes(name)
                  return <TestahelMediaTypeCard key={name} checked={isChecked} name={name} onChange={handleMediaType} />
                })}
              </Row>
            </Col>
          </Row>

          {isMobile && (
            <div className="form-brief-footer">
              <QButton
                disabled={disabledFinishLater}
                onClick={onFinishLater}
                className="qu-button-outline mt-30 full-width"
              >
                Save & Finish Later <LogOut className="ml-10" />
              </QButton>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}
