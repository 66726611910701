import get from 'lodash/get'

import { IState } from './publicProfile.types'
import * as CONSTANTS from './publicProfile.constants'
import IAction, { TName } from 'src/interfaces/IAction'
import { first } from 'lodash'

export const initialState: IState = {
  publicProfile: {
    id: 0,
    userId: 0,
    completion: 0,
    listingShortDescription: '',
    companyLogo: '',
    previewCardImage: '',
    listingHeaderImage: '',
    highlights: [],
    metricsMarketing: [],
    createdAt: '',
    updatedAt: '',
  },
  fetchPublicProfileError: '',
  publicProfileLoading: false,
  partnerPublicPercent: 0,
  partnerDetails: null,
  fetchPartnerDetailsLoading: false,
  partnerDetailsError: '',
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.FETCH_PUBLIC_PROFILE_REQUEST:
      return {
        ...state,
        fetchPublicProfileError: initialState.fetchPublicProfileError,
        publicProfileLoading: true,
      }
    case CONSTANTS.FETCH_PUBLIC_PROFILE_SUCCESS:
      const publicProfile = get(action, 'payload.publicProfile')
      return {
        ...state,
        // @ts-ignore
        publicProfile: { ...publicProfile },
        publicProfileLoading: false,
      }
    case CONSTANTS.FETCH_PUBLIC_PROFILE_FAILURE:
      return { ...state, fetchPublicProfileError: action.error, publicProfileLoading: false }

    case CONSTANTS.IMAGE_UPLOAD_SUCCESS:
      const uploadActionObject: { name: TName; content: string } = action.changedFilesPayload
        ? action.changedFilesPayload
        : { name: 'companyLogo', content: '' }
      return {
        ...state,
        publicProfile: { ...state.publicProfile, [uploadActionObject.name]: first(uploadActionObject.content) },
      }
    case CONSTANTS.IMAGE_REMOVE:
      const fieldName = get(action, 'name')
      return {
        ...state,
        publicProfile: { ...state.publicProfile, [fieldName!]: '' },
      }
    case CONSTANTS.SET_PUBLIC_PROFILE:
      const values = get(action, 'payload.publicProfile')
      // @ts-ignore
      return { ...state, publicProfile: { ...state.publicProfile, ...values } }
    case CONSTANTS.UNMOUNT_CLEAR_DATA:
      return { ...initialState }
    case CONSTANTS.SET_PUBLIC_PROFILE_PERCENT:
      const percent = action.percent ? action.percent : 0
      return { ...state, partnerPublicPercent: percent }

    case CONSTANTS.FETCH_PARTNER_DETAILS_REQUEST:
      return {
        ...state,
        fetchPartnerDetailsLoading: true,
      }
    case CONSTANTS.FETCH_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        partnerDetails: get(action, 'payload.partner'),
        fetchPartnerDetailsLoading: false,
      }
    case CONSTANTS.FETCH_PARTNER_DETAILS_FAILURE:
      return { ...state, partnerDetailsError: action.error, fetchPartnerDetailsLoading: false }

    default:
      return state
  }
}
