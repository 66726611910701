import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import {
  Col,
  Form,
  Popover,
  QButton,
  QRadio,
  QDatePicker,
  QInput,
  QSelect,
  QTextArea,
  Row,
  qnotification,
} from "quantum_components";
import { IOptionForSelect, IProductStep } from "../../brief.types";
import {
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  BRIEF_TYPES,
  BRIEF_UPLOAD_TYPES,
  CAMPAIGN_NAME_MAX,
  CAMPAIGN_NAME_MIN,
  DISTRIBUTION_TYPE,
  PRODUCT_NAME_MAX,
  PRODUCT_NAME_MIN,
  PROMOTION_TYPE,
  PROMOTION_TYPE_LABEL,
  MARKETING_OBJECTIVES,
  MARKETING_OBJECTIVES_LABELS,
  PRODUCT_DESCRIPTION_MIN,
  PRODUCT_DESCRIPTION_MAX,
} from "../../../../constants/brief";
import { useSelector } from "react-redux";
import {
  getBrief,
  getBriefSettings,
  getIsImagesUploading,
} from "../../brief.selectors";
import moment from "moment";
import {
  FORMATS,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
  REGEXES,
} from "../../../../constants";
import UploadComponent from "../../../../components/UploadComponent";
import { useReduxDispatch } from "src/helpers";
import { actions } from "../../brief.module";
import BriefDistributionPopover from "../components/Brief.distributionPopover";
import { isMobile } from "react-device-detect";
import { ReactComponent as LogOut } from "src/assets/icons/logout-icon-alt.svg";
import PopoverSummary from "../components/Brief.PopoverSummary";
import {
  disabledDates,
  disabledPastDates,
} from "../../../../helpers/datesHelper";
import IUploadFile from "../../../../interfaces/IUploadFile";
import { getFileNameFromUrl } from "../../../../utils";
import VALIDATE_STATUSES from "../../../../constants/validateStatuses";
import { isInsert, isSample } from "../../../../helpers/brief";
import { VALIDATION_MESSAGE } from "../../../../constants/validationMessages";

interface IProps {
  productFields: IProductStep;
  onChange: (productFields: IProductStep) => void;
  onFinishLater: () => void;
  disabledFinishLater: boolean;
}

export default function ProductBriefForm({
  productFields,
  onChange,
  onFinishLater,
  disabledFinishLater,
}: IProps): ReactElement {
  const { t } = useTranslation(["translation", "translationCommon"]);
  const dispatch = useReduxDispatch();
  const settings = useSelector(getBriefSettings);
  const brief = useSelector(getBrief);
  const isImagesUploading = useSelector(getIsImagesUploading);
  const [distributingError, setDistributingError] = useState("");
  const [files, setFiles] = useState<IUploadFile[]>([]);
  const [nameError, setNameError] = useState<string>("");
  const [productDescriptionError, setProductDescriptionError] =
    useState<string>("");
  const [campaignNameError, setCampaignNameError] = useState<string>("");
  const [marketingObjectiveError, setMarketingObjectiveError] =
    useState<string>("");
  const [dateError, setDateError] = useState<string>("");
  const [form] = Form.useForm();

  const hasProductSample = brief[BRIEF_STEPS.TYPE].briefTypes.includes(
    BRIEF_TYPES.PRODUCT_SAMPLE
  );

  useEffect(() => {
    const fields = { ...productFields };
    form.setFieldsValue(fields);
  });

  const validateName = (value: string | null) => {
    if (!value) {
      setNameError(t(VALIDATION_MESSAGE.PRODUCT_NAME));
      return;
    }

    if (value.length < PRODUCT_NAME_MIN || value.length > PRODUCT_NAME_MAX) {
      setNameError(t(VALIDATION_MESSAGE.PRODUCT_NAME_MIN_MAX));
      return;
    }

    setNameError("");
  };

  const validateProductDescription = (value: string | null) => {
    if (!value) {
      setProductDescriptionError(t(VALIDATION_MESSAGE.PRODUCT_DESCRIPTION));
      return;
    }

    if (
      value.length < PRODUCT_DESCRIPTION_MIN ||
      value.length > PRODUCT_DESCRIPTION_MAX
    ) {
      setProductDescriptionError(
        t(VALIDATION_MESSAGE.PRODUCT_DESCRIPTION_MIN_MAX, {
          min: PRODUCT_DESCRIPTION_MIN,
          max: PRODUCT_DESCRIPTION_MAX,
        })
      );
      return;
    }

    setProductDescriptionError("");
  };

  const validateCampaignName = (value: string | null) => {
    if (!value) {
      setCampaignNameError(t(VALIDATION_MESSAGE.CAMPAIGN_NAME));
      return;
    }

    if (value.length < CAMPAIGN_NAME_MIN || value.length > CAMPAIGN_NAME_MAX) {
      setCampaignNameError(
        t(VALIDATION_MESSAGE.CAMPAIGN_NAME_MIN_MAX, {
          min: CAMPAIGN_NAME_MIN,
          max: CAMPAIGN_NAME_MAX,
        })
      );
      return;
    }

    setCampaignNameError("");
  };

  const validateDate = (value: string | moment.Moment | null) => {
    if (!value) {
      setDateError("Please choose brief start date.");
      return;
    }

    setDateError("");
  };

  const filesMapper = () => {
    const list = productFields.productImages
      ? productFields.productImages.map((url: string, index: number) => {
        return {
          uid: index + "",
          name: getFileNameFromUrl(url),
          status: "done",
          url: url,
        } as IUploadFile;
      })
      : [];
    setFiles(list);
  };

  useEffect(() => {
    filesMapper();
  }, [productFields.productImages.length]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fields = { ...productFields };
    const { id, value, type } = e.target;

    if (id === "name") {
      validateName(value.trimLeft());
    }

    if (id === "campaignName") {
      validateCampaignName(value.trimLeft());
    }

    if (id === "productDescription") {
      validateProductDescription(value.trimLeft());
    }

    if (value) {
      // @ts-ignore
      fields[id] = type === "number" ? +value : value.trimLeft();
    } else {
      // @ts-ignore
      fields[id] = null;
    }
    onChange(fields);
  };

  const handleDurationChange = (value: string) => {
    ReactGA.event({
      category: `Create/Edit Brief ${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ? "- Invited Client"
          : ""
        } - How long should the distribution last?`,
      action: value,
      label: value,
      value: 1,
    });
    const fields = { ...productFields };
    fields.duration = value;

    onChange(fields);
  };

  const handleProductTypeChange = (value: string) => {
    ReactGA.event({
      category: `Create/Edit Brief ${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ? "- Invited Client"
          : ""
        } - What is the product type?`,
      action: value,
      label: value,
      value: 1,
    });
    const fields = { ...productFields };
    fields.productType = value;

    onChange(fields);
  };

  const handleOtherProductType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fields = { ...productFields };
    const { value } = e.target;
    if (value) {
      fields.productType = value;
      fields.otherProductType = value;
    } else {
      fields.productType = "Other";
      fields.otherProductType = null;
    }

    onChange(fields);
  };

  const handleDistributionItems = (e: any) => {
    ReactGA.event({
      category: `Create/Edit Brief ${localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ? "- Invited Client"
          : ""
        } - What are you distributing?`,
      action: e.target.id,
      label: e.target.id,
      value: 1,
    });
    const fields = { ...productFields };
    const { id } = e.target;
    fields.distributionItem = id;
    validateDistributionItem(fields.distributionItem);
    onChange(fields);
  };

  const handleDatePicker = (date: moment.Moment) => {
    const fields = { ...productFields };
    fields.mediaStartDate = date;
    validateDate(date);
    onChange(fields);
  };

  const getFileProperties = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const img: any = new Image();
        img.src = fileReader.result;
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = reject;
      };
      fileReader.onerror = reject;
    });
  };

  const handleUpload = async (uploads: File[]) => {
    const fileSize = uploads[0].size / 1024;
    if (fileSize > 100) {
      qnotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t("image_size_validation"),
      });
      return;
    }

    const { width, height }: any = await getFileProperties(uploads[0]);
    if (width > 200 || height > 200) {
      qnotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t("image_dimension_validation"),
      });
      return;
    }

    if (files.length > 0) {
      await dispatch(
        actions.removeImage(
          brief,
          files[0],
          BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES
        )
      );
    }

    dispatch(
      actions.uploadProductImage(
        brief,
        uploads,
        BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES
      )
    );
  };

  const handleRemove = async () => {
    files.length > 0 &&
      (await dispatch(
        actions.removeImage(
          brief,
          files[0],
          BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES
        )
      ));
  };

  const validateDistributionItem = (item: DISTRIBUTION_TYPE | null) => {
    const msg = !item ? t(VALIDATION_MESSAGE.DISTRIBUTION_TYPE) : "";
    setDistributingError(msg);
  };

  const handlePromotionTypeChange = (e: any) => {
    const fields = { ...productFields, campaignPromotionType: e.target.id };
    onChange(fields);
  };

  const isBrand = productFields.campaignPromotionType === PROMOTION_TYPE.BRAND;

  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      initialValues={productFields}
    >
      <div className={`form form-brief form--horizontal`}>
        <div className="form__head">
          <div className="form__number">
            {isMobile ? (
              <span>{`1. ${t(BRIEF_STEPS_NAMES[BRIEF_STEPS.PRODUCT])}`}</span>
            ) : (
              "1"
            )}
          </div>
          <h3>{t("brief.edit.step2.tell_us_about")}</h3>
        </div>
        <PopoverSummary brief={brief} />
        <div className="form__cont">
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="campaignName" className="form-label mb-25">
                * {t("brief.edit.step2.campaign_name")}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="campaignName"
                validateStatus={
                  campaignNameError
                    ? VALIDATE_STATUSES.ERROR
                    : VALIDATE_STATUSES.SUCCESS
                }
                help={
                  campaignNameError
                    ? campaignNameError
                    : t(VALIDATION_MESSAGE.CAMPAIGN_NAME)
                }
              >
                <QInput
                  onBlur={() =>
                    validateCampaignName(productFields.campaignName)
                  }
                  value={productFields.campaignName}
                  onChange={handleInputChange}
                  placeholder={t("brief.edit.step2.enter_campaign_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="productImage" className="form-label mt-15">
                {t("brief.edit.step2.upload_an_image")}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item className="mb-0 product-brief-upload">
                <UploadComponent
                  files={files}
                  onChange={handleUpload}
                  onRemove={handleRemove}
                  isUploading={isImagesUploading}
                  caption={[t("image_size_text"), t("image_dimension_text")]}
                />
                <div className="ant-form-item-explain">
                  <div role="alert">
                    {t('resize_link')} <a href="https://imageresizer.com/" target={"_blank"}>{t('here')}</a>
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="campaignPromotionType" className="form-label">
                * {t("brief.edit.step2.what_are_you_promoting")}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Row gutter={16} align="middle">
                <Col flex="auto">
                  <Form.Item className="mb-0" name={PROMOTION_TYPE.BRAND}>
                    <QRadio
                      className="mr-0"
                      onChange={handlePromotionTypeChange}
                      value={PROMOTION_TYPE.BRAND}
                      checked={
                        productFields.campaignPromotionType ===
                        PROMOTION_TYPE.BRAND
                      }
                    >
                      {t(PROMOTION_TYPE_LABEL.BRAND)}
                    </QRadio>
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item className="mb-0" name={PROMOTION_TYPE.STORE}>
                    <QRadio
                      className="mr-0"
                      onChange={handlePromotionTypeChange}
                      value={PROMOTION_TYPE.STORE}
                      checked={
                        productFields.campaignPromotionType ===
                        PROMOTION_TYPE.STORE
                      }
                    >
                      {t(PROMOTION_TYPE_LABEL.STORE)}
                    </QRadio>
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item className="mb-0" name={PROMOTION_TYPE.RESTAURANT}>
                    <QRadio
                      className="mr-0"
                      onChange={handlePromotionTypeChange}
                      value={PROMOTION_TYPE.RESTAURANT}
                      checked={
                        productFields.campaignPromotionType ===
                        PROMOTION_TYPE.RESTAURANT
                      }
                    >
                      {t(PROMOTION_TYPE_LABEL.RESTAURANT)}
                    </QRadio>
                  </Form.Item>
                </Col>
                {/* {errors.gender && <div className="text-red font-size-12 mt-5 mb-10">{errors.gender}</div>} */}
              </Row>
            </Col>
          </Row>
          <hr className="form__hr" />
          {isBrand && (
            <React.Fragment>
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label htmlFor="name" className="form-label mb-25">
                    * {t("brief.edit.step2.product_name")}
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Form.Item
                    className="mb-0"
                    name="name"
                    validateStatus={
                      nameError
                        ? VALIDATE_STATUSES.ERROR
                        : VALIDATE_STATUSES.SUCCESS
                    }
                    help={
                      nameError ? nameError : t(VALIDATION_MESSAGE.PRODUCT_NAME)
                    }
                  >
                    <QInput
                      onBlur={() => validateName(productFields.name)}
                      value={productFields.name}
                      onChange={handleInputChange}
                      placeholder={t("brief.edit.step2.enter_name")}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <hr className="form__hr" />
            </React.Fragment>
          )}
          {isBrand && (
            <React.Fragment>
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={10}>
                  <label
                    htmlFor="productDescription"
                    className="form-label mb-25"
                  >
                    * {t("brief.edit.step2.product_description")}
                  </label>
                </Col>
                <Col xs={24} sm={24} md={14}>
                  <Form.Item
                    className="mb-0"
                    name="productDescription"
                    validateStatus={
                      productDescriptionError
                        ? VALIDATE_STATUSES.ERROR
                        : VALIDATE_STATUSES.SUCCESS
                    }
                    help={
                      productDescriptionError
                        ? productDescriptionError
                        : t(VALIDATION_MESSAGE.PRODUCT_DESCRIPTION)
                    }
                  >
                    <QTextArea
                      value={productFields.productDescription}
                      onChange={handleInputChange}
                      onBlur={() =>
                        validateProductDescription(
                          productFields.productDescription
                        )
                      }
                      rows={3}
                      cols={3}
                      placeholder={t(
                        "brief.edit.step2.enter_product_description"
                      )}
                      className="form-textarea"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <hr className="form__hr" />
            </React.Fragment>
          )}

          {/*<Row gutter={16} align="top">
                      <Col xs={24} sm={24} md={10}>
                        <label htmlFor="marketingObjective" className="form-label">
                          * {t("brief.edit.step5.marketing_objective")}
                        </label>
                      </Col>
                      <Col xs={24} sm={24} md={14}>
                        {/* <label htmlFor="marketingObjective" className="form-label">
                          {t("brief.edit.step5.awareness")}:
                        </label>}
                        <Row gutter={16} align="middle">
                          <Col flex="auto">
                            <Form.Item
                              className="mb-0"
                              name={MARKETING_OBJECTIVES.BRAND_AWARENESS}
                            >
                              <QRadio
                                className="mr-0"
                                onChange={handleMarketingProperties}
                                checked={
                                  productFields.marketingObjective ===
                                  MARKETING_OBJECTIVES.BRAND_AWARENESS
                                }
                              >
                                {t(MARKETING_OBJECTIVES_LABELS.BRAND_AWARENESS)}
                              </QRadio>
                            </Form.Item>
                          </Col>
                          <Col flex="auto">
                            <Form.Item
                              className="mb-0"
                              name={MARKETING_OBJECTIVES.DRIVE_SALES}
                            >
                              <QRadio
                                className="mr-0"
                                onChange={handleMarketingProperties}
                                checked={
                                  productFields.marketingObjective ===
                                  MARKETING_OBJECTIVES.DRIVE_SALES
                                }
                              >
                                {t(MARKETING_OBJECTIVES_LABELS.DRIVE_SALES)}
                              </QRadio>
                            </Form.Item>
                          </Col>
                           <Col xs={24}>
                            <Form.Item className="mb-0" name={MARKETING_OBJECTIVES.REACH}>
                              <QRadio
                                className="mr-0"
                                onChange={handleMarketingProperties}
                                checked={
                                  productFields.marketingObjective ===
                                  MARKETING_OBJECTIVES.REACH
                                }
                              >
                                {t(MARKETING_OBJECTIVES_LABELS.REACH)}
                              </QRadio>
                            </Form.Item>
                          </Col> 
                        </Row>
                      </Col>
                      {<Col xs={24} sm={24} md={6}>
                        <label htmlFor="marketingObjective" className="form-label">
                          {t('brief.edit.step5.consideration')}:
                        </label>
                        <Row gutter={16} align="middle">
                          <Col xs={24}>
                            <Form.Item
                              className="mb-0"
                              name={MARKETING_OBJECTIVES.LEAD_GENERATION}
                            >
                              <QRadio
                                className="mr-0"
                                onChange={handleMarketingProperties}
                                checked={
                                  productFields.marketingObjective ===
                                  MARKETING_OBJECTIVES.LEAD_GENERATION
                                }
                              >
                                {t(MARKETING_OBJECTIVES_LABELS.LEAD_GENERATION)}
                              </QRadio>
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              className="mb-0"
                              name={MARKETING_OBJECTIVES.VIDEO_VIEW}
                            >
                              <QRadio
                                className="mr-0"
                                onChange={handleMarketingProperties}
                                checked={
                                  productFields.marketingObjective ===
                                  MARKETING_OBJECTIVES.VIDEO_VIEW
                                }
                              >
                                {t(MARKETING_OBJECTIVES_LABELS.VIDEO_VIEW)}
                              </QRadio>
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              className="mb-0"
                              name={MARKETING_OBJECTIVES.USER_ENGAGEMENT}
                            >
                              <QRadio
                                className="mr-0"
                                onChange={handleMarketingProperties}
                                checked={
                                  productFields.marketingObjective ===
                                  MARKETING_OBJECTIVES.USER_ENGAGEMENT
                                }
                              >
                                {t(MARKETING_OBJECTIVES_LABELS.USER_ENGAGEMENT)}
                              </QRadio>
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              className="mb-0"
                              name={MARKETING_OBJECTIVES.BRAND_RECOGNITION}
                            >
                              <QRadio
                                className="mr-0"
                                onChange={handleMarketingProperties}
                                checked={
                                  productFields.marketingObjective ===
                                  MARKETING_OBJECTIVES.BRAND_RECOGNITION
                                }
                              >
                                {t(MARKETING_OBJECTIVES_LABELS.BRAND_RECOGNITION)}
                              </QRadio>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>*/}
          {/* <Col xs={24} sm={24} md={4}>
                        <label htmlFor="marketingObjective" className="form-label">
                          {t("brief.edit.step5.conversion")}:
                        </label>
                        <Row gutter={16} align="middle">
                          <Col flex="auto">
                            <Form.Item
                              className="mb-0"
                              name={MARKETING_OBJECTIVES.DRIVE_SALES}
                            >
                              <QRadio
                                className="mr-0"
                                onChange={handleMarketingProperties}
                                checked={
                                  productFields.marketingObjective ===
                                  MARKETING_OBJECTIVES.DRIVE_SALES
                                }
                              >
                                {t(MARKETING_OBJECTIVES_LABELS.DRIVE_SALES)}
                              </QRadio>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col> }
                    </Row>
                    <Row gutter={16} align="top">
                      <Col xs={24} sm={24} md={8}></Col>
                      <Col xs={24} sm={24} md={16}>
                        {marketingObjectiveError && (
                          <div className="text-red font-size-12 mt-5 mb-10">
                            {marketingObjectiveError}
                          </div>
                        )}
                      </Col>
                    </Row>*/}

          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="mediaStartDate" className="form-label mb-25">
                * {t("brief.edit.step2.desired_start_date")}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="mediaStartDate"
                rules={[
                  {
                    required: true,
                    message: t("brief.edit.step5.choose_media_start"),
                  },
                ]}
                validateTrigger={["onBlur", "onChange"]}
              >
                <QDatePicker
                  value={productFields.mediaStartDate}
                  format={FORMATS.DATE_FORMAT}
                  onChange={handleDatePicker}
                  disabledDate={disabledPastDates}
                  disabled={brief.promotionId}
                  className="full-width"
                  size="large"
                  placeholder={t(
                    "brief.edit.step2.select_desired_starting_date"
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="instructions" className="form-label">
                {t("brief.edit.step2.do_you_have_additional")}
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="instructions"
                rules={[
                  {
                    max: 500,
                    message: t(VALIDATION_MESSAGE.ADDITIONAL_INSTRUCTION),
                  },
                ]}
              >
                <QTextArea
                  value={productFields.instructions}
                  onChange={handleInputChange}
                  rows={3}
                  cols={3}
                  placeholder={t("brief.edit.step2.let_us_know")}
                  className="form-textarea"
                />
              </Form.Item>
            </Col>
          </Row>
          {isMobile && (
            <div className="form-brief-footer">
              <QButton
                disabled={disabledFinishLater}
                onClick={onFinishLater}
                className="qu-button-outline mt-30 full-width"
              >
                {t("brief.edit.common.save_and_finish_later")}{" "}
                <LogOut className="ml-10" />
              </QButton>
            </div>
          )}
        </div>
      </div>
    </Form>
  );
}
