import { createSelector } from 'reselect'
import {
  IAnalyticsCity,
  ICampaignAnalyticsData,
  ICampaignAnalyticsInfo,
  ICampaignAnalyticsMediaBuyData,
  ICampaignDetails,
  ICampaignDistAnalyticsFilter,
  ICampaignMediaBuyFilter,
  ICampaignStats,
  ICampaignTable,
  ICampaignTableParams,
  ICampaignTimeline,
  IChartData,
  IClientGallery,
  IClientGalleryParams,
  IClientSurvey,
  IClientSurveyAnswer,
  IClientSurveysParams,
  IState,
  ITimeline,
} from './campaign.types'
import { MODULE_NAME } from './campaign.constants'
import { campaignsToTable } from '../../helpers/campaign'
import { FORMATS } from '../../constants'
import moment from 'moment'
import { getCurrency } from '../core/core.selectors'
import { CURRENCY } from '../../constants/currencies'
import { TABS_NAMES } from '../../constants/tabs'
import { CAMPAIGN_PARTNERS, CAMPAIGN_PARTNERS_NAMES } from '../../constants/campaign'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getCampaignListLoading = createSelector(selectState, (state: IState): boolean => state.campaignListLoading)

export const getCampaignList = createSelector(
  [selectState, getCurrency],
  (state: IState, currency: CURRENCY): ICampaignTable[] => {
    const tableList = campaignsToTable(state.campaignList.results, currency)

    return tableList
  }
)

export const getCampaignStats = createSelector(
  selectState,
  (state: IState): ICampaignStats => {
    return state.campaignList.stats
  }
)

export const getTotalCampaignList = createSelector(selectState, (state: IState): number => state.campaignList.total)

export const getCampaignParams = createSelector(selectState, (state: IState): ICampaignTableParams => state.params)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)

export const getCampaignDetails = createSelector(
  selectState,
  (state: IState): ICampaignDetails | null => state.campaignDetails
)
export const getCampaignDetailsLoading = createSelector(
  selectState,
  (state: IState): boolean => state.campaignDetailsLoading
)

export const getCampaignAnalyticsInfo = createSelector(
  selectState,
  (state: IState): ICampaignAnalyticsInfo | null => state.campaignAnalyticsInfo
)

export const getCampaignTimeline = createSelector(selectState, (state: IState): ITimeline[] => {
  const timelines = state.campaignTimelines.map((timeline: ICampaignTimeline) => {
    return {
      id: timeline.id,
      timelineCaption: timeline.title,
      timelineDate: moment(timeline.startedDate).format(FORMATS.DATE_LL_FORMAT),
      timelinePhotos: timeline.allImages,
      timelinePartner: timeline.partnerProfile?.companyName || CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.QUANTUM_TEAM],
    } as ITimeline
  })

  return timelines
})

export const getCampaignAnalyticsData = createSelector(
  selectState,
  (state: IState): ICampaignAnalyticsData | null => state.campaignAnalyticsData
)
export const getCampaignAnalyticsDataCities = createSelector(selectState, (state: IState): IChartData[] => {
  return state.campaignAnalyticsData
    ? state.campaignAnalyticsData.reachVolumes.map(
      (c: IAnalyticsCity) => ({ name: c.cityName, value: c.quantity } as IChartData)
    )
    : []
})

export const getCampaignAnalyticsDataGender = createSelector(selectState, (state: IState): IChartData[] => {
  const data = state.campaignAnalyticsData
  return data
    ? Object.keys(data.gender).map(
      (key: string) =>
        //@ts-ignore
        ({ name: key, value: data.gender[key] } as IChartData)
    )
    : []
})

export const getCampaignAnalyticsDataAgeGroups = createSelector(selectState, (state: IState): IChartData[] => {
  const data = state.campaignAnalyticsData
  return data
    ? Object.keys(data.ageGroups).map(
      (key: string) =>
        //@ts-ignore
        ({ name: key, value: data.ageGroups[key] } as IChartData)
    )
    : []
})

export const getCampaignAnalyticsDataLoading = createSelector(
  selectState,
  (state: IState): boolean => state.campaignAnalyticsDataLoading
)

export const getCampaignAnalyticsDistFilter = createSelector(
  selectState,
  (state: IState): ICampaignDistAnalyticsFilter => state.campaignDistAnalyticsFilter
)
export const getCampaignAnalyticsMediaBuyFilter = createSelector(
  selectState,
  (state: IState): ICampaignMediaBuyFilter => state.campaignMediaBuyAnalyticsFilter
)

export const getCampaignAnalyticsMediaBuyData = createSelector(
  selectState,
  (state: IState): ICampaignAnalyticsMediaBuyData | null => state.campaignMediaBuyAnalyticsData
)

export const getTotalClientSurveysList = createSelector(
  selectState,
  (state: IState): number => state.clientSurveys.total
)

export const getClientSurveysParams = createSelector(
  selectState,
  (state: IState): IClientSurveysParams => state.surveyParams
)

export const getClientSurveysPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.surveysPages
)

export const getClientSurveysList = createSelector(selectState, (state: IState): IClientSurvey[] => {
  const surveys = state.clientSurveys.results.filter((survey: IClientSurvey) =>
    survey.sumAnswers.some((a: IClientSurveyAnswer) => !(a.sumQuantity < 0))
  )
  return surveys
})
export const getClientSurveysListLoading = createSelector(
  selectState,
  (state: IState): boolean => state.clientSurveysLoading
)

export const getTotalClientGalleryList = createSelector(
  selectState,
  (state: IState): number => state.clientGalleryList.total
)

export const getClientGalleryParams = createSelector(
  selectState,
  (state: IState): IClientGalleryParams => state.galleryParams
)

export const getClientGalleryPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.galleryPages
)

export const getClientGalleryList = createSelector(selectState, (state: IState): IClientGallery[] => {
  return state.clientGalleryList.results
})

export const getClientGalleryLoading = createSelector(
  selectState,
  (state: IState): boolean => state.clientGalleryLoading
)

export const getClientGalleryDownloading = createSelector(
  selectState,
  (state: IState): boolean => state.downloadMediaLoading
)

export const getActiveTab = createSelector(selectState, (state: IState): TABS_NAMES => state.activeTab)

export const getClientActiveTab = createSelector(selectState, (state: IState): TABS_NAMES => state.clientActiveTab)

export const getCardList = createSelector(selectState, (state: IState): any[] =>{
  const list =  state.campaignList.results.map(el => ({
    ...el,
    key: el.id,
    product: {
      campaignName: el.productName,
      created: moment(el.startedDate).format(FORMATS.DATE_LL_FORMAT),
      productFirstImage: el.productImageUrls.length ? el.productImageUrls[0] : '',
      discountText: el.discountText,
      mediaStartDate: moment(el.mediaStartDate).format(FORMATS.DATE_LL_FORMAT),
    },
    status: {
      value: el.status,
      id: el.id
    },
    services: el?.cart?.additional?.items && el?.cart?.additional?.items[0]?.services || [],
    servicesv2: el?.cart?.additional?.services && el?.cart?.additional?.services || [],
    discountApplied: el.discountText,
  }))
  return list
}
  
)
