import React, { ReactElement, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import {
  IBriefPartnerDetails,
  ICartItem,
  ICartList,
  ICartServiceItem,
  IPartnerDetailsService,
  MediaSubtype,
} from '../../modules/client/testahelBox/testahelBox.types'
import { getMediaBuyPrice } from '../../helpers/currency'
import { useSelector } from 'react-redux'
import {
  getActiveSinglePartnerId,
  getBrief,
  getBriefSettings,
  getCart,
  getIsRejectionFlow,
  getLogisticInsertRates,
  getLogisticSampleRates,
  getPartnerFilterSettings,
  getStickerCost,
} from '../../modules/client/testahelBox/testahelBox.selectors'
import { BRIEF_STEPS, DISTRIBUTION_TYPE, MEDIA_TYPES, MEDIA_TYPES_DURATION } from '../../constants/testahel_brief'
import { getCurrency } from '../../modules/core/core.selectors'
import TestahelMobileServiceCard from './ServiceMobileCard/TestahelDetails.MobileServiceCard'
import { actions } from '../../modules/client/testahelBox/testahelBox.module'
import { useReduxDispatch } from '../../helpers'
import {
  blockService,
  calcCartItemServicesSubTotal,
  calcLogisticPrice,
  calcStickerPrice,
  calcSubTotal,
  calculation,
} from '../../helpers/cart'
import { getLogisticRate } from '../../helpers/logistic'
import { getDuration } from '../../helpers/mediaTypes'
import { isInsert, isSample, onlyDistribution } from '../../helpers/testahelBrief'

interface Props {
  details: IBriefPartnerDetails
  briefId: number
  showModal?: () => void
  viewMode?: boolean
  isBrief?: boolean
}

export default function TestahelServicesMobileForm({
  details,
  briefId,
  showModal,
  viewMode,
  isBrief,
}: Props): ReactElement {
  const dispatch = useReduxDispatch()
  const cart = useSelector(getCart)
  const partnerId = useSelector(getActiveSinglePartnerId)
  const filterSettings = useSelector(getPartnerFilterSettings)
  const mediaSubTypes = filterSettings.mediaSubtypes
  const stickerCost = useSelector(getStickerCost)
  const logisticSampleRates = useSelector(getLogisticSampleRates)
  const logisticInsertRates = useSelector(getLogisticInsertRates)
  const settings = useSelector(getBriefSettings)
  const brief = useSelector(getBrief)
  const MINIMUM_DISTRIBUTION_LOGISTIC_COUNT = settings.MINIMUM_DISTRIBUTION_LOGISTIC_COUNT
  const isRejectionFlow = useSelector(getIsRejectionFlow)
  const currency = useSelector(getCurrency)

  const [data, setData] = useState<ICartServiceItem[]>([])

  const cartItem = cart.additional.items.find((item: ICartItem) => partnerId === item.partnerId)

  const onlyInsert = isBrief ? isInsert(brief[BRIEF_STEPS.PRODUCT].distributionItem) : true
  const onlySample = isBrief ? isSample(brief[BRIEF_STEPS.PRODUCT].distributionItem) : true
  const isOnlyDistribution = onlyDistribution(brief[BRIEF_STEPS.TYPE].briefTypes)

  useEffect(() => {
    // @ts-ignore
    setData(servicesData())
  }, [])

  useEffect(() => {
    // @ts-ignore
    setData(servicesData())
  }, [cart])

  const newDistribution = (distributionType: DISTRIBUTION_TYPE) => {
    const isSample = distributionType === DISTRIBUTION_TYPE.PRODUCT_SAMPLE
    const amount = isSample ? details.pricePerSample : details.pricePerInsert
    const duration = isSample ? MEDIA_TYPES_DURATION.PER_SAMPLE : MEDIA_TYPES_DURATION.PER_INSERT

    return {
      name: distributionType,
      type: MEDIA_TYPES.DISTRIBUTION,
      amount: amount,
      currency: currency,
      quantity: null,
      total: 0,
      duration: duration,
    }
  }

  const servicesData = () => {
    const services = [] as ICartServiceItem[]

    if (details.isSampleDistribution && onlySample) {
      const targetService =
        cartItem && cartItem.services.find((s: ICartServiceItem) => s.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE)
      targetService ? services.push(targetService) : services.push(newDistribution(DISTRIBUTION_TYPE.PRODUCT_SAMPLE))
    }

    if (details.isInsertDistribution && onlyInsert) {
      const targetService =
        cartItem && cartItem.services.find((s: ICartServiceItem) => s.name === DISTRIBUTION_TYPE.PRINTED_INSERT)
      targetService ? services.push(targetService) : services.push(newDistribution(DISTRIBUTION_TYPE.PRINTED_INSERT))
    }

    const mediaTypeServices = details.availableServices?.map((service: IPartnerDetailsService) => {
      const name = service.serviceSubName ? service.serviceSubName : service.serviceName
      const targetService = cartItem && cartItem.services.find((s: ICartServiceItem) => s.name === name)

      if (targetService) {
        return targetService
      } else {
        const price = getMediaBuyPrice(service)
        console.log(price)
        const subType = mediaSubTypes.find((type: MediaSubtype) => type.name === service.serviceName)
        return {
          name: service.serviceSubName ? service.serviceSubName : service.serviceName,
          type: subType ? subType.subtype : '',
          amount: price,
          currency: currency,
          quantity: null,
          total: 0,
          duration: getDuration(service),
          imageUrl: service.serviceImageUrl ? service.serviceImageUrl : '',
        }
      }
    })
    return [...services, ...mediaTypeServices]
  }

  const handleInputChange = (e: any, name: string) => {
    const newData = [...data]
    const { value } = e.target
    const target = newData.find((service: ICartServiceItem) => service.name === name)
    if (!target) {
      return
    }

    target.quantity = value
    target.total = target.amount * +value

    setData(newData)
  }

  const checkIsInCart = (service: ICartServiceItem) => {
    const cartItems = cart.additional.items
    const targetCartItem = cartItems.find((item: ICartItem) => item.partnerId === partnerId)
    if (!targetCartItem) {
      return false
    }

    return !!targetCartItem.services.find((s: ICartServiceItem) => s === service)
  }

  const handleAddToCart = async (service: ICartServiceItem) => {
    if (window.location.pathname.includes('/briefs/edit')) {
      ReactGA.event({
        category: `Create/Edit Brief - Selected Services for ${details?.companyName}`,
        action: service.name,
        label: service.name,
        value: 1,
      })
    }
    const isDistribution = service.type === MEDIA_TYPES.DISTRIBUTION

    if (showModal && isOnlyDistribution && !isDistribution) {
      showModal()
      return
    }

    const newCart = { ...cart }
    const cartItems = newCart.additional.items
    const partnerId = details!.id

    const targetCartItem = cartItems.find((item: ICartItem) => item.partnerId === partnerId)

    let logisticSampleRate = targetCartItem ? targetCartItem.logisticSampleRate : 0
    let logisticSamplePrice = targetCartItem ? targetCartItem.logisticSamplePrice : 0
    let stickerPrice = targetCartItem ? targetCartItem.stickerPrice : 0
    let logisticInsertRate = targetCartItem ? targetCartItem.logisticInsertRate : 0
    let logisticInsertPrice = targetCartItem ? targetCartItem.logisticInsertPrice : 0
    const quantity = service.quantity ? service.quantity : 0

    if (service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE) {
      logisticSampleRate = getLogisticRate(quantity, logisticSampleRates)
      logisticSamplePrice = calcLogisticPrice(logisticSampleRate, quantity, MINIMUM_DISTRIBUTION_LOGISTIC_COUNT)
      stickerPrice = calcStickerPrice(stickerCost, quantity)
    }

    if (service.name === DISTRIBUTION_TYPE.PRINTED_INSERT) {
      logisticInsertRate = getLogisticRate(quantity, logisticInsertRates)
      logisticInsertPrice = calcLogisticPrice(logisticInsertRate, quantity, MINIMUM_DISTRIBUTION_LOGISTIC_COUNT)
    }

    if (targetCartItem) {
      const targetItemServices = targetCartItem.services
      const targetService = targetItemServices.find((s: ICartServiceItem) => s === service)
      if (targetService) {
        const index = targetItemServices.indexOf(targetService)
        targetItemServices[index] = service
      } else {
        targetItemServices.push(service)
      }

      targetCartItem.logisticSampleRate = logisticSampleRate
      targetCartItem.logisticSamplePrice = logisticSamplePrice
      targetCartItem.stickerPrice = stickerPrice
      targetCartItem.stickerCost = stickerCost
      targetCartItem.logisticInsertRate = logisticInsertRate
      targetCartItem.logisticInsertPrice = logisticInsertPrice

      const servicesTotal = calcCartItemServicesSubTotal(targetItemServices)

      if (isRejectionFlow) {
        targetCartItem.isApprovedByUser = true
      }

      targetCartItem.subTotal = calcSubTotal(
        servicesTotal,
        targetCartItem.logisticSamplePrice,
        targetCartItem.logisticInsertPrice,
        targetCartItem.stickerPrice
      )
    } else {
      const servicesTotal = calcCartItemServicesSubTotal([service])

      const newCartItem = {
        partnerId: details!.id,
        companyName: details!.companyName,
        companyLogoUrl: details!.companyLogoUrl,
        companyWebsite: details!.companyWebsite,
        services: [service],
        logisticSampleRate,
        logisticSamplePrice,
        stickerCost,
        stickerPrice,
        logisticInsertPrice,
        logisticInsertRate,
        isApprovedByUser: true,
        subTotal: calcSubTotal(servicesTotal, logisticSamplePrice, logisticInsertPrice, stickerPrice),
      } as ICartItem

      cartItems.push(newCartItem)
    }

    const result = calculation(cartItems, settings.managementFee, settings.partnerVat)

    const cartData = {
      briefId,
      additional: {
        managementFee: result.managementFee,
        taxes: result.taxes,
        cartTotal: result.total,
        items: cartItems,
      },
    } as ICartList

    await dispatch(actions.addToCart(cartData))
    await dispatch(actions.fetchCart(briefId))
  }

  const handleRemoveFromCart = async (service: ICartServiceItem) => {
    const newCart = { ...cart }
    const cartItems = newCart.additional.items
    const partnerId = details!.id

    const targetCartItem = cartItems.find((item: ICartItem) => item.partnerId === partnerId)

    if (!targetCartItem) {
      return
    }
    const targetItemServices = targetCartItem.services
    const targetService = targetItemServices.find((s: ICartServiceItem) => s === service)

    if (!targetService) {
      return
    }

    const index = targetItemServices.indexOf(targetService)
    targetItemServices.splice(index, 1)

    if (!targetItemServices.length) {
      cartItems.splice(cartItems.indexOf(targetCartItem), 1)
    }

    if (service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE) {
      targetCartItem.stickerCost = 0
      targetCartItem.stickerPrice = 0
      targetCartItem.logisticSamplePrice = 0
      targetCartItem.logisticSampleRate = 0
    }

    if (service.name === DISTRIBUTION_TYPE.PRINTED_INSERT) {
      targetCartItem.logisticInsertRate = 0
      targetCartItem.logisticInsertPrice = 0
    }

    const servicesTotal = calcCartItemServicesSubTotal(targetItemServices)

    targetCartItem.subTotal = calcSubTotal(
      servicesTotal,
      targetCartItem.logisticSamplePrice,
      targetCartItem.logisticInsertPrice,
      targetCartItem.stickerPrice
    )

    if (isRejectionFlow) {
      targetCartItem.isApprovedByUser = true
    }

    const result = calculation(cartItems, settings.managementFee, settings.partnerVat)

    const cartData = {
      briefId,
      additional: {
        managementFee: result.managementFee,
        taxes: result.taxes,
        cartTotal: result.total,
        items: cartItems,
      },
    } as ICartList

    await dispatch(actions.addToCart(cartData))
    await dispatch(actions.fetchCart(briefId))
  }

  return (
    <div className="qu-services-list">
      {data?.map((service: ICartServiceItem) => {
        const block = blockService(service)

        return (
          <TestahelMobileServiceCard
            key={service.name}
            selected={checkIsInCart(service)}
            imageUrl={service.imageUrl}
            service={service}
            onInputChange={handleInputChange}
            onClickAdd={handleAddToCart}
            onClickRemove={handleRemoveFromCart}
            viewMode={viewMode || block}
            details={details}
          />
        )
      })}
    </div>
  )
}
