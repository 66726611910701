import Home from 'src/modules/core/containers/Home'
import NotFound from '../modules/core/containers/NotFound'
import { SignInContainer } from '../modules/registration/signIn'
import { SignUpContainer } from '../modules/registration/signUp'
import { ResetPasswordContainer } from '../modules/registration/resetPassword'
import { RestorePasswordContainer } from '../modules/registration/restorePassword'
import { ActivateAccountContainer } from '../modules/registration/activateAccount'
import { GUARD_META, PATHS } from '../constants'
import {
	BriefEditContainer,
	BriefCreateContainer,
	BriefListContainer,
	BriefViewContainer,
	BriefRejectedContainer,
} from '../modules/brief/brief.module'
import { BillsListContainer } from '../modules/client/bills'
import { PublicProfileContainer } from 'src/modules/partner/publicProfile/publicProfile.module'
import { ProfilePartnerContainer } from '../modules/partner/partnerProfile/partnerProfile.module'
import {
	ProfileClientContainer,
	ProfileClientPreviewContainer,
} from '../modules/client/clientProfile/clientProfile.module'
import UpdatePasswordContainer from 'src/modules/updatePassword/containers/UpdatePassword.container'
import UpdateEmailContainer from 'src/modules/updateEmail/containers/UpdateEmail.container'
import Marketplace from 'src/modules/client/marketplace/containers/Marketplace.container'
import Announcement from "src/modules/client/announcement/containers/Announcement.container"
import { RequestListContainer } from '../modules/partner/requests'
import RequestDetailsContainer from '../modules/partner/requests/containers/RequestDetails.container'
import { DashboardContainer } from 'src/modules/partner/dashboard'
import {
	CampaignDetailsContainer,
	PartnerCampaignsContainer,
	ClientCampaignsContainer,
} from '../modules/campaign/campaign.module'
import { TeamMembersContainer } from 'src/modules/partner/teamMembers/teamMembers.module'
import InvitingClientContainer from 'src/modules/partner/invitingClient/containers/InvitingClient.container'
import { PartnerAPIContainer } from 'src/modules/partner/partnerAPI/partnerAPI.module'
import SpecialClientsContainer from 'src/modules/specialClients/containers/SpecialClients.container'
import { RegisterSuccessContainer } from 'src/modules/registration/registerSuccess'
import { HomeContainer } from 'src/modules/registration/home'
import {
	TestahelBriefCreateContainer,
	TestahelBriefEditContainer,
	TestahelBriefViewContainer,
} from 'src/modules/client/testahelBox/testahelBox.module'
import { BillingsContainer } from '../modules/billings'
import { BriefV2EditContainer } from 'src/modules/briefV2/briefV2.module'
import { PendingSignUpContainer } from 'src/modules/registration/pendingSignUp'
import { DeploymentDetailsContainer } from 'src/modules/deploymentDetails/deploymentDetails.module'
import { BriefEditV3StepOneContainer } from 'src/modules/briefV3'
import { BriefEditV3Container } from 'src/modules/briefV3/briefv3.module'
import { CampaignDetailsV3Container } from 'src/modules/campaignV3/campaignV3.module'

export default () => [
	{
		path: PATHS.ROOT,
		exact: true,
		loading: "Loading...",
		error: "Error",
		component: Home,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
		},
	},
	{
		path: PATHS.HOME_PAGE,
		exact: true,
		component: HomeContainer,
	},
	{
		path: PATHS.SIGN_IN,
		exact: true,
		component: SignInContainer,
	},
	{
		path: PATHS.SIGN_UP,
		exact: true,
		component: SignUpContainer,
	},
	{
		path: PATHS.PARTIALLY_REGISTER,
		exact: true,
		component: PendingSignUpContainer,
	},
	{
		path: PATHS.REGISTER_SUCCESS,
		exact: true,
		component: RegisterSuccessContainer,
	},
	{
		path: PATHS.RESET_PASSWORD,
		exact: true,
		component: ResetPasswordContainer,
	},
	{
		path: PATHS.RESTORE_PASSWORD,
		exact: true,
		component: RestorePasswordContainer,
	},
	{
		path: PATHS.ACTIVATE_ACCOUNT,
		exact: true,
		component: ActivateAccountContainer,
	},
	{
		path: PATHS.SPECIAL_CLIENTS,
		exact: true,
		component: SpecialClientsContainer,
	},
	{
		path: PATHS.BRIEF_EDIT_V3,
		exact: true,
		component: BriefEditV3Container,
		// meta: {
		//   [GUARD_META.AUTH_ONLY]: true,
		//   [GUARD_META.CLIENT_ONLY]: true,
		// },
	},
	{
		path: PATHS.BRIEF_EDIT_V2,
		exact: true,
		component: BriefV2EditContainer,
		// meta: {
		//   [GUARD_META.AUTH_ONLY]: true,
		//   [GUARD_META.CLIENT_ONLY]: true,
		// },
	},
	{
		path: PATHS.BRIEF,
		exact: true,
		component: BriefListContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	{
		path: PATHS.BRIEF_VIEW,
		exact: true,
		component: BriefViewContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
		},
	},
	{
		path: PATHS.CREATE_BRIEF,
		exact: true,
		component: BriefCreateContainer,
		// meta: {
		//   [GUARD_META.AUTH_ONLY]: true,
		//   [GUARD_META.CLIENT_ONLY]: true,
		// },
	},
	{
		path: PATHS.BRIEF_EDIT,
		exact: true,
		component: BriefEditContainer,
		// meta: {
		//   [GUARD_META.AUTH_ONLY]: true,
		//   [GUARD_META.CLIENT_ONLY]: true,
		// },
	},

	{
		path: PATHS.BRIEF_REJECTED,
		exact: true,
		component: BriefRejectedContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	{
		path: PATHS.TESTAHEL_BRIEF_VIEW,
		exact: true,
		component: TestahelBriefViewContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
		},
	},
	{
		path: PATHS.TESTAHEL_BRIEF_CREATE,
		exact: true,
		component: TestahelBriefCreateContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	{
		path: PATHS.TESTAHEL_BRIEF_EDIT,
		exact: true,
		component: TestahelBriefEditContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	{
		path: PATHS.BILLS,
		exact: true,
		component: BillsListContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	{
		path: PATHS.PUBLIC_PROFILE,
		exact: true,
		component: PublicProfileContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.PARTNER_ONLY]: true,
		},
	},
	{
		path: PATHS.PARTNER_PROFILE,
		exact: true,
		component: ProfilePartnerContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.PARTNER_ONLY]: true,
		},
	},
	{
		path: PATHS.TEAM_MEMBERS,
		exact: true,
		component: TeamMembersContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.PARTNER_ONLY]: true,
		},
	},
	{
		path: PATHS.INVITE_CLIENT,
		exact: true,
		component: InvitingClientContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.PARTNER_ONLY]: true,
		},
	},
	{
		path: PATHS.PARTNER_API,
		exact: true,
		component: PartnerAPIContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.PARTNER_ONLY]: true,
		},
	},
	{
		path: PATHS.CLIENT_PROFILE,
		exact: true,
		component: ProfileClientContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	{
		path: PATHS.CLIENT_PROFILE_PREVIEW,
		exact: true,
		component: ProfileClientPreviewContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	{
		path: PATHS.PARTNER_DASHBOARD,
		exact: true,
		component: DashboardContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.PARTNER_ONLY]: true,
		},
	},
	{
		path: PATHS.UPDATE_PASSWORD,
		exact: true,
		component: UpdatePasswordContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
		},
	},
	{
		path: PATHS.UPDATE_EMAIL,
		exact: true,
		component: UpdateEmailContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
		},
	},
	// {
	// 	path: PATHS.ANNOUNCEMENTS,
	// 	exact: true,
	// 	component: Announcement,
	// 	meta: {
	// 		[GUARD_META.AUTH_ONLY]: true,
	// 		[GUARD_META.CLIENT_ONLY]: true,
	// 	},
	// },
	{
		path: PATHS.ANNOUNCEMENTS_BY_ID,
		exact: true,
		component: Announcement,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	// {
	// 	path: PATHS.MARKETPLACE,
	// 	exact: true,
	// 	component: Marketplace,
	// 	meta: {
	// 		[GUARD_META.AUTH_ONLY]: true,
	// 		[GUARD_META.CLIENT_ONLY]: true,
	// 	},
	// },
	{
		path: PATHS.REQUESTS,
		exact: true,
		component: RequestListContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.PARTNER_ONLY]: true,
		},
	},
	{
		path: PATHS.REQUEST_DETAILS,
		exact: true,
		component: RequestDetailsContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.PARTNER_ONLY]: true,
		},
	},
	{
		path: PATHS.PARTNER_CAMPAIGNS,
		exact: true,
		component: PartnerCampaignsContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.PARTNER_ONLY]: true,
		},
	},
	{
		path: PATHS.CAMPAIGNS,
		exact: true,
		component: ClientCampaignsContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	{
		path: PATHS.CAMPAIGNS_V3,
		exact: true,
		component: CampaignDetailsV3Container,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
			[GUARD_META.CLIENT_ONLY]: true,
		},
	},
	{
		path: PATHS.CAMPAIGN_VIEW,
		exact: true,
		component: CampaignDetailsContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
		},
	},
	{
		path: PATHS.BILLINGS,
		exact: true,
		component: BillingsContainer,
		meta: {
			[GUARD_META.AUTH_ONLY]: true,
		},
	},
	{
		path: PATHS.DEPLOYMENT_DETAILS,
		exact: true,
		component: DeploymentDetailsContainer,
	},
	{
		path: "*",
		component: NotFound,
		ignoreGlobal: true,
	},
]
