import {
  Col,
  Empty,
  IconsCommon,
  QBackLink,
  QButton,
  QRangePicker,
  QSelect,
  Row,
  Upload,
  Modal,
} from "quantum_components";
import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SpinnerCenter from "src/components/Spinner/spinner";
import { FORMATS, LOCALIZATION_LANGUAGES } from "src/constants";
import i18n from "src/i18n";
import history from "src/utils/history";
import {
  // completeCampaign,
  fetchCampaignAnalyticsDetails,
  fetchCampaignChartsDetails,
  fetchCampaignDetails,
  fetchCampaignReportDetails,
} from "../campaignV3.actions";
import lodash from "lodash";
import moment from "moment";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { getDateFromToday, getIsoDate } from "src/helpers/datesHelper";
import { DATE_PRESET } from "src/constants/dashboard";
import MediaBuyMetrics from "src/components/MediaBuyMetrics/MediaBuyMetrics";
import { useSelector } from "react-redux";
import { getCurrentUser } from "src/modules/core/core.selectors";
import { COLORS } from "src/constants/colors";

import { ReactComponent as OpenIcon } from "src/assets/icons/open-icon.svg";
import { CAMPAIGN_METRICS } from "src/constants/campaign";
import { useTranslation } from "react-i18next";
import BudgetUsageV3 from "./BudgetUsageV3.container";

import { getReportPdf, toggleReportPrintClass } from "src/utils/getScreenPage";
import ReportPage1 from "./campaignReport/ReportPage1";
import ReportPage2 from "./campaignReport/ReportPage2";
import ReportPage3 from "./campaignReport/ReportPage3";
import ReportPage4 from "./campaignReport/ReportPage4";


export default function CampaignDetailsV3() {
  const { t } = useTranslation(["translationV2", "translation"]);
  const { id: campaignId }: any = useParams();
  const checkIcon = (
    <IconsCommon.IconCheck
      color={COLORS.LIGHT_GREEN}
      className="check_report_icon"
    />
  );
  const downloadIcon = (
    <IconsCommon.IconDownload className="download_report_icon" />
  );
  const deleteIcon = <IconsCommon.IconDelete className="delete_report_icon" />;
  const [isLoading, setIsLoading] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState<any>({});
  const [analyticsDetails, setAnalyticsDetails] = useState<any>({});
  const [chartData, setChartData] = useState<any>({});
  // const [dateRange, changeDateRange] = useState<any>([
  //   moment(getIsoDate(getDateFromToday(DATE_PRESET.WEEK))),
  //   moment(getIsoDate(moment().endOf("day"))),
  // ]);
  const [dateRange, changeDateRange] = useState<any>(null);
  const currentUser = useSelector(getCurrentUser);
  const allServicesInCart = lodash
    .get(campaignDetails, "cart.additional.services", [])
    .map((service: any) => {
      return { label: service.name, value: service.name };
    });
  const [service, setService] = useState(null);
  const [mediaFilter, setMediaFilter] = useState<any>({
    afterDate: null,
    beforeDate: null,
    mediaType: null,
    partnerId: currentUser?.partnerProfileId,
  });
  const [campaignReportDetails, setCampaignReportDetails] = useState<any>({});
  const [reportDateRange, changeReportDateRange] = useState<any>(null);
  const [reportModalOpen, setReportModalOpen] = useState(false);

  useEffect(() => {
    setMediaFilter({
      ...mediaFilter,
      partnerId: currentUser?.partnerProfileId,
    });
  }, [currentUser]);

  const getCampaignDetails = async () => {
    setIsLoading(true);
    const res = await fetchCampaignDetails(campaignId);
    setCampaignDetails(res);
    setIsLoading(false);

    // check if we have the relevant details then pre select filters
  };

  const getCampaignAnalyticDetails = async () => {
    setIsLoading(true);
    const res = await fetchCampaignAnalyticsDetails(campaignId);
    setAnalyticsDetails(res);
    setIsLoading(false);
  };

  const getCampaignChartDetials = async () => {
    setIsLoading(true);
    const res = await fetchCampaignChartsDetails(
      campaignId,
      mediaFilter?.partnerId,
      mediaFilter?.mediaType,
      mediaFilter?.beforeDate,
      mediaFilter?.afterDate
    );
    setChartData(res);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!campaignId) return;
    getCampaignDetails();
    getCampaignAnalyticDetails();
  }, [campaignId]);

  useEffect(() => {
    if (
      !mediaFilter?.beforeDate ||
      !mediaFilter?.afterDate ||
      !mediaFilter?.mediaType ||
      !mediaFilter?.partnerId
    )
      return;

    getCampaignChartDetials();
  }, [mediaFilter]);

  const handleDatePicker = (range: any) => {
    if (!range) return;
    const start = range && getIsoDate(range[0].startOf("day"));
    const end = range && getIsoDate(range[1].endOf("day"));

    const filter = { ...mediaFilter };
    filter.beforeDate = start;
    filter.afterDate = end;

    setMediaFilter(filter);
    changeDateRange([moment(start), moment(end)]);
  };

  const handleResetFilter = () => {
    const filter = { ...mediaFilter };
    delete filter.beforeDate;
    delete filter.afterDate;
    filter.mediaType = null;

    setMediaFilter(filter);
    changeDateRange(null);
    setService(null);
  };

  const handleServiceChange = (service: any) => {
    const filter = { ...mediaFilter };
    filter.mediaType = service;

    setMediaFilter(filter);
    setService(service);
  };

  const handleReportDatePicker = (range: any) => {
    if (!range) return;
    const start = range && getIsoDate(range[0].startOf("day"));
    const end = range && getIsoDate(range[1].endOf("day"));

    changeReportDateRange([moment(start), moment(end)]);
  };

  const handleDownloadReport = async () => {
    const start = reportDateRange && getIsoDate(reportDateRange[0].startOf("day"));
    const end = reportDateRange && getIsoDate(reportDateRange[1].endOf("day"));
    setIsLoading(true);
    const res = await fetchCampaignReportDetails(campaignId, start, end);
    setCampaignReportDetails(res);
    setIsLoading(false);
    downloadReport();
  }

  const downloadReport = async () => {
    setIsLoading(true)
    toggleReportPrintClass(true)

    const markup = document.getElementById('generate_report_print_area')

    if (!markup) return

    let hideDivs = document.querySelectorAll('div[hidden]')
    hideDivs[0].removeAttribute('hidden')

    setTimeout(async () => {
      const pdf: any = await getReportPdf(markup, 'en')
      hideDivs[0].setAttribute('hidden', 'true')
      const blob = pdf.output('blob')
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "Campaign Report.pdf";
      alink.click();
      toggleReportPrintClass(false)
      setIsLoading(false)
      setReportModalOpen(false);
      changeReportDateRange(null);
    }, 3500)
  }

  return (
    // @ts-ignore
    <div
      className={`qu-brief-layout full-height-v3 v3-edit-container  ${i18n.language === LOCALIZATION_LANGUAGES.ARABIC ? "html-rtl" : ""
        }`}
    >
      {isLoading && <SpinnerCenter />}
      <div
        className="qu-brief-main pt-25"
        style={{ maxHeight: "max-content", marginBottom: "100px" }}
      >
        <div className="">
          {/* header */}
          <Row justify="space-between" align="middle">
            <Col>
              <QBackLink
                title={t("Back")}
                onClick={() => history.push("/campaigns")}
              />
            </Col>
            <Row justify="end" align="middle">
              <Col>
                <QButton
                  htmlType="button"
                  type="primary"
                  size="middle"
                  className="qu-button-soft mr-15"
                  disabled={isLoading}
                  onClick={() =>
                    history.push(`/briefs/v3/edit/${campaignDetails?.brief?.id}`)
                  }
                >
                  {t("View Original Request")}
                </QButton>
              </Col>
              {i18n.language === LOCALIZATION_LANGUAGES.ENGLISH && <Col>
                              <QButton
                                htmlType="button"
                                type="primary"
                                size="middle"
                                className={`qu-button-soft mr-15`}
                                disabled={isLoading}
                                onClick={() => setReportModalOpen(true)}
                              >
                                {t("Download Report")}
                              </QButton>
                            </Col>}
            </Row>
          </Row>
          {/* header end */}

          <Row className="mt-20">
            <Col md={24}>
              <h2>{t("Automated Reports")}</h2>

              <Row style={{ gap: 15 }} className={"mt-20"}>
                <Col>
                  <label>{t("Select Service")}</label>
                  <QSelect
                    className="full-width mb-15"
                    showArrow
                    placeholder={t("Please select")}
                    options={allServicesInCart}
                    value={service}
                    onChange={handleServiceChange}
                    size="large"
                  />
                </Col>
                <Col>
                  <label>{t("Filter Results by Dates")}</label>
                  <QRangePicker
                    value={dateRange}
                    allowClear={false}
                    format={FORMATS.DATE_FORMAT}
                    onChange={handleDatePicker}
                    className="full-width"
                    size="large"
                    placeholder={[t("Start Date"), t("End Date")]}
                  />
                </Col>
                <Col style={{ alignSelf: "center" }}>
                  <QButton
                    type="primary"
                    size="small"
                    className="mt-10"
                    onClick={handleResetFilter}
                  >
                    {t("Clear Filter")}
                  </QButton>
                </Col>
              </Row>

              <Row className="mt-10">
                <Col md={20}>
                  <div className="grid-row grid-row--jcc">
                    <div className="grid-col">
                      {chartData && chartData?.metrics ? (
                        <MediaBuyMetrics
                          selectedMetrics={chartData?.selectedMetrics}
                          mediaType={mediaFilter?.mediaType || ""}
                          details={chartData?.metrics}
                          dateRange={dateRange}
                        />
                      ) : (
                        <div className="qu-chart-empty qu-chart-card h-300">
                          <Empty description={t("No Data")} />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row >
                <Col md={20}>
                  <BudgetUsageV3 campaignDetails={campaignDetails} chartData={chartData} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Modal
            wrapClassName={`qu-modal ${i18n.dir() === "rtl" ? "html-rtl" : ""}`}
            title={
              <h3>{t("Download Report")}</h3>
            }
            visible={reportModalOpen}
            centered
            width={400}
            onCancel={() => {
              setReportModalOpen(false);
              changeReportDateRange(null);
            }}
            footer={false}
          >
            <div className="mb-10">
              <div>
                <label className="text-bold label-required">
                  {t("Select Start & End Date")}
                </label>
              </div>
              <div>
                <QRangePicker
                  value={reportDateRange}
                  allowClear={false}
                  format={FORMATS.DATE_FORMAT}
                  onChange={handleReportDatePicker}
                  className="full-width"
                  size="large"
                  placeholder={[t("Start Date"), t("End Date")]}
                />
              </div>
            </div>
            <div className="mt-20">
              <QButton
                size={"large"}
                type="primary"
                htmlType="button"
                className="full-width"
                onClick={handleDownloadReport}
                disabled={!reportDateRange}
              >
                <div className="flex-row" style={{ gap: "5px" }}>
                  <div>{t("Download Report")}</div>
                  <div><CloudDownloadOutlined /></div>
                </div>
              </QButton>
            </div>
          </Modal>
        </div>
      </div>
      {
        campaignReportDetails && campaignReportDetails?.campaignName &&
        <div style={{ width: '50%', position: 'absolute', opacity: 0, pointerEvents: 'none' }}>
          <Row id="generate_report_print_area" className="generate_report_print_area" hidden>
            <Col span={24}>
              <ReportPage1 campaignReportDetails={campaignReportDetails} />
              <ReportPage2 campaignReportDetails={campaignReportDetails} />
              <ReportPage3 campaignReportDetails={campaignReportDetails} />
              <ReportPage4 campaignReportDetails={campaignReportDetails} />
            </Col>
          </Row>
        </div>
      }
    </div>
  );
}
