import { IState } from './activateAccount.types'
import * as CONSTANTS from './activateAccount.constants'
import IAction from 'src/interfaces/IAction'

const initialState: IState = {
  signUpError: '',
  isLoading: false,
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.ACTIVATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CONSTANTS.ACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CONSTANTS.ACTIVATE_ACCOUNT_FAILURE:
      return { ...state, signUpError: action.error, isLoading: false }
    default:
      return state
  }
}
