import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  QCheckbox,
  QSelectImage,
  IconsCommon,
  QButton,
  Empty,
  QPagination,
  QInput,
  QSelect,
} from 'quantum_components'

import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import {
  getCampaignAnalyticsInfo,
  getClientGalleryDownloading,
  getClientGalleryList,
  getClientGalleryLoading,
  getClientGalleryPages,
  getClientGalleryParams,
  getTotalClientGalleryList,
} from '../campaign.selectors'
import SpinnerComponent from 'src/components/Spinner/SpinnerComponent'
import GalleryImageModal from './GalleryImageModal'
import { actions } from '../campaign.module'
import { useParams } from 'react-router-dom'
import { ICampaignPartner, IClientGallery } from '../campaign.types'
import { FORMATS } from 'src/constants'
import moment from 'moment'
import { GALLERY_PER_PAGE } from 'src/constants/campaign'
import useDebounce from 'src/hooks/useDebounce'
import { SearchOutlined } from '@ant-design/icons'
import { IOptionForSelect } from '../../brief/brief.types'
import { CAMPAIGN_PARTNERS, CAMPAIGN_PARTNERS_NAMES } from 'src/constants/campaign'
import { downloadFile } from 'src/utils/downloadFile'

const ClientGallery = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translation', 'translationCommon', 'translationCampaign'])
  const dispatch = useDispatch()
  const loading = useSelector(getClientGalleryLoading)
  const params = useSelector(getClientGalleryParams)
  const gallery = useSelector(getClientGalleryList)
  const pages = useSelector(getClientGalleryPages)
  const total = useSelector(getTotalClientGalleryList)
  const downloading = useSelector(getClientGalleryDownloading)
  const analyticsInfo = useSelector(getCampaignAnalyticsInfo)

  const [selectMode, setSelectMode] = useState<boolean>(false)
  const [selected, setSelected] = useState<IClientGallery[]>([])
  const [activeItem, setActiveItem] = useState<IClientGallery | null>(null)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const { id } = useParams<{ id: any }>()

  const fetchGallery = async () => {
    await dispatch(actions.setParamsAndFetchGallery(id, { ...params, offset: 0, limit: GALLERY_PER_PAGE }))
    await dispatch(actions.setClientGalleryCurrentPage(1))
  }

  useEffect(() => {
    dispatch(
      actions.setParamsAndFetchGallery(id, { ...params, search: debouncedSearch, offset: 0, limit: GALLERY_PER_PAGE })
    )
    dispatch(actions.setClientGalleryCurrentPage(1))
  }, [debouncedSearch])

  useEffect(() => {
    fetchGallery()
  }, [])

  useEffect(() => {
    const index = activeItem ? gallery.indexOf(activeItem) : null
    setActiveIndex(index)
  }, [activeItem])

  useEffect(() => {
    setSelectMode(selected.length > 0)
  }, [selected])

  const handleSelectAll = (e: any) => {
    e.target.checked ? setSelected(gallery) : setSelected([])
  }

  const handleChangeSearch = (e: any) => {
    setSearch(e.currentTarget.value)
  }

  const handleSelect = (item: IClientGallery) => {
    const updated = [...selected]

    updated.includes(item) ? updated.splice(selected.indexOf(item), 1) : updated.push(item)

    setSelected(updated)
  }

  const handleChangePage = async (page: number, pageSize: number) => {
    await dispatch(actions.setParamsAndFetchGallery(id, { ...params, offset: (page - 1) * pageSize, limit: pageSize }))
    await dispatch(actions.setClientGalleryCurrentPage(page))
  }

  const handleOpenImage = (item: IClientGallery) => {
    setActiveItem(item)
  }

  const handleClickImage = (item: IClientGallery) => {
    if (selectMode) {
      handleSelect(item)
    } else {
      handleOpenImage(item)
    }
  }

  const handleCloseModal = () => {
    setActiveItem(null)
  }

  const handleSelectMode = () => {
    setSelectMode(true)
  }

  const handleDownload = async () => {
    if (!activeItem) return
    downloadFile(activeItem.imageUrl)
  }

  const handleDownloadAll = async () => {
    if (!selected.length) return
    const urls = selected.map((item: IClientGallery) => item.imageUrl)
    await dispatch(actions.downloadClientGallery(urls))

    setSelected([])
  }

  const handleLeft = () => {
    if (activeIndex === null) return
    if (activeIndex === 0) return
    const prev = gallery[activeIndex - 1]
    setActiveItem(prev)
  }

  const handleRight = () => {
    if (activeIndex === null) return
    if (activeIndex === gallery.length - 1) return
    const next = gallery[activeIndex + 1]
    setActiveItem(next)
  }

  const handlePartnerIds = (partnerIds: number[]) => {
    dispatch(actions.setParamsAndFetchGallery(id, { ...params, offset: 0, limit: GALLERY_PER_PAGE, partnerIds }))
    dispatch(actions.setClientGalleryCurrentPage(1))
  }

  const selectAllChecked = selected.length > 0 && selected.length === gallery.length
  const downloadCount = !selected.length ? '' : `(${selected.length})`

  const partnerOptions: IOptionForSelect[] = analyticsInfo
    ? analyticsInfo.partners.map((partner: ICampaignPartner) => ({ label: partner.companyName, value: partner.id }))
    : []

  const partnerAllOptions = [
    { label: CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.QUANTUM_TEAM], value: CAMPAIGN_PARTNERS.QUANTUM_TEAM },
    { label: CAMPAIGN_PARTNERS_NAMES[CAMPAIGN_PARTNERS.ALL_PARTNERS], value: CAMPAIGN_PARTNERS.ALL_PARTNERS },
    ...partnerOptions,
  ]

  return (
    <div className="gallery">
      <Row gutter={16} justify={'space-between'} align={'middle'}>
        <Col xs={24} md={3} className="mb-20">
          <QCheckbox checked={selectAllChecked} onChange={handleSelectAll}>
            {t('common.Select_all', { ns: 'translationCommon' })}
          </QCheckbox>
        </Col>
        <Col xs={24} md={8} className="mb-20">
          <QInput
            className={`${isMobile && 'full-width'}`}
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('common.Search', { ns: 'translationCommon' })}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        {/*<Col xs={24} md={8} className="mb-20">
          <QSelect
            className="full-width"
            showArrow
            allowClear
            maxTagCount={1}
            maxTagTextLength={6}
            mode="multiple"
            placeholder="Select Partners"
            options={partnerAllOptions}
            onChange={handlePartnerIds}
            size="large"
          />
        </Col>*/}
        <Col xs={24} md={5} className="mb-20">
          {selectMode ? (
            <QButton
              loading={downloading}
              disabled={downloading}
              onClick={handleDownloadAll}
              className="qu-button-outline"
              style={{ width: '100%' }}
            >
              {`${t('campaign.details.download', { ns: 'translationCampaign' })} ${downloadCount}`}
              <IconsCommon.IconDownload className="ml-10" />
            </QButton>
          ) : (
            <QButton onClick={handleSelectMode} className="qu-button-outline" style={{ width: '100%' }}>
              {t('campaign.details.select', { ns: 'translationCampaign' })}
              <IconsCommon.IconDownload className="ml-10" />
            </QButton>
          )}
        </Col>
      </Row>
      {loading ? (
        <SpinnerComponent />
      ) : !gallery.length ? (
        <div className="qu-chart-empty h-300">
          <Empty />
        </div>
      ) : (
        <Row gutter={8}>
          {gallery.map((item: IClientGallery) => (
            <Col key={item.imageId} xs={12} sm={8} md={6} lg={4} className="mb-8">
              <QSelectImage
                checked={selected.includes(item)}
                onChange={() => handleClickImage(item)}
                onDelete={() => handleOpenImage(item)}
                title={item.title}
                text={item.description}
                date={moment(item.startedDate).format(FORMATS.DATE_LL_FORMAT)}
                inputName="card"
                cardImage={item.imageUrl}
                isPreview
              />
            </Col>
          ))}
        </Row>
      )}
      <Row className={`${isMobile && 'pt-10'}`} justify={`${isMobile ? 'center' : 'end'}`}>
        <QPagination
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          defaultPageSize={GALLERY_PER_PAGE}
          total={total}
          onChange={handleChangePage}
        />
      </Row>
      {activeItem && (
        <GalleryImageModal
          modalOpen={!!activeItem}
          title={activeItem.title}
          imgUrl={activeItem.imageUrl}
          description={activeItem.description}
          uploadedOn={moment(activeItem.startedDate).format(FORMATS.DATE_LL_FORMAT)}
          onClose={handleCloseModal}
          onDownload={handleDownload}
          downloading={downloading}
          onLeftClick={handleLeft}
          onRightClick={handleRight}
          showLeftArrow={activeIndex !== 0}
          showRightArrow={activeIndex !== gallery.length - 1}
        />
      )}
    </div>
  )
}

export default ClientGallery
