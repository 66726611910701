import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import {
  IBrief,
  IBriefSettings,
  IProductInitState,
  IState,
  ITargetingInitState,
  IBriefResponse,
  IServiceInitState,
  IMediaBuyInitState,
  IPartnerFilter,
  IPartnerFilterSettings,
} from './briefv3.types'
import * as CONSTANTS from './briefv3.constants'
import IAction from 'src/interfaces/IAction'
import {
  BRIEF_PAYMENT_STATUS,
  BRIEF_SORT_TYPE,
  BRIEF_STATUS,
  BRIEF_STEPS,
  CREATE_BRIEF_STEPS,
  DEFAULT_BRIEF_TABLE_PARAMS,
  BRIEF_PAYMENT_SELECTED_OPTIONS,
} from '../../constants/brief'
import { IBillPaymentUploadTypes } from '../client/bills/bills.types'

export const IBriefInitState: IBrief = {
  id: null,
  promotionId:null,
  paymentEnabled:false,
  status: BRIEF_STATUS.DRAFT,
  lastFilledStep: null,
  [BRIEF_STEPS.TYPE]: { briefTypes: [] },
  [BRIEF_STEPS.PRODUCT]: cloneDeep(IProductInitState),
  [BRIEF_STEPS.TARGETING]: cloneDeep(ITargetingInitState),
  [BRIEF_STEPS.SERVICES]: cloneDeep(IServiceInitState),
  [BRIEF_STEPS.MEDIA_BUY]: cloneDeep(IMediaBuyInitState),
  [BRIEF_STEPS.PARTNER]: { quantity: 0, hasFreeSample: false },
  [BRIEF_STEPS.UPLOAD]: { uploadMaterial: [] },
  [BRIEF_STEPS.PAYMENT]: null,
  [BRIEF_STEPS.SUMMARY]: null,
}

const settingsInitState: IBriefSettings = {
  ageGroups: [],
  partnerProfileId:0,
  targetingToggles:[],
  distributionItems: [],
  productTypes: [],
  briefDurations: [],
  mediaDurations: [],
  targetSegments: [],
  targetCampaigns: [],
  targetingKeys:[],
  packagingTypes: [],
  mediaTypes: [],
  countries: [],
  MINIMUM_DISTRIBUTION_LOGISTIC_COUNT: 0,
  temperature: [],
  copackingPrices: [],
  printingPrices: [],
  managementFee: 0,
  partnerVat: '',
}

export const IPartnerFilterSettingsInitState: IPartnerFilterSettings = {
  categories: [],
  pricePerUnit: [],
  matches: [],
  types: [],
  sortBy: [],
  mediaSubtypes: [],
}

export const IPartnerFilterInitState: IPartnerFilter = {
  search: '',
  categories: [],
  pricePerUnit: [],
  matches: [],
  types: [],
  sortBy: BRIEF_SORT_TYPE.BEST_MATCH,
}

const initialState: IState = {
  createBriefError: '',
  isSaving: false,
  currentStep: CREATE_BRIEF_STEPS[1],
  needCheckLastStep: true,
  brief: cloneDeep(IBriefInitState),
  copackingPrice: null,
  printingPrice: null,
  fetchingBriefListError: '',
  briefListLoading: true,
  briefList: {
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  params: DEFAULT_BRIEF_TABLE_PARAMS,

  fetchSettingsError: '',
  settingsLoading: false,
  settings: settingsInitState,
  viewBrief: {} as IBriefResponse,
  viewBriefPaymentFilesState: {
    po: {
      isUploading: false,
      uploadingError: '',
      isRemoving: false,
      removingError: '',
    },
    bankTransfer: {
      isUploading: false,
      uploadingError: '',
      isRemoving: false,
      removingError: '',
    },
  },
  imagesUploading: false,
  imagesUploadingError: '',
  imagesRemoving: false,
  imagesRemovingError: '',
  partnerFilterSettingsError: '',
  serviceImages: '',
  productImages: '',
  partnerFilterSettingsLoading: false,
  partnerFilterSettings: IPartnerFilterSettingsInitState,
  partnerFilter: cloneDeep(IPartnerFilterInitState),
  fetchRecommendedPartnersLoading: false,
  recommendedPartners: [],
  fetchPartnerDetailsLoading: false,
  partnerDetails: null,
  fetchCartLoading: false,
  updateCartLoading: false,
  cart: {
    briefId: null,
    sampleAllocated: 0,
    additional: {
      managementFee: 0,
      managementFeeRate: 0,
      couponFee: 0,
      promotionCouponFee:0,
      taxes: 0,
      cartTotal: 0,
      items: [],
      copackingTotal: 0,
      printingTotal: 0,
      servicesTotal: 0,
      subTotal: 0,
      copackingMinQuantity: 0,
      printingMinQuantity: 0,
      promotionCartTotal:0,
      promotionTaxes:0
    },
    mediaBuyEstimates: [],
  },
  activeSinglePartnerId: null,
  totalQuantity: 0,
  uploadSettings: [],
  uploadSettingsLoading: false,
  uploadStepLoading: [],
  rejectionFlow: false,
  dialogModal: null,
  sendProposalLoading: false,
  exclusivePartnerId: null,
  potentialAudienceReach: {
    totalSampleAudience: 0,
    totalMediaBuyAudience: 0,
    messageSampleDistribution: '',
    messageMediaBuy: '',
  },
  paymentSelectedType: BRIEF_PAYMENT_SELECTED_OPTIONS[1],
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.REQUEST_START:
      return {...state,isSaving:true}

      case CONSTANTS.REQUEST_END:
        return {...state,isSaving:false}
    
    case CONSTANTS.RESET_BRIEF_STATE:
      return initialState

    case CONSTANTS.SET_NEED_CHECK_LAST_STEP:
      return { ...state, needCheckLastStep: get(action, 'payload.check') }

    case CONSTANTS.SET_REJECTION_FLOW:
      return { ...state, rejectionFlow: get(action, 'payload.rejection') }

    case CONSTANTS.SET_DIALOG_MODAL:
      return { ...state, dialogModal: get(action, 'payload.modal') }

    case CONSTANTS.SET_CURRENT_STEP:
      return { ...state, currentStep: get(action, 'payload.step') }

    case CONSTANTS.SET_SELECTED_PAYMENT_TYPE:
      return { ...state, paymentSelectedType: get(action, 'payload.paymentType') }

    case CONSTANTS.CREATE_BRIEF_REQUEST:
      return {
        ...state,
        createBriefError: initialState.createBriefError,
        isSaving: true,
      }
    case CONSTANTS.CREATE_BRIEF_SUCCESS:
      return {
        ...state,
        brief: get(action, 'payload.brief'),
        isSaving: false,
      }
    case CONSTANTS.CREATE_BRIEF_PRICING:
      return {
        ...state,
        copackingPrice: get(action, 'payload.copackingPrice'),
        printingPrice: get(action, 'payload.printingPrice'),
      }
    case CONSTANTS.CREATE_BRIEF_SUCCESS_EXCLUSIVE_PARTNER_ID:
      return {
        ...state,
        exclusivePartnerId: get(action, 'payload.exclusivePartnerId'),
      }
    case CONSTANTS.CREATE_BRIEF_FAILURE:
      return { ...state, createBriefError: action.error, isSaving: false }

    case CONSTANTS.FETCH_BRIEFS_REQUEST:
      return {
        ...state,
        briefListLoading: true,
      }
    case CONSTANTS.FETCH_BRIEFS_SUCCESS:
      const briefList = get(action, 'payload.briefList') || {results:[],total:0}
      const totalPages = Math.ceil(briefList.total / state.params.limit)
      return {
        ...state,
        briefList,
        pages: { ...state.pages, totalPages },
        briefListLoading: false,
      }
    case CONSTANTS.FETCH_BRIEFS_FAILURE:
      return { ...state, fetchingBriefListError: action.error, briefListLoading: false }
    case CONSTANTS.FETCH_BRIEF_SETTINGS_REQUEST:
      return {
        ...state,
        fetchSettingsError: initialState.fetchSettingsError,
        settingsLoading: true,
      }
    case CONSTANTS.FETCH_BRIEF_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: get(action, 'payload.settings'),
        settingsLoading: false,
      }
    case CONSTANTS.FETCH_BRIEFS_SETTINGS_FAILURE:
      return { ...state, fetchSettingsError: action.error, settingsLoading: false }

    case CONSTANTS.SET_BRIEF_LIST_PARAMS:
      return { ...state, params: get(action, 'payload.params') }

    case CONSTANTS.DELETE_BRIEF_REQUEST:
      return { ...state }
    case CONSTANTS.DELETE_BRIEF_SUCCESS:
      return { ...state }
    case CONSTANTS.DELETE_BRIEF_FAILURE:
      return { ...state }
    case CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, pages: { ...state.pages, currentPage: get(action, 'payload.page') } }

    case CONSTANTS.VIEW_BRIEF_REQUEST:
      return { ...state }
    case CONSTANTS.VIEW_BRIEF_SUCCESS:
      return { ...state, viewBrief: get(action, 'payload.briefDetails') }
    case CONSTANTS.VIEW_BRIEF_FAILURE:
      return { ...state }

    case CONSTANTS.VIEW_BRIEF_PAY_ONLINE_SUCCESS:
      return {
        ...state,
        viewBrief: {
          ...state.viewBrief,
          payment: { ...state.viewBrief.payment, status: BRIEF_PAYMENT_STATUS.PROCESSING },
        },
      }

    case CONSTANTS.VIEW_BRIEF_FILE_UPLOAD_REQUEST: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      state.viewBriefPaymentFilesState[filesType].isUploading = true
      state.viewBriefPaymentFilesState[filesType].uploadingError = ''

      return { ...state }
    }

    case CONSTANTS.VIEW_BRIEF_FILE_UPLOAD_SUCCESS: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      state.viewBriefPaymentFilesState[filesType].isUploading = false
      state.viewBrief.payment.inReview = true
      state.viewBrief.payment.status = BRIEF_PAYMENT_STATUS.PENDING

      const newFilesUrls = get(action, 'payload.newFilesUrls')||[]
      state.viewBrief.payment[filesType] = [...newFilesUrls, ...(state.viewBrief.payment[filesType] ?? [])]

      return { ...state }
    }

    case CONSTANTS.VIEW_BRIEF_FILE_UPLOAD_FAILURE: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      state.viewBriefPaymentFilesState[filesType].isUploading = false
      state.viewBriefPaymentFilesState[filesType].uploadingError = get(action, 'payload.error')

      return { ...state }
    }

    case CONSTANTS.VIEW_BRIEF_FILE_REMOVE_REQUEST: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      state.viewBriefPaymentFilesState[filesType].isRemoving = true
      state.viewBriefPaymentFilesState[filesType].removingError = ''

      return { ...state }
    }

    case CONSTANTS.VIEW_BRIEF_FILE_REMOVE_SUCCESS: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      state.viewBriefPaymentFilesState[filesType].isRemoving = false

      const urlToRemove = get(action, 'payload.urlToRemove')
      state.viewBrief.payment[filesType] = state.viewBrief.payment[filesType]!.filter(
        oldFileUrl => oldFileUrl !== urlToRemove
      )

      return { ...state }
    }

    case CONSTANTS.VIEW_BRIEF_FILE_REMOVE_FAILURE: {
      const filesType: IBillPaymentUploadTypes = get(action, 'payload.filesType')
      state.viewBriefPaymentFilesState[filesType].isRemoving = false
      state.viewBriefPaymentFilesState[filesType].removingError = get(action, 'payload.error')

      return { ...state }
    }

    case CONSTANTS.IMAGE_UPLOAD_REQUEST:
      return { ...state, imagesUploading: true }
    case CONSTANTS.IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        brief: get(action, 'payload.brief'),
        imagesUploading: false,
      }
    case CONSTANTS.IMAGE_UPLOAD_FAILURE:
      return { ...state, imagesUploadingError: action.error, imagesUploading: false }

    case CONSTANTS.IMAGE_REMOVE_REQUEST:
      return { ...state, imagesRemoving: true }
    case CONSTANTS.IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        brief: get(action, 'payload.brief'),
        imagesRemoving: false,
      }
    case CONSTANTS.IMAGE_REMOVE_FAILURE:
      return { ...state, imagesRemovingError: action.error, imagesRemoving: false }

    case CONSTANTS.FETCH_PARTNER_FILTER_SETTINGS_REQUEST:
      return {
        ...state,
        partnerFilterSettingsError: initialState.fetchSettingsError,
        partnerFilterSettingsLoading: true,
      }
    case CONSTANTS.FETCH_PARTNER_FILTER_SETTINGS_SUCCESS:
      return {
        ...state,
        partnerFilterSettings: get(action, 'payload.settings'),
        partnerFilterSettingsLoading: false,
      }
    case CONSTANTS.FETCH_PARTNER_FILTER_SETTINGS_FAILURE:
      return { ...state, partnerFilterSettingsError: action.error, partnerFilterSettingsLoading: false }

    case CONSTANTS.SET_PARTNER_FILTER:
      return {
        ...state,
        partnerFilter: get(action, 'payload.filter'),
      }
    case CONSTANTS.RESET_PARTNER_FILTER:
      return {
        ...state,
        partnerFilter: initialState.partnerFilter,
      }

    case CONSTANTS.FETCH_RECOMMENDED_PARTNERS_REQUEST:
      return {
        ...state,
        fetchRecommendedPartnersLoading: true,
      }
    case CONSTANTS.FETCH_RECOMMENDED_PARTNERS_SUCCESS:
      return {
        ...state,
        recommendedPartners: get(action, 'payload.partners'),
        fetchRecommendedPartnersLoading: false,
      }
    case CONSTANTS.FETCH_RECOMMENDED_PARTNERS_FAILURE:
      return { ...state, partnerFilterSettingsError: action.error, fetchRecommendedPartnersLoading: false }

    case CONSTANTS.FETCH_PARTNER_DETAILS_REQUEST:
      return {
        ...state,
        fetchPartnerDetailsLoading: true,
      }
    case CONSTANTS.FETCH_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        partnerDetails: get(action, 'payload.partner'),
        fetchPartnerDetailsLoading: false,
      }
    case CONSTANTS.FETCH_PARTNER_DETAILS_FAILURE:
      return { ...state, partnerFilterSettingsError: action.error, fetchPartnerDetailsLoading: false }

    case CONSTANTS.FETCH_CART_REQUEST:
      return {
        ...state,
        fetchCartLoading: true,
      }
    case CONSTANTS.FETCH_CART_SUCCESS:
      return {
        ...state,
        cart: isEmpty(get(action, 'payload.cart')) ? initialState.cart : get(action, 'payload.cart'),
        fetchCartLoading: false,
      }
    case CONSTANTS.FETCH_CART_FAILURE:
      return { ...state, partnerFilterSettingsError: action.error, fetchCartLoading: false }

    case CONSTANTS.UPDATE_CART_REQUEST:
      return { ...state, updateCartLoading: true }
    case CONSTANTS.UPDATE_CART_SUCCESS:
      return { ...state, updateCartLoading: false }
    case CONSTANTS.UPDATE_CART_FAILURE:
      return { ...state, updateCartLoading: false }

    case CONSTANTS.SET_ACTIVE_SINGLE_PARTNER:
      return {
        ...state,
        activeSinglePartnerId: get(action, 'payload.partnerId'),
      }

    case CONSTANTS.SET_TOTAL_QUANTITY:
      return {
        ...state,
        totalQuantity: get(action, 'payload.totalQuantity'),
      }

    case CONSTANTS.FETCH_UPLOAD_SETTINGS_REQUEST:
      return {
        ...state,
        uploadSettingsLoading: true,
      }
    case CONSTANTS.FETCH_UPLOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        uploadSettings: get(action, 'payload.uploadSettings'),
        uploadSettingsLoading: false,
      }
    case CONSTANTS.FETCH_UPLOAD_SETTINGS_FAILURE:
      return { ...state, partnerFilterSettingsError: action.error, uploadSettingsLoading: false }

    case CONSTANTS.IMAGE_UPLOAD_STEP_REQUEST:
      return { ...state, uploadStepLoading: [...state.uploadStepLoading, get(action, 'payload.key')] }

    case CONSTANTS.IMAGE_UPLOAD_STEP_SUCCESS:
      const newUploadLoading = [...state.uploadStepLoading]
      const key = get(action, 'payload.key')

      if (newUploadLoading.includes(key)) {
        newUploadLoading.splice(newUploadLoading.indexOf(key), 1)
      }

      return {
        ...state,
        brief: get(action, 'payload.brief'),
        uploadStepLoading: newUploadLoading,
      }

    case CONSTANTS.IMAGE_UPLOAD_STEP_FAILURE:
      return { ...state, imagesUploadingError: action.error, uploadStepLoading: [] }

    case CONSTANTS.EMAIL_PROPOSAL_REQUEST:
      return { ...state, sendProposalLoading: true }
    case CONSTANTS.EMAIL_PROPOSAL_SUCCESS:
      return { ...state, sendProposalLoading: false }
    case CONSTANTS.EMAIL_PROPOSAL_FAILURE:
      return { ...state, sendProposalLoading: false }
    case CONSTANTS.POTENTIAL_AUDIENCE_REACH_SUCCESS:
      return { ...state, potentialAudienceReach: get(action, 'payload.potentialAudienceReach') }

    default:
      return state
  }
}
