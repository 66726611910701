import { requestHttp, urls } from 'src/api'

export const getAllProductsList = async ({ offset, limit, direction, sortField, search }: any) => {
    try {
        const response = await requestHttp.get(urls.getListAllProductsUrl({ offset, limit, direction, sortField, search }))
        const { content } = response.data.data
        return content
    } catch (error) {
        return error
    }
}