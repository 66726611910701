import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Badge, Menu } from 'quantum_components'
import { useHistory, useLocation } from 'react-router-dom'
import { PATHS } from '../../../../constants'
import { isMobile } from 'react-device-detect'
import * as actions from '../../../../modules/core/core.actions'
import { useReduxDispatch } from 'src/helpers'
import { useSelector } from 'react-redux'
import { getPartnerPartnerPercent } from '../../../../modules/partner/partnerProfile/profile.selectors'
import { getPartnerPublicPercent } from '../../../../modules/partner/publicProfile/publicProfile.selectors'
import { getCurrentUser } from '../../../../modules/core/core.selectors'
import { MENU_ITEMS } from 'src/constants/menu'
import { activeMenuItem } from 'src/helpers/sideMenu'

interface MenuItem {
  title: string
  path: string
  badgeCount?: string | number
  badgeClass?: string
  submenu?: MenuItem[]
  callback?: () => void
}

const SidebarMenu = () => {
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const publicPercent = useSelector(getPartnerPublicPercent)
  const partnerPercent = useSelector(getPartnerPartnerPercent)
  const location = useLocation()

  const onRedirect = (url: string) => {
    history.push(url)
  }

  const logout = () => dispatch(actions.logout())
  const currentUser: any = useSelector(getCurrentUser)
  const [menuCenter, setMenuCenter] = useState<any>([
    {
      title: MENU_ITEMS.PARTNER_PROFILE,
      path: PATHS.PARTNER_PROFILE,
      badgeCount: `${partnerPercent}%`,
      badgeClass: partnerPercent === 100 ? 'complete' : '',
    },
    {
      title: MENU_ITEMS.PUBLIC_PROFILE,
      path: PATHS.PUBLIC_PROFILE,
      badgeCount: `${publicPercent}%`,
      badgeClass: publicPercent === 100 ? 'complete' : '',
    },
  ])
  const [menuFooter, setMenuFooter] = useState<any>([])

  useEffect(() => {
    if (currentUser.partnerPermissionLevel == 'admin') {
      setMenuCenter([
        {
          title: MENU_ITEMS.PARTNER_PROFILE,
          path: PATHS.PARTNER_PROFILE,
          badgeCount: `${partnerPercent}%`,
          badgeClass: partnerPercent === 100 ? 'complete' : '',
        },
        {
          title: MENU_ITEMS.PUBLIC_PROFILE,
          path: PATHS.PUBLIC_PROFILE,
          badgeCount: `${publicPercent}%`,
          badgeClass: publicPercent === 100 ? 'complete' : '',
        },
        { title: MENU_ITEMS.TEAM_MEMBERS, path: PATHS.TEAM_MEMBERS },
      ])
    } else {
      setMenuCenter([
        {
          title: MENU_ITEMS.PARTNER_PROFILE,
          path: PATHS.PARTNER_PROFILE,
          badgeCount: `${partnerPercent}%`,
          badgeClass: partnerPercent === 100 ? 'complete' : '',
        },
        {
          title: MENU_ITEMS.PUBLIC_PROFILE,
          path: PATHS.PUBLIC_PROFILE,
          badgeCount: `${publicPercent}%`,
          badgeClass: publicPercent === 100 ? 'complete' : '',
        },
      ])
    }

    if (currentUser.partnerApiAccess)
      setMenuFooter([
        { title: MENU_ITEMS.INVITE_CLIENT, path: PATHS.INVITE_CLIENT },
        { title: MENU_ITEMS.PARTNER_API, path: PATHS.PARTNER_API },
      ])
    else setMenuFooter([{ title: MENU_ITEMS.INVITE_CLIENT, path: PATHS.INVITE_CLIENT }])
  }, [partnerPercent, publicPercent, currentUser])

  const menuTop: MenuItem[] = [
    { title: MENU_ITEMS.DASHBOARD, path: PATHS.PARTNER_DASHBOARD },
    {
      title: MENU_ITEMS.REQUESTS,
      path: PATHS.REQUESTS,
      badgeCount: currentUser.openRequests ? currentUser.openRequests : '',
    },
    { title: MENU_ITEMS.BILLINGS, path: PATHS.BILLINGS },
    { title: MENU_ITEMS.CAMPAIGNS, path: PATHS.PARTNER_CAMPAIGNS },
  ]

  const menuUser = [
    { title: 'Update Password', path: PATHS.UPDATE_PASSWORD },
    { title: 'Log out', path: PATHS.ROOT, callback: () => logout() },
  ]

  const renderMenuItem = (item: MenuItem) => {
    if (item.submenu) {
      return (
        <Menu.SubMenu key={item.title} title={<span>{item.title}</span>}>
          {item.submenu.map((subItem: MenuItem) => (
            <Menu.Item
              key={`${item.title}-${subItem.title}`}
              onClick={() => (item.callback ? item.callback() : onRedirect(subItem.path))}
            >
              <span>{subItem.title}</span>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      )
    }
    return (
      <Menu.Item
        key={item.title}
        onClick={() => {
          if (item.callback) {
            item.callback()
          } else {
            onRedirect(item.path)
            if (item.title === MENU_ITEMS.PARTNER_PROFILE && item.badgeCount) {
              ReactGA.event({
                category: `${MENU_ITEMS.PARTNER_PROFILE} Completed`,
                action: item.badgeCount?.toString(),
                label: item.badgeCount?.toString(),
                value: 1,
              })
            }
          }
        }}
      >
        <span>{item.title}</span>
        {item.badgeCount && <Badge count={item.badgeCount} className={item.badgeClass} />}
      </Menu.Item>
    )
  }

  const activeMenu = activeMenuItem(location.pathname)

  return (
    <Menu className="sidebar-menu" mode="inline" selectedKeys={[activeMenu]}>
      {menuTop.map((item: MenuItem) => renderMenuItem(item))}
      <Menu.Divider />
      {menuCenter.map((item: MenuItem) => renderMenuItem(item))}
      <Menu.Divider />
      {menuFooter.map((item: MenuItem) => renderMenuItem(item))}
      {isMobile && <Menu.Divider />}
      {isMobile && menuUser.map((item: MenuItem) => renderMenuItem(item))}
    </Menu>
  )
}

export default SidebarMenu
