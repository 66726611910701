import React, { ReactElement } from 'react'
import { Col, Row } from 'quantum_components'
import { IBriefPartnerDetails, IPartnerDetailsCountry } from '../../modules/client/testahelBox/testahelBox.types'

interface Props {
  details: IBriefPartnerDetails
}
export default function TestahelDetailsAbout({ details }: Props): ReactElement {
  return (
    <div className="qu-details-list">
      <div className="list-item">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6}>
            <div className="list-item-title">Locations</div>
          </Col>
          <Col xs={24} sm={24} md={18}>
            {details.locations?.map((location: IPartnerDetailsCountry) => (
              <div key={location.name} className="list-item-row">
                <div className="list-item-label">{location.name}</div>
                <div className="list-item-value">{location.cities.join(', ')}</div>
              </div>
            ))}
          </Col>
        </Row>
      </div>
      {details.highlights && (
        <div className="list-item">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6}>
              <div className="list-item-title">Highlights</div>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <ul className="list-item-column">
                {details.highlights?.map((highlight: string, i: number) => (
                  <li key={i}>
                    <span>{highlight}</span>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      )}
      {details.metricsMarketing && (
        <div className="list-item">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6}>
              <div className="list-item-title">Metrics</div>
            </Col>
            <Col xs={24} sm={24} md={18}>
              <ul className="list-item-column">
                {details.metricsMarketing?.map((metric: string, i: number) => (
                  <li key={i}>
                    <span>{metric}</span>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}
