import ILocalFile from 'src/interfaces/ILocalFile'
import IUploadFile from 'src/interfaces/IUploadFile'

export const isLocalFile = (file: ILocalFile | IUploadFile): file is ILocalFile => {
  return Boolean((file as ILocalFile).file)
}

export const isUploadFile = (file: ILocalFile | IUploadFile): file is IUploadFile => {
  return !(file as ILocalFile).file
}
