import { createSelector } from 'reselect'
import { IRequestDetails, IRequestInfo, IRequestTable, IRequestTableParams, IState } from './request.types'
import { MODULE_NAME } from './request.constants'
import { requestsToTable } from '../../../helpers/request'
import { getCurrency } from '../../core/core.selectors'
import { CURRENCY } from '../../../constants/currencies'

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getRequestListLoading = createSelector(selectState, (state: IState): boolean => state.requestListLoading)

export const getRequestList = createSelector(
  [selectState, getCurrency],
  (state: IState, currency: CURRENCY): IRequestTable[] => {
    const tableList = requestsToTable(state.requestList.results, currency)

    return tableList
  }
)

export const getTotalRequestList = createSelector(selectState, (state: IState): number => state.requestList.total)

export const getRequestParams = createSelector(selectState, (state: IState): IRequestTableParams => state.params)

export const getPages = createSelector(
  selectState,
  (
    state: IState
  ): {
    totalPages: number
    currentPage: number
  } => state.pages
)

export const getRequestInfo = createSelector(selectState, (state: IState): IRequestInfo => state.requestInfo)
export const getRequestDetails = createSelector(
  selectState,
  (state: IState): IRequestDetails | null => state.requestDetails
)
export const getRequestDetailsLoading = createSelector(
  selectState,
  (state: IState): boolean => state.requestDetailsLoading
)

export const getUpdatingRequest = createSelector(selectState, (state: IState): boolean => state.updatingRequest)
