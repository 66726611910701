// @ts-nocheck
import React, { FC, useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import {
  Menu,
  Dropdown,
  IconsCommon,
  Row,
  QMenuItem,
  Col,
  QSelect,
  QInput,
  PartnerBillCardItem,
  QPagination,
  QTable,
} from 'quantum_components'
import { isMobile } from 'react-device-detect'
import UploadComponent from '../../../components/UploadComponent'
import {
  IBillingPaymentUploadTypes,
  ISelectInputOption,
  IBillingsPayment,
  IBillingsListElement,
} from '../billings.types'
import { useHistory } from 'react-router-dom'
import { useReduxDispatch } from '../../../helpers'
import { useSelector } from 'react-redux'
import {
  getBillingsList,
  getBillingsListLoading,
  getBillingsParams,
  getPages,
  getTotalBillingsList,
} from '../billings.selectors'
import useDebounce from '../../../hooks/useDebounce'
import * as coreActions from 'src/modules/core/core.actions'
import * as actions from '../billings.actions'
import * as campaignActions from '../../campaign/campaign.actions'
import { DEFAULT_BILL_TABLE_PARAMS } from '../../../constants/bill'
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg'
import { BRIEF_PAYMENT_STATUS, BRIEF_PAYMENT_STATUS_NAMES, briefPaymentStatuses } from '../../../constants/brief'

import { getFileNameFromUrl } from 'src/utils'
import { renderRoundedNumber } from '../../../helpers/currency'
import { getCurrency, getCurrentUserRole } from '../../core/core.selectors'
import { getCampaignStats } from '../../campaign/campaign.selectors'
import ROLES from '../../../constants/roles'
import IUploadFile from 'src/interfaces/IUploadFile'

const BillingsContainer: FC = () => {
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const billingList = useSelector(getBillingsList)
  const totalBillingList = useSelector(getTotalBillingsList)
  const billingListLoading = useSelector(getBillingsListLoading)
  const params = useSelector(getBillingsParams)
  const pages = useSelector(getPages)
  const campaignStats = useSelector(getCampaignStats)
  const role = useSelector(getCurrentUserRole)
  const currency = useSelector(getCurrency)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const tableLoading = {
    spinning: billingListLoading,
    indicator: <div className="loader loader-small" />,
  }

  useEffect(() => {
    dispatch(actions.setParamsAndFetch({ ...params, search: debouncedSearch, offset: 0, limit: 10 }))
    dispatch(actions.setCurrentPage(1))

    return () => dispatch(actions.setBillListParams(DEFAULT_BILL_TABLE_PARAMS))
  }, [debouncedSearch])

  useEffect(() => {
    if (role === ROLES.UNKNOWN) {
      return
    }

    dispatch(campaignActions.setParamsAndFetch(role, { offset: 0, limit: 10 }))
  }, [role])

  const columns = [
    {
      title: 'Product',
      dataIndex: '',
      key: 'product',
      width: '30%',
      fixed: 'left',
      sorter: true,
      render: (product: IBillingsListElement) => {
        return (
          <div className="q-table-product">
            <div className="q-table-product_thumb">
              <img
                src={product.productImageUrl[0] ? product.productImageUrl[0] : 'https://via.placeholder.com/50x50'}
                alt={product.productName || ''}
              />
            </div>
            <div className="q-table-product_info">
              <h3 className="q-table-product_title">{product.productName || 'Unnamed Billing'}</h3>
              <div className="q-table-product_created">
                <span>Received {product.lastApprovedDate}</span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'PO',
      dataIndex: 'payment',
      key: 'service',
      width: '20%',
      render: (payment: IBillingsPayment | null) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              alternativeFileView
              dragAndDrop={false}
              files={payment?.purchaseOrders?.map(({ po: url }) => ({ url, name: getFileNameFromUrl(url) })) ?? []}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: 'Invoice',
      dataIndex: 'payment',
      key: 'service',
      width: '20%',
      render: (payment: IBillingsPayment | null, billing: IBillingsListElement) => {
        const paymentPaid = payment?.status === BRIEF_PAYMENT_STATUS.PAID
        const filesType: IBillingPaymentUploadTypes = 'invoice'

        const isUploading = payment ? payment[filesType].isUploading : false
        const isRemoving = payment ? payment[filesType].isRemoving : false
        const isProcessing = isUploading || isRemoving
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              dragAndDrop={false}
              accept={'.pdf, .jpg, .jpeg, .png'}
              caption={['You can upload 1 file', 'Accepted file formats are: pdf, png, jpg, jpeg']}
              files={payment?.invoice.files ?? []}
              disabled={isProcessing || !payment}
              showRemoveButton={!paymentPaid}
              isUploading={isUploading}
              isRemoving={isRemoving}
              onChange={(files: File[]) => handleUpload({ bill: billing, files, filesType: 'invoice' })}
              onRemove={file => handleRemove({ bill: billing, file, filesType: 'invoice' })}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: 'Bank Transfer',
      dataIndex: 'payment',
      key: 'service',
      width: '20%',
      render: (payment: IBillingsPayment | null) => {
        return (
          <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <UploadComponent
              viewOnly
              dragAndDrop={false}
              files={payment?.bankTransfer?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []}
              disabled={!payment?.bankTransfer?.length}
              onView={async file => {
                const response: any = await dispatch(coreActions.downloadImage(file.url))
                const url = URL.createObjectURL(response.data)

                window.open(url, '_blank')
                URL.revokeObjectURL(url)
              }}
            />
          </div>
        )
      },
    },
    {
      title: 'Payment Status',
      dataIndex: '',
      key: 'service',
      width: '15%',
      render: (billing: IBillingsListElement) => {
        const paymentStatusClass = briefPaymentStatuses[billing.payment?.status]?.classStyle ?? ''
        const paymentStatusLabel = briefPaymentStatuses[billing.payment?.status]?.label ?? ''
        return (
          <div>
            {billing.payment ? (
              <span className={`status-label ${paymentStatusClass}`}>{paymentStatusLabel}</span>
            ) : (
              <span>-</span>
            )}

            <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
              <Dropdown overlay={menu(billing.id)} trigger={['click']} placement="bottomRight">
                <div className="q-table_menu">
                  <IconsCommon.IconEllipsisV />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      },
    },
  ]

  const menu = (id: number) => (
    <Menu className="user-menu-list">
      <QMenuItem key={0} onClick={() => handleView(id)} name="View" icon={<EyeIcon />} />
    </Menu>
  )

  const handleUpload = ({
    bill,
    files,
    filesType,
  }: {
    bill: IBillingsListElement
    files: File[]
    filesType: IBillingPaymentUploadTypes
  }) => {
    dispatch(actions.uploadPaymentFile({ bill, files, filesType }))
  }

  const handleRemove = ({
    bill,
    file,
    filesType,
  }: {
    bill: IBillingsListElement
    file: IUploadFile
    filesType: IBillingPaymentUploadTypes
  }) => {
    dispatch(actions.removePaymentFile({ bill, file, filesType }))
  }

  const handleChangeSearch = e => {
    setSearch(e.currentTarget.value)
  }

  const handleChangeSelect = (activePaymentStatuses: string[]) => {
    dispatch(actions.setParamsAndFetch({ ...params, offset: 0, paymentStatus: activePaymentStatuses }))
  }

  const handleChangePage = (page, pageSize) => {
    dispatch(actions.setCurrentPage(page))
    dispatch(actions.setParamsAndFetch({ ...params, offset: (page - 1) * pageSize, limit: pageSize }))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const colKey = {
      product: 'name',
    }
    const sortingParams = {
      direction: sorter.order.slice(0, -3),
      sortField: colKey[sorter.columnKey],
    }

    dispatch(
      actions.setParamsAndFetch({
        ...params,
        ...sortingParams,
      })
    )
  }

  const handleView = (requestId: number) => {
    history.push(`/requests/${requestId}`)
  }

  const handleOnRowClick = (request: IBillingsListElement) => ({
    onClick: () => handleView(request.id),
  })

  const statusOptions: ISelectInputOption[] = [
    { label: BRIEF_PAYMENT_STATUS_NAMES[BRIEF_PAYMENT_STATUS.PENDING], value: BRIEF_PAYMENT_STATUS.PENDING },
    { label: BRIEF_PAYMENT_STATUS_NAMES[BRIEF_PAYMENT_STATUS.PAID], value: BRIEF_PAYMENT_STATUS.PAID },
  ]
  return (
    <div className="dashboard-layout">
      <div className="qu-statistic-wrapper">
        <h1>Billings</h1>
        <ul className="qu-statistic-list qu-statistic-dubble">
          <li className="qu-statistic-item">
            <span className="qu-statistic-value">{renderRoundedNumber(campaignStats.upcoming, currency)}</span>
            <span className="qu-statistic-name">Upcoming Earnings</span>
          </li>
          <li className="qu-statistic-item">
            <span className="qu-statistic-value">{renderRoundedNumber(campaignStats.lifetime, currency)}</span>
            <span className="qu-statistic-name">Lifetime Earnings</span>
          </li>
        </ul>
      </div>

      <Row className="qt-header qt-header-campaigns" justify="space-between">
        <Col className="qt-search">
          <QInput
            className={`${isMobile && 'full-width'}`}
            value={search}
            onChange={handleChangeSearch}
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        {!isMobile && (
          <Col className="qt-filter">
            <QSelect
              showArrow
              maxTagTextLength={8}
              maxTagCount={2}
              value={params.status}
              onChange={handleChangeSelect}
              mode="multiple"
              size="large"
              placeholder="Filter by status"
              options={statusOptions}
            />
          </Col>
        )}
      </Row>
      {isMobile ? (
        <div className="pl-15 pr-15">
          <ul className="qu-request-list">
            {billingList.map(billing => {
              const paymentPaid = billing.payment?.status === BRIEF_PAYMENT_STATUS.PAID
              const filesType: IBillingPaymentUploadTypes = 'invoice'

              const isUploading = billing.payment ? billing.payment[filesType].isUploading : false
              const isRemoving = billing.payment ? billing.payment[filesType].isRemoving : false
              const isProcessing = isUploading || isRemoving
              return (
                <PartnerBillCardItem
                  key={billing.id}
                  request={billing}
                  onCardClick={() => handleView(billing.id)}
                  paymentStatus={briefPaymentStatuses}
                  invoiceNode={
                    <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                      <UploadComponent
                        dragAndDrop={false}
                        accept={'.pdf, .jpg, .jpeg, .png'}
                        caption={['You can upload 1 file', 'Accepted file formats are: pdf, png, jpg, jpeg']}
                        files={billing.payment?.invoice.files ?? []}
                        disabled={isProcessing || !billing.payment}
                        showRemoveButton={!paymentPaid}
                        isUploading={isUploading}
                        isRemoving={isRemoving}
                        onChange={(files: File[]) => handleUpload({ bill: billing, files, filesType })}
                        onRemove={file => handleRemove({ bill: billing, file, filesType })}
                        onView={async file => {
                          const response: any = await dispatch(coreActions.downloadImage(file.url))
                          const url = URL.createObjectURL(response.data)

                          window.open(url, '_blank')
                          URL.revokeObjectURL(url)
                        }}
                      />
                    </div>
                  }
                  bankTransferNode={
                    <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                      <UploadComponent
                        viewOnly
                        dragAndDrop={false}
                        files={
                          billing.payment?.bankTransfer?.map(url => ({ url, name: getFileNameFromUrl(url) })) ?? []
                        }
                        onView={async file => {
                          const response: any = await dispatch(coreActions.downloadImage(file.url))
                          const url = URL.createObjectURL(response.data)

                          window.open(url, '_blank')
                          URL.revokeObjectURL(url)
                        }}
                      />
                    </div>
                  }
                  poNode={
                    <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                      <UploadComponent
                        viewOnly
                        alternativeFileView
                        dragAndDrop={false}
                        files={
                          billing.payment?.purchaseOrders?.map(({ po: url }) => ({
                            url,
                            name: getFileNameFromUrl(url),
                          })) ?? []
                        }
                        onView={async file => {
                          const response: any = await dispatch(coreActions.downloadImage(file.url))
                          const url = URL.createObjectURL(response.data)

                          window.open(url, '_blank')
                          URL.revokeObjectURL(url)
                        }}
                      />
                    </div>
                  }
                />
              )
            })}
          </ul>
        </div>
      ) : (
        <div className="pt-15 pb-30">
          <QTable
            //@ts-ignore
            onRow={handleOnRowClick}
            onChange={handleTableChange}
            sortDirections={['ascend', 'descend', 'ascend']}
            loading={tableLoading}
            columns={columns}
            dataSource={billingList}
            scroll={{ x: 990 }}
            rowClassName="q-table cursorPointer"
          />
        </div>
      )}
      <Row justify={`${isMobile ? 'center' : 'end'}`} className={`${isMobile && 'pt-15 mb-30'}`}>
        <QPagination
          size="default"
          defaultCurrent={1}
          current={pages.currentPage}
          total={totalBillingList}
          onChange={handleChangePage}
        />
      </Row>
    </div>
  )
}

export default BillingsContainer
