import moment from 'moment'
import { DATE_PRESET } from 'src/constants/dashboard'

export const disabledDatesTillToday = (current: moment.Moment) => {
  return current < moment().startOf('day')
}

export const disabledPastDates = (current: moment.Moment) => {
  // return current < moment().startOf('day')
  return current < moment().add(4, 'days')
}

export const disabledDates = (current: moment.Moment) => {
  return current < afterDays(7)
}

export const disabledDatesForThirtyDays = (current: moment.Moment) => {
  return current < afterDays(30)
}

export const disabledDatesForFourteenDays = (current: moment.Moment) => {
  return current < afterDays(14)
}

export const daysPastFromToday = (date: moment.Moment) => {
  const today = moment().endOf('day')
  return today.diff(date, 'days')
}

export const afterDays = (days: number) => {
  return moment().startOf('day').add(days, 'days')
}

export const getIsoDate = (date: moment.Moment | string) => {
  return moment(date).toISOString()
}

export const getDaysAgoText = (days: number) => {
  if (days === 0) return 'Today'
  if (days === 1) return `${days} Day ago`

  return `${days} Days ago`
}

export const getDateFromToday = (date: DATE_PRESET) => {
  const today = moment().startOf('day')
  if (date === DATE_PRESET.DAY) {
    return today.subtract(1, 'day')
  }

  if (date === DATE_PRESET.WEEK) {
    return today.subtract(7, 'day')
  }

  if (date === DATE_PRESET.MONTH) {
    return today.subtract(1, 'month')
  }

  if (date === DATE_PRESET.THREE_MONTHS) {
    return today.subtract(3, 'months')
  }

  if (date === DATE_PRESET.SIX_MONTHS) {
    return today.subtract(6, 'months')
  }

  if (date === DATE_PRESET.YEAR) {
    return today.subtract(1, 'year')
  }

  return today.subtract(1, 'month')
}