import React from 'react'
import ClientHeader from '../../Headers/ClientHeader'
import { ICurrentUser } from '../../../modules/core/core.types'

interface Props {
  children: React.ReactNode
  user: ICurrentUser
}

const MarketplaceLayout = ({ children, user }: Props) => {
  return (
    <div className="layout marketplace">
      <div className="marketplace-layout">
        <ClientHeader user={user} />
        <div className="marketplace-main">
          <div className="marketplace-content">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default MarketplaceLayout
