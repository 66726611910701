import React, { useEffect, useState } from 'react'
import {
  QButton,
  Form,
  QInput,
  Radio,
  QRadio,
  Row,
  Col,
  QSelect,
  IconsCommon,
  qnotification,
  Modal,
  QCheckbox,
} from 'quantum_components'
import { NOTIFICATION_TYPES, REGEXES } from 'src/constants'
import { useSelector } from 'react-redux'
import { getForm, getPartnerProfileSettings } from 'src/modules/partner/partnerProfile/profile.selectors'
import { isMobile } from 'react-device-detect'
import { MEDIA_TYPES, MEDIA_TYPES_DURATION } from 'src/constants/brief'
import { FIELDS_MEDIA_TYPES, BANNER_SUB_TYPES } from 'src/modules/partner/partnerProfile/profile.types'
import PreviewMediaTypes from '../PreviewMediaTypes'
import ImageUploadInput from '../CustomInputs/ImageUploadInput'
import { getCurrency } from 'src/modules/core/core.selectors'
import { renderRoundedNumber } from 'src/helpers/currency'
import { PRICING_MODEL } from 'src/constants/profile'

interface Props {
  formData: any
  isChecked: boolean
  onFinish: (values: any, shouldDelete?: boolean) => void
}

const { IconArrowLeft } = IconsCommon

export default function MultiForm({ formData, onFinish, isChecked }: Props) {
  const currency = useSelector(getCurrency)
  const { mediaBuyTypes, mbPricingModel } = useSelector(getForm)
  const { mediaSubtypes } = useSelector(getPartnerProfileSettings)

  const selectedSubtypes = mediaBuyTypes.filter(type => type.name === formData.name) || []

  const [isShowPreview, setIsShowPreview] = useState(false)

  const settingsMediaType = mediaSubtypes.find(type => type.name === formData.name)
  const fieldsToCheck = settingsMediaType?.availableFields.map(f => f.name)

  const [types, setTypes] = useState(selectedSubtypes.map(subType => subType.subName) || [])
  const [forms, setForms] = useState(selectedSubtypes || [])

  const [tableKey, setTableKey] = useState(1)
  const [tableKey1, setTableKey1] = useState(1)
  const [mediaTypesDropdownOpen, setMediaTypesDropdownOpen] = useState<boolean>(false)
  const [customBannerDeleteModal, setCustomBannerDeleteModal] = useState({
    title: '',
    v: [],
    visible: false,
  })

  const handleOnFinish = (shouldDelete?: boolean) => async (values: any) => {
    if (mbPricingModel === PRICING_MODEL.METRICS) {
      forms.map((ele: any) => {
        ele.dailyClicks = ele.dailyClicks ? ele.dailyClicks : null
        ele.costPerClick = ele.costPerClick ? ele.costPerClick : null
        ele.dailyImpressions = ele.dailyImpressions ? ele.dailyImpressions : null
        ele.costPerImpression = ele.costPerImpression ? ele.costPerImpression : null
        ele.dailyReach = ele.dailyReach ? ele.dailyReach : null
        ele.costPerReach = ele.costPerReach ? ele.costPerReach : null
      })
      setForms(forms)
    }

    if (!types.includes('')) {
      if (onFinish && shouldDelete) onFinish({ name: formData.name }, shouldDelete)
      else if (onFinish) onFinish(forms, false)
    } else {
      qnotification({ type: NOTIFICATION_TYPES.ERROR, message: "Media Type Title can't be empty." })
    }
  }
  const [selectOptions, setSelectOptions] = useState<any>()

  useEffect(() => {
    const mediaTypeSelectOptions = settingsMediaType?.subNames?.map(name => ({
      label: name,
      value: name,
    }))
    if (formData.name === MEDIA_TYPES.HOME_BANNERS) {
      types.map((type: any) => {
        if (mediaTypeSelectOptions?.findIndex(ele => ele.label === type) === -1)
          mediaTypeSelectOptions?.push({
            label: type,
            value: type,
          })
      })
    }
    setSelectOptions(mediaTypeSelectOptions)
  }, [settingsMediaType])

  const handleSetForms = (types: any[]) => {
    let newMediaTypes = [...forms]
    if (types.length > 1) {
      types.forEach((t: any) => {
        const idx = newMediaTypes.findIndex(({ subName }) => subName === t.subName)
        if (idx !== -1) {
          newMediaTypes[idx] = { ...newMediaTypes[idx], ...t }
          //@ts-ignore
          if (!newMediaTypes[idx].hasError) delete newMediaTypes[idx]['hasError']
        } else {
          if (!t.hasError) delete t['hasError']
          newMediaTypes.splice(0, 0, t)
        }
      })
      newMediaTypes = newMediaTypes.filter(
        ty => (types.map(s => s.subName).includes(ty.subName) && ty.name === types[0].name) || ty.name !== types[0].name
      )
    } else if (types.length === 1) {
      const idx = newMediaTypes.findIndex(({ subName }) => subName === types[0].subName)
      if (idx !== -1) {
        newMediaTypes[idx] = { ...newMediaTypes[idx], ...types[0] }
        //@ts-ignore
        if (!newMediaTypes[idx].hasError) delete newMediaTypes[idx]['hasError']
      } else {
        if (!types[0].hasError) delete types[0]['hasError']
        newMediaTypes.splice(0, 0, types[0])
      }

      newMediaTypes = newMediaTypes.filter(
        ty => (ty.name === types[0].name && ty.subName === types[0].subName) || ty.name !== types[0].name
      )
    } else {
      newMediaTypes = newMediaTypes.filter(ty => ty.name !== types[0].name)
    }
    setForms(newMediaTypes)
  }

  const handleOnSelect = async (v: any) => {
    let difference = v.filter((x: any) => !types.includes(x))
    if (difference.length > 0) {
      setTypes([difference[0], ...types])
    } else {
      setTypes(v)
    }
    if (
      formData.name === MEDIA_TYPES.HOME_BANNERS &&
      v.length < types.length &&
      types.filter((x: any) => !v.includes(x)).length > 0
    ) {
      setMediaTypesDropdownOpen(false)
      const unSelectItem: any = types.filter((x: any) => !v.includes(x))
      if (!Object.values(BANNER_SUB_TYPES).includes(unSelectItem[0])) {
        setCustomBannerDeleteModal({
          title: unSelectItem,
          v: v,
          visible: true,
        })
        return
      }
    }
    v.length
      ? difference.length > 0
        ? handleSetForms([difference[0], ...types].map((f: any) => ({ name: formData.name, subName: f })))
        : handleSetForms(v.map((f: any) => ({ name: formData.name, subName: f })))
      : handleSetForms([{ name: formData.name }])

    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  const handleYesNo = async (yesOrNo: boolean) => {
    if (!types.includes('')) {
      if (yesOrNo) {
        const remainingOptions = selectOptions.filter((ele: any) => ele.value != customBannerDeleteModal.title)
        setSelectOptions(remainingOptions)
      }

      customBannerDeleteModal.v.length
        ? handleSetForms(customBannerDeleteModal.v.map((f: any) => ({ name: formData.name, subName: f })))
        : handleSetForms([{ name: formData.name }])

      setTypes(customBannerDeleteModal.v)

      setCustomBannerDeleteModal({
        title: '',
        v: [],
        visible: false,
      })
    } else {
      qnotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: 'You can not deselect Media Type if the title is empty.',
      })
      setCustomBannerDeleteModal({ ...customBannerDeleteModal, visible: false })
    }
    setTableKey(Math.floor(Math.random() * 1000 + 1))
    setTableKey1(Math.floor(Math.random() * 1000 + 1))
  }

  const createCustomBanner = async () => {
    if (selectOptions.length === 20) {
      qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'You have reached maximum limit of custom banners.' })
      return
    }
    for (let i = 1; i <= 15; i++) {
      const filteredOptions = selectOptions.filter((ele: any) => ele.value === `Custom Banner ${i}`)
      if (filteredOptions.length === 0) {
        selectOptions.push({
          label: `Custom Banner ${i}`,
          value: `Custom Banner ${i}`,
        })
        break
      }
    }
    setSelectOptions(selectOptions)
    handleOnSelect([...types, selectOptions[selectOptions.length - 1].label])
    setTableKey(Math.floor(Math.random() * 1000 + 1))
    setTableKey1(Math.floor(Math.random() * 1000 + 1))
    setMediaTypesDropdownOpen(true)
  }

  const onChangeInputHandler = (subtype: any, field: string) => (event: any) =>
    handleSetForms(
      types.map(f =>
        f === subtype
          ? {
              name: formData.name,
              subName: f,
              [field]: field === FIELDS_MEDIA_TYPES.IMAGE ? event : event.target.value,
            }
          : { name: formData.name, subName: f }
      )
    )

  const onChangeTitleHandler = (subtype: any, index: number) => (event: any) => {
    setMediaTypesDropdownOpen(false)

    if (types.includes(event.target.value)) {
      qnotification({ type: NOTIFICATION_TYPES.ERROR, message: 'There is an existing Banner with the same name.' })
      setForms(forms)
      setTableKey(Math.floor(Math.random() * 1000 + 1))
      return
    }

    selectOptions[selectOptions.findIndex((x: any) => x.value === subtype)].label = event.target.value
    selectOptions[selectOptions.findIndex((x: any) => x.value === subtype)].value = event.target.value
    setSelectOptions(selectOptions)

    types[types.indexOf(subtype)] = event.target.value

    forms[index].subName = event.target.value
    setForms(forms)
    handleSetForms(
      types.map(f =>
        f === event.target.value
          ? {
              name: formData.name,
              subName: event.target.value,
            }
          : { name: formData.name, subName: f }
      )
    )
    setTableKey1(Math.floor(Math.random() * 1000 + 1))
  }

  const validationFields = ['avgImpressions', 'pricePerActivity', 'priceForDuration', 'width', 'height']

  const onChangeInputWithValidation = (subtype: any, field: string, validator: RegExp) => (e: any) => {
    const value = e.currentTarget.value
    const hasError = validationFields.some(vfield => {
      const vObj = forms.find(form => form.subName === subtype)
      if (vfield === field) return !(RegExp(validator).test(value) || !value.length)
      //@ts-ignore
      return !(vObj[vfield] === undefined || RegExp(validator).test(vObj[vfield]) || !vObj[vfield]?.length)
    })

    handleSetForms(
      types.map(f =>
        f === subtype
          ? { hasError, name: formData.name, subName: f, [field]: e.currentTarget.value }
          : { name: formData.name, subName: f }
      )
    )
  }

  const onChangeCheckboxHandler = (subtype: any, field: string, value: string) => (event: any) => {
    const filteredFormData: any = forms.filter(ele => ele.subName === subtype)

    if (filteredFormData[0].marketingMetrics && filteredFormData[0].marketingMetrics.includes(value)) {
      filteredFormData[0].marketingMetrics.splice(filteredFormData[0].marketingMetrics.indexOf(value), 1)
    } else {
      if (filteredFormData[0].marketingMetrics) {
        filteredFormData[0].marketingMetrics.push(value)
      }
    }

    const fields: string[] = []
    if (value === 'Impression') {
      fields[0] = FIELDS_MEDIA_TYPES.DAILY_IMPRESSION
      fields[1] = FIELDS_MEDIA_TYPES.CPM
    } else if (value === 'Click') {
      fields[0] = FIELDS_MEDIA_TYPES.DAILY_CLICKS
      fields[1] = FIELDS_MEDIA_TYPES.CPC
    } else {
      fields[0] = FIELDS_MEDIA_TYPES.DAILY_REACH
      fields[1] = FIELDS_MEDIA_TYPES.CPR
    }

    handleSetForms(
      types.map(f =>
        f === subtype
          ? {
              name: formData.name,
              subName: f,
              [field]: filteredFormData[0].marketingMetrics || [value],
              [fields[0]]: '',
              [fields[1]]: '',
            }
          : { name: formData.name, subName: f }
      )
    )

    setTableKey(Math.floor(Math.random() * 1000 + 1))
  }

  return (
    <div className="qu-modal-media-types-content">
      <div>
        {!isShowPreview && (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <div className="qu-modal-media-types-block">
                  <label htmlFor="subName" className="form-label mb-5">
                    {formData.name} Types
                  </label>
                  <div className="mb-0">
                    <QSelect
                      key={tableKey1}
                      defaultOpen={mediaTypesDropdownOpen}
                      getPopupContainer={() => document.body}
                      defaultValue={types}
                      maxTagTextLength={8}
                      maxTagCount={2}
                      value={types}
                      onChange={handleOnSelect}
                      mode="multiple"
                      size="large"
                      placeholder="Media subtype"
                      options={selectOptions}
                      style={{ minWidth: '300px' }}
                    />
                    {formData.name === MEDIA_TYPES.HOME_BANNERS && (
                      <QButton
                        type="primary"
                        className={isMobile ? 'mt-10' : 'ml-10'}
                        style={{ backgroundColor: '#ff9800' }}
                        onClick={createCustomBanner}
                        size="small"
                      >
                        Add Custom Banner
                      </QButton>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            {types.map((type, index) => {
              const typeLocalState = forms.find(({ subName }) => subName === type)
              const preview: any = {
                mbPricingModel,
                name: typeLocalState?.name,
                description: typeLocalState?.description,
                url: typeLocalState?.previewImage,
                avgImpressions: typeLocalState?.avgImpressions,
                duration: typeLocalState?.duration,
                pricePerActivity: typeLocalState?.pricePerActivity,
                priceForDuration: typeLocalState?.priceForDuration,
                priceForImpression: typeLocalState?.priceForImpression,
                pricePerPush: typeLocalState?.pricePerPush,
                minOrderValue: typeLocalState?.minOrderValue,
                subName: typeLocalState?.subName,
                dailyImpressions: typeLocalState?.dailyImpressions,
                CPM: typeLocalState?.costPerImpression
                  ? renderRoundedNumber(typeLocalState?.costPerImpression, currency)
                  : '',
                dailyClicks: typeLocalState?.dailyClicks,
                CPC: typeLocalState?.costPerClick ? renderRoundedNumber(typeLocalState?.costPerClick, currency) : '',
                dailyReach: typeLocalState?.dailyReach,
                CPR: typeLocalState?.costPerReach ? renderRoundedNumber(typeLocalState?.costPerReach, currency) : '',
              }
              return (
                <div key={index}>
                  <hr className="form__hr" />
                  <Row gutter={40}>
                    <Col xs={24} sm={24} md={12}>
                      <div className="qu-modal-media-types-block">
                        <Form
                          key={tableKey + (index + 1)}
                          name={typeLocalState?.subName}
                          initialValues={typeLocalState}
                        >
                          <div className="qu-modal-media-types-block">
                            {!Object.values(BANNER_SUB_TYPES).includes(preview.subName) &&
                              formData.name === MEDIA_TYPES.HOME_BANNERS && (
                                <>
                                  <label htmlFor="subName" className="form-label mb-5">
                                    Title
                                  </label>
                                  <Form.Item className="mb-5" name="subName">
                                    <QInput
                                      value={typeLocalState?.subName}
                                      onChange={onChangeTitleHandler(type, index)}
                                      placeholder="Enter a title"
                                      size="large"
                                    />
                                  </Form.Item>
                                </>
                              )}

                            <label htmlFor="description" className="form-label mb-5">
                              Description
                            </label>
                            <Form.Item className="mb-0" name="description" help={settingsMediaType?.description}>
                              <QInput
                                value={typeLocalState?.description}
                                onChange={onChangeInputHandler(type, 'description')}
                                placeholder="Enter a description"
                                size="large"
                              />
                            </Form.Item>
                            <div className="qu-modal-media-types-block">
                              <h4 className="form-label mb-5">Image</h4>
                              <Form.Item className="mb-0" name={FIELDS_MEDIA_TYPES.IMAGE}>
                                <ImageUploadInput
                                  value={typeLocalState?.previewImage}
                                  onChange={onChangeInputHandler(type, FIELDS_MEDIA_TYPES.IMAGE)}
                                />
                              </Form.Item>
                            </div>
                          </div>

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DURATION) &&
                            mbPricingModel === PRICING_MODEL.DURATION && (
                              <div className="qu-modal-media-types-block">
                                <h4 className="form-label mb-5">Duration</h4>
                                <p className="p--xs tc--light mb-0">
                                  For what duration can clients purchase this media?
                                </p>
                                <Form.Item className="mb-0" name="duration">
                                  <Radio.Group
                                    onChange={onChangeInputHandler(type, 'duration')}
                                    value={typeLocalState?.duration}
                                    className="qu-radio-group"
                                  >
                                    <QRadio value={MEDIA_TYPES_DURATION.PER_DAY}>Per Day</QRadio>
                                    <QRadio value={MEDIA_TYPES_DURATION.PER_WEEK}>Per Week</QRadio>
                                    <QRadio value={MEDIA_TYPES_DURATION.PER_MONTH}>Per Month</QRadio>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            )}

                          <div className="qu-modal-media-types-block">
                            <Row gutter={20} className="mt-15">
                              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.AVG_IMPRESSIONS) &&
                                mbPricingModel === PRICING_MODEL.DURATION && (
                                  <Col span={12}>
                                    <Form.Item
                                      key={type}
                                      className="mb-0"
                                      rules={[
                                        {
                                          message: 'Invalid amount!',
                                          pattern: REGEXES.AMOUNT_REGEX,
                                        },
                                      ]}
                                      name="avgImpressions"
                                    >
                                      <QInput
                                        value={typeLocalState?.avgImpressions}
                                        onChange={onChangeInputWithValidation(
                                          type,
                                          'avgImpressions',
                                          REGEXES.AMOUNT_REGEX
                                        )}
                                        type="number"
                                        placeholder="Enter Value"
                                        size="large"
                                      />
                                    </Form.Item>
                                    <label htmlFor="avgImpressions" className="form-label form-label-secondary mt-5">
                                      Avg Impressions
                                    </label>
                                  </Col>
                                )}
                              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_DURATION) &&
                                mbPricingModel === PRICING_MODEL.DURATION && (
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="priceForDuration"
                                      rules={[
                                        {
                                          message: 'Invalid amount!',
                                          pattern: REGEXES.AMOUNT_REGEX,
                                        },
                                      ]}
                                    >
                                      <QInput
                                        value={typeLocalState?.priceForDuration}
                                        onChange={onChangeInputWithValidation(
                                          type,
                                          'priceForDuration',
                                          REGEXES.AMOUNT_REGEX
                                        )}
                                        type="number"
                                        placeholder="Enter Value"
                                        size="large"
                                      />
                                    </Form.Item>
                                    <label htmlFor="priceForDuration" className="form-label form-label-secondary mt-5">
                                      Price for Duration
                                    </label>
                                  </Col>
                                )}
                              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_ACTIVITY) &&
                                mbPricingModel === PRICING_MODEL.DURATION && (
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="pricePerActivity"
                                      rules={[
                                        {
                                          message: 'Invalid amount!',
                                          pattern: REGEXES.AMOUNT_REGEX,
                                        },
                                      ]}
                                    >
                                      <QInput
                                        value={typeLocalState?.pricePerActivity}
                                        onChange={onChangeInputWithValidation(
                                          type,
                                          'pricePerActivity',
                                          REGEXES.AMOUNT_REGEX
                                        )}
                                        type="number"
                                        placeholder="Enter Value"
                                        size="large"
                                      />
                                    </Form.Item>
                                    <label htmlFor="pricePerActivity" className="form-label form-label-secondary mt-5">
                                      Price per Activity
                                    </label>
                                  </Col>
                                )}
                              {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.PRICE_IMPRESSION) &&
                                mbPricingModel === PRICING_MODEL.DURATION && (
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="priceForImpression"
                                      rules={[
                                        {
                                          message: 'Invalid amount!',
                                          pattern: REGEXES.AMOUNT_REGEX,
                                        },
                                      ]}
                                    >
                                      <QInput
                                        value={typeLocalState?.priceForImpression}
                                        onChange={onChangeInputWithValidation(
                                          type,
                                          'priceForImpression',
                                          REGEXES.AMOUNT_REGEX
                                        )}
                                        type="number"
                                        placeholder="Enter Value"
                                        size="large"
                                      />
                                    </Form.Item>
                                    <label
                                      htmlFor="priceForImpression"
                                      className="form-label form-label-secondary mt-5"
                                    >
                                      Price for Impression 
                                    </label>
                                  </Col>
                                )}
                            </Row>
                          </div>

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.WIDTH) &&
                            fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.HEIGHT) && (
                              <div className="qu-modal-media-types-block">
                                <h4 className="form-label mb-5">
                                  {typeLocalState?.name === MEDIA_TYPES.HOME_BANNERS ? 'Banner' : 'Image/Video'}{' '}
                                  Dimensions (in pixels)
                                </h4>
                                {typeLocalState?.name === MEDIA_TYPES.HOME_BANNERS && (
                                  <p className="p--xs tc--light">
                                    Clients will be asked to upload a home page banner image based on the dimensions
                                    entered below.
                                  </p>
                                )}
                                <Row gutter={20}>
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="width"
                                      rules={[
                                        {
                                          message: 'Invalid amount!',
                                          pattern: REGEXES.AMOUNT_REGEX,
                                        },
                                      ]}
                                    >
                                      <QInput
                                        value={typeLocalState?.width}
                                        onChange={onChangeInputWithValidation(type, 'width', REGEXES.AMOUNT_REGEX)}
                                        type="number"
                                        size="large"
                                      />
                                    </Form.Item>
                                    <label htmlFor="width" className="form-label form-label-secondary mt-5">
                                      Width
                                    </label>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      className="mb-0"
                                      name="height"
                                      rules={[
                                        {
                                          message: 'Invalid amount!',
                                          pattern: REGEXES.AMOUNT_REGEX,
                                        },
                                      ]}
                                    >
                                      <QInput
                                        value={typeLocalState?.height}
                                        onChange={onChangeInputWithValidation(type, 'height', REGEXES.AMOUNT_REGEX)}
                                        type="number"
                                        size="large"
                                      />
                                    </Form.Item>
                                    <label htmlFor="height" className="form-label form-label-secondary mt-5">
                                      Height
                                    </label>
                                  </Col>
                                </Row>
                              </div>
                            )}

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.AD_TITLE_CHARACTER_LIMIT) && (
                            <div className="qu-modal-media-types-block">
                              <h4 className="form-label mb-5">Ad Title Character Count</h4>
                              <Row gutter={20}>
                                <Col xs={24} sm={24} md={12}>
                                  <Form.Item
                                    className="mb-0"
                                    name="adTitleCharacterLimit"
                                    rules={[
                                      {
                                        message: 'Invalid number!',
                                        pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                      },
                                    ]}
                                  >
                                    <QInput
                                      value={typeLocalState?.adTitleCharacterLimit}
                                      onChange={onChangeInputWithValidation(
                                        type,
                                        'adTitleCharacterLimit',
                                        REGEXES.POSITIVE_NUMBERS_REGEX
                                      )}
                                      placeholder="Enter Value"
                                      type="number"
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          )}

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.AD_DESCRIPTION_CHARACTER_LIMIT) && (
                            <div className="qu-modal-media-types-block">
                              <h4 className="form-label mb-5">Ad Description Character Count</h4>
                              <Row gutter={20}>
                                <Col xs={24} sm={24} md={12}>
                                  <Form.Item
                                    className="mb-0"
                                    name="adDescriptionCharacterLimit"
                                    rules={[
                                      {
                                        message: 'Invalid number!',
                                        pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                      },
                                    ]}
                                  >
                                    <QInput
                                      value={typeLocalState?.adDescriptionCharacterLimit}
                                      onChange={onChangeInputWithValidation(
                                        type,
                                        'adDescriptionCharacterLimit',
                                        REGEXES.POSITIVE_NUMBERS_REGEX
                                      )}
                                      placeholder="Enter Value"
                                      type="number"
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          )}

                          {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.MARKETING_METRICS) &&
                            mbPricingModel === PRICING_MODEL.METRICS && (
                              <div className="qu-modal-media-types-block">
                                <h4 className="form-label mb-5">Marketing Metrics</h4>
                                <Row gutter={10}>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item className="mb-0" name="Impression">
                                      <QCheckbox
                                        checked={typeLocalState?.marketingMetrics?.includes('Impression')}
                                        className="test-class"
                                        onChange={onChangeCheckboxHandler(type, 'marketingMetrics', 'Impression')}
                                      >
                                        Impression
                                      </QCheckbox>
                                    </Form.Item>
                                  </Col>

                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item className="mb-0" name="Click">
                                      <QCheckbox
                                        checked={typeLocalState?.marketingMetrics?.includes('Click')}
                                        className="test-class"
                                        onChange={onChangeCheckboxHandler(type, 'marketingMetrics', 'Click')}
                                      >
                                        Click
                                      </QCheckbox>
                                    </Form.Item>
                                  </Col>

                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item className="mb-0" name="Reach">
                                      <QCheckbox
                                        checked={typeLocalState?.marketingMetrics?.includes('Reach')}
                                        className="test-class"
                                        onChange={onChangeCheckboxHandler(type, 'marketingMetrics', 'Reach')}
                                      >
                                        Reach
                                      </QCheckbox>
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row gutter={10} className="mb-10 mt-10">
                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DAILY_IMPRESSION) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="dailyImpressions"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Impression')
                                            ? [
                                                {
                                                  message: 'Invalid number!',
                                                  pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                                },
                                              ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Impression')}
                                          value={typeLocalState?.dailyImpressions}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'dailyImpressions',
                                            REGEXES.POSITIVE_NUMBERS_REGEX
                                          )}
                                          type="number"
                                          placeholder="Daily Impressions"
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DAILY_CLICKS) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="dailyClicks"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Click')
                                            ? [
                                                {
                                                  message: 'Invalid number!',
                                                  pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                                },
                                              ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Click')}
                                          value={typeLocalState?.dailyClicks}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'dailyClicks',
                                            REGEXES.POSITIVE_NUMBERS_REGEX
                                          )}
                                          type="number"
                                          placeholder="Daily Clicks"
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.DAILY_REACH) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="dailyReach"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Reach')
                                            ? [
                                                {
                                                  message: 'Invalid number!',
                                                  pattern: REGEXES.POSITIVE_NUMBERS_REGEX,
                                                },
                                              ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Reach')}
                                          value={typeLocalState?.dailyReach}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'dailyReach',
                                            REGEXES.POSITIVE_NUMBERS_REGEX
                                          )}
                                          type="number"
                                          placeholder="Daily Reach"
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                </Row>

                                <Row gutter={10}>
                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.CPM) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="costPerImpression"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Impression')
                                            ? [
                                                {
                                                  message: 'Invalid amount!',
                                                  pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                                                },
                                              ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Impression')}
                                          value={typeLocalState?.costPerImpression}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'costPerImpression',
                                            REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL
                                          )}
                                          type="number"
                                          placeholder="CPM"
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.CPC) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="costPerClick"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Click')
                                            ? [
                                                {
                                                  message: 'Invalid amount!',
                                                  pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                                                },
                                              ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Click')}
                                          value={typeLocalState?.costPerClick}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'costPerClick',
                                            REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL
                                          )}
                                          type="number"
                                          placeholder="CPC"
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {fieldsToCheck?.includes(FIELDS_MEDIA_TYPES.CPR) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        className="mb-0"
                                        name="costPerReach"
                                        rules={
                                          typeLocalState?.marketingMetrics?.includes('Reach')
                                            ? [
                                                {
                                                  message: 'Invalid amount!',
                                                  pattern: REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL,
                                                },
                                              ]
                                            : []
                                        }
                                      >
                                        <QInput
                                          disabled={!typeLocalState?.marketingMetrics?.includes('Reach')}
                                          value={typeLocalState?.costPerReach}
                                          onChange={onChangeInputWithValidation(
                                            type,
                                            'costPerReach',
                                            REGEXES.AMOUNT_REGEX_WITH_TWO_DECIMAL
                                          )}
                                          type="number"
                                          placeholder="CPR"
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            )}
                        </Form>
                      </div>
                    </Col>

                    {!isMobile && (
                      <Col xs={24} sm={24} md={12}>
                        <PreviewMediaTypes preview={preview} />
                      </Col>
                    )}
                  </Row>
                </div>
              )
            })}
          </>
        )}

        {!isShowPreview && (
          <div className="qu-modal-media-types-block qu-modal-media-types-footer mt-25">
            <QButton onClick={() => setIsShowPreview(true)} className="qu-button-soft mb-10">
              Show Preview
            </QButton>
            <QButton
              //@ts-ignore
              disabled={forms.some(e => e.hasError)}
              type="primary"
              onClick={() => handleOnFinish()(formData.name)}
            >
              Save & Add Media
            </QButton>
            {isChecked && (
              <QButton
                className="qu-button-outline mt-10"
                type="primary"
                htmlType="button"
                onClick={() => handleOnFinish(true)(formData.name)}
              >
                Remove Media
              </QButton>
            )}
          </div>
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {isMobile &&
          isShowPreview &&
          types.map(type => {
            const typeLocalState = forms.find(({ subName }) => subName === type)
            const preview = {
              mbPricingModel,
              name: typeLocalState?.name,
              description: typeLocalState?.description,
              url: typeLocalState?.previewImage,
              avgImpressions: typeLocalState?.avgImpressions,
              duration: typeLocalState?.duration,
              pricePerActivity: typeLocalState?.pricePerActivity,
              priceForDuration: typeLocalState?.priceForDuration,
              priceForImpression: typeLocalState?.priceForImpression,
              pricePerPush: typeLocalState?.pricePerPush,
              minOrderValue: typeLocalState?.minOrderValue,
              subName: typeLocalState?.subName,
              dailyImpressions: typeLocalState?.dailyImpressions,
              CPM: typeLocalState?.costPerImpression
                ? renderRoundedNumber(typeLocalState?.costPerImpression, currency)
                : '',
              dailyClicks: typeLocalState?.dailyClicks,
              CPC: typeLocalState?.costPerClick ? renderRoundedNumber(typeLocalState?.costPerClick, currency) : '',
              dailyReach: typeLocalState?.dailyReach,
              CPR: typeLocalState?.costPerReach ? renderRoundedNumber(typeLocalState?.costPerReach, currency) : '',
            }
            return <PreviewMediaTypes preview={preview} />
          })}
        {isMobile && isShowPreview && (
          <QButton onClick={() => setIsShowPreview(false)} className="qu-button-outline" block>
            <IconArrowLeft className="mr-5" />
            Back
          </QButton>
        )}
      </div>

      <Modal
        key={tableKey}
        wrapClassName="qu-modal"
        title={<h3>Are you sure?</h3>}
        visible={customBannerDeleteModal.visible}
        onOk={() => handleYesNo(true)}
        onCancel={() => handleYesNo(false)}
        footer={false}
      >
        <p className="mb-25">Delete this custom banner?</p>
        <div className="grid-row grid-row--smg grid-row--jce">
          <div className="grid-col grid-col--auto">
            <QButton onClick={() => handleYesNo(true)} type="primary">
              Yes
            </QButton>
          </div>
          <div className="grid-col grid-col--auto">
            <QButton className="qu-button-outline" onClick={() => handleYesNo(false)}>
              No
            </QButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}
