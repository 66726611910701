import React, { ReactElement } from 'react'
import { QBriefCartItem, QBriefCartProduct, QButton } from 'quantum_components'
import { useReduxDispatch } from '../../../../../helpers'
import {
  ADDITIONAL_STEPS,
  BRIEF_STATUS,
  BRIEF_STEPS,
  BRIEF_TYPES,
  CONSUMER_RESEARCH_MEDIA_TYPES,
  DIALOG_MODALS,
  DISTRIBUTION_TYPE,
  MEDIA_TYPES,
  MIN_LOGISTIC_PRICE_TEXT,
} from '../../../../../constants/testahel_brief'
import { useSelector } from 'react-redux'
import { actions } from '../../testahelBox.module'
import {
  getBrief,
  getBriefSettings,
  getCart,
  getCurrentBriefId,
  getIsRejectionFlow,
  getSampleAllocated,
  getTotalQuantity,
  getIsSaving,
  getPrintingPrice,
} from '../../testahelBox.selectors'
import { IAdEstimation, ICartItem, ICartServiceItem } from '../../testahelBox.types'
import { PATHS } from '../../../../../constants'
import { useHistory } from 'react-router-dom'
import {
  getLocaleCost,
  getLocaleCostWithoutCurrencyNDecimals,
  renderRoundedNumber,
} from '../../../../../helpers/currency'
import {
  blockService,
  calcCartItemServicesSubTotal,
  calcSubTotal,
  calculation,
  getRejectedServices,
  hasDistributionItems,
  hasMediaBuyItems,
} from 'src/helpers/cart'
import TestahelCouponForm from '../../../../../components/TestahelCoupon/TestahelCouponForm'
import { getCurrency } from '../../../../core/core.selectors'
import { PRICING_MODEL } from 'src/constants/profile'
import { abbreviateNumberWithoutDecimal } from 'src/helpers/abbreviateNumber'

export default function TestahelBriefCartMenu(): ReactElement {
  const dispatch = useReduxDispatch()
  const cart = useSelector(getCart)
  const brief = useSelector(getBrief)
  const totalQuantity = useSelector(getTotalQuantity)
  const sampleAllocated = useSelector(getSampleAllocated)
  const briefId = useSelector(getCurrentBriefId)
  const isRejectionFlow = useSelector(getIsRejectionFlow)
  const settings = useSelector(getBriefSettings)
  const isSaving = useSelector(getIsSaving)
  const currency = useSelector(getCurrency)
  const printingPrice = useSelector(getPrintingPrice)
  const MINIMUM_DISTRIBUTION_LOGISTIC_COUNT = settings.MINIMUM_DISTRIBUTION_LOGISTIC_COUNT
  const isDraft = brief.status === BRIEF_STATUS.DRAFT
  const isSelectPartners = brief.status === BRIEF_STATUS.SELECT_PARTNERS

  const hasMediaBuy = hasMediaBuyItems(cart.additional.items)
  const hasDist = hasDistributionItems(cart.additional.items)

  const history = useHistory()

  const updateBrief = async () => {
    const briefForSave = { ...brief }
    briefForSave[BRIEF_STEPS.PARTNER].quantity = totalQuantity
    // briefForSave[BRIEF_STEPS.PARTNER].hasFreeSample = briefForSave[BRIEF_STEPS.SERVICES].hasFreeSample
    briefForSave.lastFilledStep = BRIEF_STEPS.PARTNER

    await dispatch(actions.updateBrief(BRIEF_STEPS.PARTNER, briefForSave))
  }

  const handleNextStep = async () => {
    const rejection = getRejectedServices(cart.additional.items)
    if (isRejectionFlow && rejection.length > 0) {
      dispatch(actions.setDialogModal(DIALOG_MODALS.BLOCK_NEXT))
      return
    }

    if (brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.MEDIA_BUYING) && !hasMediaBuy) {
      dispatch(actions.setDialogModal(DIALOG_MODALS.MEDIA_BUY))
      return
    }

    if (brief[BRIEF_STEPS.TYPE].briefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE) && !hasDist) {
      dispatch(actions.setDialogModal(DIALOG_MODALS.DISTRIBUTION))
      return
    }

    let totalBudget = 0
    cart.additional.items.map(ele => {
      ele.services.map((ele1: any) => {
        if (
          ele1.name !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
          ele1.name !== DISTRIBUTION_TYPE.PRINTED_INSERT &&
          ele1.name !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
          ele1.name !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
        ) {
          totalBudget = totalBudget + ele1.total
        }
      })
    })

    if (
      sampleAllocated !== totalQuantity &&
      brief?.BRIEF_MEDIA_BUY?.budgetValue &&
      getLocaleCost(totalBudget) !== getLocaleCost(brief?.BRIEF_MEDIA_BUY?.budgetValue)
    ) {
      await dispatch(actions.setDialogModal(DIALOG_MODALS.SAMPLE_BUDGET_ALLOCATED_MISMATCH))
      return
    }

    if (
      brief?.BRIEF_MEDIA_BUY?.budgetValue &&
      getLocaleCost(totalBudget) !== getLocaleCost(brief?.BRIEF_MEDIA_BUY?.budgetValue)
    ) {
      await dispatch(actions.setDialogModal(DIALOG_MODALS.BUDGET_ALLOCATED_MISMATCH))
      return
    }

    if (sampleAllocated !== totalQuantity) {
      dispatch(actions.setDialogModal(DIALOG_MODALS.SAMPLE_ALLOCATED_MISMATCH))
      return
    }

    let CRMediaModal = cart.additional.items[0].services.find(
      ele =>
        (ele.name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY || ele.name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE) &&
        (!ele.quantity || ele.quantity === 0)
    )
    if (CRMediaModal) {
      dispatch(actions.setDialogModal(DIALOG_MODALS.CRMEDIA))
      return
    }

    await updateBrief()
    dispatch(actions.setCurrentStep(BRIEF_STEPS.UPLOAD))
  }

  const handleSubmit = async () => {
    let CRMediaModal = cart.additional.items[0].services.find(
      ele =>
        (ele.name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY || ele.name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE) &&
        (!ele.quantity || ele.quantity === 0)
    )
    if (CRMediaModal) {
      dispatch(actions.setDialogModal(DIALOG_MODALS.CRMEDIA))
      return
    }

    await updateBrief()
    if (isDraft || isSelectPartners) await dispatch(actions.approveBrief(brief.id!))

    history.push(PATHS.BRIEF)
  }

  const handleRemove = async (item: ICartServiceItem, partnerId: number) => {
    if (!briefId) {
      return
    }

    const cartData = { ...cart }
    const cartItems = cartData.additional.items
    const targetItem = cartItems.find((cartItem: ICartItem) => cartItem.partnerId === partnerId)

    if (!targetItem) {
      return
    }

    targetItem.services.splice(targetItem.services.indexOf(item), 1)

    cartData.mediaBuyEstimates &&
      cartData.mediaBuyEstimates.length > 0 &&
      cartData.mediaBuyEstimates.splice(
        cartData.mediaBuyEstimates.findIndex((ele: IAdEstimation) => ele.partnerId === partnerId),
        1
      )
    if (!targetItem.services.length) {
      cartItems.splice(cartItems.indexOf(targetItem), 1)
    } else {
      const partnerDetails = await dispatch(actions.fetchPartnerDetails(briefId, partnerId))

      const filteredServices: any = []

      partnerDetails?.data?.data?.content?.availableServices.map((ele: any) => {
        const name = ele.serviceSubName ? ele.serviceSubName : ele.serviceName
        const selectedService = targetItem.services.find(s => s.name === name)
        if (selectedService) {
          filteredServices.push({
            pricing: selectedService,
            serviceDailyImpressions: ele.serviceDailyImpressions,
            serviceCostPerImpression: ele.serviceCostPerImpression,
            serviceDailyClicks: ele.serviceDailyClicks,
            serviceCostPerClick: ele.serviceCostPerClick,
            serviceDailyReach: ele.serviceDailyReach,
            serviceCostPerReach: ele.serviceCostPerReach,
          })
        }
      })

      let total_days_budget = 0
      let total_impressions_per_week = 0
      let total_clicks_per_week = 0
      let total_reach_per_week = 0
      filteredServices.map((ele: any) => {
        const total_impressions = ele.serviceCostPerImpression
          ? ele.pricing.total / parseFloat(ele.serviceCostPerImpression)
          : null
        const total_days_for_entered_budget = total_impressions ? total_impressions / ele.serviceDailyImpressions : null
        if (total_impressions) total_impressions_per_week = total_impressions_per_week + total_impressions

        const total_clicks = ele.serviceCostPerClick ? ele.pricing.total / parseFloat(ele.serviceCostPerClick) : null

        if (total_clicks) total_clicks_per_week = total_clicks_per_week + total_clicks

        const total_reach = ele.serviceCostPerReach ? ele.pricing.total / parseFloat(ele.serviceCostPerReach) : null
        const total_days_for_entered_budget_reach = total_reach ? total_reach / ele.serviceDailyReach : null
        if (total_reach) total_reach_per_week = total_reach_per_week + total_reach

        let totalDaysAverage = 0
        let totalDaysAverageNumber = 0
        if (total_days_for_entered_budget) {
          totalDaysAverage = totalDaysAverage + total_days_for_entered_budget
          totalDaysAverageNumber = totalDaysAverageNumber + 1
        }
        if (total_days_for_entered_budget) {
          totalDaysAverage = totalDaysAverage + total_days_for_entered_budget
          totalDaysAverageNumber = totalDaysAverageNumber + 1
        }
        if (total_days_for_entered_budget_reach) {
          totalDaysAverage = totalDaysAverage + total_days_for_entered_budget_reach
          totalDaysAverageNumber = totalDaysAverageNumber + 1
        }
        total_days_budget = totalDaysAverage
          ? total_days_budget + totalDaysAverage / totalDaysAverageNumber
          : total_days_budget
      })
      let mediaBuyEstimation: any = {
        partnerId: partnerId,
      }
      if (total_days_budget) {
        mediaBuyEstimation.campaignDays =
          parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100)))) !==
          parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (110 / 100))))
            ? parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100)))) +
              ' - ' +
              parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (110 / 100))))
            : parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100)))) === 0
            ? '1'
            : parseInt(getLocaleCostWithoutCurrencyNDecimals(Math.round(total_days_budget * (90 / 100))))
      }
      if (total_impressions_per_week) {
        mediaBuyEstimation.impressions =
          abbreviateNumberWithoutDecimal(Math.round(total_impressions_per_week * (85 / 100))) +
          '-' +
          abbreviateNumberWithoutDecimal(Math.round(total_impressions_per_week * (115 / 100)))
      }
      if (total_clicks_per_week) {
        mediaBuyEstimation.clicks =
          abbreviateNumberWithoutDecimal(Math.round(total_clicks_per_week * (85 / 100))) +
          '-' +
          abbreviateNumberWithoutDecimal(Math.round(total_clicks_per_week * (115 / 100)))
      }
      if (total_reach_per_week) {
        mediaBuyEstimation.reach =
          abbreviateNumberWithoutDecimal(Math.round(total_reach_per_week * (85 / 100))) +
          '-' +
          abbreviateNumberWithoutDecimal(Math.round(total_reach_per_week * (115 / 100)))
      }
      if (
        mediaBuyEstimation.campaignDays ||
        mediaBuyEstimation.impressions ||
        mediaBuyEstimation.clicks ||
        mediaBuyEstimation.reach
      )
        cartData.mediaBuyEstimates.push(mediaBuyEstimation)
    }

    if (item.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE) {
      targetItem.stickerCost = 0
      targetItem.stickerPrice = 0
      targetItem.logisticSamplePrice = 0
      targetItem.logisticSampleRate = 0
    }

    if (item.name === DISTRIBUTION_TYPE.PRINTED_INSERT) {
      targetItem.logisticInsertRate = 0
      targetItem.logisticInsertPrice = 0
    }

    const servicesTotal = calcCartItemServicesSubTotal(targetItem.services)

    targetItem.subTotal = calcSubTotal(
      servicesTotal,
      targetItem.logisticSamplePrice,
      targetItem.logisticInsertPrice,
      targetItem.stickerPrice
    )

    if (isRejectionFlow) {
      targetItem.isApprovedByUser = true
    }

    const result = calculation(cartItems, settings.managementFee, settings.partnerVat)
    cartData.additional.managementFee = result.managementFee
    cartData.additional.taxes = result.taxes
    cartData.additional.cartTotal = result.total

    delete cartData.sampleAllocated
    await dispatch(actions.addToCart(cartData))
    await dispatch(actions.fetchCart(briefId))
  }

  const handleEdit = async (partnerId: number) => {
    if (!briefId) {
      return
    }

    await dispatch(actions.setActiveSinglePartner(partnerId))
    await dispatch(actions.fetchPartnerDetails(briefId, partnerId))
  }

  const cartItems = cart.additional.items

  const getDistributionItems = (item: ICartItem) => {
    return item.services.filter((service: ICartServiceItem) => service.type === MEDIA_TYPES.DISTRIBUTION)
  }

  const getMediaBuyItems = (item: ICartItem, isCRMedia: boolean) => {
    if (isCRMedia) {
      return item.services.filter(
        (service: ICartServiceItem) =>
          service.type !== MEDIA_TYPES.DISTRIBUTION &&
          (service.name === CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY ||
            service.name === CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE)
      )
    } else {
      return item.services.filter(
        (service: ICartServiceItem) =>
          service.type !== MEDIA_TYPES.DISTRIBUTION &&
          service.name !== CONSUMER_RESEARCH_MEDIA_TYPES.SURVEY &&
          service.name !== CONSUMER_RESEARCH_MEDIA_TYPES.CHALLENGE
      )
    }
  }

  const renderCartItem = (
    item: ICartServiceItem,
    partnerId: number,
    priceModel: string,
    isCRMedia: boolean = false
  ) => {
    const key = partnerId + item.name
    const total = renderRoundedNumber(item.total, currency)
    const price = renderRoundedNumber(item.amount, currency)
    const description =
      item.type === MEDIA_TYPES.DISTRIBUTION || priceModel === PRICING_MODEL.DURATION
        ? `${price} x ${item.quantity}`
        : ''
    const blocked = blockService(item)

    return (
      <QBriefCartItem
        key={key}
        summaryPrice={total}
        summaryName={item.name}
        summaryDescription={description}
        onRemove={() => handleRemove(item, partnerId)}
        hideRemove={blocked || isCRMedia}
      />
    )
  }

  const renderCartAdditionalChargesItem = (item: any, name: string, totalPrice: number, minQty: number) => {
    const key = item.name
    const total = renderRoundedNumber(totalPrice, item.currency)
    const description = `${item.rate} ${item.currency} x ${
      minQty > totalQuantity ? minQty + ' (Minimum qty.)' : totalQuantity
    }`

    return (
      <QBriefCartItem
        key={key}
        summaryPrice={total}
        summaryName={name}
        summaryDescription={description}
        hideRemove={true}
        summarySubName={item.name}
      />
    )
  }

  const setCurrentStep = (step: BRIEF_STEPS) => {
    dispatch(actions.setCurrentStep(step))
  }

  const renderLogisticPrice = (service: ICartServiceItem, item: ICartItem) => {
    const isSample = service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE
    const price = isSample ? item.logisticSamplePrice : item.logisticInsertPrice
    const priceText = renderRoundedNumber(price, currency)

    const rate = isSample
      ? renderRoundedNumber(item.logisticSampleRate, currency)
      : renderRoundedNumber(item.logisticInsertRate, currency)

    const quantity = service.quantity ? service.quantity : 0
    const description =
      quantity < MINIMUM_DISTRIBUTION_LOGISTIC_COUNT ? MIN_LOGISTIC_PRICE_TEXT : `${rate} x ${quantity}`
    return (
      <QBriefCartItem
        summaryPrice={priceText}
        summaryName="Logistic Price"
        summaryDescription={description}
        hideRemove
      />
    )
  }

  const renderStickerPrice = (service: ICartServiceItem, item: ICartItem) => {
    const price = renderRoundedNumber(item.stickerPrice, currency)
    const rate = renderRoundedNumber(item.stickerCost, currency)

    const description = `${rate} x ${service.quantity}`
    return (
      <QBriefCartItem summaryPrice={price} summaryName="Sticker Cost" summaryDescription={description} hideRemove />
    )
  }

  const renderAdditional = (amount: number, name: string) => {
    if (!amount) {
      return
    }

    const price = renderRoundedNumber(amount, currency)

    return (
      <div className="selection-summary__item">
        {name === 'Discount' ? (
          <div className="p fw-700 selection-summary__price text-red">- {price}</div>
        ) : (
          <div className="p fw-700 selection-summary__price">{price}</div>
        )}
        <div className={`fw-520 selection-summary__name ${name !== 'Subtotal' && 'text-blue'}`}>{name}</div>
      </div>
    )
  }

  const renderCartTotal = () => {
    const total = cart.additional.cartTotal ? cart.additional.cartTotal : 0
    return renderRoundedNumber(total, currency)
  }

  const disabledButton = !cartItems.length || isSaving

  return (
    <div className="selection-summary">
      <div className="selection-summary__heading">E-COMMERCE</div>
      {cartItems.map((item: ICartItem) => {
        const distributions = getDistributionItems(item)
        const mediaBuys = getMediaBuyItems(item, false)
        const CRMedia = getMediaBuyItems(item, true)
        const partnerId = item.partnerId

        return (
          <div key={partnerId} className="selection-summary__cont">
            <QBriefCartProduct
              productImage={item.companyLogoUrl}
              productName={item.companyName}
              onEdit={() => handleEdit(item.partnerId)}
            />
            {distributions.length > 0 && <div className="p p--xs selection-summary__caption">Distribution</div>}
            {distributions.map((service: ICartServiceItem) => {
              return (
                <React.Fragment key={partnerId + service.name}>
                  {renderCartItem(service, partnerId, item.priceModel)}
                  {renderLogisticPrice(service, item)}
                  {!!item.stickerPrice &&
                    service.name === DISTRIBUTION_TYPE.PRODUCT_SAMPLE &&
                    renderStickerPrice(service, item)}
                </React.Fragment>
              )
            })}
            {CRMedia.length > 0 && <div className="p p--xs selection-summary__caption">Consumer Research</div>}
            {CRMedia.map((item1: ICartServiceItem) => renderCartItem(item1, partnerId, item.priceModel, true))}
            {mediaBuys.length > 0 && <div className="p p--xs selection-summary__caption">Media Buy</div>}
            {mediaBuys.map((item1: ICartServiceItem) => renderCartItem(item1, partnerId, item.priceModel))}
          </div>
        )
      })}

      {printingPrice && totalQuantity > 0 && (
        <div className="selection-summary__cont">
          <QBriefCartProduct
            productName={'Additional Charges'}
            onEdit={() => setCurrentStep(BRIEF_STEPS.SERVICES)}
            isAdditional={true}
          />
          {/* {copackingPrice &&
            renderCartAdditionalChargesItemCoPacking(
              copackingPrice,
              'Copackaging Price',
              cart.additional.copackingTotal,
              cart.additional.copackingMinQuantity
            )} */}
          {printingPrice &&
            renderCartAdditionalChargesItem(
              printingPrice,
              'Printing Price',
              cart.additional.printingTotal,
              cart.additional.printingMinQuantity
            )}
        </div>
      )}

      <div className="selection-summary__foot">
        {cartItems.length > 0 && !isRejectionFlow && <TestahelCouponForm />}
        {renderAdditional(cart.additional.servicesTotal, 'Services Total')}
        {!!cart.couponCode && renderAdditional(cart.additional.couponFee, 'Discount')}
        {renderAdditional(cart.additional.managementFee, `Management Fee (${settings.managementFee}%)`)}
        {renderAdditional(cart.additional.subTotal, 'Subtotal')}
        {renderAdditional(cart.additional.taxes, 'Taxes (15%)')}

        <div className="grid-row grid-row--aic grid-row--jcb">
          <div className="grid-col grid-col--auto">
            <div className="p font-weight-500">Total</div>
          </div>
          <div className="grid-col grid-col--auto">
            <div className="h5 selection-summary__total font-weight-600">{renderCartTotal()}</div>
          </div>
        </div>
        <div className="p p--ms ta--right tc--light">plus taxes</div>
        <QButton disabled={disabledButton} onClick={handleNextStep} className="mt--100 full-width" type="primary">
          Next Step
        </QButton>
        <QButton disabled={disabledButton} onClick={handleSubmit} className="mt--50 full-width" type="primary">
          {isDraft || isSelectPartners ? 'Submit & View Quotation' : 'Save Brief'}
          {cartItems.length > 0 && <span className="button__counter">{cartItems.length}</span>}
        </QButton>
        {/*<QButton className="qu-button-soft mt--50 full-width">*/}
        {/*  Download Proposal <span className="button__icon" />*/}
        {/*</QButton>*/}
        <div className="mt--75 p p--xs ta--center tc--xlight">
          Finished adding services to your cart?
          <br />
          Click Next Step to continue.
        </div>
      </div>
    </div>
  )
}
