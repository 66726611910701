import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Empty, QSelect, QRangePicker, QButton } from 'quantum_components'
import { useSelector } from 'react-redux'
import {
  getCampaignAnalyticsInfo,
  getCampaignAnalyticsMediaBuyData,
  getCampaignAnalyticsMediaBuyFilter,
  getCampaignDetails,
} from '../campaign.selectors'
import { ICartServiceItem, IOptionForSelect } from '../../brief/brief.types'
import { IAvailableCampaignMediaBuyData, ICampaignMediaBuyFilter, ICampaignPartner } from '../campaign.types'
import { useReduxDispatch } from 'src/helpers'
import { actions } from '../campaign.module'
import { useParams } from 'react-router-dom'
import MediaBuyMetrics from 'src/components/MediaBuyMetrics/MediaBuyMetrics'
import { subtypeNamesByPartner } from 'src/helpers/campaign'
import { getCurrentUser, getCurrentUserRole } from '../../core/core.selectors'
import ROLES from '../../../constants/roles'
import { BRIEF_TYPES, DISTRIBUTION_TYPE } from '../../../constants/brief'
import { FORMATS, LOCALIZATION_LANGUAGES } from 'src/constants'
import { getDateFromToday, getIsoDate } from 'src/helpers/datesHelper'
import { snakeCase } from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { DATE_PRESET } from 'src/constants/dashboard'

const CampaignAnalyticsMediaBuy = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationCampaign', 'translation', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const analyticsInfo = useSelector(getCampaignAnalyticsInfo)
  const mediaFilter = useSelector(getCampaignAnalyticsMediaBuyFilter)
  const mediaBuyData = useSelector(getCampaignAnalyticsMediaBuyData)
  const { id } = useParams<{ id: any }>()
  const role = useSelector(getCurrentUserRole)
  const isPartner = role === ROLES.PARTNER
  const campaignDetails = useSelector(getCampaignDetails)
  const currentUser = useSelector(getCurrentUser)
  const [showMediaBlock, setShowMediaBlock] = useState<boolean>(false)
  const [partner, setPartner] = useState<ICampaignPartner | undefined>(undefined)
  // const [dateRange, changeDateRange] = useState<any>(null);
  const [dateRange, changeDateRange] = useState<any>([moment(getIsoDate(getDateFromToday(DATE_PRESET.WEEK))), moment(getIsoDate(moment().endOf('day')))])

  console.log(mediaBuyData)

  useEffect(() => {
    if (role === ROLES.UNKNOWN) return
    fetchAnalytics()
  }, [])

  useEffect(() => {
    if (role === ROLES.UNKNOWN) return
    setDefaultFilter()
  }, [analyticsInfo])

  useEffect(() => {
    checkMediaBuys()
  }, [campaignDetails])

  const fetchAnalytics = () => {
    if (
      campaignDetails?.brief?.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
    ) {
      dispatch(actions.fetchCampaignAnalyticsInfo(id))
    }
    // if (
    //   campaignDetails?.brief?.briefTypes.length === 1 &&
    //   campaignDetails?.brief?.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING)
    // ) {
    //   dispatch(actions.fetchCampaignAnalyticsInfo(id))
    // }
  }

  const checkMediaBuys = () => {
    const types = campaignDetails?.types
    const mediaBuys = types?.filter(
      (type: string) => type !== DISTRIBUTION_TYPE.PRODUCT_SAMPLE && type !== DISTRIBUTION_TYPE.PRINTED_INSERT
    )

    setShowMediaBlock(!!mediaBuys?.length)
  }

  const setDefaultFilter = () => {
    if (!analyticsInfo || !analyticsInfo.availableCampaignMediaBuyData.length) {
      return
    }
    const first = analyticsInfo.availableCampaignMediaBuyData[0]
    const currentPartner = analyticsInfo?.partners[0]
    const firstPartnerId = isPartner ? currentPartner.id : first.partnerId
    const firstMediaType = first.mediaBuyTypeId
    const firstPartner = analyticsInfo.partners.find((partner: ICampaignPartner) => partner.id === firstPartnerId)
    const filter = { ...mediaFilter }
    filter.partnerId = firstPartnerId
    filter.mediaType = firstMediaType

    setFilterAndFetch(filter)
    setPartner(firstPartner)
  }

  const setFilterAndFetch = async (filter: ICampaignMediaBuyFilter) => {
    await dispatch(actions.setMediaBuyAnalyticsFilter(filter))
    if (!filter.partnerId || !filter.mediaType) {
      await dispatch(actions.campaignAnalyticsMediaBuySuccess(null))
      return
    }

    await dispatch(actions.fetchCampaignAnalyticsMediaBuy(id, filter))
  }

  const handlePartner = (id: number | null) => {
    const filter = { ...mediaFilter }
    filter.partnerId = id
    filter.mediaType = null

    setFilterAndFetch(filter)

    const targetPartner = analyticsInfo?.partners.find((partner: ICampaignPartner) => partner.id === id)
    setPartner(targetPartner)
  }

  const handleMediaType = (type: string) => {
    const filter = { ...mediaFilter }
    filter.mediaType = type

    setFilterAndFetch(filter)
  }

  const showBox = (type: string) => {
    if (!analyticsInfo || !analyticsInfo.availableCampaignMediaBuyData.length) {
      return ''
    }

    const data = analyticsInfo.availableCampaignMediaBuyData.find(
      (data: IAvailableCampaignMediaBuyData) => data.mediaBuyTypeId === type
    )

    return data ? '📦 ' : ''
  }

  const partnerOptions: IOptionForSelect[] = analyticsInfo
    ? analyticsInfo.partners.map((partner: ICampaignPartner) => ({ label: partner.companyName, value: partner.id }))
    : []

  const mediaSubtypeNames = partner ? subtypeNamesByPartner(partner.mediaBuyTypes) : []

  const mediaSubTypeOptions = mediaSubtypeNames.map((type: string): IOptionForSelect => {
    // const icon = showBox(type)
    return { label: t('services.media-buy.types.' + snakeCase(type), type, { ns: 'translation' }), value: type }
  })

  const mediaSubTypeOptionsFromCart = campaignDetails?.cart?.additional?.items.length ? campaignDetails?.cart?.additional?.items[0].services.map(
    (service: ICartServiceItem) => {
      return { label: t('services.media-buy.types.' + snakeCase(service.name), service.name, { ns: 'translation' }), value: service.name }
    }
  ) : []

  if (!showMediaBlock) return null

  const handleDatePicker = (range: any) => {
    if (!range) return
    const start = range && getIsoDate(range[0].startOf('day'))
    const end = range && getIsoDate(range[1].endOf('day'))

    const filter = { ...mediaFilter }
    filter.beforeDate = start
    filter.afterDate = end

    setFilterAndFetch(filter)

    changeDateRange([moment(start), moment(end)])
  }

  const handleResetFilter = () => {
    const filter = { ...mediaFilter }
    delete filter.beforeDate
    delete filter.afterDate

    setFilterAndFetch(filter)
    changeDateRange(null)
  }

  return (
    <div>
      <h2 className="mv-10">{t('campaign.details.media_buy')}</h2>
      <Row className="mb-10" gutter={16} data-html2canvas-ignore>
        <Col span={24}>
          <p className="font-weight-600 mb-8">
            {t('campaign.details.Select Media/Ad Type(s)')}:
          </p>
        </Col>
        {!isPartner && (
          <Col xs={24} sm={8} style={{ display: 'none' }}>
            <QSelect
              className="full-width mb-16"
              showArrow
              placeholder={t('campaign.details.select_partner')}
              value={mediaFilter.partnerId}
              options={partnerOptions}
              onChange={handlePartner}
              size="large"
            />
          </Col>
        )}
        <Col xs={24} sm={12}>
          <QSelect
            className="full-width mb-16"
            showArrow
            placeholder={t('campaign.details.Select Media Type')}
            value={
              mediaFilter.mediaType.length > 35
                ? i18n.language === LOCALIZATION_LANGUAGES.ARABIC
                  ? '...' + mediaFilter.mediaType.substring(0, 35)
                  : mediaFilter.mediaType.substring(0, 35) + '...'
                : mediaFilter.mediaType
            }
            // options={mediaSubTypeOptions}
            options={mediaSubTypeOptionsFromCart}
            onChange={handleMediaType}
            size="large"
          />
        </Col>
      </Row>
      <Row className="mb-10" gutter={16} data-html2canvas-ignore>
        <Col span={24}>{t('common.Filter_by_date', { ns: 'translationCommon' })}:</Col>
        <Col xs={24} sm={8}>
          <QRangePicker
            placeholder={[t("common.Start date", { ns: 'translationCommon' }), t("common.End date", { ns: 'translationCommon' })]}
            value={dateRange}
            allowClear={false}
            format={FORMATS.DATE_FORMAT}
            onChange={handleDatePicker}
            className="full-width"
            size="large"
          />
        </Col>
        <Col xs={12} sm={6} className={isMobile && 'pt-20'}>
          <QButton type="primary" size="small" onClick={handleResetFilter}>
            {t('common.Clear_filters', { ns: 'translationCommon' })}
          </QButton>
        </Col>
      </Row>
      <div className="grid-row grid-row--jcc">
        <div className="grid-col">
          {mediaBuyData && mediaBuyData.metrics ? (
            <MediaBuyMetrics
              selectedMetrics={mediaBuyData.selectedMetrics}
              mediaType={mediaFilter.mediaType || ''}
              details={mediaBuyData.metrics}
              dateRange={dateRange}
            />
          ) : (
            <div className="qu-chart-empty qu-chart-card h-300">
              <Empty description={<span>{t('common.No_Data', { ns: 'translationCommon' })}</span>} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CampaignAnalyticsMediaBuy
