import { Col, Divider, Row, Tag } from "antd";
import { AgeGroupCard } from "src/modules/brief/containers/components/Brief.AgeGroupCard";
import lodash from "lodash";
import React, { useEffect, useState } from "react";
import SpinnerCenter from "src/components/Spinner/spinner";
import {
  Modal,
  QButton,
  QInput,
  qnotification,
  QSelect,
} from "quantum_components";
import { getFileNameFromUrl } from "src/utils";
import IUploadFile from "src/interfaces/IUploadFile";
import { NOTIFICATION_TYPES } from "src/constants";
import { useDispatch } from "react-redux";
import * as briefActions from "src/modules/brief/brief.actions";
import { BRIEF_UPLOAD_TYPES } from "src/constants/brief";
import UploadComponent from "src/components/UploadComponent";
import { removeImageAsync, uploadImageAsync } from "../briefv3.actions";
import { useTranslation } from "react-i18next";
import * as coreActions from "src/modules/core/core.actions";

const V3UploadBannerMedia = ({
  arrayIndex,
  state,
  setState,
  type,
  lang,
  briefResponse,

  parentRefresh,
  title,
  captions,
  canEdit,
  isLoading,
  setIsLoading,
}: any) => {
  const [key, setKey] = useState(0);

  const [localFiles, setLocalFiles] = useState<any>([]);
  const { t } = useTranslation(["translationV2"]);

  useEffect(() => {
    if (lodash.isEmpty(briefResponse)) {
      setIsLoading(true);
      return;
    } else {
      setIsLoading(false);
    }
  }, [briefResponse]);

  const filesMapper = () => {
    const list = lodash.isEmpty(
      lodash.get(state[arrayIndex], `imageTargetUrl.${lang}.${type}`)
    )
      ? []
      : [state[arrayIndex]["imageTargetUrl"][lang][type]].map(
          (url: string, index: number) => {
            return {
              uid: index + "",
              name: getFileNameFromUrl(url),
              status: "done",
              url: url,
            } as IUploadFile;
          }
        );
    setLocalFiles(list);
    // setState(list);
  };

  useEffect(() => {
    filesMapper();
  }, [state]);

  const getFileProperties = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const img: any = new Image();
        img.src = fileReader.result;
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = reject;
      };
      fileReader.onerror = reject;
    });
  };

  const handleUpload = async (uploads: File[]) => {
    const { width, height }: any = await getFileProperties(uploads[0]);
    const serviceWidth = state[arrayIndex]?.width;
    const serviceHeight = state[arrayIndex]?.height;

    if (type == "desktop") {
      if (serviceWidth > 0 && serviceHeight > 0) {
        if (serviceWidth != width) {
          qnotification({
            type: "error",
            message: t("upload_media_width_validation", {
              width: serviceWidth,
            }),
          });
          return;
        }
        if (serviceHeight != height) {
          qnotification({
            type: "error",
            message: t("upload_media_height_validation", {
              height: serviceHeight,
            }),
          });
          return;
        }
      }
    }


    await handleRemove();

    setIsLoading(true);
    const content = await uploadImageAsync(
      briefResponse,
      uploads,
      BRIEF_UPLOAD_TYPES.BRIEFS_UPLOAD_IMAGES
    );

    let existingServices = [...state];
    existingServices[arrayIndex]["imageTargetUrl"][lang][type] = content[0];
    setState(existingServices);

    // await parentRefresh();
    setIsLoading(false);
    setKey((key) => key + 1);
  };

  const handleRemove = async () => {
    if (localFiles.length > 0) {
      setIsLoading(true);
      //   await removeImageAsync(
      //     briefResponse,
      //     localFiles[0],
      //     BRIEF_UPLOAD_TYPES.BRIEFS_PRODUCT_IMAGES
      //   );
      //   await parentRefresh();
      let existingServices = [...state];
      existingServices[arrayIndex]["imageTargetUrl"][lang][type] = null;

      setState(existingServices);
      setIsLoading(false);
      setKey((key) => key + 1);
    }
  };

  return (
    <>
      <UploadComponent
        key={key}
        // @ts-ignore
        // uploadStepHeading={title}
        files={localFiles}
        onChange={handleUpload}
        onRemove={handleRemove}
        isUploading={isLoading}
        caption={captions ? captions : [t("Supported formats: png, jpeg")]}
        disabled={!canEdit}
        disableRemove={!canEdit}
        showRemoveButton={true}
        removeText={t("Remove")}
        viewOnlyText={t("View")}
        viewText={t("View")}
        fileName={t(`for ${type}`)}
        onView={async (file) => {
          const response: any = await coreActions.downloadImageAsync(file.url);
          const url = URL.createObjectURL(response.data);

          window.open(url, "_blank");
          URL.revokeObjectURL(url);
        }}
        // viewOnly
        
        dragAndDrop={false}
      />
    </>
  );
};

export default React.memo(V3UploadBannerMedia);
