import React, { ReactElement, useEffect, useState } from 'react'
import { BRIEF_STEPS } from '../../../../../constants/testahel_brief'
import { useSelector } from 'react-redux'
import { getBriefView, getCart } from '../../testahelBox.selectors'
import * as actions from '../../testahelBox.actions'
import { useReduxDispatch } from '../../../../../helpers'
import TestahelBriefEditSummaryComponent from '../components/TestahelBriefEditSummary.component'

interface IProps {
  briefId: number
  setCurrentStep: (step: BRIEF_STEPS) => void
  onFinishLater: () => void
  rejection?: boolean
}

export default function TestahelFinalSummary({
  briefId,
  setCurrentStep,
  onFinishLater,
  rejection,
}: IProps): ReactElement {
  const dispatch = useReduxDispatch()
  const briefView = useSelector(getBriefView)
  const cart = useSelector(getCart)

  useEffect(() => {
    dispatch(actions.fetchViewBriefDetails(briefId))
    dispatch(actions.fetchCart(briefId))
  }, [briefId])

  const openPartner = async (id: number) => {
    setCurrentStep(BRIEF_STEPS.PARTNER)
    await dispatch(actions.fetchPartnerDetails(briefId, id))
    await dispatch(actions.setActiveSinglePartner(id))
  }

  return (
    <div className="qu-brief-content brief-step">
      <div className="brief-step-header">
        <h1>Review & submit your Consumer Research brief</h1>
        <p>
          Please make sure all details are correct before submitting your proposal. You can also
          <a onClick={onFinishLater}> save and finish this later.</a>
        </p>
      </div>
      <TestahelBriefEditSummaryComponent
        briefView={briefView}
        cart={cart}
        setCurrentStep={setCurrentStep}
        openPartner={openPartner}
        rejection={rejection}
      />
    </div>
  )
}
