export const MODULE_NAME = 'signUp'

/* actions */
export const RESET_STATE = `${MODULE_NAME}/RESET_STATE`
export const SIGN_UP_REQUEST = `${MODULE_NAME}/SIGN_UP_CODE_REQUEST`
export const SIGN_UP_SUCCESS = `${MODULE_NAME}/SIGN_UP_CODE_SUCCESS`
export const SIGN_UP_FAILURE = `${MODULE_NAME}/SIGN_UP_CODE_FAILURE`
export const SIGN_UP_RESET_ERROR = `${MODULE_NAME}/SIGN_UP_FORM_RESET_ERROR`

export const SIGN_UP_STATUS_CODES = {
  SUCCESS: 200,
  FAILURE: 400,
}
