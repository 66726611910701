import React from 'react'
import { Spin } from 'quantum_components'
import { LoadingOutlined } from '@ant-design/icons'
import './index.scss'

interface IProps {
  text?: string
  color?: string
}

const SpinnerCenter = ({ color, text }: IProps) => {
  return (
    <div className="spinner_overlay" style={{ backgroundColor: color }}>
      <div className="spinner">
        <Spin indicator={<LoadingOutlined className="spinner-icon" />} />
        {text && <div className="spinner-text">{text}</div>}
      </div>
    </div>
  )
}

export default SpinnerCenter
