import React, { ReactElement } from 'react'
import ReactGA from 'react-ga'
import { Col, QSelectCard, Row } from 'quantum_components'
import { BRIEF_TYPES } from '../../../../../constants/testahel_brief'

import imageSample from '../../../../../assets/cr_be_part_of_box@2x.png'
import imageCustomized from '../../../../../assets/cr_customized_box@2x.png'
import imageMedia from '../../../../../assets/cr_media_buying@2x.jpeg'
import imageResearch from '../../../../../assets/cr_consumer_research@2x.png'
import { LOCAL_STORAGE_KEYS } from 'src/constants'

interface IProps {
  briefTypes: BRIEF_TYPES[]
  onChange: (briefTypes: BRIEF_TYPES[]) => void
}

export default function TestahelBriefTypeForm({ briefTypes, onChange }: IProps): ReactElement {
  const updateBrief = async (type: BRIEF_TYPES) => {
    const newBriefTypes = [...briefTypes]
    if (newBriefTypes.includes(type)) {
      newBriefTypes.splice(briefTypes.indexOf(type), 1)
    } else {
      if (type === BRIEF_TYPES.PRODUCT_SAMPLE) {
        newBriefTypes.includes(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX) &&
          newBriefTypes.splice(briefTypes.indexOf(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX), 1)
      } else if (type === BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX) {
        newBriefTypes.includes(BRIEF_TYPES.PRODUCT_SAMPLE) &&
          newBriefTypes.splice(briefTypes.indexOf(BRIEF_TYPES.PRODUCT_SAMPLE), 1)
      }
      newBriefTypes.push(type)
    }

    return newBriefTypes
  }

  const handleProductSample = async () => {
    const changedBriefType = await updateBrief(BRIEF_TYPES.PRODUCT_SAMPLE)
    if (changedBriefType.length > 0)
      ReactGA.event({
        category: `Testahel Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? ' - Invited Client'
            : ''
        }`,
        action: 'Product Sample',
        label: 'Product Sample',
        value: 1,
      })

    onChange(changedBriefType)
  }

  const handleCustomizeBox = async () => {
    const changedBriefType = await updateBrief(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)
    if (changedBriefType.length > 0)
      ReactGA.event({
        category: `Testahel Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? ' - Invited Client'
            : ''
        }`,
        action: 'Customize your own Testahel Box',
        label: 'Customize your own Testahel Box',
        value: 1,
      })

    onChange(changedBriefType)
  }

  const handleConsumerResearch = async () => {
    const changedBriefType = await updateBrief(BRIEF_TYPES.CONSUMER_RESEARCH)
    if (changedBriefType.length > 0)
      ReactGA.event({
        category: `Testahel Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? ' - Invited Client'
            : ''
        }`,
        action: 'Consumer Research',
        label: 'Consumer Research',
        value: 1,
      })

    onChange(changedBriefType)
  }

  const handleMediaBuying = async () => {
    const changedBriefType = await updateBrief(BRIEF_TYPES.MEDIA_BUYING)
    if (changedBriefType.length > 0)
      ReactGA.event({
        category: `Testahel Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? ' - Invited Client'
            : ''
        }`,
        action: 'Media Buying',
        label: 'Media Buying',
        value: 1,
      })

    onChange(changedBriefType)
  }

  const isExists = (type: BRIEF_TYPES) => {
    return briefTypes.includes(type)
  }

  return (
    <div className="qu-brief-content brief-step brief-step-0 qu-testahel-brief-content">
      <div className="brief-step-header">
        <h1>What Consumer Research services are you interested in?</h1>
        <p>
          You can select Consumer Research with either Customized Box OR Consumer Research Box.
          <br />
          With our Consumer Research services you can get your target audience opinions, interactions
          <br />
          and maximize your brand Return on Investment using the most trending marketing tactics.
        </p>
      </div>
      <Row gutter={16} justify="center">
        <Col
          sm={24}
          md={8}
          // className="mr-15"
        >
          <QSelectCard
            checked={isExists(BRIEF_TYPES.PRODUCT_SAMPLE)}
            onChange={handleProductSample}
            title="Be Part of a Consumer Research Box"
            text="Your Product space is booked besides exclusive brands, be part of the next wave as users have requested your"
            cardType="checkbox"
            inputName="card"
            cardImage={imageSample}
          />
        </Col>
        <Col sm={24} md={8}>
          <QSelectCard
            checked={isExists(BRIEF_TYPES.CUSTOMIZED_TESTAHEL_BOX)}
            onChange={handleCustomizeBox}
            title="Customize your own Consumer Research Box"
            text="Show your audience your brand creativity by designing and controlling every component of your brand Box"
            cardType="checkbox"
            inputName="card"
            cardImage={imageCustomized}
          />
        </Col>
        <Col
          sm={24}
          md={8}
          // className="mr-15"
        >
          <QSelectCard
            checked={isExists(BRIEF_TYPES.CONSUMER_RESEARCH)}
            onChange={handleConsumerResearch}
            title="Consumer Research"
            text="Get your Targeting Audience opinion with the highest conversion rate in the market using surveys and challenges"
            cardType="checkbox"
            inputName="card3"
            cardImage={imageResearch}
          />
        </Col>
        {/* <Col sm={24} md={8}>
          <QSelectCard
            checked={isExists(BRIEF_TYPES.MEDIA_BUYING)}
            onChange={handleMediaBuying}
            title="Media Buying"
            text="Target your audience effectively through In App ads"
            cardType="checkbox"
            inputName="card2"
            cardImage={imageMedia}
          />
        </Col> */}
      </Row>
    </div>
  )
}
