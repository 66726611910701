import React, { ReactElement } from 'react'
import { Col, QSelectItem } from 'quantum_components'

interface ICardProps {
  checked: boolean
  label: string
  sublabel: string
  onChange: (e: MouseEvent, label: string) => void
  isViewOnly?: boolean
  img: string
  classname?: string
}

export function TestahelAgeGroupCard({
  checked,
  label,
  sublabel,
  onChange,
  isViewOnly,
  img,
  classname = '',
}: ICardProps): ReactElement {
  return (
    <Col xs={8} key={label}>
      <QSelectItem
        className={`mb-16 age-card ${classname}`}
        label={label}
        sublabel={sublabel}
        checked={checked}
        cardImage={<img src={img} alt={label} />}
        onChange={(e: MouseEvent) => (!isViewOnly ? onChange(e, label) : console.log(e))}
      />
    </Col>
  )
}
