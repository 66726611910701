import React, { ReactElement } from 'react'
// import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FORMATS } from '../../../../constants'
import { renderRoundedNumber } from '../../../../helpers/currency'
import { IBriefResponse, ICartList } from '../../brief.types'

import Logo from 'src/assets/logo_email@2x.png'
import './styles.scss'
import { useTranslation } from 'react-i18next'

interface IProps {
  briefView: IBriefResponse
  cart: ICartList
}

export default function BriefQuotation({ briefView, cart }: IProps): ReactElement {
  const { t } = useTranslation(['translation', 'translationCommon'])
  // console.log("BriefQuotation briefView", briefView)
  // console.log("BriefQuotation cart", cart)

  const normaliseServiceName = (name: string) => {
    let normalised = name.split(" ").map((part: string) => part.toLowerCase().replaceAll(/\(/g, '').replaceAll(/\)/g, '')).join("_")
    return normalised
  }

  const taxValue = renderRoundedNumber(briefView?.promotionId ? cart?.additional.promotionTaxes : cart?.additional.taxes, briefView?.owner?.currency);

  return (
    <div className="payment-sheet-container flex-column hide-proposal" id="new_brief_print_area">
      <div className="sub-container sub-container-top flex-row flex-sb">
        <div className="flex-column">
          <div><img src={cart?.additional?.items[0].companyLogoUrl} /></div>
          <strong>{cart?.additional?.items[0].companyName}</strong>
          <span>{t("brief.payment.Address")}: {cart?.additional?.items[0].companyAddress || '-'}</span>
          <span>{t("VAT")}: {cart?.additional?.items[0].companyVat || '-'}</span>

          <div style={{ marginTop: '40px' }} className="flex-column">
            <span>{t("Bill To")}</span>
            <strong>{briefView?.owner?.companyName}</strong>
            <span>{t("brief.payment.Address")}: {briefView?.owner?.clientProfile?.nationalAddress || '-'}</span>
            <span>{t("VAT")}: {briefView?.owner?.clientProfile?.vat || '-'}</span>
          </div>
        </div>
        <div className="heading">
          <h1>{t("Quotation")}</h1>
          <strong style={{ color: '#5b6475', float: 'right' }}>{t("QTN", { briefId: cart?.briefId })}</strong>
        </div>
      </div>
      <div className="sub-container-mid flex-column">
        <div className="section" style={{ paddingTop: 0, marginTop: 0 }}>
          <table className="fees-breakdown">
            <tr>
              <th>{t("Services")}</th>
              <th>{t("Rate")}</th>
              <th>{t("Quantity")}</th>
              <th>{t("Total")}</th>
            </tr>
            {cart?.additional.items.map(partner => {
              return (
                <>

                  {partner.services.map(service => {
                    return (
                      <>
                        <tr>
                          <td>{t('services.media-buy.types.' + normaliseServiceName(service.name), service.name)}</td>
                          <td>{renderRoundedNumber(service.amount, service.currency)}</td>
                          <td>{service.quantity}</td>
                          <td>{renderRoundedNumber(service.total, service.currency)}</td>
                        </tr>
                      </>
                    )
                  })}
                </>
              )
            })}

            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t("Services Total")}
              </td>
              <td>{renderRoundedNumber(cart?.additional.servicesTotal, briefView?.owner?.currency)}</td>
            </tr>

            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t("Discount")}
              </td>
              <td>{renderRoundedNumber(briefView?.promotionId ? cart?.additional.promotionCouponFee : cart?.additional.couponFee, briefView?.owner?.currency)}</td>
            </tr>
            {/*<tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                Management Fee ({cart?.additional.managementFeeRate}%)
              </td>
              <td>{renderRoundedNumber(cart?.additional.managementFee, briefView?.owner?.currency)}</td>
            </tr>*/}
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t("Subtotal")}
              </td>
              <td>{renderRoundedNumber(briefView?.promotionId ? cart?.additional.items[0].promotionSubtotal : cart?.additional.subTotal, briefView?.owner?.currency)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                {t("Taxes")}(15%)
              </td>
              <td>{taxValue}</td>
            </tr>
            <tr>
              <td></td>
              <td className="payment-label" colSpan={2}>
                <strong>{t("Total")}</strong>
              </td>
              <td>
                {renderRoundedNumber(briefView?.promotionId ? cart?.additional.promotionCartTotal : cart?.additional.cartTotal, briefView?.owner?.currency)}
              </td>
            </tr>
          </table>
          <div className="flex-column pt-50 pl-10">
            <span style={{ fontSize: '18px' }}>{t("brief.payment.Notes")}</span><br />
            <span>{t("brief.payment.Payable_to")}:</span>
            <span>{t("brief.payment.Bank_Name")}: {cart?.additional?.items[0].companyBank?.bank_name || '-'}</span>
            <span>{t("brief.payment.Address")}: {cart?.additional?.items[0].companyBank?.bank_address || '-'}</span>
            <span>{t("brief.payment.Account_No")}: {cart?.additional?.items[0].companyBank?.account_number || '-'}</span>
            <span>{t("brief.payment.IBAN_No")}: {cart?.additional?.items[0].companyBank?.iban_number || '-'}</span>
            <span>{t("brief.payment.Swift_Code")}: {cart?.additional?.items[0].companyBank?.swift_code || '-'}</span>
            <span>{t("brief.payment.Currency")}: {cart?.additional?.items[0].companyBank?.account_currency || '-'}</span>
          </div>
        </div>
      </div>
      <hr />
    </div>
  )
}
