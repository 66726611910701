import React from 'react'
import { Col } from 'quantum_components'
import { IBriefResponse } from '../../testahelBox.types'

interface Props {
  briefView: IBriefResponse
  onEdit: () => void
  viewMode?: boolean
}

const TestahelBriefTargeting = ({ briefView, onEdit, viewMode }: Props) => (
  <div className="qu-view-card m-10">
    <div className="qu-card-header">
      <h4>Targeting</h4>
      {!viewMode && <span onClick={onEdit}>Edit</span>}
    </div>
    <div className="product-brief">
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">Ages</div>
          <div className="data-view-value">{briefView.targetAgeGroups?.join(', ') || 'None'}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">Segments</div>
          <div className="data-view-value">{briefView.targetSegments?.join(', ') || 'None'}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">Interests</div>
          <div className="data-view-value">{briefView.targetInterests?.join(', ') || 'None'}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">Gender</div>
          <div className="data-view-value">{briefView.targetFocus?.join(', ') || 'None'}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">Countries</div>
          <div className="data-view-value">{briefView.targetCountries?.join(', ') || 'None'}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="data-view mb-15">
          <div className="data-view-label">Cities</div>
          <div className="data-view-value">{briefView.targetCities?.join(', ') || 'None'}</div>
        </div>
      </Col>
    </div>
  </div>
)
export default TestahelBriefTargeting
