/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useReduxDispatch } from '../../../../helpers'
import { Col, QAlert, QBackLink, Row } from 'quantum_components'
import { useSelector } from 'react-redux'
import { getBriefView, getCart } from '../testahelBox.selectors'
import { BRIEF_STATUS, BRIEF_STEPS, MEDIA_TYPES } from 'src/constants/testahel_brief'
import { ALERTS_MSG } from 'src/constants/alerts'
import * as actions from '../testahelBox.actions'
import { REQUEST_STATUS } from '../../../../constants/request'
import { ICartItem, ICartServiceItem } from '../testahelBox.types'
import { rejectedItems } from '../../../../helpers/cart'
import TestahelBriefEditSummaryComponent from '../../../../components/TestahelBriefView/TestahelBriefEditSummary.component'
import { PATHS } from '../../../../constants'

const TestahelBriefViewContainer = () => {
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const briefView = useSelector(getBriefView)
  const cart = useSelector(getCart)
  const { id } = useParams<{id: any}>()

  const fetchSettings = async () => {
    dispatch(actions.fetchBriefSettings())
  }

  useEffect(() => {
    fetchSettings()

    return function cleanup() {
      dispatch(actions.viewBriefSuccess({}))
    }
  }, [])

  useEffect(() => {
    dispatch(actions.fetchViewBriefDetails(id))
    dispatch(actions.fetchCart(id))
  }, [id])

  const setCurrentStep = (step: BRIEF_STEPS) => {
    history.push(`/testahel-brief/edit/${id}`)
    dispatch(actions.setNeedCheckLastStep(false))
    dispatch(actions.setCurrentStep(step))
  }

  const setActivePartner = async (partnerId: number) => {
    await dispatch(actions.fetchPartnerDetails(id, partnerId))
    await dispatch(actions.setActiveSinglePartner(partnerId))
  }

  const openPartner = async (partnerId: number) => {
    setCurrentStep(BRIEF_STEPS.PARTNER)
    await setActivePartner(partnerId)
  }

  const rejected = rejectedItems(cart.additional.items)

  const handleFindPartner = () => {
    history.push(`/briefs/rejected/${id}`)
  }

  const handleBack = () => {
    history.push(PATHS.BRIEF)
  }

  const getRejectedMessage = (companyName: string, type: string, rejectionReason: string) => {
    const reason = rejectionReason ? ` due to “${rejectionReason}”` : ''

    return (
      <>
        {`${companyName} rejected ${type}${reason}. `}
        <span className={'button--link'} onClick={handleFindPartner}>
          Find another partner
        </span>{' '}
        and submit your brief.
      </>
    )
  }
  return (
    <div className="qu-brief-view">
      {briefView.status === BRIEF_STATUS.APPROVED && (
        <Row gutter={20} className="pt-40">
          <Col span={24}>
            <QAlert message={ALERTS_MSG.ALERT_BRIEF_APPROVED} type="info" className="centered" />
          </Col>
        </Row>
      )}
      {(briefView.status === BRIEF_STATUS.PENDING_APPROVAL || briefView.status === BRIEF_STATUS.PENDING) && (
        <Row gutter={20} className="pt-40">
          <Col span={24}>
            <QAlert message={ALERTS_MSG.ALERT_BRIEF_VIEW} type="info" className="centered" />
          </Col>
        </Row>
      )}
      {briefView.status === BRIEF_STATUS.PENDING_ACCOUNT_APPROVAL && (
        <Row gutter={20} className="pt-40">
          <Col span={24}>
            <QAlert message={ALERTS_MSG.ALERT_BRIEF_LIST_NOT_EMPTY} type="warning" className="centered pt-40" />
          </Col>
        </Row>
      )}
      {briefView.status === BRIEF_STATUS.SELECT_PARTNERS && (
        <Row gutter={20} className="pt-40">
          <Col span={24}>
            <QAlert message={ALERTS_MSG.ALERT_ACCOUNT_APPROVED} type="info" className="centered" />
          </Col>
        </Row>
      )}

      {rejected.map((item: ICartItem) => {
        const distributions = item.services.filter(
          (service: ICartServiceItem) =>
            service.type === MEDIA_TYPES.DISTRIBUTION && service.requestStatus === REQUEST_STATUS.REJECTED
        )
        const mediaBuys = item.services.filter(
          (service: ICartServiceItem) =>
            service.type !== MEDIA_TYPES.DISTRIBUTION && service.requestStatus === REQUEST_STATUS.REJECTED
        )

        return (
          <React.Fragment key={item.partnerId}>
            {distributions.length > 0 && (
              <Row gutter={20} className={'pt-10'}>
                <Col span={24}>
                  <QAlert
                    message={getRejectedMessage(item.companyName, 'Distribution', distributions[0].rejectionReason)}
                    type="warning"
                    className="centered"
                  />
                </Col>
              </Row>
            )}
            {mediaBuys.length > 0 && (
              <Row gutter={20} className={'pt-10'}>
                <Col span={24}>
                  <QAlert
                    message={getRejectedMessage(item.companyName, 'Media Buy', mediaBuys[0].rejectionReason)}
                    type="warning"
                    className="centered"
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        )
      })}
      <div className={'client-brief-view'}>
        <QBackLink title={'Back to Briefs'} onClick={handleBack} />
        <TestahelBriefEditSummaryComponent
          briefView={briefView}
          cart={cart}
          setCurrentStep={setCurrentStep}
          openPartner={openPartner}
        />
      </div>
    </div>
  )
}

export default TestahelBriefViewContainer
