import React, { useState } from 'react'
import { Form, IconsCommon, QButton, QInput } from 'quantum_components'
import { IReferral } from '../../modules/client/referral/referral.types'
import { getIsoDate } from '../../helpers/datesHelper'
import moment from 'moment'
import VALIDATE_STATUSES from '../../constants/validateStatuses'
import { REGEXES } from '../../constants'
import { VALIDATION_MESSAGE } from '../../constants/validationMessages'

const rewardIcon = <IconsCommon.IconReward className={'test-class'} />

export interface IEmailField {
  value: string
  errorMsg: string
  duplicateMsg: string
  validateStatus: VALIDATE_STATUSES
}

export interface IEmailFormFields {
  firstEmail: IEmailField
  secondEmail: IEmailField
  thirdEmail: IEmailField
}

const InitialValues: IEmailFormFields = {
  firstEmail: { value: '', errorMsg: '', validateStatus: '', duplicateMsg: '' } as IEmailField,
  secondEmail: { value: '', errorMsg: '', validateStatus: '', duplicateMsg: '' } as IEmailField,
  thirdEmail: { value: '', errorMsg: '', validateStatus: '', duplicateMsg: '' } as IEmailField,
}

export interface Props {
  onSubmit: (data: IReferral) => void
  onClose: () => void
}

export type EmailFieldName = 'firstEmail' | 'secondEmail' | 'thirdEmail'

const DiscountForm = ({ onSubmit, onClose }: Props) => {
  const [formData, setFormData] = useState<IEmailFormFields>(InitialValues)

  const checkEmailsForUnique = (data: IEmailFormFields) => {
    if (!data.firstEmail.value || !data.secondEmail.value) return

    data.firstEmail.duplicateMsg = ''
    data.secondEmail.duplicateMsg = ''
    data.thirdEmail.duplicateMsg = ''
    data.firstEmail.validateStatus = data.firstEmail.errorMsg ? VALIDATE_STATUSES.ERROR : VALIDATE_STATUSES.SUCCESS
    data.secondEmail.validateStatus = data.secondEmail.errorMsg ? VALIDATE_STATUSES.ERROR : VALIDATE_STATUSES.SUCCESS
    data.thirdEmail.validateStatus = data.thirdEmail.errorMsg ? VALIDATE_STATUSES.ERROR : VALIDATE_STATUSES.SUCCESS

    if (data.firstEmail.value === data.secondEmail.value) {
      data.firstEmail.duplicateMsg = VALIDATION_MESSAGE.DUPLICATE_EMAIL
      data.secondEmail.duplicateMsg = VALIDATION_MESSAGE.DUPLICATE_EMAIL
      data.firstEmail.validateStatus = VALIDATE_STATUSES.ERROR
      data.secondEmail.validateStatus = VALIDATE_STATUSES.ERROR
    }

    if (data.firstEmail.value === data.thirdEmail.value) {
      data.firstEmail.duplicateMsg = VALIDATION_MESSAGE.DUPLICATE_EMAIL
      data.thirdEmail.duplicateMsg = VALIDATION_MESSAGE.DUPLICATE_EMAIL
      data.firstEmail.validateStatus = VALIDATE_STATUSES.ERROR
      data.thirdEmail.validateStatus = VALIDATE_STATUSES.ERROR
    }

    if (data.secondEmail.value === data.thirdEmail.value) {
      data.secondEmail.duplicateMsg = VALIDATION_MESSAGE.DUPLICATE_EMAIL
      data.thirdEmail.duplicateMsg = VALIDATION_MESSAGE.DUPLICATE_EMAIL
      data.secondEmail.validateStatus = VALIDATE_STATUSES.ERROR
      data.thirdEmail.validateStatus = VALIDATE_STATUSES.ERROR
    }
  }

  const isCorrectEmail = (email: string) => {
    return email.match(REGEXES.EMAIL_REGEX)
  }

  const validateEmail = (fieldName: EmailFieldName, value: string) => {
    const field = {
      value: value,
      validateStatus: '',
      errorMsg: '',
    } as IEmailField

    const isValid = isCorrectEmail(value)

    if (isValid) {
      field.validateStatus = VALIDATE_STATUSES.SUCCESS
    } else {
      field.validateStatus = VALIDATE_STATUSES.ERROR
      field.errorMsg = value ? VALIDATION_MESSAGE.EMAIL : 'Email is required'
    }

    return field
  }

  const onEmailChange = (fieldName: EmailFieldName, value: string) => {
    const data = { ...formData }
    data[fieldName] = validateEmail(fieldName, value)
    checkEmailsForUnique(data)
    setFormData(data)
  }

  const validateAll = () => {
    const data = { ...formData }
    Object.keys(data).forEach((name: string) => {
      //@ts-ignore
      data[name] = validateEmail(name, data[name].value)
      checkEmailsForUnique(data)
    })

    return data
  }

  const onFinish = (values: any) => {
    const data = validateAll()
    setFormData(data)

    const hasError = Object.keys(data).some((name: string) => {
      //@ts-ignore
      return !!data[name].errorMsg || !!data[name].duplicateMsg
    })

    if (hasError) return

    const payload = {
      emails: [formData.firstEmail.value, formData.secondEmail.value, formData.thirdEmail.value],
      startedDate: getIsoDate(moment()),
    } as IReferral
    onSubmit(payload)
  }

  return (
    <div className="discount-submit">
      {rewardIcon}
      <div className="mt--200 h1 discount-submit__title">Want to get a 5% discount on your current campaign?</div>
      <p className="mt--50 p--lg tc--light">
        All you have to do is <strong>refer 3 people</strong> to join Quantum platform. <br /> Add their email addresses
        below, submit and we will send them an email with a link to join our platform.
      </p>
      <Form onFinish={onFinish} layout="vertical" className="discount-submit__form">
        <Form.Item
          name="First email"
          validateStatus={formData.firstEmail.validateStatus}
          validateTrigger={['onChange', 'onBlur', 'onSubmit']}
          help={formData.firstEmail.errorMsg || formData.firstEmail.duplicateMsg || null}
        >
          <QInput
            value={formData.firstEmail.value}
            onBlur={(e: any) => onEmailChange('firstEmail', e.target.value)}
            onChange={(e: any) => onEmailChange('firstEmail', e.target.value)}
            placeholder="Enter email address"
            size="large"
            allowClear
            type="email"
          />
        </Form.Item>
        <Form.Item
          name="Second Email"
          validateStatus={formData.secondEmail.validateStatus}
          validateTrigger={['onChange', 'onBlur', 'onSubmit']}
          help={formData.secondEmail.errorMsg || formData.secondEmail.duplicateMsg || null}
        >
          <QInput
            value={formData.secondEmail.value}
            onBlur={(e: any) => onEmailChange('secondEmail', e.target.value)}
            onChange={(e: any) => onEmailChange('secondEmail', e.target.value)}
            placeholder="Enter email address"
            size="large"
            allowClear
            type="email"
          />
        </Form.Item>
        <Form.Item
          name="Third Email"
          validateStatus={formData.thirdEmail.validateStatus}
          validateTrigger={['onChange', 'onBlur', 'onSubmit']}
          help={formData.thirdEmail.errorMsg || formData.thirdEmail.duplicateMsg || null}
        >
          <QInput
            value={formData.thirdEmail.value}
            onBlur={(e: any) => onEmailChange('thirdEmail', e.target.value)}
            onChange={(e: any) => onEmailChange('thirdEmail', e.target.value)}
            placeholder="Enter email address"
            size="large"
            allowClear
            type="email"
          />
        </Form.Item>
        <div className="grid-row grid-row--jcc mt-35">
          <div className="grid-col grid-col--auto">
            <QButton className="w-180" type="primary" htmlType={'submit'}>
              Submit
            </QButton>
          </div>
        </div>
        <div className="grid-row grid-row--jcc mt-35">
          <div className="grid-col grid-col--auto">
            <button onClick={onClose} className="button button--link">
              No, I don’t want a discount
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default DiscountForm
