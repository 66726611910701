import React, { ReactElement } from 'react'
import BriefCartSummary from '../../modules/brief/containers/components/Brief.CartSummary'
import { IBriefResponse, ICartList } from '../../modules/brief/brief.types'
import { Col, Row } from 'quantum_components'
import BriefViewSummary from '../../modules/brief/containers/components/Brief.View.Summary'
import { BRIEF_PRINT_ID } from '../../constants/brief'

interface IProps {
  brief: IBriefResponse
  cart: ICartList
  summaryTitle: string
  summarySubtitle: string
  partnerIds?: number[]
  partnerQuantity?: number
}

export default function BriefViewDesktopComponent({
  brief,
  cart,
  summaryTitle,
  summarySubtitle,
  partnerIds,
  partnerQuantity,
}: IProps): ReactElement {
  return (
    <Row gutter={20} className="pt-20" id={BRIEF_PRINT_ID}>
      <Col span={16}>
        <BriefViewSummary
          briefView={brief}
          setCurrentStep={() => null}
          title="Campaign Details"
          subtitle={summarySubtitle}
          partnerIds={partnerIds}
          partnerQuantity={partnerQuantity}
          viewMode
        />
      </Col>
      <Col span={8}>
        <BriefCartSummary setCurrentStep={() => null} cart={cart} onEdit={() => null} viewMode />
      </Col>
    </Row>
  )
}
