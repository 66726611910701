import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'quantum_components'

import { actions } from '../partnerProfile.module'
import { PARTNER_STEPS, PRICING_MODEL } from 'src/constants/profile'
import { useReduxDispatch } from 'src/helpers'
import { getCurrentStep, getForm, getPartnerPartnerPercent, getProfileLoading } from '../profile.selectors'
import PartnersProfileFooter from './Layout/profile-footer'
import PartnersProfileHeader from './Layout/profile-header'
import PartnersProfileSidebar from './Layout/profile-sidebar'
import '../styles.scss'
import { StepOneContainer } from './Layout/Steps/StepOne'
import { StepTwoContainer } from './Layout/Steps/StepTwo'
import { StepThreeContainer } from './Layout/Steps/StepThree'
import { StepFourContainer } from './Layout/Steps/StepFour'
import { getCurrentUser } from 'src/modules/core/core.selectors'
import { calculateProfilePercent } from 'src/helpers/profile'
import { omit } from 'lodash'

export const PARTNER_STEPS_EDIT: PARTNER_STEPS[] = [
  PARTNER_STEPS.GENERAL,
  PARTNER_STEPS.SALES,
  PARTNER_STEPS.CUSTOMERS,
  PARTNER_STEPS.AVAILABLE,
]

const ProfilePartnerContainer = () => {
  const currentStep = useSelector(getCurrentStep)
  const currentIndex = PARTNER_STEPS_EDIT.indexOf(currentStep)
  const dispatch = useReduxDispatch()
  const form = useSelector(getForm)
  const getHasUser: any = useSelector(getCurrentUser)
  const getPartnerPercent = useSelector(getPartnerPartnerPercent)
  const partnerLoading = useSelector(getProfileLoading)

  const [errors, setErrors] = useState({
    [PARTNER_STEPS.GENERAL]: false,
    [PARTNER_STEPS.SALES]: false,
    [PARTNER_STEPS.CUSTOMERS]: false,
    [PARTNER_STEPS.AVAILABLE]: false,
  })

  const handleSetErrors = (stepName: string, value: boolean) => {
    setErrors({ ...errors, [stepName]: value })
  }

  const fetchSettings = () => dispatch(actions.fetchPartnerProfileSettings())

  useEffect(() => {
    fetchSettings()
  }, [])

  useEffect(() => {
    if (!getHasUser.id) return
    const data = dispatch(actions.fetchPartnerProfile())
    dispatch(actions.setForm({ data }))
  }, [getHasUser])

  useEffect(() => {
    dispatch(actions.setPartnerProfilePercent(calculateProfilePercent(form)))
  }, [form])

  const handleNext = () => {
    dispatch(actions.setCurrentStep(PARTNER_STEPS_EDIT[currentIndex + 1]))
  }

  const handleBack = () => {
    dispatch(actions.setCurrentStep(PARTNER_STEPS_EDIT[PARTNER_STEPS_EDIT.indexOf(currentStep) - 1]))
  }

  function getContent() {
    switch (currentIndex) {
      case 0:
        return (
          <StepOneContainer
            setError={handleSetErrors}
            isViewOnly={getHasUser && getHasUser.partnerPermissionLevel == 'view' ? true : false}
          />
        )
      case 1:
        return (
          <StepTwoContainer
            setError={handleSetErrors}
            isViewOnly={getHasUser && getHasUser.partnerPermissionLevel == 'view' ? true : false}
          />
        )
      case 2:
        return (
          <StepThreeContainer
            setError={handleSetErrors}
            isViewOnly={getHasUser && getHasUser.partnerPermissionLevel == 'view' ? true : false}
          />
        )
      case 3:
        return (
          <StepFourContainer
            setError={handleSetErrors}
            isViewOnly={getHasUser && getHasUser.partnerPermissionLevel == 'view' ? true : false}
          />
        )
    }
  }

  async function saveUpdates() {
    const warehouseAddresses = form.warehouseAddresses?.filter(e => e)
    const newForm = omit(
      {
        ...form,
        warehouseAddresses,
        completion: getPartnerPercent,
        metrics: { ...form.metrics, Looks: form.Looks, Leads: form.Leads },
      },
      ['Looks', 'Leads']
    )
    if (newForm.mbPricingModel === PRICING_MODEL.DURATION) {
      newForm.mediaBuyTypes.map((ele: any) => {
        ele.costPerClick = null
        ele.costPerImpression = null
        ele.costPerReach = null
        ele.dailyClicks = null
        ele.dailyImpressions = null
        ele.dailyReach = null
        ele.marketingMetrics = null
      })
    } else if (newForm.mbPricingModel === PRICING_MODEL.METRICS) {
      newForm.mediaBuyTypes.map((ele: any) => {
        ele.duration = null
        ele.avgImpressions = null
        ele.priceForDuration = null
        ele.priceForImpression = null
        ele.pricePerActivity = null
      })
    }

    if (form.hasDynamicSamplePricing) {
      if (form.isSampleDistribution) {
        if (newForm.dynamicSamplePriceTypes && newForm.dynamicSamplePriceTypes.length) {
          newForm.dynamicSamplePrices &&
            Object.entries(newForm.dynamicSamplePrices).forEach(([key, value]) => {
              const priceEle = newForm.dynamicSamplePriceTypes?.find((ele: string) => ele === key)
              !priceEle && newForm.dynamicSamplePrices && delete newForm.dynamicSamplePrices[key]
            })
        } else {
          newForm.dynamicSamplePriceTypes = null
          newForm.dynamicSamplePrices = null
        }
      } else {
        delete newForm.dynamicSamplePriceTypes
        delete newForm.dynamicSamplePrices
      }
      delete newForm.pricePerSample
    } else {
      delete newForm.dynamicSamplePriceTypes
      delete newForm.dynamicSamplePrices
    }

    dispatch(actions.fetchPartnerProfileSave(newForm))
  }
  const disabledButton = Object.values(errors).some(e => e)
  if (partnerLoading) return <p></p>
  return (
    <div className="partner-container">
      <div className="partner-container-content">
        <Row gutter={20}>
          <Col xs={24} sm={24} md={16}>
            <PartnersProfileHeader />
          </Col>
          <Col xs={24}>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={16}>
                {getContent()}
              </Col>
              <Col xs={24} sm={24} md={8}>
                <PartnersProfileSidebar currentStep={currentStep} />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={16}>
            <PartnersProfileFooter
              currentIndex={currentIndex}
              onBack={handleBack}
              onNext={handleNext}
              onFinishLater={() => saveUpdates()}
              disabled={getHasUser && getHasUser.partnerPermissionLevel == 'view' ? true : disabledButton}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ProfilePartnerContainer
