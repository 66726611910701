import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import Logo_Image from 'src/assets/ReportImages/logo.png'
import { FORMATS } from 'src/constants'
import { getBase64 } from 'src/utils/getBase64'
import { ICampaignReportData } from '../../campaignV3.types'

import './styles.scss'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'src/modules/core/core.selectors'

interface IProps {
  campaignReportDetails: ICampaignReportData
}

export default function ReportPage4({ campaignReportDetails }: IProps): ReactElement {
  const currentUser = useSelector(getCurrentUser);
  const [CompanyLogoImage, setCompanyLogoImage] = useState('')
  const [ClientLogoImage, setClientLogoImage] = useState('')

  useEffect(() => {
    getCompanyLogoImage()
    getClientLogoImage()
  }, [campaignReportDetails])

  const getCompanyLogoImage = async () => {
    const logoImageUrl = currentUser?.partnerData?.logo ? currentUser?.partnerData?.logo : ''
    if (!logoImageUrl) return

    const image = await getBase64(logoImageUrl)
    setCompanyLogoImage(image)
  }

  const getClientLogoImage = async () => {
    const logoImageUrl = campaignReportDetails?.companyLogo ? campaignReportDetails.companyLogo : ''
    if (!logoImageUrl) return

    const image = await getBase64(logoImageUrl)
    setClientLogoImage(image)
  }

  return (
    <div className="report-page-container flex-column report-page4">
      <div className='main-first-div'>
        <h1 className="fourth-page-heading">Thank You</h1>
      </div>
      <img className="first-page-company-logo" alt="company-logo" src={CompanyLogoImage} />
      <img className="first-page-client-logo" alt="client-logo" src={ClientLogoImage} />
      <img className="first-page-logo-image" alt="quantums-logo" src={Logo_Image} />
    </div>
  )
}
