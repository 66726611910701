export enum PARTNER_STEPS {
  GENERAL = 'GENERAL_INFO',
  SALES = 'SALES_INFO',
  CUSTOMERS = 'CUSTOMERS_LOCALE',
  AVAILABLE = 'AVAILABLE_SERVICES',
}

export const PARTNER_STEPS_NAMES: { [key: string]: string } = {
  [PARTNER_STEPS.GENERAL]: 'General Info',
  [PARTNER_STEPS.SALES]: 'Sales Info',
  [PARTNER_STEPS.CUSTOMERS]: 'Customers & Locale',
  [PARTNER_STEPS.AVAILABLE]: 'Available Service',
}

export const PARTNER_STEPS_SIDE_BAR: { [key: string]: string } = {
  [PARTNER_STEPS.GENERAL]: 'General Company Info',
  [PARTNER_STEPS.SALES]: 'Sales Information',
  [PARTNER_STEPS.CUSTOMERS]: 'Customers & Locale',
  [PARTNER_STEPS.AVAILABLE]: 'Available Services',
}

export enum GENERAL_COMPANY_INFO {
  COMPANY_NAME = 'Company Name',
  COMPANY_DESCRIPTION = 'Company Description',
  COMPANY_WEBSITE = 'Company Website',
  HEADQUARTERS_ADDRESS = 'Headquarters Address',
  SOCIAL_MEDIA_URLS = 'Social Media followers',
  AUTOMATIC_APPROVAL = 'Automatic Approval',
}

export const GENERAL_COMPANY_INFO_NAMES: { [key: string]: string } = {
  [GENERAL_COMPANY_INFO.COMPANY_NAME]: 'Company Name',
  [GENERAL_COMPANY_INFO.COMPANY_DESCRIPTION]: 'Company Description',
  [GENERAL_COMPANY_INFO.COMPANY_WEBSITE]: 'Company Website',
  [GENERAL_COMPANY_INFO.HEADQUARTERS_ADDRESS]: 'Headquarters Address',
  [GENERAL_COMPANY_INFO.SOCIAL_MEDIA_URLS]: 'Social Media followers',
  [GENERAL_COMPANY_INFO.AUTOMATIC_APPROVAL]: 'Automatic Approval',
}
export const GENERAL_COMPANY_INFO_VALUES: { [key: string]: string } = {
  [GENERAL_COMPANY_INFO.COMPANY_NAME]: 'companyName',
  [GENERAL_COMPANY_INFO.COMPANY_DESCRIPTION]: 'companyDescription',
  [GENERAL_COMPANY_INFO.COMPANY_WEBSITE]: 'companyWebsite',
  [GENERAL_COMPANY_INFO.HEADQUARTERS_ADDRESS]: 'headquarterAddress',
  [GENERAL_COMPANY_INFO.SOCIAL_MEDIA_URLS]: 'metrics',
  [GENERAL_COMPANY_INFO.AUTOMATIC_APPROVAL]: 'isAutomaticallyApprovedRequest',
}

export const GENERAL_COMPANY_INFO_INITIAL_VALUES: { [key: string]: string | {} } = {
  [GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.COMPANY_NAME]]: '',
  [GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.COMPANY_DESCRIPTION]]: '',
  [GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.COMPANY_WEBSITE]]: '',
  [GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.HEADQUARTERS_ADDRESS]]: '',
  [GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.SOCIAL_MEDIA_URLS]]: {},
  [GENERAL_COMPANY_INFO_VALUES[GENERAL_COMPANY_INFO.AUTOMATIC_APPROVAL]]: '',
}

export enum SALES_INFORMATION {
  PRODUCT_CATEGORIES = 'Product Categories',
  MONTHLY_VISITORS = 'Monthly Visitors',
  MONTHLY_CUSTOMERS = 'Monthly Customers',
  MONTHLY_ORDERS = 'Monthly Orders',
  AVERAGE_ORDER_COST = 'Average Order Cost',
  PRIMARY_STORE_CURRENCY = 'Primary Store Currency',
  VAT = 'VAT',
}

export const SALES_INFORMATION_NAMES: { [key: string]: string } = {
  [SALES_INFORMATION.PRODUCT_CATEGORIES]: 'Product Categories',
  [SALES_INFORMATION.MONTHLY_VISITORS]: 'Monthly Visitors',
  [SALES_INFORMATION.MONTHLY_CUSTOMERS]: 'Monthly Customers',
  [SALES_INFORMATION.MONTHLY_ORDERS]: 'Monthly Orders',
  [SALES_INFORMATION.AVERAGE_ORDER_COST]: 'Average Order Cost',
  [SALES_INFORMATION.PRIMARY_STORE_CURRENCY]: 'Primary Store Currency',
  [SALES_INFORMATION.VAT]: 'VAT',
}

export const SALES_INFORMATION_VALUES: { [key: string]: string } = {
  [SALES_INFORMATION.PRODUCT_CATEGORIES]: 'productCategories',
  [SALES_INFORMATION.MONTHLY_VISITORS]: 'Looks',
  [SALES_INFORMATION.MONTHLY_CUSTOMERS]: 'Leads',
  [SALES_INFORMATION.MONTHLY_ORDERS]: 'amountOfOrders',
  [SALES_INFORMATION.AVERAGE_ORDER_COST]: 'costOfOrder',
  [SALES_INFORMATION.PRIMARY_STORE_CURRENCY]: 'currency',
  [SALES_INFORMATION.VAT]: 'vat',
}

export const SALES_INFORMATION_INITIAL_VALUES: { [key: string]: string | Array<string> | {} } = {
  [SALES_INFORMATION_VALUES[SALES_INFORMATION.PRODUCT_CATEGORIES]]: [],
  [SALES_INFORMATION_VALUES[SALES_INFORMATION.MONTHLY_VISITORS]]: '',
  [SALES_INFORMATION_VALUES[SALES_INFORMATION.MONTHLY_CUSTOMERS]]: '',
  [SALES_INFORMATION_VALUES[SALES_INFORMATION.MONTHLY_ORDERS]]: '',
  [SALES_INFORMATION_VALUES[SALES_INFORMATION.AVERAGE_ORDER_COST]]: '',
  [SALES_INFORMATION_VALUES[SALES_INFORMATION.PRIMARY_STORE_CURRENCY]]: '',
  [SALES_INFORMATION_VALUES[SALES_INFORMATION.VAT]]: '',
}

export enum CUSTOMERS_AND_LOCALE {
  GENDER_FOCUS = 'Gender Focus',
  AGE_GROUPS = 'Age Groups',
  TRAGETED_SEGMENTS = 'Trageted Segments',
  COUNTRIES = 'Countries',
  CITIES = 'Cities',
}

export const CUSTOMERS_AND_LOCALE_NAMES: { [key: string]: string } = {
  [CUSTOMERS_AND_LOCALE.GENDER_FOCUS]: 'Gender Focus',
  [CUSTOMERS_AND_LOCALE.AGE_GROUPS]: 'Age Groups',
  [CUSTOMERS_AND_LOCALE.TRAGETED_SEGMENTS]: 'Trageted Segments',
  [CUSTOMERS_AND_LOCALE.COUNTRIES]: 'Countries',
  [CUSTOMERS_AND_LOCALE.CITIES]: 'Cities',
}

export const CUSTOMERS_AND_LOCALE_VALUES: { [key: string]: string } = {
  [CUSTOMERS_AND_LOCALE.GENDER_FOCUS]: 'partnerGenders',
  [CUSTOMERS_AND_LOCALE.AGE_GROUPS]: 'partnerAgeGroups',
  [CUSTOMERS_AND_LOCALE.TRAGETED_SEGMENTS]: 'partnerTargetSegments',
  [CUSTOMERS_AND_LOCALE.COUNTRIES]: 'countries',
  [CUSTOMERS_AND_LOCALE.CITIES]: 'cities',
}

export const CUSTOMERS_AND_LOCALE_INITIAL_VALUES: { [key: string]: Array<string> | null } = {
  [CUSTOMERS_AND_LOCALE_VALUES[CUSTOMERS_AND_LOCALE.GENDER_FOCUS]]: null,
  [CUSTOMERS_AND_LOCALE_VALUES[CUSTOMERS_AND_LOCALE.AGE_GROUPS]]: null,
  [CUSTOMERS_AND_LOCALE_VALUES[CUSTOMERS_AND_LOCALE.TRAGETED_SEGMENTS]]: null,
  [CUSTOMERS_AND_LOCALE_VALUES[CUSTOMERS_AND_LOCALE.COUNTRIES]]: [],
  [CUSTOMERS_AND_LOCALE_VALUES[CUSTOMERS_AND_LOCALE.CITIES]]: [],
}

export enum AVAILABLE_SERVICES {
  SAMPLE_DISTRIBUTION = 'Sample Distribution',
  SAMPLE_PRICE = 'Sample Price',
  DYNAMIC_SAMPLE_PRICE_TYPES = 'Dynmaic Sample Price Types',
  DYNAMIC_SAMPLE_PRICES = 'Dynmaic Sample Prices',
  INSERT_DISTRIBUTION = 'Insert Distribution',
  INSERT_PRICE = 'Insert Price',
  WAREHOUSE_ADDRESS = 'Warehouse Address',
  MEDIA_BUYING = 'Media Buying',
  IMPRESSION_RATE_MEDIA_BUYING = 'Media Buying Impression Rates',
  CONVERSION_RATE_MEDIA_BUYING = 'Media Buying Conversion Rate',
  REACH_RATE_MEDIA_BUYING = 'Media Buying Reach Rate',
  ADVERTISEMENT_LANGUAGE_MEDIA_BUYING = 'Media Buying Advertisement Language',
  PRICING_MODEL_MEDIA_BUYING = 'Media Buying Pricing Model',
  MEDIA_BUYING_TYPES = 'Media Buying Types',
}

export const AVAILABLE_SERVICES_VALUES: { [key: string]: string } = {
  [AVAILABLE_SERVICES.SAMPLE_DISTRIBUTION]: 'isSampleDistribution',
  [AVAILABLE_SERVICES.SAMPLE_PRICE]: 'pricePerSample',
  [AVAILABLE_SERVICES.DYNAMIC_SAMPLE_PRICE_TYPES]: 'dynamicSamplePriceTypes',
  [AVAILABLE_SERVICES.DYNAMIC_SAMPLE_PRICES]: 'dynamicSamplePrices',
  [AVAILABLE_SERVICES.INSERT_DISTRIBUTION]: 'isInsertDistribution',
  [AVAILABLE_SERVICES.INSERT_PRICE]: 'pricePerInsert',
  [AVAILABLE_SERVICES.WAREHOUSE_ADDRESS]: 'warehouseAddresses',
  [AVAILABLE_SERVICES.MEDIA_BUYING]: 'isMediaBuy',
  [AVAILABLE_SERVICES.IMPRESSION_RATE_MEDIA_BUYING]: 'impressionRate',
  [AVAILABLE_SERVICES.CONVERSION_RATE_MEDIA_BUYING]: 'conversionRate',
  [AVAILABLE_SERVICES.REACH_RATE_MEDIA_BUYING]: 'reachRate',
  [AVAILABLE_SERVICES.ADVERTISEMENT_LANGUAGE_MEDIA_BUYING]: 'advertisementLanguage',
  [AVAILABLE_SERVICES.PRICING_MODEL_MEDIA_BUYING]: 'mbPricingModel',
  [AVAILABLE_SERVICES.MEDIA_BUYING_TYPES]: 'mediaBuyTypes',
}

export const AVAILABLE_SERVICES_INITIAL_VALUES: { [key: string]: string | string[] | boolean | null } = {
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_DISTRIBUTION]]: false,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.SAMPLE_PRICE]]: '',
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.DYNAMIC_SAMPLE_PRICE_TYPES]]: null,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.DYNAMIC_SAMPLE_PRICES]]: null,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_DISTRIBUTION]]: false,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.INSERT_PRICE]]: '',
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.WAREHOUSE_ADDRESS]]: [],
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING]]: false,
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.IMPRESSION_RATE_MEDIA_BUYING]]: '',
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.CONVERSION_RATE_MEDIA_BUYING]]: '',
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.REACH_RATE_MEDIA_BUYING]]: '',
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.ADVERTISEMENT_LANGUAGE_MEDIA_BUYING]]: [],
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.PRICING_MODEL_MEDIA_BUYING]]: '',
  [AVAILABLE_SERVICES_VALUES[AVAILABLE_SERVICES.MEDIA_BUYING_TYPES]]: [],
}

export const NAMES_PUBLIC_PROFILE_FIELDS = {
  LISTING_SHORT_DESCRIPTION: 'listingShortDescription',
  HIGHLIGHTS: 'highlights',
  METRICS_MARKETING: 'metricsMarketing',
  COMPANY_LOGO: 'companyLogo',
  PREVIEW_CARD_IMAGE: 'previewCardImage',
  LISTING_HEADER_IMAGE: 'listingHeaderImage',
}

export const ADVERTISEMENT_LANGUAGES = {
  ARABIC: 'arabic',
  ENGLISH: 'english',
}

export const PRICING_MODEL = {
  DURATION: 'duration',
  METRICS: 'metrics',
}

export const DynamicPricingTypes = {
  Distribution: {
    MASS_DISTRIBUTION: 'Mass Distribution',
    TARGETED_DISTRIBUTION: 'Targeted Distribution',
  },
  SampleType: {
    DRY: 'Dry',
    FRESH_GOODS: 'Fresh Goods',
    FROZEN: 'Frozen',
  },
}

export const DynamicPricesMainTypes = {
  Distribution: 'Distribution Types',
  SampleType: 'Sample Types',
}

export const DynamicPricingTypeLabels: { [key: string]: string } = {
  MASS_DISTRIBUTION: 'Mass Distribution',
  TARGETED_DISTRIBUTION: 'Targeted Distribution',
  DRY: 'Dry',
  FRESH_GOODS: 'Fresh Goods',
  FROZEN: 'Frozen',
}
