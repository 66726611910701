import React, { ReactElement, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { getBriefSettings } from '../../testahelBox.selectors'
import { Col, Form, QButton, QCheckbox, QRadio, QSelect, Row } from 'quantum_components'
import { Select } from 'antd'
import {
  BRIEF_STEPS,
  BRIEF_STEPS_NAMES,
  BRIEF_TYPES,
  COUNTRY_NAME,
  GENDER,
} from '../../../../../constants/testahel_brief'
import { IBrief, ICity, ICountry, IOptionForSelect, ITargetingStep } from '../../testahelBox.types'
import { isMobile } from 'react-device-detect'

import { ReactComponent as LogOut } from 'src/assets/icons/logout-icon-alt.svg'
import TestahelPopoverSummary from '../components/TestahelBrief.PopoverSummary'
import { TestahelAgeGroupCard } from '../components/TestahelBrief.AgeGroupCard'
import { first } from 'lodash'
import { filterTestahelCountries, validateCountriesAndCities } from '../../../../../helpers/validation'
import { LOCAL_STORAGE_KEYS } from 'src/constants'

interface TargetingErrors {
  gender: string
  country: string
  ageGroup: string
}

interface IAgeGroup {
  value: string
  label: string
  sublabel: string
  img: string
}

type CheckboxFields = 'gender' | 'country' | 'ageGroup'

interface IProps {
  brief: IBrief
  targetingFields: ITargetingStep
  onChange: (targetingFields: ITargetingStep) => void
  onFinishLater: () => void
  disabledFinishLater: boolean
}

export default function TestahelTargetingForm({
  brief,
  targetingFields,
  onChange,
  onFinishLater,
  disabledFinishLater,
}: IProps): ReactElement {
  const settings = useSelector(getBriefSettings)
  const [form] = Form.useForm()
  const [errors, setErrors] = useState<TargetingErrors>({ gender: '', country: '', ageGroup: '' } as TargetingErrors)
  const [countries, setCountries] = useState<ICountry[]>([])
  const [cityError, setCityError] = useState<boolean>(false)
  const [districts, setDistricts] = useState([])

  const { Option, OptGroup } = Select

  const productTypeSettings = settings.productTypes.map((type: string) => {
    return { label: type, value: type }
  })
  const productTypeOptions: IOptionForSelect[] = [...productTypeSettings]

  useEffect(() => {
    form.setFieldsValue(targetingFields)
  })

  useEffect(() => {
    let districts: any = []
    setDistricts(districts)
    settings.countries.forEach(country => {
      country.city.forEach(city => {
        if (targetingFields.targetCities.includes(city.name)) {
          districts.push(...city.districts)
          setDistricts(districts)
        }
      })
    })
  }, [settings])

  useEffect(() => {
    const filtered = filterTestahelCountries(targetingFields.targetCountries, settings.countries)
    filtered.map(ele => {
      ele.city[
        ele.city.findIndex(selectVal => {
          return selectVal.id == -1
        })
      ].country_id = -1
      ele.city[
        ele.city.findIndex(selectVal => {
          return selectVal.id == 0
        })
      ].country_id = -1
      if (ele.city.filter(val => targetingFields.targetCities.includes(val.name)).length == ele.city.length - 2) {
        ele.city[
          ele.city.findIndex(selectVal => {
            return selectVal.id == -1
          })
        ].country_id = 0
      } else {
        ele.city[
          ele.city.findIndex(selectVal => {
            return selectVal.id == 0
          })
        ].country_id = 0
      }
    })
    setCountries(filtered)
  }, [targetingFields])

  const handleGender = (e: any) => {
    ReactGA.event({
      category: `Create/Edit Brief ${
        localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
          ? '- Invited Client'
          : ''
      } - Gender Focus`,
      action: e.target.id,
      label: e.target.id,
      value: 1,
    })
    const fields = { ...targetingFields }
    const { id } = e.target
    fields.targetFocus = [id]
    validateCheckboxes(fields.targetFocus, 'gender')
    onChange(fields)
  }

  const validateCheckboxes = async (items: any[], fieldName: CheckboxFields) => {
    const name = fieldName === 'ageGroup' ? 'age group' : fieldName
    const newErrors = { ...errors }
    const msg = items.length > 0 ? '' : `Should be at least one target ${name}.`
    newErrors[fieldName] = msg
    setErrors(newErrors)
  }

  const handleCityChange = async (values: string[], cities: any[]) => {
    countries.forEach(country => {
      if (cities.filter(val => val.key === '0').find(val => val.country == country.name)) {
        country.city.map(val => {
          if (!values.includes(val.name)) {
            values.push(val.name)
          }
        })
      }

      if (cities.filter(val => val.key === '-1').find(val => val.country == country.name)) {
        country.city.map(val => {
          if (values.includes(val.name)) {
            values.splice(values.indexOf(val.name), 1)
          }
        })
      }
    })

    values = values.filter(val => val.search('Select All') == -1 && val.search('Deselect All') == -1)
    const fields = { ...targetingFields }
    fields.targetCities = values
    const isValid = validateCountriesAndCities(fields, settings.countries)
    setCityError(!isValid)

    let districts: any = []
    setDistricts(districts)
    countries.forEach(country => {
      country.city.forEach(city => {
        if (values.includes(city.name)) {
          districts.push(...city.districts)
          setDistricts(districts)
        }
      })
    })

    if (districts.length)
      fields.targetDistricts.map((ele: any, index: number) => {
        const findDistrict = districts.find((ele1: any) => ele1.name === ele)
        if (!findDistrict) fields.targetDistricts.splice(index, 1)
      })
    else fields.targetDistricts = []

    onChange(fields)
  }

  const handleDistrictChange = async (values: string[], selectedDistricts: any[]) => {
    const fields = { ...targetingFields }
    let filteredDistrict: any = []
    selectedDistricts.map(district => {
      filteredDistrict.push(district.value)
    })
    fields.targetDistricts = filteredDistrict
    onChange(fields)
  }

  const handleAgeGroup = (e: any, name: string) => {
    const fields = { ...targetingFields }
    const items = fields.targetAgeGroups
    !items.includes(name) &&
      ReactGA.event({
        category: `Create/Edit Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? '- Invited Client'
            : ''
        } - Age Groups`,
        action: name,
        label: name,
        value: 1,
      })
    items.includes(name) ? items.splice(items.indexOf(name), 1) : items.push(name)
    validateCheckboxes(items, 'ageGroup')

    onChange(fields)
  }

  const handleSegmentsChange = (values: string[]) => {
    const fields = { ...targetingFields }
    let difference = values.filter(x => !fields.targetSegments.includes(x))
    difference.length > 0 &&
      ReactGA.event({
        category: `Create/Edit Brief ${
          localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN) ||
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
            ? '- Invited Client'
            : ''
        } - Segments`,
        action: difference[0],
        label: difference[0],
        value: 1,
      })
    fields.targetSegments = values
    onChange(fields)
  }

  const handleInterestsChange = (values: string[]) => {
    const fields = { ...targetingFields }
    fields.targetInterests = values
    onChange(fields)
  }

  const segments: IOptionForSelect[] = settings.targetSegments.map((type: any) => {
    return { id: 'targetSegments', label: type.name, value: type.name }
  })

  const ageGroups = () => {
    const reducer = (s: any) => {
      const value = s.from + (s.to ? '-' + s.to + ' ' + s.name : '+ ' + s.name)
      const sublabel = s.from + (s.to ? '-' + s.to : '+')
      const label = s.name
      const img = s.src

      return { label, sublabel, value, img } as IAgeGroup
    }

    return settings.ageGroups.map((group: any) => reducer(group))
  }

  return (
    <Form form={form} layout="vertical" hideRequiredMark initialValues={targetingFields}>
      <div className={`form form-brief form--horizontal`}>
        <div className="form__head">
          <div className="form__number">
            {isMobile ? <span>{`2. ${BRIEF_STEPS_NAMES[BRIEF_STEPS.TARGETING]}`}</span> : '2'}
          </div>
          <h3>Who do you want to target</h3>
        </div>
        <TestahelPopoverSummary brief={brief} />
        <div className="form__cont">
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="targetFocus" className="form-label">
                * Gender focus
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Row gutter={16} align="middle">
                <Col flex="auto">
                  <Form.Item className="mb-0" name={GENDER.FEMALE}>
                    <QRadio
                      className="mr-0"
                      onChange={handleGender}
                      checked={first(targetingFields.targetFocus) === GENDER.FEMALE}
                    >
                      {GENDER.FEMALE}
                    </QRadio>
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item className="mb-0" name={GENDER.MALE}>
                    <QRadio
                      className="mr-0"
                      onChange={handleGender}
                      checked={first(targetingFields.targetFocus) === GENDER.MALE}
                    >
                      {GENDER.MALE}
                    </QRadio>
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item className="mb-0" name={GENDER.BOTH}>
                    <QRadio
                      className="mr-0"
                      onChange={handleGender}
                      checked={first(targetingFields.targetFocus) === GENDER.BOTH}
                    >
                      {GENDER.BOTH}
                    </QRadio>
                  </Form.Item>
                </Col>
                {errors.gender && <div className="text-red font-size-12 mt-5 mb-10">{errors.gender}</div>}
              </Row>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="ageGroups" className="form-label">
                * Age groups
              </label>
              {errors.ageGroup && <div className="text-red font-size-12 mt-5 mb-10">{errors.ageGroup}</div>}
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Row gutter={16} justify={'center'} align="middle">
                {ageGroups().map((group: IAgeGroup) => {
                  const isChecked = targetingFields.targetAgeGroups.includes(group.value)
                  return (
                    <TestahelAgeGroupCard
                      key={group.value}
                      checked={isChecked}
                      label={group.label}
                      sublabel={group.sublabel}
                      img={group.img}
                      onChange={(e: any) => handleAgeGroup(e, group.value)}
                    />
                  )
                })}
              </Row>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="targetSegments" className="form-label">
                * Segments
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="targetSegments"
                rules={[
                  {
                    required: true,
                    message: 'Please choose at least one target segment.',
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <QSelect
                  value={targetingFields.targetSegments}
                  onChange={handleSegmentsChange}
                  showSearch
                  showArrow
                  maxTagTextLength={14}
                  maxTagCount={2}
                  mode="multiple"
                  size="large"
                  placeholder="Select Segments"
                  options={segments}
                  filterOption={(input: string, option: IOptionForSelect) =>
                    option.label.toLowerCase().includes(input.toLocaleLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="targetInterests" className="form-label">
                * Interests
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                className="mb-0"
                name="targetInterests"
                rules={[
                  {
                    required: true,
                    message: 'Please choose at least one interest.',
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <QSelect
                  value={targetingFields.targetInterests}
                  onChange={handleInterestsChange}
                  showSearch
                  showArrow
                  maxTagTextLength={14}
                  maxTagCount={2}
                  mode="multiple"
                  size="large"
                  placeholder="Select Interests"
                  options={productTypeOptions}
                  filterOption={(input: string, option: IOptionForSelect) =>
                    option.label.toLowerCase().includes(input.toLocaleLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="targetFocus" className="form-label mt-5">
                * Countries
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Row gutter={16} align="middle">
                {settings.countries.map((country: ICountry) => {
                  return (
                    <Col key={country.id} xs={24}>
                      <Form.Item className="mb-0" name={country.name}>
                        <QCheckbox
                          checked={targetingFields.targetCountries.includes(country.name)}
                          className="test-class"
                          disabled={true}
                        >
                          {country.name}
                        </QCheckbox>
                      </Form.Item>
                      {'(Consumer Research service is only available in Saudi Arabia)'}
                    </Col>
                  )
                })}
                {errors.country && <div className="text-red font-size-12 mt-5 mb-10">{errors.country}</div>}
              </Row>
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="targetCities" className="form-label">
                * Cities
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item className="mb-0" name="targetCities">
                <QSelect
                  value={targetingFields.targetCities}
                  onChange={handleCityChange}
                  showSearch
                  showArrow
                  mode="multiple"
                  size="large"
                  placeholder="Select Cities"
                >
                  {countries.map((country: ICountry) => {
                    return (
                      <OptGroup key={country.id} label={country.name}>
                        {country.city.map((city: ICity) => {
                          return (
                            city.country_id >= 0 && (
                              <Option key={city.id} country={country.name} value={city.name}>
                                {city.name}
                              </Option>
                            )
                          )
                        })}
                      </OptGroup>
                    )
                  })}
                </QSelect>
              </Form.Item>
              {cityError && (
                <div className="text-red font-size-12 mt-5 mb-10">
                  Should be at least one city from chosen countries.
                </div>
              )}
            </Col>
          </Row>
          <hr className="form__hr" />
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={10}>
              <label htmlFor="targetDistricts" className="form-label">
                Districts ({COUNTRY_NAME.SAUDI_ARABIA})
              </label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Form.Item className="mb-0" name="targetDistricts">
                <QSelect
                  value={targetingFields.targetDistricts}
                  onChange={handleDistrictChange}
                  showSearch
                  showArrow
                  mode="multiple"
                  size="large"
                  placeholder="Select Districts"
                >
                  {countries.length > 0 &&
                    countries[0].city.map((city: ICity) => {
                      let selectedCity = targetingFields.targetCities.find(ele => ele === city.name)
                      return (
                        selectedCity && (
                          <OptGroup key={city.id} label={city.name}>
                            {city.districts.map((district: ICity) => {
                              return (
                                <Option key={district.id} value={district.name}>
                                  {district.name}
                                </Option>
                              )
                            })}
                          </OptGroup>
                        )
                      )
                    })}
                </QSelect>
              </Form.Item>
            </Col>
          </Row>
          <hr className="form__hr" />
          {isMobile && (
            <div className="form-brief-footer">
              <QButton
                disabled={disabledFinishLater}
                onClick={onFinishLater}
                className="qu-button-outline mt-30 full-width"
              >
                Save & Finish Later <LogOut className="ml-10" />
              </QButton>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}
