import React, { useState } from 'react'
import { Row, Col, IconsCommon, QButton } from 'quantum_components'

import CampaignAnalyticsDistCharts from './CampaignAnalyticsDistCharts'
import { getScreenPagePdf } from '../../../utils/getScreenPage'
import { CAMPAIGN_PRINT_ANALYTICS_ID } from '../../../constants/campaign'
import CampaignAnalyticsMediaBuy from './CampaignAnalyticsMediaBuy'
import { CampaignAnalyticsTimeline } from './CampaignAnalyticsTimeline'
import { isMobile } from 'react-device-detect'
import { getCampaignDetails } from 'src/modules/campaign/campaign.selectors'
import { useSelector } from 'react-redux'
import { BRIEF_TYPES } from 'src/constants/brief'

const CampaignAnalytics = () => {
  const campaignDetails = useSelector(getCampaignDetails)
  const [generatingPdf, setGeneratingPdf] = useState<boolean>(false)

  const handleDownload = async () => {
    setGeneratingPdf(true)
    const markup = document.getElementById(CAMPAIGN_PRINT_ANALYTICS_ID)

    if (markup) {
      const pdf = await getScreenPagePdf(markup)
      pdf.save('Campaign Report.pdf')
    }

    setGeneratingPdf(false)
  }

  return (
    <React.Fragment>
      <Row gutter={20}>
        <Col xs={24} sm={16}>
          {campaignDetails?.brief?.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE) && (
            <div className={'mb-20'}>
              <CampaignAnalyticsDistCharts />
            </div>
          )}
          {campaignDetails?.brief?.briefTypes?.includes(BRIEF_TYPES.MEDIA_BUYING) && (
            <div className={'mb-20'}>
              <CampaignAnalyticsMediaBuy />
            </div>
          )}
        </Col>
        <Col xs={24} sm={8}>
          {/* {campaignDetails?.brief?.briefTypes?.includes(BRIEF_TYPES.PRODUCT_SAMPLE) && <CampaignAnalyticsTimeline />} */}
          {!isMobile && (
            <QButton
              loading={generatingPdf}
              disabled={generatingPdf}
              onClick={handleDownload}
              block
              className="qu-button-soft mt-20 justify-between"
              data-html2canvas-ignore
            >
              Download Report <IconsCommon.IconDownload />
            </QButton>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CampaignAnalytics
