import get from 'lodash/get'

import { IState } from './teamMembers.types'
import * as CONSTANTS from './teamMembers.constants'
import IAction from 'src/interfaces/IAction'

export const initialState: IState = {
  departmentsList: [
    {
      value: 0,
      label: '',
      isOwner: false,
    },
  ],
  teamMembersList: {
    id: 0,
    isOwner: false,
    isOpen: true,
    locatePosition: null,
    locateDepartmentId: null,
    name: '',
    partnerMembers: [],
    subMembers: [],
    createdAt: '',
    createdBy: 0,
    updatedAt: '',
    updatedBy: 0,
  },
  permissionLevels: [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'View & Edit',
      value: 'view_edit',
    },
    {
      label: 'View',
      value: 'view',
    },
  ],
  locateOptions: [
    {
      label: 'Select Position',
      value: null,
    },
    {
      label: 'Above',
      value: 'above',
    },
    {
      label: 'Below',
      value: 'below',
    },
  ],
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.FETCH_DEPARTMENTS_LIST_SUCCESS:
      const departmentsList = get(action, 'payload.departmentsList')
      return {
        ...state,
        departmentsList: departmentsList,
      }
    case CONSTANTS.FETCH_TEAM_MEMBERS_LIST_SUCCESS:
      const teamMembersList = get(action, 'payload.teamMembers')
      return {
        ...state,
        teamMembersList: teamMembersList,
      }
    default:
      return state
  }
}
