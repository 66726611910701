import React, { useEffect, useState } from 'react'
import { Form, QButton, QSelect, Radio, ServicesSummary, IconsCommon, QInput } from 'quantum_components'
import { REQUEST_ACTIONS, REQUEST_STATUS } from '../../../../../constants/request'
import { useSelector } from 'react-redux'
import { getPartnerProfileSettings } from '../../../partnerProfile/profile.selectors'
import { IOptionForSelect } from '../../../../brief/brief.types'
import { useReduxDispatch } from '../../../../../helpers'
import { actions } from '../../request.module'
import { useHistory } from 'react-router-dom'
import { PATHS } from '../../../../../constants'
import { getRequestDetails, getUpdatingRequest } from '../../request.selectors'
import { getCurrentUser } from 'src/modules/core/core.selectors'
import SpinnerCenter from 'src/components/Spinner/spinner'

const rejectIcon = <IconsCommon.IconClose />

const OTHER = 'Other'

export interface Props {
  requestId: number
}

const RequestForm = ({ requestId }: Props) => {
  const history = useHistory()
  const dispatch = useReduxDispatch()
  const currentUser: any = useSelector(getCurrentUser)
  const settings = useSelector(getPartnerProfileSettings)
  const requestDetails = useSelector(getRequestDetails)
  const updatingRequest = useSelector(getUpdatingRequest)

  const [requestAction, setRequestAction] = useState<REQUEST_ACTIONS>(REQUEST_ACTIONS.PENDING)
  const [reason, setReason] = useState<string | null>(null)
  const [input, setInput] = useState<string | null>(null)
  const [requestApproving, setRequestApproving] = useState(false)

  const isApprove = requestAction === REQUEST_ACTIONS.APPROVE
  const isReject = requestAction === REQUEST_ACTIONS.REJECT
  const isPending = requestAction === REQUEST_ACTIONS.PENDING
  const isReviewNow = requestDetails?.status === REQUEST_STATUS.REVIEW_NOW

  useEffect(() => {
    if (!requestDetails) {
      return
    }

    if (requestDetails.status === REQUEST_STATUS.ACCEPTED) {
      setRequestAction(REQUEST_ACTIONS.APPROVE)
    }
    if (requestDetails.status === REQUEST_STATUS.REJECTED) {
      setRequestAction(REQUEST_ACTIONS.REJECT)
    }

    setRejectionReason(requestDetails.rejectionReason)
  }, [requestDetails])

  const setRejectionReason = (rejectionReason: string | null) => {
    if (!rejectionReason) {
      return
    }

    const isSettingReason = settings.rejectionReasons.some((reason: string) => reason === rejectionReason)

    if (isSettingReason) {
      setReason(rejectionReason)
    } else {
      setReason(OTHER)
      setInput(rejectionReason)
    }
  }

  const handleChange = (e: any) => {
    const value = e.target.value

    if (value === REQUEST_ACTIONS.APPROVE) {
      setReason(null)
    }
    setRequestAction(e.target.value)
  }

  const handleSubmit = async () => {
    if (requestAction === REQUEST_ACTIONS.APPROVE) {
      setRequestApproving(true)
    }

    const reasonValue = reason === OTHER ? input : reason
    await dispatch(actions.updateRequest(requestId, requestAction, reasonValue))

    setRequestApproving(false)
    history.push(PATHS.REQUESTS)
  }

  const handleReason = (value: string) => {
    if (value !== OTHER) {
      setInput(null)
    }

    setReason(value)
  }

  const handleInput = (e: any) => {
    setInput(e.target.value)
  }

  const renderSubmit = () => {
    if (!isReviewNow) {
      return null
    }

    const className = isApprove ? 'qu-button-green' : 'qu-button-red'
    const disabled = isPending || (isReject && !reason) || (reason === OTHER && !input) || updatingRequest

    return (
      <QButton
        onClick={handleSubmit}
        disabled={disabled || (currentUser && currentUser.partnerPermissionLevel == 'view')}
        className={`${className} qu-button-outline full-width mv-20`}
      >
        Submit Response
      </QButton>
    )
  }

  const reasons = [...settings.rejectionReasons, OTHER]
  const reasonsOptions: IOptionForSelect[] = reasons.map((reason: string) => {
    return { label: reason, value: reason }
  })

  return (
    <React.Fragment>
      {requestApproving && <SpinnerCenter text="Generating PO…" />}

      <ServicesSummary>
        <div className="SS__category ph-15">
          <Form layout="vertical">
            <div className="mv-20">
              <Radio.Group
                disabled={!isReviewNow || (currentUser && currentUser.partnerPermissionLevel == 'view')}
                className="qu-radio-row"
                value={requestAction}
                onChange={handleChange}
              >
                <Radio.Button className="qu-radio-accept" value={REQUEST_ACTIONS.APPROVE}>
                  <div className="qu-radio-center full-height">
                    <IconsCommon.IconAccept />
                    <span>Accept</span>
                  </div>
                </Radio.Button>
                <Radio.Button className="qu-radio-reject" value={REQUEST_ACTIONS.REJECT}>
                  <div className="qu-radio-center full-height">
                    {rejectIcon}
                    <span>Reject</span>
                  </div>
                </Radio.Button>
              </Radio.Group>
            </div>
            {isReject && (
              <React.Fragment>
                <Form.Item colon={false} label={<b>Rejection Reason</b>}>
                  <QSelect
                    disabled={!isReviewNow || (currentUser && currentUser.partnerPermissionLevel == 'view')}
                    className="full-width"
                    showSearch
                    size="large"
                    placeholder="Select a reason for rejection"
                    value={reason}
                    onChange={handleReason}
                    options={reasonsOptions}
                    help="This will be visible to the client"
                  />
                </Form.Item>
                {reason === OTHER && (
                  <Form.Item colon={false}>
                    <QInput
                      disabled={!isReviewNow || (currentUser && currentUser.partnerPermissionLevel == 'view')}
                      size="large"
                      placeholder="Please enter the reason"
                      maxLength={255}
                      value={input}
                      onChange={handleInput}
                    />
                  </Form.Item>
                )}
              </React.Fragment>
            )}
            {/*<Form.Item*/}
            {/*  colon={false}*/}
            {/*  label={<b>Invite others to view, accept or reject</b>}*/}
            {/*>*/}
            {/*  <QSelect*/}
            {/*    maxTagTextLength={8}*/}
            {/*    maxTagCount={2}*/}
            {/*    mode="multiple"*/}
            {/*    placeholder="enter email addresses"*/}
            {/*    options={selectOptions}*/}
            {/*    size="large"*/}
            {/*  />*/}
            {/*</Form.Item>*/}
          </Form>
        </div>
      </ServicesSummary>
      {renderSubmit()}
      {isApprove && isReviewNow && (
        <p className="p--sm text-center">
          After you accept this request, it will appear as a Campaign. Quantum staff will contact you regarding product
          distribution within one business day of submission.
        </p>
      )}
      {isReject && isReviewNow && (
        <p className="p--sm text-center text-red">
          Clients will be notified as soon as you <br /> click Submit.
        </p>
      )}
    </React.Fragment>
  )
}

export default RequestForm
