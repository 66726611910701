import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as actions from 'src/modules/registration/signUp/signUp.actions'
import { useReduxDispatch } from 'src/helpers'
import { PATHS, REGEXES, ROLES, LOCALIZATION_LANGUAGES } from 'src/constants'
import { Col, Form, Modal, QButton, QButtonEye, QInput, qnotification, Radio, Row } from 'quantum_components'
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-icon.svg'
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrow-left-icon.svg'
import { validateMessages } from '../../../../helpers/validationMessage'
import { Store } from 'antd/lib/form/interface'
import AuthLayout from '../../../../components/Layouts/AuthLayout'
import ReCAPTCHA from 'react-google-recaptcha'
import { VALIDATION_MESSAGE } from '../../../../constants/validationMessages'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script';
import { useSelector } from 'react-redux'
import { getIsLoading } from '../signUp.selectors'
import SpinnerCenter from 'src/components/Spinner/spinner'
import { SOCIAL_LOGIN_TYPES } from 'src/constants/common'

export interface FormValues {
  email: string
  username: string
  companyName: string
  firstName: string
  lastName: string
  phone: string
  password: string
  role: ROLES
  reCaptchaToken: string
}

export interface SocialFormValues {
  email?: string
  companyName?: string
  firstName?: string
  lastName?: string
  phone?: string
  password?: string
  role?: ROLES
  socialToken: string
  socialType?: string
}

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''

export default function SignUpContainer({ hidePartnerInfo, specialClientData }): ReactElement {
  const { i18n } = useTranslation()
  const { t } = useTranslation(['translationUser', 'translationCommon'])
  const dispatch = useReduxDispatch()
  const isLoading = useSelector(getIsLoading);
  const [reCaptcha, setRecaptcha] = useState<string>('')
  const [reCaptchaError, setRecaptchaError] = useState<boolean>(false)
  const [passwordIsVisible, setPasswordVisible] = useState<boolean>(false)
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [googleToken, setGoogleToken] = useState('');
  const clientId = process.env.REACT_APP_GOOGLE_LOGIN_KEY

  const [confirm, setConfirm] = useState(false)
  const [formData, setFormData] = useState<any>({})

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
  })

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha('register');
      setGoogleToken(token);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const turnUsernameIntoEmail = (username: string) => {
    const safeUsername = username.trim().replace(/ /g, '').replace(/\s/g, '').replace(/_/g, '').toLowerCase();
    return `safe_${safeUsername}@yopmail.com`
  }

  const submitForm = (values: Store) => {
    if (!values) {
      values = { ...formData };
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = '';
    let specialId = '';
    let briefId = '';

    if (specialClientData && specialClientData.p) {
      // data coming from sign in modal in invite client
      token = specialClientData.t;
      specialId = specialClientData.p;
      briefId = specialClientData.b;
    } else {
      // data coming from query string
      token = urlParams.has('t') ? urlParams.get('t') : "";
      specialId = urlParams.has('p') ? urlParams.get('p') : "";
      briefId = urlParams.has('b') ? urlParams.get('b') : "";
    }

    // if (!reCaptcha) {
    //   setRecaptchaError(true)
    //   return
    // }

    const formValues = { ...values, role: ROLES.CLIENT, companyName: values.email.split("@")[1].split(".")[0], firstName: values.email.split("@")[0], reCaptchaToken: googleToken } as FormValues

    setConfirm(false)
    setFormData({})

    dispatch(actions.signUp(formValues, token, specialId, briefId))
  }

  const onFinish = (values: Store) => {
    // if (!values.username && !values.email) {
    //   qnotification({ type: 'error', message: t('user.signup.please_provide') })
    //   return;
    // }

    // if (!values.email) {
    //   setConfirm(true)
    //   setFormData({ ...values, email: turnUsernameIntoEmail(values.username) })
    //   return;
    // }
    // if (!executeRecaptcha) {
    //   return;
    // }
    submitForm(values);
  }

  const initialValues = { role: ROLES.CLIENT }

  const handleReCaptcha = (token: string | null) => {
    if (token) {
      setRecaptcha(token)
      setRecaptchaError(false)
    } else {
      setRecaptcha('')
      setRecaptchaError(true)
    }
  }

  const getPasswordInputType = (passwordIsVisible: boolean) => {
    return passwordIsVisible ? 'text' : 'password'
  }

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordIsVisible)
  }

  const getSigninLink = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.has('t') ? urlParams.get('t') : "";
    const specialId = urlParams.has('p') ? urlParams.get('p') : "";

    const signinUrl = (token && specialId) ? `${PATHS.SIGN_IN}?t=${token}&p=${specialId}` : PATHS.SIGN_IN;
    return signinUrl;
  }

  const handleLoginSuccess = async (data: any) => {
    if (data?.profileObj) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let token = '';
      let specialId = '';
      let briefId = '';

      if (specialClientData && specialClientData.p) {
        // data coming from sign in modal in invite client
        token = specialClientData.t;
        specialId = specialClientData.p;
        briefId = specialClientData.b;
      } else {
        // data coming from query string
        token = urlParams.has('t') ? urlParams.get('t') : "";
        specialId = urlParams.has('p') ? urlParams.get('p') : "";
        briefId = urlParams.has('b') ? urlParams.get('b') : "";
      }

      const formValues = {
        email: data.profileObj.email,
        firstName: data.profileObj.givenName,
        lastName: data.profileObj.familyName,
        socialToken: data.tokenId,
        socialType: SOCIAL_LOGIN_TYPES.GOOGLE,
        role: ROLES.CLIENT
      } as SocialFormValues
      dispatch(actions.socialSignUp(formValues, token, specialId, briefId))
    }
  }

  const handleLoginFailure = async (error: any) => {
    console.log('error', error)
  }

  return (
    <AuthLayout hidePartnerInfo={hidePartnerInfo} AuthPageLadyImageMaxWidth={"400px"}>
      {isLoading && <SpinnerCenter />}

      <Modal
        visible={confirm}
        title={t("user.signup.confirm_username_title")}
        onCancel={() => setConfirm(false)}
        closable={!isLoading}
        footer={
          [
            <QButton disabled={isLoading} htmlType='button' type='primary' size='middle' onClick={() => submitForm(null)}>{t("user.signup.yes")}</QButton>
          ]
        }
      >
        <div>
          {t("user.signup.confirm_username")}
        </div>
      </Modal>

      <div className="auth-form">
        {!hidePartnerInfo && <h1 className="font-size-26" style={{ marginBottom: '5px' }}>
          {t('user.signup.welcome_to_the_quantum')}
        </h1>}
        {/* <h4 className='mb-20'>
          {t('user.signup.create_your_account')}
        </h4> */}
        <Form
          layout="vertical"
          hideRequiredMark
          initialValues={initialValues}
          validateMessages={{ ...validateMessages, required: t('required field') }}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            {/* <Col span={24}>
              <Form.Item
                label={t('user.signup.company_name')}
                name="companyName"
                rules={[{ required: true, max: 255, whitespace: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t('user.signup.your_company_name')} size="large" />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                label={t('user.signup.first_name')}
                name="firstName"
                rules={[{ required: true, max: 255, whitespace: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t('user.signup.first_name')} size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('user.signup.last_name')}
                name="lastName"
                rules={[{ required: true, max: 255, whitespace: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t('user.signup.last_name')} size="large" />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                label={t('user.signup.company_email')}
                name="email"
                rules={[{ max: 255, whitespace: true, pattern: REGEXES.EMAIL_SIGNUP_REGEX, message: t('user.signup.Organisation email is not a valid email') }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t('user.signup.your_company_email_address')} size="large" type="email" />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                label={t('user.signup.username')}
                name="username"
                rules={[{max: 255, min:8, pattern:/^[a-z_0-9]{8,255}$/,message:t("user.signup.username_validation")}]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t('user.signup.username')} size="large" />
              </Form.Item>
            </Col> */}
            {/* <Col span={24}>
              <Form.Item
                label={t('user.signup.phone_number')}
                name="phone"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    pattern: REGEXES.TEN_DIGIT,
                    message: t(VALIDATION_MESSAGE.PHONE_NUMBER, { ns: 'translation' }),
                  },
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t('user.signup.your_phone_number')} size="large" />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                label={t('user.signup.password')}
                name="password"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    pattern: REGEXES.PASSWORD,
                    message: t(VALIDATION_MESSAGE.PASSWORD, { ns: 'translation' }),
                  },
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput
                  size="large"
                  placeholder={t('user.signup.your_password')}
                  style={{ paddingRight: 50 }}
                  type={getPasswordInputType(passwordIsVisible)}
                />
              </Form.Item>
              <QButtonEye onClick={handlePasswordVisibility} isVisible={passwordIsVisible} />
            </Col>
          </Row>
          {/* <div className="auth-captcha">
              <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleReCaptcha} />
              {reCaptchaError && <div className="text-red font-size-12 mt-5 mb-10">Please complete the CAPTCHA</div>}
            </div> */}
          <QButton
            // onClick={() => handleReCaptcha(reCaptcha)}
            className="text-capitalize"
            type="primary"
            block
            htmlType="submit"
          >
            {t('user.signup.register_now')}
            {i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? (
              <ArrowRight className="ml-5" />
            ) : (
              <ArrowLeft className="ml-5" />
            )}
          </QButton>
          {!hidePartnerInfo && <p className="auth-terms">
            {t('user.signup.disclaimer1')}{' '}
            <a href="https://www.quantums.com.sa/terms-and-conditions" target="_blank" rel="noopener noreferrer">
              {t('user.signup.disclaimer2')}
            </a>{' '}
            {t('user.signup.disclaimer3')}{' '}
            <a href="https://www.quantums.com.sa/privacy-policy" target="_blank" rel="noopener noreferrer">
              {t('user.signup.disclaimer4')}
            </a>
          </p>}
          {/* <h5 className='mt-10 full-width text-center'>{t('user.signup.or')}</h5> */}

          {/* <GoogleLogin
            className='mt-10 full-width ant-btn qu-button text-capitalize ant-btn-primary ant-btn-block google-login-button'
            clientId={clientId}
            buttonText={t('user.signup.sign_in_with_google')}
            onSuccess={handleLoginSuccess}
            onFailure={handleLoginFailure}
            cookiePolicy={'single_host_origin'}
          /> */}
        </Form>
      </div>
      {!hidePartnerInfo && <div className="auth-section-footer">
        <p>
          {t('user.signup.already_have_an_account')} <Link to={getSigninLink()}>{t('user.signup.sign_in')}</Link>
        </p>
      </div>}
    </AuthLayout>
  )
}
