import get from 'lodash/get'

import { IState } from './campaign.types'
import * as CONSTANTS from './campaign.constants'
import IAction from 'src/interfaces/IAction'
import { CLIENT_SURVEY_PER_PAGE } from '../../constants/survey'
import { TABS_NAMES } from '../../constants/tabs'
import { getDateFromToday, getIsoDate } from 'src/helpers/datesHelper'
import moment from 'moment'
import { DATE_PRESET } from 'src/constants/dashboard'

const initialState: IState = {
  fetchingCampaignListError: '',
  campaignInfoError: '',
  campaignDetailsError: '',
  campaignListLoading: true,
  campaignDetailsLoading: true,
  campaignList: {
    stats: {
      lifetime: 0,
      upcoming: 0,
    },
    results: [],
    total: 0,
  },
  pages: {
    totalPages: 1,
    currentPage: 1,
  },
  params: {
    offset: 0,
    limit: 10,
  },
  campaignDetails: null,
  campaignAnalyticsInfo: null,
  campaignAnalyticsInfoLoading: false,
  campaignAnalyticsInfoError: '',
  campaignAnalyticsData: null,
  campaignAnalyticsDataLoading: false,
  campaignAnalyticsDataError: '',
  campaignTimelines: [],
  campaignTimelineLoading: false,
  campaignTimelineError: '',
  campaignDistAnalyticsFilter: {
    partnerIds: [],
    cityIds: [],
    ageGroups: [],
  },
  campaignMediaBuyAnalyticsFilter: {
    partnerId: null,
    mediaType: '',
    beforeDate: getIsoDate(getDateFromToday(DATE_PRESET.WEEK)),
    afterDate: getIsoDate(moment().endOf('day'))
  },
  campaignMediaBuyAnalyticsData: null,
  campaignMediaBuyAnalyticsLoading: false,
  clientSurveys: {
    results: [],
    total: 0,
  },
  clientSurveysLoading: false,
  clientSurveysError: '',
  surveysPages: {
    totalPages: 1,
    currentPage: 1,
  },
  surveyParams: {
    offset: 0,
    limit: CLIENT_SURVEY_PER_PAGE,
    partnerIds: [],
  },
  clientGalleryList: {
    results: [],
    total: 0,
  },
  clientGalleryLoading: false,
  clientGalleryError: '',
  galleryPages: {
    totalPages: 1,
    currentPage: 1,
  },
  galleryParams: {
    offset: 0,
    limit: CLIENT_SURVEY_PER_PAGE,
    search: '',
  },

  downloadMediaLoading: false,
  downloadMediaError: '',
  activeTab: TABS_NAMES.ANALYTICS,
  clientActiveTab: TABS_NAMES.DISTRIBUTION,
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_CAMPAIGN_STATE:
      return initialState
    case CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, pages: { ...state.pages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_CAMPAIGN_LIST_PARAMS:
      return { ...state, params: get(action, 'payload.params') }
    case CONSTANTS.SET_ACTIVE_TAB:
      return { ...state, activeTab: get(action, 'payload.tab') }
    case CONSTANTS.SET_CLIENT_ACTIVE_TAB:
      return { ...state, clientActiveTab: get(action, 'payload.tab') }

    case CONSTANTS.FETCH_CAMPAIGNS_REQUEST:
      return {
        ...state,
        campaignListLoading: true,
      }
    case CONSTANTS.FETCH_CAMPAIGNS_SUCCESS:
      const campaignList = get(action, 'payload.campaignList')
      // @ts-ignore
      const totalPages = Math.ceil(campaignList.total / state.params.limit)
      return {
        ...state,
        campaignList,
        pages: { ...state.pages, totalPages },
        campaignListLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGNS_FAILURE:
      return { ...state, fetchingCampaignListError: action.error, campaignListLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...state,
        campaignDetails: get(action, 'payload.details'),
        campaignDetailsLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_DETAILS_FAILURE:
      return { ...state, campaignDetailsError: action.error, campaignDetailsLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_REQUEST:
      return { ...state, campaignAnalyticsInfoLoading: true }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_SUCCESS:
      return {
        ...state,
        campaignAnalyticsInfo: get(action, 'payload.info'),
        campaignAnalyticsInfoLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_INFO_FAILURE:
      return { ...state, campaignAnalyticsInfoError: action.error, campaignAnalyticsInfoLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_REQUEST:
      return { ...state, campaignAnalyticsDataLoading: true }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        campaignAnalyticsData: get(action, 'payload.data'),
        campaignAnalyticsDataLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_DATA_FAILURE:
      return { ...state, campaignAnalyticsDataError: action.error, campaignAnalyticsDataLoading: false }

    case CONSTANTS.FETCH_CAMPAIGN_TIMELINE_REQUEST:
      return { ...state, campaignTimelineLoading: true }
    case CONSTANTS.FETCH_CAMPAIGN_TIMELINE_SUCCESS:
      return {
        ...state,
        campaignTimelines: get(action, 'payload.data'),
        campaignTimelineLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_TIMELINE_FAILURE:
      return { ...state, campaignTimelineError: action.error, campaignTimelineLoading: false }

    case CONSTANTS.SET_DIST_ANALYTICS_FILTER:
      return { ...state, campaignDistAnalyticsFilter: get(action, 'payload.filter') }

    case CONSTANTS.SET_MEDIA_BUY_ANALYTICS_FILTER:
      return { ...state, campaignMediaBuyAnalyticsFilter: get(action, 'payload.filter') }

    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_REQUEST:
      return { ...state, campaignMediaBuyAnalyticsLoading: true }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_SUCCESS:
      return {
        ...state,
        campaignMediaBuyAnalyticsData: get(action, 'payload.data'),
        campaignMediaBuyAnalyticsLoading: false,
      }
    case CONSTANTS.FETCH_CAMPAIGN_ANALYTICS_MEDIA_BUY_FAILURE:
      return { ...state, campaignAnalyticsDataError: action.error, campaignMediaBuyAnalyticsLoading: false }

    case CONSTANTS.FETCH_CLIENT_SURVEYS_REQUEST:
      return {
        ...state,
        clientSurveysLoading: true,
      }
    case CONSTANTS.FETCH_CLIENT_SURVEYS_SUCCESS:
      const clientSurveys = get(action, 'payload.surveys');
      // @ts-ignore
      const surveyTotal = Math.ceil(clientSurveys.total / state.surveyParams.limit)
      return {
        ...state,
        clientSurveys,
        surveysPages: { ...state.surveysPages, totalPages: surveyTotal },
        clientSurveysLoading: false,
      }
    case CONSTANTS.FETCH_CLIENT_SURVEYS_FAILURE:
      return { ...state, clientSurveysError: action.error, clientSurveysLoading: false }

    case CONSTANTS.SET_CLIENT_SURVEYS_CURRENT_PAGE:
      return { ...state, surveysPages: { ...state.surveysPages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_CLIENT_SURVEYS_PARAMS:
      return { ...state, surveyParams: get(action, 'payload.params') }

    case CONSTANTS.FETCH_CLIENT_GALLERY_REQUEST:
      return {
        ...state,
        clientGalleryLoading: true,
      }
    case CONSTANTS.FETCH_CLIENT_GALLERY_SUCCESS:
      const clientGalleryList = get(action, 'payload.gallery');
      // @ts-ignore
      const galleryTotal = Math.ceil(clientGalleryList.total / state.galleryParams.limit)
      return {
        ...state,
        clientGalleryList,
        galleryPages: { ...state.galleryPages, totalPages: galleryTotal },
        clientGalleryLoading: false,
      }
    case CONSTANTS.FETCH_CLIENT_GALLERY_FAILURE:
      return { ...state, clientGalleryError: action.error, clientGalleryLoading: false }

    case CONSTANTS.SET_CLIENT_GALLERY_CURRENT_PAGE:
      return { ...state, galleryPages: { ...state.galleryPages, currentPage: get(action, 'payload.page') } }
    case CONSTANTS.SET_CLIENT_GALLERY_PARAMS:
      return { ...state, galleryParams: get(action, 'payload.params') }

    case CONSTANTS.FETCH_DOWNLOAD_MEDIA_REQUEST:
      return { ...state, downloadMediaLoading: true }
    case CONSTANTS.FETCH_DOWNLOAD_MEDIA_SUCCESS:
      return { ...state, downloadMediaLoading: false }
    case CONSTANTS.FETCH_DOWNLOAD_MEDIA_FAILURE:
      return { ...state, downloadMediaError: action.error, downloadMediaLoading: false }

    default:
      return state
  }
}
