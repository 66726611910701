import localStorage from 'redux-persist/es/storage'
import { LOCAL_STORAGE_KEYS } from '../constants'
import ROLES from '../constants/roles'

const getUserRole = async () => {
  const userRole = await localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLE)
  const role = userRole ? (userRole as ROLES) : ROLES.UNKNOWN
  return role
}

export default getUserRole
