import React, { FC } from 'react'
import { QRadio, Radio } from 'quantum_components'
import { GENDER } from '../../../../../../constants/brief'

interface IProps {
  value: GENDER[]
  onChange: (value: GENDER[]) => void
  isViewOnly?: boolean
}

const GenderFocusInput: FC<IProps> = ({ value = [], onChange, isViewOnly }) => {
  const handleRadio = (e: any) => {
    const { value } = e.target
    onChange([value])
  }

  return (
    <Radio.Group
      className="qu-radio-group"
      name="genderFocus"
      value={value[0]}
      onChange={handleRadio}
      disabled={isViewOnly}
    >
      <QRadio value="Male">Male</QRadio>
      <QRadio value="Female">Female</QRadio>
      <QRadio value="Both">Both</QRadio>
    </Radio.Group>
  )
}

export default GenderFocusInput
